import { addDays } from 'date-fns';
import { get } from 'lodash/fp';
import { v4 as uuid } from 'uuid';
import IDimension from '../domains/dimensions/types';
import { formatISO } from '../domains/reports/adapters/date';
import { AttributionFilterTypes } from '../domains/reports/types';
import IDateRange, { IUndigestedDateRange } from './dateRange';
import IIdName from './idName';
import ITextValue from './textValue';

export const comparisonOperators = {
  GT: 'Greater than',
  LT: 'Less than',
  EQ: 'Equal to',
};

export type ComparisonOperator = keyof typeof comparisonOperators;

export const unionOperators = {
  AND: 'And',
  OR: 'Or',
};

export type UnionOperator = keyof typeof unionOperators;

export const containsOperators = {
  CONTAINS: 'contains one of',
  'NOT CONTAINS': 'does not contain one of',
};

export type ContainsOperator = keyof typeof containsOperators;

export const inOperators = {
  IN: 'is one of',
  'NOT HAVE': 'unexposed to',
  'NOT IN': 'is not one of',
};

export const dateOperators = {
  BETWEEN: 'Between',
  'UNEXPOSED BETWEEN': 'Unexposed between',
};

export const piqBreakoutOperators = {
  BREAKOUT_ON: 'Breakout on',
};

export type PIQBreakoutOperator = keyof typeof piqBreakoutOperators;

export type DateOperator = keyof typeof dateOperators;

export const allOperators = {
  ...comparisonOperators,
  ...containsOperators,
  ...dateOperators,
  ...inOperators,
  ...unionOperators,
  ...piqBreakoutOperators,
};

export type InOperator = keyof typeof inOperators;

export type Operator =
  | ContainsOperator
  | InOperator
  | ComparisonOperator
  | DateOperator
  | PIQBreakoutOperator;

export type FilterValue = (IIdName | ITextValue | string)[] | string;

export enum FilterType {
  LOGICAL = 'LOGICAL',
  SIMPLE = 'SIMPLE',
  DATETIME_RANGE = 'DATETIME_RANGE',
  COMPOUND_FILTER = 'COHORT',
  GENERIC_EVENTS_DATASET = 'GENERIC_EVENTS_DATASET',
}

export type NonDateRangeFilterTypes = 'LOGICAL' | 'SIMPLE';

export type AllFilterTypes =
  | NonDateRangeFilterTypes
  | 'DATETIME_RANGE'
  | 'COHORT'
  | 'PLACEIQ'
  | 'GENERIC_EVENTS_DATASET'
  | 'EXPERIAN_BREAKOUTS';

export type UndigestedFilter = {
  id?: string;
  field?: string;
  operator_type?: string;
  type?: AllFilterTypes;
  operator?: Operator;
  value?: FilterValue;
  start?: string;
  end?: string;
  startString?: string;
  endString?: string;
  isRule?: boolean;
  description?: string;
};

export type RuleFilter = UndigestedFilter & {
  filterId: string;
  subDimensions?: Array<IDimension>;
  name?: string;
  mainField?: string;
  childField?: string;
  filterRules?: Record<string, unknown>[];
  children?: FilterValue;
};

export type UndigestedBase = (IUndigestedDateRange | UndigestedFilter)[];

export type IChildrenBase = (IDateRange | RuleFilter)[];

export type IQueryFiltersChildren =
  | IChildrenBase
  | IQueryFilters[]
  | UndigestedFilter[];

export type UndigestedFiltersChildren =
  | UndigestedBase
  | IQueryFilters<UndigestedFiltersChildren>[];

export interface IQueryFilters<T = IQueryFiltersChildren> {
  type?: 'LOGICAL';
  operator?: 'AND';
  name?: AttributionFilterTypes;
  dates?: { start: string; end: string };
  children?: T;
}

export function isArrayOfIQueryFilters(
  prop: unknown,
): prop is IQueryFilters<UndigestedFiltersChildren>[] {
  return get('[0].type', prop) === FilterType.LOGICAL;
}

export const newFilter = (): RuleFilter => ({
  filterId: uuid(),
});

export const newDateTimeFilter = (start?: string, end?: string): IDateRange => {
  const today = formatISO(new Date());
  const tomorrow = formatISO(addDays(new Date(), 1));

  return {
    start: start ?? today,
    end: end ?? tomorrow,
    type: FilterType.DATETIME_RANGE,
  };
};

export interface IFilterValuePayload extends IFilterValueMetricPayload {
  lineBegin: number;
  lineEnd: number;
  valueFilter: string;
  childField?: string;
  filterRules: Record<string, unknown>[];
}
export interface IFilterValueMetricPayload {
  datasetIds: string[];
  daypart_dataset_id?: string;
  genericEventDatasets?: string[];
  field: string;
  selectedClient?: string;
  userEmail?: string;
  mode?: string;
  filter_rules?: IQueryFiltersChildren;
}
