import { get, set } from 'lodash';

type DefaultType = Record<string, unknown>;

export function getOrSetDefault<ObjectType = DefaultType, Value = boolean>(
  object: ObjectType,
  path: string,
  value?: Value,
): void {
  const defaultValue = value ?? true;
  const descendant = get(object, path);
  set(object as DefaultType, path, defaultValue);
  if (descendant === undefined) {
    return;
  }
  if (typeof defaultValue !== 'boolean') {
    if (typeof descendant !== 'boolean') {
      if (Array.isArray(descendant)) {
        descendant.push(defaultValue);
        set(object as DefaultType, path, descendant);
        return;
      }
      set(object as DefaultType, path, [descendant, defaultValue]);
    }
  }
  return;
}
