import { IDumbbellComputedSeries, IDumbbellSeries } from '..';

/**
 * Computes the segmentedData value to series data acceptable by Highcharts
 *
 * @param series
 * Array of { value, color } objects which represent the points for each DEMOGRAPHIC segment.
 * @param y
 * y value must be the same for all points of each segment - to be placed on one straight line.
 * @returns
 * chart series data object acceptable by Highcharts {x, y, marker}
 */

export const computeSeries = (
  series: IDumbbellSeries[],
  y: number,
): IDumbbellComputedSeries[] =>
  series
    .sort((a, b) => a.value - b.value)
    .map((s) => {
      const extraDemographicData = s.reach
        ? {
            reach: s.reach,
            pcOfUniverse: s.pcOfUniverse,
          }
        : {};
      return {
        x: s.value,
        y,
        ...extraDemographicData,
        marker: { fillColor: s.color, radius: 10, symbol: 'circle' },
        name: s.name,
      };
    });
