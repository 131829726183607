import { getClass } from 'helpers/components';
import React, { FunctionComponent } from 'react';
import { IOption } from 'types/textValue';

export const chipDropdownName = 'chip-dropdown';

export const chipDropdownChipLegendClass = getClass(
  `${chipDropdownName}__legend`,
);

export interface IChipDropdown extends IOption {
  simple?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  short?: boolean;
  toggleStatus?: () => void;
}

const ChipDropdown: FunctionComponent<IChipDropdown> = (props) => {
  const {
    id,
    text,
    value,
    color,
    simple,
    showLegend,
    selected,
    short,
    toggleStatus,
  } = props;

  const chipDropdownChipClass = getClass(chipDropdownName, {
    boolean: [
      {
        state: simple,
        class: '--simple',
      },
      {
        state: selected,
        class: '--selected',
      },
    ],
  });

  const chipDropdownChipLabelClass = getClass(`${chipDropdownName}__label`, {
    boolean: [
      {
        state: short && text?.length > 20,
        class: '--short',
      },
    ],
  });

  return (
    <div
      className={chipDropdownChipClass}
      onClick={() => {
        if (toggleStatus && simple) toggleStatus();
      }}
      aria-hidden="true"
      data-id={id}
      data-value={value}
    >
      {showLegend && (
        <span
          style={{
            backgroundColor: color,
          }}
          className={chipDropdownChipLegendClass}
        />
      )}
      <span title={text} className={chipDropdownChipLabelClass}>
        {text}
      </span>
    </div>
  );
};

export default ChipDropdown;
