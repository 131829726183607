import {
  ResultBaseSubStructure,
  ResultBaseTotalDataTarget,
} from 'domains/reports/types';
import { IOption } from 'types/textValue';
import { ITableData } from '../components/TableWrapper';

export type IChartAndTableTransformerInputData = ResultBaseTotalDataTarget;

export enum InputFilters {
  xAxis = 'xAxis',
  yAxis = 'yAxis',
  bubbleSize = 'bubbleSize',
  breakout = 'breakout',
  sortedBy = 'sortedBy',
}

export type ITransformerInputFilters = { [key in InputFilters]: string } & {
  targets: Array<IOption>;
  isSortedDesc: boolean;
};

export interface ITransformerInput {
  filters: ITransformerInputFilters;
  filteredData: IChartAndTableTransformerInputData;
  metrics: Array<string>;
  conversionType?: string;
}

export interface ITransformerOutput<SeriesType> {
  chartSeries: SeriesType;
  tableData: ITableData;
}

export type DataRowWithTarget = ResultBaseSubStructure & {
  target: string | number;
  color: string;
};
