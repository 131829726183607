import { getTestId, getId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactNode } from 'react';

export const h4ComponentName = 'h4';

export interface IProps {
  testId?: string;
  id?: string;
  children?: ReactNode;
}

const H4: FunctionComponent<IProps> = (props) => {
  const { testId, id, children } = props;

  const h4Class = getClass(h4ComponentName);
  const h4Id = getId(h4ComponentName, id);
  const h4TestId = getTestId(h4ComponentName, testId);

  return (
    <h4 className={h4Class} data-testid={h4TestId} id={h4Id}>
      {children}
    </h4>
  );
};

export default H4;
