import { Dispatch } from 'redux';
import * as reportActions from 'store/actions/report';
import * as rootActions from 'store/actions/root';
import * as reportSelectors from 'store/selectors/report';
import State from 'types/state';

export const handleRemoveMissingReportName =
  (setMissingNameHook: Function, closeModalHook: Function) => (): void => {
    setMissingNameHook(undefined);
    closeModalHook();
  };

export const onSaveEmptyNameReport =
  (
    isModalReportNameAndRun: boolean,
    name: string | undefined,
    closeModalHook: Function,
    doSubmitHook: Function,
  ) =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    const state = getState();
    const report = reportSelectors.getReport(state);

    if (name) {
      dispatch(
        reportActions.setReport({
          ...report,
          name,
        }),
      );
    }

    closeModalHook();
    if (isModalReportNameAndRun) {
      dispatch(rootActions.setAttributionReportList(null));
    }
    await doSubmitHook(isModalReportNameAndRun, {
      ...report,
      name,
    });
  };
