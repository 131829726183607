import Button, { Kind } from 'components/Button';
import Icon, { Type as IconType, Color as IconColor } from 'components/Icon';
import React, { ReactElement, useState } from 'react';

const RulesWarning = (): ReactElement | null => {
  const [isVisible, toggleVisibility] = useState(true);

  if (!isVisible) return null;
  return (
    <div className="rules-builder-warning" data-testid="rules-builder-warning">
      <Icon type={IconType.warning} color={IconColor.dark} />
      <div>
        <span>Editing a rule will erase the rules below it.</span>
      </div>
      <Button
        kind={Kind.icon}
        iconType={IconType.close}
        onClick={() => toggleVisibility(false)}
        testId="rules-builder-warning-close-btn"
      />
    </div>
  );
};

export default RulesWarning;
