import Icon, { Type, Size } from 'components/Icon';
import { IProcessing } from 'domains/processing/types';
import { getClass } from 'helpers/components';
import React, { FunctionComponent } from 'react';

export const ProcessingOverlayTestId = 'process-overlay-screen';
const ProcessingOverlayComponentName = 'processing-overlay';
const processingOverlayClass = getClass(ProcessingOverlayComponentName);
const processingOverlayContentClass = getClass(
  `${ProcessingOverlayComponentName}__content`,
);

const ProcessingOverlay: FunctionComponent<IProcessing> = (
  props: IProcessing,
) => {
  const { label, running, percentage } = props;

  return (
    <div
      data-testid={ProcessingOverlayTestId}
      className={`${processingOverlayClass} ${running ? 'running' : ''}`}
    >
      <div className={processingOverlayContentClass}>
        <Icon type={Type.loading} size={Size.large} />
        <p className="label">{label}</p>
        {percentage && <p className="percentage">{percentage}%</p>}
      </div>
    </div>
  );
};

export default ProcessingOverlay;
