import { startCase } from 'lodash/fp';
import { toLower } from 'lodash';

export const MetricNamesMap: { [key: string]: string } = {
  'campaign.FREQUENCY': 'Impression Frequency',
  'campaign.IMPRESSIONS': 'Impressions',
  'campaign.FREQUENCY_EQ': 'Eq. Impression Frequency',
  'campaign.IMPRESSIONS_EQ': 'Eq. Impressions',
  'campaign.SPOT_COUNT': 'Ad Spot Count',
  'campaign.AD_COST': 'Ad Cost',
  'campaign.SPOT_COUNT_EQ': 'Eq. Ad Spot Count',
  'campaign.REACH': 'Reach',
  'campaign.REACH_PC': 'Reach %',
  'campaign.UNIQUE_REACH': 'Unique Reach',
  'converters.MEAN': 'Converters: Exposed Mean',
  'converters.LIFT': 'Converters: % Lift',
  'converters.COST_PER_INCREMENTAL_LIFT':
    'Converters: Cost per Incremental Converter',
  'converters.NETWORK': 'Conversion: Network',
  'converters.INCREMENTAL_LIFT': 'Converters: Incremental Lift',
  'converters.PERSUASION_INDEX': 'Converters: Persuasion Index',
  'converters.PERSUASION_INDEX_BY_COST': 'Converters: Ad Cost Persuasion Index',
  'conversions.MEAN': 'Exposed Mean',
  'conversions.LIFT': '% Lift',
  'conversions.NETWORK': 'Network',
  'conversions.INCREMENTAL_LIFT': 'Incremental Lift',
  'conversions.PERSUASION_INDEX': 'Persuasion Index',
  'conversions.EXPOSED_CONVERSIONS': 'Exposed Daily Conversions',
  'conversions.ALL_CONVERTED_HOUSEHOLDS': 'All Daily Conversions',
  'total_sales.MEAN': 'Total Sales: Exposed Mean',
  'total_sales.LIFT': 'Total Sales: % Lift',
  'total_sales.NETWORK': 'Total Sales: Network',
  'total_sales.INCREMENTAL_LIFT': 'Total Sales: Incremental Lift',
  'total_sales.PERSUASION_INDEX': 'Total Sales: Persuasion Index',
  'total_sales.EXPOSED_SALES': 'Exposed Daily Sales',
  'total_sales.ALL_EXPOSED_SALES': 'All Daily Sales',
  'sales_per_transaction.LIFT': 'Sales Per Transaction: % Lift',
  'sales_per_transaction.NETWORK': 'Sales Per Transaction: Network',
  'sales_per_transaction.INCREMENTAL_LIFT':
    'Sales Per Transaction: Incremental Lift',
  'sales_per_transaction.PERSUASION_INDEX':
    'Sales Per Transaction: Persuasion Index',
};

export const getMetricName = (
  accessor: string,
  conversionType?: string,
  useEquivalizedMetrics?: boolean,
): string => {
  if (accessor.includes('conversions')) {
    return `${startCase(conversionType ?? '')}: ${MetricNamesMap[accessor]}`;
  }

  const equvalizedMetricList = [
    'campaign.IMPRESSIONS',
    'campaign.FREQUENCY',
    'campaign.SPOT_COUNT',
  ];
  if (useEquivalizedMetrics && equvalizedMetricList.includes(accessor)) {
    return `${MetricNamesMap[accessor + '_EQ']}` ?? MetricNamesMap[accessor];
  }
  return MetricNamesMap[accessor] ?? startCase(toLower(startCase(accessor)));
};
