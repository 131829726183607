import { IMappedChartData } from '../../../types/multiChartTransformers';
import {
  ResultBase,
  SampleGroupNames,
  ISeriesDataItem,
} from '../../../domains/reports/types';
import { IChartConfig } from '../../charts/commonChartDesignConfig';
import { getSeriesDataItem } from '../../charts/getSeriesDataItem';
import { getClass } from '../../components';
import { IOption } from '../../../types/textValue';
import { getColumnSeries } from './getColunmSeries';
import { xAxisLabelFormatter } from './xAxisLabelFormartter';
import { getCategories } from './getCategories';

export interface ITargetFilters {
  target: IOption[];
  percentileIndex: number;
}

export interface IChartTargetItem {
  REACH_PC: ISeriesDataItem[];
}

const plotlineClass = getClass('exposure-conversion-latency', {
  concat: ['plot-line'],
});

const DEFAULT_SAMPLE_GROUPS = {
  genpop: [],
  target: [],
};

export const mapDataToChartConfig = ({
  exposed_converted,
}: ResultBase): IMappedChartData<IChartTargetItem> => {
  const adLatency = exposed_converted?.AD_LATENCY ?? DEFAULT_SAMPLE_GROUPS;
  const targetKeys = Object.keys(adLatency) as SampleGroupNames[];

  const dataByTarget = targetKeys.reduce(
    (acc, target) => {
      const adLatencyData = adLatency?.[target];
      return {
        ...acc,
        [target]: {
          REACH_PC: getSeriesDataItem('REACH_PC', adLatencyData),
        },
      };
    },
    {} as { [key in SampleGroupNames]: IChartTargetItem },
  );

  return {
    categories: getCategories(exposed_converted?.AD_LATENCY?.genpop ?? []),
    ...dataByTarget,
  };
};

export const getChartConfig = (
  chartData: IMappedChartData<IChartTargetItem>,
  filters: ITargetFilters,
): IChartConfig => {
  const { categories, ...data } = chartData;
  const { target, percentileIndex } = filters;

  return {
    chart: {
      height: 530,
      marginTop: 35,
      marginRight: 1,
      marginBottom: 80,
      spacingLeft: 0,
    },
    yAxis: {
      title: {
        text: '% of Exposed Converted Households',
      },
      labels: {
        format: '{value}%',
      },
    },
    xAxis: {
      categories,
      title: {
        text: 'Days Between Last Ad Exposure and Conversion',
      },
      labels: {
        formatter: xAxisLabelFormatter,
      },
      plotLines: [
        {
          value: percentileIndex,
          className: plotlineClass,
          color: '#383B41',
          zIndex: 4,
        },
      ],
    },
    plotOptions: {
      column: {
        minPointLength: 2,
      },
    },
    series: getColumnSeries<IChartTargetItem>(
      data,
      target,
      '% of Exposed Converters',
      'REACH_PC',
    ),
  };
};
