import IDimension, {
  Groups as DimensionsGroups,
} from 'domains/dimensions/types';
import IMetric, { Metrics } from 'domains/metrics/types';
import IReport, {
  ReportDatasetsSelections,
  ReportDatasetSelection,
  IAttributionReport,
} from 'domains/reports/types';
import { surface as surfaceRules } from 'helpers/general';
import { AccumulatedEntries, getAccumulatedEntries } from 'hooks/helpers';
import { flow, get, isEmpty } from 'lodash/fp';
import { useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Index, Actions } from 'routes';
import {
  setReportList as setReportsAction,
  setTargetList as setTargetsAction,
} from 'store/actions/root';
import { IDateRange } from 'types/dateRange';
import FetchMethod from 'types/fetchMethod';
import { RuleFilter } from 'types/filter';
import { Modes, Mode, IQuery } from 'types/query';
import State from 'types/state';
import { IMeta } from '../types/apiReturn';
import IError from '../types/error';
import useCurrentUser from './useCurrentUser';
import useFetch from './useFetch';

interface IFetchResults<T> {
  onError: (error: IError) => void;
  onSuccess: (result: T, meta?: IMeta<T> | undefined) => void;
}

interface IFetchReports extends Omit<IFetchResults<IReport[]>, 'onSuccess'> {
  onSuccess: (result: IReport[], meta?: IMeta<IReport> | undefined) => void;
  clientExtID: string;
  simple?: boolean;
}

interface IFetchTargets extends IFetchResults<IReport[]> {
  clientExtID: string;
}

interface IToggleReportPublicPrivate
  extends Omit<IFetchResults<IReport | IAttributionReport>, 'onSuccess'> {
  onSuccess: (report: IReport | undefined) => void;
  id: string;
}

interface IFetchReportById
  extends Omit<IFetchResults<IReport | IAttributionReport>, 'onSuccess'> {
  onSuccess: (report: IReport, meta: IMeta<IReport> | undefined) => void;
  id: string;
}

export type HookReturn = {
  areQueryRulesValid: (query: IQuery) => boolean;
  canQueryBeExecuted: (query: IQuery) => boolean;
  fetchReportById: (fetchOptions: IFetchReportById) => void;
  fetchReports: (fetchOptions: IFetchReports) => void;
  fetchTargets: (fetchOptions: IFetchTargets) => void;
  fetchedReport: boolean;
  getDatasetSelection: (
    queryDatasets?: string[],
    queryMode?: Mode,
  ) => ReportDatasetSelection;
  getReport: (id?: string) => IReport | undefined;
  getSaveAsCopyReportName: (name?: string, placeholder?: string) => string;
  hasFetchedReports: boolean;
  hasQueryAdsDatasets: (datasets?: string[], mode?: Mode) => boolean;
  hasQueryContentDatasets: (mode?: Mode) => boolean;
  hasQueryCustomAdsDatasets: (datasets?: string[], mode?: Mode) => boolean;
  hasQueryCustomExposureDatasets: (datasets?: string[], mode?: Mode) => boolean;
  indexedReports: AccumulatedEntries;
  isCumulative: (query?: IQuery) => boolean;
  isAQueryValid: (
    query: IQuery,
    datasetMode: ReportDatasetSelection,
  ) => boolean;
  isLoadingTargets: boolean;
  isLoadingToggleReportPublicPrivate: boolean;
  isReportNameToSaveReportAsCopyValid: (reportCopyName?: string) => boolean;
  isReportNameValid: (reportName?: string) => boolean;
  loading: boolean;
  newReportNamePlaceholder: string;
  reports: IReport[];
  resetReports: () => void;
  setReports: (reports: IReport[]) => void;
  shoulDimensiondOrRuleBeRemoveFromQueryWithDatasetAds: (
    dimension?: IDimension | RuleFilter | IDateRange,
  ) => boolean;
  shouldDimensionBeRemovedFromQueryWithDatasetContent: (
    dimension?: IDimension,
  ) => boolean;
  shouldMetricBeRemovedFromQueryWithDatasetContent: (
    metric?: IMetric,
  ) => boolean;
  toggleReportPublicPrivate: (fetchOptions: IToggleReportPublicPrivate) => void;
};

const useReports = (isTarget?: boolean): HookReturn => {
  const elementType = isTarget ? 'targets' : 'reports';

  const newReportNamePlaceholder = !isTarget ? 'New Report' : 'New Audience';

  const { doFetch, loading } = useFetch<IReport>();

  const [isLoadingTargets, setIsLoadingTargets] = useState(false);
  const [
    isLoadingToggleReportPublicPrivate,
    setIsLoadingToggleReportPublicPrivate,
  ] = useState(false);

  const reports = useSelector(
    ({ domains: { [elementType]: reportsState } }: State) => reportsState,
  );

  const { selectedClient } = useCurrentUser();

  const [hasFetchedReports, setFetchedReports] = useState(false);
  const [fetchedReport, setFetchedReport] = useState(false);

  const getReport = (reportId?: string): IReport | undefined =>
    reports?.find((report: IReport) => report.id === reportId);

  const hasQueryContentDatasets = (mode?: Mode): boolean => {
    if (!mode) {
      return false;
    }

    return mode === Modes.linear;
  };

  const hasQueryAdsDatasets = (datasets?: string[], mode?: Mode): boolean => {
    if (!mode) {
      return false;
    }

    return mode === Modes.adverts && (!datasets || datasets?.length === 0);
  };

  const hasQueryCustomAdsDatasets = (
    datasets?: string[],
    mode?: Mode,
  ): boolean => {
    if (!datasets || !mode) {
      return false;
    }

    return mode === Modes.adverts && datasets?.length > 0;
  };

  const hasQueryCustomExposureDatasets = (
    datasets?: string[],
    mode?: Mode,
  ): boolean => {
    if (!datasets || !mode) {
      return false;
    }

    return mode === Modes.genericEvents && datasets?.length > 0;
  };

  const getDatasetSelection = (
    queryDatasets?: string[],
    queryMode?: Mode,
  ): ReportDatasetSelection => {
    const queryHasContentDatasets = hasQueryContentDatasets(queryMode);

    if (queryHasContentDatasets) {
      return ReportDatasetsSelections.content;
    }

    const queryHasCustomAdsDatasets = hasQueryCustomAdsDatasets(
      queryDatasets,
      queryMode,
    );

    if (queryHasCustomAdsDatasets) {
      return ReportDatasetsSelections.customAds;
    }

    const queryHasAdsDatasets = hasQueryAdsDatasets(queryDatasets, queryMode);

    if (queryHasAdsDatasets) {
      return ReportDatasetsSelections.ads;
    }

    return ReportDatasetsSelections.content;
  };

  const getSaveAsCopyReportName = (name?: string): string => {
    if (!name) {
      return `Copy of ${newReportNamePlaceholder}`;
    }

    return `Copy of ${name}`;
  };

  const isReportNameToSaveReportAsCopyValid = (
    reportCopyName?: string,
  ): boolean =>
    reportCopyName !== undefined &&
    reportCopyName !== getSaveAsCopyReportName() &&
    reportCopyName !== '' &&
    reportCopyName !== newReportNamePlaceholder;

  const isReportNameValid = (reportCopyName?: string): boolean =>
    reportCopyName !== undefined &&
    reportCopyName !== newReportNamePlaceholder &&
    reportCopyName !== '';

  const areQueryRulesValid = (query: IQuery): boolean => {
    const children = get('filters.children', query);
    const isNestedSchema = children && !isEmpty(get('[0].children', children));
    const filters = isNestedSchema ? surfaceRules(children) : children;
    const allRules: RuleFilter[] = filters.filter(
      (queryFilter: RuleFilter) => !!queryFilter?.filterId,
    );
    const validRules = allRules.filter(
      ({ field, id, operator, filterId, type, value, start, end }) =>
        [field, id, operator, filterId, type].every(
          (queryFilterElem) => queryFilterElem,
        ) &&
        ((start && end) || (value && !Array.isArray(value)) || value?.length),
    );
    return allRules.length === validRules.length;
  };

  const isCumulative = flow(get('cumulative_group'), Boolean);

  const isAQueryValid = (
    query: IQuery,
    datasetSelection: ReportDatasetSelection,
  ): boolean => {
    if (
      datasetSelection === ReportDatasetsSelections.content &&
      hasQueryContentDatasets(query.mode) &&
      areQueryRulesValid(query)
    ) {
      return true;
    }

    if (
      datasetSelection === ReportDatasetsSelections.ads &&
      hasQueryAdsDatasets(query.datasets, query.mode) &&
      areQueryRulesValid(query)
    ) {
      return true;
    }

    if (
      datasetSelection === ReportDatasetsSelections.customAds &&
      hasQueryCustomAdsDatasets(query.datasets, query.mode) &&
      areQueryRulesValid(query)
    ) {
      return true;
    }

    return false;
  };

  const canQueryBeExecuted = (query: IQuery): boolean =>
    !!query?.select?.length && areQueryRulesValid(query);

  const shouldMetricBeRemovedFromQueryWithDatasetContent = (
    metric?: IMetric,
  ): boolean => {
    const isImpressionsOrFrequency =
      metric?.id === Metrics.impressions || metric?.id === Metrics.frequency;
    const isEnabledForSelectedDataset = !!metric?.hideFor?.includes(
      'dataset_selection::content',
    );
    return (
      (metric && (isImpressionsOrFrequency || isEnabledForSelectedDataset)) ||
      false
    );
  };

  const shouldDimensionBeRemovedFromQueryWithDatasetContent = (
    dimension?: IDimension,
  ): boolean =>
    (dimension?.group && dimension.group === DimensionsGroups.ads) || false;

  const shoulDimensiondOrRuleBeRemoveFromQueryWithDatasetAds = (
    dimensionOrRule?: IDimension | RuleFilter | IDateRange,
  ): boolean => {
    let shouldBeRemoved = false;

    if (!dimensionOrRule?.id) return shouldBeRemoved;

    shouldBeRemoved =
      dimensionOrRule.id === 'EPISODE_START_TIME' ||
      dimensionOrRule.id === 'EPISODE_END_TIME' ||
      dimensionOrRule.id === 'EPISODE_DURATION';

    return shouldBeRemoved;
  };

  const dispatch = useDispatch();

  const indexedReports: AccumulatedEntries = useMemo(
    () => getAccumulatedEntries(reports ?? []),
    [reports],
  );

  const setFunction = isTarget ? setTargetsAction : setReportsAction;

  const setReports = useCallback(
    (reportsToSet: IReport[]): void => {
      dispatch(setFunction(reportsToSet));
    },
    [dispatch, setFunction],
  );

  const fetchReports = useCallback(
    ({ simple = false, clientExtID, onSuccess, onError }: IFetchReports) => {
      setFetchedReports(true);
      doFetch({
        endpoint: `/${Index.SEGMENT_REPORTS}/${
          simple ? 'simple' : ''
        }?clientExtID=${clientExtID}`,
        method: FetchMethod.GET,
        onSuccess,
        onError,
      });
    },
    [doFetch],
  );

  const fetchReportById = useCallback(
    ({ id: reportId, onSuccess, onError }: IFetchReportById) => {
      setFetchedReport(true);
      doFetch({
        endpoint: `/${Index.SEGMENT_REPORTS}/${reportId}`,
        payload: {
          clientId: selectedClient,
        },
        method: FetchMethod.POST,
        onSuccess: (
          res: IReport[] | IAttributionReport[],
          meta?: IMeta<IReport>,
        ) => {
          onSuccess(res[0], meta);
        },
        onError: (error: IError) => onError(error),
      });
    },
    [doFetch, selectedClient],
  );

  const fetchTargets = useCallback(
    ({ clientExtID, onSuccess, onError }: IFetchTargets) => {
      setIsLoadingTargets(true);
      setFetchedReports(true);
      doFetch({
        endpoint: `/${Index.SEGMENT_REPORTS}/targets/?clientExtID=${clientExtID}`,
        payload: {},
        method: FetchMethod.GET,
        onSuccess: (res: IReport[]) => {
          onSuccess(res);
          setIsLoadingTargets(false);
        },
        onError: (error: IError) => {
          onError(error);
          setIsLoadingTargets(false);
        },
      });
    },
    [doFetch],
  );

  const toggleReportPublicPrivate = useCallback(
    ({ id: reportId, onSuccess, onError }: IToggleReportPublicPrivate) => {
      setIsLoadingToggleReportPublicPrivate(true);

      doFetch({
        endpoint: `/${Index.SEGMENT_REPORTS}/${Actions.SEGMENT_TOGGLE_IS_PUBLIC}/${reportId}`,
        payload: {},
        method: FetchMethod.POST,
        onSuccess: (res: IReport[], meta?: IMeta<IReport>) => {
          setIsLoadingToggleReportPublicPrivate(false);
          onSuccess(meta?.changed);
        },
        onError: (error: IError) => {
          setIsLoadingToggleReportPublicPrivate(false);
          onError(error);
        },
      });
    },
    [doFetch],
  );

  const resetReports = (): void => setFetchedReports(false);

  return {
    areQueryRulesValid,
    canQueryBeExecuted,
    fetchReportById,
    fetchReports,
    fetchTargets,
    fetchedReport,
    getDatasetSelection,
    getReport,
    getSaveAsCopyReportName,
    hasFetchedReports,
    hasQueryAdsDatasets,
    hasQueryContentDatasets,
    hasQueryCustomAdsDatasets,
    hasQueryCustomExposureDatasets,
    indexedReports,
    isCumulative,
    isAQueryValid,
    isLoadingTargets,
    isLoadingToggleReportPublicPrivate,
    isReportNameToSaveReportAsCopyValid,
    isReportNameValid,
    loading,
    newReportNamePlaceholder,
    reports: reports ?? [],
    resetReports,
    setReports,
    shoulDimensiondOrRuleBeRemoveFromQueryWithDatasetAds,
    shouldDimensionBeRemovedFromQueryWithDatasetContent,
    shouldMetricBeRemovedFromQueryWithDatasetContent,
    toggleReportPublicPrivate,
  };
};

export default useReports;
