import { isAttributionReport } from 'domains/reports/adapters/general';
import IReport, { IAttributionReport } from 'domains/reports/types';
import { fetchApi } from 'helpers/fetching';
import { showErrorToast } from 'helpers/general';
import { isEmpty } from 'lodash/fp';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Actions, Index } from 'routes';
import { IStringDateRange } from 'types/dateRange';
import State from 'types/state';
import FetchMethod from '../../../types/fetchMethod';
import * as reportReducer from '../../reducers/report';
import {
  setReportQueryDatasets,
  setReportAction,
  setReportQueryFilterStartDate,
  setReportQueryFilterEndDate,
} from './index';
import * as domainSelectors from 'store/selectors/domains';
import * as reportSelectors from 'store/selectors/report';
import * as domainActions from 'store/actions/domains';
import { Mode, Modes } from 'types/query';
import { initialDomainsDateRanges } from 'store/reducers/businessData';
import { getDatasetDefaultDateRange } from 'domains/reports/adapters/date';

export const setReportQueryDatasetsAction =
  (
    datasets: string[],
    updatedReport?: IReport,
    preserveSelectedDates = false,
  ) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<IReport | undefined> => {
    dispatch(setReportQueryDatasets(datasets));
    const state = getState();
    let report = updatedReport ?? reportSelectors.getReport(state);
    const domainsDateRanges = domainSelectors.getDomainsDateRanges(state);
    if (datasets.length) {
      const apiResults = await fetchApi({
        endpoint: `/${Index.SEGMENT_DATASETS}/${Actions.SEGMENT_DATASET_DATERANGE}`,
        payload: datasets,
        method: FetchMethod.POST,
      });
      if (isEmpty(apiResults?.data)) {
        showErrorToast(apiResults?.error?.message ?? 'Report not found');
        return report;
      }
      const { startDate: startDateString, endDate: endDateString } = apiResults
        .data[0] as IStringDateRange;
      if (startDateString && endDateString) {
        if (!report) return undefined;
        if (!preserveSelectedDates) {
          if (isAttributionReport(report as IAttributionReport)) {
            report = reportReducer.setExposureFlightDateStart(
              startDateString,
              report,
            );
            report = reportReducer.setExposureFlightDateEnd(
              endDateString,
              report,
            );
          } else {
            report = reportReducer.setQueryFilterStartDate(
              startDateString,
              report,
            );
            report = reportReducer.setQueryFilterEndDate(endDateString, report);
          }
        }

        dispatch(
          domainActions.setDomainsDateRanges({
            ...domainsDateRanges,
            [Modes.customAdverts]: {
              min_date: startDateString,
              max_date: endDateString,
            },
          }),
        );
        await dispatch(setReportAction(report));
      }
    } else {
      if (!report) return undefined;
      dispatch(
        domainActions.setDomainsDateRanges({
          ...domainsDateRanges,
          [Modes.customAdverts]: initialDomainsDateRanges.MODE_CUSTOM_ADVERTS,
        }),
      );
      const state = getState();
      const { dateStart, dateEnd } = getDatasetDefaultDateRange(
        domainSelectors.getDomainsDateRanges(state),
        report.query.mode as Mode,
      );
      dispatch(setReportQueryFilterStartDate(dateStart));
      dispatch(setReportQueryFilterEndDate(dateEnd));
      await dispatch(setReportAction(report));
    }
    return report;
  };
