import DatePicker from 'components/DatePicker';
import { DropdownSearchQueryProvider } from 'components/DropdownSearch';
import FieldWrapper from 'components/Field/Wrapper';
import Flex, { Direction, Horizontal } from 'components/Flex';
import H4 from 'components/H4';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import {
  getTextValueArrayFromArray,
  getTextValueDatasets,
  injectDatasetControl,
} from 'components/QueryBuilder/adapters';
import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';
import {
  LOCALE_DATASETS_TOOLTIP,
  LOCALE_TARGET_TOOLTIP,
} from 'components/QueryBuilder/locale';
import RulesBuilder from 'components/RulesBuilder';
import SegmentedControl from 'components/SegmentedControl';
import { useDatasets } from 'domains/datasets/hooks';
import { getDatasetDefaultDateRange } from 'domains/reports/adapters/date';

import { isBaseReportSaving } from 'domains/reports/adapters/rulesets';
import {
  LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL,
  LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL_TOOLTIP,
  LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_PLACEHOLDER,
  LOCALE_QUERY_BUILDER_DATASETS_GENERIC_EVENTS_DROPDOWN_LABEL,
  LOCALE_QUERY_BUILDER_DATASETS_GENERIC_EVENTS_DROPDOWN_LABEL_TOOLTIP,
} from 'domains/reports/locales/datasets';
import { getClass, getId, getTestId } from 'helpers/components';
import { handleDropdownSearchSyncFilter } from 'helpers/utils';
import useUserPermissions from 'hooks/useUserPermissions';
import { get, isEmpty } from 'lodash/fp';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import {
  getQueryGenericEventsDatasets,
  getQueryMode,
  getQueryScheduleDatasets,
} from 'store/reducers/report';
import { IChildrenBase, IQueryFilters, RuleFilter } from 'types/filter';
import { Modes } from 'types/query';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { AttributionFilterType, reportSubType } from 'domains/reports/types';
import { curateDisplayedRules } from 'domains/reports/adapters/general';
import { getAttributionFiltersFromQueryFilters } from './adapters';
import { SharedNetworkOwnerRule } from './components/SharedNetworkOwnerRule.component';
import {
  ATTRIBUTION_TARGET_SELECT_LIMIT,
  DOM_KEY_OUTCOME,
  DOM_KEY_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN,
  DOM_KEY_QUERY_BUILDER_DATASETS_GENERIC_EVENTS_DROPDOWN,
  DOM_KEY_QUERY_BUILDER_DATASETS_SEGMENTED_CONTROL,
  DOM_KEY_TARGET,
  DOM_KEY_VIEWERSHIP,
} from './constants';
import {
  LOCALE_AD_DATE_RANGE_LABEL,
  LOCALE_AD_DATE_RANGE_LABEL_TOOLTIP,
  LOCALE_AD_DETAILS_LABEL,
  LOCALE_DATASETS_DATERANGE_LABEL,
  LOCALE_DATASETS_DATERANGE_LABEL_TOOPTIP,
  LOCALE_DATASETS_LABEL,
  LOCALE_EQ_METRIC_LABEL,
  LOCALE_EQ_METRIC_TOOLTIP,
  LOCALE_OUTCOME,
  LOCALE_OUTCOME_LABEL,
  LOCALE_OUTCOME_TOOLTIP,
  LOCALE_TARGET,
  LOCALE_TARGET_SELECT_TARGET,
  LOCALE_VIEWERSHIP_DETAILS_LABEL,
} from './locales';
import { IProps } from './types';

export const componentName = 'attribution-query-builder';

export const AttributionQueryBuilder: FunctionComponent<IProps> = (
  props: IProps,
): ReactElement => {
  const {
    availableDimensions,
    availableRulesDimensions,
    availableTargets,
    businessData,
    exposureFlightDates,
    id,
    testId,
    eqMetric,
    outcomeViewershipDates,
    report,
    setExposureAdFiltersAction,
    setQueryModeAction,
    addExposureAdFiltersAction,
    replaceExposureAdFiltersAction,
    deleteExposureAdFiltersAction,
    addOutcomeViewershipFiltersAction,
    replaceOutcomeViewershipFiltersAction,
    deleteOutcomeViewershipFiltersAction,
    addOutcomeBrandLiftFiltersAction,
    replaceOutcomeBrandLiftFiltersAction,
    deleteOutcomeBrandLiftFiltersAction,
    handleAttrReportsAdFlightDateChange,
    handleAttrReportsViewershipDetailsDateChange,
    handleChangeScheduleDataset,
    handleQueryBuilderEqMetricChange,
    handleChangeGenericEventsDataset,
    handleQueryBuilderTargetChange,
    subType,
    toolbar,
    isReportInvalid,
    messageList,
    addWarningMessage,
    deleteWarningMessage,
    disabled,
    domainsDateRanges,
  } = props;

  const { datasets, targets, filters } = report.query;
  const { isRunLaunched } = toolbar;
  const {
    dateMin: outcomeViewershipDateMin,
    dateMax: outcomeViewershipDateMax,
  } = getDatasetDefaultDateRange(domainsDateRanges, Modes.linear);
  const queryChildren = filters.children as IQueryFilters<IChildrenBase>[];
  const {
    getScheduleDatasetsForSelectedClient,
    getGenericEventDatasetsForSelectedClient,
  } = useDatasets();
  const curatedScheduleDatasets = useMemo(
    () => getTextValueDatasets(getScheduleDatasetsForSelectedClient()),
    [getScheduleDatasetsForSelectedClient],
  );

  const curatedGenericEventsDatasets = useMemo(
    () => getTextValueDatasets(getGenericEventDatasetsForSelectedClient()),
    [getGenericEventDatasetsForSelectedClient],
  );

  const componentId = useMemo(() => getId(componentName, id), [id]);
  const componentTestId = useMemo(
    () => getTestId(componentName, testId),
    [testId],
  );
  const componentClass = useMemo(() => getClass(componentName), []);
  const viewershipClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_VIEWERSHIP] }),
    [],
  );
  const outcomeClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_OUTCOME] }),
    [],
  );
  const targetClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_TARGET] }),
    [],
  );

  const { checkPermissions } = useUserPermissions();

  const canViewSharedNetworkOwnerRule = checkPermissions(
    'attribution_reports.exposures_ads.shared_network_owner::view',
  );
  const canViewEquivalizedToggle = checkPermissions(
    'attribution_reports.equivalized_metrics.toggle::view',
  );
  const canViewTargetsField = checkPermissions(
    'attribution_reports.target_selector::view',
  );

  const canEdit = checkPermissions('reports::update') && !disabled;

  const exposureAdsFilters = curateDisplayedRules(
    AttributionFilterType.adDetails,
    getAttributionFiltersFromQueryFilters(
      queryChildren,
      AttributionFilterType.adDetails,
      false,
      availableDimensions,
    ) as RuleFilter[],
    checkPermissions,
  );

  const outcomeViewershipFilters = curateDisplayedRules(
    AttributionFilterType.viewership,
    getAttributionFiltersFromQueryFilters(
      queryChildren,
      AttributionFilterType.viewership,
      false,
      availableDimensions,
    ) as RuleFilter[],
    checkPermissions,
  );

  const outcomeBrandLiftFilters = getAttributionFiltersFromQueryFilters(
    queryChildren,
    AttributionFilterType.brandLift,
    false,
    availableDimensions,
  ) as RuleFilter[];

  const realMode = getQueryMode(report);
  const queryBuilderDatasetsComponentName = 'query-builder-datasets';

  const queryBuilderDatasetsSegmentedControlClass = useMemo(
    () =>
      getClass(queryBuilderDatasetsComponentName, {
        concat: [DOM_KEY_QUERY_BUILDER_DATASETS_SEGMENTED_CONTROL],
      }),
    [],
  );

  const curatedAvailableTargets = availableTargets.map((target) => ({
    text: target.name ?? target.id,
    value: target.id,
  }));

  const hiddenRuleIDs =
    realMode === 'MODE_GENERIC_EVENTS'
      ? ['SHARED_NETWORK_OWNER', 'AD_TUNING_DURATION']
      : ['SHARED_NETWORK_OWNER'];

  const queryGenericEventsDatasets = getQueryGenericEventsDatasets(report);
  const customExposureValue = useMemo(
    () =>
      !isEmpty(queryGenericEventsDatasets)
        ? get('0.id', queryGenericEventsDatasets)
        : '',
    [queryGenericEventsDatasets],
  );
  const validateCustomAdsDropdown = useMemo((): string => {
    let message = '';
    let fieldIsInvalid = false;
    let messagePrefix = 'Custom Ads';

    if (realMode === Modes.customAdverts) {
      const customAdsDatasetValue = getTextValueArrayFromArray(
        getQueryScheduleDatasets(report),
        curatedScheduleDatasets,
      );
      fieldIsInvalid = !customAdsDatasetValue.length;
    } else if (realMode === Modes.genericEvents) {
      messagePrefix = 'Custom Exposures';
      fieldIsInvalid = !customExposureValue;
    }

    if (fieldIsInvalid) {
      message = `${messagePrefix} require at least one dataset.`;
      addWarningMessage({
        id: 'dataset_value',
        message,
        field: realMode,
      });
    } else {
      message = '';
      const valueIsInError = messageList.filter(
        (m) => m.id === 'dataset_value' && m.field === realMode,
      );
      if (valueIsInError.length) {
        deleteWarningMessage({
          id: 'dataset_value',
          field: realMode,
        });
      }
    }

    return message;
  }, [
    getQueryScheduleDatasets(report),
    addWarningMessage,
    deleteWarningMessage,
    realMode,
    messageList,
  ]);

  const movingDates = !(
    Modes.customAdverts === realMode || Modes.genericEvents === realMode
  );

  const filterDatasetsOptions: DropdownSearchQueryProvider = useCallback(
    (valueFilter = '') =>
      handleDropdownSearchSyncFilter(valueFilter, curatedScheduleDatasets),
    [curatedScheduleDatasets],
  );

  const filterGenericEventsDatasets: DropdownSearchQueryProvider = useCallback(
    (valueFilter = '') =>
      handleDropdownSearchSyncFilter(valueFilter, curatedGenericEventsDatasets),
    [curatedGenericEventsDatasets],
  );

  const datasetOptions = useMemo(
    () => injectDatasetControl(businessData, report),
    [businessData],
  );

  const { dateMin, dateMax } = getDatasetDefaultDateRange(
    domainsDateRanges,
    realMode,
  );

  return (
    <div
      id={componentId}
      data-testid={componentTestId}
      className={componentClass}
    >
      <section>
        <H4>{LOCALE_AD_DETAILS_LABEL}</H4>
        <FieldWrapper>
          <div className={queryBuilderDatasetsSegmentedControlClass}>
            <QueryBuilderItem
              label={LOCALE_DATASETS_LABEL}
              tooltip={LOCALE_DATASETS_TOOLTIP}
            >
              <SegmentedControl
                options={datasetOptions}
                onSelect={setQueryModeAction}
                selected={realMode}
                trackingId="datasets"
                disabled={!canEdit}
              />
            </QueryBuilderItem>
          </div>
        </FieldWrapper>
        {realMode === Modes.customAdverts && (
          <QueryBuilderItem
            label={
              LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL
            }
            tooltip={
              LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL_TOOLTIP
            }
          >
            <MultipleDropdownSelect
              ignoreGrouping
              errorText={validateCustomAdsDropdown}
              isReportInvalid={isReportInvalid}
              disabled={isRunLaunched || !canEdit || isBaseReportSaving(report)}
              options={curatedScheduleDatasets}
              onChange={handleChangeScheduleDataset}
              selected={getTextValueArrayFromArray(
                getQueryScheduleDatasets(report),
                curatedScheduleDatasets,
              )}
              trackingId="custom_ads"
              testId={DOM_KEY_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN}
              placeholder={
                LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_PLACEHOLDER
              }
              queryProvider={filterDatasetsOptions}
            />
          </QueryBuilderItem>
        )}
        {realMode === Modes.genericEvents && (
          <QueryBuilderItem
            label={LOCALE_QUERY_BUILDER_DATASETS_GENERIC_EVENTS_DROPDOWN_LABEL}
            tooltip={
              LOCALE_QUERY_BUILDER_DATASETS_GENERIC_EVENTS_DROPDOWN_LABEL_TOOLTIP
            }
          >
            <MultipleDropdownSelect
              withSearch
              options={curatedGenericEventsDatasets}
              errorText={validateCustomAdsDropdown}
              maximumChipsSelected={ATTRIBUTION_TARGET_SELECT_LIMIT}
              isReportInvalid={isReportInvalid}
              trackingId="custom_exposures"
              testId={DOM_KEY_QUERY_BUILDER_DATASETS_GENERIC_EVENTS_DROPDOWN}
              disabled={isRunLaunched || !canEdit || isBaseReportSaving(report)}
              onChange={handleChangeGenericEventsDataset}
              selected={getTextValueArrayFromArray(
                [customExposureValue],
                curatedGenericEventsDatasets,
              )}
              queryProvider={filterGenericEventsDatasets}
            />
          </QueryBuilderItem>
        )}
        <QueryBuilderItem
          label={LOCALE_AD_DATE_RANGE_LABEL}
          tooltip={LOCALE_AD_DATE_RANGE_LABEL_TOOLTIP}
        >
          <DatePicker
            disabled={isRunLaunched || !canEdit || isBaseReportSaving(report)}
            startDate={exposureFlightDates?.start}
            endDate={exposureFlightDates?.end}
            handleChange={handleAttrReportsAdFlightDateChange}
            limitToRange={!!(datasets?.length && datasets.length > 0)}
            maxDate={dateMax}
            minDate={dateMin}
            showNumOfDays
            trackingId="Attribution_Report"
            testId="ad-flight-dates"
            movingDates={movingDates}
            relativeDateOffset={exposureFlightDates?.relativeDateOffset}
          />
        </QueryBuilderItem>
        <QueryBuilderItem label="">
          <RulesBuilder
            datasets={datasets}
            disabled={isRunLaunched || !canEdit}
            fixed
            dimensions={availableDimensions}
            canAddRules={false}
            canDeleteRules={false}
            filters={exposureAdsFilters}
            section="adDetails"
            testId="ad-details"
            ruleIdsToHide={hiddenRuleIDs}
            addItem={addExposureAdFiltersAction}
            replaceItem={replaceExposureAdFiltersAction}
            deleteItem={deleteExposureAdFiltersAction}
          />
        </QueryBuilderItem>
        {canViewSharedNetworkOwnerRule && realMode !== Modes.genericEvents && (
          <SharedNetworkOwnerRule
            exposureAdsFilters={exposureAdsFilters}
            setExposureAdFiltersAction={setExposureAdFiltersAction}
            availableDimensions={availableDimensions}
            disabled={!canEdit}
            messageList={messageList}
            deleteWarningMessage={deleteWarningMessage}
          />
        )}
        {canViewEquivalizedToggle && realMode !== Modes.genericEvents && (
          <QueryBuilderItem
            label={LOCALE_EQ_METRIC_LABEL}
            tooltip={LOCALE_EQ_METRIC_TOOLTIP}
          >
            <Flex direction={Direction.row} horizontal={Horizontal.left}>
              <ToggleSwitch
                name="toggle-eq-metric-switch"
                checked={eqMetric}
                testId="eq-metric-switch"
                onChange={handleQueryBuilderEqMetricChange}
                trackingId="EQ_METRIC"
                yesLabel="On"
                noLabel="Off"
              />
            </Flex>
          </QueryBuilderItem>
        )}
      </section>
      {
        /* Viewership Details */
        subType === reportSubType.tvTuneIn.key && (
          <section className={viewershipClass}>
            <H4>{LOCALE_VIEWERSHIP_DETAILS_LABEL}</H4>
            <QueryBuilderItem
              label={LOCALE_DATASETS_DATERANGE_LABEL}
              tooltip={LOCALE_DATASETS_DATERANGE_LABEL_TOOPTIP}
            >
              <DatePicker
                disabled={isRunLaunched || !canEdit}
                handleChange={handleAttrReportsViewershipDetailsDateChange}
                startDate={outcomeViewershipDates.start}
                endDate={outcomeViewershipDates.end}
                minDate={outcomeViewershipDateMin}
                maxDate={outcomeViewershipDateMax}
                trackingId="Viewership details"
                testId="viewership-dates"
                showNumOfDays
                movingDates={movingDates}
                relativeDateOffset={outcomeViewershipDates.relativeDateOffset}
              />
            </QueryBuilderItem>
            <QueryBuilderItem label="">
              <RulesBuilder
                disabled={isRunLaunched || !canEdit}
                fixed
                dimensions={availableDimensions}
                canAddRules={false}
                canDeleteRules={false}
                filters={outcomeViewershipFilters}
                section="viewershipDetails"
                testId="viewership"
                addItem={addOutcomeViewershipFiltersAction}
                replaceItem={replaceOutcomeViewershipFiltersAction}
                deleteItem={deleteOutcomeViewershipFiltersAction}
              />
            </QueryBuilderItem>
          </section>
        )
      }
      {
        /* Outcome Details */
        subType === reportSubType.outcome.key && (
          <section className={outcomeClass}>
            <H4>{LOCALE_OUTCOME_LABEL}</H4>
            <QueryBuilderItem
              label={LOCALE_OUTCOME}
              labelAlignment="top"
              tooltip={LOCALE_OUTCOME_TOOLTIP}
            >
              <RulesBuilder
                disabled={isRunLaunched || !canEdit}
                dimensions={availableRulesDimensions}
                maxRules={1}
                canDeleteRules={false}
                filters={outcomeBrandLiftFilters}
                placeholder="Add Outcome"
                testId="outcome"
                minDate={exposureFlightDates?.start}
                maxDate={exposureFlightDates?.end}
                addItem={addOutcomeBrandLiftFiltersAction}
                replaceItem={replaceOutcomeBrandLiftFiltersAction}
                deleteItem={deleteOutcomeBrandLiftFiltersAction}
              />
            </QueryBuilderItem>
          </section>
        )
      }
      {/* Target */}
      <section className={targetClass}>
        {canViewTargetsField && (
          <QueryBuilderItem
            label={LOCALE_TARGET}
            tooltip={LOCALE_TARGET_TOOLTIP}
          >
            <Flex direction={Direction.row} horizontal={Horizontal.left}>
              <MultipleDropdownSelect
                withSearch
                options={curatedAvailableTargets}
                placeholder={LOCALE_TARGET_SELECT_TARGET}
                onChange={handleQueryBuilderTargetChange}
                selected={getTextValueArrayFromArray(
                  targets ?? [],
                  curatedAvailableTargets,
                )}
                trackingId="target"
                disabled={isRunLaunched || !canEdit}
                testId="target"
                maximumChipsSelected={ATTRIBUTION_TARGET_SELECT_LIMIT}
              />
            </Flex>
          </QueryBuilderItem>
        )}
      </section>
    </div>
  );
};
