import { useAuth0 } from '@auth0/auth0-react';
import { Canceler } from 'axios';
import { appendClientExtID, secureFetch } from 'helpers/fetching';
import React, { useReducer, useEffect, useRef, useCallback } from 'react';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import { v4 as uuid } from 'uuid';
import { IMeta } from '../types/apiReturn';
import useCurrentUser from './useCurrentUser';

export type DoFetchProps<T, U = void> = {
  endpoint: string;
  payload?: object;
  method?: FetchMethod;
  onSuccess?: (data: T[], meta?: IMeta<T, U>) => void;
  onError?: (error: IError) => void;
  onResolve?: () => void;
  isDownload?: boolean;
};

type State<T, U = void> = {
  loading: boolean;
  data: T[];
  error?: IError;
  fetchId: string;
  canceler?: Canceler;
} & DoFetchProps<T, U>;

const initState = {
  data: [],
  loading: false,
  endpoint: '',
  payload: {},
  fetchId: '',
};

export type UseFetchReturn<T, U = void> = {
  loading: boolean;
  data: T[];
  error?: IError;
  doFetch: ({ endpoint, payload, method }: DoFetchProps<T, U>) => void;
  doCancel: () => void;
};

type Action<T, U = void> =
  | { type: 'begin request'; payload: DoFetchProps<T, U> & { fetchId: string } }
  | { type: 'request launched'; canceler: Canceler }
  | { type: 'success'; payload: T[] }
  | { type: 'failure'; error: string };

function reducer<T, U = void>(
  state: State<T, U>,
  action: Action<T, U>,
): State<T, U> {
  switch (action.type) {
    case 'begin request':
      return {
        ...initState,
        ...action.payload,
      };
    case 'request launched':
      return {
        ...state,
        loading: true,
        canceler: action.canceler,
      };
    case 'success':
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case 'failure':
      return {
        endpoint: '',
        loading: false,
        fetchId: '',
        data: [],
      };
    default:
      return state;
  }
}

function useFetch<T, U = void>(): UseFetchReturn<T, U> {
  const [
    {
      loading,
      error,
      data,
      endpoint,
      method,
      onSuccess,
      onError,
      onResolve,
      fetchId,
      payload,
      canceler,
      isDownload,
    },
    dispatch,
  ] = useReducer<React.Reducer<State<T, U>, Action<T, U>>>(reducer, initState);
  const { getAccessTokenSilently, logout } = useAuth0();
  const { token, setToken, selectedClient, currentLoggedUser } =
    useCurrentUser();
  const userClients = currentLoggedUser?.clients ?? [];
  const selectedClientExtID =
    (userClients ?? []).find((client) => client.id === selectedClient)?.extID ??
    '';

  const previousFetchId = useRef('');

  const doCancel = useCallback(() => {
    if (canceler) {
      canceler();
    }
  }, [canceler]);

  useEffect(() => {
    if (
      !loading &&
      endpoint &&
      fetchId &&
      previousFetchId.current !== fetchId
    ) {
      previousFetchId.current = fetchId;

      const fetchData = async (): Promise<void> => {
        let accessToken;
        if (!token) {
          try {
            accessToken = await getAccessTokenSilently({
              authorizationParams: {
                scope: 'read:current_user',
              },
            });
          } catch (e) {
            logout();
          }
        } else {
          accessToken = token;
          setToken(token);
        }

        const [promise, currentCanceler] = secureFetch<T, U>({
          endpoint: appendClientExtID(endpoint, selectedClientExtID),
          payload,
          accessToken,
          method,
          isDownload,
          selectedClient,
        });
        dispatch({ type: 'request launched', canceler: currentCanceler });
        const results = await promise;

        if (results?.error?.message) {
          dispatch({
            type: 'failure',
            error: results.error.message,
            ...(results?.error?.meta && { meta: results.error.meta }),
          });
          if (onError) {
            onError(results.error);
          }
        } else {
          dispatch({
            type: 'success',
            payload: Array.isArray(results.data)
              ? results.data
              : [results.data],
          });
          if (onSuccess) {
            if (results.meta) {
              onSuccess(results.data, results.meta);
            } else {
              onSuccess(results.data);
            }
          }
        }
        if (onResolve) {
          onResolve();
        }
      };

      fetchData();
    }
  }, [
    endpoint,
    method,
    onSuccess,
    onError,
    fetchId,
    payload,
    doCancel,
    loading,
    logout,
    isDownload,
    getAccessTokenSilently,
    token,
    setToken,
    onResolve,
    selectedClient,
    selectedClientExtID,
  ]);

  useEffect(
    () => (): void => {
      if (loading) {
        doCancel();
      }
    },
    [doCancel, loading],
  );

  const doFetch = useCallback((props: DoFetchProps<T, U>): void => {
    dispatch({
      type: 'begin request',
      payload: {
        ...props,
        fetchId: uuid(),
      },
    });
  }, []);

  return {
    loading,
    data,
    error,
    doFetch,
    doCancel,
  };
}

export default useFetch;
