export const LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL =
  'Custom Ads';
export const LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL_TOOLTIP =
  'Allows a user to generate a report based on a client specific custom ad schedule.';

export const LOCALE_QUERY_BUILDER_DATASETS_GENERIC_EVENTS_DROPDOWN_LABEL =
  'Custom Exposures';
export const LOCALE_QUERY_BUILDER_DATASETS_GENERIC_EVENTS_DROPDOWN_LABEL_TOOLTIP =
  'A group of potential customers that you identify to sell products or services to. Each group can be divided into smaller segments. Segments are typically grouped by age, location, income and lifestyle.';

export const LOCALE_QUERY_BUILDER_DATASETS_GENERIC_EVENTS_DROPDOWN_PLACEHOLDER =
  'Select Custom Exposures Segment';
export const LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_PLACEHOLDER =
  'Select Custom Ads Schedules';
export const LOCALE_QUERY_BUILDER_DATASETS_CONFIRMATION_DIALOG_TITLE =
  'Notification';
export const LOCALE_QUERY_BUILDER__DATASETS_CONFIRMATION_DIALOG_CONFIRM_LABEL =
  'OKAY';
export const LOCALE_QUERY_BUILDER_DATASETS_CONTENT_CONFIRMATION_DIALOG_MESSAGE_ONE = `
  By selecting this option, the Content dataset will be used to run the report.
`;
export const LOCALE_QUERY_BUILDER_DATASETS_ADS_CONFIRMATION_DIALOG_MESSAGE_ONE = `
  By selecting this option, the Ads dataset will be used to run the report.
`;
export const LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_CONFIRMATION_DIALOG_MESSAGE_ONE = `
  By selecting this option, the Custom Ads dataset will be used to run the report.
`;
export const LOCALE_QUERY_BUILDER_DATASETS_CONTENT_CONFIRMATION_DIALOG_MESSAGE_TWO = `
  The following Metrics, Dimensions and Rules will be removed from the report:
`;

export const LOCALE_DATASET_CHANGE_NO_FEEDBACK_NEEDED =
  'You have changed your Dataset. Some available configurations may have changed.';

export const LOCALE_QUERY_BUILDER_DATASETS_NEW_DATE_INVALID = `
  Data is not available for some of the selected dates in the date range. Dates will be adjusted based on available data.
`;
