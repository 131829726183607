export const DOM_KEY_QUERY_BUILDER_DATASETS_SEGMENTED_CONTROL =
  'segmented-control';
export const DOM_KEY_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN =
  'custom-ads-datasets';
export const DOM_KEY_BODY = 'body';
export const DOM_KEY_FOOTER = 'footer';
export const TARGET_SELECT_LIMIT = 10;
export const TOGGLE_VIEW_TABLE = 'Show Table Results';
export const TOGGLE_VIEW_TABLE_TOOLTIP =
  'Flags whether the report results should show the table';
export const TOGGLE_VIEW_CHART = 'Show Chart Results';
export const TOGGLE_VIEW_CHART_TOOLTIP =
  'Flags whether the report results should show the visualizations';
export const TOGGLE_VIEW_VALUE_ON = 'On';
export const TOGGLE_VIEW_VALUE_OFF = 'Off';
