import React, { useRef } from 'react';
import State from 'types/state';
import { connect } from 'react-redux';
import {
  getAttributionReportSpecializedInsightsData,
  getAttributionReportSpecializedInsightsTarget,
  getAttributionReportSpecializedInsightsTargetOptions,
} from 'store/selectors/reportResult';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Flex, { Vertical } from 'components/Flex';
import { getClass } from 'helpers/components';
import { get, isEmpty } from 'lodash';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import { IOption } from 'types/textValue';
import { handleSpecializedInsightsTargetChange } from 'store/actions/reportResult';
import { IHighchartsSeriesData } from 'domains/reports/types';
import { waterfallChartConfig } from 'helpers/charts/waterfallChartConfig';

interface ISpecializedInsightsState {
  specializedInsightsData: IHighchartsSeriesData[];
  specializedInsightsTargetOptions: IOption[];
  specializedInsightsSelectedTarget: IOption[];
}

interface ISpecializedInsightsProps {
  setTarget: (target: IOption[]) => void;
}

const chartFiltersComponentName = 'chart-filters-wrapper';
const chartFiltersClass = getClass(chartFiltersComponentName);
const containerClass = getClass(chartFiltersComponentName, {
  concat: ['container'],
});
const headerClass = getClass(chartFiltersComponentName, {
  concat: ['header'],
});
const filtersClass = getClass(chartFiltersComponentName, {
  concat: ['filters'],
});
const labelClass = getClass(chartFiltersComponentName, {
  concat: ['label'],
});

const SpecializedInsights: React.FC<
  ISpecializedInsightsState & ISpecializedInsightsProps
> = ({
  specializedInsightsData,
  specializedInsightsTargetOptions,
  specializedInsightsSelectedTarget,
  setTarget,
}) => {
  const color = get(specializedInsightsSelectedTarget, '0.color', '#ffffff');
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const options = {
    ...waterfallChartConfig,
  };

  if (isEmpty(specializedInsightsData)) return null;

  return (
    <section className={chartFiltersClass}>
      <Flex vertical={Vertical.between} className={containerClass}>
        <Flex vertical={Vertical.between}>
          <header>
            <h3 className={headerClass}>Incremental Reach by Platform</h3>
          </header>
        </Flex>
      </Flex>
      <HighchartsReact
        allowCharUpdate
        highcharts={Highcharts}
        options={{
          ...options,
          series: [
            {
              data: specializedInsightsData,
              color,
            },
          ],
        }}
        ref={chartRef}
        oneToOn
      />
      <div className={filtersClass}>
        <div>
          <label className={labelClass}>Audiences</label>
          <MultiSelectDropdown
            options={specializedInsightsTargetOptions ?? []}
            selected={specializedInsightsSelectedTarget ?? []}
            placeholder="Choose audience"
            showLegend
            onChange={(selectedTargets) => setTarget(selectedTargets)}
          />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: State): ISpecializedInsightsState => ({
  specializedInsightsData: getAttributionReportSpecializedInsightsData(state),
  specializedInsightsTargetOptions:
    getAttributionReportSpecializedInsightsTargetOptions(state),
  specializedInsightsSelectedTarget:
    getAttributionReportSpecializedInsightsTarget(state),
});

const mapDispatchToProps = {
  setTarget: handleSpecializedInsightsTargetChange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpecializedInsights);
