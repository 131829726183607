import createChartConfigWithCommonDesign from 'helpers/charts/commonChartDesignConfig';
import renderCustomPlotLine from 'helpers/charts/renderCustomPlotline';
import Highcharts from 'highcharts';
import { merge } from 'lodash';
import React from 'react';
import { ChartPosition } from 'types/chartPosition';
import { commonMultichartConfig } from '../commonMultichartConfig';
import {
  IMultiChartConfig,
  IMultiChartOptions,
  ICustomPlotlineRef,
  IMultiChartRefs,
} from 'types/MultiChart';
import { handleChartTooltips } from './handleChartsTooltips';

export const useMultiChartConfig = ({
  chartConfig,
  chartRefs,
  setPlotBoundaries,
}: {
  chartConfig: IMultiChartConfig;
  chartRefs: React.MutableRefObject<IMultiChartRefs>;
  setPlotBoundaries: React.Dispatch<
    React.SetStateAction<{
      top: {
        top: number;
        left: number;
        width: number;
        height: number;
      };
      bottom: {
        top: number;
        left: number;
        width: number;
        height: number;
      };
    }>
  >;
}): IMultiChartOptions => {
  const { current: customPlotLineRefs } = React.useRef<ICustomPlotlineRef>({
    top: undefined,
    bottom: undefined,
  });

  const computeChartConfig = (
    chartRefs: React.MutableRefObject<IMultiChartRefs>,
    chartConfig: IMultiChartConfig,
    customPlotLineRefs: ICustomPlotlineRef,
  ): IMultiChartOptions => {
    const { charts, shared } = chartConfig;
    return Object.entries(charts).reduce(
      (acc, [chartPosition, chartConfig]) => {
        const { series, yAxis, ...rest } = chartConfig;
        const position = chartPosition as ChartPosition;
        const oppositeChart =
          position === ChartPosition.Bottom
            ? ChartPosition.Top
            : ChartPosition.Bottom;

        const rerenderPlotline = (chart: Highcharts.Chart): void => {
          const plotlineValue = chart.xAxis[0].options.plotLines?.[0].value;
          if (plotlineValue !== undefined) {
            customPlotLineRefs[position]?.destroy();
            customPlotLineRefs[position] = renderCustomPlotLine(
              chart,
              position,
              plotlineValue,
            );
          }
        };
        const { yAxis: yAxisCommon, ...common } =
          createChartConfigWithCommonDesign(commonMultichartConfig);

        return {
          ...acc,
          [position]: merge(
            {},
            common,
            {
              chart: {
                events: {
                  render({ target: chart }: { target: Highcharts.Chart }) {
                    rerenderPlotline(chart);

                    const { plotHeight, plotLeft, plotTop, plotWidth } = chart;
                    const newBoundary = {
                      top: plotTop as number,
                      left: plotLeft as number,
                      width: plotWidth as number,
                      height: plotHeight as number,
                    };

                    setPlotBoundaries((prev) => ({
                      ...prev,
                      [position]: newBoundary,
                    }));
                  },
                },
              },
              exporting: {
                sourceWidth: 1920,
                sourceHeight: 1080,
                scale: 1,
                buttons: {
                  contextButton: {
                    x: 6,
                    y: position === ChartPosition.Top ? 28 : 8,
                  },
                },
              },
              xAxis: {
                labels: {
                  enabled: chartPosition === ChartPosition.Bottom,
                },
                title: {
                  enabled: chartPosition === ChartPosition.Bottom,
                },
              },
              yAxis: Array.isArray(yAxis)
                ? yAxis.map((axis) => merge({}, yAxisCommon, axis))
                : merge({}, yAxisCommon, yAxis),
              series: series?.map((dataset) => ({
                ...dataset,
                point: {
                  events: {
                    mouseOver: ({ target: hoverPoint }: PointerEvent) => {
                      handleChartTooltips(
                        hoverPoint,
                        chartRefs.current[oppositeChart],
                      );
                    },
                    mouseOut: ({ target: hoverPoint }: PointerEvent) => {
                      handleChartTooltips(
                        hoverPoint,
                        chartRefs.current[oppositeChart],
                        true,
                      );
                    },
                  },
                },
              })),
            },
            rest,
            shared,
          ),
        };
      },
      {} as IMultiChartOptions,
    );
  };

  const [config, setConfig] = React.useState<IMultiChartOptions>(() => {
    return computeChartConfig(chartRefs, chartConfig, customPlotLineRefs);
  });

  React.useEffect(() => {
    setConfig(() =>
      computeChartConfig(chartRefs, chartConfig, customPlotLineRefs),
    );
  }, [chartRefs, chartConfig, customPlotLineRefs]);

  return config;
};
