import { useDispatch, useSelector } from 'react-redux';
import { setUserDomains } from 'store/actions/domains';
import { setStateLoaded } from 'store/actions/root';
import Domains from 'types/domains';
import State from 'types/state';

export type HookReturn = {
  domains: Domains;
  setDomains: (domains: Domains) => void;
  setDomainsLoaded: () => void;
};

const useDomains = (): HookReturn => {
  const domains = useSelector(
    ({ domains: domainsState }: State) => domainsState,
  );

  const dispatch = useDispatch();

  return {
    domains,
    setDomainsLoaded: (): void => {
      dispatch(setStateLoaded());
    },
    setDomains: (domainData: Domains): void => {
      dispatch(setUserDomains(domainData));
    },
  };
};

export default useDomains;
