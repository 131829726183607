import { getDateRangeFromQueryFilters } from 'components/QueryBuilder/adapters';
import { parseISO } from 'date-fns/fp';
import IReport from 'domains/reports/types';
import { formatLocale } from 'helpers/date';
import { flow, get, head, size } from 'lodash/fp';

export const getVendors = get('vendors');
export const isMultiple = (list: unknown[]): boolean => size(list) > 1;
export const returnSingleVendorName = flow(head, get('vendor_name'));
export const getTarget = get('target');
export const getGenPop = get('genpop');

export const getFormattedDate = (
  report: IReport | null,
): { formatStartDate: string; formatEndDate: string } => {
  const { startDate, endDate } = getDateRangeFromQueryFilters(
    report?.query.filters.children,
  );

  const breakoutCategoriesDates =
    (
      report?.query.breakout_categories as {
        type: string;
        operator: string;
        children: object[];
        dates: { start: string; end: string };
      }
    )?.dates ?? {};
  return {
    formatStartDate: formatLocale(
      parseISO(breakoutCategoriesDates.start ?? startDate),
    ),
    formatEndDate: formatLocale(
      parseISO(breakoutCategoriesDates.end ?? endDate),
    ),
  };
};
