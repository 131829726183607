import { getClass } from 'helpers/components';
import React, { FunctionComponent, useMemo } from 'react';
import Flex, { Direction, Horizontal, Vertical } from '../Flex';

export const targetSizeBarComponentName = 'target-size-bar';

export interface Props {
  value: number;
}

const DOM_KEY_PERCENTAGE_WRAPPER = 'percentage-wrapper';
const DOM_KEY_VALUE_FLOATING = 'value-floating';
const DOM_KEY_VALUE_WRAPPER = 'value-wrapper';
const DOM_KEY_NUMBER = 'number';
const DOM_KEY_LINE = 'line';
const DOM_KEY_VALUE2 = 'value2';
const MAX_OFFSET_THRESHOLD = 6;

const TargetSizeBar: FunctionComponent<Props> = ({ value }) => {
  const value2 = +(100 - value).toFixed(1);
  const haveOffset = value <= MAX_OFFSET_THRESHOLD;
  const haveOffsetValue2 = value2 <= MAX_OFFSET_THRESHOLD;

  const targetSizeBarWrapperClass = useMemo(
    () => getClass(targetSizeBarComponentName, { concat: ['wrapper'] }),
    [],
  );
  const targetSizeBarClass = useMemo(
    () =>
      getClass(targetSizeBarComponentName, {
        boolean: [
          {
            state: haveOffset || haveOffsetValue2,
            class: 'offset',
          },
        ],
      }),
    [haveOffset, haveOffsetValue2],
  );

  const percentageWrapperClass = useMemo(
    () =>
      getClass(targetSizeBarComponentName, {
        add: [DOM_KEY_PERCENTAGE_WRAPPER],
      }),
    [],
  );
  const valueFloatingClass = useMemo(
    () => getClass(percentageWrapperClass, { add: [DOM_KEY_VALUE_FLOATING] }),
    [percentageWrapperClass],
  );
  const valueWrapperClass = useMemo(
    () => getClass(percentageWrapperClass, { add: [DOM_KEY_VALUE_WRAPPER] }),
    [percentageWrapperClass],
  );
  const numberClass = useMemo(
    () => getClass(valueWrapperClass, { add: [DOM_KEY_NUMBER] }),
    [valueWrapperClass],
  );
  const lineClass = useMemo(
    () => getClass(valueWrapperClass, { add: [DOM_KEY_LINE] }),
    [valueWrapperClass],
  );
  const percentageWrapperClassValue2 = useMemo(
    () =>
      getClass(targetSizeBarComponentName, {
        add: [DOM_KEY_PERCENTAGE_WRAPPER, DOM_KEY_VALUE2],
      }),
    [],
  );
  const valueFloatingClassValue2 = useMemo(
    () =>
      getClass(percentageWrapperClassValue2, {
        add: [DOM_KEY_VALUE_FLOATING, DOM_KEY_VALUE2],
      }),
    [percentageWrapperClassValue2],
  );
  const valueWrapperClassValue2 = useMemo(
    () =>
      getClass(percentageWrapperClassValue2, {
        add: [DOM_KEY_VALUE_WRAPPER, DOM_KEY_VALUE2],
      }),
    [percentageWrapperClassValue2],
  );
  const numberClassValue2 = useMemo(
    () =>
      getClass(valueWrapperClassValue2, {
        add: [DOM_KEY_NUMBER, DOM_KEY_VALUE2],
      }),
    [valueWrapperClassValue2],
  );
  const lineClassValue2 = useMemo(
    () =>
      getClass(valueWrapperClassValue2, {
        add: [DOM_KEY_LINE, DOM_KEY_VALUE2],
      }),
    [valueWrapperClassValue2],
  );
  const percentageSpanClassValue2 = useMemo(
    () => getClass(targetSizeBarComponentName, { add: [DOM_KEY_VALUE2] }),
    [],
  );

  return (
    <div className={targetSizeBarWrapperClass}>
      <Flex
        direction={Direction.column}
        horizontal={Horizontal.left}
        vertical={Vertical.top}
      >
        <div className={targetSizeBarClass}>
          {haveOffset ? (
            <span
              style={{ width: `${value}%` }}
              className={percentageWrapperClass}
            >
              <div className={valueFloatingClass}>
                <div className={valueWrapperClass}>
                  <span className={numberClass}>{value}%</span>
                  <div className={lineClass} />
                </div>
              </div>
            </span>
          ) : (
            <span style={{ width: `${value}%` }}>{value}%</span>
          )}
          {haveOffsetValue2 ? (
            <span
              style={{ width: `${value2}%` }}
              className={`${percentageWrapperClassValue2}`}
            >
              <div className={valueFloatingClassValue2}>
                <div className={valueWrapperClassValue2}>
                  <span className={numberClassValue2}>{value2}%</span>
                  <div className={lineClassValue2} />
                </div>
              </div>
            </span>
          ) : (
            <span
              className={percentageSpanClassValue2}
              style={{ width: `${value2}%` }}
            >
              {value2}%
            </span>
          )}
        </div>
      </Flex>
    </div>
  );
};

export default TargetSizeBar;
