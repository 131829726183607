export const prefix = 'r2d2';

export const getComponentUuid = (name: string): string => `${prefix}-${name}`;

export const getTestId = (
  componentName: string,
  componentTestId?: string,
): string => {
  let testId = `${prefix}-${componentName}`;

  if (componentTestId) {
    testId += `-${componentTestId}`;
  }

  return testId;
};

export const getId = (componentName: string, componentId?: string): string => {
  if (componentId) {
    return `${prefix}-${componentName}-${componentId}`;
  }

  return '';
};

type ClassBooleanProperty = {
  state?: boolean;
  class?: string;
  preventCollisions?: boolean;
};

type ClassProperties = {
  text?: Array<string | undefined>;
  boolean?: Array<ClassBooleanProperty | undefined>;
  add?: Array<string | undefined>;
  concat?: Array<string | undefined>;
};
export const getClass = (
  componentName: string,
  classProperties?: ClassProperties,
): string => {
  let outClass: string;
  const baseUUIDClass = getComponentUuid(componentName);

  outClass = baseUUIDClass;

  if (classProperties?.text) {
    classProperties.text.forEach((textProperty?: string): void => {
      if (textProperty) outClass = outClass?.concat(' ', textProperty);
    });
  }

  if (classProperties?.concat) {
    classProperties.concat.forEach((concatProperty?: string): void => {
      if (concatProperty) outClass = outClass?.concat('-', concatProperty);
    });
  }

  if (classProperties?.add) {
    classProperties.add.forEach((addProperty?: string): void => {
      if (addProperty) outClass = outClass?.concat(' ', addProperty);
    });
  }

  if (classProperties?.boolean) {
    classProperties.boolean.forEach(
      (booleanProperty: ClassBooleanProperty | undefined): void => {
        if (
          booleanProperty?.state &&
          booleanProperty?.class &&
          !booleanProperty?.preventCollisions
        ) {
          outClass = outClass?.concat(' ', booleanProperty.class);
        } else if (
          booleanProperty?.state &&
          booleanProperty?.class &&
          booleanProperty?.preventCollisions
        ) {
          outClass = outClass?.concat(
            ' ',
            `${baseUUIDClass}-${booleanProperty.class}`,
          );
        }
      },
    );
  }

  return outClass;
};
