import { ParseResult, ParseError, ParseMeta } from 'papaparse';

export interface IParsedCSV {
  data: string[][];
  error: ParseError;
  meta: ParseMeta;
}

export interface IEditManyUsersState {
  previewTable: IValidatedCell[][];
}

export interface IEditManyUsersProps extends IEditManyUsersState {
  validateUploadedFile: (payload: ParseResult<IParsedCSV>) => void;
  resetPreviewTable: () => void;
  handleSubmit: () => void;
}

export enum IOperationType {
  create = 'C',
  update = 'U',
  delete = 'D',
}

export interface IValidatedData {
  id: string;
  name: string;
  error: boolean;
  toDelete?: boolean;
}

export interface IKeyValue {
  key: string;
  value: string;
  error?: boolean;
  errorMessage?: string;
}

export interface IValidatedCell extends Omit<IKeyValue, 'value'> {
  value: string | IValidatedData[] | null;
}
