import { IOption } from 'types/textValue';
import * as constants from 'features/targets/constants';

export const axisCampaignOptions: IOption[] = [
  {
    id: 0,
    text: 'Reach',
    value: 'campaign.REACH',
    color: '',
    tooltip: 'The number of unduplicated households that watched.',
  },
  {
    id: 1,
    text: 'Reach %',
    value: 'campaign.REACH_PC',
    color: '',
    tooltip: 'The number of unduplicated households that watched.',
  },
  {
    id: 2,
    text: 'Impressions',
    value: 'campaign.IMPRESSIONS',
    color: '',
    tooltip: 'The quantity of times that an ad was watched.',
  },
  {
    id: 3,
    text: 'Impression Frequency',
    value: 'campaign.FREQUENCY',
    color: '',
    tooltip: 'The average number of ads watched per household.',
  },
  {
    id: 4,
    text: 'Ad Spot Count',
    value: 'campaign.SPOT_COUNT',
    color: '',
    tooltip: 'The quantity of ads aired.',
  },
  {
    id: 5,
    text: 'Ad Cost',
    value: 'campaign.AD_COST',
    color: '',
    tooltip: '',
  },
  {
    id: 6,
    text: 'Unique Reach',
    value: 'campaign.UNIQUE_REACH',
    color: '',
    tooltip:
      'The number of unduplicated households that watched, expressed in thousands, unique to each row or chart element in the report',
  },
];

export const axisBasePerformanceOptions: Partial<IOption>[] = [
  {
    text: '% Lift',
    value: 'LIFT',
    color: '',
    tooltip:
      'The rate at which exposed households converted or performed a certain action.',
  },
  {
    text: 'Incremental Lift',
    value: 'INCREMENTAL_LIFT',
    color: '',
    tooltip: '',
  },
  {
    text: 'Cost per Incremental Converter',
    value: 'COST_PER_INCREMENTAL_LIFT',
    color: '',
    tooltip: '',
  },
  {
    text: 'Persuasion Index',
    value: 'PERSUASION_INDEX',
    color: '',
    tooltip: '',
  },
  {
    text: 'Ad Cost Persuasion Index',
    value: 'PERSUASION_INDEX_BY_COST',
    color: '',
    tooltip: '',
  },
  {
    text: 'Exposed Mean',
    value: 'MEAN',
    color: '',
    tooltip:
      'The rate at which exposed households converted or performed a certain action.',
  },
];

export const performersWhiteList = ['LIFT', 'MEAN'];

export const TARGET_RESULT_ALLOWED_METRICS = [
  {
    text: constants.LOCAL_UNIVERSE_METRICS,
    value: constants.LOCAL_UNIVERSE_METRICS,
    tooltip:
      '% Of Universe is the percent of households in the audience universe',
  },
  {
    text: constants.LOCAL_POPULATION_INDEX_METRICS,
    value: constants.LOCAL_POPULATION_INDEX_METRICS,
    tooltip:
      'Population index is the ratio of % of audience divided by the % of all households in the universe, multiplied by 100',
  },
];
