import Dropdown from 'components/Dropdown';
import DropdownSearch from 'components/DropdownSearch';
import Flex, { Direction, Horizontal, Vertical } from 'components/Flex';
import Input, { NewInputValue, Type as InputType } from 'components/Input';
import Label from 'components/Label';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import { getClass, getId, getTestId } from 'helpers/components';
import { isNil } from 'lodash/fp';
import React, {
  FocusEventHandler,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from 'react';
import ITextValue from 'types/textValue';
import InlineAlert, { AlertTypes } from '../../InlineAlert';

export const fieldsComponentName = 'fields';

export interface IFieldsProps {
  type: InputType;
  value?: string | number;
  multiDropdownValue?: ITextValue[];
  testId?: string;
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  size?: number;
  list?: string;
  minLength?: number;
  placeholder?: string;
  readonly?: boolean;
  spellcheck?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (newFieldValue: NewInputValue) => void;
  onBlur?: FocusEventHandler;
  onKeyPressEnter?: (arg0: React.KeyboardEvent<HTMLElement>) => void;
  onMultipleDropdownChange?: (selected: ITextValue[]) => void;
  className?: string;
  options?: string[] | ITextValue[];
  multipleDropdownOptions?: ITextValue[];
  preventEnterKeyDown?: boolean;
  canReorder?: boolean;
  portalContainerClass?: string;
}

const Fields: FunctionComponent<PropsWithChildren<IFieldsProps>> = (
  props,
): ReactElement => {
  const {
    type,
    label,
    value,
    multiDropdownValue,
    name,
    placeholder,
    disabled,
    hasError,
    errorMessage,
    id,
    testId,
    onChange,
    onBlur,
    onKeyPressEnter,
    onMultipleDropdownChange,
    className,
    options,
    multipleDropdownOptions,
    preventEnterKeyDown = true,
    minLength = 0,
    canReorder = true,
    portalContainerClass,
  } = props;

  const fieldsClass = getClass(fieldsComponentName, { add: [className ?? ''] });
  const fieldsId = getId(fieldsComponentName, id);
  const fieldsTestId = getTestId(fieldsComponentName, testId);

  const isSelect = type === InputType.select;
  const isDropdownSearch = [
    InputType.typeahead,
    InputType.dropdownSearch,
  ].includes(type);
  const isMultipleDropdownSelect = type === InputType.multipleDropdownSelect;
  const isInput = !isSelect && !isDropdownSearch && !isMultipleDropdownSelect;

  return (
    <div className={fieldsClass} data-testid={fieldsTestId}>
      <Flex
        direction={Direction.column}
        vertical={Vertical.top}
        horizontal={Horizontal.left}
      >
        {label && (
          <Label
            forId={fieldsId}
            testId={fieldsTestId}
            text={label}
            disabled={disabled}
          />
        )}
        {isSelect && (
          <Dropdown
            onChange={onChange}
            options={options}
            testId={fieldsTestId}
            id={fieldsId}
            name={name}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            value={value as string}
            hasError={hasError}
            portalContainerClass={portalContainerClass}
          />
        )}
        {isDropdownSearch && (
          <DropdownSearch
            onChange={onChange}
            testId={fieldsTestId}
            onBlur={onBlur}
            id={fieldsId}
            placeholder={placeholder}
            disabled={disabled}
            options={options as ITextValue[]}
            hasError={hasError}
            enableHighlight
            portalContainerClass={portalContainerClass}
          />
        )}
        {isMultipleDropdownSelect && (
          <MultipleDropdownSelect
            onChange={onMultipleDropdownChange}
            onBlur={onBlur}
            options={multipleDropdownOptions}
            testId={fieldsTestId}
            placeholder={placeholder}
            disabled={disabled}
            selected={multiDropdownValue}
            minimumChipsSelected={minLength}
            canReorder={canReorder}
            portalContainerClass={portalContainerClass}
          />
        )}
        {isInput && (
          <Input
            confirmOnEnter={!isNil(onKeyPressEnter)}
            type={type}
            testId={fieldsTestId}
            onBlur={onBlur}
            id={fieldsId}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            hasError={hasError}
            onChange={onChange}
            onEnterKeyDown={onKeyPressEnter}
            preventEnterKeyDown={preventEnterKeyDown}
          />
        )}
        {hasError && (
          <InlineAlert
            message={errorMessage ?? ''}
            mode={AlertTypes.error}
            hideClose
            testId={fieldsTestId}
          />
        )}
      </Flex>
    </div>
  );
};

export default Fields;
