import { checkPermissionTree } from 'domains/permissions/helpers';
import { IPermissionTree } from 'domains/permissions/types';
import IReport from 'domains/reports/types';
import IResultSelection from 'domains/resultSelections/types';
import { fetchApi } from 'helpers/fetching';
import hash from 'object-hash';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Index } from 'routes';
import ActionTypes from 'store/actions/types';
import * as reportSelectors from 'store/selectors/report';
import * as resultSelectionsSelectors from 'store/selectors/resultSelections';
import * as userSelectors from 'store/selectors/user';
import { Action } from 'types/action';
import FetchMethod from 'types/fetchMethod';
import State from 'types/state';

const setResultSelection = (
  payload: IResultSelection | null,
): Action<IResultSelection | {}> => ({
  type: ActionTypes.RESULT_SELECTION_SET_RESULT_SELECTION,
  payload: payload ?? {},
});

const checkResultSelectionPermission = (
  permissions: IPermissionTree,
): boolean => checkPermissionTree('reports::update', permissions);

const executeSilentError =
  (payload: string | undefined) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>): void => {
    console.error(payload);
    dispatch(setResultSelection(null));
  };

export const createNewResultSelection =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const report = reportSelectors.getReport(state) as IReport;
    const user = userSelectors.getUser(state);

    const canSetSelections = checkResultSelectionPermission(user.permissions);
    if (!canSetSelections) return;

    if (!report) {
      executeSilentError('No report loaded');
    }

    const id = report.id;
    const reportQuery = report.query;
    const reportQueryHash = hash(reportQuery);
    const type = 'attribution-report';

    const payload = {
      resultSelection: {
        id,
        reportQueryHash,
        type,
        filters: {},
        rowsRemoved: {},
      },
    };

    const apiCall = {
      endpoint: Index.SEGMENT_RESULT_SELECTIONS,
      payload,
      method: FetchMethod.POST,
    };

    const apiResult = await fetchApi(apiCall);

    if (apiResult.error) {
      executeSilentError(apiResult.error?.message ?? 'Unknown error');
    }

    const resultSelection = apiResult.data[0] as IResultSelection | null;

    dispatch(setResultSelection(resultSelection));
  };

export const loadResultSelection =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const report = reportSelectors.getReport(state) as IReport;

    if (!report) {
      executeSilentError('No report loaded');
    }

    const reportId = report.id;
    const reportQuery = report.query;
    const reportQueryHash = hash(reportQuery);

    const apiCall = {
      endpoint: `${Index.SEGMENT_RESULT_SELECTIONS}/${reportId}/${reportQueryHash}`,
      method: FetchMethod.GET,
    };

    const apiResult = await fetchApi(apiCall);

    if (apiResult.error) {
      executeSilentError(apiResult.error?.message ?? 'Unknown error');
    }

    const resultSelection = apiResult.data[0] as IResultSelection | null;

    if (!resultSelection) {
      return dispatch(createNewResultSelection());
    }

    dispatch(setResultSelection(resultSelection));
  };

export const updateResultSelection =
  (payload: Partial<IResultSelection>) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const currentResultSelection = resultSelectionsSelectors.getResultSelection(
      state,
    ) as IResultSelection;
    const user = userSelectors.getUser(state);

    const canSetSelections = checkResultSelectionPermission(user.permissions);
    if (!canSetSelections) return;

    const resultSelection = { ...currentResultSelection, ...payload };
    const apiCall = {
      endpoint: `${Index.SEGMENT_RESULT_SELECTIONS}`,
      method: FetchMethod.PATCH,
      payload: { resultSelection },
    };

    const apiResult = await fetchApi(apiCall);

    if (apiResult.error) {
      executeSilentError(apiResult.error?.message ?? 'Unknown error');
    }

    dispatch(setResultSelection(resultSelection));
  };

export const updateResultSelectionFilters =
  (payload: Partial<IResultSelection['filters']>) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const currentResultSelection = resultSelectionsSelectors.getResultSelection(
      state,
    ) as IResultSelection;

    const nextFilters = {
      ...currentResultSelection.filters,
      ...payload,
    };

    return dispatch(updateResultSelection({ filters: nextFilters }));
  };

export const updateResultSelectionRowsRemoved =
  (payload: Partial<IResultSelection['rowsRemoved']>) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const currentResultSelection = resultSelectionsSelectors.getResultSelection(
      state,
    ) as IResultSelection;

    const nextRowsRemoved = {
      ...currentResultSelection.rowsRemoved,
      ...payload,
    };

    return dispatch(updateResultSelection({ rowsRemoved: nextRowsRemoved }));
  };
