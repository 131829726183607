import Button, { Kind } from 'components/Button';
import DatePicker from 'components/DatePicker';
import { Anchor } from 'components/DatePicker/interface';
import Flex, { Horizontal } from 'components/Flex';
import {
  LOCALE_DATE_RANGE_LABEL,
  LOCALE_DATE_RANGE_LABEL_TOOLTIP,
} from 'components/QueryBuilder/locale';
import { Style, Tooltip } from 'components/Tooltip';
import IDimension from 'domains/dimensions/types';
import { getClass, getTestId } from 'helpers/components';
import { showWarningToast } from 'helpers/general';
import { filter, get } from 'lodash';
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as messageListActions from 'store/actions/messageList';
import { FilterType, FilterValue, newFilter, RuleFilter } from 'types/filter';
import State from 'types/state';
import IReport, { IDomainsDateRanges } from 'domains/reports/types';
import * as reportSelectors from 'store/selectors/report';
import * as domainsSelector from 'store/selectors/domains';
import {
  getCompoundRuleDate,
  getCompoundRuleMaxDate,
  getCompoundRuleMinDate,
  verifyAndGetFilterRules,
} from './helpers';
import RulesBuilderItem from './RulesBuilderItem';
import RulesWarning from './RulesWarning';
import useUserPermissions from '../../../hooks/useUserPermissions';

type ICompoundRulesBuilderItemProps = {
  dimensions: IDimension[];
  mainField?: string;
  queryMinDate?: string;
  queryMaxDate?: string;
  onChange: (f: RuleFilter, index: number) => void;
  mainFilter: RuleFilter;
  fieldIndex: number;
  report: IReport;
  testId?: string;
  disabled?: boolean;
  dateRanges: IDomainsDateRanges;
  deleteMessagesForID: (id: string) => void;
};

export const componentName = 'compound-rules-builder-item';
const componentClass = getClass(componentName);

const CompoundRulesBuilderItem: FC<ICompoundRulesBuilderItemProps> = (
  props: ICompoundRulesBuilderItemProps,
) => {
  const {
    dimensions,
    queryMinDate,
    queryMaxDate,
    mainField,
    onChange,
    fieldIndex,
    mainFilter,
    testId,
    disabled,
    report,
    dateRanges,
    deleteMessagesForID,
  } = props;

  const { id: reportId } = useParams<{ id: string }>();
  const { checkPermissions } = useUserPermissions();

  const canViewPIQCategory =
    report.type !== 'attribution-report' ||
    checkPermissions(
      'attribution_reports.outcomes_brandlift.placeiq.piq_category::view',
    );
  const canViewPIQChain =
    report.type !== 'attribution-report' ||
    checkPermissions(
      'attribution_reports.outcomes_brandlift.placeiq.piq_chain::view',
    );
  const isPIQField = mainField === 'PLACEIQ';
  const showSmartSelection = !isPIQField;
  const canViewPIQChildren = canViewPIQCategory || canViewPIQChain;

  const curatedDimensions = useMemo(
    () =>
      dimensions.filter(
        (d) =>
          (canViewPIQChain && d.id === 'PIQ_CHAIN') ||
          (canViewPIQCategory && d.id === 'PIQ_CATEGORY') ||
          (d.id !== 'PIQ_CHAIN' && d.id !== 'PIQ_CATEGORY'),
      ),
    [canViewPIQCategory, canViewPIQChain],
  );

  const getFilterForRulesBuilderItem = (
    existingFilters: RuleFilter[],
    filterForRule: RuleFilter,
    index: number,
  ): RuleFilter => ({
    ...filterForRule,
    field: filterForRule.id,
    mainField,
    childField: filterForRule.id,
    isRule: true,
    name: filterForRule.name,
    filterRules: verifyAndGetFilterRules(existingFilters, index) || [],
  });

  const [startDate, setStartDate] = useState(
    getCompoundRuleDate(
      mainFilter,
      dateRanges,
      queryMinDate,
      'start',
      isPIQField,
    ),
  );
  const [endDate, setEndDate] = useState(
    getCompoundRuleDate(
      mainFilter,
      dateRanges,
      queryMaxDate,
      'end',
      isPIQField,
    ),
  );
  const minDate = getCompoundRuleMinDate(dateRanges, isPIQField);
  const maxDate = getCompoundRuleMaxDate(dateRanges, isPIQField);

  const passedFilters: RuleFilter[] = useMemo(() => {
    if (mainFilter?.children) {
      let newPassedFilter = filter(mainFilter?.children as RuleFilter[], (mf) =>
        get(mf, 'id'),
      ) as RuleFilter[];

      newPassedFilter = newPassedFilter.map(
        (f, i) =>
          f.id && getFilterForRulesBuilderItem(newPassedFilter || [], f, i),
      ) as RuleFilter[];

      return newPassedFilter;
    }
    return [];
  }, [mainFilter]);

  const DEFAULT_RULE: RuleFilter[] = useMemo(() => {
    if (mainFilter.id !== 'PLACEIQ') {
      return [
        {
          field: dimensions[0]?.id,
          mainField,
          childField: dimensions[0]?.id,
          type: FilterType.SIMPLE,
          filterId: dimensions[0]?.id,
          id: dimensions[0]?.id,
          isRule: true,
          name: dimensions[0]?.name,
          filterRules: [],
          value: [],
        },
      ];
    }
    if (!canViewPIQChildren) return [];
    if (canViewPIQCategory) {
      return [
        {
          field: dimensions[0]?.id,
          mainField,
          childField: dimensions[0]?.id,
          type: FilterType.SIMPLE,
          filterId: dimensions[0]?.id,
          id: dimensions[0]?.id,
          isRule: true,
          name: dimensions[0]?.name,
          filterRules: [],
          value: [],
        },
      ];
    }
    if (canViewPIQChain) {
      return [
        {
          field: dimensions[1]?.id,
          mainField,
          childField: dimensions[1]?.id,
          type: FilterType.SIMPLE,
          filterId: dimensions[1]?.id,
          id: dimensions[1]?.id,
          isRule: true,
          name: dimensions[1]?.name,
          filterRules: [],
          value: [],
        },
      ];
    }
    return [];
  }, [dimensions, canViewPIQChain, canViewPIQChildren, canViewPIQCategory]);

  const getDefaultRule = useCallback(
    (): RuleFilter[] => (reportId ? [] : DEFAULT_RULE),
    [reportId, DEFAULT_RULE],
  );
  const [filters, setFilters] = useState<RuleFilter[]>(
    passedFilters.length ? passedFilters : getDefaultRule(),
  );

  const buildQueryData = (): Record<string, unknown> => {
    const dateTimeFilters = filters.filter(
      (f) => f && f.type === 'DATETIME_RANGE',
    );
    const dateTimeFilter = dateTimeFilters.length
      ? []
      : [
          {
            type: 'DATETIME_RANGE',
            start: startDate,
            end: endDate,
          } as RuleFilter,
        ];

    const childFilters = filters
      .filter((f) => f)
      .map((childFilter) => ({
        field: childFilter.id,
        id: childFilter.id,
        type: childFilter.type,
        operator: childFilter.operator,
        filterId: childFilter.id ?? childFilter.filterId,
        value: childFilter?.value ?? [],
      }));

    const children = [...dateTimeFilter, ...childFilters];

    onChange(
      {
        ...mainFilter,
        type: 'COHORT',
        field: mainField,
        id: mainField,
        operator: 'IN',
        value: children as FilterValue,
        children: children as FilterValue,
        filterId: mainFilter.filterId,
      },
      fieldIndex,
    );

    return {
      type: 'COHORT',
      field: mainField,
      id: mainField,
      operator: 'IN',
      children,
    };
  };

  useEffect(() => {
    buildQueryData();
  }, [filters, startDate, endDate]);

  const handleDateRangeChange = useCallback(
    (newStartDate: string, newEndDate: string): void => {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    },
    [],
  );

  const handleFilterChange = (changedFilter: RuleFilter): void => {
    let newFilters = [...filters];
    const index = newFilters.findIndex(
      (f) => f.filterId === changedFilter.filterId,
    );
    newFilters[index] = getFilterForRulesBuilderItem(
      filters,
      changedFilter,
      index,
    );
    const nextFilter = newFilters[index + 1];

    if (nextFilter) {
      if (nextFilter.field)
        showWarningToast('Some of your configurations have been removed');
      const nextIndex = index + 1;
      deleteMessagesForID(nextFilter.filterId);
      newFilters = [
        ...newFilters.slice(0, nextIndex),
        ...newFilters.slice(nextIndex + 1),
      ];
      newFilters = [...newFilters];
    }

    setFilters(newFilters.filter((f) => f));
  };

  const handleAdd = useCallback((): void => {
    const newFilters = [...filters, newFilter()];
    setFilters(newFilters.filter((f) => f));
  }, [filters]);

  const handleRuleDelete = useCallback(
    (changedFilter: RuleFilter): void => {
      let newFilters = [...filters];
      const index = newFilters.findIndex(
        (f) => f.filterId === changedFilter.filterId,
      );
      newFilters = [
        ...newFilters.slice(0, index),
        ...newFilters.slice(index + 1),
      ];
      setFilters(newFilters.filter((f) => f));
    },
    [filters],
  );

  const componentTestId = useMemo(
    () => getTestId(componentName, testId),
    [testId],
  );

  const getRuleBuilderItem = (
    filterForRule: RuleFilter,
    index: number,
  ): ReactElement => (
    <RulesBuilderItem
      key={filterForRule.filterId || filterForRule.id}
      dimensions={curatedDimensions.filter((d) => {
        if (index > 0) {
          return d.id !== filters[0].id;
        }
        return true;
      })}
      filter={filterForRule}
      index={index}
      onChange={handleFilterChange}
      onDelete={handleRuleDelete}
      placeholder="Add New Rule"
      parentRuleTracking={mainFilter}
      parentId={mainFilter.filterId}
      disabled={disabled}
      isDeletable
    />
  );

  const hasMultipleRules = filters.filter((item) => item.value).length >= 2;
  return (
    <div
      data-testid={componentTestId}
      className={componentClass}
      style={{
        padding: 10,
        marginTop: 10,
        width: '95%',
      }}
    >
      <div className="rules-builder-header">
        {hasMultipleRules && (
          <div data-testid="compound-rules-warning">
            <RulesWarning />
          </div>
        )}
      </div>
      <Flex horizontal={Horizontal.left}>
        <Tooltip
          style={Style.tertiary}
          content={LOCALE_DATE_RANGE_LABEL_TOOLTIP}
        >
          <label style={{ marginLeft: 20, marginTop: 10 }}>
            {LOCALE_DATE_RANGE_LABEL}
          </label>
        </Tooltip>
        <DatePicker
          anchor={Anchor.right}
          endDate={endDate}
          handleChange={handleDateRangeChange}
          trackingId={`Compound Rule - ${mainField}`}
          maxDate={maxDate}
          minDate={minDate}
          showNumOfDays
          disabled={disabled}
          startDate={startDate}
          showSmartSelection={showSmartSelection}
        />
      </Flex>
      <div style={{ padding: 20 }}>
        {filters.map(
          (filterForRule, index) =>
            filterForRule &&
            filterForRule.type !== 'DATETIME_RANGE' &&
            !filterForRule.start && (
              <React.Fragment key={filterForRule.filterId || filterForRule.id}>
                {getRuleBuilderItem(filterForRule, index)}
              </React.Fragment>
            ),
        )}
      </div>
      <div style={{ marginLeft: 20, marginTop: 10 }}>
        {canViewPIQChildren && curatedDimensions.length > filters.length && (
          <Button
            kind={Kind.linkPrimary}
            onClick={handleAdd}
            disabled={disabled}
            testId="add-compound-filter"
          >
            Add New Rule
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: State,
): {
  report: IReport;
  dateRanges: IDomainsDateRanges;
} => ({
  report: reportSelectors.getReport(state),
  dateRanges: domainsSelector.getDomainsDateRanges(state),
});

const mapDispatchToProps = {
  deleteMessagesForID: messageListActions.deleteMessagesForID,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompoundRulesBuilderItem);
