import { Placement } from 'components/Popover';
import { Style, Tooltip } from 'components/Tooltip';
import { getClass } from 'helpers/components';
import React, { FC, useEffect, useRef, useState } from 'react';

const nameCellName = 'table-name-cell';

const nameCellClassName = getClass(nameCellName);

const TableNameCell: FC<{ name: string; color: string; showDot?: boolean }> = ({
  name,
  color,
  showDot = false,
}) => {
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  // Note: render tooltip only if text is truncated
  useEffect(() => {
    const element = spanRef.current;

    if (element) {
      setIsEllipsisActive(element.offsetHeight < element.scrollHeight);
    }
  }, []);

  return (
    <div className={nameCellClassName}>
      {showDot && (
        <div
          className={`${nameCellClassName}__dot`}
          style={{
            backgroundColor: color,
          }}
        />
      )}
      <Tooltip
        placement={Placement.top}
        style={Style.tertiary}
        content={isEllipsisActive ? name : undefined}
        color={color}
      >
        <span className={`${nameCellClassName}__name`} ref={spanRef}>
          {name}
        </span>
      </Tooltip>
    </div>
  );
};

export default TableNameCell;
