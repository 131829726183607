export const DOM_KEY_QUERY_BUILDER_DATASETS_SEGMENTED_CONTROL =
  'segmented-control';
export const DOM_KEY_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN =
  'custom-ads-datasets';
export const DOM_KEY_QUERY_BUILDER_DATASETS_GENERIC_EVENTS_DROPDOWN =
  'generic-events-datasets';
export const ATTRIBUTION_TARGET_SELECT_LIMIT = 1;
export const DOM_KEY_VIEWERSHIP = 'viewership';
export const DOM_KEY_OUTCOME = 'outcome';
export const DOM_KEY_TARGET = 'target';
