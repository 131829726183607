import { ExposureConversionSetNames } from 'domains/reports/types';

export const checkIfHasOmission = (
  omissionMessages: ExposureConversionSetNames[] = [],
  omission_keys: ExposureConversionSetNames[] = [],
): boolean => {
  for (const omissionKey of omission_keys) {
    if (omissionMessages.includes(omissionKey)) return true;
  }

  return false;
};
