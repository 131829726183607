import Button, { Kind, Size } from 'components/Button';
import { dropdownComponentName } from 'components/Dropdown';
import Flex, { Horizontal } from 'components/Flex';
import Input, { Type } from 'components/Input';
import { Popover } from 'components/Popover';
import { Placement } from 'components/Sticker';
import { getClass, getId, getTestId } from 'helpers/components';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import React, {
  FC,
  FocusEventHandler,
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import AnimateHeight from 'react-animate-height';
import ITextValue from 'types/textValue';
import { getOptions } from './pathGenerator';

export const EVENT_DROPDOWN_SEARCH_SHOULD_CLOSE = 'dropdown:should:close';

const featureComponentName = 'feature';
const DOM_KEY_FEATURE_RIGHTSIDE_CONTENT = 'right-side-content';
const featureRightSideContentClass = getClass(featureComponentName, {
  concat: [DOM_KEY_FEATURE_RIGHTSIDE_CONTENT],
});

const dropdownStickerClass = getClass(dropdownComponentName);

const OptionsTreeClass = getClass(dropdownComponentName, {
  text: ['tooltip-wrapper'],
});

export type DropdownSearchQueryProvider = (
  query?: string,
  lineBegin?: number,
  lineEnd?: number,
) => Promise<ITextValue[]> | ITextValue[];

export interface IDropdownSearchProps {
  testId?: string;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  stickToBottom?: boolean;
  groups: (string | undefined)[];
  setGroups: (groups: (string | undefined)[]) => void;
  selected?: string;
  setSelected: (selected: string) => void;
  onBlur?: FocusEventHandler;
  name?: string;
}

export const dropdownSearchComponentName = 'dropdown-search';
export const defaultPlaceholder = 'Search';
export const dropdownSearchIconLoading = 'icon-loading-wrapper';

export enum States {
  error = 'error',
}

const defaultWidth = '100%';

export interface IOption {
  value: string;
  id: string;
  children: IOption[];
}

interface IOptionsTreeItemProps {
  option: IOption;
  level?: number;
  path: string;
  onClick: (p: string) => void;
  selected: string;
  addGroup: (g: string) => void;
}

const OptionTreeItem: FC<IOptionsTreeItemProps> = ({
  option,
  level = 0,
  path,
  onClick,
  selected,
  addGroup,
}) => {
  const [deployed, setDeployed] = useState(selected.startsWith(path));
  const [showNewChildInput, setShowNewChildInput] = useState(false);
  const [newChildValue, setNewChildValue] = useState('');

  const toggleShowNewChildInput = (e: React.MouseEvent | undefined): void => {
    e?.stopPropagation();
    setShowNewChildInput((s) => !s);
    setNewChildValue('');
  };

  const addNewChild = (e: React.MouseEvent | undefined): void => {
    e?.stopPropagation();
    const newPath = `${path}|${newChildValue.trim()}`;
    addGroup(newPath);
    onClick(newPath);
    toggleShowNewChildInput(e);
  };

  const newChildInputComponent = showNewChildInput ? (
    <Flex horizontal={Horizontal.right} style={{ gap: 10 }}>
      <Input
        type={Type.text}
        autocomplete={false}
        value={newChildValue}
        placeholder="Enter your new folder's name"
        onChange={(t) => setNewChildValue(t.toString())}
        wrapperStyle={{ maxWidth: 200 }}
      />
      <Button
        size={Size.small}
        kind={Kind.primary}
        onClick={addNewChild}
        disabled={!newChildValue.trim()}
      >
        Add
      </Button>
      <Button size={Size.small} onClick={toggleShowNewChildInput}>
        Cancel
      </Button>
    </Flex>
  ) : (
    <Button size={Size.small} onClick={toggleShowNewChildInput}>
      Add New Sub-Folder
    </Button>
  );

  if (option.children.length) {
    const defaultClass = getClass('options-tree-group', {
      boolean: [{ state: deployed, class: 'deployed' }],
      add: [`level-${level}`],
    });

    const nameClass = getClass('options-tree-group', { concat: ['name'] });
    const toggleDeployed = (): void => {
      onClick(path);
      setDeployed((d) => !d);
    };

    return (
      <>
        <div
          onClick={toggleDeployed}
          onKeyDown={() => {}}
          className={defaultClass}
          style={{ paddingRight: 0 }}
        >
          <Flex horizontal={Horizontal.between}>
            <div
              className={`${nameClass} ${
                deployed ? 'deployed' : ''
              } level-${level}-group`}
              style={{ flex: 1 }}
            >
              {option.value}
            </div>
            {newChildInputComponent}
          </Flex>
        </div>
        <AnimateHeight duration={300} height={deployed ? 'auto' : 0}>
          {option.children.map((item) => (
            <OptionTreeItem
              key={item.id}
              option={item}
              level={level + 1}
              path={`${path}|${item.value}`}
              onClick={onClick}
              selected={selected}
              addGroup={addGroup}
            />
          ))}
        </AnimateHeight>
      </>
    );
  }

  const defaultClass = getClass('options-tree-item', {
    add: [`level-${level}`],
    boolean: [{ state: false, class: 'selected' }],
  });
  const textClass = getClass('options-tree-item', { add: ['text'] });

  return (
    <li
      className={defaultClass}
      onClick={() => onClick(path)}
      onKeyDown={() => {}}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 0,
      }}
    >
      <span className={textClass}>{option.value}</span>
      {newChildInputComponent}
    </li>
  );
};

export const DropdownPath: FunctionComponent<IDropdownSearchProps> = ({
  placeholder = defaultPlaceholder,
  disabled,
  id,
  testId,
  hasError,
  stickToBottom,
  groups,
  setGroups,
  selected = '',
  setSelected,
  onBlur,
  name,
}): ReactElement => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const refOptions = useRef<HTMLDivElement>(null);

  const dropdownSearchId = getId(dropdownSearchComponentName, id);
  const dropdownSearchTestId = getTestId(dropdownSearchComponentName, testId);
  const dropdownClass = getClass(dropdownComponentName, {
    boolean: [
      {
        state: hasError,
        class: States.error,
      },
      {
        state: disabled,
        class: 'disabled',
      },
    ],
  });

  const options = useMemo(() => getOptions(groups as string[]), [groups]);

  const addGroup = (group: string): void => setGroups([...groups, group]);

  useOnOutsideClick(
    [ref, refOptions],
    useCallback(() => {
      setOpened(false);
    }, []),
  );

  const handleClick = (): false | void => {
    if (!disabled) {
      setOpened(true);
    }
  };

  useEffect((): void => {
    const handleDropdownShouldClose = (e: Event): void => {
      e.preventDefault();
      setOpened(false);
    };

    document.addEventListener(
      EVENT_DROPDOWN_SEARCH_SHOULD_CLOSE,
      handleDropdownShouldClose,
    );
  }, []);

  const optionsStyle = {
    width: `${ref.current?.offsetWidth ?? defaultWidth}px`,
  };

  const text = selected.split('|').at(-1);
  const optionsTreeClass = getClass('options-tree', {
    add: [Placement.bottomEnd],
  });
  return (
    <div
      id={dropdownSearchId}
      data-testid={dropdownSearchTestId}
      ref={ref}
      className={dropdownClass}
      onKeyDown={() => {}}
      onClick={handleClick}
      title={text}
    >
      <Popover
        placement={Placement.bottomStart}
        visible={opened}
        showArrow={false}
        controlled
        matchTriggerWidth
        customStickerClass={dropdownStickerClass}
        stickToBottom={stickToBottom}
        customPortalContainer={`.${featureRightSideContentClass}`}
        content={
          <div
            className={OptionsTreeClass}
            style={optionsStyle}
            ref={refOptions}
          >
            <div className={optionsTreeClass}>
              {options.map((option) => (
                <OptionTreeItem
                  key={option.id}
                  option={option}
                  path={option.value}
                  onClick={setSelected}
                  selected={selected}
                  addGroup={addGroup}
                />
              ))}
            </div>
          </div>
        }
      >
        <Input
          ref={inputRef}
          autocomplete={false}
          value={text}
          placeholder={placeholder}
          type={Type.text}
          testId={dropdownSearchComponentName}
          disabled={disabled}
          wrapperStyle={{
            width: defaultWidth,
          }}
          name={name}
          onBlur={onBlur}
          readonly
        />
      </Popover>
    </div>
  );
};
