import IReport from 'domains/reports/types';
import { trackFiltersChange } from 'helpers/mixpanel';
import { IOption } from 'types/textValue';
import { sectionName } from '.';
import { ITransformerInputFilters } from './transformer';

interface IState extends ITransformerInputFilters {
  segmentsList: IOption[];
  targetsList: IOption[];
  lastValidReport: IReport | null;
}

export enum ActionTypes {
  SET_TARGETS = 'SET_TARGETS',
  SET_ONE_TARGET = 'SET_ONE_TARGET',
  SET_SEGMENTS = 'SET_SEGMENTS',
  SET_ONE_SEGMENT = 'SET_ONE_SEGMENT',
  SET_DEMOGRAPHIC = 'SET_DEMOGRAPHIC',
  CHANGE_MULTI_TARGET_SWITCH = 'CHANGE_MULTI_TARGET_SWITCH',
}

export type Action =
  | { payload: IOption[]; type: ActionTypes.SET_TARGETS }
  | { payload: string; type: ActionTypes.SET_ONE_TARGET }
  | { payload: IOption[]; type: ActionTypes.SET_SEGMENTS }
  | { payload: string; type: ActionTypes.SET_ONE_SEGMENT }
  | {
      payload: { value: string; text: string } | undefined;
      type: ActionTypes.SET_DEMOGRAPHIC;
    }
  | { payload: boolean; type: ActionTypes.CHANGE_MULTI_TARGET_SWITCH };

export const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case ActionTypes.SET_TARGETS: {
      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: action.payload.map((target) => target.text),
      });

      return { ...state, targets: action.payload };
    }

    case ActionTypes.SET_ONE_TARGET: {
      const targets = state.targetsList.filter(
        (item) => item.value === action.payload,
      );

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: targets.map((target) => target.text),
      });

      return { ...state, targets };
    }

    case ActionTypes.SET_SEGMENTS: {
      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: action.payload.map((segment) => segment.text),
      });

      return { ...state, segments: action.payload };
    }

    case ActionTypes.SET_ONE_SEGMENT: {
      const segments = state.segmentsList.filter(
        (item) => item.value === action.payload,
      );

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: segments.map((segment) => segment.text),
      });

      return { ...state, segments };
    }

    case ActionTypes.SET_DEMOGRAPHIC: {
      if (action.payload) {
        trackFiltersChange({
          lastValidReport: state.lastValidReport,
          actionType: action.type,
          sectionName,
          selection: action.payload.text,
        });

        return { ...state, demographic: action.payload.value };
      }

      return state;
    }

    case ActionTypes.CHANGE_MULTI_TARGET_SWITCH: {
      const multiTarget = action.payload;
      const segments = !multiTarget
        ? [state.segmentsList[1], state.segmentsList[2]]
        : [state.segmentsList[1]];
      const targets =
        multiTarget && state.targetsList.length > 1
          ? [state.targetsList[0], state.targetsList[1]]
          : [state.targetsList[0]];

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: action.payload ? 'MULTIPLE_TARGETS' : 'MULTIPLE_SEGMENTS',
      });

      return { ...state, multiTarget, segments, targets };
    }

    default: {
      return state;
    }
  }
};
