import { IDataset } from '../domains/datasets/types';
import IDimension from '../domains/dimensions/types';
import IReport, { ISortOption } from '../domains/reports/types';
import { IQueryFilters, RuleFilter } from './filter';

export const ALL_VIEWING_INTERVAL = { id: 'ALL_VIEWING', name: 'All Viewing' };
export const DAY_INTERVAL = { id: 'DAY', name: 'Day' };

export const reportQueryInterval: IReportQueryInterval[] = [
  ALL_VIEWING_INTERVAL,
  { id: 'QUARTER_HOUR', name: '15 Minutes' },
  { id: 'HALF_HOUR', name: '30 Minutes' },
  { id: 'HOUR', name: 'Hour' },
  DAY_INTERVAL,
  { id: 'WEEK', name: 'Week' },
  { id: 'MONTH', name: 'Month' },
  { id: 'QUARTER', name: 'Quarter' },
  { id: 'YEAR', name: 'Year' },
];

export interface IReportQueryInterval {
  name: string;
  id: string;
}

export const reportSortingOrder = {
  DESC: 'Descending',
  ASC: 'Ascending',
};

export type ReportSortingOrder = keyof typeof reportSortingOrder;

export type ReportSortingOrders = {
  [key: string]: string;
};

export interface IReportSorting {
  field: string;
  order: ReportSortingOrder;
}

export type TimeFrames = {
  [key: string]: string;
};

export const timeFrames: TimeFrames = {
  Broadcast: 'BROADCAST',
  Calendar: 'CALENDAR',
} as const;

type TimeFrame = (typeof timeFrames)[keyof typeof timeFrames];

export type WeightingModes = {
  [key: string]: string;
};

export const weightingModes: WeightingModes = {
  UNWEIGHTED: 'WEIGHT_UNWEIGHTED',
  NATIONAL: 'WEIGHT_605_STANDARD',
  'DVR WEIGHTED': 'WEIGHT_605_DVR',
} as const;

type WeightingMode = (typeof weightingModes)[keyof typeof weightingModes];

export type Granularities = {
  [key: string]: string;
};

export const granularities: Granularities = {
  DEVICE: 'DEVICE',
  ACCOUNT: 'ACCOUNT',
} as const;

type Granularity = (typeof granularities)[keyof typeof granularities];

export type TimeZones = {
  [key: string]: string;
};

export const timeZones: TimeZones = {
  ET: 'America/New_York',
  CDT: 'America/Chicago',
  MDT: 'America/Denver',
  PDT: 'America/Los_Angeles',
  UTC: 'UTC',
} as const;

type TimeZone = (typeof timeZones)[keyof typeof timeZones];

export type IAttributionReportType = {
  id: string;
  name: string;
};

export const Modes = {
  linear: 'MODE_LINEAR',
  adverts: 'MODE_ADVERTS',
  customAdverts: 'MODE_CUSTOM_ADVERTS',
  genericEvents: 'MODE_GENERIC_EVENTS',
  placeIQ: 'MODE_PLACEIQ',
} as const;

export type Mode = (typeof Modes)[keyof typeof Modes];

export interface IQuerySettings {
  weight?: WeightingMode;
  sample_factor?: number;
  level?: Granularity;
  time_frame?: TimeFrame;
  time_zone?: TimeZone;
}

interface IGroupAdvanced {
  name: string;
  grouping_set: string[];
}

export interface IQuery<T = IQueryFilters, D = string[]>
  extends IQuerySettings {
  cumulative_group?: string;
  breakouts?: { id: string; name: string }[];
  datasets?: D;
  generic_events_datasets?: IDataset[];
  custom_schedule_datasets?: D;
  dimensions?: IDimension[];
  filters: T;
  group?: string[];
  groupsByAction?: boolean;
  interval?: IReportQueryInterval;
  mode?: Mode;
  select?: string[];
  sort_field?: ISortOption;
  sort_order?: ISortOption;
  sorting?: IReportSorting;
  targets?: string[];
  time_base?: string;
  type?: string;
  subType?: string;
  weight?: WeightingMode;
  crosswalks?: string[];
  vendor_sources?: number[];
  custom_event_datasets?: string[];
  ignore_compliance?: boolean;
  tableVisible?: boolean;
  chartVisible?: boolean;
  s3_qc_data_upload?: boolean;
  performance_metric_groups?: string[];
  breakout_categories?: T;
  includeTargetBreakdown?: boolean;
  group_advanced?: IGroupAdvanced[];
  daypart_dataset_id?: string;
  genpop_crosswalk_combination?: string;
}
export interface IQueryAAPI<T = IQueryFilters, D = IDataset[]>
  extends IQuerySettings {
  cumulative_group?: string;
  breakouts?: { id: string; name: string }[];
  datasets?: D;
  generic_events_datasets?: IDataset[];
  custom_schedule_datasets?: D;
  dimensions?: IDimension[];
  filters: T;
  group?: string[];
  groupsByAction?: boolean;
  interval?: IReportQueryInterval;
  mode?: Mode;
  select?: string[];
  sort_field?: ISortOption;
  sort_order?: ISortOption;
  sorting?: IReportSorting;
  targets?: (string | IReport)[];
  time_base?: string;
  type?: string;
  subType?: string;
  weight?: WeightingMode;
  crosswalks?: string[];
  vendor_sources?: number[];
  custom_event_datasets?: string[];
  ignore_compliance?: boolean;
  tableVisible?: boolean;
  chartVisible?: boolean;
  s3_qc_data_upload?: boolean;
  breakout_categories?: Partial<RuleFilter>[];
  daypart_dataset_id?: string;
  includeTargetBreakdown?: boolean;
  genpop_crosswalk_combination?: string[][];
}
