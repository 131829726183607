import { ResultBase, ResultBaseTotalDataTarget } from 'domains/reports/types';
import { getBreakoutDataTarget } from 'helpers/charts/chartWithTable/getBreakoutDataTarget';
import { filterOnNetwork } from './filterOnNetwork';
import { mergeBreakoutDataTargets } from './mergeBreakoutDataTargets';

export const getNetworkBreakoutDataTarget = (
  data: ResultBase,
  breakout: string,
  netOnOff: boolean,
): ResultBaseTotalDataTarget => {
  const parentBreakoutData = getBreakoutDataTarget(data, breakout);
  const childBreakoutData = getBreakoutDataTarget(data, 'NET_ON_OFF');

  return mergeBreakoutDataTargets({
    parentBreakoutData: netOnOff
      ? filterOnNetwork(parentBreakoutData)
      : parentBreakoutData,
    childBreakoutData,
    parentBreakout: breakout,
    childBreakout: 'NET_ON_OFF',
  });
};
