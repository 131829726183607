import {
  DataRowGraph,
  IMetaData,
  IDateRangeQueryFilter,
} from 'domains/reports/types';
import { getTestId, getClass } from 'helpers/components';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import merge from 'lodash/merge';
import React, { FunctionComponent, ReactElement } from 'react';
import color from 'theme/605/colors';
import createChartConfigWithCommonDesign from 'helpers/charts/commonChartDesignConfig';
import { getHighchartsConfig } from '../../domains/reports/adapters/results';
import {
  IOptions,
  ISGMConfig,
  OptionAnyTitle,
} from '../../domains/reports/types';
import { getChartColors, getChartTimezoneOffset } from './helpers';

HighchartsExporting(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsMore(Highcharts);
highcharts3d(Highcharts);

export const chartComponentName = 'chart';
export const ChartPrimaryColor = color.primary;
export const ChartBackgroundColor = color.white;

export interface IChartProps {
  data?: DataRowGraph[];
  metadata?: IMetaData[];
  options?: IOptions;
  testId?: string;
  title?: OptionAnyTitle;
  height?: null | number | string;
  disableAnimationAfterFirstRender?: boolean;
  SGMConfig: ISGMConfig;
  colors?: string[];
  config?: IOptions;
  isTarget?: boolean;
  dateRange?: IDateRangeQueryFilter;
}

const time = {
  getTimezoneOffset: getChartTimezoneOffset,
};

const Chart: FunctionComponent<IChartProps> = (props): ReactElement => {
  const {
    colors = [],
    config,
    data,
    disableAnimationAfterFirstRender,
    height,
    metadata = [],
    options,
    SGMConfig,
    testId,
    title,
    isTarget,
    dateRange,
  } = props;

  const isMultiSeries = !!SGMConfig.series.length;
  const chartColorsIndex = getChartColors(isMultiSeries);

  const configuredColors = chartColorsIndex.map(
    (chartColor: string, index: number) => colors[index] || chartColor,
  );
  const chartClass = getClass(chartComponentName);
  const chartTestId = getTestId(chartComponentName, testId);
  const configuration = {
    boost: { useGPUTranslations: true },
    title,
    time,
    backgroundColor: ChartBackgroundColor,
    colors: configuredColors,
    height,
    disableAnimationAfterFirstRender,
    isTarget,
  };

  let optionsAdaptedToHightcharts;

  const { exporting: exportingCommon } = createChartConfigWithCommonDesign();

  if (configuration && data) {
    optionsAdaptedToHightcharts = merge(
      getHighchartsConfig(data, metadata, SGMConfig, configuration, dateRange),
      options,
      config,
      {
        exporting: {
          ...exportingCommon,
        },
      },
    ) as IOptions;
  }

  return (
    <article className={chartClass} data-testid={chartTestId}>
      {optionsAdaptedToHightcharts && (
        <HighchartsReact
          allowChartUpdate
          immutable={!disableAnimationAfterFirstRender}
          highcharts={Highcharts}
          options={optionsAdaptedToHightcharts}
        />
      )}
    </article>
  );
};

export default React.memo(Chart);
