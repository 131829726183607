import { flow, get, set } from 'lodash/fp';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';
import IDomains from 'types/domains';
import { initialDomainsDateRanges } from '../businessData';

const initialState = {
  dimensions: [],
  metrics: [],
  dashboards: [],
  datasets: [],
  dateRanges: initialDomainsDateRanges,
};

const setAttributionReports = set('attributionReports');
const setClients = set('clients');
const setDashboards = set('dashboards');
const setDatasets = set('datasets');
const setDimensions = set('dimensions');
const setMetrics = set('metrics');
const setPermissions = set('permissions');
const setDateRanges = set('dateRanges');
const setRootLoaded = set('loaded', true);
const setDomainsLoaded = set('loaded', true);

enum OperatorMap {
  'IN' = 1,
  'NOT IN' = 2,
  'NOT HAVE' = 3,
  'CONTAINS' = 4,
  'NOT CONTAINS' = 5,
}

const defineOperatorSortWeight = (operator: string): number =>
  OperatorMap[operator as keyof typeof OperatorMap] ?? 0;

const reducer = (
  domains: IDomains = initialState,
  action: Action<IDomains>,
): IDomains => {
  const { payload, type } = action;

  const dashboards = get('dashboards', payload);
  const datasets = get('datasets', payload);
  const dateRanges = get('dateRanges', payload);
  const dimensions = (get('dimensions', payload) ?? []).map((item) => {
    const operators = (get('operators', item) ?? []).sort(
      (op1: string, op2: string) => {
        const op1Weight = defineOperatorSortWeight(op1);
        const op2Weight = defineOperatorSortWeight(op2);

        return op1Weight - op2Weight;
      },
    );
    return { ...item, operators };
  });
  const metrics = get('metrics', payload);

  switch (type) {
    case ActionType.DOMAINS_INIT:
      return flow(
        setDashboards(dashboards),
        setDatasets(datasets),
        setDimensions(dimensions),
        setMetrics(metrics),
        setRootLoaded,
        setDomainsLoaded,
        setDateRanges(dateRanges),
      )(domains);

    case ActionType.DOMAINS_SET_CLIENTS:
      return setClients(payload, domains);
    case ActionType.DOMAINS_SET_DASHBOARDS:
      return setDashboards(payload, domains);
    case ActionType.DOMAINS_SET_DATASETS:
      return setDatasets(payload, domains);
    case ActionType.DOMAINS_SET_DIMENSIONS:
      return setDimensions(payload, domains);
    case ActionType.DOMAINS_SET_METRICS:
      return setMetrics(payload, domains);
    case ActionType.DOMAINS_SET_PERMISSIONS:
      return setPermissions(payload, domains);
    case ActionType.DOMAINS_SET_ATTRIBUTION_REPORTS:
      return setAttributionReports(payload, domains);
    case ActionType.DOMAINS_SET_DATE_RANGES:
      return setDateRanges(payload, domains);
    default:
      return domains;
  }
};

export default reducer;
