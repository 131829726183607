import get from 'lodash/fp/get';
import React, { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from 'store/reducers';

const devToolsMiddleware =
  process.env.NODE_ENV === 'development' &&
  get('__REDUX_DEVTOOLS_EXTENSION__', window)
    ? composeWithDevTools({
        trace: true,
        traceLimit: 25,
      })(applyMiddleware(thunk))
    : applyMiddleware(thunk);

const store = createStore(reducers, devToolsMiddleware);

export function RenderHookProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  return <Provider store={store}>{children}</Provider>;
}

export function RenderHookProviderWithRouter({
  children,
}: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  return (
    <RenderHookProvider>
      <BrowserRouter>{children}</BrowserRouter>
    </RenderHookProvider>
  );
}

export default store;
