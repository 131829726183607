interface IMetric {
  cume_description?: string;
  cume_name?: string;
  id: string;
  _id?: string;
  name: string;
  filter?: string;
  description?: string;
  hideFor?: string[];
  group?: string;
  tooltip?: string;
  isCummulative?: () => boolean;
  content_unsupported?: boolean;
  adverts_unsupported?: boolean;
  custom_adverts_unsupported?: boolean;
  generic_events_unsupported?: boolean;
}

export const Metrics = {
  reach: 'REACH',
  impressions: 'IMPRESSIONS',
  avgAudience: 'AVG_AUDIENCE',
  shareOfViewing: 'SHARE_OF_VIEWING',
  avgWatched: 'AVG_WATCHED',
  frequency: 'FREQUENCY',
  share: 'SHARE',
  rating: 'RATING',
  totalWatched: 'TOTAL_WATCHED',
  hour: 'HOUR',
  platform: 'VIEWING_TYPE',
} as const;

export type Metric = (typeof Metrics)[keyof typeof Metrics] | string;

export default IMetric;
