import HighlightCard from 'components/HighlightCard';
import { Type as IconType } from 'components/Icon';
import {
  PerformanceGroupNames,
  ResultBaseSubStructure,
} from 'domains/reports/types';
import { getClass, getTestId } from 'helpers/components';
import React from 'react';
import useScroll from 'hooks/useScroll';
import { mainPerfectScrollbar } from 'features';
import { getTop4PersuableAudiencesCardsContent } from 'helpers/visualization';

export interface ICardContentProps {
  card: ResultBaseSubStructure;
  performanceMetric: PerformanceGroupNames;
  conversionsMetricName: string;
  selectedTarget: string;
}

const BreakoutIconMap: { [x: string]: IconType } = {
  NETWORK: IconType.network,
  NET_ON_OFF: IconType.network,
  CREATIVE_LABEL: IconType.creative,
  DAYPART: IconType.daypart,
  CONTENT_SERIES_TITLE: IconType.tvAd,
  NETWORK_GENRE: IconType.genre,
  DAY_OF_WEEK: IconType.calendar,
  DURATION_ROUNDED_LABEL: IconType.adDuration,
  PLATFORM: IconType.devices,
};

const componentName = 'card-content';
const cardContentWrapper = getClass(componentName, { add: ['wrapper'] });
const cardContentTextClass = getClass(componentName, { add: ['bold-text'] });
const cardTitleClass = getClass(componentName, {
  add: ['bold-text', 'ellipsis'],
});

export const persuasionInfoTestId = getTestId(componentName, 'persuasion-info');

export const CardContent: React.FC<ICardContentProps> = ({
  card,
  performanceMetric,
  conversionsMetricName,
  selectedTarget,
}) => {
  const { goTo } = useScroll(mainPerfectScrollbar);
  const icon =
    BreakoutIconMap[card?.BREAKOUT?.VALUE as string] || IconType.genre;

  const {
    title,
    persuasionValue,
    persuasionInfo,
    incrementalConverter,
    performanceMetricFriendlyString,
  } = getTop4PersuableAudiencesCardsContent(
    card,
    performanceMetric,
    conversionsMetricName,
    selectedTarget,
  );

  const renderPersuasionInfo = (): React.ReactElement =>
    persuasionValue ? (
      <>
        <span className={cardContentTextClass}>{persuasionValue}</span>{' '}
        {persuasionInfo}
      </>
    ) : (
      <>{persuasionInfo}</>
    );

  return (
    <HighlightCard
      iconType={icon}
      clickToScroll={() => {
        goTo('#CAMPAIGN_DELIVERY_AND_INSIGHTS');
      }}
    >
      <div className={cardContentWrapper}>
        <div className={cardTitleClass} title={title}>
          {title}
        </div>{' '}
        was{' '}
        <span data-testid={persuasionInfoTestId}>{renderPersuasionInfo()}</span>
        , driving{' '}
        <span className={cardContentTextClass}>{incrementalConverter}</span>{' '}
        <span>{performanceMetricFriendlyString}.</span>
      </div>
    </HighlightCard>
  );
};
