import Modal, { Type } from 'components/Modal';
import Flex, { Horizontal } from 'components/Flex';
import Button, { Kind, Type as ButtonType } from 'components/Button';
import React, { FC } from 'react';
import { IModalDialogProps } from '../types';

export const ModalDialog: FC<IModalDialogProps> = ({
  isOpen,
  onConfirm,
  onClose,
  message,
  closeLabel,
  confirmLabel,
}) => (
  <Modal isOpen={isOpen} type={Type.dialog} onCloseClick={onClose}>
    <section className="user-dimension-form-modal">{message}</section>
    <footer>
      <Flex
        horizontal={Horizontal.right}
        className="user-dimension-form-modal-footer"
      >
        {closeLabel && (
          <Button
            type={ButtonType.button}
            kind={Kind.outline}
            onClick={onClose}
          >
            {closeLabel}
          </Button>
        )}
        {confirmLabel && (
          <Button
            type={ButtonType.button}
            kind={Kind.primary}
            onClick={onConfirm}
          >
            {confirmLabel}
          </Button>
        )}
      </Flex>
    </footer>
  </Modal>
);
