import Flex, { Direction, Horizontal, Vertical } from 'components/Flex';
import {
  addDays,
  addMonths,
  addWeeks,
  isAfter,
  isBefore,
  subMonths,
  subWeeks,
} from 'date-fns';
import subDays from 'date-fns/subDays';
import { getClass, getTestId } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';

const LOCALE_MONTH = 'Month';
const LOCALE_WEEK = 'Week';
const LOCALE_DAY = 'Day';

interface IDatePresets {
  handleRangeChange: (date1: Date, date2: Date) => void;
  startDate?: string;
  endDate?: string;
  minDate?: string;
  maxDate?: string;
  testId?: string;
}

interface ActionButton {
  action: () => void;
  disabled?: boolean;
  text: string;
  buttonTestId?: string;
}
const componentName = 'date-presets';

const componentClass = getClass(componentName);

export const DatePresets: FunctionComponent<IDatePresets> = ({
  handleRangeChange,
  startDate: startDateString,
  endDate: endDateString,
  minDate: minDateString,
  maxDate: maxDateString,
  testId,
}): ReactElement => {
  const startDate = new Date(startDateString ?? Date.now());
  const endDate = new Date(endDateString ?? Date.now());

  const minDate = minDateString ? new Date(minDateString) : undefined;
  const maxDate = maxDateString ? new Date(maxDateString) : undefined;

  const componentTestId = getTestId(componentName, testId);

  const actionButtons: ActionButton[] = [
    {
      text: `- 1 ${LOCALE_MONTH}`,
      action: () => handleRangeChange(endDate, addMonths(startDate, -1)),
      disabled: minDate ? isBefore(subMonths(startDate, 1), minDate) : false,
      buttonTestId: 'minus-1-month',
    },
    {
      text: `- 1 ${LOCALE_WEEK}`,
      action: () => handleRangeChange(endDate, addDays(startDate, -7)),
      disabled: minDate ? isBefore(subWeeks(startDate, 1), minDate) : false,
      buttonTestId: 'minus-1-week',
    },
    {
      text: `- 1 ${LOCALE_DAY}`,
      action: () => handleRangeChange(endDate, addDays(startDate, -1)),
      disabled: minDate ? isBefore(subDays(startDate, 1), minDate) : false,
      buttonTestId: 'minus-1-day',
    },
    {
      text: `+ 1 ${LOCALE_DAY}`,
      action: () => handleRangeChange(startDate, addDays(endDate, 1)),
      disabled:
        endDateString && maxDate
          ? isAfter(addDays(endDate, 1), maxDate)
          : false,
      buttonTestId: 'plus-1-day',
    },
    {
      text: `+ 1 ${LOCALE_WEEK}`,
      action: () => handleRangeChange(startDate, addDays(endDate, 7)),
      disabled:
        endDateString && maxDate
          ? isAfter(addWeeks(endDate, 1), maxDate)
          : false,
      buttonTestId: 'plus-1-week',
    },
    {
      text: `+ 1 ${LOCALE_MONTH}`,
      action: () => handleRangeChange(startDate, addMonths(endDate, 1)),
      disabled:
        endDateString && maxDate
          ? isAfter(addMonths(endDate, 1), maxDate)
          : false,
      buttonTestId: 'plus-1-month',
    },
  ];

  return (
    <Flex
      className={componentClass}
      data-testid={componentTestId}
      direction={Direction.column}
      horizontal={Horizontal.left}
      vertical={Vertical.top}
    >
      <Flex className="presets-wrapper custom" vertical={Vertical.top}>
        <div className="presets-column">
          {actionButtons.map(
            ({
              disabled: disabledField = false,
              text,
              action,
              buttonTestId,
            }) => (
              <button
                type="button"
                key={text}
                onClick={(e) => {
                  e.preventDefault();
                  action();
                }}
                disabled={disabledField}
                className="presets-button"
                data-testid={buttonTestId}
              >
                {text}
              </button>
            ),
          )}
        </div>
      </Flex>
    </Flex>
  );
};
