import { v4 as uuid } from 'uuid';
import { IDashboardReport, Dashboard } from './types';

const DEFAULT_WIDTH = 6;
const DEFAULT_HEIGHT = 6;

export const newDashboardReport = (): IDashboardReport => ({
  id: uuid(),
  reportId: '',
  width: 'Full',
  sizeX: DEFAULT_WIDTH,
  sizeY: DEFAULT_HEIGHT,
  col: 0,
  row: 0,
  divisor: 1,
  decimal_places: 0,
  type: 'table',
  title: '',
  unit_label: '',
  time_label: '',
  bg_color: '',
  font_color: '',
  title_color: '',
  chart_color: '',
  chart_colors: [],
  limit: 10,
  on_hover_details: '',
  selectVal: '',
});

export const newDashboard = (userId?: string, client?: string): Dashboard => ({
  userId: userId ?? '',
  client,
  reports: [],
});
