import Button, {
  Type as ButtonType,
  Size as ButtonSize,
} from 'components/Button';
import Flex, { Direction, Vertical } from 'components/Flex';
import Icon, { Type as IconType, Size as IconSize } from 'components/Icon';
import { mainPerfectScrollbar } from 'features';
import { getClass } from 'helpers/components';
import useScroll from 'hooks/useScroll';
import React from 'react';
import { connect } from 'react-redux';
import * as reportActions from 'store/actions/report';

const chartOmissionAlertComponentName = 'chart-omission-alert';
const chartOmissionAlertComponentClass = getClass(
  chartOmissionAlertComponentName,
);

interface IProps {
  setAccordionQueryOpen: () => void;
}

const ChartOmissionAlert: React.FC<IProps> = ({ setAccordionQueryOpen }) => {
  const { goTo } = useScroll(mainPerfectScrollbar);

  const handleEditMyQueryClick = (): void => {
    setAccordionQueryOpen();
    return goTo('#EDIT_REPORT_QUERY_SECTION');
  };

  return (
    <Flex
      className={chartOmissionAlertComponentClass}
      direction={Direction.column}
      vertical={Vertical.top}
    >
      <Icon type={IconType.alertTriangle} size={IconSize.ultraLarge} />
      <p className={`${chartOmissionAlertComponentClass}__title`}>
        There are no results to return.
      </p>
      <p className={`${chartOmissionAlertComponentClass}__sub-title`}>
        Try adjusting your query configuration to find what you’re looking for.
      </p>
      <Button
        type={ButtonType.button}
        size={ButtonSize.small}
        onClick={handleEditMyQueryClick}
      >
        Edit My Query
      </Button>
      <p className={`${chartOmissionAlertComponentClass}__aside-text`}>
        If you keep having trouble, reach out for support.
      </p>
    </Flex>
  );
};

const mapDispatchToProps = {
  setAccordionQueryOpen: reportActions.setAccordionQueryOpen,
};

export default connect(null, mapDispatchToProps)(ChartOmissionAlert);
