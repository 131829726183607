import { parseISO } from 'date-fns';
import {
  IDashboardReportData,
  IDashboardReportExecutionEndedPayload,
  IDashboardReportExecutionPayload,
  IDashboardReportExecutionUpdatedPayload,
} from 'domains/dashboard/types';
import {
  formatISO,
  getDatasetDefaultDateRange,
} from 'domains/reports/adapters/date';
import { getSGMConfig } from 'domains/reports/adapters/results';
import IReport, {
  IExecuteResponse,
  IExecuteStatusResponse,
  IMetaData,
  DataRowGraph,
  RowsDropped,
  cacheMode,
} from 'domains/reports/types';
import { BUTTONS } from 'helpers/datepicker';
import { IButtonMetadata } from 'helpers/datepicker/types';
import { fetchApi } from 'helpers/fetching';
import { delay, getUSLocaleStringFromSeconds } from 'helpers/utils';
import { get } from 'lodash';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Index, Actions } from 'routes';
import ActionType from 'store/actions/types';
import * as dashboardSelector from 'store/selectors/dashboard';
import * as userSelector from 'store/selectors/user';
import * as domainSelector from 'store/selectors/domains';
import { Action } from 'types/action';
import IDateRange from 'types/dateRange';
import FetchMethod from 'types/fetchMethod';
import { Mode } from 'types/query';
import IRunningQuery from 'types/runningQuery';
import State from 'types/state';

export const loadDashboardReportStarted = (id: string): Action<string> => ({
  type: ActionType.DASHBOARD_REPORT_LOAD_STARTED,
  payload: id,
});

export const loadDashboardReportEnded = (
  payload: IDashboardReportData,
): Action<IDashboardReportData> => ({
  type: ActionType.DASHBOARD_REPORT_LOAD_ENDED,
  payload,
});

export const dashboardReportExecutionStarted = (
  payload: IDashboardReportExecutionPayload,
): Action<IDashboardReportExecutionPayload> => ({
  type: ActionType.DASHBOARD_REPORT_EXECUTION_STARTED,
  payload,
});

export const dashboardReportExecutionUpdated = (
  cacheQueryId: string,
  progress: number,
): Action<IDashboardReportExecutionUpdatedPayload> => ({
  type: ActionType.DASHBOARD_REPORT_EXECUTION_UPDATED,
  payload: {
    cacheQueryId,
    progress,
  },
});

export const dashboardReportExecutionEnded = (
  cacheQueryId: string,
  data: DataRowGraph[],
  metadata: IMetaData[],
  executed_at: string | undefined,
  rows_dropped: number | RowsDropped | undefined,
): Action<IDashboardReportExecutionEndedPayload> => ({
  type: ActionType.DASHBOARD_REPORT_EXECUTION_ENDED,
  payload: {
    cacheQueryId,
    data,
    metadata,
    executed_at,
    rows_dropped,
  },
});

export const dashboardReportReset = (id: string): Action<string> => ({
  type: ActionType.DASHBOARD_REPORT_RESET,
  payload: id,
});

export const dashboardReportCheckExecution =
  (cacheQueryId: string) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const statusResponse = await fetchApi({
      endpoint: `/${Index.SEGMENT_REPORTS}/${Actions.SEGMENT_STATUS}`,
      payload: {
        cacheQueryId,
      },
      method: FetchMethod.POST,
    });
    const statusData = statusResponse.data[0] as IExecuteStatusResponse;
    if (statusData.progress !== 100) {
      await delay(1500);
      dispatch(
        dashboardReportExecutionUpdated(statusData.id, statusData.progress),
      );
      dispatch(dashboardReportCheckExecution(cacheQueryId));
      return;
    }
    const state = getState();
    const dashboardReports = dashboardSelector.getDashboardReports(state);
    const currentReport = dashboardReports.find(
      (item: IDashboardReportData) =>
        item.execution?.cacheQueryId === cacheQueryId,
    );

    const currentReportData = currentReport?.data;
    const selectedClient = userSelector.getSelectedClient(state);
    const executeResponse = await fetchApi({
      endpoint: `/${Index.SEGMENT_REPORTS}/${Actions.SEGMENT_EXECUTE}`,
      payload: {
        cacheQueryId,
        clientId: selectedClient,
        report: currentReportData,
      },
      method: FetchMethod.POST,
    });
    const executeResponseData = executeResponse.data[0] as IExecuteResponse;
    dispatch(
      dashboardReportExecutionEnded(
        cacheQueryId,
        executeResponseData?.result?.data as DataRowGraph[],
        executeResponseData?.result?.metadata as IMetaData[],
        getUSLocaleStringFromSeconds(executeResponseData?.result?.executed_at),
        executeResponseData?.result?.rows_dropped ?? 0,
      ),
    );
  };

export const processDashboardReport =
  (id: string) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const dashboardsReports = dashboardSelector.getDashboardReports(state);
    const isInitialized = dashboardsReports.find(
      (item: IDashboardReportData) => item.id === id,
    );
    if (isInitialized) {
      return;
    }

    dispatch(loadDashboardReportStarted(id));
    const selectedClient = userSelector.getSelectedClient(state);
    try {
      const dashboardReportResponse = await fetchApi({
        endpoint: `/${Index.SEGMENT_REPORTS}/${Index.SEGMENT_DASHBOARDS}/${id}`,
        payload: {
          clientId: selectedClient,
          id,
        },
        method: FetchMethod.POST,
      });
      const data = dashboardReportResponse.data[0] as IReport;
      const filtersChildren = (data?.query?.filters?.children ??
        []) as IDateRange[];
      const relativeDateOffsetFilterIndex = filtersChildren.findIndex(
        (item) => !!item.relativeDateOffset,
      );

      const SGMConfig = getSGMConfig(
        data.query,
        data.selectedMetrics ?? [],
        data.selectedCharts ?? [],
      );

      let updatedFiltersChildren = filtersChildren;
      if (relativeDateOffsetFilterIndex >= 0) {
        const movingDateFilter = (data?.query?.filters?.children ?? [])[
          relativeDateOffsetFilterIndex
        ] as IDateRange;

        const { dateMax } = getDatasetDefaultDateRange(
          domainSelector.getDomainsDateRanges(state),
          data.query.mode as Mode,
        );

        const { start: movingDateStart, end: movingDateEnd } = (
          BUTTONS[
            movingDateFilter.relativeDateOffset as string
          ] as IButtonMetadata
        )?.getRange(parseISO(dateMax));

        updatedFiltersChildren = filtersChildren.map((item) => {
          if (item.relativeDateOffset) {
            return {
              ...item,
              start: formatISO(movingDateStart),
              end: formatISO(movingDateEnd),
            };
          }
          return item;
        });
      }

      const payload: IReport = {
        ...data,
        cache_mode: cacheMode.default,
        query: {
          ...get(data, 'query', {}),
          filters: {
            ...get(data, 'query.filters', {}),
            children: updatedFiltersChildren,
          },
        },
      };

      dispatch(
        loadDashboardReportEnded({
          id,
          isRunning: true,
          isLoading: false,
          hasMovingDates: relativeDateOffsetFilterIndex >= 0,
          data: payload,
          SGMConfig,
        }),
      );
      const executeResponse = await fetchApi({
        endpoint: `/${Index.SEGMENT_REPORTS}/${Actions.SEGMENT_EXECUTE}`,
        payload: {
          doComplexityCheck: true,
          report: payload,
        },
        method: FetchMethod.POST,
      });
      const executeData = executeResponse.data[0] as IRunningQuery;
      dispatch(
        dashboardReportExecutionStarted({
          reportId: id,
          cacheQueryId: executeData.query_id,
          clientId: selectedClient,
        }),
      );
      await delay(1500);
      dispatch(dashboardReportCheckExecution(executeData.query_id));
    } catch (e) {
      dispatch(
        loadDashboardReportEnded({
          id,
          isRunning: false,
          isLoading: false,
          hasError: true,
        }),
      );
    }
  };
