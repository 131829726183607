import { HighlightedText } from 'components/HighlightedText';
import { Style, Tooltip } from 'components/Tooltip';
import { getClass, getTestId } from 'helpers/components';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import React, { ReactElement } from 'react';
import ITextValue from 'types/textValue';

export const componentName = 'options-tree-item';

interface IProps {
  item: ITextValue;
  level?: number;
  onClick: (e: string) => void;
  testId?: string;
  isSelected?: boolean;
  highlighted?: string;
}

const OptionsTreeItem = (props: IProps): ReactElement => {
  const {
    onClick,
    item,
    level = 0,
    testId,
    isSelected,
    highlighted = '',
  } = props;

  const { value, text, icon, tooltip, isUnmatched } = item;

  const defaultClass = getClass(componentName, {
    add: [`level-${level}`],
    boolean: [
      { state: isSelected, class: 'selected' },
      { state: isUnmatched, class: 'is_unmatched' },
    ],
  });

  const itemTestId = getTestId(
    componentName,
    `${testId ? `${testId}-` : ''}${value}`,
  );

  const iconTestId = getTestId(
    componentName,
    `icon-${testId ? `${testId}-` : ''}${value}`,
  );
  const iconClass = getClass(componentName, { add: ['icon'] });

  const textClass = getClass(componentName, { add: ['text'] });

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    const newValue = (event.currentTarget as HTMLInputElement).dataset.value;
    onClick(isNil(newValue) ? '' : newValue);
  };

  const treeItemValue = isNil(value) ? '' : value;

  const optionsTreeItemElement = (
    <li
      data-testid={itemTestId}
      key={value}
      onClick={handleClick}
      onKeyDown={noop}
      data-value={treeItemValue}
      className={defaultClass}
      title={text}
    >
      {icon && (
        <span className={iconClass} data-testid={iconTestId}>
          {icon}
        </span>
      )}
      {(highlighted.length > 1 && (
        <HighlightedText
          text={text}
          highlighted={highlighted}
          textClass={textClass}
        />
      )) || <span className={textClass}>{text}</span>}
    </li>
  );

  if (tooltip) {
    return (
      <Tooltip style={Style.secondary} content={item.tooltip}>
        {optionsTreeItemElement}
      </Tooltip>
    );
  }
  return optionsTreeItemElement;
};

export default OptionsTreeItem;
