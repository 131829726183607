import { useDatasets } from 'domains/datasets/hooks';
import IReport, { IAttributionReport } from 'domains/reports/types';

type HookReturn = {
  exportReport: (reportConfig: IReport | IAttributionReport) => void;
};

export const useReportConfiguration = (): HookReturn => {
  const { getDataset } = useDatasets();

  /** Exports the passed report configuration to JSON
   * @param reportConfig {IReport | IAttributionReport} - The report configuration to export
   * */
  const exportReport = (reportConfig: IReport | IAttributionReport): void => {
    // We shouldn't support exports of non-saved reports
    if (!reportConfig.id) {
      throw Error('Report must be saved prior to exporting');
    }

    // If this report configuration uses custom datasets
    // we'll have to replace their ID for their dataset name
    if (reportConfig.query.datasets && reportConfig.query.datasets.length > 0) {
      reportConfig.query.datasets = reportConfig.query.datasets.map(
        (datasetId) => {
          const datasetName = getDataset(datasetId)?.name ?? '';
          return datasetName;
        },
      );
    }

    // Create link object for downloading the report config
    const downloadEl = document.createElement('a');
    const file = new Blob([JSON.stringify(reportConfig, null, 4)], {
      type: 'text/json',
    });
    downloadEl.href = URL.createObjectURL(file);

    // Use the link to download the file, release object URL when done
    downloadEl.download = `${reportConfig.name}.json`;
    downloadEl.click();

    URL.revokeObjectURL(downloadEl.href);
  };

  return {
    exportReport,
  };
};
