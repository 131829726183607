import H1 from 'components/H1';
import InlineAlert, {
  AlertTypes,
  BackgroundColors,
} from 'components/InlineAlert';
import Loading from 'components/Loading';
import { getClass, getTestId } from 'helpers/components';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Index } from 'routes';
import { toLower } from 'lodash';
import { ICrosswalkCombinationDetails } from 'domains/datasets/types';
import { UploadEditForm, UploadEditFormProps } from './components/edit-form';
import { UserDimensions } from './components/UserDefinedDimensions/UserDimensions';
import { CrosswalkCombination } from './components/CrosswalkCombination';
import { IUserDimensionsProps } from './components/UserDefinedDimensions/types';

type FunctionsProps<T> = {
  [K in keyof T]: T[K] extends (...args: infer J) => unknown ? K : never;
}[keyof T];

export type OwnState = Omit<
  UploadEditFormProps,
  FunctionsProps<UploadEditFormProps>
> &
  Omit<
    IUserDimensionsProps,
    FunctionsProps<IUserDimensionsProps> | 'isEdit'
  > & {
    errorMessage: string;
    crosswalkCombinationDetails: ICrosswalkCombinationDetails;
  };

export type OwnActions = Pick<
  UploadEditFormProps,
  FunctionsProps<UploadEditFormProps>
> &
  Pick<IUserDimensionsProps, FunctionsProps<IUserDimensionsProps>> & {
    resetErrorMessage: () => void;
  };
export const uploadsComponentName = 'uploads';

const uploadsTestId = getTestId(uploadsComponentName);
const uploadsDefaultClass = getClass(uploadsComponentName);

export const Uploads: FunctionComponent<OwnState & OwnActions> = ({
  errorMessage,
  resetErrorMessage,
  datasetLoading,
  fetchUploadsMetadata,
  crosswalkSources,
  uploadFormReset,
  dimensions,
  userDimensionDetails,
  uploadForm,
  uploadFormUpdated,
  fileTypeOptions,
  dataProviderOptions,
  handleValidation,
  handleFileUpload,
  handleSaveAction,
  uploadPercentage,
  handleUploadSubmit,
  isLoadingUpdate,
  finishProcessing,
  selectedClient,
  userEmail,
  clients,
  uploadFormChanged,
  validationErrors,
  updatePercentage,
  startProcessing,
  fetchDomains,
  crosswalkCombinationDetails,
}) => {
  const { id, type } = useParams();
  const [datasetType, setDatasetType] = useState<string>();
  const editing = id !== undefined;
  const loadDataset = useCallback(async (): Promise<void> => {
    const dataset = await fetchUploadsMetadata(id);

    if (dataset) {
      setDatasetType(toLower(dataset.type).replace('_', '-'));
    } else {
      setDatasetType(type);
    }
  }, [fetchUploadsMetadata, id, type]);
  useEffect(() => {
    loadDataset();
  }, [loadDataset]);

  useEffect(() => uploadFormReset, [uploadFormReset]);

  if (datasetLoading) {
    return <Loading />;
  }
  return (
    <section className={uploadsDefaultClass} data-testid={uploadsTestId}>
      {errorMessage && (
        <div className="top-inline-alert-container">
          <InlineAlert
            message={errorMessage}
            mode={AlertTypes.warn}
            backgroundColor={BackgroundColors.white}
            onClose={resetErrorMessage}
          />
        </div>
      )}
      <H1>Uploads</H1>
      {datasetType !== Index.SEGMENT_USER_DIMENSION &&
        datasetType !== Index.SEGMENT_CROSSWALK_COMBINATION && (
          <UploadEditForm
            clients={clients}
            dataProviderOptions={dataProviderOptions}
            fileTypeOptions={fileTypeOptions}
            finishProcessing={finishProcessing}
            handleFileUpload={handleFileUpload}
            handleSaveAction={handleSaveAction}
            handleUploadSubmit={handleUploadSubmit}
            handleValidation={handleValidation}
            uploadForm={uploadForm}
            uploadFormUpdated={uploadFormUpdated}
            isLoadingUpdate={isLoadingUpdate}
            selectedClient={selectedClient}
            userEmail={userEmail}
            uploadFormChanged={uploadFormChanged}
            startProcessing={startProcessing}
            uploadPercentage={uploadPercentage}
            updatePercentage={updatePercentage}
            validationErrors={validationErrors}
            dimensions={dimensions}
            datasetLoading={datasetLoading}
            crosswalkSources={crosswalkSources}
            fetchUploadsMetadata={fetchUploadsMetadata}
            uploadFormReset={uploadFormReset}
          >
            <div className="inline-alert-container">
              <InlineAlert
                message={`Closing this window before clicking the "${
                  editing ? 'SAVE' : 'UPLOAD'
                }" button will result in the loss of all data.`}
                mode={AlertTypes.warn}
              />
            </div>
          </UploadEditForm>
        )}
      {datasetType === Index.SEGMENT_USER_DIMENSION && (
        <UserDimensions
          dimensions={dimensions}
          fetchDomains={fetchDomains}
          isEdit={editing}
          userDimensionDetails={userDimensionDetails}
          clients={clients}
        />
      )}
      {datasetType === Index.SEGMENT_CROSSWALK_COMBINATION && (
        <CrosswalkCombination
          crosswalkCombinationDetails={crosswalkCombinationDetails}
          clients={clients}
          fetchDomains={fetchDomains}
          crosswalkSources={crosswalkSources}
        />
      )}
    </section>
  );
};
