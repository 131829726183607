import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Button';
import Field from 'components/Field';
import Flex, { Horizontal } from 'components/Flex';
import { Type as InputType, NewInputValue } from 'components/Input';
import SkeletonForm from 'components/Skeleton/Form';
import IDashboard, { Dashboard } from 'domains/dashboard/types';
import { getTestId, getClass, getId } from 'helpers/components';
import React, { FunctionComponent } from 'react';
import { useForm } from '../../../../hooks/useForm';
import { validationSchema } from './validations';

const LOCALE_FIELD_NAME_LABEL = 'Name';
const LOCALE_FIELD_NAME_PLACEHOLDER = 'Add new dashboard name';
const LOCALE_ACTION_OK = 'Save';
const LOCALE_ACTION_CANCEL = 'Cancel';
const LOCALE_ACTION_OK_LOADING = 'Saving...';

export const formComponentName = 'dashboard-form';

export interface IFormProps {
  dashboard: IDashboard;
  loading?: boolean;
  onSubmit: (dashboard: Dashboard) => void;
  onCancel: () => void;
  testId?: string;
}

const Form: FunctionComponent<IFormProps> = (props) => {
  const { dashboard, loading = true, onSubmit, onCancel, testId } = props;

  const { errors, setFieldValue, handleSubmit, values } = useForm<IDashboard>(
    dashboard,
    validationSchema,
    onSubmit,
  );

  const formId = getId(formComponentName);
  const formTestId = getTestId(formComponentName, testId);
  const formDefaultClass = getClass(formComponentName);
  const saveButtonTestId = getClass(formComponentName, { concat: ['save'] });
  const cancelButtonTestId = getClass(formComponentName, {
    concat: ['cancel'],
  });
  const inputNameTestId = getClass(formComponentName, { concat: ['name'] });

  return (
    <section className={formDefaultClass} data-testid={formTestId}>
      {loading ? (
        <SkeletonForm
          fields={{ inputs: [{ type: 'text' }] }}
          actions={{ buttons: { yes: true } }}
        />
      ) : (
        <form onSubmit={handleSubmit} data-testid={`${formTestId}-form`}>
          <Field
            label={LOCALE_FIELD_NAME_LABEL}
            type={InputType.text}
            id={`${formId}-name`}
            name={`${formId}-input`}
            placeholder={LOCALE_FIELD_NAME_PLACEHOLDER}
            onChange={(val: NewInputValue): void => setFieldValue('name', val)}
            value={values.name}
            hasError={!!errors.name}
            errorMessage={errors.name?.toString() ?? ''}
            testId={inputNameTestId}
          />
          <footer>
            <Flex horizontal={Horizontal.between}>
              <Button
                type={ButtonType.button}
                kind={ButtonKind.text}
                onClick={onCancel}
                testId={cancelButtonTestId}
              >
                {LOCALE_ACTION_CANCEL}
              </Button>
              <Button
                type={ButtonType.submit}
                kind={ButtonKind.primary}
                size={ButtonSize.small}
                disabled={loading}
                testId={saveButtonTestId}
              >
                {loading ? LOCALE_ACTION_OK_LOADING : LOCALE_ACTION_OK}
              </Button>
            </Flex>
          </footer>
        </form>
      )}
    </section>
  );
};

export default Form;
