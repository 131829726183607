import Modal, { Type } from 'components/Modal';
import ModalContent from 'components/Modal/ModalContent';
import React, { ReactElement, ReactNode } from 'react';

export const componentName = 'dialog';

type Props = {
  children: ReactNode;
  customClass?: string;
  footer?: ReactNode;
  header?: string;
  isOpen: boolean;
  onAfterOpen?: () => void;
  onCloseClick?: () => void;
  testId?: string;
};

const Dialog = (props: Props): ReactElement => {
  const {
    children,
    customClass,
    footer,
    header,
    isOpen,
    onAfterOpen,
    onCloseClick,
    testId,
  } = props;
  return (
    <Modal
      customClass={customClass}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onCloseClick={onCloseClick}
      testId={testId}
      type={Type.dialog}
    >
      <ModalContent
        footer={footer}
        header={header}
        testId={testId}
        type={Type.dialog}
      >
        {children}
      </ModalContent>
    </Modal>
  );
};

export default Dialog;
