import ListActions from 'components/ListActions';
import Loading from 'components/Loading';
import Table, { textOverflows, alignments, Alignment } from 'components/Table';
import IReport, { ReportType, reportType } from 'domains/reports/types';
import { getTestId, getClass } from 'helpers/components';
import useUserPermissions from 'hooks/useUserPermissions';
import { get } from 'lodash/fp';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { CellProps, Column } from 'react-table';
import { Actions } from 'routes';
import * as rootSelectors from 'store/selectors/root';
import State from 'types/state';
import { getReportSubTypeName } from 'domains/reports/adapters/general';
import DateTimeCell from './DateTimeCell';

export const reportsListComponentName = 'reports-list';

interface IProps {
  attributionReportList: IReport[];
  onDeleteClick: (report: IReport) => void;
  onUpdateClick: (report: IReport) => void;
  onCloneClick: (
    report: IReport,
    action: Actions.SEGMENT_COPY | Actions.SEGMENT_CLONE,
  ) => void;
  loading?: boolean;
  noResultsMessage?: string;
  placeholder?: string;
  reportList: IReport[];
  targetList: IReport[];
  type?: ReportType;
  testId?: string;
}

const ReportsList = (props: IProps): ReactElement => {
  const {
    attributionReportList,
    loading,
    onDeleteClick,
    onUpdateClick,
    onCloneClick,
    placeholder = 'Search for items',
    noResultsMessage = 'No results found',
    reportList,
    type = reportType,
    targetList,
    testId,
  } = props;
  const dataMap = {
    [reportType.attributionReport]: attributionReportList,
    [reportType.report]: reportList,
    [reportType.target]: targetList,
  };

  const data = get(`${type}`, dataMap);

  const { checkPermissions } = useUserPermissions();

  const reportsListTestId = getTestId(reportsListComponentName, testId);
  const reportsListClassName = getClass(reportsListComponentName);

  const hiddenColumns: string[] | undefined = [];

  const getColumns = (): Column<IReport>[] => {
    const columns: Column<IReport>[] = [
      {
        Header: 'Name',
        accessor: 'name',
        width: '30%',
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        width: '15%',
      },
      {
        Header: 'Modified By',
        accessor: 'updatedBy',
        width: '15%',
      },
      {
        Header: 'Last Modified',
        width: '15%',
        accessor: 'updatedAt',
        Cell: ({ row }: CellProps<IReport>): JSX.Element => (
          <DateTimeCell dateTime={row.original.updatedAt} />
        ),
      },
      {
        Header: 'Report Type',
        width: '10%',
        Cell: ({ row }: CellProps<IReport>): JSX.Element => (
          <> {getReportSubTypeName(row.original.subType)}</>
        ),
      },
      {
        Header: 'Public',
        width: '5%',
        accessor: (row): string => (row.isPublic ? 'Yes' : 'No'),
      },
      {
        id: 'Actions',
        Header: 'Actions',
        width: '20%',
        Cell: ({ row }: CellProps<IReport>): JSX.Element => {
          const report = row.original;

          const handleDelete = (): void => onDeleteClick(report);
          const handleUpdate = (): void => onUpdateClick(report);
          const handleClone = (): void =>
            onCloneClick(report, Actions.SEGMENT_CLONE);

          return (
            <ListActions<IReport>
              loading={loading}
              item={report}
              id={row.original.id}
              canUpdate={checkPermissions('reports::update')}
              canClone={checkPermissions('reports::duplicate')}
              canDelete={
                checkPermissions('reports::delete::all') ||
                checkPermissions('reports::delete')
              }
              onDeleteClick={handleDelete}
              onUpdateClick={handleUpdate}
              onCloneClick={handleClone}
              testId={reportsListTestId}
            />
          );
        },
      },
    ];

    return columns;
  };

  const getAlignment = (): Alignment[] => [
    alignments.left,
    alignments.left,
    alignments.left,
    alignments.left,
    alignments.left,
    alignments.left,
    alignments.right,
  ];

  const columns = getColumns();
  const alignment = getAlignment();

  return (
    <div data-testid={reportsListTestId} className={reportsListClassName}>
      {loading ? (
        <Loading />
      ) : (
        <Table<IReport>
          initialState={{ sortBy: [{ id: 'updatedAt', desc: true }] }}
          alignment={alignment}
          columns={columns}
          data={data || []}
          editItemSegment={`${Actions.SEGMENT_EDIT}`}
          filter
          filterPlaceholder={placeholder}
          goToEditOnRowClick
          hiddenColumns={hiddenColumns}
          noResultsMessage={noResultsMessage}
          textOverflow={textOverflows.ellipsis}
          pagination
        />
      )}
    </div>
  );
};

const mapStateToProps = (
  state: State,
): {
  attributionReportList: IReport[];
  reportList: IReport[];
  targetList: IReport[];
} => ({
  attributionReportList: rootSelectors.getAttributionReportList(state) || [],
  reportList: rootSelectors.getReportList(state),
  targetList: rootSelectors.getTargetList(state),
});

export default connect(mapStateToProps)(ReportsList);
