import { DragOverlay } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { noop } from 'lodash';
import React, { FC } from 'react';
import { SortableItem } from './SortableItem';

interface OverlayProps {
  chipDisabled: boolean;
  canRemove: boolean;
  widths: { [id: string]: number };
}
export const Overlay: FC<OverlayProps> = ({
  chipDisabled,
  canRemove,
  widths,
}) => {
  const { active, over } = useSortable({ id: 'overlay' });
  const width = over?.id ? widths?.[over.id] : widths?.[active?.id as string];
  return (
    active && (
      <DragOverlay
        style={{ flexBasis: width, flexGrow: 0, flexShrink: 0, width }}
      >
        <SortableItem
          id="overlay"
          item={active.id as string}
          onClick={noop}
          chipDisabled={chipDisabled}
          canRemove={canRemove}
        />
      </DragOverlay>
    )
  );
};
