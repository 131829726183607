import Flex, { Direction, Vertical, Horizontal } from 'components/Flex';
import { getTestId, getId, getClass } from 'helpers/components';
import React, { ReactNode, FunctionComponent } from 'react';

export const wrapperComponentName = 'field-wrapper';

export const sizes = {
  small: 'small',
  auto: 'auto',
} as const;

export type Size = (typeof sizes)[keyof typeof sizes];

export interface IProps {
  children?: ReactNode;
  direction?: Direction;
  id?: string;
  size?: Size;
  testId?: string;
}

const Wrapper: FunctionComponent<IProps> = (props) => {
  const { children, direction = Direction.column, id, size, testId } = props;

  const wrapperClass = getClass(wrapperComponentName, {
    add: [size as string],
  });
  const wrapperId = getId(wrapperComponentName, id);
  const wrapperTestId = getTestId(wrapperComponentName, testId);

  return (
    <div id={wrapperTestId} className={wrapperClass} data-testid={wrapperId}>
      <Flex
        direction={direction || Direction.column}
        vertical={
          direction === Direction.column ? Vertical.top : Vertical.middle
        }
        horizontal={Horizontal.left}
      >
        {children}
      </Flex>
    </div>
  );
};

export default Wrapper;
