export const LOCALE_AUDIT_BUTTON_TOOLTIP_LABEL = 'Audit';
export const LOCALE_COPY_LINK_BUTTON_TOOLTIP_LABEL =
  'Click to copy report link to clipboard';
export const LOCALE_AUDIT_REPORT_MODAL_TITLE = 'Report Audit';
export const LOCALE_AUDIT_TARGET_MODAL_TITLE = 'Audience Audit';
export const LOCALE_CANCEL = 'Cancel';
export const LOCALE_CREATED = 'created';
export const LOCALE_EXPORT_LABEL = 'Export';
export const LOCALE_EXPORT_LABEL_CSV = 'CSV';
export const LOCALE_EXPORT_LABEL_XLXS = 'XLSX';
export const LOCALE_EXPORT_LABEL_ISPOT_SEGMENT = 'iSpot Segment';
export const LOCALE_EXPORT_LABEL_SUMMARY = 'XLSX Summary';
export const LOCALE_EXPORTING_LABEL = 'Exporting';
export const LOCALE_FIELD_RESULT = 'Result';
export const LOCALE_FIELD_SETTINGS = 'Settings';
export const LOCALE_GENERIC_ERROR = 'There has been an error.';
export const LOCALE_INVALID_TARGET =
  'One or more of the attached audiences are invalid and have been removed.';
export const LOCALE_LINK_COPIED = 'Link copied to clipboard';
export const LOCALE_MISSING_REPORT_NAME_MODAL_TEXT = 'Name';
export const LOCALE_MISSING_REPORT_NAME_MODAL_TITLE =
  'Please enter a name for this report';
export const LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_MODAL_TITLE =
  'Please specify a name for your report';
export const LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_NAME_LABEL = 'Name';
export const LOCALE_MISSING_TARGET_NAME_MODAL_TITLE =
  'Please enter a name for this audience';
export const LOCALE_MISSING_TARGET_NAME_SAVE_AS_COPY_MODAL_TITLE =
  'Please specify a name for your audience';
export const LOCALE_NOTIFICATION_HEADER = 'Notification';
export const LOCALE_OK = 'Ok';
export const LOCALE_BACK_TO_REPORT_LIST = 'Back To Report List';
export const LOCALE_REPORT = 'Report';
export const LOCALE_DESKTOP_DOWNLOAD = 'Download to Desktop';
export const LOCALE_REPORT_DOWNLOAD_SUCCEEDED = 'Download completed!';
export const LOCALE_REPORT_DOWNLOAD_FAILED = 'Download failed!';
export const LOCALE_REPORT_UPLOAD_SUCCEEDED = 'S3 Upload completed!';
export const LOCALE_REPORT_UPLOAD_FAILED = 'Upload failed!';
export const LOCALE_REPORT_FIELD_QUERY = 'Report Configuration';
export const LOCALE_S3_UPLOAD = 'Send to S3';
export const LOCALE_REPORT_UPLOADED = 'This has been sent to your S3 bucket.';
export const LOCALE_SAVE_AND_OPEN = 'Save and open';
export const LOCALE_SAVE_AND_RUN = 'Save and Run';
export const LOCALE_TARGET = 'Audience';
export const LOCALE_TARGET_FIELD_QUERY = 'Audience Configuration';
export const LOCALE_UPDATED = 'updated';
export const LOCALE_IS_PUBLIC = 'Report is now public';
export const LOCALE_IS_PRIVATE = 'Report is now private';
export const LOCALE_DATA_READY_TO_EXPORT =
  'Data is ready. Click on Export button.';
export const LOCALE_REPORT_VALIDATION_ERROR =
  'There are errors with your report configuration. See highlighted fields below.';
