import { OnChangeProps, RangeWithKey } from '605-react-date-range';
import {
  endOfDay,
  isAfter,
  isBefore,
  isFuture,
  isSameDay,
  isValid,
  max,
  min,
  parseISO,
  startOfDay,
} from 'date-fns';
import { addDays, parse, subDays, differenceInDays } from 'date-fns/fp';
import {
  dateFormat,
  datesInBounds,
  formatISO,
  rangesAreValid,
} from 'domains/reports/adapters/date';
import { BUTTONS } from 'helpers/datepicker';
import { formatLocale } from 'helpers/date';
import { IButtonMetadata } from 'helpers/datepicker/types';
import { showErrorToast } from 'helpers/general';
import { trackDateRangeChange } from 'helpers/mixpanel';
import {
  useCallback,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
  useMemo,
  Dispatch,
  useReducer,
} from 'react';
import { dateFocusedInputChanged } from './helpers';
import {
  DatePickerState,
  FocusedInput,
  LastUpdatedDate,
  IUseDatePickerProps,
  RangeValues,
} from './interface';

const sub1Day = subDays(1);
const add1Day = addDays(1);
const parseFromLocale = parse(new Date(), dateFormat(navigator.language));

const getStartAndEndDate = (
  date: Date,
  selectedFocusedInput?: FocusedInput,
  storedStartDateUTC?: string,
  storedEndDateUTC?: string,
): { startDate: string; endDate: string } => {
  switch (selectedFocusedInput) {
    case FocusedInput.startDate:
      return {
        startDate: formatISO(date),
        endDate: storedEndDateUTC ?? formatISO(date),
      };
    case FocusedInput.endDate:
      return {
        startDate: storedStartDateUTC ?? formatISO(date),
        endDate: formatISO(date),
      };
    default:
      return {
        startDate: formatISO(date),
        endDate: formatISO(date),
      };
  }
};

interface IDatePickerData {
  proposedStartDate: string;
  proposedEndDate: string;
  focusedInput?: FocusedInput;
  lastUpdatedDate?: LastUpdatedDate;
  datePickerState: RangeValues[];
}

interface IUseDatePickerResult extends IDatePickerData {
  datePickerMinDate?: string | Date;
  isActiveSmartSelection: boolean;
  handleRangeChange: (date1: Date, date2: Date) => void;
  handleSmartSelection: (
    startDate: string,
    endDate: string,
    relativeDateOffset?: string,
  ) => void;
  handleChangeDateRangePicker: (item: OnChangeProps) => void;
  changeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  focusOutInput: (
    e: ChangeEvent<HTMLInputElement>,
    input: FocusedInput,
  ) => void;
  handleKeyEnterInput: (e: KeyboardEvent<HTMLInputElement>) => void;
  dispatch: Dispatch<Partial<IDatePickerData>>;
}

const datePickerReducer = (
  state: IDatePickerData,
  newState: Partial<IDatePickerData>,
): IDatePickerData => ({
  ...state,
  ...newState,
});

type InitializerArgs = Record<
  | 'startDateString'
  | 'endDateString'
  | 'storedStartDateUTC'
  | 'storedEndDateUTC',
  string | undefined
>;

const datePickerInitializer = ({
  startDateString,
  endDateString,
  storedStartDateUTC,
  storedEndDateUTC,
}: InitializerArgs): IDatePickerData => ({
  focusedInput: undefined,
  lastUpdatedDate: 2,
  proposedStartDate: formatLocale(
    startDateString ? parseISO(startDateString) : new Date(),
  ),
  proposedEndDate: formatLocale(
    endDateString ? parseISO(endDateString) : new Date(),
  ),
  datePickerState: [
    {
      startDate: storedStartDateUTC ? parseISO(storedStartDateUTC) : new Date(),
      endDate: storedEndDateUTC ? parseISO(storedEndDateUTC) : new Date(),
      key: 'selection',
    },
  ],
});

export const useDatePicker = ({
  startDate: startDateString,
  endDate: endDateString,
  disabled,
  handleChange,
  minDate: minDateString,
  maxDate: maxDateString,
  limitToRange,
  relativeDateOffset,
  trackingId,
}: IUseDatePickerProps): IUseDatePickerResult => {
  const storedStartDateUTC = startDateString;
  const storedEndDateUTC = endDateString;
  const isActiveSmartSelection = relativeDateOffset !== undefined;

  const [
    {
      proposedStartDate,
      proposedEndDate,
      focusedInput,
      lastUpdatedDate,
      datePickerState,
    },
    dispatch,
  ] = useReducer(
    datePickerReducer,
    { startDateString, endDateString, storedStartDateUTC, storedEndDateUTC },
    datePickerInitializer,
  );

  useEffect(() => {
    if (storedStartDateUTC && storedEndDateUTC) {
      const startDate = startOfDay(parseISO(storedStartDateUTC));
      const endDate = endOfDay(parseISO(storedEndDateUTC));
      dispatch({
        datePickerState: [
          {
            startDate,
            endDate,
            key: 'selection',
          },
        ],
        proposedStartDate: formatLocale(startDate),
        proposedEndDate: formatLocale(endDate),
      });
    }
  }, [storedStartDateUTC, storedEndDateUTC]);

  useEffect(() => {
    if (
      storedEndDateUTC &&
      maxDateString &&
      storedStartDateUTC &&
      isAfter(parseISO(storedEndDateUTC), parseISO(maxDateString))
    ) {
      handleChange?.(storedStartDateUTC, maxDateString, relativeDateOffset);
    }
  }, [
    storedStartDateUTC,
    storedEndDateUTC,
    maxDateString,
    relativeDateOffset,
    handleChange,
  ]);

  useEffect(() => {
    if (relativeDateOffset && maxDateString) {
      const { start, end } = (
        BUTTONS[relativeDateOffset] as IButtonMetadata
      )?.getRange(parseISO(maxDateString));

      handleChange?.(formatISO(start), formatISO(end), relativeDateOffset);
    }
  }, [relativeDateOffset, handleChange, maxDateString]);

  const handleRangeChange = useCallback(
    (date1: Date, date2: Date): void => {
      if (disabled) return;

      let startDate = min([date1, date2]);
      let endDate = max([date1, date2]);

      const maxDatePlus1Day = maxDateString && add1Day(parseISO(maxDateString));

      if (maxDatePlus1Day && isAfter(endDate, maxDatePlus1Day)) {
        endDate = maxDatePlus1Day;
      }

      const minDate = minDateString && parseISO(minDateString);

      if (minDate && isBefore(startDate, minDate)) {
        startDate = minDate;
      }

      if (date1) {
        dispatch({ datePickerState: [{ startDate, endDate }] });
      }

      dispatch({
        proposedStartDate: formatLocale(startDate),
        proposedEndDate: formatLocale(endDate),
      });

      handleChange?.(formatISO(startDate), formatISO(endDate));
    },
    [disabled, maxDateString, minDateString, handleChange],
  );

  const handleSmartSelection = useCallback(
    (date1: string, date2: string, relativeDateOffset?: string): void =>
      handleChange?.(
        date1,
        date2,
        isActiveSmartSelection ? relativeDateOffset : undefined,
      ),
    [handleChange, isActiveSmartSelection],
  );

  const setDate = useCallback(
    (date: Date, selectedFocusedInput?: FocusedInput): void => {
      const { startDate, endDate } = getStartAndEndDate(
        date,
        selectedFocusedInput,
        storedStartDateUTC,
        storedEndDateUTC,
      );

      const focusedInput =
        selectedFocusedInput === 0 ? ' Start Date' : ' End Date';
      trackDateRangeChange(trackingId + focusedInput, startDate, endDate);
      if (minDateString && maxDateString) {
        if (
          storedStartDateUTC &&
          storedEndDateUTC &&
          minDateString &&
          maxDateString
        ) {
          if (
            rangesAreValid(startDate, endDate, minDateString, maxDateString)
          ) {
            return handleRangeChange(parseISO(startDate), parseISO(endDate));
          }

          return handleRangeChange(
            parseISO(storedStartDateUTC),
            parseISO(storedEndDateUTC),
          );
        }

        return;
      }
      return handleRangeChange(parseISO(startDate), parseISO(endDate));
    },
    [
      handleRangeChange,
      maxDateString,
      minDateString,
      storedEndDateUTC,
      storedStartDateUTC,
      trackingId,
    ],
  );

  const handleChangeDateRangePicker = useCallback(
    (item: OnChangeProps, toggleFocus: boolean = true): void => {
      if (isActiveSmartSelection) return;

      const storedStartDate = storedStartDateUTC
        ? parseISO(storedStartDateUTC)
        : new Date();
      const storedEndDate = storedEndDateUTC
        ? parseISO(storedEndDateUTC)
        : new Date();
      const { selection, range1 } = item as DatePickerState;
      let { startDate, endDate } = selection ?? range1;
      if (focusedInput === FocusedInput.startDate) {
        startDate = isSameDay(startDate, storedStartDate) ? endDate : startDate;
        endDate = storedEndDate;
      }

      if (focusedInput === FocusedInput.endDate) {
        if (
          isBefore(startDate, storedStartDate) &&
          isSameDay(endDate, storedStartDate)
        ) {
          endDate = startDate;
        } else {
          endDate = isSameDay(endDate, storedEndDate) ? startDate : endDate;
          startDate = storedStartDate;
        }
      }
      let targetDate =
        focusedInput === FocusedInput.startDate ? startDate : endDate;

      const isChangingStartDate = focusedInput === FocusedInput.startDate;
      const endDateLess1Day = sub1Day(storedEndDate);

      if (
        isChangingStartDate &&
        differenceInDays(startDate, storedEndDate) <= 0
      ) {
        // changing start date to new value after stored end date
        const newDate = isBefore(startDate, endDate) ? endDate : startDate;
        if (toggleFocus) {
          dispatch({
            focusedInput: FocusedInput.endDate,
            lastUpdatedDate: LastUpdatedDate.startDate,
          });
        }

        return setDate(newDate);
      }

      if (
        isChangingStartDate &&
        differenceInDays(startDate, storedStartDate) === 0
      ) {
        if (!toggleFocus) return;

        dispatch({
          focusedInput: FocusedInput.endDate,
          lastUpdatedDate: LastUpdatedDate.startDate,
        });
        return;
      }

      if (
        !isChangingStartDate &&
        differenceInDays(endDate, storedEndDate) === 0
      ) {
        if (!toggleFocus) return;
        dispatch({
          focusedInput: FocusedInput.startDate,
          lastUpdatedDate: LastUpdatedDate.endDate,
        });
        return;
      }

      if (!isChangingStartDate && isBefore(endDate, storedStartDate)) {
        const newDate = isBefore(startDate, endDate) ? startDate : endDate;
        if (toggleFocus) {
          dispatch({
            focusedInput: FocusedInput.startDate,
            lastUpdatedDate: LastUpdatedDate.endDate,
          });
        }
        return setDate(newDate);
      }

      if (
        focusedInput === FocusedInput.startDate &&
        isAfter(startDate, endDateLess1Day)
      ) {
        targetDate = endDateLess1Day;
      }

      setDate(targetDate, focusedInput);
      if (toggleFocus) {
        dispatch({
          focusedInput: isChangingStartDate
            ? FocusedInput.endDate
            : FocusedInput.startDate,
          lastUpdatedDate: isChangingStartDate
            ? LastUpdatedDate.startDate
            : LastUpdatedDate.endDate,
        });
      }

      return;
    },
    [
      focusedInput,
      setDate,
      storedEndDateUTC,
      storedStartDateUTC,
      isActiveSmartSelection,
    ],
  );

  const changeInput = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;

    switch (focusedInput) {
      case FocusedInput.startDate:
        return dispatch({ proposedStartDate: value });
      case FocusedInput.endDate:
        return dispatch({ proposedEndDate: value });
    }
  };

  const dateValidation = (
    value: string,
    startDate: Date,
    endDate: Date,
  ): Boolean => {
    const valueDate = parseFromLocale(value);
    const dateIsValid =
      minDateString && maxDateString
        ? isValid(valueDate) &&
          datesInBounds(value, minDateString, maxDateString)
        : isValid(valueDate);

    if (dateIsValid) {
      return true;
    }

    const rangeWord = focusedInput === FocusedInput.endDate ? 'End' : 'Start';
    showErrorToast(`${rangeWord} date is invalid`);
    dispatch({
      proposedStartDate: formatLocale(startDate),
      proposedEndDate: formatLocale(endDate),
    });

    return false;
  };

  const focusOutInput = (
    e: ChangeEvent<HTMLInputElement>,
    input: FocusedInput,
  ): void => {
    const { value } = e.currentTarget;

    const startDate = storedStartDateUTC
      ? parseISO(storedStartDateUTC)
      : new Date();
    const endDate = storedEndDateUTC ? parseISO(storedEndDateUTC) : new Date();

    if (
      !dateFocusedInputChanged(
        value,
        formatLocale(startDate) ?? value,
        formatLocale(endDate) ?? value,
        input,
      )
    ) {
      return;
    }

    const dateIsValid = dateValidation(value, startDate, endDate);
    if (dateIsValid) {
      const selection = {
        startDate:
          input === FocusedInput.startDate
            ? parseFromLocale(proposedStartDate)
            : startDate,
        endDate:
          input === FocusedInput.endDate
            ? parseFromLocale(proposedEndDate)
            : endDate,
      } as RangeWithKey;
      handleChangeDateRangePicker({ selection }, false);
    }
  };

  const handleKeyEnterInput = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === 13) {
      const startDate = new Date(storedStartDateUTC ?? Date.now());
      const endDate = new Date(storedEndDateUTC ?? Date.now());
      const dateIsValid =
        focusedInput === FocusedInput.startDate
          ? dateValidation(proposedStartDate, startDate, endDate)
          : dateValidation(proposedEndDate, startDate, endDate);
      e.preventDefault();
      if (!dateIsValid) return;
      const selection = {
        startDate:
          focusedInput === FocusedInput.startDate
            ? parseFromLocale(proposedStartDate)
            : startDate,
        endDate:
          focusedInput === FocusedInput.endDate
            ? parseFromLocale(proposedEndDate)
            : endDate,
      } as RangeWithKey;
      e.currentTarget.blur();

      handleChangeDateRangePicker({ selection });
    }
  };

  const datePickerMinDate = useMemo(() => {
    const startDate = storedStartDateUTC
      ? parseISO(storedStartDateUTC)
      : new Date();
    const endDate = storedEndDateUTC ? parseISO(storedEndDateUTC) : new Date();
    const minDate = minDateString && parseISO(minDateString);

    const isOutOfBounds = limitToRange
      ? isBefore(startDate, startDate) || isAfter(startDate, endDate)
      : isFuture(startDate);

    return isOutOfBounds ? startDate : minDate;
  }, [limitToRange, minDateString, endDateString, startDateString]);

  return {
    proposedStartDate,
    proposedEndDate,
    focusedInput,
    lastUpdatedDate,
    datePickerState,
    datePickerMinDate,
    isActiveSmartSelection,
    handleRangeChange,
    handleChangeDateRangePicker,
    changeInput,
    focusOutInput,
    handleKeyEnterInput,
    handleSmartSelection,
    dispatch,
  };
};
