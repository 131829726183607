import { useRef } from 'react';

export type hookReturn = {
  goTo: (anchor: string | HTMLElement) => void;
};

const scrollTop = (
  perfectScrollbar: HTMLElement,
  positionToScroll: number,
  attempts: number = 0,
): void => {
  const scrollTopBefore = perfectScrollbar.scrollTop;
  perfectScrollbar.scrollTop -= 100;
  if (perfectScrollbar.scrollTop > positionToScroll && attempts < 5) {
    setTimeout(
      () =>
        scrollTop(
          perfectScrollbar,
          positionToScroll,
          scrollTopBefore === perfectScrollbar.scrollTop ? attempts + 1 : 0,
        ),
      15,
    );
  }
};

const scrollBottom = (
  perfectScrollbar: HTMLElement,
  positionToScroll: number,
  attempts: number = 0,
): void => {
  const scrollTopBefore = perfectScrollbar.scrollTop;
  perfectScrollbar.scrollTop += 100;
  if (perfectScrollbar.scrollTop < positionToScroll && attempts < 5) {
    setTimeout(
      () =>
        scrollBottom(
          perfectScrollbar,
          positionToScroll,
          scrollTopBefore === perfectScrollbar.scrollTop ? attempts + 1 : 0,
        ),
      15,
    );
  }
};

const scrollToPosition = (
  perfectScrollbar: HTMLElement,
  positionToScroll: number,
): void => {
  if (perfectScrollbar.scrollTop > positionToScroll) {
    scrollTop(perfectScrollbar, positionToScroll);
  } else {
    scrollBottom(perfectScrollbar, positionToScroll);
  }
};

const scrollPerfectScrollbarSmoothly = (
  perfectScrollbarElement: HTMLElement,
  anchor: HTMLElement,
): void => {
  scrollToPosition(perfectScrollbarElement, anchor.offsetTop - 150);
};

const useScroll = (perfectScrollbarId?: string): hookReturn => {
  const perfectScrollbarElement = useRef<HTMLElement | null>();
  if (perfectScrollbarId) {
    perfectScrollbarElement.current =
      document.getElementById(perfectScrollbarId);
  }

  const scrollUntil = (anchor: HTMLElement): void => {
    if (perfectScrollbarElement.current) {
      scrollPerfectScrollbarSmoothly(perfectScrollbarElement.current, anchor);
    } else {
      anchor.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const goTo = (anchor: string | HTMLElement): void => {
    if (typeof anchor === 'string') {
      const element = document.querySelector(anchor) as HTMLElement;
      if (element) {
        scrollUntil(element);
      }
    } else {
      scrollUntil(anchor);
    }
  };

  return {
    goTo,
  };
};

export default useScroll;
