import { buildYup } from 'schema-to-yup';
import { validationSchemaType } from '../../../../helpers/forms/formValidations';

const s3PathValidation = {
  type: 'string',
  pattern: new RegExp(/^s3:\/\/((?!impact_exports|target_exports)\S)+\/$/, 'i'),
};

const s3ValidationErrorMessage =
  'Invalid Upload Path. Path must start with "s3://", not include "impact_exports" or "target_exports" and end with a trailing slash "/"';

const validationSchemaData = {
  ...validationSchemaType,
  properties: {
    uploadPath: {
      ...s3PathValidation,
    },
    audienceUploadPath: {
      ...s3PathValidation,
    },
  },
};

const validationConfig = {
  errMessages: {
    uploadPath: {
      pattern: s3ValidationErrorMessage,
    },
    audienceUploadPath: {
      pattern: s3ValidationErrorMessage,
    },
  },
};

export const validationSchema = buildYup(
  validationSchemaData,
  validationConfig,
);
