interface IUser {
  authId?: string;
  clients?: string[];
  email: string;
  id?: string;
  isNew?: boolean;
  name: string;
  roles?: string[];
  createdBy?: string;
  createdAt?: string;
  lastModifiedBy?: string;
  updatedAt?: string;
}

export interface IUserReportData extends IUser {
  last_login?: string;
  blocked?: boolean;
  modules?: string[];
}

export default IUser;

export class User implements IUser {
  name: string;

  id: string;

  roles: string[];

  email: string;

  isNew: boolean;

  authId: string;

  clients: string[];

  constructor(user?: IUser) {
    this.name = user?.name ?? '';
    this.id = user?.id ?? '';
    this.roles = user?.roles ?? [];
    this.email = user?.email ?? '';
    this.isNew = user?.isNew ?? true;
    this.authId = user?.authId ?? '';
    this.clients = user?.clients ?? [];
  }
}
