import Button, {
  Type as buttonType,
  Kind as buttonKind,
} from 'components/Button';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { Type as iconType } from 'components/Icon';
import Table, { textOverflows, alignments } from 'components/Table';
import { getTestId, getClass } from 'helpers/components';
import useFetch from 'hooks/useFetch';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import { partial } from 'lodash';
import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { CellProps } from 'react-table';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import IRunningQuery from 'types/runningQuery';
import { Index as DOMAINS } from '../../../routes';

const QUERY_LIST_PAGE_SIZE = 10;
const FETCH_INTERVAL = 1000;
const LOCALE_KILL_QUERY_CONFIRMATION_BODY = 'This cannot be undone.';
const LOCALE_KILL_QUERY_CONFIRMATION_HEADER = 'Stop Query?';
const LOCALE_KILL_QUERY_CONFIRMATION_BUTTON = 'Stop';

export const listComponentName = 'running-queries-list-table';

interface IProps {
  testId?: string;
}

const RunningQueriesListTable = (props: IProps): ReactElement => {
  const { testId } = props;
  const [queries, setQueries] = useState([] as IRunningQuery[]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [stopQueryId, setStopQueryId] = useState('');
  const [errorCount, setErrorCount] = useState(0);
  const { checkPermissions } = useUserPermissions();

  const fetchInterval = useRef(0);
  const { doErrorToast } = useToast();
  const { doFetch } = useFetch<IRunningQuery>();

  const stopQueryDialog = (query: IRunningQuery): void => {
    setDialogOpen(true);
    setStopQueryId(query.query_id);
  };

  const closeDialog = (): void => {
    setStopQueryId('');
    setDialogOpen(false);
  };

  const handleStop = (): void => {
    doFetch({
      endpoint: `/${DOMAINS.SEGMENT_RUNNING_QUERIES}/`,
      payload: { id: stopQueryId },
      method: FetchMethod.DELETE,
    });
    closeDialog();
  };

  const handleError = useCallback(
    (err: IError): void => {
      setErrorCount((errors) => errors + 1);
      doErrorToast(err?.message ?? 'There has been an error.');
    },
    [doErrorToast],
  );

  useEffect(() => {
    if (errorCount > 0) window.clearInterval(fetchInterval.current);
  }, [errorCount]);

  useEffect(() => {
    const getQueries = (): void => {
      doFetch({
        endpoint: `/${DOMAINS.SEGMENT_RUNNING_QUERIES}/`,
        method: FetchMethod.GET,
        onSuccess: (d) => setQueries(d),
        onError: handleError,
      });
    };

    getQueries();
    fetchInterval.current = window.setInterval(getQueries, FETCH_INTERVAL);

    const cleanup = (): void => {
      window.clearInterval(fetchInterval.current);
    };

    return cleanup;
  }, [doFetch, handleError]);

  const tableTestId = getTestId(listComponentName, testId);
  const className = getClass(listComponentName);

  return (
    <div data-testid={tableTestId} className={className}>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onConfirm={handleStop}
        onCancel={closeDialog}
        header={LOCALE_KILL_QUERY_CONFIRMATION_HEADER}
        confirmButtonText={LOCALE_KILL_QUERY_CONFIRMATION_BUTTON}
      >
        <p>{LOCALE_KILL_QUERY_CONFIRMATION_BODY}</p>
      </ConfirmationDialog>
      <Table<IRunningQuery>
        filter
        filterPlaceholder="Search for queries"
        goToEditOnRowClick
        noResultsMessage="No results found"
        textOverflow={textOverflows.ellipsis}
        alignment={[
          alignments.left,
          alignments.right,
          alignments.right,
          alignments.right,
          alignments.left,
          alignments.right,
        ]}
        columns={[
          {
            Header: 'Query ID',
            accessor: 'query_id',
            width: '20%',
          },
          {
            Header: 'Rows Read',
            accessor: 'read_rows',
            width: '10%',
          },
          {
            Header: 'Total Rows',
            accessor: 'total_rows_approx',
            width: '10%',
          },
          {
            Header: '% Complete',
            accessor: 'pc_complete',
            width: '10%',
          },
          {
            Header: 'Query',
            accessor: 'query',
            width: '30%',
          },
          {
            Header: 'Actions',
            id: 'Actions',
            width: '20%',
            Cell: function ListTableActionsCell({
              row: { original: query },
            }: CellProps<IRunningQuery>): JSX.Element {
              return (
                <>
                  {checkPermissions('queries::stop') && (
                    <Button
                      type={buttonType.button}
                      kind={buttonKind.icon}
                      iconType={iconType.trash}
                      onClick={partial(stopQueryDialog, query)}
                      testId={`${testId}-stop`}
                    />
                  )}
                </>
              );
            },
          },
        ]}
        data={queries}
        pageSize={QUERY_LIST_PAGE_SIZE}
      />
    </div>
  );
};

export default RunningQueriesListTable;
