import IMessage from 'domains/messageList/types';
import { Dispatch } from 'redux';
import ActionType from 'store/actions/types';
import * as messageListSelectors from 'store/selectors/messageList';
import { Action } from 'types/action';
import State from 'types/state';

export const setMessageList = (payload: IMessage[]): Action<IMessage[]> => ({
  type: ActionType.MESSAGE_LIST_SET_MESSAGES,
  payload,
});

export const addMessage =
  (message: IMessage) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const messageList: IMessage[] =
      messageListSelectors.getMessageList(getState());
    const messageAlreadyInList = messageList.some(
      (m) => m.id === message.id && m.field === message.field,
    );
    if (messageAlreadyInList) return;
    messageList.push(message);
    dispatch(setMessageList(messageList));
  };

export const deleteMessage =
  (message: IMessage) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const messageList: IMessage[] =
      messageListSelectors.getMessageList(getState());
    const newMessageList = messageList.filter((m) => {
      m.id !== message.id && m.field !== message.field;
    });
    dispatch(setMessageList(newMessageList));
  };

export const deleteMessagesForID =
  (id: string) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const messageList: IMessage[] =
      messageListSelectors.getMessageList(getState());
    dispatch(
      setMessageList(
        messageList.filter((m) => m.id !== id && m.parentId !== id),
      ),
    );
  };

export const initializeMessageList =
  () =>
  (dispatch: Dispatch, getState: () => State): void => {
    const messageList: IMessage[] =
      messageListSelectors.getMessageList(getState());
    if (messageList.length) dispatch(setMessageList([]));
  };
