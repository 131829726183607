import {
  getDateRangeFromQueryFilters,
  getDefaultSelectedConfig,
} from 'components/QueryBuilder/adapters';
import {
  getCols,
  getRows,
  getValues,
} from 'components/ReportResultsForm/utils';
import {
  generatePivotTable,
  TableRowConfig,
} from 'components/Table/components/PivotTable/adapters/rawPivot';
import IDimension from 'domains/dimensions/types';
import {
  getDatasetSelection,
  isReport,
  isTarget,
} from 'domains/reports/adapters/general';
import { isBaseReportValid } from 'domains/reports/adapters/rulesets';
import {
  CROSSWALKED_FORMAT,
  DEFAULT_UNIVERSE_NAME,
  STATUS_CHECK_INTERVAL_SECONDS,
} from 'domains/reports/constants';
import {
  CONFIRM_QUERY_MESSAGES,
  confirmDialogType,
  LOCALE_REPORT_DOWNLOADED,
  LOCALE_REPORT_UPLOADED,
  NOT_FOUND_CACHE_RESULT,
  LOCALE_EMPTY_SEGMENT_FILE,
} from 'domains/reports/locales/general';
import IReport, {
  AggregationMethodOptions,
  cacheMode,
  ChartTypes,
  DataRowGraph,
  ExecuteErrorResponse,
  ExposureConversionSetNames,
  IChartData,
  IColumn,
  IConfirmDialog,
  IHighchartsSeriesData,
  IQueryResult,
  IReportJobCompleteResponse,
  ISaveSegmentsResponse,
  ISGMConfig,
  isIAttributionReport,
  ITableConfig,
  PerformanceGroupNames,
  ReportJobExecutionStatuses,
  ReportType,
  reportType,
  ResultBase,
  RowsDropped,
  SampleGroupNames,
  IDateRangeQueryFilter,
} from 'domains/reports/types';
import { showErrorToast, showSuccessToast, waitSeconds } from 'helpers/general';
import {
  trackInlineValidationShown,
  trackResultRetrieved,
} from 'helpers/mixpanel';
import {
  get,
  isEmpty,
  isEqual,
  isNumber,
  isUndefined,
  memoize,
  uniq,
} from 'lodash/fp';
import { batch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Actions, Index as DOMAINS, Index } from 'routes';
import ActionTypes from 'store/actions/types';
import store from 'store/index';
import { initialState, IReportResult } from 'store/reducers/reportResult';
import { IExecuteTargetResult } from 'store/reducers/targetResult';
import * as messageListSelectors from 'store/selectors/messageList';
import * as reportSelectors from 'store/selectors/report';
import * as resultSelectors from 'store/selectors/reportResult';
import * as businessDataSelectors from 'store/selectors/businessData';
import * as domainsSelectors from 'store/selectors/domains';
import {
  getCacheQueryId,
  getDownloadQuery,
  getExecutedReport,
  getFileUpload,
} from 'store/selectors/reportResult';
import { getPermissions, getSelectedClient } from 'store/selectors/user';
import { Action, PayloadLessAction } from 'types/action';
import {
  savingStatus,
  SegmentsSavingStatus,
} from 'types/attributionReportsResult';
import FetchMethod from 'types/fetchMethod';
import State from 'types/state';
import {
  getBusinessRules,
  getIntervalDimensions,
  getSGMConfig,
} from 'domains/reports/adapters/results';
import { fetchApi } from 'helpers/fetching';
import {
  processRules,
  setIsReportInvalid,
  setLastValidReport,
  setSelectedCharts,
  setSelectedMetrics,
  setShowConfirmationDialog,
  setShowDialogModal,
} from '../report';
import { loadResultSelection } from '../resultSelections';
import {
  disableCancel,
  disableRefresh,
  disableRun,
  disableSave,
  disableSaveAs,
  enableCancel,
  enableRefresh,
  enableRun,
  enableSave,
  enableSaveAs,
  setLastCachedDate,
  setProgress,
  setQueryProgress,
} from '../toolbar';
import * as downloadActions from '../downloads';

import { convertStringToArray } from 'helpers/string';
import ITextValue, { IOption } from 'types/textValue';
import { getWaterfallChartData } from 'helpers/reports';
import { checkPermissionTree } from 'domains/permissions/helpers/checkPermissionTree';
import { ISPOT_SEGMENT_CONFIG_OPTION_ID } from 'features/targets/constants';
import { IConfigOption } from 'domains/configOptions/types';
import { IDataset } from 'domains/datasets/types';

export const setDisplayChartData = (
  payload: IChartData,
): Action<IChartData> => ({
  type: ActionTypes.RESULT_SET_DISPLAY_CHART_DATA,
  payload,
});

export const setDateRange = (
  payload: IReport,
): Action<IDateRangeQueryFilter> => {
  const dateRange = getDateRangeFromQueryFilters(
    get('query.filters.children', payload),
  );
  return {
    type: ActionTypes.RESULT_SET_DATE_RANGE,
    payload: dateRange,
  };
};

export const setDownloadQuery = (payload: boolean): Action<boolean> => ({
  type: ActionTypes.RESULT_SET_DOWNLOAD_QUERY,
  payload,
});

export const setFileUpload = (payload: boolean): Action<boolean> => ({
  type: ActionTypes.RESULT_SET_FILE_UPLOAD,
  payload,
});

export const setExportConfigOptions = (
  payload: ITextValue[],
): Action<ITextValue[]> => ({
  type: ActionTypes.RESULT_SET_TARGET_RESULT_EXPORT_CONFIG_OPTIONS,
  payload,
});

export const setErrorMessage = (payload: string): Action<string> => ({
  type: ActionTypes.RESULT_SET_ATTRIBUTION_REPORT_ERROR_MESSAGE,
  payload,
});

export const setSGMConfig = (payload: ISGMConfig): Action<ISGMConfig> => ({
  type: ActionTypes.RESULT_SET_SGM_CONFIG,
  payload,
});

export const resetReportResult = (): PayloadLessAction => ({
  type: ActionTypes.RESULT_RESET_RESULT,
});

export const resetTargetResult = (): PayloadLessAction => ({
  type: ActionTypes.RESULT_RESET_TARGET_RESULT,
});

export const setShowLROI = (payload: boolean): Action<boolean> => ({
  type: ActionTypes.RESULT_SET_SHOW_LROI,
  payload,
});

export const setBreakoutList = (
  payload: { id: string; name: string }[],
): Action<{ id: string; name: string }[]> => ({
  type: ActionTypes.RESULT_SET_BREAKOUT_LIST,
  payload,
});

export const setDemographicBreakoutList = (
  payload: { id: string; name: string }[],
): Action<{ id: string; name: string }[]> => ({
  type: ActionTypes.RESULT_SET_DEMOGRAPHIC_BREAKOUT_LIST,
  payload,
});

export const resetAttributionReportResult = (): PayloadLessAction => ({
  type: ActionTypes.RESULT_RESET_ATTRIBUTION_REPORT_RESULT,
});

export const setAttributionReportResultBreakouts = (
  payload: ResultBase,
): Action<ResultBase> => ({
  type: ActionTypes.RESULT_ATTRIBUTION_REPORT_RETRIEVED,
  payload,
});

export const setAttributionReportResultDemographicStats = (
  payload: ResultBase,
): Action<ResultBase> => ({
  type: ActionTypes.RESULT_ATTRIBUTION_REPORT_DEMOGRAPHICS_RETRIEVED,
  payload,
});

export const setAttributionReportResultMiscStats = (
  payload: ResultBase,
): Action<ResultBase> => ({
  type: ActionTypes.RESULT_ATTRIBUTION_REPORT_MISC_RETRIEVED,
  payload,
});

export const setAttributionReportResultConversionType = (
  payload: string,
): Action<string> => ({
  type: ActionTypes.RESULT_ATTRIBUTION_REPORT_SET_CONVERSION_TYPE,
  payload,
});

export const setSegmentsSavingStatus = (
  payload: SegmentsSavingStatus,
): Action<SegmentsSavingStatus> => ({
  type: ActionTypes.RESULT_ATTRIBUTION_SEGMENTS_SAVE_STATUS_UPDATED,
  payload,
});

export const setSaveSegmentsFilenames = (
  payload: Array<string>,
): Action<Array<string>> => ({
  type: ActionTypes.RESULT_ATTRIBUTION_SEGMENTS_SAVE_SET_FILENAMES,
  payload,
});

export const resetSaveSegmentsFilenames = (): PayloadLessAction => ({
  type: ActionTypes.RESULT_ATTRIBUTION_SEGMENTS_SAVE_RESET_FILENAMES,
});

export const setVendorSourceName = (
  payload?: string,
): Action<string | undefined> => ({
  type: ActionTypes.RESULT_ATTRIBUTION_SET_VENDOR_SOURCE_NAME,
  payload,
});

export const setReportResult = (
  payload: IReportResult,
): Action<IReportResult> => ({
  type: ActionTypes.RESULT_RETRIEVED,
  payload,
});

export const setTargetResult = (
  payload: IExecuteTargetResult,
): Action<IExecuteTargetResult> => ({
  type: ActionTypes.RESULT_TARGET_RETRIEVED,
  payload,
});

export const setExecutedReport = (payload: IReport): Action<IReport> => ({
  type: ActionTypes.RESULT_SET_EXECUTED_REPORT,
  payload,
});

export const cancelExecutionJob = (payload: boolean): Action<boolean> => ({
  type: ActionTypes.RESULT_SET_EXECUTED_REPORT_CANCELLING,
  payload,
});

export const setAttributionReportOmissionMessages = (
  payload: ExposureConversionSetNames[],
): Action<ExposureConversionSetNames[]> => ({
  type: ActionTypes.RESULT_SET_ATTRIBUTION_OMISSION_MESSAGES,
  payload,
});

export const setAttributionReportMetrics = (
  payload: string[],
): Action<string[]> => ({
  type: ActionTypes.RESULT_SET_ATTRIBUTION_REPORT_METRICS,
  payload,
});

export const closeSaveSegmentsSuccessModal =
  () =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>): void => {
    dispatch(setSegmentsSavingStatus(savingStatus.none));
    dispatch(resetSaveSegmentsFilenames());
  };

export const setAttributionReportResultSpecializedInsightsChart = (
  payload: IHighchartsSeriesData[],
): Action<IHighchartsSeriesData[]> => ({
  type: ActionTypes.RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_CHART,
  payload,
});

export const setAttributionReportResultSpecializedInsightsTargetOptions = (
  payload: IOption[],
): Action<IOption[]> => ({
  type: ActionTypes.RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_TARGET_OPTIONS,
  payload,
});

export const setAttributionReportResultSpecializedInsightsTarget = (
  payload: IOption[],
): Action<IOption[]> => ({
  type: ActionTypes.RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_TARGET,
  payload,
});

export const handleSpecializedInsightsTargetChange =
  (selectedTarget: IOption[]) =>
  (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): void => {
    if (!selectedTarget[0]?.value) return;
    const state = getState();
    const miscResult = resultSelectors.getAttributionReportMiscResult(state);
    const target =
      selectedTarget[0]?.value === 'genpop'
        ? SampleGroupNames.genpop
        : SampleGroupNames.target;
    const waterfallChartData = getWaterfallChartData(miscResult, target);
    dispatch(
      setAttributionReportResultSpecializedInsightsTarget(selectedTarget),
    );
    dispatch(
      setAttributionReportResultSpecializedInsightsChart(waterfallChartData),
    );
  };

export const processSpecializedInsightsChart =
  (payload: ResultBase) =>
  (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): void => {
    const state = getState();
    const targetObjects =
      reportSelectors.getAttributionReportTargetObjects(state) ?? [];
    const resultTarget =
      targetObjects.length > 1
        ? SampleGroupNames.target
        : SampleGroupNames.genpop;
    const selectedTarget = targetObjects.find(
      (target: IOption) => target.value === resultTarget,
    ) as IOption;
    const waterfallChartData = getWaterfallChartData(
      payload,
      selectedTarget?.value === 'genpop'
        ? SampleGroupNames.genpop
        : SampleGroupNames.target,
    );
    dispatch(
      setAttributionReportResultSpecializedInsightsTargetOptions(targetObjects),
    );
    dispatch(
      setAttributionReportResultSpecializedInsightsTarget([selectedTarget]),
    );
    dispatch(
      setAttributionReportResultSpecializedInsightsChart(waterfallChartData),
    );
  };

export const processAttributionReportOmissionMessages =
  (payload: {
    rows: RowsDropped;
    breakoutList: { id: string; name: string }[];
    demographicBreakoutList: { id: string; name: string }[];
  }) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>): void => {
    if (isEmpty(payload.rows)) return;
    const { rows } = payload;
    const omissionMessageArray: ExposureConversionSetNames[] = [];
    Object.keys(rows).map((segment) => {
      if (typeof rows[segment] === 'number') {
        const segmentValueAsaNumber = rows[segment] as unknown as number;
        const isInvalid =
          (segmentValueAsaNumber ?? 0) > 0 ? `${segment}` : null;
        if (isInvalid) {
          omissionMessageArray.push(isInvalid as ExposureConversionSetNames);
        }
      } else {
        Object.keys(rows[segment]).forEach((breakout) => {
          const isInvalid =
            (rows[segment][breakout]! ?? 0) > 0
              ? `${segment}.${breakout}`
              : null;
          if (isInvalid) {
            omissionMessageArray.push(isInvalid as ExposureConversionSetNames);
          }
        });
      }
    });
    dispatch(setAttributionReportOmissionMessages(omissionMessageArray));
  };

export const handleAttributionSegmentsSave =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    // this method will call the new api endpoint and handle success/failure on save
    const state = getState();
    const cacheQueryId = resultSelectors.getLastExecutedCacheQueryId(state);
    const report = reportSelectors.getReport(state) as IReport;
    const reportId = report?.id;
    dispatch(setSegmentsSavingStatus(savingStatus.saving));
    dispatch(disableSave());
    dispatch(disableRun());
    dispatch(disableRefresh());
    dispatch(disableSaveAs());
    const apiResponse = await fetchApi({
      endpoint: `reports/${Index.SEGMENT_GENERATE_EXPOSURE_SEGMENTS}/${cacheQueryId}/${reportId}`,
      method: FetchMethod.POST,
    });
    const data = apiResponse.data as unknown as ISaveSegmentsResponse;
    const filenames = data?.files;
    dispatch(setSaveSegmentsFilenames(filenames));
    dispatch(enableSave());
    dispatch(enableRun());
    dispatch(enableRefresh());
    dispatch(enableSaveAs());
    dispatch(
      setSegmentsSavingStatus(
        apiResponse?.error ? savingStatus.failed : savingStatus.success,
      ),
    );
  };

export const setReportResultAction =
  (obtainedResult?: IQueryResult, updatedSGMConfig?: ISGMConfig) =>
  (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): void => {
    const state = getState();
    const dimensions = get('domains.dimensions', state);
    const report = reportSelectors.getReport(state) as IReport;
    const targetList = get('query.targets', report) ?? [];
    const defaultChartData = memoize(() => ({
      SGMConfig: {
        groups: [],
        metrics: [],
        series: [],
      },
      dimensions: report.query?.group ?? [],
      interval: {},
      metadata: [],
      result: [],
      select: [],
      spatialDimensions: [],
      tableVisible: false,
      chartVisible: false,
    }));

    if (!obtainedResult) {
      dispatch(setReportResult(initialState));
      return;
    }

    const {
      data,
      metadata,
      result,
      rows: rowCount,
      rows_dropped: rowsDropped,
    } = obtainedResult;

    trackResultRetrieved(report, obtainedResult);

    const spatialDimensions: string[] = getIntervalDimensions(report.query);
    let selectedDisplayedMetrics = get('selectedMetrics', report) ?? [];
    let selectedDisplayedCharts = get('selectedCharts', report) ?? [];

    const loadedMetricsAreInQuery =
      !!selectedDisplayedMetrics.length &&
      selectedDisplayedMetrics.every((x) => report?.query?.select?.includes(x));

    if (!loadedMetricsAreInQuery && report?.query?.select?.length) {
      selectedDisplayedMetrics = report?.query?.select;
    }

    selectedDisplayedCharts = !!selectedDisplayedCharts.length
      ? selectedDisplayedCharts
      : [ChartTypes.column];

    const defaultSMGConfig = getSGMConfig(
      report.query,
      selectedDisplayedMetrics,
      selectedDisplayedCharts,
    );

    if (report?.query?.interval?.id === 'ALL_VIEWING') {
      defaultSMGConfig.meta = {
        rowLimit: isNumber(report.rowLimit) ? report.rowLimit : 10,
      };
    }

    const chartData = {
      ...defaultChartData(),
      data,
      metadata: (metadata ?? []).map((x) => ({
        ...x,
        dimension_meta: (dimensions ?? []).find(
          (dim: IDimension) => dim.id === x.id,
        ),
      })),
      result,
      rowCount,
      rowsDropped,
      ...(report?.query?.interval && { interval: report?.query?.interval }),
      ...(report?.query?.cumulative_group && {
        cumulative_group: report?.query?.cumulative_group,
      }),
      ...(report?.query?.select && { select: report?.query?.select }),
      ...(report?.query?.group && { dimensions: uniq(report?.query?.group) }),
      SGMConfig: updatedSGMConfig ?? defaultSMGConfig,
      spatialDimensions,
      tableVisible: report?.query?.tableVisible,
      chartVisible: report?.query?.chartVisible,
    };

    // Add AUDIENCES dimension if multitargets
    if (targetList.length > 1 && !chartData.dimensions.includes('TARGET')) {
      chartData.dimensions.push('TARGET');
    }

    const selectedGroups = chartData.data?.length
      ? chartData.spatialDimensions
      : [
          ...(report?.query?.group ?? []),
          report?.query?.interval?.id ?? '',
        ].filter((x) => x !== 'ALL_VIEWING');

    const businessRules = getBusinessRules(
      selectedGroups,
      chartData.SGMConfig,
      report.type === 'target',
    );

    if (
      businessRules?.hasSpatialDimensions &&
      !businessRules?.isAllViewing &&
      targetList.length
    ) {
      chartData.SGMConfig.meta = {
        ...chartData.SGMConfig.meta,
        targetList: targetList[0],
      };
    }

    const payload = {
      chartData,
      displayChartData: chartData,
      rowCount,
      targetList,
    };

    batch(() => {
      dispatch(setReportResult(payload));
      dispatch(setSelectedMetrics(selectedDisplayedMetrics));
      dispatch(setDateRange(report));
      dispatch(setSelectedCharts(selectedDisplayedCharts));
    });
  };

export const executionReportSuccessAction =
  (reportResult: IQueryResult) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const report = reportSelectors.getReport(getState());

    batch(() => {
      dispatch(setLastValidReport(report));

      if (!isEqual(reportResult, report)) {
        dispatch(setLastCachedDate(reportResult.created));

        if (isEmpty(get('selectedMetrics', report))) {
          const query = get('query', report);
          const { selectedMetrics, selectedCharts } =
            getDefaultSelectedConfig(query);
          dispatch(setSelectedMetrics(selectedMetrics));
          dispatch(setSelectedCharts(selectedCharts));
        }
      } else {
        dispatch(setLastCachedDate(report.notPersistedProps?.cacheCreatedAt));
      }

      dispatch(setReportResultAction(reportResult));
      dispatch(
        processRules({
          isBaseReportRefreshing: false,
          isBaseReportRunning: false,
          isAccordionSettingsOpen: false,
          isAccordionQueryOpen: false,
          isAccordionResultsOpen: true,
        }),
      );
    });
  };

export const executionJobAttributionReportSuccessAction =
  (reportResult: IReportJobCompleteResponse) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const aggregation_method = reportSelectors.getAggregationMethod(getState());
    const {
      rows_dropped,
      vendor_source_name,
      breakouts,
      result_demographic_stats,
      result_misc,
      result_breakouts,
      demographic_breakouts,
      executed_at,
    } = reportResult;
    const state = getState();
    const currentReport = reportSelectors.getReport(state);
    trackResultRetrieved(currentReport, reportResult);
    dispatch(setLastCachedDate(executed_at));
    const report = reportSelectors.getReport(getState());
    batch(() => {
      dispatch(setLastValidReport(report));

      // Show/hide LROI visualization components based on report config
      dispatch(
        setShowLROI(aggregation_method === AggregationMethodOptions.ADVANCED),
      );

      // Set breakout_list
      dispatch(setBreakoutList(breakouts));

      dispatch(setDemographicBreakoutList(demographic_breakouts));

      // Set Vendor Source Name
      dispatch(setVendorSourceName(vendor_source_name));

      // Set Result data must be the last call otherwise the other Visualization params don't set before rendering.
      dispatch(setAttributionReportResultBreakouts(result_breakouts));
      dispatch(
        setAttributionReportResultDemographicStats(result_demographic_stats),
      );
      dispatch(setAttributionReportResultMiscStats(result_misc));
      dispatch(processSpecializedInsightsChart(result_misc));
      dispatch(generateMetrics());

      const conversionType = reportSelectors.getConversionType(state) ?? '';
      dispatch(setAttributionReportResultConversionType(conversionType));

      dispatch(
        processAttributionReportOmissionMessages({
          rows: rows_dropped as RowsDropped,
          breakoutList: breakouts,
          demographicBreakoutList: demographic_breakouts,
        }),
      );
      dispatch(
        processRules({
          isBaseReportRefreshing: false,
          isBaseReportRunning: false,
          isAccordionQueryOpen: false,
          isAccordionMethodologyOpen: false,
          isAccordionResultsOpen: true,
          isBaseReportExporting: false,
        }),
      );
    });
  };

export const executionReportReset =
  () =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    dispatch(setProgress(0));
    dispatch(disableCancel());
    dispatch(
      processRules({
        isBaseReportRefreshing: false,
        isBaseReportRunning: false,
        hasBaseReportValidResults: false,
        isAccordionSettingsOpen: false,
        isAccordionQueryOpen: true,
        isAccordionMethodologyOpen: true,
        isAccordionResultsOpen: false,
      }),
    );
  };

export const setReportFailureErrorSpecificFlow =
  (message: string) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    dispatch(setErrorMessage(message));
    dispatch(
      processRules({
        isAccordionQueryOpen: false,
        isAccordionMethodologyOpen: false,
        isAccordionResultsOpen: true,
      }),
    );
  };

export const executionReportFailure =
  (error: ExecuteErrorResponse) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    dispatch(executionReportReset());
    dispatch(resetTargetResult());
    if (error?.message?.includes('minimum reporting threshold')) {
      dispatch(setReportFailureErrorSpecificFlow(error.message));
    } else if (error?.message?.includes('not found')) {
      // GRAF-1327 workaround to not show toast, since there's no
      // way to properly query if a query was cancelled currently for
      // platf0rm reports and targets
    } else if (error?.message?.toLowerCase().includes('custom exposure')) {
      let confirmationDialogMessage: string | string[] = error.message;
      if (error?.message?.indexOf('[') >= 0) {
        confirmationDialogMessage = convertStringToArray(
          error.message,
        ) as string[];
      }
      const confirmationDialogType = confirmDialogType['noResults'];
      dispatch(
        setShowDialogModal({
          confirmationDialogMessage,
          confirmationDialogType,
        }),
      );
    } else {
      dispatch(setErrorMessage(error?.message ?? 'Unknown error'));
    }
  };

const checkResultIntegration = (getState: () => State): boolean => {
  const state = getState();
  const report = reportSelectors.getReport(state);
  if (!report) return false;
  const executedReport = getExecutedReport(state);
  if (executedReport?.id) {
    return report.id === executedReport.id;
  }
  return true;
};

export const cancelExecutionJobAction =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const cacheQueryId = getCacheQueryId(state);
    const report = reportSelectors.getReport(state) as IReport;
    const reportIsAttributionReport = isIAttributionReport(report);

    const apiCall = reportIsAttributionReport
      ? {
          endpoint: `reports/${Actions.SEGMENT_REPORT_JOB}`,
          payload: { cacheQueryId, reportId: report.id },
          method: FetchMethod.DELETE,
        }
      : {
          endpoint: `/${DOMAINS.SEGMENT_RUNNING_QUERIES}/`,
          payload: { id: cacheQueryId, reportId: report.id },
          method: FetchMethod.DELETE,
        };

    const results = await fetchApi(apiCall);
    if (!checkResultIntegration(getState)) return;
    if (results.error) {
      dispatch(executionReportFailure(results.error));
    }
    dispatch(executionReportReset());
  };

export const executionTargetSuccessAction =
  (reportResult: IExecuteTargetResult) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const report = reportSelectors.getReport(state);

    const businessData = businessDataSelectors.getBusinessData(state);
    let universeLabel = businessData?.universeName ?? DEFAULT_UNIVERSE_NAME;
    const hasVendorSource = universeLabel !== DEFAULT_UNIVERSE_NAME;
    const HHFootprint = report?.query?.genpop_crosswalk_combination;
    if (!hasVendorSource && HHFootprint) {
      const allDatasets = domainsSelectors.getDatasets(state);
      const crosswalkCombinationDataset = allDatasets.find(
        (item: IDataset) => item.id === HHFootprint,
      );
      universeLabel =
        crosswalkCombinationDataset?.extended_metadata?.universe_label;
    }
    trackResultRetrieved(reportSelectors.getReport(getState()), reportResult);

    const userPermissionsTree = getPermissions(state);
    const hasISpotSegmentExportPermission = checkPermissionTree(
      'targets.ispot_segment_export::view',
      userPermissionsTree,
    );
    if (hasISpotSegmentExportPermission) {
      const exportConfigOptionsResponse = await fetchApi({
        endpoint: `${Index.SEGMENT_CONFIG_OPTIONS}/${ISPOT_SEGMENT_CONFIG_OPTION_ID}`,
        method: FetchMethod.GET,
      });
      const exportConfigData = get(
        'data.0',
        exportConfigOptionsResponse,
      ) as IConfigOption;
      if (exportConfigOptionsResponse.error || !exportConfigData?.id) {
        showErrorToast('Failed to fetch export config options.');
      } else {
        dispatch(setExportConfigOptions(exportConfigData.values ?? []));
      }
    }
    dispatch(setLastValidReport(report));
    dispatch(
      setTargetResult({
        ...reportResult,
        universeLabel,
      }),
    );
    dispatch(
      processRules({
        isBaseReportRefreshing: false,
        isBaseReportRunning: false,
        isAccordionQueryOpen: false,
        isAccordionResultsOpen: true,
        targetHasValidResults: true,
      }),
    );
  };

export const handleConfigOptionExport =
  (configOptionId: string, toggleModalHook: (isOpen: boolean) => void) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    toggleModalHook(false);
    const lastValidReport = reportSelectors.getLastValidReport(state);
    if (!get('query.genpop_crosswalk_combination', lastValidReport)) {
      showErrorToast(LOCALE_EMPTY_SEGMENT_FILE);
      return;
    }
    const cacheQueryId = resultSelectors.getLastExecutedCacheQueryId(state);
    await dispatch(
      downloadActions.handleDownload(
        'targets/download',
        {
          runReportProps: {
            report: {
              ...lastValidReport,
              use_async: true,
              iSpotIngestClient: configOptionId,
            },
          },
          format: CROSSWALKED_FORMAT,
          isFileUpload: true,
          cacheQueryId,
        },
        'S3 Upload completed!',
        'S3 Upload failed!',
        false,
      ),
    );
  };

export const executionReportFinished =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const report = reportSelectors.getReport(state);
    const selectedClient = getSelectedClient(state);

    const reportIsAttributionReport = isIAttributionReport(report);
    const reportIsReport = isReport(report);
    const cacheQueryId = getCacheQueryId(getState());
    const isDownloadQuery = getDownloadQuery(getState());
    const isFileUpload = getFileUpload(getState());
    const areResultsHidden =
      !report.query.chartVisible &&
      !report.query.tableVisible &&
      reportIsReport;
    ``;
    if (areResultsHidden) {
      // Cancel the execute API call if not needed
      dispatch(executionReportSuccessAction(report));
      return;
    }
    const apiCall = reportIsAttributionReport
      ? isDownloadQuery
        ? {
            endpoint: `reports/${Actions.SEGMENT_REPORT_JOB}/xlsx`,
            payload: {
              cacheQueryId,
              clientId: selectedClient,
              report,
              isFileUpload,
            },
            method: FetchMethod.POST,
            isDownload: true,
          }
        : {
            endpoint: `reports/${Actions.SEGMENT_REPORT_JOB}/${cacheQueryId}`,
            method: FetchMethod.GET,
          }
      : {
          endpoint: `reports/${Actions.SEGMENT_EXECUTE}`,
          method: FetchMethod.POST,
          payload: {
            report,
            clientId: selectedClient,
            cacheQueryId,
          },
        };

    const results = await fetchApi(apiCall);
    if (!checkResultIntegration(getState)) return;
    if (results.error) {
      dispatch(executionReportFailure(results.error));
      return;
    }

    const executeResults = get('data[0].result', results);

    if (get('status', executeResults) === NOT_FOUND_CACHE_RESULT) {
      dispatch(executionReportReset());
      return;
    }

    const resultData = get('data', executeResults);
    if (!resultData?.length && executeResults?.rows_dropped === 0) {
      const confirmationDialogMessage = CONFIRM_QUERY_MESSAGES['noResults'];
      const confirmationDialogType = confirmDialogType['noResults'];
      dispatch(
        setShowConfirmationDialog({
          confirmationDialogMessage,
          confirmationDialogType,
        }),
      );
    }

    if (isDownloadQuery) {
      if (isFileUpload) {
        showSuccessToast(LOCALE_REPORT_UPLOADED);
      } else {
        // Generate Filename
        const fileName = !report.name
          ? `export_${new Date().toISOString()}.xlsx`
          : `${report.name
              .replace(/[^a-z0-9]/gi, '_')
              .toLowerCase()}_${new Date().toISOString()}.xlsx`;

        // Generate the file from the results data
        const blob = new Blob(results.data as unknown as BlobPart[], {
          type: 'text/plain',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showSuccessToast(LOCALE_REPORT_DOWNLOADED);
      }
      // Finished exporting set states to reflect success.
      dispatch(
        processRules({
          isBaseReportRunning: false,
          isBaseReportExporting: false,
        }),
      );

      return;
    }

    if (reportIsAttributionReport) {
      await dispatch(loadResultSelection());
      dispatch(executionJobAttributionReportSuccessAction(executeResults));
      return;
    }

    if (reportIsReport) {
      dispatch(executionReportSuccessAction(executeResults));
      return;
    }

    // if is target..
    dispatch(executionTargetSuccessAction(executeResults));
  };

export const executionReportCheckStatus =
  (type: ReportType) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const cacheQueryId = getCacheQueryId(getState());

    let apiCall;
    const reportIsAttribution = type === reportType.attributionReport;
    if (reportIsAttribution) {
      apiCall = {
        endpoint: `reports/${Actions.SEGMENT_REPORT_JOB}/${cacheQueryId}/status`,
        method: FetchMethod.GET,
      };
    } else {
      apiCall = {
        endpoint: `reports/${Actions.SEGMENT_STATUS}`,
        method: FetchMethod.POST,
        payload: { cacheQueryId },
      };
    }

    const results = await fetchApi(apiCall);
    if (!checkResultIntegration(getState)) return;
    const executeResults = get('data[0]', results);
    if (results.error) {
      dispatch(executionReportFailure(results.error));
      return;
    }

    const progress = reportIsAttribution
      ? executeResults.total_progress
      : executeResults.progress;

    dispatch(setProgress(progress));

    if (reportIsAttribution) {
      dispatch(setQueryProgress(executeResults.progress));
    }

    const isCompleted = reportIsAttribution
      ? executeResults.status === ReportJobExecutionStatuses.COMPLETED
      : progress === 100;

    if (isCompleted) {
      dispatch(executionReportFinished());
      dispatch(reportJobExecutionFinished());
      return;
    }

    if (
      executeResults.status === ReportJobExecutionStatuses.CANCELLED ||
      resultSelectors.getExecutedReportCancelling(getState())
    ) {
      dispatch(executionReportReset());
      dispatch(reportJobExecutionCancelled());
      return;
    }

    await waitSeconds(STATUS_CHECK_INTERVAL_SECONDS);

    dispatch(executionReportCheckStatus(type));
  };

export const executionReportStarted = (payload: string): Action<string> => ({
  type: ActionTypes.RESULT_SET_EXECUTED_REPORT_STARTED,
  payload,
});

export const reportJobExecutionFinished = (): PayloadLessAction => ({
  type: ActionTypes.RESULT_SET_EXECUTED_REPORT_FINISHED,
});

export const reportJobExecutionCancelled = (): PayloadLessAction => ({
  type: ActionTypes.RESULT_SET_EXECUTED_REPORT_CANCELLED,
});

export const executionReportStartedAction =
  (queryId: string, type: ReportType) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    dispatch(enableCancel());
    dispatch(executionReportStarted(queryId));
    dispatch(setProgress(0));
    dispatch(executionReportCheckStatus(type));
    dispatch(processRules({}));
  };

export const executionShowReportPayload =
  () =>
  async (
    _dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<Promise<void> | Promise<Action<IConfirmDialog>>> => {
    const state = getState();
    const report = reportSelectors.getReport(state);

    const reportIsAttributionReport = isIAttributionReport(report);

    const endpoint = reportIsAttributionReport
      ? `reports/${Actions.SEGMENT_ATTR_REPORT_PAYLOAD}/`
      : `reports/${Actions.SEGMENT_REPORT_PAYLOAD}/`;

    const breakouts = getAttributionBreakouts();

    const apiCall = {
      endpoint,
      payload: {
        report: {
          ...report,
          query: {
            ...report.query,
            ...(reportIsAttributionReport && { breakouts }),
          },
        } as IReport,
      },
      method: FetchMethod.POST,
    };
    const apiResults = await fetchApi(apiCall);
    console.info('Payload', apiResults.data[0]);
  };

export const executionReportInitialize =
  (
    cache: cacheMode,
    doConfirmComplexity = false,
    isDownloadQuery = false,
    isFileUpload = false,
  ) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<Promise<void> | Promise<Action<IConfirmDialog>>> => {
    const state = getState();
    const report = reportSelectors.getReport(state);
    const { type } = report ?? {};
    const messageList = messageListSelectors.getMessageList(state);
    let isReportInvalid = false;
    if (messageList) isReportInvalid = messageList.length > 0;
    else
      isReportInvalid = !isBaseReportValid(
        report as IReport,
        getDatasetSelection((report as IReport).query.mode),
      );
    if (isReportInvalid) {
      const invalidityReason = messageList.length
        ? 'inline-validation'
        : 'base-report-invalid';
      trackInlineValidationShown(report, invalidityReason, messageList);
      // If report is invalid dispatch a banner for validation error.
      dispatch(setIsReportInvalid(true));
      await dispatch(
        processRules({
          isAccordionSettingsOpen: false,
          isAccordionQueryOpen: true,
          isAccordionMethodologyOpen: true,
          isAccordionResultsOpen: false,
        }),
      );
      return;
    }
    const dynamicBreakouts =
      reportSelectors.dynamicBreakouts(state) ??
      ([] as { id: string; name: string }[]);
    if (
      isEmpty(dynamicBreakouts) &&
      report?.query?.mode === 'MODE_GENERIC_EVENTS'
    ) {
      trackInlineValidationShown(
        report,
        'empty-dynamic-breakouts',
        messageList,
      );
      showErrorToast('Empty dynamic breakouts!');
      await dispatch(
        processRules({
          isAccordionSettingsOpen: false,
          isAccordionQueryOpen: true,
          isAccordionMethodologyOpen: true,
          isAccordionResultsOpen: false,
        }),
      );
      return;
    }
    dispatch(setIsReportInvalid(false));
    const rulesToProcess = !isDownloadQuery
      ? {
          isBaseReportRefreshing: cache === cacheMode.force,
          isBaseReportRunning: true,
          isBaseReportInitiallyExecuted: true,
          isAccordionSettingsOpen: false,
          isAccordionQueryOpen: true,
          isAccordionMethodologyOpen: true,
          isAccordionResultsOpen: false,
        }
      : {
          isBaseReportExporting: true,
        };
    if (!isDownloadQuery) {
      // Want to keep report result if just exporting
      dispatch(resetAttributionReportResult());
    }
    const reportIsReport = isReport(report);
    const reportIsTarget = isTarget(report);
    const reportIsAttributionReport = isIAttributionReport(report);
    const isCached = cache === cacheMode.always;
    dispatch(setDownloadQuery(isDownloadQuery));
    dispatch(setFileUpload(isFileUpload));
    dispatch(setExecutedReport(report));
    await dispatch(processRules(rulesToProcess));
    dispatch(setProgress(0));
    dispatch(disableCancel());

    const endpoint = reportIsAttributionReport
      ? `reports/${Actions.SEGMENT_REPORT_JOB}/`
      : `reports/${Actions.SEGMENT_EXECUTE}/`;

    const breakouts = getAttributionBreakouts();

    const apiCall = {
      endpoint,
      payload: {
        report: {
          ...report,
          query: {
            ...report.query,
            ...(reportIsAttributionReport && { breakouts }),
          },
          cache_mode: cache,
        } as IReport,
        doComplexityCheck: !doConfirmComplexity,
      },
      method: FetchMethod.POST,
    };
    const apiResults = await fetchApi(apiCall);

    if (!checkResultIntegration(getState)) return;

    if (apiResults.error) {
      dispatch(executionReportFailure(apiResults.error));
      return;
    }
    if (get('data[0].result.status', apiResults) === NOT_FOUND_CACHE_RESULT) {
      dispatch(executionReportReset());
      return;
    }

    // If is cached we just show the results
    if (isCached) {
      const results = get('data[0].result', apiResults);
      if (reportIsReport || reportIsTarget) {
        dispatch(executionReportSuccessAction(results));
        return;
      }
    }

    // If we find complexity on that execution we ask the user to execute it!
    const complexityRows = get(
      'data[0].complexity.num_schedule_rows',
      apiResults,
    );
    if (!isUndefined(complexityRows)) {
      const key = complexityRows === 0 ? 'noResults' : 'confirmLongQuery';
      const confirmationDialogMessage = CONFIRM_QUERY_MESSAGES[key];
      const confirmationDialogType = confirmDialogType[key];
      dispatch(
        setShowConfirmationDialog({
          confirmationDialogMessage,
          confirmationDialogType,
        }),
      );
      return;
    }

    // If not we continue getting async data
    const queryString = reportIsAttributionReport
      ? 'data[0].report_resource_id'
      : 'data[0].query_id';
    const queryId = get(queryString, apiResults);

    if (!queryId) {
      dispatch(executionReportReset());
      return;
    } // Check if we don't have a cache and trigger a reset to enable 'Run'
    dispatch(executionReportStartedAction(queryId, type));
    // Disabled as part of GRAF-1518 Run and Save Feature
    // if (!isCached) showSuccessToast(LOCALE_REPORT_UPDATED);
  };

export const getAttributionBreakouts = (): { id: string; name: string }[] => {
  const state = store.getState();
  const dynamicBreakouts =
    reportSelectors.dynamicBreakouts(state) ??
    ([] as { id: string; name: string }[]);

  const userPermissionsTree = getPermissions(state);
  const hasDayOfWeekPermission = checkPermissionTree(
    'attribution_reports.breakouts.day_of_week::view',
    userPermissionsTree,
  );
  const hasAdDurationPermission = checkPermissionTree(
    'attribution_reports.breakouts.duration_rounded_label::view',
    userPermissionsTree,
  );
  const hasSeriesGenrePermission = checkPermissionTree(
    'attribution_reports.breakouts.parent_series_genre::view',
    userPermissionsTree,
  );
  const hasSeriesPermission = checkPermissionTree(
    'attribution_reports.breakouts.content_series_title::view',
    userPermissionsTree,
  );
  const hasPlatformPermission = checkPermissionTree(
    'attribution_reports.breakouts.viewing_type::view',
    userPermissionsTree,
  );

  const attributionBreakouts: { id: string; name: string }[] = [
    { id: 'CREATIVE_LABEL', name: 'Creative' },
    { id: 'NETWORK', name: 'Network' },
    { id: 'DAYPART', name: 'Daypart' },
    { id: 'DAY_OF_WEEK', name: 'Day of Week' },
    { id: 'DURATION_ROUNDED_LABEL', name: 'Ad Duration' },
    { id: 'PARENT_SERIES_GENRE', name: 'Series Genre' },
    { id: 'CONTENT_SERIES_TITLE', name: 'Series' },
    { id: 'VIEWING_TYPE', name: 'Platform' },
  ];

  const resultBreakouts = (
    isEmpty(dynamicBreakouts) ? attributionBreakouts : dynamicBreakouts
  ).filter((item: { id: string; name: string }) => {
    if (item.id === 'DAY_OF_WEEK' && !hasDayOfWeekPermission) return false;
    if (item.id === 'DURATION_ROUNDED_LABEL' && !hasAdDurationPermission)
      return false;
    if (item.id === 'PARENT_SERIES_GENRE' && !hasSeriesGenrePermission)
      return false;
    if (item.id === 'CONTENT_SERIES_TITLE' && !hasSeriesPermission)
      return false;
    if (item.id === 'VIEWING_TYPE' && !hasPlatformPermission) return false;
    return true;
  });

  return resultBreakouts;
};

export const getTableData =
  (rowData: DataRowGraph[], generateIndexColumn?: boolean) =>
  (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): ITableConfig => {
    const state = getState();
    const displayChartData = resultSelectors.getDisplayChartData(
      get('reportResult', state),
    );
    const updatedTableData = {
      ...displayChartData,
      data: rowData,
    };
    const inputData = get('data', updatedTableData);
    const inputMetadata = get('metadata', updatedTableData);
    const tableCols = getCols(updatedTableData);

    if (!inputData || isEmpty(tableCols)) {
      return {
        headers: [],
        data: [],
      };
    }
    const config = {
      ROWS: getRows(updatedTableData),
      COLS: tableCols,
      VALUES: getValues(updatedTableData),
    };

    const { data, headers, unlinkedHeaders } = generatePivotTable(
      inputData,
      config,
      inputMetadata,
    );

    let newData: DataRowGraph[] = [];
    if (data) {
      newData = data.map((row: TableRowConfig) => {
        const newRows: DataRowGraph = {};
        Object.keys(row).forEach((key: string) => {
          newRows[key] = {
            DISPLAY_VALUE: row[key].toString(),
            VALUE: row[key].toString(),
          };
        });
        return newRows;
      });
    }

    // Change the colspan depending on the number of ROWS
    if (unlinkedHeaders) {
      unlinkedHeaders.forEach((subheader: IColumn[], index: number) => {
        if (index === 0) {
          subheader[0].colspan =
            config.ROWS.length + (generateIndexColumn ? 1 : 0);
        }
      });
    }

    return {
      unlinkedHeaders,
      headers,
      data: newData,
    };
  };

const campaignMetrics = [
  'campaign.REACH',
  'campaign.REACH_PC',
  'campaign.IMPRESSIONS',
  'campaign.FREQUENCY',
  'campaign.SPOT_COUNT',
  'campaign.UNIQUE_REACH',
  'campaign.AD_COST',
];

const performanceBaseMetric = [
  'MEAN',
  'LIFT',
  'PERSUASION_INDEX',
  'INCREMENTAL_LIFT',
  'COST_PER_INCREMENTAL_LIFT',
  'PERSUASION_INDEX_BY_COST',
];

export const generateMetrics =
  () =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    dispatch(setAttributionReportMetrics([]));
    const performanceMetricGroups: PerformanceGroupNames[] =
      reportSelectors.getPerformanceMetricGroupsFromLastValidReport(getState());
    const metrics = campaignMetrics;
    performanceMetricGroups?.forEach((pm) => {
      performanceBaseMetric?.forEach((pbm) => {
        metrics.push(`${pm}.${pbm}`);
      });
    });
    dispatch(setAttributionReportMetrics(metrics));
  };
