import { connect } from 'react-redux';
import * as toolbarSelectors from 'store/selectors/toolbar';
import State from 'types/state';
import { QueryProgressComponent } from './QueryProgress.component';
import { IQueryProgressComponentProps } from './types';

const mapStateToProps = (state: State): IQueryProgressComponentProps => ({
  queryProgress: toolbarSelectors.getQueryProgress(state),
});

export const QueryProgress = connect(mapStateToProps)(QueryProgressComponent);
