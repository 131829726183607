import IReport, {
  IQueryResult,
  IReportJobCompleteResponse,
  reportType,
} from 'domains/reports/types';
import {
  filter,
  flow,
  get,
  has,
  isEmpty,
  isObject,
  map,
  negate,
  pickBy,
} from 'lodash/fp';
import store from 'store';
import { IExecuteTargetResult } from 'store/reducers/targetResult';
import { getClientName, getDatasetNames } from 'store/selectors/domains';
import {
  FilterValue,
  IChildrenBase,
  Operator,
  RuleFilter,
  allOperators,
} from 'types/filter';
import IIdName from 'types/idName';
import State from 'types/state';
import ITextValue from 'types/textValue';

const MS_PER_DAY: number = 1000 * 60 * 60 * 24;

const isNotEmpty = negate(isEmpty);
const isNotObject = negate(isObject);
const pickPrimitiveFields = pickBy(isNotObject);
const pickPrimitiveQueryFields = flow(get('query'), pickPrimitiveFields);
const getIsTarget = flow(get('targetData'), isNotEmpty);

const getClientFields = (
  clientId?: string,
): { client: string; clientId?: string } => {
  const state = store.getState() as State;
  return {
    client: getClientName(clientId, state),
    clientId,
  };
};

const mapObjects = (report: IReport): {} => {
  const { client, selectedCharts, selectedMetrics, sort_field, sort_order } =
    report;
  return {
    selectedCharts,
    selectedMetrics,
    sort_field: get('id', sort_field),
    sort_order: get('id', sort_order),
    ...getClientFields(client),
  };
};

const getQueryFilterList = flow(
  get('children'),
  filter(has('id')),
  map('field'),
);

const getQueryFilterOperatorList = flow(
  get('children'),
  filter(has('id')),
  map(
    (f: RuleFilter) => f.field + ' | ' + allOperators[f.operator as Operator],
  ),
);

const datediff = (first: Date, second: Date): number =>
  Math.round((second.getTime() - first.getTime()) / MS_PER_DAY);

export const getDatasetNameList = (idList: string[] | undefined): string[] => {
  const state = store.getState() as State;
  return getDatasetNames(idList, state);
};

const flattenRuleValues = (valueObj: FilterValue): string[] => {
  if (!valueObj) return [];

  if (Array.isArray(valueObj)) {
    return valueObj.map((item) => {
      if (has('name', item)) {
        return (item as IIdName).name;
      } else if (has('text', item)) {
        return (item as ITextValue).text;
      } else {
        return item as string;
      }
    });
  } else {
    return [valueObj];
  }
};

const mapRuleNameValuePairs = (report: IReport): {} => {
  const query = get('query', report);
  const filters = get('filters', query);
  const children = get('children', filters) as IChildrenBase;

  return (children as RuleFilter[])?.reduce((acc, curr): {} => {
    const { id, value } = curr;
    return !id || !value
      ? acc
      : {
          ...acc,
          [id]: flattenRuleValues(value),
        };
  }, {});
};

const mapQueryObjects = (report: IReport): {} => {
  const query = get('query', report);
  const datasets = getDatasetNameList(get('datasets', query));
  const filters = get('filters', query);
  const group = get('group', query);
  const interval = get('interval', query);
  const select = get('select', query);
  const sorting = get('sorting', query);
  const targets = get('targets', query);
  const dateEnd: Date = new Date(get('children[0].end', filters));
  const dateStart: Date = new Date(get('children[0].start', filters));
  const totalDays: number = datediff(dateStart, dateEnd);

  return {
    ...mapRuleNameValuePairs(report),
    datasets,
    dateEnd,
    dateStart,
    filterOperator: get('operator', filters),
    filters: getQueryFilterList(filters),
    operators: getQueryFilterOperatorList(filters),
    filterType: get('type', filters),
    group,
    interval: get('id', interval),
    select,
    sortingField: get('field', sorting),
    sortingOrder: get('order', sorting),
    targets,
    // targetNameList,
    // targetIdList,
    totalDays,
  };
};

const getHHCountFromResult = get('targetData.target.universe[0].universe');

const getFilters = get('query.filters');
const getChildren = get('children');

type genericObject<T = string> = Record<string, T>;

const recursivelyGetFilterValues = (
  filter: genericObject,
): genericObject<string | string[]> => {
  const children = getChildren(filter);

  if (children) {
    return children.reduce(
      (allFilters: genericObject, child: genericObject) => ({
        ...allFilters,
        ...recursivelyGetFilterValues(child),
      }),
      {},
    );
  }

  return filter?.id && filter.type !== 'DATETIME_RANGE'
    ? {
        [filter.id]: Array.isArray(filter.value)
          ? filter.value.map((v: genericObject) => v.text ?? v.name)
          : filter.value,
      }
    : {};
};

const getStartEndDate = (filter: genericObject): genericObject =>
  [
    ['exposures_ads', ''],
    ['outcomes_viewership', 'Viewership'],
  ].reduce((allDates, [filterName, sufix]) => {
    const exposureFilter = getChildren(filter)?.find(
      ({ name }: { name: string }) => name === filterName,
    )?.children?.[0];

    return {
      ...allDates,
      [`dateStart${sufix}`]: exposureFilter?.start,
      [`dateEnd${sufix}`]: exposureFilter?.end,
    };
  }, {});

const pickAttributeReports = (
  report: IReport,
): genericObject<string | string[]> => {
  const filter = getFilters(report);
  const attributeFilters = recursivelyGetFilterValues(filter);
  const performance_metric_groups = get(
    'query.performance_metric_groups',
    report,
  );

  return {
    ...attributeFilters,
    filters: Object.keys(attributeFilters),
    ...getStartEndDate(filter),
    performance_metric_groups,
  };
};

const mapBreakouts = (report: IReport): {} => {
  const breakouts = get('query.breakout_categories.children', report) ?? [];

  const experianBreakouts = breakouts.filter(
    (ruleFilter: RuleFilter) => ruleFilter.type === 'EXPERIAN_BREAKOUTS',
  );
  const experianBreakoutsNames = experianBreakouts.map(
    (ruleFilter: RuleFilter) => ruleFilter.name,
  );
  const PIQBreakouts = breakouts.find(
    (ruleFilter: RuleFilter) => ruleFilter.type === 'PLACEIQ',
  );
  const PIQCategory = (get('children', PIQBreakouts) ?? []).find(
    (ruleFilter: RuleFilter) => get('id', ruleFilter) === 'PIQ_CATEGORY',
  );
  const PIQCategoryNames = (get('value', PIQCategory) ?? []).map(
    (item: ITextValue) => item.text,
  );
  const PIQChain = (get('children', PIQBreakouts) ?? []).find(
    (item: RuleFilter) => get('id', item) === 'PIQ_CHAIN',
  );
  const PIQChainNames = (get('value', PIQChain) ?? []).map(
    (item: ITextValue) => item.text,
  );

  return {
    breakouts: [
      ...experianBreakoutsNames,
      ...PIQCategoryNames,
      ...PIQChainNames,
    ],
  };
};

export const pickAllFromReport = (report: IReport): {} => ({
  ...pickPrimitiveFields(report),
  ...pickPrimitiveQueryFields(report),
  ...mapObjects(report),
  ...mapQueryObjects(report),
  ...(report.type === reportType.attributionReport &&
    pickAttributeReports(report)),
  ...(report.type === reportType.target && mapBreakouts(report)),
});

export const pickAllFromResult = (
  result: IQueryResult | IReportJobCompleteResponse | IExecuteTargetResult,
): {} => {
  const isTarget = getIsTarget(result);
  const hhCount = Math.trunc(getHHCountFromResult(result) * 1000);

  return {
    ...(isTarget && { hhCount }),
  };
};

export const pickIdentityFromReport = (
  report: IReport | null | undefined,
): genericObject<string> => {
  if (!report) {
    return { id: '', name: '', reportType: '' };
  }

  const { id, name, type } = report;
  return { id, name: name ?? '', reportType: type ?? 'attribution-report' };
};
