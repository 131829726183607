import Flex, { Direction, Horizontal, Vertical } from 'components/Flex';
import { getClass, getTestId } from 'helpers/components';
import React, { PropsWithChildren } from 'react';
import Icon, {
  Type as IconType,
  Color as IconColor,
  Size as IconSize,
} from '../Icon';

// predefined colors which can be used instead of custom color strings
export enum HighlightCardColor {
  basicBorder = '#eaeaea',
  targetBorder = '#ac56f2',
  genpopBorder = '#219ed4',
  basicBackground = '#f0f0f0',
  targetBackground = '#b86ef4',
  genpopBackground = '#219ed4',
}

export type ITheme = {
  backgroundColor: string;
  borderColor: string;
  iconColor?: IconColor;
};

type IHighlightCardProps = {
  iconType: IconType;
  theme?: ITheme;
  title?: string;
  content?: string;
  footer?: string;
  testId?: string;
  subcontent?: string;
  clickToScroll?: () => void;
};

export const highlightCardComponentName = 'highlight-card';

const highlightCardClass = getClass(highlightCardComponentName);

const highlightCardDecoratorClass = getClass(highlightCardComponentName, {
  concat: ['decorator'],
});

const highlightCardIconClass = getClass(highlightCardComponentName, {
  concat: ['icon'],
});

const highlightCardContentContainerClass = getClass(
  highlightCardComponentName,
  { concat: ['content-container'] },
);

const highlightCardTitleClass = getClass(highlightCardComponentName, {
  concat: ['title'],
});

const highlightCardContentClass = getClass(highlightCardComponentName, {
  concat: ['content'],
});

const highlightCardSubContentClass = getClass(highlightCardComponentName, {
  concat: ['sub-content'],
});

const highlightCardCustomContentClass = getClass(highlightCardComponentName, {
  concat: ['custom-content'],
});

const highlightCardFooterClass = getClass(highlightCardComponentName, {
  concat: ['footer'],
});

const HighlightCard: React.FC<PropsWithChildren<IHighlightCardProps>> = ({
  theme,
  iconType,
  title,
  content,
  subcontent,
  footer,
  testId,
  children,
  clickToScroll,
}) => {
  const highlightCardTestId = getTestId(highlightCardComponentName, testId);

  const { backgroundColor, borderColor, iconColor } = theme ?? {};

  return (
    <section
      data-testid={highlightCardTestId}
      className={
        clickToScroll
          ? `${highlightCardClass} click-to-scroll-enabled`
          : highlightCardClass
      }
      style={{ borderColor }}
    >
      <div
        className={highlightCardDecoratorClass}
        style={{ backgroundColor }}
      />
      <Flex
        horizontal={Horizontal.center}
        vertical={Vertical.middle}
        className={highlightCardIconClass}
        style={{ backgroundColor }}
      >
        <Icon
          type={iconType}
          color={iconColor ?? IconColor.gray}
          size={IconSize.large}
        />
      </Flex>
      <Flex
        direction={Direction.column}
        horizontal={Horizontal.center}
        vertical={Vertical.top}
        className={highlightCardContentContainerClass}
      >
        {title && <span className={highlightCardTitleClass}>{title}</span>}
        {content && (
          <div>
            <span className={highlightCardContentClass}>{content}</span>
            {subcontent && (
              <span className={highlightCardSubContentClass}>{subcontent}</span>
            )}
          </div>
        )}
        {children && (
          <div className={highlightCardCustomContentClass}>{children}</div>
        )}
        {footer && <span className={highlightCardFooterClass}>{footer}</span>}
      </Flex>
      {clickToScroll && (
        <Flex
          horizontal={Horizontal.center}
          vertical={Vertical.middle}
          className="scroll-button-wrapper"
        >
          <button
            type="button"
            onClick={() => {
              clickToScroll();
            }}
          >
            <Icon
              type={IconType.clickToScrollDown}
              color={IconColor.gray}
              size={IconSize.medium}
            />
          </button>
        </Flex>
      )}
    </section>
  );
};

export default HighlightCard;
