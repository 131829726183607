import { NewInputValue } from 'components/Input';
import Dashboard, {
  IDashboardReport,
  IDashboardReportFormUpdatedPayload,
} from 'domains/dashboard/types';
import IMetric from 'domains/metrics/types';
import IReport from 'domains/reports/types';
import { fetchApi } from 'helpers/fetching';
import { showErrorToast } from 'helpers/general';
import { get } from 'lodash';
import { flow, map, split, trim, compact } from 'lodash/fp';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Index } from 'routes';
import ActionType from 'store/actions/types';
import * as dashboardSelectors from 'store/selectors/dashboard';
import * as domainsSelectors from 'store/selectors/domains';
import * as userSelector from 'store/selectors/user';
import { Action, PayloadLessAction } from 'types/action';
import FetchMethod from 'types/fetchMethod';
import State from 'types/state';
import ITextValue from 'types/textValue';

export const dashboardLoadAllReportsStarted = (): PayloadLessAction => ({
  type: ActionType.DASHBOARD_REPORT_FORM_LOAD_ALL_REPORTS_STARTED,
});

export const dashboardLoadAllReportsEnded = (
  payload: ITextValue[],
): Action<ITextValue[]> => ({
  type: ActionType.DASHBOARD_REPORT_FORM_LOAD_ALL_REPORTS_ENDED,
  payload,
});

export const dashboardLoadReportDetailsStarted = (): PayloadLessAction => ({
  type: ActionType.DASHBOARD_REPORT_FORM_LOAD_REPORT_DETAILS_STARTED,
});

export const dashboardLoadReportDetailsEnded = (
  id: string,
): Action<string> => ({
  type: ActionType.DASHBOARD_REPORT_FORM_LOAD_REPORT_DETAILS_ENDED,
  payload: id,
});

export const dashboardReportFormUpdateStarted = (): PayloadLessAction => ({
  type: ActionType.DASHBOARD_REPORT_FORM_UPDATE_STARTED,
});

export const dashboardReportFormUpdateEnded = (): PayloadLessAction => ({
  type: ActionType.DASHBOARD_REPORT_FORM_UPDATE_ENDED,
});

export const dashboardSetUserDashboard = (
  payload: Dashboard,
): Action<Dashboard> => ({
  type: ActionType.DASHBOARD_SET_USER_DASHBOARD,
  payload,
});

export const dashboardReportFormSetMetricsOptions = (
  payload: ITextValue[],
): Action<ITextValue[]> => ({
  type: ActionType.DASHBOARD_REPORT_FORM_SET_METRICS_OPTIONS,
  payload,
});

export const dashboardReportFormSetFieldValue = (
  key: string,
  value: NewInputValue,
): Action<IDashboardReportFormUpdatedPayload> => ({
  type: ActionType.DASHBOARD_REPORT_FORM_SET_FIELD_VALUE,
  payload: { key, value },
});

export const dashboardReportFormSet = (
  payload: IDashboardReport,
): Action<IDashboardReport> => ({
  type: ActionType.DASHBOARD_REPORT_FORM_SET,
  payload,
});

export const dashboardReportFormReset = (): PayloadLessAction => ({
  type: ActionType.DASHBOARD_REPORT_FORM_RESET,
});

export const loadAllReports =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    dispatch(dashboardLoadAllReportsStarted());
    const state = getState();
    const clientExtID = userSelector.getSelectedClientExtId(state);
    const allReportsResponse = await fetchApi({
      endpoint: `/${Index.SEGMENT_REPORTS}/simple?clientExtID=${clientExtID}`,
      method: FetchMethod.GET,
    });
    if (allReportsResponse.error) {
      showErrorToast(
        allReportsResponse?.error?.message ?? 'There has been an error.',
      );
    }
    const allReports = allReportsResponse.data as IReport[];
    const result = allReports.map(
      (report: IReport): ITextValue => ({
        text: report.name ?? '',
        value: report.id,
      }),
    );
    dispatch(dashboardLoadAllReportsEnded(result));
  };

export const handleChangeSelectedReport =
  (reportId: NewInputValue) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    dispatch(dashboardLoadReportDetailsStarted());
    const state = getState();
    const selectedClient = userSelector.getSelectedClient(state);
    const reportDetailsResponse = await fetchApi({
      endpoint: `/${Index.SEGMENT_REPORTS}/${reportId}`,
      method: FetchMethod.POST,
      payload: {
        clientId: selectedClient,
      },
    });
    const reportDetails = reportDetailsResponse.data[0] as IReport;
    const reportMetrics = get(reportDetails, 'query.select', []);
    const isCumulative = !!get(reportDetails, 'query.cumulative_group');
    const allMetrics = domainsSelectors.getMetrics(state);
    const metricOptions = reportMetrics.map((id: string) => {
      const targetMetric = allMetrics.find(
        (metric: IMetric) => metric.id === id,
      );
      return {
        text: isCumulative ? targetMetric?.cume_name : targetMetric?.name,
        value: id,
      };
    });
    dispatch(dashboardReportFormSetMetricsOptions(metricOptions));
    dispatch(dashboardLoadReportDetailsEnded(reportDetails.id));
  };

export const handleReportFormSubmit =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    dispatch(dashboardReportFormUpdateStarted());
    const state = getState();
    const currentForm = dashboardSelectors.getDashboardReportForm(state);
    const reports = dashboardSelectors.getAllDashboardReportsOptions(state);
    let title = currentForm.title;
    if (!title) {
      const selectedReport = reports.find(
        (report: ITextValue) => report.value === currentForm.reportId,
      );
      title = selectedReport?.text ?? '';
    }

    const chartColors = currentForm.chart_color;
    const chartColorList = flow(split(','), map(trim), compact)(chartColors);
    const newReportPayload = {
      ...currentForm,
      title,
      chart_color: '',
      chart_colors: chartColorList,
    };
    const currentDashboard = dashboardSelectors.getUserDashboard(state);
    const currentDashboardReports = currentDashboard.reports ?? [];

    const isEditingReport = currentDashboardReports.some(
      (item: IDashboardReport) => item.id === newReportPayload.id,
    );
    const reportsPayload = isEditingReport
      ? currentDashboardReports.map((item: IDashboardReport) =>
          item.id === newReportPayload.id ? newReportPayload : item,
        )
      : [...currentDashboardReports, newReportPayload];

    const payload = {
      ...currentDashboard,
      reports: reportsPayload,
    };
    const updateDashboardResponse = await fetchApi({
      endpoint: `/${Index.SEGMENT_DASHBOARDS}`,
      method: FetchMethod.PATCH,
      payload,
    });
    if (updateDashboardResponse.error) {
      dispatch(dashboardReportFormUpdateEnded());
      throw Error(updateDashboardResponse?.error?.message);
    }
    dispatch(dashboardReportFormUpdateEnded());
    dispatch(dashboardReportFormReset());
  };
