import ActionsBar from 'components/ActionsBar';
import { Size as ButtonSize, Type as ButtonType } from 'components/Button';
import { DropdownSearchQueryProvider } from 'components/DropdownSearch';
import Field from 'components/Field';
import { NewInputValue, Type as InputType } from 'components/Input';
import Label from 'components/Label';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import IClient from 'domains/clients/types';
import Dataset from 'domains/datasets/dataset';
import { DatasetTypes, IDataset } from 'domains/datasets/types';
import { getClass, getId, getTestId } from 'helpers/components';
import { handleDropdownSearchSyncFilter } from 'helpers/utils';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import ITextValue from 'types/textValue';
import { v4 as uuid } from 'uuid';

const LOCALE_DATASET_FIELD_DISPLAY_NAME_LABEL = 'Display name';
const LOCALE_DATASET_FIELD_NAME_LABEL = 'Name';
const LOCALE_DATASET_FIELD_TYPE_LABEL = 'Dataset Type';
const LOCALE_DATASET_FIELD_TYPE_PLACEHOLDER = 'Select Dataset Type...';
const LOCALE_DATASET_FIELD_CLIENTS_WITH_ACCESS_LABEL = 'Clients with Access';
const LOCALE_DATASET_FIELD_CLIENTS_WITH_ACCESS_PLACEHOLDER =
  'Select Clients to Assign to...';
const LOCALE_ACTION_OK = 'Save';
const LOCALE_ACTION_CANCEL = 'Cancel';
const LOCALE_ACTION_OK_LOADING = 'Saving...';

export const formComponentName = 'dataset-form';

export interface IFormProps {
  testId?: string;
  dataset?: IDataset;
  clients: IClient[];
  loading?: boolean;
  onSubmit: (dataset: IDataset, clientIds: string[]) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<IFormProps> = (props): ReactElement => {
  const { loading, clients, onSubmit, onCancel, testId } = props;

  let { dataset } = props;

  if (!dataset) dataset = new Dataset();

  const getClientsRightsIdsFromClientsSelected = (
    clientsSelected: ITextValue[],
  ): string[] => {
    const clientsRightsIds = [] as string[];

    if (!clientsSelected) {
      return clientsRightsIds;
    }

    clientsSelected.map((clientSelected: ITextValue): boolean => {
      clientsRightsIds.push(clientSelected.value as string);
      return true;
    });

    return clientsRightsIds;
  };

  const getClientsRightsOptionsByDatasetId = (
    datasetId: string,
  ): ITextValue[] => {
    const clientsWithDatasetId = [] as ITextValue[];

    if (!datasetId) {
      return clientsWithDatasetId;
    }

    clients.map((client: IClient): boolean => {
      if (
        client.dataset_rights?.includes(datasetId) &&
        client.id &&
        client.name
      ) {
        clientsWithDatasetId.push({
          text: client.name,
          value: client.id,
        });
      }

      return true;
    });

    return clientsWithDatasetId;
  };

  const getClientsRightsOptions = (): ITextValue[] => {
    const clientsOptions = [] as ITextValue[];

    clients.map((client: IClient): boolean => {
      if (client.name && client.id) {
        clientsOptions.push({
          text: client.name,
          value: client.id,
        });
      }

      return true;
    });

    return clientsOptions;
  };

  const datasetsTypesOptions = Object.values(DatasetTypes);
  const [displayName, setDisplayName] = useState(dataset?.display_name);
  const [name, setName] = useState(dataset?.name);
  const [datasetId] = useState(dataset?.dataset_id);
  const [type, setType] = useState(dataset?.type);
  const [clientsRights, setClientsRights] = useState(
    getClientsRightsOptionsByDatasetId(dataset?.id),
  );
  const clientsRightsOptions = getClientsRightsOptions();

  const handleDisplayNameChange = (newName: NewInputValue): void => {
    setDisplayName(newName as string);
  };

  const handleNameChange = (newName: NewInputValue): void => {
    setName(newName as string);
  };

  const handleTypeChange = (newType: NewInputValue): void => {
    setType(newType as string);
  };

  const handleClientsRightsChange = (clientsSelected: ITextValue[]): void => {
    setClientsRights(clientsSelected);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();

    const clientsRightsIdsSelected =
      getClientsRightsIdsFromClientsSelected(clientsRights);

    onSubmit(
      {
        id: dataset?.id ?? uuid(),
        dataset_id: datasetId,
        display_name: displayName,
        name,
        type,
      },
      clientsRightsIdsSelected,
    );
  };

  const formId = useMemo(() => getId(formComponentName), []);
  const formTestId = useMemo(
    () => getTestId(formComponentName, testId),
    [testId],
  );
  const formDefaultClass = useMemo(() => getClass(formComponentName), []);

  const filterClientsRightsOptions: DropdownSearchQueryProvider = useCallback(
    (valueFilter = '') =>
      handleDropdownSearchSyncFilter(
        valueFilter,
        clientsRightsOptions as ITextValue[],
      ),
    [clientsRightsOptions],
  );

  return (
    <section className={formDefaultClass} data-testid={formTestId}>
      <form onSubmit={handleSubmit} data-testid={`${formTestId}-form`}>
        <Field
          label={LOCALE_DATASET_FIELD_DISPLAY_NAME_LABEL}
          type={InputType.text}
          id={`${formId}-display-name`}
          name={`${formId}-display-name`}
          onChange={handleDisplayNameChange}
          value={displayName}
        />
        <Field
          label={LOCALE_DATASET_FIELD_NAME_LABEL}
          type={InputType.text}
          id={`${formId}-name`}
          name={`${formId}-name`}
          onChange={handleNameChange}
          value={name}
        />
        <Field
          label={LOCALE_DATASET_FIELD_TYPE_LABEL}
          type={InputType.select}
          id={`${formId}-type`}
          name={`${formId}-type`}
          onChange={handleTypeChange}
          options={datasetsTypesOptions}
          placeholder={LOCALE_DATASET_FIELD_TYPE_PLACEHOLDER}
          value={type}
        />
        <div className="search-dropdown-container">
          <div className="label-container">
            <Label text={LOCALE_DATASET_FIELD_CLIENTS_WITH_ACCESS_LABEL} />
          </div>
          <MultipleDropdownSelect
            ignoreGrouping
            placeholder={LOCALE_DATASET_FIELD_CLIENTS_WITH_ACCESS_PLACEHOLDER}
            options={clientsRightsOptions}
            onChange={handleClientsRightsChange}
            selected={clientsRights}
            queryProvider={filterClientsRightsOptions}
          />
        </div>
        <ActionsBar
          cancel
          cancelLabel={LOCALE_ACTION_CANCEL}
          disabledOK={loading}
          margin
          ok
          okLabel={loading ? LOCALE_ACTION_OK_LOADING : LOCALE_ACTION_OK}
          okSize={ButtonSize.medium}
          okType={ButtonType.submit}
          onCancel={onCancel}
        />
      </form>
    </section>
  );
};

export default Form;
