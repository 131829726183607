import { ChangeHandlerType, IModalDialogProps } from './types';
import { NewInputValue } from 'components/Input';
import ITextValue from 'types/textValue';
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';
import { v4 as uuid } from 'uuid';
import {
  IUserDimensionDetails,
  IUserDimensionValue,
} from 'domains/datasets/types';
import { get, isEmpty, isEqual } from 'lodash';
import {
  ArrayHelpers,
  FormikErrors,
  FormikHelpers,
  FormikTouched,
} from 'formik';
import { Actions, Index } from 'routes';
import { NavigateFunction } from 'react-router-dom';
import { fetchApi } from 'helpers/fetching';
import FetchMethod from 'types/fetchMethod';
import { showErrorToast, showSuccessToast } from 'helpers/general';
import { delay } from 'helpers/utils';
import { getOr } from 'lodash/fp';
import {
  ADD_CUSTOM_VALUE_ERROR_DIALOG,
  ADD_CUSTOM_VALUE_ERROR_DIALOG_CANCEL_LABEL,
  SAVE_ERROR_MESSAGE,
  SAVE_SUCCESS_MESSAGE,
  GO_BACK_BUTTON_LABEL,
  CONTINUE_BUTTON_LABEL,
  SOURCE_DIMENSION_CHANGE_WARNING_MESSAGE,
  CANCEL_EDIT_WARNING_DIALOG_MESSAGE,
  NO_BUTTON_LABEL,
  YES_BUTTON_LABEL,
} from './locale';

export const changeHandler: ChangeHandlerType =
  (handleChange, name) => (value: NewInputValue | ITextValue[]) => {
    const target = {
      name,
      value,
    } as ChangeEvent<HTMLInputElement>['target'];
    const event = { target } as ChangeEvent<HTMLInputElement>;
    handleChange(event);
  };

export const handleMultiSelectChange =
  (
    handleChange: ChangeEventHandler<HTMLInputElement>,
    touched: FormikTouched<IUserDimensionDetails>,
    setTouched: FormikHelpers<IUserDimensionDetails>['setTouched'],
    name: string,
  ) =>
  (value: string | ITextValue[]) => {
    setTouched({
      ...touched,
      [name]: true,
    });
    changeHandler(handleChange, name)(value);
  };
export const sourceDimensionChangeHandler =
  (
    modalStateSetter: (state: IModalDialogProps) => void,
    handleChange: ChangeEventHandler<HTMLInputElement>,
    values: IUserDimensionDetails,
  ) =>
  (value: NewInputValue | ITextValue[]) => {
    const prevValue = values.source_dimension;
    changeHandler(handleChange, 'source_dimension')(value);

    if (!isEmpty(values?.custom_values)) {
      modalStateSetter({
        onClose: () => {
          changeHandler(handleChange, 'source_dimension')(prevValue);
          modalStateSetter({ isOpen: false });
        },
        isOpen: true,
        message: SOURCE_DIMENSION_CHANGE_WARNING_MESSAGE,
        onConfirm: () => {
          changeHandler(handleChange, 'custom_values')([]);
          modalStateSetter({ isOpen: false });
        },
        closeLabel: NO_BUTTON_LABEL,
        confirmLabel: YES_BUTTON_LABEL,
      });
      return;
    }
    changeHandler(handleChange, 'source_dimension')(value);
  };

export const emptyCustomValue: () => IUserDimensionValue = () => ({
  id: uuid(),
  mapped_value: '',
  source_values: [],
});
/**
 *
 * @param values
 * @param setModalState
 * @param arrayFieldHelpers
 * @param setActiveTab
 * values
 * setModalState
 * arrayFieldHelpers
 * setActiveTab
 */
export const addCustomValues =
  (
    values: IUserDimensionDetails,
    setModalState: Dispatch<SetStateAction<IModalDialogProps>>,
    arrayFieldHelpers: ArrayHelpers,
    setActiveTab: Dispatch<SetStateAction<number>>,
  ) =>
  () => {
    if (isEmpty(values.source_dimension)) {
      setModalState({
        isOpen: true,
        onClose: () => setModalState({ isOpen: false }),
        message: ADD_CUSTOM_VALUE_ERROR_DIALOG,
        closeLabel: ADD_CUSTOM_VALUE_ERROR_DIALOG_CANCEL_LABEL,
      });
      return;
    }

    arrayFieldHelpers.push(emptyCustomValue());

    setActiveTab(values.custom_values.length);
  };

export const canUserSave = (
  values: IUserDimensionDetails,
  errors: FormikErrors<IUserDimensionDetails>,
  dirty: boolean,
  loading: boolean,
): boolean =>
  !loading && dirty && !isEmpty(values?.custom_values) && isEmpty(errors);

export const showTabWarningSign = (
  errors: FormikErrors<IUserDimensionDetails>,
  touched: FormikTouched<IUserDimensionDetails>,
  index: number,
): boolean =>
  (get(touched, ['custom_values', index, 'mapped_value']) &&
    get(errors, ['custom_values', index, 'mapped_value'])) ||
  (get(touched, ['custom_values', index, 'source_values']) &&
    get(errors, ['custom_values', index, 'source_values']));

export const getPath = (isEdit: boolean, id: string | undefined): string => {
  const basePath = `${Index.SEGMENT_ADMIN}/${Index.SEGMENT_UPLOADS}`;
  return isEdit
    ? `${basePath}/${Actions.SEGMENT_EDIT}/${id}`
    : `${basePath}/${Actions.SEGMENT_CREATE}`;
};

export const postUserDimension = async (
  userDimension: IUserDimensionDetails,
  navigate: NavigateFunction,
  setLoading: Dispatch<SetStateAction<boolean>>,
  fetchDomains: (refreshClients: boolean) => Promise<void>,
): Promise<void> => {
  try {
    const id = get(userDimension, 'id');
    setLoading(true);
    const response = await fetchApi({
      endpoint: `/${Index.SEGMENT_DATASETS}/${Index.SEGMENT_USER_DIMENSION}/${
        id ?? ''
      }`,
      method: id ? FetchMethod.PUT : FetchMethod.POST,
      payload: userDimension,
    });

    if (!response.data || response.error) {
      throw new Error(getOr(SAVE_ERROR_MESSAGE, 'error.message', response));
    }

    fetchDomains(true);

    navigate(`${Index.SEGMENT_ADMIN}/${Index.SEGMENT_UPLOADS}`);
    await delay(10);
    showSuccessToast(SAVE_SUCCESS_MESSAGE);
  } catch (e) {
    showErrorToast(getOr(SAVE_ERROR_MESSAGE, 'message', e));
  }
  setLoading(false);
};

export const handleEditCancel = (
  form: IUserDimensionDetails,
  initialState: IUserDimensionDetails,
  setModalState: Dispatch<SetStateAction<IModalDialogProps>>,
  navigate: NavigateFunction,
): void => {
  if (isEqual(form, initialState)) {
    navigate(`${Index.SEGMENT_ADMIN}/${Index.SEGMENT_UPLOADS}`);
    return;
  }
  setModalState({
    isOpen: true,
    onClose: () => setModalState({ isOpen: false }),
    message: CANCEL_EDIT_WARNING_DIALOG_MESSAGE,
    closeLabel: GO_BACK_BUTTON_LABEL,
    confirmLabel: CONTINUE_BUTTON_LABEL,
    onConfirm: () =>
      navigate(`${Index.SEGMENT_ADMIN}/${Index.SEGMENT_UPLOADS}`),
  });
};
