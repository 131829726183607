import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import React, { MouseEventHandler } from 'react';
import { getClass } from 'helpers/components';

interface IOption {
  text: string;
  handler: () => void;
}

interface IProps {
  options: IOption[];
  label?: string;
}

export const componentName = 'button-dropdown';
const componentClass = getClass(componentName);

const ButtonDropdown: React.FC<IProps> = ({
  options,
  label = 'New',
}): React.ReactElement => (
  <div className={componentClass}>
    <Dropdown as={ButtonGroup}>
      <Dropdown.Toggle
        split
        className="blw-0 br-br-4 br-tr-4"
        id="dropdown-split-basic"
        variant="success"
        style={{ color: 'white' }}
      >
        <span data-testid={componentName}>{label}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options?.map((option, index) => (
          <Dropdown.Item
            as="li"
            onClick={option.handler as unknown as MouseEventHandler}
            key={`${componentName}-option-${option.text}`}
            data-testId={`${componentName}-option-${index}`}
          >
            {option.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

export default ButtonDropdown;
