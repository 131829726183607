import Flex, { Direction, Horizontal, Vertical } from 'components/Flex';
import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';
import RulesBuilderItem from 'components/RulesBuilder/components/RulesBuilderItem';
import { ToggleSwitch } from 'components/ToggleSwitch';
import IDimension from 'domains/dimensions/types';
import IMessage from 'domains/messageList/types';
import React, { FC, useCallback } from 'react';
import { newFilter, RuleFilter } from '../../../../../types/filter';
import {
  LOCALE_COMPARE_NETWORK_ON_VS_OFF_LABEL,
  LOCALE_COMPARE_NETWORK_ON_VS_OFF_LABEL_TOOLTIP,
} from '../locales';

interface ISharedNetworkOwnerRuleProps {
  exposureAdsFilters: RuleFilter[];
  availableDimensions?: IDimension[];
  disabled?: boolean;
  setExposureAdFiltersAction: (ruleFilterList: RuleFilter[]) => void;
  messageList: IMessage[];
  deleteWarningMessage: (message: IMessage) => void;
}

const getNewSharedNetWorkRule = (): RuleFilter => ({
  ...newFilter(),
  field: 'SHARED_NETWORK_OWNER',
  id: 'SHARED_NETWORK_OWNER',
  isRule: true,
  operator: 'IN',
  type: 'SIMPLE',
  value: [],
});

const sharedNetworkOwnerFilter = (filter: RuleFilter): boolean =>
  filter.id === 'SHARED_NETWORK_OWNER';

export const SharedNetworkOwnerRule: FC<ISharedNetworkOwnerRuleProps> = ({
  exposureAdsFilters,
  availableDimensions,
  setExposureAdFiltersAction,
  disabled,
  messageList,
  deleteWarningMessage,
}) => {
  const sharedNetworkOwnerRule = exposureAdsFilters.find(
    sharedNetworkOwnerFilter,
  );
  const index = exposureAdsFilters.findIndex(sharedNetworkOwnerFilter);

  const toogleSwitchChange = useCallback(
    (isSharedNetworkOwnerOn: boolean) => {
      const newRules = isSharedNetworkOwnerOn
        ? [...exposureAdsFilters, getNewSharedNetWorkRule()]
        : exposureAdsFilters.filter(
            (filter) => filter.id !== 'SHARED_NETWORK_OWNER',
          );

      if (sharedNetworkOwnerRule && !isSharedNetworkOwnerOn) {
        const valueIsInError = messageList.filter(
          (m) =>
            m.id === sharedNetworkOwnerRule.filterId && m.field === 'value',
        );
        if (valueIsInError.length)
          deleteWarningMessage({
            id: sharedNetworkOwnerRule.filterId,
            field: 'value',
          });
      }

      setExposureAdFiltersAction(newRules);
    },
    [
      exposureAdsFilters,
      setExposureAdFiltersAction,
      messageList,
      deleteWarningMessage,
    ],
  );

  const handleChange = useCallback(
    (filter: RuleFilter): void => {
      exposureAdsFilters[index] = filter;

      setExposureAdFiltersAction([...exposureAdsFilters]);
    },
    [exposureAdsFilters, setExposureAdFiltersAction, index],
  );

  return (
    <div className="shared-network-owner-rule-component">
      <QueryBuilderItem
        label={LOCALE_COMPARE_NETWORK_ON_VS_OFF_LABEL}
        tooltip={LOCALE_COMPARE_NETWORK_ON_VS_OFF_LABEL_TOOLTIP}
      >
        <Flex
          direction={Direction.row}
          horizontal={Horizontal.left}
          vertical={Vertical.baseline}
        >
          <ToggleSwitch
            name="shared-network-owner-switch"
            checked={Boolean(sharedNetworkOwnerRule)}
            onChange={toogleSwitchChange}
            trackingId="SHARED_NETWORK_OWNER"
            yesLabel="On"
            noLabel="Off"
            disabled={disabled}
          />
          {sharedNetworkOwnerRule && (
            <div className="rules-builder-item-container">
              <RulesBuilderItem
                filter={sharedNetworkOwnerRule}
                index={0}
                dimensions={availableDimensions}
                fixed
                isDeletable={false}
                onChange={handleChange}
                disabled={disabled}
              />
            </div>
          )}
        </Flex>
      </QueryBuilderItem>
    </div>
  );
};
