import IReport, {
  ReportType,
  reportType,
  UNSAVED_REPORT_ID,
} from 'domains/reports/types';
import { isEmpty } from 'lodash';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as reportSelectors from 'store/selectors/report';
import * as userSelectors from 'store/selectors/user';
import IError from 'types/error';
import State from 'types/state';
import { processRules, setReportName } from '..';
import { fetchTargetsAction } from '../../businessData';
import {
  setAttributionReportList,
  setReportList,
  setTargetList,
} from '../../root';
import { handleCreateReport, handleUpdateReport } from './fetchers';
import { IApiResponse, IGetUpdatersReturn, StoreListUpdaterType } from './type';

export type ApiUpdaterType = (payload: IReport) => Promise<IApiResponse>;

/**
 * get the right for each report type
 * as well for creating or updating report
 */
const getFunctions = (
  report: Pick<IReport, 'id' | 'type'>,
): IGetUpdatersReturn => {
  const { id, type } = report;

  const listActionUpdater: { [x: string]: StoreListUpdaterType } = {
    [reportType.report]: setReportList,
    [reportType.target]: setTargetList,
    [reportType.attributionReport]: setAttributionReportList,
  };

  const storeListUpdater = listActionUpdater[type as ReportType];
  const apiFetcher =
    id === UNSAVED_REPORT_ID ? handleCreateReport : handleUpdateReport;

  return {
    apiUpdater: apiFetcher,
    storeListUpdater,
  };
};

export const handleUpdateName =
  (name: string) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<IReport | undefined> => {
    const state = getState();

    const oldReport = reportSelectors.getReport(state);
    const clientId = userSelectors.getSelectedClientExtId(state);
    const oldName = reportSelectors.getReportName(oldReport);

    if (`${name}`.trim() === `${oldName}`.trim() || isEmpty(name)) {
      return;
    }

    const { id, type } = reportSelectors.getReport(state) as IReport;
    const userId = userSelectors.getId(state);
    const { apiUpdater, storeListUpdater } = getFunctions({
      id,
      type,
    });

    dispatch(
      processRules({
        isBaseReportSaving: true,
      }),
    );

    let report: IReport | undefined;
    let reportList: IReport[];
    let error = null;

    let payload: Partial<IReport> =
      id === UNSAVED_REPORT_ID
        ? { ...oldReport, name }
        : {
            name,
            updatedBy: userId,
            id,
          };

    try {
      const response: IApiResponse = await apiUpdater(payload as IReport);
      ({ report, reportList, error } = response);

      const newReport = reportList?.find(
        (reportList) => (report as IReport)?.id === reportList?.id,
      );

      if (newReport) {
        dispatch(setReportName(name));
        dispatch(processRules({}));
      }

      dispatch(storeListUpdater(reportList));

      if (type === reportType.target) dispatch(fetchTargetsAction(clientId));
    } catch (e) {
      error = e as IError;
    } finally {
      dispatch(processRules({ isBaseReportSaving: false }));
      if (error) throw error;
    }
    if (report) return report as IReport;
  };
