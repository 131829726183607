import { includes } from 'lodash/fp';

export const decodeBreakout = (breakout: string, name: string): string => {
  const lName = name.toLocaleLowerCase();

  if (includes('full campaign', lName)) {
    return 'total';
  }

  if (breakout !== 'NETWORK') return breakout;

  return includes('off network', lName) || includes('on network', lName)
    ? 'NET_ON_OFF'
    : breakout;
};
