import H3 from 'components/H3';
import { Type } from 'components/Modal';
import { getTestId, getClass } from 'helpers/components';
import React, { ReactNode, ReactElement } from 'react';

export const modalContentComponentName = 'modal-content';

type Props = {
  children: ReactNode;
  header?: string;
  footer?: ReactNode;
  testId?: string;
  showSeparators?: boolean;
  type?: Type;
};

const ModalContent = (props: Props): ReactElement => {
  const {
    header,
    footer,
    children,
    type = Type.fullScreen,
    testId,
    showSeparators = true,
  } = props;

  const modalTestId = getTestId(modalContentComponentName, testId);
  const containerClasses = getClass(modalContentComponentName, {
    boolean: [{ state: showSeparators, class: 'separators' }],
    add: [type],
  });

  const sectionTestId = getTestId(modalContentComponentName, 'section');
  const footerTestId = getTestId(modalContentComponentName, 'footer');
  const headerTestId = getTestId(modalContentComponentName, 'header');

  return (
    <div data-testid={modalTestId} className={containerClasses}>
      {header && (
        <header data-testid={headerTestId}>
          <H3>{header}</H3>
        </header>
      )}
      <section data-testid={sectionTestId}>{children}</section>
      {footer && <footer data-testid={footerTestId}>{footer}</footer>}
    </div>
  );
};

export default ModalContent;
