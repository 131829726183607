import {
  ResultBaseSubStructure,
  ResultEnhancedDemographicGroups,
} from 'domains/reports/types';
import { forEach, get } from 'lodash';
import { ITransformerInputFilters, TSegmentedData } from '..';
import { createPointName } from './createPointName';
import { updateSegmentData } from './updateSegmentData';

/**
 * Converts SegmentsDataSet data with filtered breakouts to the format of object with keys corresponding to segments names.
 * Segment value is an array of chart points, with shape: {value, color}, where value is POPULATION_INDEX.VALUE.
 * Takes multiple targets and uses targets color for points.
 *
 * @param data
 * filtered chart data from SegmentsDataSet object, should contain only the filtered segments
 * @param filters
 * provide the DEMOGRAPHIC metric, and segments
 * @returns
 * {
 *   'key1': [
 *     {
 *       value: [number],
 *       color: [string]
 *     }
 *   ],
 *   'key2': [
 *     {
 *       value: [number],
 *       color: [string]
 *     }
 *   ]
 * }
 */

export const getSegmentedDataByTargets = (
  data: ResultEnhancedDemographicGroups,
  filters: ITransformerInputFilters,
): TSegmentedData => {
  let segmentedData: TSegmentedData = {};

  const { demographic, segments, targets } = filters;

  forEach(targets, (target) => {
    forEach(segments, (segment) => {
      const targetValue = target.value as string;
      const sData = get(data, segment.value);
      const targetData = get(sData, demographic);
      const demographicData: ResultBaseSubStructure[] = get(
        targetData,
        targetValue,
      );
      const color = target.color ?? '';

      const name = createPointName(target.text, segment?.text ?? '');
      segmentedData = updateSegmentData(
        segmentedData,
        demographicData,
        color,
        name,
      );
    });
  });

  return segmentedData;
};
