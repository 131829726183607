import { Placement } from 'components/Sticker';
import { getClass, getTestId } from 'helpers/components';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import React, { FunctionComponent, ReactElement, useRef } from 'react';
import ITextValue, { ITextValueGroup } from 'types/textValue';
import { isArrayOfStrings } from '../../helpers/types';
import {
  getOptionsObject,
  setDefaultExpandedForArrayOfGroups,
} from './helpers';
import OptionsTreeGroup from './OptionsTreeGroup';
import OptionsTreeItem from './OptionsTreeItem';

export const optionsTreeComponentName = 'options-tree';

interface IOptionsTreeProps {
  allGroupsExpanded?: boolean;
  expandedGroups?: string[];
  ignoreGrouping?: boolean;
  onClick: (e: string) => void;
  onClickOutside?: () => void;
  options: string[] | ITextValue[];
  selectedOptions?: string[] | ITextValue[];
  testId?: string;
  value?: string;
  highlighted?: string;
}

export const isSelectedOption = (
  selectedOptions: ITextValue[] | string[] | undefined,
  currentOptionValue: string | ITextValue,
): boolean => {
  if (!selectedOptions) return false;

  if (isArrayOfStrings(selectedOptions)) {
    const found = (selectedOptions as string[]).find(
      (option: string) => option.toString() === currentOptionValue.toString(),
    );
    return !!found;
  }

  const found = (selectedOptions as ITextValue[]).find(
    (option: ITextValue) =>
      option.value?.toString() ===
      (currentOptionValue as ITextValue).value.toString(),
  );

  return !!found;
};

const OptionsTree: FunctionComponent<IOptionsTreeProps> = (
  props: IOptionsTreeProps,
): ReactElement => {
  const {
    expandedGroups,
    ignoreGrouping,
    onClick,
    onClickOutside,
    options,
    selectedOptions,
    value,
    testId,
    allGroupsExpanded = false,
    highlighted = '',
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  useOnOutsideClick(ref, () => {
    if (onClickOutside) onClickOutside();
  });

  const optionsTreeClass = getClass(optionsTreeComponentName, {
    add: [Placement.bottomEnd],
  });
  const optionsTreeTestId = getTestId(optionsTreeComponentName, testId);

  const optionsTree = getOptionsObject(options, ignoreGrouping);

  const optionsTreeWithDefaultExpanded = setDefaultExpandedForArrayOfGroups(
    optionsTree,
    expandedGroups,
  );

  return (
    <div className={optionsTreeClass} data-testid={optionsTreeTestId} ref={ref}>
      {optionsTreeWithDefaultExpanded.map(
        (option: ITextValueGroup | ITextValue) => {
          if (option.group) {
            const { expanded } = option as ITextValueGroup;
            return (
              <OptionsTreeGroup
                expanded={allGroupsExpanded || expanded}
                allGroupsExpanded={allGroupsExpanded}
                group={option as ITextValueGroup}
                key={`${option.group}`}
                onClick={onClick}
                selectedOptions={selectedOptions}
                highlighted={highlighted}
              />
            );
          }
          return (
            <OptionsTreeItem
              key={`${(option as ITextValue).value}-${value}`}
              isSelected={
                selectedOptions
                  ? isSelectedOption(selectedOptions, option as ITextValue)
                  : (option as ITextValue).value === value
              }
              item={option as ITextValue}
              onClick={onClick}
            />
          );
        },
      )}
    </div>
  );
};

export default OptionsTree;
