import { get, flow } from 'lodash/fp';

export type State = {
  selectedMetric: string;
};

export const getTargetData = get('targetResult.targetData');

const getTarget = get('target');
const getGenPop = get('genpop');
const getUniverse = get('universe[0].universe');
const getGenPopUniverse = flow(getTargetData, getGenPop, getUniverse);

export const getUniverseLabel = flow(getTargetData, get('universeLabel'));
export const getTargetUniverse = flow(getTargetData, getTarget, getUniverse);
export const getTargetPercentage = (state: State): number => {
  const targetUniverse: number = getTargetUniverse(state);
  const genPopUniverse: number = getGenPopUniverse(state);
  return +((targetUniverse / genPopUniverse) * 100).toFixed(1);
};
export const getExportConfigOptions = get('targetResult.exportConfigOptions');
