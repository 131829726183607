import { StickyColumnConfig } from 'components/Table';
import { TableRowConfig } from 'components/Table/components/PivotTable/adapters/rawPivot';
import { IHeaderColumn } from 'components/Table/ThRow';
import { Metric } from 'domains/metrics/types';
import { getFilledArrayElements } from 'domains/reports/adapters/results';
import { IChartData, DataRowGraph } from 'domains/reports/types';
import {
  difference,
  flatten,
  flow,
  get,
  tail,
  isNil,
  includes,
} from 'lodash/fp';

export type Header = {
  Header?: string;
  accessor?: string;
};

export type IColumn = {
  colspan?: number;
  label?: string;
};

export interface IUtilReturn {
  unlinkedHeaders?: IColumn[][] | undefined;
  headers: StickyColumnConfig[];
  data: TableRowConfig[];
}

export interface ITableConfig {
  unlinkedHeaders?: IHeaderColumn[][] | undefined;
  headers: StickyColumnConfig[];
  data: DataRowGraph[];
}

export const getRows = get('spatialDimensions');

export const getCols = (tableData: IChartData): string[] =>
  difference(get('dimensions', tableData), get('spatialDimensions', tableData));

export const getValues = (tableData: IChartData): Metric[] =>
  get('select', tableData);

export const getLastHeader = flow(get('headers'), tail, flatten);

export const hasMetaTarget = (chartData: IChartData): boolean => {
  const targetMeta = get('metadata', chartData).find((e) => e.id === 'TARGET');
  return !isNil(targetMeta) && !includes('TARGET', chartData.dimensions);
};

export const getAvailablePairs = (
  selectedMetrics: string[],
  selectedCharts: string[],
): string[][] => {
  const availableMetrics = getFilledArrayElements(selectedMetrics);
  const availableCharts = selectedCharts
    .filter((chart: string, index: number) => selectedMetrics[index])
    .filter((chart: string) => chart);

  const availablePairs = availableCharts.map(
    (chart: string, index: number) => ({
      chart,
      metric: availableMetrics[index],
    }),
  );
  return [
    availablePairs.map((x) => x.chart),
    availablePairs.map((x) => x.metric),
  ];
};

export const getTargetList = get('targets');
