import {
  ResultEnhancedDataTarget,
  ResultBaseSubStructure,
  ResultBaseTotalDataTarget,
} from 'domains/reports/types';
import { IState } from 'helpers/charts/chartWithTable/types';

export const mergeFullCampaignRow = (
  breakoutData: ResultBaseTotalDataTarget,
  state: IState,
): ResultBaseTotalDataTarget => {
  const { data } = state;
  const { exposed_all } = data;
  const getFullCampaign = (
    target: keyof ResultEnhancedDataTarget,
  ): ResultBaseSubStructure | null => {
    const exposed_all_total = exposed_all?.total?.[
      target
    ] as ResultBaseSubStructure[];
    return exposed_all_total
      ? {
          ...exposed_all_total?.[0],
          name: {
            VALUE: 'FULL_CAMPAIGN',
            DISPLAY_VALUE: 'Full Campaign',
          },
        }
      : null;
  };

  const targetFullCampaign = getFullCampaign('target');
  const genpopFullCampaign = getFullCampaign('genpop');

  return {
    target: targetFullCampaign
      ? [
          { ...targetFullCampaign },
          ...((breakoutData.target as ResultBaseSubStructure[]) ?? []),
        ]
      : breakoutData.target,
    genpop: genpopFullCampaign
      ? [
          { ...genpopFullCampaign },
          ...((breakoutData.genpop as ResultBaseSubStructure[]) ?? []),
        ]
      : breakoutData.genpop,
  };
};
