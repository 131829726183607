import { TableEvent } from 'components/ReportEventsTable';
import { format } from 'date-fns';
import IEvent from 'domains/events/types';
import { getByIdFromCollection } from 'helpers/types';
import IUser from 'types/user';
import { get, isEmpty } from 'lodash';

const LOCALE_DATE_TIME_FORMAT = 'MMM d, yyyy h:mm:ss b';

const getDate = (dateTime?: string): string =>
  format(dateTime ? new Date(dateTime) : new Date(), LOCALE_DATE_TIME_FORMAT);

const getUserNameById = (
  userId: string,
  users?: IUser[],
): string | undefined => {
  const user = getByIdFromCollection<IUser>(userId, users ?? []);
  return user?.name;
};

const getAction = (action: string): string => {
  const capitalizeFirstLetter = (actionUppercase: string): string =>
    actionUppercase.charAt(0).toUpperCase() + actionUppercase.slice(1);

  const actionLowerCase = action.toLocaleLowerCase();
  return capitalizeFirstLetter(actionLowerCase);
};

const getReport = (event: IEvent): string => {
  const report = get(event, 'report');
  return JSON.stringify(isEmpty(report) ? 'Missing report' : report, null, 2);
};

const getTableEvents = (events?: IEvent[], users?: IUser[]): TableEvent[] => {
  if (!events) return [] as TableEvent[];

  const tableEvents = events?.map((event: IEvent) => {
    const dateTime = getDate(event?.createdAt);
    const userName = getUserNameById(event.userId, users);
    const actionType = getAction(event?.actionType);
    const report = getReport(event);
    return {
      dateTime,
      userName,
      actionType,
      report,
    };
  });

  if (!tableEvents) return [] as TableEvent[];

  return tableEvents;
};

export default getTableEvents;
