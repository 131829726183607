import ActionsBar from 'components/ActionsBar';
import H3 from 'components/H3';
import Modal, { Type } from 'components/Modal';
import { uniqueId } from 'lodash';
import React from 'react';
import {
  savingStatus,
  SegmentsSavingStatus,
} from 'types/attributionReportsResult';

type OwnProps = {
  saveSegmentsStatus: SegmentsSavingStatus;
  onClose: () => void;
  filenames: string[];
};

const SaveSegmentsSuccessModal = ({
  saveSegmentsStatus,
  onClose,
  filenames = [],
}: OwnProps): React.ReactElement => (
  <Modal
    type={Type.auto}
    isOpen={saveSegmentsStatus === savingStatus.success}
    onCloseClick={onClose}
  >
    <div>
      <header>
        <H3>Segments Saved</H3>
      </header>
      <section>
        Your segments have successfully been uploaded and will be processed in
        approximately one hour. The segment csv{' '}
        {filenames.length > 1 ? 'files are' : 'file is'}:
        <ul>
          {filenames.map((item) => (
            <li key={uniqueId()}>{item}</li>
          ))}
        </ul>
      </section>
      <ActionsBar cancel={false} okLabel="Ok" onOK={onClose} />
    </div>
  </Modal>
);
export default SaveSegmentsSuccessModal;
