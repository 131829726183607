import Button, { Kind } from 'components/Button';
import Flex, { Horizontal } from 'components/Flex';
import { headerComponentName } from 'components/Header';
import Icon, { Type, Size, Color } from 'components/Icon';
import PopoverMenu, { IMenuOption } from 'components/PopoverMenu';
import { getClass, getTestId } from 'helpers/components';
import { sortUserClients } from 'helpers/utils';
import partial from 'lodash/partial';
import React, { ReactElement } from 'react';
import { IClient } from '../../domains/clients/types';

export const clientSelectorComponentName = 'client-selector-menu';

type Props = {
  clients: IClient[];
  selectedClient: IClient | undefined;
  onSelectClient: (client: IClient) => void;
  testId?: string;
  userClients?: string[];
};

const LOCALE_SELECT_A_CLIENT = 'Select a client';

const ClientSelector = (props: Props): ReactElement => {
  const { clients, userClients, onSelectClient, testId, selectedClient } =
    props;

  const clientSelectorTestId = getTestId(clientSelectorComponentName, testId);
  const clientSelectorClassName = getClass(clientSelectorComponentName);

  const availableClients = sortUserClients([
    ...clients.filter((client: IClient) =>
      userClients?.includes(client.id ?? ''),
    ),
  ]);

  const menuOptions = availableClients.map(
    (client: IClient): IMenuOption => ({
      key: client?.id ?? '',
      option: (
        <div>
          <Button onClick={partial(onSelectClient, client)} kind={Kind.link}>
            <Flex horizontal={Horizontal.left}>
              <Icon type={Type.database} size={Size.small} color={Color.dark} />
              <span>{client.name}</span>
            </Flex>
          </Button>
        </div>
      ),
    }),
  );

  return (
    <div data-testid={clientSelectorTestId} className={clientSelectorClassName}>
      <PopoverMenu
        customStickerWrapperClass={headerComponentName}
        options={menuOptions}
      >
        {selectedClient?.name ?? LOCALE_SELECT_A_CLIENT}
      </PopoverMenu>
    </div>
  );
};

export default ClientSelector;
