import useSelectedClient from 'domains/clients/useSelectedClient';
import IReport from 'domains/reports/types';
import useReports from 'hooks/useReports';
import useToast from 'hooks/useToast';
import { isNull } from 'lodash/fp';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as rootActions from 'store/actions/root';
import * as rootSelectors from 'store/selectors/root';
import * as userSelectors from 'store/selectors/user';
import { Action } from 'types/action';
import IError from 'types/error';
import State from 'types/state';
import TargetsListFeature from '../../list.feature';

interface IProps {
  clientExtID: string;
  targetList: IReport[];
  setTargetList: (arg0: IReport[] | null) => Action<IReport[] | null>;
  testId?: string;
  path: string;
}

const LOCALE_TARGET_LIST_REPORTS = 'Advanced Audiences';

const TargetsListContainer = (props: IProps): ReactElement => {
  const { clientExtID, targetList, setTargetList, testId, path } = props;
  const [loaded, setLoaded] = useState(false);
  const {
    selectedClient: { extID },
  } = useSelectedClient();
  const { doErrorToast } = useToast();
  const { fetchTargets } = useReports();

  useEffect(() => {
    if ((isNull(targetList) && loaded) || !loaded) {
      setLoaded(true);
      fetchTargets({
        clientExtID,
        onSuccess: (res: IReport[]) => {
          setTargetList(res);
        },
        onError: (error: IError) => {
          setTargetList(null);
          doErrorToast(error?.message ?? 'There has been an error.');
        },
      });
    }
  }, [
    clientExtID,
    doErrorToast,
    extID,
    fetchTargets,
    loaded,
    setTargetList,
    targetList,
  ]);

  return (
    <TargetsListFeature
      title={LOCALE_TARGET_LIST_REPORTS}
      testId={testId}
      loading={isNull(targetList) || !loaded}
      path={path}
    />
  );
};

const mapStateToProps = (
  state: State,
): Pick<IProps, 'clientExtID' | 'targetList'> => ({
  clientExtID: userSelectors.getSelectedClientExtId(state),
  targetList: rootSelectors.getTargetList(state),
});

const mapDispatchToProps = {
  setTargetList: rootActions.setTargetList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TargetsListContainer);
