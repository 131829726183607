import {
  BreakoutItem,
  ChartDataSet,
  DataRowGraphValue,
  IKeyString,
  PerformanceGroupKeys,
  PerformanceGroupNames,
  PerformanceMetricsTop25,
  ResultBase,
  ResultBaseSampleGroups,
  ResultBaseSubStructure,
  ResultBaseTotalDataTarget,
  ResultBaseTotalStructure,
  ResultTop25,
  SampleGroupNames,
  Top25SubStructure,
} from '../../domains/reports/types';

import ITextValue, { IOption } from '../../types/textValue';
import {
  camelCase,
  cloneDeep,
  get,
  isEmpty,
  set,
  startCase,
  uniq,
} from 'lodash';

interface IExtractedInformation {
  convertersContent: string;
  convertersSubContent: string;
  convertersFooter: string;
  convertersCostContent: string;
  convertersCostFooter: string;
  conversionsContent: string;
  conversionsSubContent: string;
  conversionsFooter: string;
  totalSalesContent: string;
  totalSalesSubContent: string;
  totalSalesFooter: string;
  salesPerTransactionContent: string;
  salesPerTransactionSubContent: string;
  salesPerTransactionFooter: string;
}

interface IDeliveryAndOutcomesParams {
  SPOT_COUNT: DataRowGraphValue | undefined;
  AD_COST: DataRowGraphValue | undefined;
  REACH: DataRowGraphValue | undefined;
  REACH_PC: DataRowGraphValue | undefined;
  IMPRESSIONS: DataRowGraphValue | undefined;
  FREQUENCY: DataRowGraphValue | undefined;
  CONVERTED_REACH: DataRowGraphValue | undefined;
  INCREMENTAL_LIFT: DataRowGraphValue | undefined;
  DAILY_CONVERSIONS: DataRowGraphValue | undefined;
  CONVERSIONS_INCREMENTAL_LIFT: DataRowGraphValue | undefined;
  TOTAL_SALES: DataRowGraphValue | undefined;
  TOTAL_SALES_INCREMENTAL_LIFT: DataRowGraphValue | undefined;
  percentageOfConverters: number;
  percentageOfConversions: number;
  percentageOfTotalSales: number;
  adToConversationLatencyDays: string;
  [key: string]: string | number | undefined | DataRowGraphValue;
}

export type FormatType = 'number' | 'percent' | 'multiple' | 'money';

const FormatTypeMap = {
  number: '',
  percent: '%',
  multiple: 'X',
  money: '$',
};

export const getPercentile = (
  data: ResultBaseSampleGroups | undefined,
  percentileKey: string,
  targetOption: ITextValue | string,
): DataRowGraphValue & { percentileIndex: number } => {
  let percentileIndex = 0;
  const target = (
    typeof targetOption === 'string' ? targetOption : targetOption?.value
  ) as SampleGroupNames;
  const sampleGroupsData = data?.[target];
  const percentileItem = sampleGroupsData?.find(
    (item: ResultBaseSubStructure, i: number): boolean => {
      if ((item?.campaign?.[percentileKey]?.VALUE as number) >= 0) {
        percentileIndex = i;
        return true;
      }
      percentileIndex = -1;
      return false;
    },
  )?.campaign?.[percentileKey];
  // NOTE: percentileIndex shows where on xAxis should the plot line be rendered
  return {
    ...(percentileItem ?? {
      VALUE: 0,
      DISPLAY_VALUE: 'N/A',
    }),
    percentileIndex,
  };
};

export const getDeliveryAndOutcomesParams = (
  miscResultData: ResultBase,
  selectedTarget: ITextValue,
): IDeliveryAndOutcomesParams => {
  const { converted_total, exposed_all, exposed_converted } = miscResultData;
  const exposedAllTotal = exposed_all?.total;
  const { value: target } = selectedTarget ?? {};
  const exposedData = exposedAllTotal?.[
    target as SampleGroupNames
  ] as ResultBaseSubStructure[];

  const convertedData = converted_total?.[
    target as SampleGroupNames
  ] as ResultBaseSubStructure[];

  const { SPOT_COUNT, REACH, REACH_PC, IMPRESSIONS, FREQUENCY, AD_COST } =
    (exposedData?.[0]?.campaign as ChartDataSet) ?? ({} as DataRowGraphValue);

  const { INCREMENTAL_LIFT } =
    (exposedData?.[0]?.converters as ChartDataSet) ?? {};

  const { INCREMENTAL_LIFT: CONVERSIONS_INCREMENTAL_LIFT } =
    (exposedData?.[0]?.conversions as ChartDataSet) ?? {};

  const { INCREMENTAL_LIFT: TOTAL_SALES_INCREMENTAL_LIFT } =
    (exposedData?.[0]?.total_sales as ChartDataSet) ?? {};

  const {
    REACH: CONVERTED_REACH,
    DAILY_CONVERSIONS,
    TOTAL_SALES,
  } = convertedData?.[0]?.campaign ?? {};

  const percentageOfConverters =
    (Number(INCREMENTAL_LIFT?.VALUE) / Number(CONVERTED_REACH?.VALUE)) * 100;

  const percentageOfConversions =
    (Number(CONVERSIONS_INCREMENTAL_LIFT?.VALUE) /
      Number(DAILY_CONVERSIONS?.VALUE)) *
    100;

  const percentageOfTotalSales =
    (Number(TOTAL_SALES_INCREMENTAL_LIFT?.VALUE) / Number(TOTAL_SALES?.VALUE)) *
    100;

  const adToConversationLatencyPercentile = getPercentile(
    exposed_converted?.AD_LATENCY,
    'AD_LATENCY_50TH_PERCENTILE',
    selectedTarget,
  );

  const adToConversionLatencyPercentileIndex =
    adToConversationLatencyPercentile?.percentileIndex;
  const adToConversationLatencyValue =
    adToConversionLatencyPercentileIndex === -1
      ? '25+'
      : adToConversationLatencyPercentile?.VALUE;

  const adToConversationLatencyDays =
    adToConversationLatencyValue +
    (adToConversationLatencyValue === 1 ? ' Day' : ' Days');

  return {
    SPOT_COUNT,
    AD_COST,
    REACH,
    REACH_PC,
    IMPRESSIONS,
    FREQUENCY,
    CONVERTED_REACH,
    INCREMENTAL_LIFT,
    DAILY_CONVERSIONS,
    CONVERSIONS_INCREMENTAL_LIFT,
    TOTAL_SALES,
    TOTAL_SALES_INCREMENTAL_LIFT,
    percentageOfConverters,
    percentageOfConversions,
    percentageOfTotalSales,
    adToConversationLatencyDays,
  };
};

// native Intl.NumberFormat method was not working when imported on server side,
// probably due to typescript version diff.
export const customIntlNumberFormat = (
  n: number,
  fractionDigits: number,
  hideSuffix = false,
): string => {
  if (hideSuffix) return n.toFixed(fractionDigits);
  const suffix = ['', 'k', 'M', 'B', 'T'];
  const divider = [1, 1e3, 1e6, 1e9, 1e12];
  const absNumber = Math.abs(n);
  let resultSuffixIndex = 0;
  let resultDividerIndex = 0;
  if (absNumber >= 1e12) {
    resultSuffixIndex = 4;
    resultDividerIndex = 4;
  } else if (absNumber >= 1e9) {
    resultSuffixIndex = 3;
    resultDividerIndex = 3;
  } else if (absNumber >= 1e6) {
    resultSuffixIndex = 2;
    resultDividerIndex = 2;
  } else if (absNumber >= 1e3) {
    resultSuffixIndex = 1;
    resultDividerIndex = 1;
  }
  const result = (n / divider[resultDividerIndex]).toFixed(fractionDigits);
  return Number(result) < 1000 || resultSuffixIndex === 4
    ? `${result}${suffix[resultSuffixIndex]}`
    : `1.00${suffix[resultSuffixIndex + 1]}`;
};

/**
 * Formats passed value to a rounded string with k, M, B, T suffixes. If specified, adds % (percent) and X (multiple) to formatted value.
 */
export const numberFormatter = (
  value: number | null | undefined | string | boolean,
  format: FormatType = 'number',
  fractionDigits: number = 2,
  renderSign: boolean = false,
): string => {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  const numberValue = Number(value);

  if (Number.isNaN(numberValue)) {
    return 'N/A';
  }

  const hideSuffix = numberValue >= 1000 && format === 'percent';
  const formattedValue = customIntlNumberFormat(
    numberValue,
    fractionDigits,
    hideSuffix,
  ).replace('-', '');

  let sign = '';

  if (renderSign && numberValue > 0) {
    sign = '+';
  } else if (numberValue < 0) {
    sign = '-';
  }

  const prefix = format === 'money' ? FormatTypeMap[format] : '';
  const suffix = format !== 'money' ? FormatTypeMap[format] : '';

  return `${sign}${prefix}${formattedValue}${suffix}`;
};

export const generateTotalCampaignCardContent = (
  exposedAll: ResultBaseTotalStructure,
  target: string,
  targetName: string,
): IExtractedInformation => {
  const extractedInformation = {};
  Object.keys(PerformanceGroupKeys).forEach((key) => {
    const {
      INCREMENTAL_LIFT: incrementalLift,
      LIFT: lift,
      COST_PER_INCREMENTAL_LIFT: costPerIncrementalLift,
    } = get(exposedAll, `total.${target}.0.${key}`, {}) as ChartDataSet;
    const {
      INCREMENTAL_LIFT: footerIncrementalLift,
      LIFT: footerLift,
      COST_PER_INCREMENTAL_LIFT: footerCostPerIncrementalLift,
    } = get(
      exposedAll,
      `total.${
        target === SampleGroupNames.genpop
          ? SampleGroupNames.target
          : SampleGroupNames.genpop
      }.0.${key}`,
      {},
    ) as ChartDataSet;
    const isSalesPerformance =
      key === PerformanceGroupKeys.total_sales ||
      key === PerformanceGroupKeys.sales_per_transaction;
    const content = `${numberFormatter(
      incrementalLift?.VALUE,
      isSalesPerformance ? 'money' : 'number',
    )}`;
    const adCostContent = numberFormatter(
      costPerIncrementalLift?.VALUE,
      'money',
    );

    const subContent = `${numberFormatter(
      lift?.VALUE,
      'percent',
      2,
      true,
    )} Lift`;
    const footerLabel = target === 'genpop' ? targetName : 'Total US HHs';
    const footer =
      footerIncrementalLift && footerLift
        ? `${footerLabel}: ${numberFormatter(
            footerIncrementalLift?.VALUE,
            isSalesPerformance ? 'money' : 'number',
          )}, ${numberFormatter(footerLift?.VALUE, 'percent', 2, true)} Lift`
        : '';
    const adCostFooter = footerCostPerIncrementalLift
      ? `${footerLabel}: ${numberFormatter(
          footerCostPerIncrementalLift?.VALUE,
          'money',
        )}`
      : '';
    set(extractedInformation, `${camelCase(key)}Content`, content);
    set(extractedInformation, `${camelCase(key)}SubContent`, subContent);
    set(extractedInformation, `${camelCase(key)}Footer`, footer);

    // cost is available for converters performance metric only
    if (key === PerformanceGroupKeys.converters) {
      set(extractedInformation, `${camelCase(key)}CostContent`, adCostContent);
      set(extractedInformation, `${camelCase(key)}CostFooter`, adCostFooter);
    }
  });
  return extractedInformation as IExtractedInformation;
};

export const getTop4BreakoutsByTarget = (
  breakoutChartDataWithTop25: ResultBase,
  performanceMetric: PerformanceGroupNames,
  target: string | number,
): ResultBaseSubStructure[] =>
  (
    get(
      breakoutChartDataWithTop25,
      `exposed_all.TOP_25.${performanceMetric}.${target}`,
      [],
    ) as ResultBaseSubStructure[]
  )?.slice(0, 4) ?? [];

export const getTop4PersuableAudiencesCardsContent = (
  card: ResultBaseSubStructure,
  performanceMetric: PerformanceGroupNames,
  conversionsMetricName: string,
  selectedTargetName: string,
): IKeyString => {
  const isSales =
    performanceMetric === 'sales_per_transaction' ||
    performanceMetric === 'total_sales';
  const incrementalConverter = numberFormatter(
    card?.[performanceMetric]?.INCREMENTAL_LIFT?.VALUE,
    isSales ? 'money' : 'number',
  );
  const persuasionPath = card?.[performanceMetric]?.PERSUASION_INDEX_BY_COST
    ?.VALUE
    ? `${performanceMetric}.PERSUASION_INDEX_BY_COST.VALUE`
    : `${performanceMetric}.PERSUASION_INDEX.VALUE`;
  const persuasionIndex = Number(get(card, persuasionPath)) / 100;
  const title = (card?.name?.DISPLAY_VALUE ?? '').toString();
  const persuasiveComparisonText =
    selectedTargetName === 'genpop' ? 'Total Campaign' : 'Total';

  let persuasionInfo = '';
  let persuasionValue = '';
  if (persuasionIndex > 1) {
    persuasionValue = numberFormatter(persuasionIndex);
    persuasionInfo = `times more persuasive than ${persuasiveComparisonText}`;
  } else if (persuasionIndex < 1) {
    persuasionValue = numberFormatter(1 - persuasionIndex);
    persuasionInfo = `times less persuasive than ${persuasiveComparisonText}`;
  } else {
    persuasionInfo = `just as persuasive as ${persuasiveComparisonText}`;
  }

  const performanceMetricFriendlyString = startCase(
    performanceMetric === PerformanceGroupKeys.conversions
      ? conversionsMetricName ?? ''
      : performanceMetric,
  ).toLowerCase();
  return {
    title,
    persuasionValue,
    persuasionInfo,
    incrementalConverter,
    performanceMetricFriendlyString,
  };
};

export const reducePerformanceMetrics = (pmList: string[]): string[] => {
  if (isEmpty(pmList)) {
    return ['converters'];
  }
  return uniq(
    pmList?.reduce(
      (acc, pm) =>
        pm === 'sales'
          ? [...acc, 'total_sales', 'sales_per_transaction']
          : [...acc, pm],
      ['converters'],
    ),
  );
};

export const generateChartBreakoutWT25 = (
  reportData: ResultBase,
  top_25_breakouts: ResultBaseTotalStructure,
  resultMisc: ResultBase,
): ResultBase =>
  ({
    ...reportData,
    exposed_all: {
      total: resultMisc?.exposed_all?.total ?? {},
      ...reportData?.exposed_all,
      ...top_25_breakouts,
    },
  }) as ResultBase;

export const generateTop25 = (
  resultMisc: ResultBase,
  targets: IOption[],
  breakouts: BreakoutItem[],
  performanceGroups: PerformanceGroupNames[],
): ResultBaseTotalStructure => {
  const BASE_SAMPLE_GROUPS: ResultTop25 = {
    [SampleGroupNames.target]: [],
    [SampleGroupNames.genpop]: [],
  };

  let TOP_25: PerformanceMetricsTop25 = {
    converters: BASE_SAMPLE_GROUPS,
  };

  performanceGroups.forEach((performanceGroup) => {
    TOP_25[performanceGroup] = BASE_SAMPLE_GROUPS;
    for (const target of targets) {
      const top25Data = resultMisc?.top_25_breakouts as PerformanceMetricsTop25;
      const targetValue = target.value as SampleGroupNames;
      const performanceData = top25Data?.[performanceGroup];
      const targetData =
        performanceData?.[targetValue]?.map((item: Top25SubStructure) => {
          const { VALUE: breakoutId } = item?.BREAKOUT;

          //Find friendly display name from breakouts
          const breakoutName = breakouts.find(
            ({ id }: BreakoutItem) => id === breakoutId,
          )?.name;

          //To display 'Network: AMC' or 'Series: 48 Hours on ID'
          const updatedValue = `${breakoutName}: ${item.name?.DISPLAY_VALUE}`;

          return {
            ...item,
            name: {
              VALUE: breakoutId?.toString() ?? '',
              DISPLAY_VALUE: updatedValue,
            },
          };
        }) ?? [];

      TOP_25 = set(
        cloneDeep(TOP_25),
        `${performanceGroup}.${targetValue}`,
        targetData ?? [],
      );
    }
  });

  return {
    TOP_25,
  } as ResultBaseTotalStructure;
};

export const isCostDataAvailable = (resultMisc: ResultBase): boolean => {
  const exposedAllGenpopData = get(
    resultMisc,
    'exposed_all.total.genpop',
    [],
  ) as ResultBaseTotalDataTarget[];
  const hasCostData = exposedAllGenpopData.some((item) =>
    get(item, 'converters.COST_PER_INCREMENTAL_LIFT.VALUE', false),
  );
  return hasCostData;
};
