import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';

export const skeletonComponentName = 'skeleton';

export enum Direction {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export enum Size {
  cover = 'cover',
  large = 'large',
  medium = 'medium',
  small = 'small',
  full = 'full',
}

export interface ISkeletonProps {
  size?: Size;
  length?: string;
  direction?: Direction;
  testId?: string;
}

const Skeleton: FunctionComponent<ISkeletonProps> = (props): ReactElement => {
  const { size, length, direction, testId } = props;

  const skeletonClass = getClass(skeletonComponentName, {
    text: [size, direction],
  });
  const skeletonTestId = getTestId(skeletonComponentName, testId);
  const skeletonStyle = { width: length };

  return (
    <div
      className={skeletonClass}
      data-testid={skeletonTestId}
      style={skeletonStyle}
    />
  );
};

export default Skeleton;
