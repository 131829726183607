import Flex, { Horizontal } from 'components/Flex';
import SidebarContext from 'components/Sidebar/sidebar.context';
import { featureComponentName, DOM_KEY_FEATURE_CONTENT } from 'features';
import { getClass, getTestId } from 'helpers/components';
import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';

export const stickyHeaderComponentName = 'sticky-header';

export interface IStickyHeaderProps {
  children: ReactNode;
  testId?: string;
}

const StickyHeader: FunctionComponent<IStickyHeaderProps> = (
  props,
): ReactElement => {
  const { children, testId } = props;

  const [
    isStickyHeaderIntersectingWithFeatureContent,
    setIsStickyHeaderIntersectingWithFeatureContent,
  ] = useState<boolean>(false);

  const { expanded: sideBarIsExpanded } = useContext(SidebarContext);

  const handleStickyHeaderIntersectWithFeatureContent = (
    entries: { isIntersecting: boolean }[],
  ): void => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsStickyHeaderIntersectingWithFeatureContent(false);
      } else {
        setIsStickyHeaderIntersectingWithFeatureContent(true);
      }
    });
  };

  const featureContentClass = useMemo(
    () =>
      getClass(featureComponentName, {
        concat: [DOM_KEY_FEATURE_CONTENT],
      }),
    [],
  );

  const stickyHeaderDefaultClass = useMemo(
    () => getClass(stickyHeaderComponentName),
    [],
  );

  useEffect(() => {
    const featureContentNode = document.querySelector(
      `.${featureContentClass}`,
    );

    const stickyHeaderIntersectionObserver = new IntersectionObserver(
      handleStickyHeaderIntersectWithFeatureContent,
      {
        root: featureContentNode,
        rootMargin: '0px',
        threshold: 1.0,
      },
    );

    const stickyHeaderNode = document.querySelector(
      `.${stickyHeaderDefaultClass}`,
    );

    if (stickyHeaderNode) {
      if (sideBarIsExpanded) {
        stickyHeaderIntersectionObserver.unobserve(stickyHeaderNode);
      } else if (!sideBarIsExpanded) {
        stickyHeaderIntersectionObserver.observe(stickyHeaderNode);
      }
    }

    return (): void => {
      if (stickyHeaderNode) {
        stickyHeaderIntersectionObserver.unobserve(stickyHeaderNode);
      }
    };
  }, [featureContentClass, sideBarIsExpanded, stickyHeaderDefaultClass]);

  const stickyHeaderClass = useMemo(
    () =>
      isStickyHeaderIntersectingWithFeatureContent
        ? `${stickyHeaderDefaultClass} intersected`
        : stickyHeaderDefaultClass,
    [isStickyHeaderIntersectingWithFeatureContent, stickyHeaderDefaultClass],
  );

  const stickyHeaderTestId = getTestId(stickyHeaderComponentName, testId);

  return (
    <div className={stickyHeaderClass} data-testid={stickyHeaderTestId}>
      {children}
    </div>
  );
};

export default StickyHeader;
