import { getClass } from 'helpers/components';
import { map } from 'lodash/fp';
import React, { FunctionComponent } from 'react';
import fontList from 'theme/605/fonts';

export const fontLoaderComponentName = 'font-loader';

const fontLoaderClass = getClass(fontLoaderComponentName);

const FontLoader: FunctionComponent = () => (
  <div className={fontLoaderClass}>
    {map(
      (font: string) => (
        <span key={font} style={{ fontFamily: font }} />
      ),
      fontList,
    )}
  </div>
);

export default FontLoader;
