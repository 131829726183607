import { IResultSelectionFilters } from 'domains/resultSelections/types';
import { get } from 'lodash/fp';
import { IOption } from 'types/textValue';

export const DEFAULT_X_AXIS = 'campaign.REACH';
export const DEFAULT_Y_AXIS = 'converters.LIFT';
export const DEFAULT_BUBBLE_SIZE = 'campaign.IMPRESSIONS';
export const DEFAULT_SORTED_BY = 'campaign.REACH';
export const DEFAULT_BREAKOUT = 'TOP_25.converters';

export const DROPDOWN_DEFAULTS = {
  x: DEFAULT_X_AXIS,
  y: DEFAULT_Y_AXIS,
  bubbleSize: DEFAULT_BUBBLE_SIZE,
  sortedBy: DEFAULT_SORTED_BY,
  breakout: DEFAULT_BREAKOUT,
};

export type DROPDOWN_DEFAULT = keyof typeof DROPDOWN_DEFAULTS;

export enum DROPDOWN_DEFAULT_KEYS {
  X = 'x',
  Y = 'y',
  BUBBLE_SIZE = 'bubbleSize',
  SORTED_BY = 'sortedBy',
  BREAKOUT = 'breakout',
}

/**
 * Find selection for the given dropdown in the result selections and return default selection
 * @param dropdown -> creative, network, x, y, bubbleSize, etc
 * @param defaultSelection -> TOP_25.converters, DEFAULT_X_AXIS, DEFAULT_Y_AXIS
 * @returns selection
 */
export const getDefaultSelection = (
  dropdown: DROPDOWN_DEFAULT,
  options: IOption[],
  savedSelections: IResultSelectionFilters['campaignDeliveryOutcomes'],
): string => {
  const savedSelection = get(dropdown, savedSelections) as string;
  const selectionExists =
    !!savedSelection && options.some(({ value }) => value === savedSelection);

  const defaultSelection = DROPDOWN_DEFAULTS[dropdown];
  const defaultSelectionExists = options.some(
    ({ value }) => value === defaultSelection,
  );

  const firstAvailableSelection = options[0]?.value as string;
  return (
    (!!selectionExists && savedSelection) ||
    (defaultSelectionExists && defaultSelection) ||
    firstAvailableSelection
  );
};
