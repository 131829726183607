import { ChartType } from 'components/ChartWithTable';
import { TableDataType } from 'components/Table';
import { ResultBase, SampleGroupNames } from 'domains/reports/types';
import { IResultSelectionRowsRemoved } from 'domains/resultSelections/types';
import { filterByMetricValues } from 'helpers/charts/chartWithTable/filterByMetricValues';
import { getCurrentBreakoutData } from 'helpers/charts/chartWithTable/getCurrentBreakoutData';
import { getFilteredData } from 'helpers/charts/chartWithTable/getFilteredData';
import { removeRowsFromData } from 'helpers/charts/chartWithTable/removeRowsFromData';
import { IState } from 'helpers/charts/chartWithTable/types';
import { trackFiltersChange, trackGraphRowRemoval } from 'helpers/mixpanel';
import { cloneDeep, isArray } from 'lodash';
import ITextValue, { IOption } from 'types/textValue';
import { DEFAULT_IS_SORTED_DESC, sectionName } from '.';
import { getFilterKey, getPrevFilterKey } from './helpers/getFilterKey';
import { getYAxisValue } from './helpers/getYAxisValue';
import { mergeFullCampaignRow } from './helpers/mergeFullCampaignRow';
import { DEFAULT_SORTED_BY } from './helpers/getDefaultSelection';

export enum ActionTypes {
  INITIALIZE = 'INITIALIZE',
  CHANGE_BREAKOUT = 'CHANGE_BREAKOUT',
  CHANGE_RESULTS_COUNT = 'CHANGE_RESULTS_COUNT',
  CHANGE_NET_ON_OFF = 'CHANGE_NET_ON_OFF',
  CHANGE_UNIQUE_ON_OFF = 'CHANGE_UNIQUE_ON_OFF',
  REMOVE_RECORD_FROM_DATA = 'REMOVE_RECORD_FROM_DATA',
  RESET_CHART_DATA = 'RESET_CHART_DATA',
  CHANGE_TARGETS = 'CHANGE_TARGETS',
  CHANGE_X_AXIS = 'CHANGE_X_AXIS',
  CHANGE_Y_AXIS = 'CHANGE_Y_AXIS',
  CHANGE_BUBBLE_SIZE = 'CHANGE_BUBBLE_SIZE',
  CHANGE_CHART_TYPE = 'CHANGE_CHART_TYPE',
  CHANGE_SORTED_BY = 'CHANGE_SORTED_BY',
}

export type Action =
  | {
      payload: {
        chartData: ResultBase;
        rowsRemoved: IResultSelectionRowsRemoved['campaignDeliveryOutcomes'];
        sortedBy: string;
        isSortedDesc: boolean;
      };
      type: ActionTypes.INITIALIZE;
    }
  | {
      payload: ITextValue;
      type: ActionTypes.CHANGE_BREAKOUT;
    }
  | { payload: boolean; type: ActionTypes.CHANGE_NET_ON_OFF }
  | {
      payload: { uniqueOnOff: boolean };
      type: ActionTypes.CHANGE_UNIQUE_ON_OFF;
    }
  | { payload: TableDataType; type: ActionTypes.REMOVE_RECORD_FROM_DATA }
  | {
      payload: {
        chartData: ResultBase;
        rowsRemoved: IResultSelectionRowsRemoved['campaignDeliveryOutcomes'];
        sortedBy: string;
        isSortedDesc: boolean;
      };
      type: ActionTypes.RESET_CHART_DATA;
    }
  | { payload: IOption[]; type: ActionTypes.CHANGE_TARGETS }
  | { payload: ITextValue; type: ActionTypes.CHANGE_X_AXIS }
  | { payload: IOption; type: ActionTypes.CHANGE_Y_AXIS }
  | { payload: ITextValue; type: ActionTypes.CHANGE_BUBBLE_SIZE }
  | { payload: number; type: ActionTypes.CHANGE_RESULTS_COUNT }
  | { payload: ITextValue; type: ActionTypes.CHANGE_CHART_TYPE }
  | { payload: string; type: ActionTypes.CHANGE_SORTED_BY };

export const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case ActionTypes.INITIALIZE:
    case ActionTypes.RESET_CHART_DATA: {
      const { chartData, rowsRemoved, sortedBy, isSortedDesc } = action.payload;
      // NOTE: deep clone is needed to avoid global chart data mutations
      const dataWithRemovedRows = removeRowsFromData(
        cloneDeep(chartData),
        rowsRemoved ?? [],
      );

      const filters = { ...state.filters, sortedBy, isSortedDesc };
      const stateWithNewData = {
        ...state,
        data: dataWithRemovedRows,
        filters,
      };
      const currBreakoutData = mergeFullCampaignRow(
        getCurrentBreakoutData(stateWithNewData),
        stateWithNewData,
      );
      const filteredData = getFilteredData(filters, currBreakoutData);

      return { ...stateWithNewData, filteredData, currBreakoutData };
    }
    case ActionTypes.CHANGE_BREAKOUT: {
      const breakout = action.payload.value as string;
      const currFilters = state.filters;

      const resultsCount = breakout.includes('TOP_25')
        ? 25
        : currFilters.resultsCount;

      const filters = { ...currFilters, breakout, resultsCount };

      const stateWithNewFilters = { ...state, filters };

      const currBreakoutData = mergeFullCampaignRow(
        getCurrentBreakoutData(stateWithNewFilters),
        stateWithNewFilters,
      );

      const filteredData = getFilteredData(filters, currBreakoutData);

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: action.payload.text,
      });

      return { ...stateWithNewFilters, filteredData, currBreakoutData };
    }
    case ActionTypes.CHANGE_RESULTS_COUNT: {
      const resultsCount = action.payload;
      const currBreakoutData = state.currBreakoutData;
      const filters = { ...state.filters, resultsCount };
      const stateWithNewFilters = { ...state, filters };

      const filteredData = getFilteredData(filters, currBreakoutData);

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: resultsCount,
      });

      return { ...stateWithNewFilters, filteredData };
    }
    case ActionTypes.CHANGE_NET_ON_OFF: {
      const netOnOff = action.payload;
      const filters = { ...state.filters, netOnOff };
      const stateWithNewFilters = { ...state, filters };

      const currBreakoutData = mergeFullCampaignRow(
        getCurrentBreakoutData(stateWithNewFilters),
        stateWithNewFilters,
      );

      const filteredData = getFilteredData(filters, currBreakoutData);

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: netOnOff ? 'ON' : 'OFF',
      });

      return { ...stateWithNewFilters, filteredData, currBreakoutData };
    }
    case ActionTypes.CHANGE_UNIQUE_ON_OFF: {
      const { uniqueOnOff } = action.payload;

      // ON uniqueOnOff keeps Y-Axis breakout selected before into prevYAxis filter
      // OFF uniqueOnOff uses prevYAxis value to update YAxis filter
      const filters = { ...state.filters, uniqueOnOff };

      const stateWithNewFilters = { ...state, filters };

      const currBreakoutData = mergeFullCampaignRow(
        getCurrentBreakoutData(stateWithNewFilters),
        stateWithNewFilters,
      );

      const filteredData = getFilteredData(filters, currBreakoutData);

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: uniqueOnOff ? 'ON' : 'OFF',
      });

      return { ...stateWithNewFilters, filteredData, currBreakoutData };
    }

    case ActionTypes.REMOVE_RECORD_FROM_DATA: {
      const { breakout, decodedBreakout, target, name } = action.payload;

      if (target === null) return state;

      const dataWithRemovedRows = removeRowsFromData(state.data, [
        action.payload,
      ]);

      const stateWithNewData = { ...state, data: dataWithRemovedRows };

      const currBreakoutData = mergeFullCampaignRow(
        getCurrentBreakoutData(stateWithNewData),
        stateWithNewData,
      );

      const filteredData = {
        ...state.filteredData,
        [target]: state.filteredData[target as SampleGroupNames]?.filter(
          filterByMetricValues(breakout, name),
        ),
      };

      trackGraphRowRemoval({
        lastValidReport: state.lastValidReport,
        sectionName,
        selection: {
          breakout: decodedBreakout,
          target,
          name,
        },
      });

      return { ...stateWithNewData, filteredData, currBreakoutData };
    }
    case ActionTypes.CHANGE_TARGETS:
    case ActionTypes.CHANGE_X_AXIS:
    case ActionTypes.CHANGE_BUBBLE_SIZE: {
      const filters = {
        ...state.filters,
        [getFilterKey(action.type)]: isArray(action.payload)
          ? action.payload
          : action.payload.value,
        [getPrevFilterKey(action.type)]: isArray(action.payload)
          ? action.payload
          : action.payload.value,
      };

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: isArray(action.payload)
          ? action.payload.map((target) => target.text)
          : action.payload.text,
      });

      return { ...state, filters };
    }
    case ActionTypes.CHANGE_Y_AXIS: {
      const yAxis = action.payload.value as string;
      const sortedBy =
        state.filters.chartType === ChartType.BAR ? yAxis : DEFAULT_SORTED_BY;

      const filters = {
        ...state.filters,
        yAxis,
        prevYAxis: yAxis,
        sortedBy,
        isSortedDesc: DEFAULT_IS_SORTED_DESC,
      };

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: action.payload.text,
      });

      return { ...state, filters };
    }
    case ActionTypes.CHANGE_CHART_TYPE: {
      const chartType = action.payload.value;
      const yAxis =
        chartType === ChartType.BUBBLE
          ? getYAxisValue(state.filters.yAxis, state.filters.axisOptions)
          : state.filters.yAxis;

      const sortedBy = chartType === ChartType.BAR ? yAxis : DEFAULT_SORTED_BY;

      const filters = {
        ...state.filters,
        chartType: chartType as ChartType,
        yAxis,
        sortedBy,
      };

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: action.payload.text,
      });

      return { ...state, filters };
    }
    case ActionTypes.CHANGE_SORTED_BY: {
      const sortedBy = action.payload;
      const {
        sortedBy: currentSortedBy,
        isSortedDesc: currentIsSortedDescending,
      } = state.filters;

      const isChangingSortBy = sortedBy !== currentSortedBy;

      const isSortedDesc =
        currentSortedBy === sortedBy
          ? !currentIsSortedDescending
          : DEFAULT_IS_SORTED_DESC;

      const filters = {
        ...state.filters,
        sortedBy:
          !isChangingSortBy && !currentIsSortedDescending
            ? DEFAULT_SORTED_BY
            : sortedBy,
        isSortedDesc,
      };

      const stateWithNewFilters = { ...state, filters };

      const currBreakoutData = mergeFullCampaignRow(
        getCurrentBreakoutData(stateWithNewFilters),
        stateWithNewFilters,
      );
      const filteredData = getFilteredData(filters, currBreakoutData);

      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: sortedBy,
      });

      return { ...stateWithNewFilters, filteredData, currBreakoutData };
    }
    default: {
      return state;
    }
  }
};
