import { isCacheExpired } from 'domains/reports/adapters/rulesets';
import IReport, {
  IBusinessData,
  UNSAVED_REPORT_ID,
} from 'domains/reports/types';
import { QueryProgressDictionary } from 'domains/toolbar/types';
import {
  trackToolbarCancel,
  trackToolbarRefresh,
  trackToolbarRun,
  trackToolbarSave,
  trackToolbarSaveAs,
} from 'helpers/mixpanel';
import { isUndefined, isEmpty } from 'lodash';
import { Dispatch } from 'redux';
import ActionType from 'store/actions/types';
import { getReport, isTarget } from 'store/selectors/report';
import { getTargetData } from 'store/selectors/targetResult';
import { getLastExecutedAtCacheDate } from 'store/selectors/toolbar';
import { PayloadLessAction, Action } from 'types/action';
import State from 'types/state';

export interface INotPersistedPropType {
  [key: string]:
    | boolean
    | number
    | string
    | undefined
    | IBusinessData
    | string[];
}

export const disableCancel = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_DISABLE_CANCEL,
});

export const disableRefresh = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_DISABLE_REFRESH,
});

export const disableRun = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_DISABLE_RUN,
});

export const disableSave = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_DISABLE_SAVE,
});

export const disableSaveAs = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_DISABLE_SAVE_AS_COPY,
});

export const enableCancel = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_ENABLE_CANCEL,
});

export const enableRefresh = (): PayloadLessAction => {
  return {
    type: ActionType.TOOLBAR_ENABLE_REFRESH,
  };
};

export const enableRun = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_ENABLE_RUN,
});

export const enableSave = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_ENABLE_SAVE,
});

export const enableSaveAs = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_ENABLE_SAVE_AS_COPY,
});

export const enableAll = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_ENABLE_ALL,
});

export const setReadOnlyReportLoadStarted = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_SET_READ_ONLY_LOAD_STARTED,
});

export const setReadOnlyReportLoadEnded = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_SET_READ_ONLY_LOAD_ENDED,
});

export const refresh = (payload: IReport): Action<IReport> => {
  trackToolbarRefresh(payload as IReport);
  return {
    payload,
    type: ActionType.TOOLBAR_REFRESH,
  };
};

export const run = (payload: IReport): Action<IReport> => {
  trackToolbarRun(payload as IReport);
  return {
    payload,
    type: ActionType.TOOLBAR_RUN,
  };
};

export const cancel = (payload: IReport): Action<IReport> => {
  trackToolbarCancel(payload as IReport);
  return {
    payload,
    type: ActionType.TOOLBAR_CANCEL,
  };
};

export const save = (payload: IReport): Action<IReport> => {
  trackToolbarSave(payload as IReport);
  return {
    payload,
    type: ActionType.TOOLBAR_SAVE,
  };
};

export const saveAs = (payload: IReport): Action<IReport> => {
  trackToolbarSaveAs(payload as IReport);
  return {
    payload,
    type: ActionType.TOOLBAR_SAVE_AS_COPY,
  };
};

export const setLastCachedDate = (payload: number): Action<number> => ({
  payload,
  type: ActionType.TOOLBAR_SET_LAST_CACHED_DATE,
});

export const setRefreshLaunched = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_SET_IS_REFRESH_LAUNCHED,
});

export const setRefreshNotLaunched = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_SET_IS_REFRESH_NOT_LAUNCHED,
});

export const setRunLaunched = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_SET_IS_RUN_LAUNCHED,
});

export const setRunNotLaunched = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_SET_IS_RUN_NOT_LAUNCHED,
});

export const setProgress = (payload: number): Action<number> => ({
  payload,
  type: ActionType.TOOLBAR_SET_PROGRESS,
});

export const setQueryProgress = (
  payload: QueryProgressDictionary,
): Action<QueryProgressDictionary> => ({
  payload,
  type: ActionType.TOOLBAR_SET_QUERY_PROGRESS,
});

export const setType = (payload: string): Action<string> => ({
  payload,
  type: ActionType.TOOLBAR_SET_REPORT_TYPE,
});

export const enablePrivatePublic = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_ENABLE_REPORT_PUBLIC_PRIVATE,
});

export const disablePrivatePublic = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_DISABLE_REPORT_PUBLIC_PRIVATE,
});

export const enableAudit = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_ENABLE_AUDIT,
});

export const disableAudit = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_DISABLE_AUDIT,
});

export const enableLink = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_ENABLE_LINK,
});

export const disableLink = (): PayloadLessAction => ({
  type: ActionType.TOOLBAR_DISABLE_LINK,
});

export const handleEnableDisableToolbarButtons =
  () =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    const state = getState();
    const report = getReport(state);
    const { id } = report;
    const lastCachedDate = getLastExecutedAtCacheDate(state);

    if (isUndefined(report)) return;
    const { notPersistedProps } = report;
    const {
      isAccordionResultsOpen,
      isBaseReportRefreshing,
      isBaseReportRunning,
      isBaseReportSaving,
      isBaseReportSavingAs,
      reportHasChanged,
    } = notPersistedProps as INotPersistedPropType;

    const isExpired = isCacheExpired(lastCachedDate as number);

    if (isTarget(state)) {
      dispatch(disableSave());
      dispatch(disableSaveAs());
      dispatch(enableRun());
      if (
        (id === UNSAVED_REPORT_ID || reportHasChanged) &&
        isEmpty(getTargetData(state))
      ) {
        return;
      }
    }
    if (
      isBaseReportRunning ||
      isBaseReportRefreshing ||
      isBaseReportSaving ||
      isBaseReportSavingAs
    ) {
      dispatch(disableRefresh());
      dispatch(disableRun());
      dispatch(disableSave());
      dispatch(disableSaveAs());

      return;
    }

    dispatch(enableSave());
    dispatch(enableSaveAs());
    if (isExpired && !reportHasChanged && isAccordionResultsOpen) {
      dispatch(enableRefresh());
      dispatch(disableRun());
      return;
    }
    dispatch(disableRefresh());
    dispatch(enableRun());
    return;
  };
