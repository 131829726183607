import Table, { TableDataType } from 'components/Table';
import { getClass, getTestId } from 'helpers/components';
import React, { FC, useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Column, Row } from 'react-table';
import TableHeader from './TableHeader';
import 'react-perfect-scrollbar/dist/css/styles.css';

export type ITableDataItem = TableDataType;

export type ITableData = Array<ITableDataItem>;

export type IColumn = Column<TableDataType> & {
  active?: boolean;
  isSorted?: boolean;
  isSortedDesc?: boolean;
  disableSort?: boolean;
};

export type ITableColumns = Array<IColumn>;

export type ITableRow = Row<TableDataType> | null;

interface TableProps {
  columns: Array<IColumn>;
  data: ITableData;
  testId?: string;
  onRowHover: (row: ITableRow) => void;
  rowRemoveClickAction: (row: ITableRow) => void;
  onHeaderCellClick: (id: string) => void;
  stickyFirstColumn?: boolean;
}

export const tableName = 'chart-with-table-table';

const tableComponentClassName = getClass(tableName);
const tableWrapperClassName = getClass(`${tableName}__table-wrapper`);
const tableScrollableContentClassName = getClass(
  `${tableName}__scrollable-content`,
);

const TableWrapper: FC<TableProps> = ({
  columns,
  data,
  testId,
  onRowHover,
  rowRemoveClickAction,
  onHeaderCellClick,
  stickyFirstColumn = false,
}) => {
  const tableTestId = getTestId(tableName, testId);
  const tableClassName = stickyFirstColumn
    ? `${tableComponentClassName} sticky-first-column`
    : tableComponentClassName;

  const scrollbarRef = useRef<HTMLElement | null>(null);

  //   PerfectScrollbar doesn't remember the last scroll position.
  //   We refresh the data to keep the scroll position and content height updated.
  const [, setRefreshData] = useState<TableDataType[]>([]);

  const [scrollLeft, setScrollLeft] = useState<number>(0);

  useEffect(() => {
    setRefreshData(data);
  }, [data]);

  const setScrollbarRef = (element: HTMLElement): void => {
    scrollbarRef.current = element;
  };

  const setActiveColumnOffset = (offset: number): void => {
    const { current } = scrollbarRef;
    if (current) {
      current.scrollLeft = offset;
    }
  };

  const handleScrollX = (container: HTMLElement): void => {
    setScrollLeft(container.scrollLeft);
  };

  return (
    <div className={tableClassName} data-testid={tableTestId}>
      <TableHeader
        columns={columns}
        testId={`${tableTestId}-header`}
        scrollLeft={scrollLeft}
        onActiveColumnOffsetChange={setActiveColumnOffset}
        onHeaderCellClick={onHeaderCellClick}
      />
      <div className={tableScrollableContentClassName}>
        <PerfectScrollbar
          options={{
            wheelPropagation: false,
          }}
          onScrollX={handleScrollX}
          containerRef={setScrollbarRef}
        >
          <div className={tableWrapperClassName}>
            <Table
              columns={columns}
              data={data}
              cellspacing="0"
              alignment={['left']}
              hasHeader={false}
              onRowHover={onRowHover}
              rowRemoveClickAction={rowRemoveClickAction}
              testId={`${tableTestId}-table`}
              stickyFirstColumn={stickyFirstColumn}
            />
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default TableWrapper;
