import { getClass, getTestId } from 'helpers/components';
import React, { ReactElement } from 'react';

const campaignDeliveryOverlayComponentName = 'campaign-delivery-overlay';
const campaignDeliveryOverlayClass = getClass(
  campaignDeliveryOverlayComponentName,
);

export const campaignDeliveryOverlayTestId = getTestId(
  campaignDeliveryOverlayComponentName,
);

export const Overlay = (): ReactElement => (
  <div
    className={campaignDeliveryOverlayClass}
    data-testid={campaignDeliveryOverlayTestId}
  />
);
