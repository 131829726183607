import Box, { Type as BoxType } from 'components/Box';
import Button, { Kind, Type, Size } from 'components/Button';
import Dropdown from 'components/Dropdown';
import Error from 'components/Error';
import H1 from 'components/H1';
import IClient from 'domains/clients/types';
import IReport from 'domains/reports/types';
import { getComponentUuid, getClass } from 'helpers/components';
import useClients from 'hooks/useClients';
import useFetch from 'hooks/useFetch';
import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Index as Routes, Actions } from 'routes';
import FetchMethod from 'types/fetchMethod';

export const importReportComponentName = 'import-report';
export const importReportComponent = getComponentUuid(
  importReportComponentName,
);

const LOCALE_IMPORT_REPORT_TITLE = 'Import Report';
const LOCALE_UPLOAD = 'Upload';
const LOCALE_BEGIN_IMPORT = 'Begin Import';
const LOCALE_SELECT_CLIENT = 'Select Clients to Assign';

const ImportReport = (): ReactElement => {
  const importReportClass = getClass(importReportComponentName);

  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedClient, setSelectedClient] = useState<IClient>();
  const [importError, setImportError] = useState({
    errorOccurred: false,
    errorMessage: '',
  });
  const [importedReport, setImportedReport] = useState<IReport | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const { clients } = useClients();
  const { doFetch } = useFetch<IReport>();

  /* Effect hook that resets the import error/success state
   *  when the selected file or client is changed */
  useEffect(() => {
    setImportError({
      errorOccurred: false,
      errorMessage: '',
    });
    setImportedReport(null);
  }, [selectedClient, selectedFile]);

  const clientOptions = clients.map((client) => ({
    text: client.name ?? '',
    value: client.id ?? '',
  }));

  const fileSelectedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const uploadedFile = event.target.files?.[0];

    if (uploadedFile) {
      setSelectedFile(uploadedFile);
    }
  };

  const handleClientSelect = (selectedClientId: string): void => {
    const selectedClientFound = clients.find(
      (client) => client.id === selectedClientId,
    );
    setSelectedClient(selectedClientFound);
  };

  const handleImport = async (): Promise<void> => {
    // Clear out the imported report from state
    // prior to doing another import
    if (importedReport) {
      setImportedReport(null);
    }

    // Get the contents of the file
    const fileContents = await new Promise<Record<string, unknown>>(
      (resolve) => {
        // Set up file reader and resolve once file has been loaded
        const fileReader = new FileReader();
        fileReader.onload = (event: ProgressEvent<FileReader>) => {
          const text = event.target?.result as string;
          return resolve(JSON.parse(text));
        };

        if (selectedFile) {
          fileReader.readAsText(selectedFile);
        }
      },
    );

    // Send the contents of the file and selected client ID
    // to the import endpoint
    doFetch({
      endpoint: `/${Routes.SEGMENT_REPORTS}/${Actions.SEGMENT_IMPORT}/${selectedClient?.extID}`,
      method: FetchMethod.POST,
      payload: fileContents,
      onSuccess: (reports) => setImportedReport(reports[0]),
      onError: (response) =>
        setImportError({
          errorOccurred: true,
          errorMessage: response.message ?? '',
        }),
    });
  };

  return (
    <div className={`${importReportClass} container-fluid`}>
      <div className="header-row row mb-1">
        <H1>{LOCALE_IMPORT_REPORT_TITLE}</H1>
      </div>

      <div className="row">
        <div className="col-xs-6 mr-3 pl-0">
          {/** Hides the input file button and triggers it
           * on the upload button click */}
          <div className="row mb-1 ml-0">
            <input
              type="file"
              accept="application/json"
              multiple={false}
              style={{ display: 'none' }}
              onChange={fileSelectedHandler}
              ref={inputRef}
            />
            <Button
              kind={Kind.primary}
              type={Type.button}
              size={Size.medium}
              onClick={() => inputRef.current?.click()}
              className="spaced-btn"
            >
              {LOCALE_UPLOAD}
            </Button>
          </div>

          {/** List of clients to select for the import */}
          <div className="row mb-1 ml-0">
            <Dropdown
              placeholder={LOCALE_SELECT_CLIENT}
              options={clientOptions}
              onChange={handleClientSelect}
              value={selectedClient ? selectedClient.id : ''}
            />
          </div>

          <div className="row ml-0">
            <Button
              kind={Kind.primary}
              type={Type.button}
              size={Size.medium}
              onClick={handleImport}
              className="spaced-btn"
              disabled={!(selectedFile && selectedClient)}
            >
              {LOCALE_BEGIN_IMPORT}
            </Button>
          </div>
        </div>

        {/** Display the selected file when available */}
        {selectedFile && (
          <div className="col-xs-6 mr-3 pl-0">
            <div className="row h-50 ml-0">
              <Box type={BoxType.primary}>
                <p>{selectedFile.name}</p>
              </Box>
            </div>

            {importError.errorOccurred && (
              <Error message={importError.errorMessage} />
            )}

            {importedReport && (
              <p>
                {'Import was successful. '}
                <Link
                  to={`/${Routes.SEGMENT_ATTRIBUTION_REPORTS}/${Actions.SEGMENT_EDIT}/${importedReport.id}`}
                  target="_blank"
                >
                  Navigate to the imported report
                </Link>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportReport;
