import { array, object, string } from 'yup';

export const validationsSchemaBuilder = (): unknown =>
  object().shape({
    type: string().required(),
    name: string().required().min(2),
    dataset_id: string().required(),
    crosswalk_combinations: array(
      object().shape({ text: string(), value: string() }),
    )
      .required()
      .min(1),
    universe_label: array(object().shape({ text: string(), value: string() }))
      .required()
      .min(1),
  });
