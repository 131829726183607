import H1 from 'components/H1';
import IClient from 'domains/clients/types';
import { useDatasets } from 'domains/datasets/hooks';
import { IDataset } from 'domains/datasets/types';
import { getTestId, getClass } from 'helpers/components';
import useClients from 'hooks/useClients';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Index, getUrl } from 'routes';
import * as domainsActions from 'store/actions/domains';
import { Action } from 'types/action';
import { IMeta } from 'types/apiReturn';
import IError from 'types/error';
import DatasetForm from './components/Form';

export const editDatasetComponentName = 'edit-dataset';

const LOCALE_DATASET_EDIT_TITLE = 'Edit Dataset';
const LOCALE_DATASET_CREATE_TITLE = 'New Dataset';
const LOCALE_DATASET_CREATE_SUCCESS = 'Dataset created';
export const LOCALE_DATASET_UPDATE_SUCCESS = 'Dataset updated';

const DOM_KEY_DATASET_EDIT_HEADER = 'header';

interface Props {
  setClients: (arg0: IClient[]) => Action<IClient[]>;
}

const DatasetEdit: FunctionComponent<Props> = (props: Props) => {
  const { setClients } = props;
  const navigate = useNavigate();
  const { setDatasets, getDataset } = useDatasets();
  const { id } = useParams<{ id: string }>();
  const { doSuccessToast, doErrorToast } = useToast();
  const { clients } = useClients();
  const { doUpdate, doCreate, loading } = useItemAdmin<IDataset, IClient[]>({
    endpoint: getUrl([Index.SEGMENT_DATASETS]),
  });

  const goToDatasets = (): void => {
    navigate(`/${Index.SEGMENT_ADMIN}/${Index.SEGMENT_DATASETS}`);
  };

  const handleSuccess = (
    datasets: IDataset[],
    meta?: IMeta<IDataset, IClient[]>,
  ): void => {
    setDatasets(datasets);
    if (meta?.updated) setClients(meta?.updated);
    doSuccessToast(
      id ? LOCALE_DATASET_CREATE_SUCCESS : LOCALE_DATASET_UPDATE_SUCCESS,
    );
    navigate(`/${Index.SEGMENT_ADMIN}/${Index.SEGMENT_DATASETS}`);
  };

  const handleError = (err: IError): void => {
    doErrorToast(err?.message ?? 'There has been an error.');
  };

  const handleSubmit = async (
    submitDataset: IDataset,
    clientIds: string[],
  ): Promise<void> => {
    if (id) {
      doUpdate({
        item: { ...submitDataset, id },
        params: { clientIds },
        onSuccess: handleSuccess,
        onError: handleError,
      });
    } else {
      doCreate({
        item: { ...submitDataset },
        params: { clientIds },
        onSuccess: handleSuccess,
        onError: handleError,
      });
    }
  };

  const dataset = getDataset(id);
  const editDatasetTestId = getTestId(editDatasetComponentName);
  const editDatasetClass = getClass(editDatasetComponentName);
  const editDatasetHeaderClass = getClass(editDatasetComponentName, {
    concat: [DOM_KEY_DATASET_EDIT_HEADER],
  });

  return (
    <section className={editDatasetClass} data-testid={editDatasetTestId}>
      <header className={editDatasetHeaderClass}>
        <H1>{id ? LOCALE_DATASET_EDIT_TITLE : LOCALE_DATASET_CREATE_TITLE}</H1>
      </header>
      <DatasetForm
        dataset={dataset}
        clients={clients}
        onSubmit={handleSubmit}
        onCancel={goToDatasets}
        loading={loading}
        testId={editDatasetTestId}
      />
    </section>
  );
};

const mapDispatchToProps = {
  setClients: domainsActions.setClients,
};

export default connect(null, mapDispatchToProps)(DatasetEdit);
