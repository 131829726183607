import IEvent from 'domains/events/types';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';

type Payload = IEvent[] | null | undefined;

export const setEvents = (payload: Payload): Action<Payload> => ({
  type: ActionType.EVENTS_SET_EVENTS,
  payload,
});
