import { transform } from 'lodash';
import { ISegmentData, TSegmentedData } from '..';
import { computeSeries } from './computeSeries';

/**
 * Converts Segmented Data to Series Data acceptable by Highcharts.
 *
 * @param segmentedData
 * Object with [segment] keys, and values of chart points values.
 * @returns
 * Highcharts series for each line with multiple points in [data] list
 */

export const reverseSegmentedData = (obj: TSegmentedData): TSegmentedData =>
  Object.keys(obj)
    .reverse()
    .reduce((a: TSegmentedData, key: string) => {
      a[key] = obj[key];
      return a;
    }, {});

export const getSeriesDataFromSegmentedData = (
  segmentedData: TSegmentedData,
): ISegmentData[] =>
  transform(
    reverseSegmentedData(segmentedData),
    (prev, curr, key) => {
      prev.push({
        name: key,
        data: computeSeries(curr, prev.length),
      });
    },
    [] as ISegmentData[],
  );
