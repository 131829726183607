import { setCookie, getCookie } from 'helpers/cookies';
import useClients from 'hooks/useClients';
import { useSelector } from 'react-redux';
import State from 'types/state';
import IClient from './types';

export const COOKIE_NAME_SELECTED_CLIENT = 'selectedClient';

export type HookReturn = {
  selectedClient: IClient;
  selectedClientId: string;
  setClientCookie: (client: IClient | undefined) => void;
};

const initialState = {
  name: '',
  id: '',
};

export const setClientInCookie = (selectedClient: IClient): void => {
  setCookie(COOKIE_NAME_SELECTED_CLIENT, JSON.stringify(selectedClient));
};

export const clientIsValid = (
  client: IClient | undefined,
  clients: IClient[] = [],
): boolean => {
  const structureIsValid = !!(client?.id && client.extID && client.name);
  if (clients.length && client) {
    const integrityIsValid = !!(
      clients.find((x) => x.id === client.id) &&
      clients.find((x) => x._id === client._id)
    );
    return structureIsValid && integrityIsValid;
  }
  return structureIsValid;
};

export const getClientInCookie = (): IClient => {
  const clientInCookieRaw = getCookie(COOKIE_NAME_SELECTED_CLIENT);
  try {
    const clientInCookie = JSON.parse(clientInCookieRaw);
    if (clientIsValid(clientInCookie)) {
      return clientInCookie;
    }
  } catch (error) {
    return { ...initialState };
  }
  return { ...initialState };
};

export const getClientInSessionStorage = (): IClient => {
  const clientInSessionStorageRaw = window.sessionStorage.getItem('client');
  try {
    const clientInSessionStorage = JSON.parse(
      clientInSessionStorageRaw ?? '{}',
    );
    if (clientIsValid(clientInSessionStorage)) {
      return clientInSessionStorage;
    }
  } catch (error) {
    return { ...initialState };
  }
  return { ...initialState };
};

const useSelectedClient = (): HookReturn => {
  const selectedClientId = useSelector(
    ({ user: { selectedClient = '' } }: State) => selectedClient,
  );

  const { clients } = useClients();

  const getSelectedClient = (): IClient => {
    const clientInSessionStorage = getClientInSessionStorage();
    if (clientIsValid(clientInSessionStorage)) {
      // Match the stored ID to the available list of clients.
      const matchingClient = clients.find(
        (client) => client.id === clientInSessionStorage.id,
      ) as IClient;
      // Can return the essential client details we store as a fallback.
      return matchingClient ?? clientInSessionStorage;
    }

    const clientCookie = getClientInCookie();
    if (clientIsValid(clientCookie)) {
      const matchingClient = clients.find(
        (client) => client.id === clientCookie.id,
      ) as IClient;
      return matchingClient ?? clientCookie;
    }
    return initialState;
  };

  const setClientCookie = (selectedClient: IClient | undefined): void => {
    if (selectedClient && clientIsValid(selectedClient)) {
      const essentialClientDetails = {
        _id: selectedClient._id,
        id: selectedClient.id,
        extID: selectedClient.extID,
        name: selectedClient.name,
      };
      setClientInSessionStorage(essentialClientDetails);
      setClientInCookie(essentialClientDetails);
    }
  };

  const setClientInSessionStorage = (
    selectedClient: IClient | undefined,
  ): void => {
    window.sessionStorage.setItem('client', JSON.stringify(selectedClient));
  };

  return {
    selectedClientId,
    selectedClient: getSelectedClient(),
    setClientCookie,
  };
};

export default useSelectedClient;
