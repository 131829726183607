import ActionsBar from 'components/ActionsBar';
import Dropdown from 'components/Dropdown';
import H3 from 'components/H3';
import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import State from 'types/state';
import ITextValue from 'types/textValue';
import * as targetResultSelectors from 'store/selectors/targetResult';
import * as reportResultActions from 'store/actions/reportResult';

interface IProps {
  exportConfigOptions: ITextValue[];
  onCancel: () => void;
  handleConfigOptionExport: (
    configOptionId: string,
    toggleModalHook: (isOpen: boolean) => void,
  ) => void;
}

const ISpotSegmentModal = ({
  exportConfigOptions = [],
  onCancel,
  handleConfigOptionExport,
}: IProps): ReactElement => {
  const [configOption, setConfigOption] = useState<string>('');
  return (
    <div>
      <header>
        <H3>Please select an iSpot client to upload to</H3>
      </header>
      <section>
        <Dropdown
          options={exportConfigOptions}
          onChange={(val) => setConfigOption(val)}
          modalDropdown
          placeholder="Client"
        />
      </section>
      <ActionsBar
        okLabel="Confirm and Export"
        onOK={() => handleConfigOptionExport(configOption, onCancel)}
        onCancel={onCancel}
        disabledOK={!configOption}
      />
    </div>
  );
};

const mapStateToProps = (
  state: State,
): Pick<IProps, 'exportConfigOptions'> => ({
  exportConfigOptions: targetResultSelectors.getExportConfigOptions(state),
});

const mapDispatchToProps = {
  handleConfigOptionExport: reportResultActions.handleConfigOptionExport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ISpotSegmentModal);
