import { initialState as businessData } from 'store/reducers/businessData';
import { initialState as events } from 'store/reducers/events';
import { initialState as messageList } from 'store/reducers/messageList';
import { initialState as processing } from 'store/reducers/processing';
import { initialState as report } from 'store/reducers/report';
import { initialState as reportResult } from 'store/reducers/reportResult';
import { initialState as targetResult } from 'store/reducers/targetResult';
import { initialState as toolbar } from 'store/reducers/toolbar';

export default {
  messageList,
  attributionReportList: null,
  config: {
    audience: '',
    clientId: '',
    domain: '',
    env: '',
    papiUrl: '',
  },
  user: {
    id: '',
    email: '',
    selectedClient: undefined,
  },
  businessData,
  domains: {
    attributionReports: [],
    clients: [],
    dashboards: [],
    datasets: [],
    dimensions: [],
    metrics: [],
    targets: [],
  },
  loaded: false,
  toolbar,
  report,
  reportList: null,
  reportResult,
  reportResults: null,
  targetList: null,
  targetResult,
  events,
  processing,
};
