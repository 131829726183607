import Button, { Kind, Type, Size } from 'components/Button';
import Flex from 'components/Flex';
import H1 from 'components/H1';
import ListActions from 'components/ListActions';
import Loading from 'components/Loading';
import Table, { textOverflows, alignments } from 'components/Table';
import { getTestId, getClass } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Actions, Index } from 'routes';
import IError from 'types/error';
import IRole from 'types/role';

const ROLE_LIST_PAGE_SIZE = 10;

export const rolesListComponentName = 'roles-list';

const LOCALE_BUTTON_NEW_ROLE_LABEL = 'New';
const LOCALE_FILTER_PLACEHOLDER = 'Search for roles';
const LOCALE_FILTER_NO_RESULTS_MESSAGE = 'No results found';
const LOCALE_FIRST_COLUMN_TITLE = 'Name';
const LOCALE_FIRST_COLUMN_ACCESSOR = 'name';
const LOCALE_SECOND_COLUMN_TITLE = 'Actions';
const LOCALE_SECOND_COLUMN_ID = 'Actions';

export interface IRoleListProps {
  roles: IRole[];
  testId?: string;
  resetList?: () => void;
  loading?: boolean;
}

const RolesList: FunctionComponent<IRoleListProps> = (props): ReactElement => {
  const { roles, testId, resetList, loading } = props;

  const navigate = useNavigate();
  const { checkPermissions } = useUserPermissions();
  const { doSuccessToast, doErrorToast } = useToast();
  const { doDelete } = useItemAdmin<IRole>({
    endpoint: `/${Index.SEGMENT_ROLES}`,
  });
  const { doCreate } = useItemAdmin<IRole>({
    endpoint: `/${Index.SEGMENT_ROLES}/${Actions.SEGMENT_CLONE}`,
  });

  const gotoCreate = (): void => {
    navigate(Actions.SEGMENT_CREATE);
  };

  const handleUpdateClick = (role: IRole): void => {
    navigate(`${Actions.SEGMENT_EDIT}/${role.id}`);
  };

  const handleDeleteClick = (role: IRole): void => {
    doDelete({
      item: role,
      onSuccess: (): void => {
        if (resetList) resetList();
        doSuccessToast('Role deleted');
      },
      onError: (err: IError): void => {
        doErrorToast(err?.message ?? 'There has been an error.');
      },
    });
  };

  const handleCloneClick = (role: IRole): void => {
    doCreate({
      item: role,
      onSuccess: (): void => {
        if (resetList) resetList();
        doSuccessToast('Role cloned!');
      },
      onError: (err: IError): void => {
        doErrorToast(err?.message ?? 'There has been an error.');
      },
    });
  };

  const rolesListTestId = getTestId(rolesListComponentName, testId);
  const rolesListDefaultClass = getClass(rolesListComponentName);

  return (
    <div className={`${rolesListDefaultClass}`} data-testid={rolesListTestId}>
      <header className={`${rolesListDefaultClass}-header`}>
        <Flex>
          <H1>Roles</H1>
          {checkPermissions('roles::create') && (
            <Button
              kind={Kind.primary}
              type={Type.button}
              size={Size.small}
              onClick={gotoCreate}
            >
              {LOCALE_BUTTON_NEW_ROLE_LABEL}
            </Button>
          )}
        </Flex>
      </header>
      <section>
        {!loading ? (
          <Table<IRole>
            initialState={{ sortBy: [{ id: 'name' }] }}
            filter
            filterPlaceholder={LOCALE_FILTER_PLACEHOLDER}
            goToEditOnRowClick
            noResultsMessage={LOCALE_FILTER_NO_RESULTS_MESSAGE}
            textOverflow={textOverflows.ellipsis}
            editItemSegment={Actions.SEGMENT_EDIT}
            alignment={[alignments.left, alignments.right]}
            columns={[
              {
                Header: LOCALE_FIRST_COLUMN_TITLE,
                accessor: LOCALE_FIRST_COLUMN_ACCESSOR,
                width: '80%',
              },
              {
                Header: LOCALE_SECOND_COLUMN_TITLE,
                id: LOCALE_SECOND_COLUMN_ID,
                width: '20%',
                Cell: function RolesListTableCell({
                  row: { original: role },
                }: CellProps<IRole>): JSX.Element {
                  return (
                    <ListActions<IRole>
                      loading={loading}
                      item={role}
                      canUpdate={checkPermissions('roles::update')}
                      canDelete={checkPermissions('roles::delete')}
                      canClone={checkPermissions('roles::duplicate')}
                      onDeleteClick={(): void => handleDeleteClick(role)}
                      onUpdateClick={(): void => handleUpdateClick(role)}
                      onCloneClick={(): void => handleCloneClick(role)}
                      testId={rolesListTestId}
                      id={role.id}
                    />
                  );
                },
              },
            ]}
            data={roles}
            pageSize={ROLE_LIST_PAGE_SIZE}
          />
        ) : (
          <Loading />
        )}
      </section>
    </div>
  );
};

export default RolesList;
