import { Multichart } from 'components/Multichart';
import { useMultichartTransformer } from 'components/Multichart/transformers';
import { IMultiChartConfig } from 'types/MultiChart';
import { ResultBase } from 'domains/reports/types';
import React from 'react';
import { IOption } from 'types/textValue';
import {
  getChartConfig,
  IChartTargetItem,
  ITargetFilters,
  mapDataToChartConfig,
} from 'helpers/visualization/CampaignExposure/exposure-households-and-converters';

interface IExposedHouseholdsAndConvertersProps {
  target: IOption[];
  chartData: ResultBase;
  percentileIndex: number;
}

export const ExposedHouseholdsAndConvertersChart: React.FC<
  IExposedHouseholdsAndConvertersProps
> = ({ target, percentileIndex, chartData }) => {
  const chartConfig = useMultichartTransformer<
    IChartTargetItem,
    ITargetFilters,
    IMultiChartConfig
  >(chartData as ResultBase, {
    filters: {
      target,
      percentileIndex,
    },
    reportResultMapper: mapDataToChartConfig,
    configGetter: getChartConfig,
  });

  return <Multichart chartConfig={chartConfig} />;
};
