import DurationSelector from 'components/DurationSelector';
import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import Input, { Type as InputType, NewInputValue } from 'components/Input';
import { getClass } from 'helpers/components';
import { trackReportInputChange } from 'helpers/mixpanel';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import noop from 'lodash/noop';
import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { RuleFilter } from 'types/filter';
import IIdName from 'types/idName';
import ITextValue from 'types/textValue';
import { humanizeDuration } from '../../helpers/humanizeDuration';

const componentName = 'duration-wrapper';
const componentClass = getClass(componentName);

type Props = {
  disabled?: boolean;
  isMinutes?: boolean;
  trackingId?: string;
  trackingRuleContext?: {
    filter: RuleFilter;
    index: number;
    parentRuleTracking?: RuleFilter;
  };
  onChange: (newValue: NewInputValue) => void;
  value?: number | string | (string | IIdName | ITextValue)[];
  errorText?: string;
  loading?: boolean;
  isReportInvalid?: boolean;
};

const DurationInput: FunctionComponent<Props> = ({
  disabled,
  isMinutes = false,
  loading = false,
  onChange,
  trackingId,
  trackingRuleContext,
  value = 0,
  errorText,
  isReportInvalid,
}) => {
  const [showWidget, setShowWidget] = React.useState(false);
  const [numericValue, setnumericValue] = useState(parseInt(`${value}`, 10));
  const ref = useRef<HTMLDivElement>(null);

  useOnOutsideClick(ref, () => {
    setShowWidget(false);
  });

  const handleOnClick = (): void => setShowWidget(true);

  const handleOnChange = (newValue: NewInputValue): void => {
    if (trackingId)
      trackReportInputChange(
        trackingId,
        newValue,
        undefined,
        'changed',
        trackingRuleContext,
      );
    onChange(typeof newValue === 'boolean' ? newValue : `${newValue}`);
  };

  useEffect(() => {
    setnumericValue(parseInt(`${value}`, 10));
  }, [numericValue, value]);

  return (
    <div ref={ref} onClick={handleOnClick} onKeyDown={noop}>
      <Input
        disabled={disabled}
        placeholder="Select duration"
        testId="value-select"
        type={InputType.select}
        loading={loading}
        readonly
        value={humanizeDuration(numericValue)}
        hasError={!!errorText && isReportInvalid}
      />
      {showWidget && (
        <div className={componentClass}>
          <DurationSelector
            isMinutes={isMinutes}
            onChange={handleOnChange}
            total={numericValue}
          />
        </div>
      )}
      {errorText && isReportInvalid && (
        <InlineAlert mode={AlertTypes.error} message={errorText} hideClose />
      )}
    </div>
  );
};

export default DurationInput;
