import { Metric } from 'domains/metrics/types';
import {
  PerformanceGroupNames,
  reportType,
  reportTypeEquivalences,
  ResultBase,
  ResultBaseTotalStructure,
  SampleGroupNames,
} from 'domains/reports/types';
import { compact, flow, get, isEqual, map, startCase } from 'lodash/fp';
import { createSelector } from 'reselect';
import * as reportSelectors from 'store/selectors/report';
import * as resultSelectors from 'store/selectors/reportResult';
import * as resultSelectionsSelector from 'store/selectors/resultSelections';
import { ALL_VIEWING_INTERVAL, Modes } from 'types/query';
import State from 'types/state';
import { IOption } from '../../types/textValue';
import { checkPermissions } from 'domains/reports/adapters/general';
import {
  generateChartBreakoutWT25,
  generateTop25,
} from 'helpers/visualization';
import { toLower } from 'lodash';
import {
  axisBasePerformanceOptions,
  axisCampaignOptions,
  performersWhiteList,
} from 'store/constants';

const getChartData = get('chartData');
const getIntervalId = get('interval.id');
const getSGMConfig = get('SGMConfig');

export const getReportResult = get('reportResult');
export const getExecutedReport = flow(getReportResult, get('executedReport'));
export const getExecutedReportCancelling = flow(
  getExecutedReport,
  get('cancelling'),
);
export const getLastExecutedCacheQueryId = flow(
  getReportResult,
  get('lastExecutedCacheQueryId'),
);
export const getSavedSegmentsFilenames = flow(
  getReportResult,
  get('savedSegmentsFilenames'),
);
export const getCacheQueryId = flow(getExecutedReport, get('cacheQueryId'));

export const getDownloadQuery = get('attributionReportResult.downloadQuery');
export const getFileUpload = get('attributionReportResult.fileUpload');

export const getDisplayChartData = get('displayChartData');
export const getDisplaySGMConfig = flow(getDisplayChartData, getSGMConfig);
export const getResultChartData = getChartData;
export const getIsAllViewing = flow(
  getResultChartData,
  getIntervalId,
  isEqual(ALL_VIEWING_INTERVAL.id),
);
export const getResultSGMConfig = flow(getResultChartData, getSGMConfig);

export const getRowCount = get('rowCount');
export const getAttributionReportResult = get(
  'attributionReportResult.resultData',
);

export const getDemographicChartData = get(
  'attributionReportResult.resultData.demographicStats',
);
export const getAttributionReportMiscResult = get(
  'attributionReportResult.resultData.miscResultData',
);
export const getAttributionReportSpecializedInsightsData = get(
  'attributionReportResult.resultData.specializedInsights.chartData',
);
export const getAttributionReportSpecializedInsightsTarget = get(
  'attributionReportResult.resultData.specializedInsights.selectedTarget',
);
export const getAttributionReportSpecializedInsightsTargetOptions = get(
  'attributionReportResult.resultData.specializedInsights.targetOptions',
);
export const getAttributionResultMetrics: (state: State) => string[] = get(
  'attributionReportResult.metrics',
);
export const getConversionType = get('attributionReportResult.conversionType');
export const getShowLroiInVisualization = get(
  'attributionReportResult.showLroiInVisualization',
);
export const getAttributionOmissionMessages = get(
  'attributionReportResult.omissionMessages',
);
export const getSegmentsSavingStatus = get(
  'attributionReportResult.segmentsSavingStatus',
);
export const getBreakoutList = get('attributionReportResult.breakoutList');
export const getDemographicBreakoutList = get(
  'attributionReportResult.demographicBreakoutList',
);

export const allowMoreFilters = (state: State): boolean => {
  const displayChartData = resultSelectors.getDisplayChartData(
    get('reportResult', state),
  );
  const reportSelectedMetrics: string[] = reportSelectors.getSelectedMetrics(
    get('report', state),
  );
  const displayedFilters = reportSelectedMetrics.filter((metric: string) =>
    displayChartData?.select?.includes(metric),
  );

  if (displayedFilters.length === 2) return false;
  const metrics = (displayChartData?.select as Metric[]) || [];
  return displayedFilters.length < metrics.length;
};

export const getErrorMessage = get('attributionReportResult.errorMessage');

const getTop25: (state: State) => ResultBaseTotalStructure = createSelector(
  getAttributionReportMiscResult,
  reportSelectors.getAttributionReportTargetObjects,
  getBreakoutList,
  reportSelectors.getPerformanceMetricGroupsFromLastValidReport,
  (
    resultMisc: ResultBase,
    targets,
    breakouts,
    performanceGroups: PerformanceGroupNames[],
  ) => generateTop25(resultMisc, targets, breakouts, performanceGroups),
);

export const getMiscResultData: (state: State) => ResultBase = createSelector(
  getAttributionReportMiscResult,
  (reportResult) => {
    if (!reportResult) return {};
    return reportResult;
  },
);

const getAttributionReportBreakoutResult = get(
  'attributionReportResult.resultData.breakouts',
);
export const getBreakoutChartDataWithTop25: (state: State) => ResultBase =
  createSelector(
    getAttributionReportBreakoutResult,
    getTop25,
    getMiscResultData,
    (reportData, top_25, resultMisc) =>
      generateChartBreakoutWT25(reportData, top_25, resultMisc),
  );

export const hasAdCostRelateResults = createSelector(
  resultSelectors.getBreakoutChartDataWithTop25,
  resultSelectionsSelector.getResultSelection,
  (
    breakoutResults,
    resultSelection,
  ): {
    AD_COST: boolean;
    PERSUASION_INDEX_BY_COST: boolean;
    COST_PER_INCREMENTAL_LIFT: boolean;
  } => {
    const selectedBreakoutPath = get(
      'filters.campaignDeliveryOutcomes.breakout',
      resultSelection,
    );
    const selectedBreakout = get(
      `exposed_all.${selectedBreakoutPath}.${SampleGroupNames.genpop}`,
      breakoutResults,
    );
    const adCostResult = compact(
      map(get('campaign.AD_COST'), selectedBreakout),
    );

    const persuasionIndexByCostResult = compact(
      map(get('converters.PERSUASION_INDEX_BY_COST'), selectedBreakout),
    );

    const costPerIncrementalListResult = compact(
      map(get('converters.COST_PER_INCREMENTAL_LIFT'), selectedBreakout),
    );

    return {
      AD_COST: adCostResult?.length > 0,
      PERSUASION_INDEX_BY_COST: persuasionIndexByCostResult?.length > 0,
      COST_PER_INCREMENTAL_LIFT: costPerIncrementalListResult?.length > 0,
    };
  },
);

export const getAxisOptions = createSelector(
  reportSelectors.getPerformanceMetricGroupsFromLastValidReport,
  getConversionType,
  reportSelectors.getModeFromLastValidReport,
  reportSelectors.getEqMetricFromLastValidReport,
  resultSelectionsSelector.getResultSelection,
  resultSelectors.hasAdCostRelateResults,
  (
    performanceMetricGroups: PerformanceGroupNames[],
    conversionType: string,
    mode: string,
    eqMetric: boolean,
    resultSelection,
    hasAdCostRelatedResults: {
      AD_COST: boolean;
      PERSUASION_INDEX_BY_COST: boolean;
      COST_PER_INCREMENTAL_LIFT: boolean;
    },
  ): IOption[] => {
    const isGeneric = mode === Modes.genericEvents;

    const options = axisCampaignOptions.filter((op) => {
      if (isGeneric && op.value === 'campaign.SPOT_COUNT') {
        return false;
      }

      if (!hasAdCostRelatedResults.AD_COST && op.value === 'campaign.AD_COST') {
        return false;
      }

      const value = `${op.value}`;
      const permission = `${reportTypeEquivalences(
        reportType.attributionReport,
      )}.campaign_delivery_and_insights.${toLower(value)}::view`;

      if (checkPermissions(permission)) {
        return true;
      }
      if (
        value === 'campaign.REACH' &&
        checkPermissions('attribution_reports.campaign.reach::view')
      )
        return true;
      if (
        value === 'campaign.IMPRESSIONS' &&
        checkPermissions('attribution_reports.campaign.impressions::view')
      )
        return true;
      return false;
    });
    const equivalizedMetricList = [
      'campaign.IMPRESSIONS',
      'campaign.FREQUENCY',
      'campaign.SPOT_COUNT',
    ];
    const equivalizeAdjustedOptions = options.map((op) => {
      if (eqMetric && equivalizedMetricList.includes(op.value as string)) {
        return {
          ...op,
          text: 'Eq. ' + op.text,
        };
      }
      return op;
    });

    performanceMetricGroups.forEach((pm) => {
      axisBasePerformanceOptions.forEach((baseOption) => {
        if (
          (baseOption.value === 'COST_PER_INCREMENTAL_LIFT' ||
            baseOption.value === 'PERSUASION_INDEX_BY_COST') &&
          pm !== 'converters'
        )
          return;

        if (
          !hasAdCostRelatedResults.COST_PER_INCREMENTAL_LIFT &&
          baseOption.value === 'COST_PER_INCREMENTAL_LIFT'
        )
          return;
        if (
          !hasAdCostRelatedResults.PERSUASION_INDEX_BY_COST &&
          baseOption.value === 'PERSUASION_INDEX_BY_COST'
        )
          return;
        const value = `${baseOption.value}`;
        const permission = `${reportTypeEquivalences(
          reportType.attributionReport,
        )}.campaign_delivery_and_insights.${toLower(value)}::view`;
        if (
          checkPermissions(permission) ||
          performersWhiteList.includes(value)
        ) {
          const pmLabel = pm === 'conversions' ? conversionType : pm;
          const option: IOption = {
            id: equivalizeAdjustedOptions.length + 10,
            ...baseOption,
            text: `${startCase(pmLabel)}: ${baseOption.text}`,
            value: `${pm}.${baseOption.value}`,
          } as IOption;
          equivalizeAdjustedOptions.push(option);
        }
      });
    });

    return equivalizeAdjustedOptions;
  },
);
