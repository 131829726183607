import { LOCALE_HOUSEHOLDS_VISITED_BETWEEN } from 'features/targets/constants';
import { getClass, getTestId } from 'helpers/components';
import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs as TabsComponent } from 'react-tabs';

export const tabsComponentName = 'tabs';

export interface ITabPanelProps {
  path?: string;
  title: ReactNode;
  panelContent?: ReactElement;
  id: string;
}

export interface TabsProps {
  currentTabIndex?: number;
  testId?: string;
  panels: ITabPanelProps[];
  reportCurrentTab?: React.Dispatch<React.SetStateAction<number>>;
  path: string;
  startDate?: string;
  endDate?: string;
}

const Tabs: FunctionComponent<TabsProps> = (props: TabsProps) => {
  const {
    currentTabIndex,
    panels,
    reportCurrentTab,
    testId,
    path,
    startDate,
    endDate,
  } = props;
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const pathToRemove = new RegExp(`^${path}/`);
  const activeTab = pathname.replace(pathToRemove, '').split('/')[0];

  const [selectedIndex, setSelectedIndex] = useState(() => {
    const selectedPathIndex = panels
      .map(({ path: panelPath }) => panelPath)
      .findIndex((item) => item === activeTab);
    return selectedPathIndex > -1 ? selectedPathIndex : 0;
  });

  const [doRedirectOnSelectTab] = useState(
    panels.map(({ path: panelPath }) => panelPath).filter((item) => !!item)
      .length === panels.length,
  );

  const tabsDefaultClass = getClass(tabsComponentName);
  const tabListClass = getClass(tabsComponentName, { concat: ['tab-list'] });
  const tabListLocaleHouseholdsClass = getClass(tabsComponentName, {
    concat: ['tab-list-locale-households'],
  });
  const tabClass = getClass(tabsComponentName, { concat: ['tab'] });
  const tabPanelClass = getClass(tabsComponentName, { concat: ['tab-panel'] });

  const tabsSelectedTabClass = getClass(tabsComponentName, {
    concat: ['tab-selected'],
  });
  const tabsSelectedPanelClass = getClass(tabsComponentName, {
    concat: ['tab-panel-selected'],
  });

  const tabsTestId = getTestId(tabsComponentName, testId);

  const showDateRange =
    panels[selectedIndex].id === 'PlaceIQ Category' ||
    panels[selectedIndex].id === 'PlaceIQ Chain';

  const onSelectTab = (index: number): void => {
    if (reportCurrentTab) {
      reportCurrentTab(index);
    } else {
      setSelectedIndex(index);
    }

    if (doRedirectOnSelectTab) {
      const newPath = `${path}/${panels[index].path}`;
      navigate(newPath);
    }
  };

  return (
    <TabsComponent
      className={tabsDefaultClass}
      data-testid={tabsTestId}
      selectedTabClassName={tabsSelectedTabClass}
      selectedTabPanelClassName={tabsSelectedPanelClass}
      onSelect={onSelectTab}
      selectedIndex={currentTabIndex ?? selectedIndex}
    >
      {showDateRange && (
        <div className={tabListLocaleHouseholdsClass}>
          {LOCALE_HOUSEHOLDS_VISITED_BETWEEN} {startDate} - {endDate}
        </div>
      )}
      <TabList className={tabListClass}>
        {panels.map(({ title, id }) => (
          <Tab key={id} className={tabClass}>
            {title}
          </Tab>
        ))}
      </TabList>
      {panels.map(({ panelContent, id }) => (
        <TabPanel key={id} className={tabPanelClass}>
          {panelContent}
        </TabPanel>
      ))}
    </TabsComponent>
  );
};

export default Tabs;
