import Chart from 'components/Chart';
import Field from 'components/Field';
import Flex, { Horizontal, Vertical } from 'components/Flex';
import { NewInputValue, Type } from 'components/Input';
import QueryTable from 'components/QueryTable';
import ChartFilter, {
  Filter as ChartFilterType,
} from 'components/ReportChartFilter';
import GroupFilter from 'components/ReportChartFilter/GroupFilter';
import { Metric } from 'domains/metrics/types';
import {
  getBusinessRules,
  getGroupsFromQuery,
} from 'domains/reports/adapters/results';
import IReport, {
  DataRowGraph,
  IChartData,
  IMetaData,
  ISGMConfig,
} from 'domains/reports/types';
import { getClass } from 'helpers/components';
import {
  filter,
  get,
  head,
  intersection,
  isEqual,
  map,
  pullAt,
  reject,
  size,
  uniqBy,
  uniq,
  isNumber,
} from 'lodash/fp';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import * as reportActions from 'store/actions/report';
import * as resultActions from 'store/actions/reportResult';
import * as businessDataSelectors from 'store/selectors/businessData';
import * as reportSelectors from 'store/selectors/report';
import * as resultSelectors from 'store/selectors/reportResult';
import { IQuery } from 'types/query';
import State from '../../types/state';
import ITextValue from '../../types/textValue';
import TargetFilter from './components/TargetFilter';
import locale from './locale';
import { getTargetList, hasMetaTarget, Header, ITableConfig } from './utils';

export const reportResultsFormComponentName = 'results-report';
const DOM_KEY_RESULTS_CHART = 'results-chart';
const DOM_KEY_NO_DATA = 'no-data';
const DOM_KEY_TOO_MUCH_DATA = 'too-much-data';

const reportFormResultsChartClass = getClass(reportResultsFormComponentName, {
  concat: [DOM_KEY_RESULTS_CHART],
});
const reportFormNoDataClass = getClass(reportResultsFormComponentName, {
  concat: [DOM_KEY_NO_DATA],
});
const reportFormTooMuchDataClass = getClass(reportResultsFormComponentName, {
  concat: [DOM_KEY_TOO_MUCH_DATA],
});

export interface IReportResultsFormProps {
  isLoading: boolean;
  query: IQuery;
}

type OwnState = {
  chartData: IChartData;
  displayChartData: IChartData;
  isAllViewing: boolean;
  isTarget: boolean;
  reportRowLimit: number;
  reportSelectedCharts: string[];
  reportSelectedMetrics: string[];
  reportSelectedTargets: string[];
  SGMConfig: ISGMConfig;
  targets?: IReport[];
  totalRowCount: number;
  allowMoreFilters: boolean;
};

type OwnActions = {
  resetReportResult: () => void;
  setDisplayChartData: (arg0: IChartData) => void;
  setDisplaySGMConfig: (arg0: ISGMConfig) => void;
  setReportRowLimit: (arg0: number) => void;
  setReportSelectedTargets: (arg0: string[]) => void;
  handleRowLimitChange: (arg0: NewInputValue) => void;
  handleChangeFilters: (arg0: ChartFilterType[]) => void;
  getTableData: (arg0: DataRowGraph[], arg1: boolean) => void;
};

interface OwnProps extends IReportResultsFormProps, OwnState, OwnActions {}

const ReportResultsForm: FunctionComponent<OwnProps> = (
  props,
): ReactElement => {
  const {
    chartData,
    displayChartData,
    isAllViewing,
    isLoading,
    query,
    reportRowLimit,
    reportSelectedCharts,
    reportSelectedMetrics,
    reportSelectedTargets,
    resetReportResult,
    setDisplayChartData,
    setReportRowLimit,
    setReportSelectedTargets,
    setDisplaySGMConfig,
    handleRowLimitChange,
    handleChangeFilters,
    SGMConfig,
    targets,
    totalRowCount,
    isTarget,
    allowMoreFilters,
    getTableData,
  } = props;

  useEffect(
    () => () => {
      resetReportResult();
    },
    [resetReportResult],
  );

  const realQueryTargets =
    (chartData?.data?.length &&
      chartData?.data[0].TARGET &&
      uniq(chartData?.data?.map((x) => x.TARGET.VALUE))) ||
    [];
  const queryTargetList = targets?.filter((x) =>
    realQueryTargets.includes(x.id),
  );

  const isTargetGroup = hasMetaTarget(chartData);

  const originalData = get('data', chartData) as DataRowGraph[];
  const interval = get('interval.id', chartData);
  const resultHasInterval =
    get('interval.id', displayChartData) !== 'ALL_VIEWING';

  const intervalList = isAllViewing
    ? []
    : map(`${interval}.VALUE`, uniqBy(`${interval}.VALUE`, originalData));

  const intervalCount = isAllViewing ? totalRowCount : size(intervalList);
  const rowLimitOptionList = filter(
    (o) => get('value', o) < intervalCount,
    locale.ROW_LIMIT_OPTIONS,
  );

  useEffect(() => {
    if (isAllViewing && reportRowLimit) setReportRowLimit(reportRowLimit);
  }, [reportRowLimit, isAllViewing, setReportRowLimit]);

  const chartVisible = get('chartVisible', chartData);
  const tableVisible = get('tableVisible', chartData);

  const hasMultiValueReportData =
    chartData.data &&
    chartData.data.length > 1 &&
    chartData.data.length <= 10000 &&
    chartVisible;

  const tableData = useMemo(
    () =>
      tableVisible
        ? getTableData(
            // expensive call to generate table data!
            chartData?.data ?? [],
            true,
          )
        : ({} as ITableConfig),
    [tableVisible, getTableData, chartData?.data],
  ) as ITableConfig;

  const unlinkedHeaders = get('unlinkedHeaders', tableData);

  const selectedGroups = chartData.data?.length
    ? chartData.spatialDimensions
    : [...(query.group ?? []), query?.interval?.id ?? ''].filter(
        (x) => x !== 'ALL_VIEWING',
      );

  const businessRules = getBusinessRules(selectedGroups, SGMConfig, isTarget);

  // We should store targetList on redux once this gets persisted via report model
  const getSelectedTargetList = (): string[] => {
    const queryTargets = getTargetList(query) || [];
    if (reportSelectedTargets?.length === 0 && queryTargets.length) {
      reportSelectedTargets.push(...queryTargets);
    }

    let filteredTargets = intersection(
      reportSelectedTargets,
      targets
        ?.filter((target) => queryTargets.includes(target.id))
        ?.map((target) => target.id) ?? [],
    );

    if (businessRules.hasSpatialDimensions && !filteredTargets.length) {
      const first = head(targets)?.id ?? '';
      filteredTargets = [first];
    }

    return filteredTargets;
  };
  const selectedTargetsFromQuery = queryTargetList?.map(
    (queryTarget) => queryTarget.id,
  );
  const [targetList, setTargetList] = useState<string[]>(
    selectedTargetsFromQuery ?? [],
  );

  const handleTargetFilterChange = useCallback(
    (filters: ITextValue[]): void => {
      Promise.resolve(map('value', filters) as string[]).then(
        (filterValueList: string[]): void => {
          let filterValueListCopy = filterValueList;
          if (businessRules.hasSpatialDimensions && !targetList.length) {
            const first = head(targets)?.id ?? '';
            filterValueListCopy = [first];
          }
          setReportSelectedTargets(filterValueListCopy);
          setTargetList(filterValueListCopy);
          setDisplayChartData({
            ...displayChartData,
            SGMConfig: {
              ...displayChartData.SGMConfig,
              meta: {
                ...displayChartData.SGMConfig.meta,
                targetList: filterValueListCopy,
              },
            },
          });
        },
      );
    },
    [
      businessRules.hasSpatialDimensions,
      displayChartData,
      setDisplayChartData,
      setReportSelectedTargets,
      targetList.length,
      targets,
    ],
  );

  const getMetaDataFromPivotHeaders = (
    headers: Header[],
    metadata: IMetaData[],
  ): IMetaData[] =>
    headers.map((header: Header) => {
      let foundMeta = metadata.find((meta) => meta.id === header.Header);
      if (!foundMeta) {
        foundMeta = {
          display: true,
          id: header.Header ?? '',
          name: header.Header ?? '',
          description: '',
          short_name: header.Header,
          type: 'String',
        };
      }
      return {
        ...foundMeta,
        accessor: header.accessor ?? header.Header,
      };
    });
  let filteredSelectedCharts = reportSelectedCharts;
  const filteredSelectedMetrics = reportSelectedMetrics.filter(
    (metric: string, index: number) => {
      if (displayChartData?.select?.includes(metric)) return true;
      filteredSelectedCharts = pullAt(index, filteredSelectedCharts);
      return false;
    },
  );

  const allViewingMaxNumber = isAllViewing ? 10 : 0;
  const rowNumberConverted = (
    isNumber(reportRowLimit) ? reportRowLimit : allViewingMaxNumber
  ).toString();

  const onChangeFilters = useCallback(
    (filters: ChartFilterType[]) => handleChangeFilters(filters),
    [handleChangeFilters],
  );

  const queryTableData: DataRowGraph[] = unlinkedHeaders
    ? tableData.data
    : originalData;

  const queryTableMetadata: IMetaData[] =
    unlinkedHeaders && tableData.headers
      ? getMetaDataFromPivotHeaders(
          tableData.headers,
          displayChartData.metadata,
        )
      : displayChartData.metadata;

  const selectedTargetList = getSelectedTargetList();
  const metaTargetList = (
    businessRules?.multiSeries ? selectedTargetList[0] : selectedTargetList
  ) as string[];

  return (
    <>
      {!isLoading && chartData.data && (
        <div className={reportFormResultsChartClass}>
          {hasMultiValueReportData && (
            <>
              {businessRules.canShowGroupFilter && (
                <GroupFilter
                  metadata={displayChartData.metadata}
                  dimensions={displayChartData.dimensions}
                  series={SGMConfig.series}
                  onChange={(newSeries: string[]): void => {
                    const newSGM = {
                      ...SGMConfig,
                      series: newSeries,
                    };
                    newSGM.groups = getGroupsFromQuery(
                      query,
                      newSGM,
                      isTargetGroup,
                    );
                    setDisplaySGMConfig(newSGM);
                  }}
                />
              )}
              <Flex vertical={Vertical.top} horizontal={Horizontal.between}>
                <ChartFilter
                  onChangeFilters={onChangeFilters}
                  metrics={(displayChartData.select as Metric[]) || []}
                  selectedCharts={filteredSelectedCharts}
                  selectedMetrics={filteredSelectedMetrics}
                  selectedDimensions={displayChartData.dimensions}
                  metadata={displayChartData.metadata}
                  businessRules={businessRules}
                  allowMoreFilters={allowMoreFilters}
                />
                {!!totalRowCount && isAllViewing ? (
                  <div className="row-limit-wrapper">
                    <Field
                      disabled={isLoading}
                      label={`${locale.ROW_LIMIT_LABEL}${
                        totalRowCount ? ` (Total: ${intervalCount})` : ''
                      }`}
                      onChange={handleRowLimitChange}
                      options={rowLimitOptionList}
                      placeholder={locale.ROW_LIMIT_PLACEHOLDER}
                      testId="row-limit"
                      type={Type.select}
                      value={rowNumberConverted}
                    />
                  </div>
                ) : (
                  []
                )}
              </Flex>
              {businessRules.isMultiTarget ? (
                <TargetFilter
                  onChange={handleTargetFilterChange}
                  selectedTargetList={selectedTargetList}
                  optionList={queryTargetList}
                  businessRules={businessRules}
                  canReorder={false}
                  hasInterval={resultHasInterval}
                />
              ) : (
                []
              )}

              <div />
              {businessRules.isMultiChart || businessRules.isMultiTarget ? (
                <>
                  {displayChartData.SGMConfig.metrics.map((metricConfig) => {
                    const newSGMConfig = {
                      ...displayChartData.SGMConfig,
                      ...(isAllViewing && {
                        // If we have selected "All Viewing" for the
                        // interval, e.g. no interval, then we want to group
                        // the x-axis by the combindation of dimensions
                        // (SGMConfig.groups) and not also by combination of
                        // the targets i.e., if we have a targets named T1
                        // and T2 and dimensions/SGMConfig.groups named D1
                        // and D2 our x-axis categories (e.g. uniqColumns)
                        // should be:
                        // "D1, D2" and not "T1, D1, D2"/"T2, D1, D2"
                        groups: reject(
                          isEqual('TARGET'),
                          displayChartData.SGMConfig.groups,
                        ),
                        series: ['TARGET'],
                      }),
                      meta: {
                        ...displayChartData.SGMConfig.meta,
                        ...selectedTargetList,
                        targetList: metaTargetList,
                      },
                      metrics: [
                        {
                          ...metricConfig,
                        },
                      ],
                    };
                    return (
                      <Chart
                        key={metricConfig.metric}
                        data={displayChartData.data}
                        metadata={displayChartData.metadata}
                        SGMConfig={newSGMConfig}
                        dateRange={chartData?.dateRange}
                      />
                    );
                  })}
                </>
              ) : (
                <Chart
                  data={displayChartData.data}
                  metadata={displayChartData.metadata}
                  SGMConfig={displayChartData.SGMConfig}
                  dateRange={chartData?.dateRange}
                />
              )}
            </>
          )}
          {chartData.data.length > 10000 && (
            <div className={reportFormTooMuchDataClass}>
              <Flex vertical={Vertical.middle} horizontal={Horizontal.center}>
                {locale.TOO_MUCH_DATA}
              </Flex>
            </div>
          )}
          {originalData && tableVisible ? (
            <QueryTable
              data={queryTableData}
              metadata={queryTableMetadata}
              overrideHeaders={unlinkedHeaders}
              pagination
            />
          ) : (
            []
          )}
        </div>
      )}
      {!isLoading && !displayChartData.data && (
        <div className={reportFormNoDataClass}>
          <Flex vertical={Vertical.top} horizontal={Horizontal.center}>
            {locale.NO_CHART_DATA}
          </Flex>
        </div>
      )}
      {!isLoading && !tableVisible && !chartVisible && (
        <div className={reportFormNoDataClass}>
          <Flex vertical={Vertical.top} horizontal={Horizontal.center}>
            {locale.DATA_READY_TO_EXPORT}
          </Flex>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: State): OwnState => ({
  chartData: resultSelectors.getResultChartData(get('reportResult', state)),
  displayChartData: resultSelectors.getDisplayChartData(
    get('reportResult', state),
  ),
  isAllViewing: resultSelectors.getIsAllViewing(get('reportResult', state)),
  isTarget: reportSelectors.isTarget(state),
  reportRowLimit: reportSelectors.getRowLimit(get('report', state)),
  reportSelectedCharts: reportSelectors.getSelectedCharts(get('report', state)),
  reportSelectedMetrics: reportSelectors.getSelectedMetrics(
    get('report', state),
  ),
  reportSelectedTargets: reportSelectors.getSelectedTargets(
    get('report', state),
  ),
  SGMConfig: resultSelectors.getDisplaySGMConfig(get('reportResult', state)),
  targets: businessDataSelectors.getAvailableTargets(state),
  totalRowCount: resultSelectors.getRowCount(get('reportResult', state)),
  allowMoreFilters: resultSelectors.allowMoreFilters(state),
});

const mapDispatchToProps: OwnActions = {
  resetReportResult: resultActions.resetReportResult,
  setDisplayChartData: resultActions.setDisplayChartData,
  setDisplaySGMConfig: resultActions.setSGMConfig,
  setReportRowLimit: reportActions.setRowLimit,
  setReportSelectedTargets: reportActions.setSelectedTargets,
  handleRowLimitChange: reportActions.handleRowLimitChange,
  handleChangeFilters: reportActions.handleChangeFilters,
  getTableData: resultActions.getTableData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportResultsForm);
