import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Field from 'components/Field';
import { Type as InputType } from 'components/Input';
import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';
import Button, { Kind, Type as ButtonType } from 'components/Button';
import { useNavigate } from 'react-router-dom';
import Flex, { Horizontal } from 'components/Flex';
import { Index } from 'routes';
import { startCase } from 'lodash/fp';
import { lowerCase } from 'lodash';
import Icon, { Color, Type as IconType } from 'components/Icon';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import { toTextValuePairs } from 'helpers/types';
import { ICrossWalkCombinationProps } from './types';
import {
  canUserSave,
  changeHandler,
  handleMultiSelectChange,
  postCrosswalkCombination,
} from './adapters';
import { validationsSchemaBuilder } from './validationSchema';
import {
  CANCEL_BUTTON_LABEL,
  CLIENT_LABEL,
  CROSSWALK_LABEL,
  CROSSWALK_REQUIRED_ERROR_MESSAGE,
  DATASET_ID_LABEL,
  DATASET_NAME_LABEL,
  DATASET_NAME_PLACEHOLDER,
  DATASET_NAME_REQUIRED_ERROR_MESSAGE,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
  FILE_TYPE_LABEL,
  SAVE_BUTTON_LABEL,
  UNIVERSE_LABEL,
  UNIVERSE_REQUIRED_ERROR_MESSAGE,
} from './locale';

export const CrosswalkCombination = ({
  crosswalkCombinationDetails,
  clients,
  crosswalkSources,
  fetchDomains,
}: ICrossWalkCombinationProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [duplicatedNameError, setDuplicatedNameError] = useState<string>('');

  return (
    <div className="crosswalk-combination-form">
      <Formik
        initialValues={crosswalkCombinationDetails}
        onSubmit={(values) =>
          postCrosswalkCombination(
            values,
            navigate,
            setLoading,
            fetchDomains,
            setDuplicatedNameError,
          )
        }
        validationSchema={validationsSchemaBuilder()}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          handleBlur,
          dirty,
          setTouched,
        }) => (
          <Form>
            <QueryBuilderItem
              label={FILE_TYPE_LABEL}
              id="type"
              testId="type"
              labelAlignment="top"
            >
              <Field
                type={InputType.select}
                id="type"
                testId="type"
                value={values.type}
                onChange={changeHandler(handleChange, 'type')}
                onBlur={handleBlur}
                name="type"
                disabled
                options={[
                  {
                    text: startCase(lowerCase(values.type)),
                    value: values.type,
                  },
                ]}
              />
            </QueryBuilderItem>
            <QueryBuilderItem
              label={DATASET_NAME_LABEL}
              id="name"
              testId="name"
              labelAlignment="top"
            >
              <Field
                type={InputType.text}
                id="name"
                testId="name"
                disabled={loading}
                placeholder={DATASET_NAME_PLACEHOLDER}
                onChange={changeHandler(
                  handleChange,
                  'name',
                  setDuplicatedNameError,
                )}
                onBlur={handleBlur}
                value={values.name}
                errorMessage={
                  duplicatedNameError || DATASET_NAME_REQUIRED_ERROR_MESSAGE
                }
                hasError={
                  !!(touched.name && errors.name) || !!duplicatedNameError
                }
                name="name"
              />
            </QueryBuilderItem>
            <QueryBuilderItem
              label={DATASET_ID_LABEL}
              id="dataset_id"
              testId="dataset_id"
              labelAlignment="top"
            >
              <Field
                type={InputType.text}
                id="dataset_id"
                testId="dataset_id"
                value={values.dataset_id}
                onChange={changeHandler(handleChange, 'dataset_id')}
                name="dataset_id"
                onBlur={handleBlur}
                disabled
              />
            </QueryBuilderItem>
            <QueryBuilderItem
              label={CLIENT_LABEL}
              id="client"
              testId="client"
              labelAlignment="top"
            >
              <MultipleDropdownSelect
                name="clients"
                testId="clients"
                disabled={loading}
                placeholder={CLIENT_LABEL}
                onBlur={handleBlur}
                selected={values.clients}
                withSearch
                options={toTextValuePairs(clients)}
                onChange={handleMultiSelectChange(
                  handleChange,
                  touched,
                  setTouched,
                  'clients',
                )}
              />
            </QueryBuilderItem>
            <div className="margin-top-30">
              <QueryBuilderItem
                label={CROSSWALK_LABEL}
                id="crosswalk_combinations"
                testId="crosswalk_combinations"
                labelAlignment="top"
              >
                <MultipleDropdownSelect
                  name="crosswalk_combinations"
                  testId="crosswalk_combinations"
                  disabled={loading}
                  placeholder={CROSSWALK_LABEL}
                  onBlur={handleBlur}
                  selected={values.crosswalk_combinations}
                  withSearch
                  options={toTextValuePairs(crosswalkSources)}
                  onChange={handleMultiSelectChange(
                    handleChange,
                    touched,
                    setTouched,
                    'crosswalk_combinations',
                  )}
                  errorText={CROSSWALK_REQUIRED_ERROR_MESSAGE}
                  isReportInvalid={
                    !!(
                      errors.crosswalk_combinations &&
                      touched.crosswalk_combinations
                    )
                  }
                />
              </QueryBuilderItem>
            </div>
            <div className="margin-top-30">
              <QueryBuilderItem
                label={UNIVERSE_LABEL}
                id="universe_label"
                testId="universe_label"
                labelAlignment="top"
              >
                <MultipleDropdownSelect
                  name="universe_label"
                  testId="universe_label"
                  disabled={loading}
                  placeholder={UNIVERSE_LABEL}
                  onBlur={handleBlur}
                  selected={values.universe_label}
                  withSearch
                  options={[
                    {
                      text: 'All US TV Households',
                      value: 'All US TV Households',
                    },
                    {
                      text: 'All US Households',
                      value: 'All US Households',
                    },
                  ]}
                  maximumChipsSelected={1}
                  onChange={handleMultiSelectChange(
                    handleChange,
                    touched,
                    setTouched,
                    'universe_label',
                  )}
                  errorText={UNIVERSE_REQUIRED_ERROR_MESSAGE}
                  isReportInvalid={!!errors.universe_label}
                />
              </QueryBuilderItem>
            </div>
            <QueryBuilderItem
              label={DESCRIPTION_LABEL}
              id="description"
              testId="description"
              labelAlignment="top"
            >
              <Field
                type={InputType.text}
                id="description"
                testId="description"
                disabled={loading}
                onBlur={handleBlur}
                placeholder={DESCRIPTION_PLACEHOLDER}
                value={values.description}
                name="description"
                onChange={changeHandler(handleChange, 'description')}
              />
            </QueryBuilderItem>
            <Flex horizontal={Horizontal.between} className="mt-2">
              <Button
                type={ButtonType.button}
                kind={Kind.outline}
                onClick={() =>
                  navigate(`${Index.SEGMENT_ADMIN}/${Index.SEGMENT_UPLOADS}`)
                }
              >
                {CANCEL_BUTTON_LABEL}
              </Button>
              <Button
                type={ButtonType.submit}
                kind={Kind.primary}
                disabled={!canUserSave(errors, dirty, loading)}
              >
                {loading ? (
                  <Icon color={Color.primary} type={IconType.loading} />
                ) : (
                  SAVE_BUTTON_LABEL
                )}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </div>
  );
};
