import { getTimezoneOffset } from 'date-fns-tz';
import {
  ChartType,
  ChartTypes,
  EChartTypes,
  timezones,
} from '../../../domains/reports/types';
import { colorChart, colorChartSeries } from '../../../theme/605/colors';

const lineCharts: ChartType[] = [
  ChartTypes.line,
  ChartTypes.linePercentageStacked,
  ChartTypes.lineStacked,
];

const mapCharts: ChartType[] = [ChartTypes.map];

const pieCharts: ChartType[] = [ChartTypes.pie];

const columnCharts: ChartType[] = [
  ChartTypes.column,
  ChartTypes.columnStacked,
  ChartTypes.columnPercentageStacked,
];

const barCharts: ChartType[] = [
  ChartTypes.bar,
  ChartTypes.barPercentageStacked,
  ChartTypes.barStacked,
];

const areaCharts: ChartType[] = [
  ChartTypes.area,
  ChartTypes.areaStacked,
  ChartTypes.areaPercentageStacked,
  ChartTypes.areaspline,
];

const heatmapCharts: ChartType[] = [ChartTypes.heatmap];

const packedbubbleCharts: ChartType[] = [ChartTypes.packedbubble];

export const chartTypeBasic = {
  bar: 'bar',
  pie: 'pie',
  map: 'map',
  column: 'column',
  area: 'area',
  line: 'line',
  heatmap: 'heatmap',
  packedbubble: 'packedbubble',
};

export const getChartColors = (isSeries: boolean): string[] => {
  const colorChartIndex = isSeries ? colorChartSeries : colorChart;
  return [
    colorChartIndex.bar1,
    colorChartIndex.bar2,
    colorChartIndex.bar3,
    colorChartIndex.bar4,
    colorChartIndex.bar5,
    colorChartIndex.bar6,
    colorChartIndex.bar7,
    colorChartIndex.bar8,
  ];
};

export const ChartColors = [
  colorChart.bar1,
  colorChart.bar2,
  colorChart.bar3,
  colorChart.bar4,
  colorChart.bar5,
  colorChart.bar6,
  colorChart.bar7,
  colorChart.bar8,
];

export type ChartTypeBasic =
  (typeof chartTypeBasic)[keyof typeof chartTypeBasic];

export const isBarChart = (type?: ChartType): boolean =>
  barCharts.includes(type as ChartType);

export const isPieChart = (type?: ChartType): boolean =>
  pieCharts.includes(type as ChartType);

export const isMapChart = (type?: ChartType): boolean =>
  mapCharts.includes(type as ChartType);

export const isColumnChart = (type?: ChartType): boolean =>
  columnCharts.includes(type as ChartType);

export const isAreaChart = (type?: ChartType): boolean =>
  areaCharts.includes(type as ChartType);

export const isLineChart = (type?: ChartType): boolean =>
  lineCharts.includes(type as ChartType);

export const isHeatmap = (type?: ChartType): boolean =>
  heatmapCharts.includes(type as ChartType);

export const isPackedbubble = (type?: ChartType): boolean =>
  packedbubbleCharts.includes(type as ChartType);

export const getChartBasicType = (type: ChartType): EChartTypes => {
  if (isBarChart(type)) {
    return EChartTypes.bar;
  }
  if (isPieChart(type)) {
    return EChartTypes.pie;
  }
  if (isMapChart(type)) {
    return EChartTypes.map;
  }
  if (isColumnChart(type)) {
    return EChartTypes.column;
  }
  if (isAreaChart(type)) {
    return EChartTypes.area;
  }
  if (isLineChart(type)) {
    return EChartTypes.line;
  }
  if (isHeatmap(type)) {
    return EChartTypes.heatmap;
  }
  if (isPackedbubble(type)) {
    return EChartTypes.packedbubble;
  }
  return EChartTypes.column;
};

export const getChartTimezoneOffset = (timestamp: number): number =>
  -getTimezoneOffset(timezones.americaNewYork, timestamp) / 60000;
