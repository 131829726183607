import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';
import { IIconProps } from './types';

export const iconComponentName = 'icon';

const Icon: FunctionComponent<IIconProps> = (props): ReactElement => {
  const { testId, type, color, size, customClass } = props;

  const iconTestId = getTestId(iconComponentName, testId);
  const iconClass = getClass(iconComponentName, {
    text: [type, color, size, customClass],
  });

  return <span className={iconClass} data-testid={iconTestId} />;
};

export default Icon;
export * from './types';
