import { IDataset, IDatasetDetails } from 'domains/datasets/types';
import IDimension from 'domains/dimensions/types';
import IReport, { DynamicBreakouts } from 'domains/reports/types';
import { fetchApi } from 'helpers/fetching';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Actions, Index } from 'routes';
import * as domainSelectors from 'store/selectors/domains';
import * as domainActions from 'store/actions/domains';
import * as reportSelectors from 'store/selectors/report';
import FetchMethod from 'types/fetchMethod';
import State from 'types/state';
import * as reportReducer from '../../reducers/report';
import {
  setReportQueryGenericEventsDatasets,
  setReportAction,
  resetCustomExposureSegmentRules,
  setReportDynamicBreakouts,
  resetReportDynamicBreakouts,
  setReportPerformanceMetricGroups,
  setReportConversionType,
} from './index';
import { Modes } from 'types/query';

export const setReportGenericEventsDatasetsAction =
  (dataset: string, updatedReport?: IReport, preserveSelectedDates = false) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<IReport | undefined> => {
    dispatch(resetCustomExposureSegmentRules());
    dispatch(resetReportDynamicBreakouts());
    const availableDatasets = domainSelectors.getDatasets(getState()) ?? [];
    const selectedDataset = availableDatasets.find(
      (dimension: IDataset) => dimension.id === dataset,
    );

    const datasetMongoId = selectedDataset?.id;

    dispatch(
      setReportQueryGenericEventsDatasets(
        selectedDataset ? [selectedDataset] : [],
      ),
    );

    if (!datasetMongoId) {
      return;
    }

    const state = getState();
    let report = updatedReport ?? reportSelectors.getReport(state);

    if (!report) return;

    const apiResults = await fetchApi({
      endpoint: `/${Index.SEGMENT_DATASETS}/${Actions.SEGMENT_CUSTOM_DATASET_DATA}?generic_events_dataset_ids=${datasetMongoId}`,
      method: FetchMethod.GET,
    });
    const domainsDateRanges = domainSelectors.getDomainsDateRanges(state);

    const {
      min_date,
      max_date,
      dimensions,
      performance_metric_groups,
      conversion_type,
    } = apiResults.data as unknown as IDatasetDetails;

    dispatch(
      domainActions.setDomainsDateRanges({
        ...domainsDateRanges,
        [Modes.genericEvents]: { min_date, max_date },
      }),
    );

    if (!preserveSelectedDates) {
      report = reportReducer.setExposureFlightDateStart(min_date, report);
      report = reportReducer.setExposureFlightDateEnd(max_date, report);
    }

    // Intentionally suppress Brand breakout
    const dynamicBreakouts = (dimensions ?? [])
      .filter(({ id }) => id !== 'BRAND_LABEL')
      .map((dimension: IDimension) => ({
        id: dimension?.id,
        name: dimension?.name,
      })) as DynamicBreakouts;
    await dispatch(setReportAction(report));
    dispatch(setReportDynamicBreakouts(dynamicBreakouts));

    dispatch(setReportPerformanceMetricGroups(performance_metric_groups ?? []));
    dispatch(setReportConversionType(conversion_type ?? ''));
    return report;
  };
