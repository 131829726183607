import Field from 'components/Field';
import { Type as InputType } from 'components/Input';
import React, { FunctionComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import * as configOptionsActions from 'store/actions/configOptions';
import { IUpdateConfigOptionValue } from 'domains/configOptions/types';
import Icon from 'components/Icon';
import { Type as IconType, Color as IconColor } from 'components/Icon/types';
import Button, { Kind } from 'components/Button';
import {
  LOCALE_EMPTY_TEXT_FIELD_ERROR,
  LOCALE_EMPTY_VALUE_FIELD_ERROR,
} from '../../locale';

interface IProps {
  text: string;
  value: string;
  index: number;
  canDelete: boolean;
  canMoveUp: boolean;
  canMoveDown: boolean;
  hasValueError: boolean;
  hasTextError: boolean;
}

interface IConnectedProps {
  updateConfigOptionValueItem: (payload: IUpdateConfigOptionValue) => void;
  removeConfigOptionValueItem: (index: number) => void;
  toggleConfigOptionValueItemPosition: (pos1: number, pos2: number) => void;
}

export const formComponentName = 'config-option-value-card';
export const editConfigOptionComponentName = 'edit-config-option';

const ValueCard: FunctionComponent<IProps & IConnectedProps> = (
  props,
): ReactElement => {
  const {
    text,
    value,
    index,
    canDelete,
    canMoveUp,
    canMoveDown,
    hasValueError,
    hasTextError,
    updateConfigOptionValueItem,
    removeConfigOptionValueItem,
    toggleConfigOptionValueItemPosition,
  } = props;

  return (
    <div className="valueCardWrapper">
      <div className="deleteContainer">
        <Button
          kind={Kind.text}
          onClick={() => removeConfigOptionValueItem(index)}
          disabled={!canDelete}
        >
          <Icon type={IconType.trash} color={IconColor.primary} />
        </Button>
      </div>
      <div className="changeOrderContainer">
        <div className="arrow arrow-up">
          <Button
            kind={Kind.text}
            onClick={() =>
              toggleConfigOptionValueItemPosition(index, index - 1)
            }
            disabled={!canMoveUp}
          >
            <Icon type={IconType.rightArrow} color={IconColor.primary} />
          </Button>
        </div>
        <div className="arrow arrow-down">
          <Button
            kind={Kind.text}
            onClick={() =>
              toggleConfigOptionValueItemPosition(index, index + 1)
            }
            disabled={!canMoveDown}
          >
            <Icon type={IconType.rightArrow} color={IconColor.primary} />
          </Button>
        </div>
      </div>
      <Field
        label="Text"
        type={InputType.text}
        onChange={(newValue) =>
          updateConfigOptionValueItem({
            field: 'text',
            index,
            value: newValue as string,
          })
        }
        value={text}
        hasError={hasTextError}
        errorMessage={LOCALE_EMPTY_TEXT_FIELD_ERROR}
      />
      <Field
        label="Value"
        type={InputType.text}
        onChange={(newValue) =>
          updateConfigOptionValueItem({
            field: 'value',
            index,
            value: newValue as string,
          })
        }
        value={value}
        hasError={hasValueError}
        errorMessage={LOCALE_EMPTY_VALUE_FIELD_ERROR}
      />
    </div>
  );
};

const mapDispatchToProps: IConnectedProps = {
  updateConfigOptionValueItem: configOptionsActions.updateConfigOptionValueItem,
  removeConfigOptionValueItem: configOptionsActions.removeConfigOptionValueItem,
  toggleConfigOptionValueItemPosition:
    configOptionsActions.toggleConfigOptionValueItemPosition,
};

export default connect(null, mapDispatchToProps)(ValueCard);
