import React, { Reducer } from 'react';
import { clientValidationErrors } from 'types/error';

type HookReturn = {
  handleError: (message?: string) => void;
  resetExtIDError: () => void;
  resetNameError: () => void;
  extIDError: boolean;
  nameError: boolean;
};

type State = { extIDError: boolean; nameError: boolean };

type Action =
  | { type: 'duplicated-ext-id-error' }
  | { type: 'duplicated-name-error' }
  | { type: 'duplicated-ext-id-error-reset' }
  | { type: 'duplicated-name-error-reset' };

const initState = {
  extIDError: false,
  nameError: false,
};

const reducer = (s: State, a: Action): State => {
  switch (a.type) {
    case 'duplicated-ext-id-error':
      return { ...s, extIDError: true };
    case 'duplicated-name-error':
      return { ...s, nameError: true };
    case 'duplicated-ext-id-error-reset':
      return { ...s, extIDError: false };
    case 'duplicated-name-error-reset':
      return { ...s, nameError: false };
    default:
      return s;
  }
};

const useClientErrors = (): HookReturn => {
  const [state, dispatch] = React.useReducer(
    reducer as Reducer<State, Action>,
    initState as State,
  );

  const handleError = (message?: string): void => {
    if (message === clientValidationErrors.duplicatedExtID) {
      dispatch({ type: 'duplicated-ext-id-error' });
    }

    if (message === clientValidationErrors.duplicatedName) {
      dispatch({ type: 'duplicated-name-error' });
    }
  };

  const resetExtIDError = (): void =>
    dispatch({ type: 'duplicated-ext-id-error-reset' });

  const resetNameError = (): void =>
    dispatch({ type: 'duplicated-name-error-reset' });

  const hookReturnData: HookReturn = {
    handleError,
    resetExtIDError,
    resetNameError,
    extIDError: state.extIDError,
    nameError: state.nameError,
  };
  return hookReturnData;
};

export default useClientErrors;
