export const CAMPAIGN_EXPOSURE_IMPRESSION_FREQUENCY_TOOLTIP_TEXT: string = `Use this plot to help you identify the optimal campaign frequency. Look for trends where the "Cumulative % of exposed converted households" is much larger than the "Cumulative % of exposed households". This indicates that you are driving, proportionally, many more conversions at that frequency.

Marrying these high converting frequencies with your point of diminishing returns (this is the point where the "cumulative % of exposed converted households" plot starts leveling out) will help you hone in on the optimal frequency.

The median of the Cumulative % of Exposed Converted Households is indicated to help you identify the frequency at which half of your converters reacted.

Before reducing a future plan to a frequency below this line, dig into why households past this needed more impressions to convert so that you do not lose them with any frequency reduction adjustments.`;

export const CAMPAIGN_EXPOSURE_EXPOSURE_TO_CONVERSATION_LATENCY_TOOLTIP_TEXT: string = `Use this plot to help you identify the optimal ad latency to drive conversions on specific days. Look for where the plot starts to level off.

This indicates that you are starting to see minimal gains at latencies larger than that point. The median is indicated to help you identify the latency at which half of your converters reacted.

Before tailoring a future campaign with a new latency that is less than this line, dig into why households past this needed more a longer time to convert so that you do not lose them with any adjustments.

Note: This plot only displays converters up to 25 days after exposure. Some households may convert after 25 days.`;
