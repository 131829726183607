import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';

export const errorComponentName = 'error';

export interface IErrorProps {
  message?: string;
  children?: ReactNode;
  testId?: string;
}

const Error: FunctionComponent<IErrorProps> = (props): ReactElement => {
  const { message, children, testId } = props;

  const errorTestId = getTestId(errorComponentName, testId);
  const errorClass = getClass(errorComponentName);

  return (
    <p className={errorClass} data-testid={errorTestId}>
      {message}
      {children && children}
    </p>
  );
};

export default Error;
