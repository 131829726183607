import { DatasetSubType } from '../domains/datasets/types';
import ITextValue from './textValue';

export type TAttributesConfig = {
  notes?: string;
  source_col_type?: string;
  id: string;
  description?: string;
  source_col: string;
  dest_col_type?: string;
  mapping?: string;
  name: string;
  path: string;
};

export type TUploadFormFallback = {
  dataset: string;
  category?: ITextValue[];
  chain?: ITextValue[];
};
export interface IUploadFormDatasetMetadata {
  attributes: TAttributesConfig[];
  vendor_name: string;
  fallback?: TUploadFormFallback;
  source_path?: string;
  subType?: string;
  conversion_type?: string;
  performance_metric_groups?: string[];
  dataset_path?: string;
  max_event_date: string;
  min_event_date: string;
}
export interface IUploadForm {
  status?: string;
  dataset_id?: string;
  version: number;
  fileType: string;
  name?: string;
  display_name: string;
  s3Source: boolean;
  s3Path: string;
  crosswalk_combination?: ITextValue[];
  clientsWithAccess: ITextValue[];
  dataset_metadata: IUploadFormDatasetMetadata;
}

export interface IFormUpdatedPayload {
  key: string;
  value: string | boolean | number | ITextValue[] | TAttributesConfig[];
}

export interface IUploadsState {
  [key: string]: {} | string | number | null;
}

export interface IUploaderCallback {
  sent: number;
  total: number;
  percentage: number;
}

export type TBaseConfig = {
  version: number;
  dataset_id: number | string;
  dataset_name: string;
  client_id: string | number;
  vendor_name: string;
  source_path: string;
  ingest_type?: string;
};

export type TFallback = {
  dataset: string;
  category: (string | number)[];
  chain: (string | number)[];
} | null;

export type TReturnFull = TBaseConfig & {
  name: string;
  path: string;
  attributes: TAttributesConfig[];
  fallback: TFallback | null;
};

export type TDatasetMetadata = {
  id: number;
  source_path: string; // source_path of latest dataset file version
  version: number; // metadata version (increments for every ingest operation)
  type: string;
  sub_type?: DatasetSubType; // sub_type only for datasets of type GERENIC_EVENTS: value can be exposure or conversion
  manual: boolean; // from dataset_metadata.type_metadata field. 'false' is AU Segment, 'true' manually uploaded segments
  vendor_name: string; // value from 'Data Provider' UI field
  lines?: number; // or timestamp. Schedule Only
  min_event_date?: string; // or timestamp. Schedule Only
  max_event_date?: string; // or timestamp. Schedule Only
  attributes: TAttributesConfig[];
  fallback: TFallback;
  crosswalk_combination: string[][];
  performance_metric_groups: string[];
  conversion_type: string;
  dataset_path?: string;
};

export type TDatasetInfo = {
  dataset_id: string;
  status: string;
  ingest_message: string[];
  ingest_type: string;
  dataset_metadata: TDatasetMetadata;
};

export type TReturn = TBaseConfig | TReturnFull;

export enum UploadStatus {
  validated = 'Validated',
  completed = 'Completed',
  failed = 'Failed',
  inProgress = 'In Progress',
}
