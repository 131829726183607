import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from '605-react-date-range';
import Flex, { Direction, Horizontal, Vertical } from 'components/Flex';
import Icon, {
  Color,
  Size as IconSize,
  Type as IconType,
} from 'components/Icon';
import Input, { Type } from 'components/Input';
import { Placement } from 'components/Sticker';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { Style, Tooltip } from 'components/Tooltip';
import { addDays, parseISO, startOfDay } from 'date-fns';
import { getClass, getId, getTestId } from 'helpers/components';
import { BUTTONS } from 'helpers/datepicker';
import noop from 'lodash/noop';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { colorDatePicker } from 'theme/605/colors';
import useOnOutsideClick from '../../hooks/useOnOutsideClick';
import { DatePresets } from './components/DatePresets';
import { SmartSelection } from './components/SmartSelection';
import { DATE_PICKER_COMPONENT_NAME } from './constants';
import { getNumberOfDaysBetweenTwoDays } from './helpers';
import {
  Anchor,
  FocusedInput,
  IDatePickerProps,
  LastUpdatedDate,
} from './interface';
import { useDatePicker } from './use-datepicker.hook';

const LOCALE_SELECT_RANGE = 'Please select a date range';
const LOCALE_MOVING_DATES_ENABLED = 'Moving Dates Enabled';
const DEFAULT_ANCHOR = Anchor.left;

const TEST_ID_DATE_START = 'date-start';
const TEST_ID_DATE_END = 'date-end';

const dateStartTestId = getTestId(
  DATE_PICKER_COMPONENT_NAME,
  TEST_ID_DATE_START,
);
const dateEndTestId = getTestId(DATE_PICKER_COMPONENT_NAME, TEST_ID_DATE_END);

const DatePicker: FunctionComponent<IDatePickerProps> = ({
  id,
  testId,
  disabled = false,
  startDate: storedStartDate,
  endDate: storedEndDate,
  relativeDateOffset,
  limitToRange,
  handleChange,
  trackingId,
  minDate: minDateString,
  maxDate: maxDateString,
  showDatePresets = false,
  showSmartSelection = true,
  showFloating = true,
  anchor = DEFAULT_ANCHOR,
  showNumOfDays,
  footerMessage,
  movingDates = false,
}: IDatePickerProps): ReactElement => {
  const {
    proposedStartDate,
    proposedEndDate,
    focusedInput,
    lastUpdatedDate,
    datePickerState,
    isActiveSmartSelection,
    handleRangeChange,
    handleSmartSelection,
    handleChangeDateRangePicker,
    changeInput,
    focusOutInput,
    handleKeyEnterInput,
    datePickerMinDate,
    dispatch,
  } = useDatePicker({
    startDate: storedStartDate,
    endDate: storedEndDate,
    disabled,
    handleChange,
    minDate: minDateString,
    maxDate: maxDateString,
    relativeDateOffset,
    limitToRange,
    trackingId,
  });

  const maxDate = maxDateString
    ? startOfDay(parseISO(maxDateString))
    : undefined;
  const minDate = minDateString
    ? startOfDay(parseISO(minDateString))
    : undefined;
  const dateMaxDatePlusOneDay = maxDate ? addDays(maxDate, 1) : undefined;

  const componentId = useMemo(
    () => getId(DATE_PICKER_COMPONENT_NAME, id),
    [id],
  );
  const componentTestId = useMemo(
    () => getTestId(DATE_PICKER_COMPONENT_NAME, testId),
    [testId],
  );
  const componentClass = useMemo(
    () =>
      getClass(DATE_PICKER_COMPONENT_NAME, {
        boolean: [
          {
            state: disabled,
            class: 'disabled',
          },
        ],
      }),
    [disabled],
  );
  const datepickerClass = useMemo(
    () =>
      getClass(DATE_PICKER_COMPONENT_NAME, {
        concat: ['wrapper'],
        boolean: [{ class: 'floating', state: showFloating }],
      }),
    [showFloating],
  );

  const customDayContentRenderer = useMemo(() => {
    if (!isActiveSmartSelection) return undefined;
    return (day: string) => (
      <Tooltip
        style={Style.secondary}
        content="To use Moving Dates, select a date range preset."
        placement={Placement.top}
      >
        <span>{day}</span>
      </Tooltip>
    );
  }, [isActiveSmartSelection]);

  const ref = useRef<HTMLDivElement>(null);

  useOnOutsideClick(
    ref,
    useCallback(() => dispatch({ focusedInput: undefined }), [dispatch]),
  );

  const hidePresetsClass = !showDatePresets ? 'hide-presets' : '';
  const anchorClass = `anchor-${anchor}`;
  const lastUpdate = lastUpdatedDate as number;
  const focusedRange =
    lastUpdate === LastUpdatedDate.notUpdated
      ? (focusedInput as number)
      : lastUpdate;

  const calendarComponent = focusedInput !== undefined && (
    <>
      <Flex
        direction={Direction.row}
        horizontal={Horizontal.left}
        vertical={Vertical.top}
        className={`${datepickerClass} ${hidePresetsClass} ${anchorClass}`}
      >
        <div className={`calendar-wrapper ${hidePresetsClass}`}>
          {showDatePresets && (
            <DatePresets
              handleRangeChange={handleRangeChange}
              maxDate={dateMaxDatePlusOneDay?.toISOString()}
              minDate={minDateString}
              startDate={storedStartDate}
              endDate={storedEndDate}
            />
          )}
          <DateRangePicker
            rangeColors={[colorDatePicker.range1]}
            data-lpignore="true"
            minDate={datePickerMinDate}
            maxDate={maxDate}
            focusedRange={[0, focusedRange]}
            dragSelectionEnabled={false}
            onChange={handleChangeDateRangePicker}
            className={hidePresetsClass}
            months={2}
            ranges={datePickerState}
            direction="horizontal"
            showSelectionPreview
            showPreview={false}
            showDateDisplay={false}
            broadcastCalendar
            smartSelection={isActiveSmartSelection}
            dayContentRenderer={customDayContentRenderer}
          />
          <div className="smart-selection-wrapper">
            {movingDates && (
              <div className="switch-wrapper">
                <span className="switch-label">Moving Dates</span>
                <Tooltip
                  style={Style.secondary}
                  content="Date selections adjust as time passes."
                  placement={Placement.top}
                >
                  <div className="switch-info">
                    <span>i</span>
                  </div>
                </Tooltip>
                <div>
                  <ToggleSwitch
                    yesLabel="On"
                    noLabel="Off"
                    onChange={(value) =>
                      storedStartDate &&
                      storedEndDate &&
                      handleChange?.(
                        storedStartDate,
                        storedEndDate,
                        value ? Object.keys(BUTTONS)[0] : undefined,
                      )
                    }
                    checked={isActiveSmartSelection}
                    name="Moving Dates"
                    topOffset={5}
                  />
                </div>
              </div>
            )}
            {showSmartSelection && (
              <div className="smart-selection-container">
                <SmartSelection
                  handleChange={handleSmartSelection}
                  trackingId={trackingId}
                  maxDate={maxDate}
                  minDate={minDate}
                  relativeDateOffset={relativeDateOffset}
                  disabled={disabled}
                />
              </div>
            )}
          </div>
        </div>
      </Flex>
      {footerMessage && (
        <div className="broadcast-calendar-footer">
          <div className="footer-wrapper">
            <div className="footer-box" />
            <span>{footerMessage}</span>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div
      id={componentId}
      data-testid={componentTestId}
      className={componentClass}
      ref={ref}
    >
      <Input type={Type.children} disabled={disabled}>
        <div>
          {storedStartDate && storedEndDate ? (
            <Flex horizontal={Horizontal.left} vertical={Vertical.middle}>
              <span
                onKeyDown={noop}
                onClick={() => {
                  if (disabled) return;
                  dispatch({
                    focusedInput: FocusedInput.startDate,
                  });
                }}
              >
                <input
                  disabled={disabled || !!relativeDateOffset}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (disabled) return;
                    dispatch({
                      focusedInput: FocusedInput.startDate,
                    });
                  }}
                  onKeyDown={handleKeyEnterInput}
                  className={`date ${
                    focusedInput === FocusedInput.startDate ? 'focused' : ''
                  }`}
                  data-testid={dateStartTestId}
                  onChange={changeInput}
                  onBlur={(e) => focusOutInput(e, FocusedInput.startDate)}
                  onFocus={() => {
                    dispatch({
                      focusedInput: FocusedInput.startDate,
                      lastUpdatedDate: LastUpdatedDate.notUpdated,
                    });
                  }}
                  value={proposedStartDate}
                />
                <Icon type={IconType.rightArrow} color={Color.dark} />
                <input
                  disabled={disabled || !!relativeDateOffset}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (disabled) return;
                    dispatch({
                      focusedInput:
                        focusedInput === undefined
                          ? FocusedInput.startDate
                          : FocusedInput.endDate,
                    });
                  }}
                  onKeyDown={handleKeyEnterInput}
                  className={`date ${
                    focusedInput === FocusedInput.endDate ? 'focused' : ''
                  }`}
                  data-testid={dateEndTestId}
                  onChange={changeInput}
                  onBlur={(e) => focusOutInput(e, FocusedInput.endDate)}
                  onFocus={(): void => {
                    dispatch({
                      lastUpdatedDate: LastUpdatedDate.notUpdated,
                      focusedInput: FocusedInput.endDate,
                    });
                  }}
                  value={proposedEndDate}
                />
                {showNumOfDays && (
                  <span className="number-of-days">
                    {getNumberOfDaysBetweenTwoDays(
                      storedStartDate,
                      storedEndDate,
                    )}
                  </span>
                )}
                {isActiveSmartSelection ? (
                  <span className="moving-dates-span">
                    <Icon
                      type={IconType.calendar}
                      color={Color.primary}
                      size={IconSize.medium}
                    />
                    <span className="moving-dates-txt">
                      {LOCALE_MOVING_DATES_ENABLED}
                    </span>
                  </span>
                ) : (
                  <Icon
                    type={IconType.calendar}
                    color={Color.primary}
                    size={IconSize.medium}
                  />
                )}
              </span>
            </Flex>
          ) : (
            <span>{LOCALE_SELECT_RANGE}</span>
          )}
        </div>
        {showFloating && calendarComponent}
      </Input>
      {!showFloating && calendarComponent}
    </div>
  );
};

export default DatePicker;
