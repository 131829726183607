export const Groups = {
  ads: 'Ads',
} as const;

export type Group = (typeof Groups)[keyof typeof Groups];

interface IDimension {
  id: string;
  name?: string;
  short_name?: string;
  filter?: string;
  description?: string;
  group?: string;
  notes?: string;
  value_type?: string;
  hideFor?: string[];
  custom_segment?: boolean;
  dimension_type?: string;
  dimensions?: Array<IDimension>;
  operators?: Array<string>;
  is_dimension?: boolean;
  is_rule?: boolean;
  user_dimension_source?: boolean;
  user_dimension?: boolean;
  content_unsupported?: boolean;
  adverts_unsupported?: boolean;
  custom_adverts_unsupported?: boolean;
  generic_events_unsupported?: boolean;
}

export default IDimension;
