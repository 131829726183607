import { buildYup } from 'schema-to-yup';
import {
  getCommonErrors,
  validationSchemaType,
} from '../../../../helpers/forms/formValidations';

const validationSchemaData = {
  ...validationSchemaType,
  required: ['name'],
  properties: {
    name: {
      name: 'Dashboard Name',
      type: 'string',
    },
  },
};

export const validationSchema = buildYup(validationSchemaData, {
  errMessages: {
    name: getCommonErrors(validationSchemaData.properties.name),
  },
});
