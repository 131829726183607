import useFetch from 'hooks/useFetch';
import useCurrentUser from 'hooks/useCurrentUser';
import React, { useCallback, useState } from 'react';
import ITextValue from 'types/textValue';
import { DropdownSearchQueryProvider } from 'components/DropdownSearch';
import { showErrorToast } from 'helpers/general';
import { IFilterValuePayload } from 'types/filter';
import FetchMethod from 'types/fetchMethod';
import Flex, { Horizontal } from 'components/Flex';
import Button, { Kind, Type as ButtonType } from 'components/Button';
import { Color, Type as IconType } from 'components/Icon';
import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';
import Field from 'components/Field';
import { Type as InputType } from 'components/Input';
import { get } from 'lodash/fp';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import { ICustomValuesProps } from '../types';
import { changeHandler, handleMultiSelectChange } from '../adapters';
import {
  DELETE_CUSTOM_VALUE_WARNING_MESSAGE,
  MAPPED_VALUE_LABEL,
  MAPPED_VALUE_PLACEHOLDER,
  SOURCE_VALUES_LABEL,
  SOURCE_VALUES_PLACEHOLDER,
  YES_BUTTON_LABEL,
  NO_BUTTON_LABEL,
} from '../locale';

export const CustomValuesTabPanel = ({
  values,
  index,
  handleChange,
  arrayFieldHelpers,
  modalStateSetter,
  errors,
  handleBlur,
  touched,
  setTouched,
  setActiveTab,
  loading,
}: ICustomValuesProps): JSX.Element => {
  const mappedValuePath = `custom_values[${index}].mapped_value`;
  const sourceValuePath = `custom_values[${index}].source_values`;
  const { doFetch } = useFetch();
  const { email, selectedClient } = useCurrentUser();
  const [valuesOptions, setValuesOptions] = useState<ITextValue[]>([]);

  const retrieveValue: DropdownSearchQueryProvider = useCallback(
    (valueFilter = '', lineBegin = 0, lineEnd = 0) =>
      new Promise((resolve, reject) => {
        if (!values.source_dimension) {
          setValuesOptions([]);
          showErrorToast('Please provide a source dimension.');
          resolve([]);
          return;
        }
        doFetch({
          endpoint: '/filterValues',
          payload: {
            userEmail: email ?? '',
            field: values.source_dimension,
            datasetIds: [],
            genericEventDatasets: [],
            lineBegin,
            lineEnd,
            valueFilter,
            filterRules: [],
            selectedClient,
          } as IFilterValuePayload,
          method: FetchMethod.POST,
          onError: (e) => {
            setValuesOptions([]);
            showErrorToast(e.message ?? 'Cannot retrieve rules values');
            reject(e);
          },
          onSuccess: (d) => {
            setValuesOptions(d as ITextValue[]);
            resolve(d as ITextValue[]);
          },
        });
      }),
    [doFetch, email, selectedClient, values.source_dimension],
  );

  return (
    <div className="px-5 pb-4">
      <Flex horizontal={Horizontal.right} className="pt-3 pb-3">
        <Button
          type={ButtonType.button}
          kind={Kind.icon}
          disabled={loading}
          iconColor={Color.primary}
          iconType={IconType.trash}
          onClick={() => {
            modalStateSetter({
              isOpen: true,
              message: DELETE_CUSTOM_VALUE_WARNING_MESSAGE,
              confirmLabel: YES_BUTTON_LABEL,
              closeLabel: NO_BUTTON_LABEL,
              onClose: () => modalStateSetter({ isOpen: false }),
              onConfirm: () => {
                arrayFieldHelpers.remove(index);
                setActiveTab(index > 0 ? index - 1 : 0);
                modalStateSetter({ isOpen: false });
              },
            });
          }}
        />
      </Flex>
      <QueryBuilderItem label={MAPPED_VALUE_LABEL} labelAlignment="top">
        <Field
          type={InputType.text}
          onBlur={handleBlur}
          value={get(mappedValuePath, values)}
          placeholder={MAPPED_VALUE_PLACEHOLDER}
          name={mappedValuePath}
          disabled={loading}
          errorMessage={get(mappedValuePath, errors) as string}
          hasError={
            !!(get(mappedValuePath, touched) && get(mappedValuePath, errors))
          }
          onChange={changeHandler(handleChange, mappedValuePath)}
        />
      </QueryBuilderItem>
      <QueryBuilderItem label={SOURCE_VALUES_LABEL} labelAlignment="top">
        <MultipleDropdownSelect
          name={sourceValuePath}
          selected={get(sourceValuePath, values)}
          placeholder={SOURCE_VALUES_PLACEHOLDER}
          fetchedOptions
          options={valuesOptions}
          disabled={loading}
          queryProvider={retrieveValue}
          isReportInvalid={
            !!(get(sourceValuePath, touched) && get(sourceValuePath, errors))
          }
          errorText={get(sourceValuePath, errors)}
          onBlur={handleBlur}
          onChange={handleMultiSelectChange(
            handleChange,
            touched,
            setTouched,
            sourceValuePath,
          )}
        />
      </QueryBuilderItem>
    </div>
  );
};
