import { sanitizeExportFileNameWDate } from 'helpers/string';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import { Slide, toast, ToastPosition } from 'react-toastify';

export const arrayMin = (array: number[]): number =>
  Math.min.apply(null, array);

export const arrayMax = (array: number[]): number =>
  Math.max.apply(null, array);

/* Given a tree structure like:
 * { children: [{ children: [{ node }], ... }], ... }
 * Put all of the nodes found into an array and return it
 *
 * @param tree [Object]
 * @param key [String] In order to traverse the tree we need a key to trigger
 * the recursive call to this function
 * @returns [Array]
 */
export function surface(
  tree: object | undefined,
  key = 'children',
): Array<object> {
  return reduce(
    tree,
    (allSurfacedNodes: Array<object>, node: object) => {
      const children = get(node, key);
      let memo = [...allSurfacedNodes];

      if (!isEmpty(children)) {
        memo = [...memo, ...surface(children, key)];
      } else {
        memo = [...memo, node];
      }

      return memo;
    },
    [],
  );
}

export const copyCurrentUrl = (): void => {
  const el = document.createElement('input');
  el.value = window.location.href;
  el.id = 'url-input';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  el.remove();
};

export const removeRunParamFromUrl = (url: string): string => url.split('?')[0];

const commonToastConfig = {
  autoClose: 5000,
  closeOnClick: true,
  hideProgressBar: false,
  pauseOnHover: true,
  position: ToastPosition.BOTTOM_RIGHT,
  transition: Slide,
};
export const showSuccessToast = (message: string): void => {
  toast.success(message, commonToastConfig);
};

export const showInfoToast = (message: string): void => {
  toast.info(message, commonToastConfig);
};

export const showWarningToast = (message: string): void => {
  toast.warn(message, commonToastConfig);
};

export const showErrorToast = (message: string): void => {
  toast.error(message, commonToastConfig);
};

export const showToast = (message: string): void => {
  toast(message, commonToastConfig);
};

export const waitSeconds = async (seconds = 1): Promise<void> => {
  await new Promise((r) => setTimeout(r, seconds * 1000));
};

export const downloadBlobFile = (
  resultBlob: Blob,
  fileName: string,
  format: string = 'xlsx',
  type: string = 'text/plain',
): void => {
  const getExportFilename = (): string => {
    // The file extension of the xlsx-summary is .xlsx
    const cleanedFormat = format === 'xlsx-summary' ? 'xlsx' : format;
    if (!fileName) {
      return `export_${new Date().toISOString()}.${cleanedFormat}`;
    }
    return `${sanitizeExportFileNameWDate(fileName)}.${cleanedFormat}`;
  };

  const blob = new Blob([resultBlob], { type });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', getExportFilename());
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
