import Button, {
  buttonComponentName,
  Kind,
  Size,
  Type as BtnType,
} from 'components/Button';
import Dropdown, { dropdownComponentName } from 'components/Dropdown';
import Error, { errorComponentName } from 'components/Error';
import H1 from 'components/H1';
import Input, { inputComponentName, Type } from 'components/Input';
import Label from 'components/Label';
import { getComponentUuid, getTestId } from 'helpers/components';
import { showErrorToast, showSuccessToast } from 'helpers/general';
import useFetch from 'hooks/useFetch';
import React, { FormEvent, useState } from 'react';
import FetchMethod from 'types/fetchMethod';
import {
  CTA_LABEL,
  DROPDOWN_LABEL,
  DROPDOWN_PLACEHOLDER,
  ERROR_MESSAGE,
  FEATURE_FLAGS_HEADER,
  INPUT_LABEL,
  INPUT_PLACEHOLDER,
  options,
} from './locales';
import { IFormValues } from './types';

const FLAG_INPUT_NAME = 'flag-name';
const REPORT_TYPE_DROPDOWN_NAME = 'report-type';
const ERROR_MESSAGE_NAME = 'error-message';
const ADD_BUTTON_NAME = 'add';

export const REPORT_TYPE_TEST_ID = getTestId(
  dropdownComponentName,
  REPORT_TYPE_DROPDOWN_NAME,
);
export const REPORT_TYPE_DROPDOWN_ID = getComponentUuid(
  REPORT_TYPE_DROPDOWN_NAME,
);

export const FLAG_NAME_TEST_ID = getTestId(inputComponentName, FLAG_INPUT_NAME);
export const FLAG_NAME_ID = getComponentUuid(FLAG_INPUT_NAME);

export const ERROR_MESSAGE_TEST_ID = getTestId(
  errorComponentName,
  ERROR_MESSAGE_NAME,
);

export const ADD_BUTTON_TEST_ID = getTestId(
  buttonComponentName,
  ADD_BUTTON_NAME,
);

const forbiddenChars = new RegExp(/[`|\s!@#$%^&*()+-=\][{};':"\\,.<>/?~]/);

export const FeatureFlagsForm = (): JSX.Element => {
  const [values, setValue] = useState<IFormValues>({
    reportType: '',
    flagName: '',
  });
  const isFilled = !!(
    (values.flagName as string).length && values.reportType.length
  );

  const { doFetch, loading } = useFetch();

  const hasError = forbiddenChars.test(values.flagName as string);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const payload = {
      name: `feature_flags.${values.reportType}.${values.flagName}`,
      description: `Apply feature flag ${values.flagName} to all ${values.reportType} run by user`,
    };

    doFetch({
      endpoint: '/permissions',
      payload,
      method: FetchMethod.POST,
      onError: (e) => {
        showErrorToast(e.message as string);
      },
      onSuccess: () => {
        showSuccessToast('Flag successfully added');
      },
    });
  };
  return (
    <div className="container-fluid mt-4">
      <div className="header-row">
        <H1>{FEATURE_FLAGS_HEADER}</H1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mt-2 row">
          <Label forId={REPORT_TYPE_DROPDOWN_NAME} text={DROPDOWN_LABEL} />
          <Dropdown
            testId={REPORT_TYPE_DROPDOWN_NAME}
            onChange={(reportType: string) =>
              setValue({ ...values, reportType })
            }
            options={options}
            placeholder={DROPDOWN_PLACEHOLDER}
            value={values.reportType}
            name={REPORT_TYPE_DROPDOWN_ID}
            id={REPORT_TYPE_DROPDOWN_ID}
          />
        </div>
        <div className="mt-1 row">
          <Label forId={FLAG_NAME_ID} text={INPUT_LABEL} />
          <Input
            placeholder={INPUT_PLACEHOLDER}
            testId={FLAG_INPUT_NAME}
            id={FLAG_NAME_ID}
            name={FLAG_NAME_ID}
            type={Type.text}
            hasError={hasError}
            onChange={(flagName) => {
              setValue({ ...values, flagName });
            }}
            value={values.flagName as string}
          />
          {hasError && (
            <Error testId={ERROR_MESSAGE_NAME} message={ERROR_MESSAGE} />
          )}
        </div>
        <div className="mt-1 row">
          <Button
            type={BtnType.submit}
            kind={Kind.primary}
            size={Size.medium}
            className="spaced-btn"
            disabled={hasError || loading || !isFilled}
            testId={ADD_BUTTON_NAME}
          >
            {CTA_LABEL}
          </Button>
        </div>
      </form>
    </div>
  );
};
