import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import Flex, { Direction } from '../Flex';

export const mainComponentName = 'main';

export interface IMainProps {
  testId?: string;
  children?: ReactNode;
  onlyFooter?: boolean;
}

export enum States {
  onlyFooter = 'only-footer',
}

const Main: FunctionComponent<IMainProps> = (props): ReactElement => {
  const { children, testId, onlyFooter } = props;

  const mainTestId = getTestId(mainComponentName, testId);
  const mainClass = getClass(mainComponentName, {
    boolean: [
      {
        state: onlyFooter,
        class: States.onlyFooter,
      },
    ],
  });

  return (
    <main className={mainClass} data-testid={mainTestId}>
      <Flex direction={Direction.column}>{children}</Flex>
    </main>
  );
};

export default Main;
