import { DropdownSearchQueryProvider } from 'components/DropdownSearch';
import FieldWrapper from 'components/Field/Wrapper';
import Label from 'components/Label';
import { isBaseReportSaving } from 'domains/reports/adapters/rulesets';
import {
  LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL,
  LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_PLACEHOLDER,
} from 'domains/reports/locales/datasets';
import { handleDropdownSearchSyncFilter } from 'helpers/utils';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import { ToggleSwitch } from 'components/ToggleSwitch';
import mixpanel from 'mixpanel-browser';
import ActionType from 'store/actions/types';
import useUserPermissions from 'hooks/useUserPermissions';
import { getDatasetDefaultDateRange } from 'domains/reports/adapters/date';
import { useDatasets } from '../../domains/datasets/hooks';
import { getClass, getId, getTestId } from '../../helpers/components';
import {
  getQueryMode,
  getQueryScheduleDatasets,
} from '../../store/reducers/report';
import { Mode, Modes } from '../../types/query';
import ITextValue from '../../types/textValue';
import DatePicker from '../DatePicker';
import MultipleDropdownSelect from '../MultipleDropdownSelect';
import {
  getFiltersFromQueryFilters,
  getParametersFromQuery,
  getTextValueArrayFromArray,
  getTextValueDatasets,
  injectDatasetControl,
} from '../QueryBuilder/adapters';
import QueryBuilderItem, {
  LabelAlignments,
} from '../QueryBuilder/components/QueryBuilderItem';
import RulesBuilder from '../RulesBuilder';
import SegmentedControl from '../SegmentedControl';
import {
  DOM_KEY_BODY,
  DOM_KEY_FOOTER,
  DOM_KEY_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN,
  DOM_KEY_QUERY_BUILDER_DATASETS_SEGMENTED_CONTROL,
} from './constants';
import {
  LOCALE_BREAKDOWN_TOGGLE_TOOLTIP,
  LOCALE_DATASETS_LABEL,
  LOCALE_DATASETS_TOOLTIP,
  LOCALE_DATE_RANGE_LABEL,
  LOCALE_DATE_RANGE_TOOLTIP,
  LOCALE_RULES_LABEL,
} from './locale';
import { IOwnProps } from './types';
import BreakoutCategoriesRulesComponent from './BreakoutCategoriesRules.component';

export const componentName = 'query-builder';

export const TargetBuilder: FunctionComponent<IOwnProps> = (
  props,
): ReactElement => {
  const {
    availableRulesDimensions,
    businessData,
    id,
    report,
    setQueryModeAction,
    setReportQueryDatasetsAction,
    setReportQueryFilterEndDate,
    setReportQueryFilterStartDate,
    setReportQueryFilterRelativeDateOffset,
    testId,
    toolbar,
    addQueryFilterAction,
    replaceQueryFilterAction,
    deleteQueryFilterAction,
    disabled,
    messageList,
    addWarningMessage,
    deleteWarningMessage,
    isReportInvalid,
    setQueryIncludeTargetBreakdown,
    includeTargetBreakdown,
    domainsDateRanges,
  } = props;

  const { isRunLaunched } = toolbar;

  const { query } = report;

  const { datasets } = query;

  const { checkPermissions } = useUserPermissions();

  const { getScheduleDatasetsForSelectedClient } = useDatasets();

  const availableScheduleDatasets = useMemo(
    getScheduleDatasetsForSelectedClient,
    [getScheduleDatasetsForSelectedClient],
  );
  const curatedScheduleDatasets = useMemo(
    () => getTextValueDatasets(availableScheduleDatasets),
    [availableScheduleDatasets],
  );

  const {
    startDate: queryStartDate,
    endDate: queryEndDate,
    relativeDateOffset: relativeOffset,
  } = getParametersFromQuery(query);

  const componentId = useMemo(() => getId(componentName, id), [id]);
  const componentTestId = useMemo(
    () => getTestId(componentName, testId),
    [testId],
  );
  const componentClass = useMemo(() => getClass(componentName), []);
  const componentBodyClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_BODY] }),
    [],
  );
  const componentFooterClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_FOOTER] }),
    [],
  );

  const handleChangeScheduleDataset = (
    selectedDatasets: ITextValue[],
  ): void => {
    const datasetValues = selectedDatasets.map(
      (dataset: ITextValue) => dataset.value,
    ) as string[];
    setReportQueryDatasetsAction(datasetValues);
  };

  const realMode = getQueryMode(report);

  const queryBuilderDatasetsComponentName = 'query-builder-datasets';

  const queryBuilderDatasetsSegmentedControlClass = useMemo(
    () =>
      getClass(queryBuilderDatasetsComponentName, {
        concat: [DOM_KEY_QUERY_BUILDER_DATASETS_SEGMENTED_CONTROL],
      }),
    [],
  );

  const movingDates = realMode !== Modes.customAdverts;

  const validateCustomAdsDropdown = useMemo((): string => {
    let message = '';
    if (realMode === Modes.customAdverts) {
      const customAdsDatasetValue = getTextValueArrayFromArray(
        getQueryScheduleDatasets(report),
        curatedScheduleDatasets,
      );
      if (!customAdsDatasetValue.length) {
        message = 'Custom Ads requires at least one dataset.';
        addWarningMessage({
          id: 'dataset_value',
          message,
          field: realMode,
        });
      } else {
        message = '';
        const valueIsInError = messageList.filter(
          (m) => m.id === 'dataset_value' && m.field === realMode,
        );
        if (valueIsInError.length) {
          deleteWarningMessage({
            id: 'dataset_value',
            field: realMode,
          });
        }
      }
    }
    return message;
  }, [
    getTextValueArrayFromArray(
      getQueryScheduleDatasets(report),
      curatedScheduleDatasets,
    ),
    addWarningMessage,
    deleteWarningMessage,
    messageList,
    realMode,
  ]);

  const handleChangeScheduleDate = useCallback(
    (
      selectedStartDate: string,
      selectedEndDate: string,
      relativeDateOffset?: string,
    ): void => {
      setReportQueryFilterStartDate(selectedStartDate);
      setReportQueryFilterEndDate(selectedEndDate);
      setReportQueryFilterRelativeDateOffset(relativeDateOffset);
    },
    [
      setReportQueryFilterStartDate,
      setReportQueryFilterEndDate,
      setReportQueryFilterRelativeDateOffset,
    ],
  );

  const filterDatasetsOptions: DropdownSearchQueryProvider = useCallback(
    (valueFilter = '') =>
      handleDropdownSearchSyncFilter(valueFilter, curatedScheduleDatasets),
    [curatedScheduleDatasets],
  );

  const datasetOptions = useMemo(
    () => injectDatasetControl(businessData, report),
    [businessData],
  );

  const breakdownTogglePermission = checkPermissions(
    `targets.breakdown_toggle::view`,
  );

  const { dateMin, dateMax } = getDatasetDefaultDateRange(
    domainsDateRanges,
    report.query.mode as Mode,
  );

  const { dateMin: pIQDateMin, dateMax: pIQDateMax } =
    getDatasetDefaultDateRange(domainsDateRanges, Modes.placeIQ as Mode);

  return (
    <div
      id={componentId}
      data-testid={componentTestId}
      className={componentClass}
    >
      <section className={componentBodyClass}>
        <QueryBuilderItem
          label={LOCALE_DATE_RANGE_LABEL}
          tooltip={LOCALE_DATE_RANGE_TOOLTIP}
          className="large"
        >
          <DatePicker
            disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
            startDate={queryStartDate}
            endDate={queryEndDate}
            handleChange={handleChangeScheduleDate}
            limitToRange={!!(datasets?.length && datasets.length > 0)}
            maxDate={dateMax}
            minDate={dateMin}
            trackingId="target"
            showNumOfDays
            movingDates={movingDates}
            relativeDateOffset={relativeOffset}
          />
        </QueryBuilderItem>
        <FieldWrapper>
          <Label
            text={LOCALE_DATASETS_LABEL}
            tooltip={LOCALE_DATASETS_TOOLTIP}
          />
          <div className={queryBuilderDatasetsSegmentedControlClass}>
            <SegmentedControl
              options={datasetOptions}
              onSelect={setQueryModeAction}
              selected={realMode}
              trackingId="datasets"
              disabled={disabled}
            />
          </div>
        </FieldWrapper>
        {realMode === Modes.customAdverts && (
          <QueryBuilderItem
            label={
              LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_LABEL
            }
          >
            <MultipleDropdownSelect
              ignoreGrouping
              disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
              options={curatedScheduleDatasets}
              onChange={handleChangeScheduleDataset}
              errorText={validateCustomAdsDropdown}
              isReportInvalid={isReportInvalid}
              trackingId="custom_ads"
              selected={getTextValueArrayFromArray(
                getQueryScheduleDatasets(report),
                curatedScheduleDatasets,
              )}
              testId={DOM_KEY_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN}
              placeholder={
                LOCALE_QUERY_BUILDER_DATASETS_CUSTOM_ADS_MULTI_DROPDOWN_PLACEHOLDER
              }
              queryProvider={filterDatasetsOptions}
            />
          </QueryBuilderItem>
        )}
      </section>

      <footer className={componentFooterClass}>
        <QueryBuilderItem
          label={LOCALE_RULES_LABEL}
          labelAlignment={LabelAlignments.top}
        >
          <RulesBuilder
            datasets={datasets}
            dimensions={availableRulesDimensions}
            disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
            filters={getFiltersFromQueryFilters(query.filters.children)}
            maxDate={queryEndDate}
            minDate={queryStartDate}
            addItem={addQueryFilterAction}
            replaceItem={replaceQueryFilterAction}
            deleteItem={deleteQueryFilterAction}
          />
        </QueryBuilderItem>
      </footer>
      <section className={componentBodyClass}>
        <QueryBuilderItem
          label="Breakdown"
          className="large"
          tooltip={LOCALE_BREAKDOWN_TOGGLE_TOOLTIP}
        >
          <ToggleSwitch
            name="Breakdown Toggle"
            checked={includeTargetBreakdown}
            onChange={(value) => {
              setQueryIncludeTargetBreakdown(value);

              mixpanel.track(ActionType.REPORT_SET_QUERY_INCLUDE_BREAKDOWN, {
                value,
              });
            }}
            trackingId="BreakdownToggle"
            disabled={!breakdownTogglePermission}
          />
        </QueryBuilderItem>

        <BreakoutCategoriesRulesComponent
          disabled={!!disabled || !includeTargetBreakdown}
          dateMin={pIQDateMin}
          dateMax={pIQDateMax}
          isRunLaunched={isRunLaunched}
        />
      </section>
    </div>
  );
};
