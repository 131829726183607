import { format, isValid } from 'date-fns';
import { Point, Series, TooltipFormatterContextObject } from 'highcharts';
import { groupBy } from 'lodash';

interface IExtendedSeries extends Series {
  tooltipOptions: {
    valueSuffix?: string;
  };
}

export default function sharedTooltipFormatter(
  this: TooltipFormatterContextObject,
): string {
  const groupedPoints = groupBy(
    ((this.points ?? [this.point]) as TooltipFormatterContextObject[])?.map(
      (point) => ({
        ...point,
        point: point.point ?? { ...point },
        color: point.series.options?.custom?.color ?? point.color,
      }),
    ),
    'color',
  );

  // NOTE: If this.x is valid date format it properly, else just display x
  const title = isValid(Number(this.x))
    ? format(this.x as number, 'M-d-yyyy')
    : this.x;

  return `
    <div class="shared-tooltip">
     <div class="tooltip-title" data-testid="tooltip-title">
     ${title}
     </div>
     <div class="tooltip-separator"></div>
     ${Object.values(groupedPoints)
       ?.map((target, index) => {
         return `
         <div class=${index ? 'tooltip-target-wrapper' : ''}>
         ${target
           .map(({ point, color, y, series }) => {
             const { name, tooltipOptions } = series as IExtendedSeries;
             return `
                    <div class="tooltip-target-item">
                    <div class="tooltip-metric-title-wrapper">
                    <div class="metric-indicator" style="background:${color}" data-testid="metric-indicator-${color}"/></div>
                      <div class="metric-title">
                      ${name}:
                      </div>
                      </div>
                      <div class="metric-value">
                      ${
                        (point as Point & { custom: { displayValue: string } })
                          .custom?.displayValue ??
                        y?.toString() + (tooltipOptions?.valueSuffix ?? '')
                      }
                      </div>
                      </div>
                `;
           })
           .join('')}
           </div>
           `;
       })
       .join('')}
     </div>
  `;
}
