import { getClass } from 'helpers/components';
import { RefObject, useEffect } from 'react';

const featureClass = getClass('feature');

const targetIsNotContainedBy = (
  target: EventTarget,
  item: HTMLElement,
): boolean => item && !item.contains(target as HTMLElement);

const useOnOutsideClick = (
  refs: RefObject<HTMLDivElement>[] | RefObject<HTMLDivElement>,
  callback: () => void,
  config: {
    scoped?: boolean;
    capture?: boolean;
  } = { scoped: false, capture: true },
): void => {
  const { scoped, capture } = config;

  useEffect(() => {
    const handleClick = ({ target }: MouseEvent): void => {
      const testRefs = !Array.isArray(refs) ? [refs] : refs;

      if (
        testRefs.every(
          (item) =>
            target &&
            item.current &&
            targetIsNotContainedBy(target, item.current),
        )
      ) {
        callback();
      }
    };

    let nodeToAttach: HTMLDivElement | null;

    if (scoped) {
      nodeToAttach = document.querySelector(`.${featureClass}`);

      if (nodeToAttach) {
        nodeToAttach.addEventListener('click', handleClick, capture);
      }
    } else {
      document.addEventListener('click', handleClick, capture);
    }

    return () => {
      if (scoped && nodeToAttach) {
        nodeToAttach.removeEventListener('click', handleClick);
        return;
      }

      document.removeEventListener('click', handleClick);
    };
  }, [callback, refs, scoped, capture]);
};

export default useOnOutsideClick;
