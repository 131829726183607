import Highcharts, { LegendOptions, PlotOptions } from 'highcharts';
import {
  filter,
  get,
  indexOf,
  sortBy,
  toString,
  union,
  size,
  difference,
  isEmpty,
  uniq,
} from 'lodash/fp';
import merge from 'lodash/merge';
import { getChartBasicType } from '../../../components/Chart/helpers';
import { arrayMax, arrayMin } from '../../../helpers/general';
import { colorChart } from '../../../theme/605/colors';
import { IQuery, IReportSorting } from '../../../types/query';
import IDimension from '../../dimensions/types';
import { Metric } from '../../metrics/types';
import {
  daysOfWeek,
  defaultCharts,
  defaultMetrics,
  optionAxisTypes,
  stackings,
} from '../constants';
import {
  ReportTimeDimension,
  ReportTimeDimensions,
  IMetaData,
  DataRowGraph,
  ChartTypes,
  BusinessRules,
  ChartType,
  DataRowGraphValue,
  getStackType,
  ICustomChart,
  IConfiguration,
  IOptions,
  ISGMConfig,
  MetricConfig,
  OptionChart,
  OptionPlotOptions,
  OptionPlotOptionsChartType,
  OptionSerie,
  Stacking,
  DaysOfWeek,
  UniqRowsColumns,
  GroupConfigAxis,
  MetricsYAxisConfig,
  ChartGeneratedSeries,
  SeriesType,
} from '../types';
import { getEndDate, getStartDate } from './date';
import { IDateRangeQueryFilter } from 'domains/reports/types';

const getDisplayValue = get('DISPLAY_VALUE');
const getValue = get('VALUE');

export const getTimeDimension = (
  dimensionIds: string[],
): ReportTimeDimension => {
  let obtainedTimeDimension = '';
  dimensionIds.forEach((dimension: string) => {
    if (ReportTimeDimensions.includes(dimension as ReportTimeDimension)) {
      obtainedTimeDimension = dimension;
    }
  });
  return obtainedTimeDimension as ReportTimeDimension;
};

const getMoreThanOneDimension = (dimensions: string[]): boolean =>
  dimensions.length > 1;

const getMoreThanOneMetric = (metrics: string[]): boolean => metrics.length > 1;

const getIsAllViewing = (dimensions: string[]): boolean =>
  !dimensions.some((dimension: string) =>
    ReportTimeDimensions.includes(dimension as ReportTimeDimension),
  );

export const getHeatmapAvailable = (
  dimensions: string[],
  metrics: string[],
): boolean =>
  getIsAllViewing(dimensions) &&
  getMoreThanOneDimension(dimensions) &&
  !getMoreThanOneMetric(metrics);

const getPlainMetricsFromMetricConfig = (metrics: MetricConfig[]): string[] =>
  metrics.map((metricConfig: MetricConfig) => metricConfig.metric);

/** --------------------------------------------
 Rulesets definition:
 ------------------------------------------------
 ruleSet1: More than 1 metric with dimension plus interval
 */
export const getBusinessRules = (
  dimensions: string[],
  SGMConfig: ISGMConfig,
  isTarget: boolean,
): BusinessRules => {
  const plainMetrics = getPlainMetricsFromMetricConfig(SGMConfig.metrics);
  const configHasTargets = (config: 'series' | 'groups'): boolean =>
    !!SGMConfig[config].find((group) => group === 'TARGET');

  const seriesHasTarget = configHasTargets('series');
  const groupsHasTarget = configHasTargets('groups');
  const conditions = {
    hasSpatialDimensions: !isEmpty(
      difference(dimensions, ReportTimeDimensions),
    ),
    moreThanOneDimension: getMoreThanOneDimension(dimensions),
    moreThanOneMetric: getMoreThanOneMetric(plainMetrics),
    oneSeries: SGMConfig.series.length === 1,
    multiSeries: !!SGMConfig.series.length,
    isAllViewing: getIsAllViewing(dimensions),
    isHeatmap:
      (SGMConfig.metrics.length &&
        SGMConfig.metrics[0].chartType === ChartTypes.heatmap) ||
      false,
    isPackedbubble:
      (SGMConfig.metrics.length &&
        SGMConfig.metrics[0].chartType === ChartTypes.packedbubble) ||
      false,
    isMultiTarget: seriesHasTarget || groupsHasTarget,
  };

  const rules = {
    isTarget,
    canShowGroupFilter: false,
    heatmapAvailable:
      conditions.isHeatmap || getHeatmapAvailable(dimensions, plainMetrics),
    packedbubbleAvailable:
      conditions.isPackedbubble ||
      (conditions.isAllViewing &&
        conditions.moreThanOneDimension &&
        !conditions.moreThanOneMetric),
    canShowGroupCharts:
      conditions.moreThanOneDimension || conditions.isMultiTarget,
    ruleSet1:
      !!plainMetrics.length &&
      conditions.moreThanOneDimension &&
      !conditions.isAllViewing &&
      !conditions.isMultiTarget,
    ruleSetCase11:
      conditions.isMultiTarget &&
      !conditions.moreThanOneDimension &&
      !conditions.isAllViewing,
    isMultiChart:
      conditions.moreThanOneDimension &&
      conditions.moreThanOneMetric &&
      !conditions.isAllViewing,
  };

  return {
    ...conditions,
    ...rules,
  };
};

export const getSeriesFromQuery = (
  query: IQuery,
  SGMConfig: ISGMConfig,
  isTargetGroup: boolean,
): string[] => {
  if (query?.interval?.id !== ReportTimeDimension.ALL_VIEWING) {
    return isTargetGroup ? union(query?.group, ['TARGET']) : query?.group ?? [];
  }
  return SGMConfig.series || [];
};

export const getGroupsFromQuery = (
  query: IQuery,
  SGMConfig: ISGMConfig,
  isTargetGroup: boolean,
): string[] => {
  if (query?.interval?.id !== ReportTimeDimension.ALL_VIEWING) {
    const interval = query?.interval?.id;
    return interval
      ? [interval].filter((x) => x !== ReportTimeDimension.ALL_VIEWING)
      : [];
  }
  return (
    union(query.group, isTargetGroup ? ['TARGET'] : [])
      ?.filter((dimension: string) => !SGMConfig.series.includes(dimension))
      .filter((group: string) => group !== ReportTimeDimension.ALL_VIEWING) ||
    []
  );
};

const getStackingType = (chartType: string): Stacking => {
  if (chartType.includes('PercentageStacked')) {
    return stackings.percentageStacked;
  }
  if (chartType.includes('Stacked')) {
    return stackings.stacked;
  }
  return stackings.unstacked;
};

const getTitle = (columnName: string, metadata: IMetaData[]): string => {
  const found = metadata.find((metaEl: IMetaData) => metaEl.id === columnName);
  return found ? found.name : columnName;
};

export function generateSGMConfig(
  series: string[],
  groups: string[],
  metrics: string[],
  chartTypes: string[],
): ISGMConfig {
  const metricsConfig: MetricConfig[] = metrics.map(
    (metric: string, index: number) => ({
      metric,
      chartType: getChartBasicType(chartTypes[index]),
      stacked: getStackType(chartTypes[index]),
    }),
  );

  const SGMConfig = {
    series,
    groups,
    metrics: metricsConfig,
  };

  return SGMConfig;
}

const getSeriesValue = (array: string[], dataRow: DataRowGraph): string =>
  array.map((id: string) => getValue(dataRow[id])).join(', ');

const getUniqueGroupSeries = (
  data: DataRowGraph[],
  SGMConfig: ISGMConfig,
): UniqRowsColumns => {
  const { series, groups } = SGMConfig;
  const uniqRows: string[] = [];
  const uniqColumns: string[] = [];

  data.forEach((dataElement: DataRowGraph) => {
    const rowId = getSeriesValue(series, dataElement);
    const colId = getSeriesValue(groups, dataElement);
    if (!uniqRows.includes(rowId)) uniqRows.push(rowId);
    if (!uniqColumns.includes(colId)) uniqColumns.push(colId);
  });

  return { uniqRows, uniqColumns };
};

export const getCuratedData = (
  data: DataRowGraph[],
  metric: Metric,
  dimensions?: string[],
  calculatePercentage?: boolean,
): string[][] => {
  const totalMetric = calculatePercentage
    ? data.reduce((prev, curr) => {
        const currMetric = curr[metric].VALUE ?? 0;
        return prev + +currMetric;
      }, 0)
    : 0;
  return data.map((dataMetric: DataRowGraph) => {
    let selectedDimension = metric;
    if (dimensions) {
      const timeDimension = getTimeDimension(dimensions);
      selectedDimension = timeDimension as string;

      if (!timeDimension && dimensions) {
        selectedDimension = dimensions
          .map((dimension) => getDisplayValue(dataMetric[dimension]))
          .join(', ');
      }
    }

    const metricValue = getValue(dataMetric[metric]);
    const value = calculatePercentage
      ? `${parseFloat(((metricValue * 100) / totalMetric).toFixed(1))} %`
      : getDisplayValue(dataMetric[metric]);

    const dataValue = [
      ReportTimeDimensions.includes(selectedDimension as ReportTimeDimension)
        ? toString(getDisplayValue(dataMetric[selectedDimension]))
        : selectedDimension,
      toString(value),
    ];
    return dataValue;
  });
};

const getMetricName = (metric: string, metadata: IMetaData[]): string =>
  metadata.find((metaMetric: IMetaData) => metaMetric.id === metric)?.name ??
  metric;

const getSeriesNames = (
  metric: MetricConfig,
  metadata: IMetaData[],
  businessRules: BusinessRules,
  serieLabel: string,
): string =>
  businessRules.multiSeries
    ? serieLabel || getMetricName(metric.metric, metadata)
    : getMetricName(metric.metric, metadata);

const getGroupDisplayName = (dataRow: DataRowGraph, series: string[]): string =>
  series
    .filter((serie: string) => {
      if (series.length > 1) {
        return serie !== 'TARGET';
      }
      return serie;
    })
    .map((serie: string) => dataRow[serie].DISPLAY_VALUE)
    .join(', ');

const getIntervalSerieConfig = (
  seriesData: SeriesType[], // appeared as array of [empty]
  axisIndex: number,
  rowIndex: number,
  colIndex: number,
  dataRow: DataRowGraph,
  rowId: string, // can be string
  metric: MetricConfig,
  data: DataRowGraph[],
  metadata: IMetaData[],
  businessRules: BusinessRules,
  series: string[],
): SeriesType[] => {
  if (!seriesData[rowIndex]) {
    seriesData[rowIndex] = {
      yAxis: axisIndex,
      name: getSeriesNames(metric, metadata, businessRules, rowId),
      data: [],
      type: metric.chartType,
      metric: metric.metric,
      curatedData: [],
      multiMetric: businessRules.moreThanOneMetric,
      multiTargetData: [],
      ...(rowId && {
        multiMetric: businessRules.moreThanOneMetric,
        group: rowId,
        groupDisplayName: getGroupDisplayName(dataRow, series),
        metricCuratedName:
          metadata.find((x) => x.id === metric.metric)?.name ?? metric.metric,
      }),
    };
  }

  const serieValue = [colIndex, +getValue(dataRow[metric.metric])];
  seriesData[rowIndex].data.push(serieValue);

  if (businessRules.isAllViewing) {
    seriesData[rowIndex].curatedData[colIndex] = [
      getMetricName(metric.metric, metadata),
      getDisplayValue(dataRow[metric.metric]).toString(),
    ];
  } else {
    seriesData[rowIndex].curatedData = [
      ...(seriesData[rowIndex].curatedData || []),
      [
        getMetricName(metric.metric, metadata),
        getDisplayValue(dataRow[metric.metric]).toString(),
      ],
    ];
  }

  if (businessRules.isMultiTarget) {
    seriesData[rowIndex].multiTargetData = [
      ...(seriesData[rowIndex].multiTargetData ?? []),
      {
        targetCuratedName: dataRow.TARGET.DISPLAY_VALUE.toString(),
        target: dataRow?.TARGET?.VALUE?.toString() ?? '',
      },
    ];
  }

  return seriesData;
};

const getIntervalMultiSerieHeatmapConfig = (
  seriesData: SeriesType[],
  rowIndex: number,
  colIndex: number,
  dataRow: DataRowGraph,
  uniqRows: string[],
  metric: MetricConfig,
  data: DataRowGraph[],
): SeriesType[] => {
  if (!seriesData[0]) {
    const curatedMetricData = getCuratedData(data, metric.metric);
    seriesData[0] = {
      name: '',
      data: [],
      categories: uniqRows,
      metric: metric.metric,
      curatedData: curatedMetricData,
    };
  }
  const serieValue = [rowIndex, colIndex, +getValue(dataRow[metric.metric])];
  seriesData[0].data.push(serieValue);
  return seriesData;
};

const getSeries = (
  data: DataRowGraph[],
  SGMConfig: ISGMConfig,
  totalDimensions: number,
  uniqRows: string[],
  uniqColumns: string[],
  businessRules: BusinessRules,
  metadata: IMetaData[],
): SeriesType[] => {
  const { series, groups, metrics } = SGMConfig;

  let seriesData: SeriesType[] = [];

  data.forEach((dataRow: DataRowGraph) => {
    metrics.forEach((metric: MetricConfig, i: number) => {
      const rowId = businessRules.moreThanOneMetric
        ? metric.metric
        : getSeriesValue(series, dataRow);

      const rowIndex = businessRules.moreThanOneMetric
        ? i
        : uniqRows.indexOf(rowId);

      const colId = getSeriesValue(groups, dataRow);

      const colIndex: number = businessRules.isAllViewing
        ? uniqColumns.indexOf(colId)
        : +colId * 1000;

      seriesData = businessRules.isHeatmap // This is exclusive to 3!
        ? getIntervalMultiSerieHeatmapConfig(
            seriesData,
            rowIndex,
            colIndex,
            dataRow,
            uniqRows,
            metric,
            data,
          )
        : getIntervalSerieConfig(
            seriesData,
            i,
            rowIndex,
            colIndex,
            dataRow,
            rowId,
            metric,
            data,
            metadata,
            businessRules,
            series,
          );
    });
  });

  // Highcharts requires the data to be sorted
  seriesData.forEach((serieData) => {
    serieData.data = serieData.data.sort(
      (a: number[], b: number[]) => a[0] - b[0],
    );
  });
  return seriesData;
};

// Indicates the z-order of the chart types.
const CHART_TYPE_LIST = ['area', 'bar', 'column', 'line'];
export const sortByChartType = sortBy((e: OptionSerie) =>
  indexOf(e.type, CHART_TYPE_LIST),
);

export const generateData = (
  data: DataRowGraph[],
  SGMConfig: ISGMConfig,
  totalDimensions: number,
  businessRules: BusinessRules,
  metadata: IMetaData[],
): ChartGeneratedSeries => {
  const { uniqRows, uniqColumns } = getUniqueGroupSeries(data, SGMConfig);
  const seriesData = getSeries(
    data,
    SGMConfig,
    totalDimensions,
    uniqRows,
    uniqColumns,
    businessRules,
    metadata,
  );
  const highchartSeriesConfig = {
    series: sortByChartType(seriesData) as SeriesType[],
    categories_x: uniqColumns,
    categories_y: uniqRows,
  };

  return highchartSeriesConfig;
};

const legendConfiguration = {
  labelFormatter: function formatLabel(this: {
    userOptions: {
      groupDisplayName: string;
      targetCuratedName: string;
      metricCuratedName: string;
      multiMetric: string;
    };
    name: string;
  }): string {
    if (this.userOptions.targetCuratedName && this.userOptions.multiMetric) {
      return (
        `${this.userOptions.targetCuratedName}, ${this.userOptions.metricCuratedName}` ||
        this.name
      );
    }
    return this.userOptions.groupDisplayName || this.name;
  },
};

const getChartBasicConfig = (chartSeries: SeriesType[]): IOptions => ({
  credits: { enabled: false },
  yAxis: [],
  title: { text: '' },
  series: chartSeries,
  legend: legendConfiguration as unknown as LegendOptions,
  chart: {},
});

const isThousands = (value: string): boolean =>
  new RegExp('\\b(000)\\b').test(value);

const getMetricsYAxisConfig = (
  metrics: MetricConfig[],
  metadata: IMetaData[],
): MetricsYAxisConfig[] => {
  const yAxisConfig: MetricsYAxisConfig[] = [];

  metrics.forEach((metric: MetricConfig, i: number) => {
    const axisTitle =
      metadata.find((metaRow: IMetaData) => metaRow.id === metric.metric)
        ?.name ?? getTitle(metric.metric, metadata);

    yAxisConfig.push({
      labels: {
        formatter: function formatLabel(): string {
          const defaultLabelFormatter = get('axis.defaultLabelFormatter', this);
          const title = get('axis.userOptions.title.text', this) as string;
          const value = get('value', this) as number;

          if (isThousands(title)) {
            return Highcharts.numberFormat(value / 1000, -1, '.', ',');
          }

          return defaultLabelFormatter.call(this);
        },
      },
      opposite: i % 2 === 1,
      title: { text: axisTitle },
    });
  });
  return yAxisConfig;
};

const getGroupConfigs = (
  groups: string[],
  metrics: MetricConfig[],
  metadata: IMetaData[],
  categories_x: string[],
  categories_y: string[],
  businessRules: BusinessRules,
  data: DataRowGraph[],
  SGMConfig: ISGMConfig,
  dateRange?: IDateRangeQueryFilter,
): GroupConfigAxis => {
  let xAxis;
  let yAxis;
  const curatedData = data.map((row: DataRowGraph) => {
    if (
      businessRules.hasSpatialDimensions &&
      groups.length > 1 &&
      SGMConfig?.meta?.targetList?.length === 1
    ) {
      return groups
        .filter((group) => group !== 'TARGET')
        .map((group) => row[group]);
    }
    return groups.map((group) => row[group]);
  });
  const curatedDataTexts = curatedData.map((curatedRow: DataRowGraphValue[]) =>
    curatedRow
      .map((rowValue: DataRowGraphValue) => rowValue.DISPLAY_VALUE)
      .join(', '),
  );

  if (
    groups.some((dimension: string) =>
      ReportTimeDimensions.includes(dimension as ReportTimeDimension),
    )
  ) {
    yAxis = getMetricsYAxisConfig(metrics, metadata);
    xAxis = [
      {
        type: optionAxisTypes.datetime,
        min: dateRange ? getStartDate(dateRange!.startDate) : undefined,
        ceiling: dateRange ? getEndDate(dateRange!.endDate) : undefined,
        tickColor: '#ccd6eb',
      },
    ];
  } else if (!businessRules.isHeatmap) {
    const filteredGroups =
      groups.length > 1 ? groups.filter((group) => group !== 'TARGET') : groups;
    yAxis = getMetricsYAxisConfig(metrics, metadata);
    xAxis = [
      {
        labels: {
          formatter: ({
            pos,
            value,
          }: {
            pos?: number;
            value?: string;
          }): string =>
            businessRules.isTarget
              ? value ?? ''
              : uniq(curatedDataTexts)[pos ?? 0],
        },
        categories: categories_x,
        title: {
          text: filteredGroups.reduce(
            (prev, curr) =>
              `${prev}${prev ? ',' : ''} ${getTitle(curr, metadata)}`,
            '',
          ),
        },
      },
    ];
  } else {
    yAxis = [
      {
        categories: categories_x,
      },
    ];

    xAxis = [
      {
        categories: categories_y,
      },
    ];
  }
  return {
    xAxis,
    yAxis,
  };
};

const getConfigStacked = (stacking: Stacking): OptionPlotOptionsChartType => ({
  stacking,
  connectNulls: true,
});

const getConfigPlotPie = (): OptionPlotOptionsChartType => ({
  allowPointSelect: true,
  cursor: 'pointer',
  dataLabels: {
    enabled: true,
    formatter: ({ chart }: { chart: ICustomChart }): string => {
      const sliceIndex = chart.point?.index;
      const label = chart.series?.chart.axes[0].categories[sliceIndex ?? 0];
      return label ?? '';
    },
  },
});

const getConfigPackedbubble = (): OptionPlotOptionsChartType => ({
  minSize: '15%',
  maxSize: '100%',
  layoutAlgorithm: {
    gravitationalConstant: 0.5,
    splitSeries: true,
    seriesInteraction: false,
    dragBetweenSeries: true,
    parentNodeLimit: true,
  },
  dataLabels: {
    enabled: true,
    formatter(): string {
      return this.series?.name ?? '';
    },
    filter: {
      property: 'y',
      operator: '>',
      value: 0,
    },
    style: {
      color: 'black',
      textOutline: 'none',
      fontWeight: 'normal',
    },
  },
});

const getPlotOptions = (metrics: MetricConfig[]): PlotOptions => {
  const plotOptions: OptionPlotOptions = {};
  const chartTypes = metrics.map((metric: MetricConfig) => metric.chartType);
  const chartTypesUnique = chartTypes.filter(
    (chartType: string, index: number) =>
      chartTypes.indexOf(chartType) === index,
  );
  chartTypesUnique.forEach((chartType: string) => {
    plotOptions[chartType] = {};

    const stackingType =
      metrics?.find((metric: MetricConfig) => metric.chartType === chartType)
        ?.stacking ?? stackings.unstacked;
    if (stackingType !== stackings.unstacked) {
      plotOptions[chartType] = getConfigStacked(stackingType);
    }

    if (chartType === ChartTypes.pie) {
      plotOptions[chartType] = getConfigPlotPie();
    }

    if (chartType === ChartTypes.packedbubble) {
      plotOptions[chartType] = getConfigPackedbubble();
    }
  });
  plotOptions.series = {};
  return plotOptions;
};

function getChartStyleConfig(
  SGMconfig: ISGMConfig,
  configuration: IConfiguration,
): OptionChart {
  const { meta } = SGMconfig;
  const chart: OptionChart = {
    ...(meta?.['3D'] && {
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 25,
      },
    }),
    ...(configuration.disableAnimationAfterFirstRender && { animation: false }),
    ...(SGMconfig.metrics.length === 1 && {
      type: getChartBasicType(SGMconfig.metrics[0].chartType),
    }),
    backgroundColor: configuration.backgroundColor,
    height: configuration.height,
    style: {
      fontFamily: 'SourceSansProLight',
    },
  };

  return chart;
}

const getMinMax3DimensionalSeriesData = (
  series: SeriesType[],
): { min: number; max: number } => {
  const seriesNumbers = series[0].data.map((el: number[]) => el[2]);
  let min = arrayMin(seriesNumbers);
  const max = arrayMax(seriesNumbers);
  if (min === max) {
    min -= 0.1; // If we pass the same value for min/max, the table show no color
  }
  return { min, max };
};

const groupContainsDayOfWeek = (groups: string[]): boolean =>
  groups.includes('DAY_OF_WEEK');

export const getFormatterLayout = (
  chart: ICustomChart,
  metadata: IDimension[],
  businessRules: BusinessRules,
): string => {
  const point = chart?.hoverPoint;

  if (point) {
    const { curatedData, metric, groupDisplayName } = point.series.userOptions;
    const metricCleaned =
      metadata.find((metaEl: IDimension) => metaEl.id === metric)?.name ??
      metric;
    const dataGroup = groupDisplayName
      ? `<li>${groupDisplayName}</li><br/><br/>`
      : '';
    const dataMetricPoint = curatedData[point.index];

    if (businessRules.isMultiTarget) {
      const { multiTargetData } = point.series.userOptions;
      const targetCuratedName = multiTargetData
        ? multiTargetData[point.index].targetCuratedName
        : '';
      return `${targetCuratedName}, <span style="color:${point.color}">●</span> ${metricCleaned}: ${dataMetricPoint[1]}</span>`;
    }
    const dataMetric = `${dataGroup}<li>${metricCleaned}: ${dataMetricPoint[1]}</li>`;
    return `<span><span style="color:${point.color}">●</span> ${dataMetric}</span>`;
  }
  return 'no-data';
};

export const getTargetFormatterLayout = (chart: ICustomChart): string => {
  const point = chart?.hoverPoint;

  if (point) {
    const { curatedData } = point.series.userOptions;
    let breakdownTooltipNote = '%';
    if (point.series.userOptions.metric === 'Population Index') {
      breakdownTooltipNote = 'Population Index';
    }
    const breakdownTooltip = `${breakdownTooltipNote} of ${point.category} in ${
      point.series.userOptions.name
    } : ${parseFloat(curatedData[point.x][1]).toFixed(2)}${
      breakdownTooltipNote !== 'Population Index' ? '%' : ''
    }`;
    return `${point.category}</span><br/><span>${breakdownTooltip}</span>`;
  }
  return 'no-data';
};

const filterMultiTargetData = (
  data: DataRowGraph[],
  SGMConfig: ISGMConfig,
): DataRowGraph[] => {
  if (SGMConfig.meta?.targetList) {
    const targetList = SGMConfig.meta?.targetList;
    if (targetList.length) {
      return data.filter((x) =>
        targetList.includes(x?.TARGET?.VALUE?.toString() ?? ''),
      );
    }
  }
  return data;
};

export const getHighchartsConfig = (
  data: DataRowGraph[],
  metadata: IMetaData[],
  SGMConfig: ISGMConfig,
  configuration: IConfiguration,
  dateRange?: IDateRangeQueryFilter,
): IOptions => {
  const { metrics, groups } = SGMConfig;
  let copyData = data;

  const businessRules = getBusinessRules(
    groups,
    SGMConfig,
    configuration.isTarget ?? false,
  );

  if (businessRules.isMultiTarget) {
    copyData = filterMultiTargetData(copyData, SGMConfig);
  }

  if (businessRules.isHeatmap && groupContainsDayOfWeek(SGMConfig.groups)) {
    copyData = copyData.sort(
      (a: DataRowGraph, b: DataRowGraph) =>
        daysOfWeek[getValue(b.DAY_OF_WEEK) as DaysOfWeek] -
        daysOfWeek[getValue(a.DAY_OF_WEEK) as DaysOfWeek],
    );
  }
  const totalDimensions =
    groups.length +
    (businessRules.isAllViewing ? 1 : 0) +
    (businessRules.isHeatmap ? 1 : 0);
  const { series, categories_x, categories_y } = generateData(
    copyData,
    SGMConfig,
    totalDimensions,
    businessRules,
    metadata,
  );

  const { xAxis, yAxis } = getGroupConfigs(
    groups,
    metrics,
    metadata,
    categories_x,
    categories_y,
    businessRules,
    copyData,
    SGMConfig,
    dateRange,
  );

  const { colors } = configuration;
  let heatmapConfig = {};
  if (businessRules.isHeatmap) {
    const { min, max } = getMinMax3DimensionalSeriesData(series);
    heatmapConfig = {
      colorAxis: {
        min,
        max,
        stops: [
          [0, colorChart.bar7],
          [0.5, '#ffffff'],
          [1, colorChart.bar1],
        ],
      },
    };
  }

  const xAxisBaseConfig = [
    {
      lineWidth: 0,
    },
  ];

  const xAxisConfig = merge(xAxisBaseConfig, xAxis, configuration.xAxis).map(
    (groupSeries, groupIndex) => {
      const dataLength = get(`[${groupIndex}].data.length`, series) || 0;
      const rowLimit = SGMConfig.meta?.rowLimit;
      return {
        ...groupSeries,
        ...(rowLimit && {
          max: rowLimit > dataLength ? dataLength - 1 : rowLimit - 1,
        }),
      };
    },
  );

  const chartConfig = {
    ...getChartBasicConfig(series),
    xAxis: xAxisConfig,
    yAxis: merge(yAxis, configuration.yAxis),
    chart: getChartStyleConfig(SGMConfig, configuration),
    plotOptions: merge(getPlotOptions(metrics), configuration.plotOptions),
    colors,
    tooltip: {
      formatter: ({ chart }: { chart: ICustomChart }): string =>
        configuration.isTarget
          ? getTargetFormatterLayout(chart)
          : getFormatterLayout(chart, metadata, businessRules),
    },
    credits: {
      enabled: false,
    },
    time: {
      ...configuration.time,
    },
    ...(businessRules.isHeatmap && heatmapConfig),
  };

  return chartConfig;
};

export const getMetricsFromQuery = (
  selectedMetrics: Metric[],
  selectedCharts: ChartType[],
): MetricConfig[] =>
  selectedMetrics.map((metric: Metric, index: number) => {
    const chartType = selectedCharts[index];
    const stackingType = chartType
      ? getStackingType(chartType)
      : stackings.unstacked;
    const chartTypeBasic = getChartBasicType(chartType);
    return {
      metric,
      chartType: chartTypeBasic,
      ...(stackingType !== stackings.unstacked && { stacking: stackingType }),
    };
  });

export const getIntervalDimensions = (query: IQuery): string[] => {
  const spatialDimensions = [];
  spatialDimensions.push(...(query?.group ?? []));
  if (query?.interval?.id) spatialDimensions.push(query.interval.id);
  return spatialDimensions
    .filter((dimension) => dimension !== ReportTimeDimension.ALL_VIEWING)
    .filter((i) => i);
};

export const getFilledArrayElements = (array: string[]): string[] =>
  array.filter((x) => x);

export const getMetricConfigFromQuery = (
  selectedMetrics: string[],
  selectedCharts: string[],
  query: IQuery,
): MetricConfig[] => {
  const queryDefaultMetrics = getFilledArrayElements(
    query?.select?.length ? query.select : defaultMetrics,
  );
  const availableMetrics = filter(
    (metric) => queryDefaultMetrics.includes(metric),
    selectedMetrics,
  );
  const defaultMetricConfig = getMetricsFromQuery(
    availableMetrics || queryDefaultMetrics,
    selectedCharts || defaultCharts,
  );
  return defaultMetricConfig;
};

export const getSGMConfig = (
  query: IQuery,
  selectedMetrics: string[],
  selectedCharts: string[],
): ISGMConfig => {
  const partialSGMConfig: ISGMConfig = {
    series: [],
    groups: [],
    metrics: [],
  };
  let copySelectedMetrics = [...selectedMetrics];
  let copySelectedCharts = [...selectedCharts];

  const isTargetGroup = size(get('targets', query)) > 1;
  if (!copySelectedMetrics.length && !copySelectedCharts.length) {
    copySelectedMetrics = (query?.select?.length && [query.select[0]]) || [
      defaultMetrics[0],
    ];
    copySelectedCharts = [ChartTypes.column];
  }

  const newSeries = getSeriesFromQuery(query, partialSGMConfig, isTargetGroup);
  partialSGMConfig.series = newSeries;
  const newGroups = getGroupsFromQuery(query, partialSGMConfig, isTargetGroup);

  const metrics = getMetricConfigFromQuery(
    copySelectedMetrics,
    copySelectedCharts,
    query,
  );
  const filteredMetrics = metrics as MetricConfig[];

  const sgm = {
    series: newSeries,
    groups: newGroups,
    metrics: filteredMetrics,
  };

  return sgm;
};

export const getDefaultOrderBy = (isTarget?: boolean): IReportSorting => {
  if (isTarget) {
    return {
      field: 'ACCOUNT_ID',
      order: 'DESC',
    };
  }
  return {
    field: 'REACH',
    order: 'DESC',
  };
};
