import { isObject as isObjectLo, isUndefined } from 'lodash';
import { StringKeyValue } from 'types';

const isEmptyArray = (value: unknown): boolean =>
  Array.isArray(value) && value.length === 0;

const isBasicType = (value: unknown): boolean =>
  typeof value === 'string' ||
  isUndefined(value) ||
  typeof value === 'number' ||
  typeof value === 'boolean' ||
  Number.isNaN(value);

const isNullish = (value: unknown): boolean => {
  return (
    value === '' ||
    value === null ||
    isUndefined(value) ||
    isEmptyArray(value) ||
    isEmptyObject(value as StringKeyValue)
  );
};

const isEmptyObject = (value: StringKeyValue): boolean =>
  isObject(value) && isEmptyArray(Object.keys(value));

const isObject = (value: unknown): boolean =>
  isObjectLo(value) && value?.constructor?.name === 'Object';

export const removeEmptyTreeValues = (entry: unknown): void => {
  if (isBasicType(entry)) return;
  if (isNullish(entry)) return;
  if (Array.isArray(entry)) {
    entry.forEach((item, index) => {
      removeEmptyTreeValues(item);
      if (isNullish(item)) {
        entry.splice(index, 1);
      }
    });
    return;
  }
  if (isObject(entry as StringKeyValue)) {
    Object.entries(entry as StringKeyValue<unknown>).forEach(([key, value]) => {
      if (isNullish(value)) {
        delete (entry as StringKeyValue)[key as string];
        return;
      }
      if (isBasicType(value)) {
        return;
      }
      if (Array.isArray(value)) {
        removeEmptyTreeValues((entry as StringKeyValue)[key]);
        if (isNullish(value)) {
          delete (entry as StringKeyValue)[key as string];
        }
        return;
      }
      if (isObject(value)) {
        removeEmptyTreeValues((entry as StringKeyValue)[key]);
        if (isEmptyObject(value as StringKeyValue)) {
          delete (entry as StringKeyValue)[key as string];
          return;
        }
      }
    });
  }
};
