import Field from 'components/Field';
import { Type as InputType } from 'components/Input';
import Label from 'components/Label';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import useFetch from 'hooks/useFetch';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import FetchMethod from 'types/fetchMethod';
import ITextValue from 'types/textValue';
import { IFormUpdatedPayload } from 'types/uploads';

interface IForUseInImpactProps {
  uploadFormUpdated: (arg0: IFormUpdatedPayload) => void;
  forUseInImpact: boolean;
  userEmail: string;
  selectedClient: string;
  PIQ_CATEGORY: ITextValue[];
  PIQ_CHAIN: ITextValue[];
}

const usePlaceIQValues = ({
  selectedClient,
  userEmail,
}: Pick<IForUseInImpactProps, 'userEmail' | 'selectedClient'>): Record<
  'retrieveCategories' | 'retrieveChains',
  (
    valueFilter?: string,
    lineBegin?: number,
    lineEnd?: number,
  ) => Promise<ITextValue[]>
> &
  Record<'categoryValues' | 'chainValues', ITextValue[]> => {
  const { doFetch: fetchCategories } = useFetch<ITextValue>();
  const { doFetch: fetchChains } = useFetch<ITextValue>();
  const [categoryValues, setCategoryValues] = useState<ITextValue[]>([]);
  const [chainValues, setChainValues] = useState<ITextValue[]>([]);

  const retrieveCategories = useCallback(
    (valueFilter = '', lineBegin = 0, lineEnd = 0) =>
      new Promise<ITextValue[]>((resolve, reject) => {
        setCategoryValues([]);

        fetchCategories({
          endpoint: '/filterValues',
          payload: {
            userEmail,
            datasetIds: [],
            genericEventDatasets: [],
            field: 'PLACEIQ',
            valueFilter,
            childField: 'PIQ_CATEGORY',
            filterRules: [],
            selectedClient,
            lineBegin,
            lineEnd,
          },
          method: FetchMethod.POST,
          onError: reject,
          onSuccess: (d) => {
            resolve(d);
            setCategoryValues(d);
          },
        });
      }),
    [fetchCategories, selectedClient, userEmail],
  );

  const retrieveChains = useCallback(
    (valueFilter = '', lineBegin = 0, lineEnd = 0) =>
      new Promise<ITextValue[]>((resolve, reject) => {
        setChainValues([]);
        fetchChains({
          endpoint: '/filterValues',
          payload: {
            userEmail,
            datasetIds: [],
            genericEventDatasets: [],
            field: 'PLACEIQ',
            valueFilter,
            childField: 'PIQ_CHAIN',
            filterRules: [],
            selectedClient,
            lineBegin,
            lineEnd,
          },
          method: FetchMethod.POST,
          onError: reject,

          onSuccess: (d) => {
            resolve(d);
            setChainValues(d);
          },
        });
      }),
    [fetchChains, selectedClient, userEmail],
  );

  return { retrieveCategories, retrieveChains, categoryValues, chainValues };
};

export const ForUseInImpact: React.FC<
  PropsWithChildren<IForUseInImpactProps>
> = ({
  uploadFormUpdated,
  forUseInImpact,
  userEmail,
  selectedClient,
  PIQ_CATEGORY,
  PIQ_CHAIN,
}) => {
  const { retrieveCategories, retrieveChains, categoryValues, chainValues } =
    usePlaceIQValues({
      selectedClient,
      userEmail,
    });

  return (
    <div className="form-container">
      <div className="inline-field-container">
        <Label text="IMP4CT Advanced Settings" />

        <div className="field-container imp4ct-advanced-settings">
          <Field
            type={InputType.select}
            id="uploads-for-use-in-impact"
            name="uploads-for-use-in-impact"
            onChange={(value) =>
              uploadFormUpdated({
                key: 'dataset_metadata.fallback.dataset',
                value: value === 'yes' ? 'piq' : '',
              })
            }
            options={[
              { text: 'Yes', value: 'yes' },
              { text: 'No', value: 'no' },
            ]}
            value={forUseInImpact ? 'yes' : 'no'}
          />
        </div>
      </div>

      {forUseInImpact && (
        <section className="inline-field-container for-impact">
          <Label text="Matched Control Pre-Campaign Conversions" />

          <aside>
            <article>
              <Field
                type={InputType.select}
                options={[{ text: 'Category', value: 'category' }]}
                value="category"
                disabled
              />
              <Field
                type={InputType.select}
                options={[{ text: 'is one of', value: 'is_one_of' }]}
                value="is_one_of"
                disabled
              />

              <MultipleDropdownSelect
                queryProvider={retrieveCategories}
                loading={false}
                testId="PIQ_CATEGORY"
                selected={PIQ_CATEGORY}
                options={categoryValues}
                onChange={(value) =>
                  uploadFormUpdated({
                    key: 'dataset_metadata.fallback.category',
                    value,
                  })
                }
                placeholder="Select PIQ Category"
                enableHighlight
              />
            </article>
            <article>
              <Field
                type={InputType.select}
                options={[{ text: 'Chain', value: 'chain' }]}
                value="chain"
                disabled
              />
              <Field
                type={InputType.select}
                options={[{ text: 'is one of', value: 'is_one_of' }]}
                value="is_one_of"
                disabled
              />
              <MultipleDropdownSelect
                queryProvider={retrieveChains}
                loading={false}
                testId="PIQ_CHAIN"
                selected={PIQ_CHAIN}
                options={chainValues}
                onChange={(value) =>
                  uploadFormUpdated({
                    key: 'dataset_metadata.fallback.chain',
                    value,
                  })
                }
                placeholder="Select PIQ Chain"
                enableHighlight
              />
            </article>
          </aside>
        </section>
      )}
    </div>
  );
};
