import IReport, {
  ExposureConversionSetNames,
  ResultEnhancedDemographicGroups,
  ResultBase,
} from 'domains/reports/types';
import IResultSelection from 'domains/resultSelections/types';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as reportSelectors from 'store/selectors/report';
import * as reportResultSelectors from 'store/selectors/reportResult';
import * as resultSelectionsSelector from 'store/selectors/resultSelections';
import State from 'types/state';
import { IOption } from 'types/textValue';
import Storytelling from './storytelling';

interface ConnectedProps {
  targets?: string[];
  omissionMessages: ExposureConversionSetNames[];
  breakoutList: { name: string; id: string }[];
  demographicBreakoutList: { name: string; id: string }[];
  targetsObject: IOption[];
  showLroiInVisualization: boolean;
  useEquivalizedMetrics: boolean;
  segmentsList: IOption[];
  breakoutChartDataWithTop25: ResultBase;
  demographicChartData: ResultEnhancedDemographicGroups;
  miscResultData: ResultBase;
  resultSelection: IResultSelection;
  lastValidReport: IReport | null;
}

interface Props extends ConnectedProps {
  isOpened: boolean;
}

const Visualization: FunctionComponent<Props> = (props: Props) => {
  const {
    targets,
    omissionMessages,
    breakoutList,
    demographicBreakoutList,
    targetsObject,
    showLroiInVisualization,
    segmentsList,
    breakoutChartDataWithTop25,
    demographicChartData,
    miscResultData,
    resultSelection,
    lastValidReport,
    useEquivalizedMetrics,
    isOpened,
  } = props;

  return (
    <Storytelling
      breakoutChartData={breakoutChartDataWithTop25}
      demographicChartData={demographicChartData}
      miscResultData={miscResultData}
      targets={targets}
      showLroiInVisualization={showLroiInVisualization}
      useEquivalizedMetrics={useEquivalizedMetrics}
      targetsObject={targetsObject}
      breakoutList={breakoutList}
      omissionMessages={omissionMessages}
      demographicBreakoutList={demographicBreakoutList}
      segmentsList={segmentsList}
      resultSelection={resultSelection}
      lastValidReport={lastValidReport}
      isVisualizationOpened={isOpened}
    />
  );
};

// may need to send more selectors to map the ppt presentation
const mapStateToProps = (state: State): ConnectedProps => ({
  targets: reportSelectors.getAttributionReportTargetNames(state),
  omissionMessages: reportResultSelectors.getAttributionOmissionMessages(state),
  breakoutList: reportResultSelectors.getBreakoutList(state),
  demographicBreakoutList:
    reportResultSelectors.getDemographicBreakoutList(state),
  targetsObject: reportSelectors.getAttributionReportTargetObjects(state),
  segmentsList: reportSelectors.getAttributionReportSegmentsList(state),
  showLroiInVisualization:
    reportResultSelectors.getShowLroiInVisualization(state),
  useEquivalizedMetrics: reportSelectors.getEqMetricFromLastValidReport(state),
  breakoutChartDataWithTop25:
    reportResultSelectors.getBreakoutChartDataWithTop25(state),
  demographicChartData: reportResultSelectors.getDemographicChartData(state),
  miscResultData: reportResultSelectors.getMiscResultData(state),
  resultSelection: resultSelectionsSelector.getResultSelection(state),
  lastValidReport: reportSelectors.getLastValidReport(state),
});

export default connect(mapStateToProps)(Visualization);
