import { numberFormatter } from 'helpers/visualization';
import {
  Point,
  Series,
  SeriesOptionsType,
  TooltipFormatterContextObject,
} from 'highcharts';
import { ITooltipValueFormatters } from './commonChartDesignConfig';

interface IPointMarker {
  fillColor: string;
  radius: number;
  symbol: string;
}

interface IPointExtended extends Point {
  marker: IPointMarker;
  reach?: number;
  pcOfUniverse?: number;
}

export interface ISeriesExtended extends Series {
  isBubble: boolean;
  tooltipOptions: {
    tooltipValueFormatters: ITooltipValueFormatters;
  };
  setOptions: (options: SeriesOptionsType) => void;
}

export default function dumbbellTooltipFormatter(
  this: TooltipFormatterContextObject,
): string {
  const point = this.point as IPointExtended;

  const extraDemographicData = point.reach
    ? `<div class="tooltip-target-item">
  <div class="metric-title">
    Audience Attribute Size:
  </div>
  <div class="metric-value">
    ${numberFormatter(point.reach, 'number', 2)}
  </div>
</div>
<div class="tooltip-target-item">
  <div class="metric-title">
    Audience Universe Proportion:
  </div>
  <div class="metric-value">
    ${numberFormatter(point.pcOfUniverse, 'percent', 0)}
  </div>
</div>`
    : '';

  return `
    <div class="point-tooltip">
      <div class="tooltip-title" data-testid="tooltip-title">
        ${point.name}
      </div>
      <div class="tooltip-separator" ></div>
      <div class='tooltip-target-wrapper'>
        <div class="tooltip-target-item">
          <div class="metric-title">
            Population Index:
          </div>
          <div class="metric-value">
            ${numberFormatter(point.x, 'number', 0)}
          </div>
        </div>
        ${extraDemographicData}
      </div>
    </div>
  `;
}
