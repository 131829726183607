import { AxisLabelsFormatterContextObject } from 'highcharts';

export function xAxisLabelFormatter(
  this: AxisLabelsFormatterContextObject,
): string {
  return this.value
    .toString()
    .replace(/(DAYS|DAY|IMPRESSIONS)/, '')
    .trim();
}
