import { IOption } from 'types/textValue';

export const getPersistedTargets = (
  targetsList: IOption[],
  persistedTargets?: Array<IOption['value']>,
): IOption[] => {
  if (persistedTargets?.length === targetsList.length) return targetsList;

  return [
    targetsList.find(
      (t) => persistedTargets?.[0] === t.value || t.value === 'target',
    ) ?? targetsList[0],
  ];
};
