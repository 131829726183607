import IReport from 'domains/reports/types';
import { IReportToolbarProps } from 'domains/toolbar/types';
import { set } from 'lodash/fp';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';

export const initialState: IReportToolbarProps = {
  isCancelRunDisabled: true,
  isRefreshDisabled: true,
  isReportNameDisabled: false,
  isRefreshLaunched: false,
  isRunDisabled: true,
  isRunLaunched: false,
  isSaveAsCopyDisabled: true,
  isSaveDisabled: true,
  lastCachedDate: undefined,
  isPublicPrivateDisabled: true,
  isLoadingReadOnlyToggle: false,
  isAuditDisabled: true,
  isLinkDisabled: true,
  onCancelRun: () => {},
  onRefresh: () => {},
  onRun: () => {},
  onSave: () => {},
  onSaveAsCopy: () => {},
  onExportConfiguration: () => {},
  progress: 0,
  queryProgress: {},
  reportType: 'report',
  showRefreshButton: false,
};

const setCancelIsDisabled = set('isCancelRunDisabled');
const disableCancel = setCancelIsDisabled(true);
const enableCancel = setCancelIsDisabled(false);

const setRefreshIsDisabled = set('isRefreshDisabled');
const disableRefresh = setRefreshIsDisabled(true);
const enableRefresh = setRefreshIsDisabled(false);

const setRunIsDisabled = set('isRunDisabled');
const disableRun = setRunIsDisabled(true);
const enableRun = setRunIsDisabled(false);

const setSaveIsDisabled = set('isSaveDisabled');
const disableSave = setSaveIsDisabled(true);
const enableSave = setSaveIsDisabled(false);

const setSaveAsIsDisabled = set('isSaveAsCopyDisabled');
const disableSaveAs = setSaveAsIsDisabled(true);
const enableSaveAs = setSaveAsIsDisabled(false);

const setLastCachedDate = set('lastCachedDate');
const setRefreshLaunched = set('isRefreshLaunched');
const setRunLaunched = set('isRunLaunched');
const setProgress = set('progress');
const setQueryProgress = set('queryProgress');
const setType = set('reportType');
const setAuditDisabled = set('isAuditDisabled');

const setExportDisabled = set('isExportDisabled');
const setExportRunning = set('isExportRunning');

const setPublicPrivateIsDisabled = set('isPublicPrivateDisabled');
const enablePublicPrivate = setPublicPrivateIsDisabled(false);
const disablePublicPrivate = setPublicPrivateIsDisabled(true);

const setAuditIsDisabled = set('isAuditDisabled');
const enableAudit = setAuditIsDisabled(false);
const disableAudit = setAuditIsDisabled(true);

const setLinkDisabled = set('isLinkDisabled');
const enableLink = setLinkDisabled(false);
const disableLink = setLinkDisabled(true);

const reducer = (
  toolbar: IReportToolbarProps = initialState,
  action: Action<IReportToolbarProps | IReport | number>,
): IReportToolbarProps => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.TOOLBAR_DISABLE_CANCEL:
      return disableCancel(toolbar);
    case ActionType.TOOLBAR_DISABLE_REFRESH:
      return disableRefresh(toolbar);
    case ActionType.TOOLBAR_DISABLE_RUN:
      return disableRun(toolbar);
    case ActionType.TOOLBAR_DISABLE_SAVE:
      return disableSave(toolbar);
    case ActionType.TOOLBAR_DISABLE_SAVE_AS_COPY:
      return disableSaveAs(toolbar);
    case ActionType.TOOLBAR_ENABLE_CANCEL:
      return enableCancel(toolbar);
    case ActionType.TOOLBAR_ENABLE_REFRESH:
      return enableRefresh(toolbar);
    case ActionType.TOOLBAR_ENABLE_RUN:
      return enableRun(toolbar);
    case ActionType.TOOLBAR_ENABLE_SAVE:
      return enableSave(toolbar);
    case ActionType.TOOLBAR_ENABLE_SAVE_AS_COPY:
      return enableSaveAs(toolbar);
    case ActionType.TOOLBAR_SET_LAST_CACHED_DATE:
      return setLastCachedDate(payload, toolbar);
    case ActionType.TOOLBAR_SET_IS_REFRESH_LAUNCHED:
      return setRefreshLaunched(payload, toolbar);
    case ActionType.TOOLBAR_SET_IS_RUN_LAUNCHED:
      return setRunLaunched(true, toolbar);
    case ActionType.TOOLBAR_SET_PROGRESS:
      return setProgress(Number(payload), toolbar);
    case ActionType.TOOLBAR_SET_QUERY_PROGRESS:
      return setQueryProgress(payload, toolbar);
    case ActionType.TOOLBAR_SET_REPORT_TYPE:
      return setType(payload, toolbar);
    case ActionType.TOOLBAR_DISABLE_REPORT_PUBLIC_PRIVATE:
      return disablePublicPrivate(toolbar);
    case ActionType.TOOLBAR_ENABLE_REPORT_PUBLIC_PRIVATE:
      return enablePublicPrivate(toolbar);
    case ActionType.TOOLBAR_DISABLE_AUDIT:
      return disableAudit(toolbar);
    case ActionType.TOOLBAR_ENABLE_AUDIT:
      return enableAudit(toolbar);
    case ActionType.TOOLBAR_DISABLE_LINK:
      return disableLink(toolbar);
    case ActionType.TOOLBAR_ENABLE_LINK:
      return enableLink(toolbar);
    case ActionType.TOOLBAR_DISABLE_CANCEL_BUTTON:
      return disableCancel(toolbar);
    case ActionType.TOOLBAR_ENABLE_CANCEL_BUTTON:
      return enableCancel(toolbar);
    case ActionType.TOOLBAR_SET_IS_REFRESH_NOT_LAUNCHED:
      return setRefreshLaunched(false, toolbar);
    case ActionType.TOOLBAR_SET_IS_RUN_NOT_LAUNCHED:
      return setRunLaunched(false, toolbar);
    case ActionType.TOOLBAR_ENABLE_AUDIT_BUTTON:
      return setAuditDisabled(false, toolbar);
    case ActionType.TOOLBAR_DISABLE_AUDIT_BUTTON:
      return setAuditDisabled(true, toolbar);
    case ActionType.TOOLBAR_ENABLE_EXPORT_BUTTON:
      return setExportDisabled(false, toolbar);
    case ActionType.TOOLBAR_DISABLE_EXPORT_BUTTON:
      return setExportDisabled(true, toolbar);
    case ActionType.TOOLBAR_SET_EXPORT_RUNNING:
      return setExportRunning(true, toolbar);
    case ActionType.TOOLBAR_SET_EXPORT_NOT_RUNNING:
      return setExportRunning(false, toolbar);
    case ActionType.TOOLBAR_SET_READ_ONLY_LOAD_STARTED:
      return { ...toolbar, isLoadingReadOnlyToggle: true };
    case ActionType.TOOLBAR_SET_READ_ONLY_LOAD_ENDED:
      return { ...toolbar, isLoadingReadOnlyToggle: false };
    default:
      return toolbar;
  }
};

export default reducer;
