import { useEffect, useState } from 'react';
import { Index } from 'routes';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import IRole from 'types/role';
import useFetch from './useFetch';
import useToast from './useToast';

export type HookReturn = {
  roles: IRole[];
  loading: boolean;
  resetRoles: () => void;
};

const useRoles = (): HookReturn => {
  const [roles, setRoles] = useState<IRole[]>();
  const [loading, setLoading] = useState(false);
  const { doErrorToast } = useToast();
  const { doFetch } = useFetch<IRole>();

  useEffect(() => {
    if (loading || roles) return;
    setLoading(true);
    doFetch({
      endpoint: `/${Index.SEGMENT_ROLES}`,
      method: FetchMethod.GET,
      onSuccess: (res: IRole[]) => {
        setRoles(res);
        setLoading(false);
      },
      onError: (e: IError) => {
        setRoles([]);
        doErrorToast(e?.message ?? 'There has been an error.');
        setLoading(false);
      },
    });
  }, [doErrorToast, doFetch, loading, roles]);

  const resetRoles = (): void => setRoles(undefined);

  return {
    roles: roles ?? [],
    loading,
    resetRoles,
  };
};

export default useRoles;
