import { ErrorMeta } from '../domains/reports/types';

type IError = {
  message?: string;
  technical?: string;
  code?: string;
  data?: object;
  meta?: ErrorMeta;
};

export const clientValidationErrors = {
  duplicatedExtID: 'Duplicated Ext. ID!',
  duplicatedName: 'Duplicated name!',
};

export default IError;
