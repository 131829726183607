import {
  ExposureConversionSetNames,
  IHighchartsSeriesData,
  PerformanceGroupNames,
  ResultBase,
} from '../domains/reports/types';
import { IOption } from './textValue';

export const savingStatus = {
  none: '',
  saving: 'SAVING',
  failed: 'FAILED',
  success: 'SUCCESS',
} as const;

export type SegmentsSavingStatus =
  (typeof savingStatus)[keyof typeof savingStatus];

export interface IAttributionReportResult {
  resultData?: {
    breakouts: ResultBase;
    demographicStats: ResultBase;
    miscResultData: ResultBase;
    specializedInsights?: {
      chartData: IHighchartsSeriesData[];
      selectedTarget: IOption[];
      targetOptions: IOption[];
    };
  };
  performanceMetricGroups: PerformanceGroupNames[];
  conversionType?: string;
  showLroiInVisualization: boolean;
  vendorSourceName?: string;
  omissionMessages?: Array<ExposureConversionSetNames>;
  segmentsSavingStatus: SegmentsSavingStatus;
  errorMessage?: string;
  downloadQuery?: boolean;
  fileUpload?: boolean;
}
