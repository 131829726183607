import { array, object, string, TestContext } from 'yup';
import { IUserDimensionValue } from 'domains/datasets/types';
import IDimension from 'domains/dimensions/types';
import {
  CLIENT_REQUIRED_ERROR_MESSAGE,
  CUSTOM_VALUES_ERROR_MESSAGE,
  DATASET_NAME_REQUIRED_ERROR_MESSAGE,
  DATASET_NAME_UNIQUE_ERROR_MESSAGE,
  DESCRIPTION_REQUIRED_ERROR_MESSAGE,
  MAPPED_VALUE_REQUIRED_ERROR_MESSAGE,
  MAPPED_VALUE_UNIQUE_ERROR_MESSAGE,
  PATH_REQUIRED_ERROR_MESSAGE,
  SOURCE_DIMENSION_REQUIRED_ERROR_MESSAGE,
  SOURCE_VALUES_REQUIRED_ERROR_MESSAGE,
} from './locale';

const customValueSchema = object().shape({
  mapped_value: string()
    .required(MAPPED_VALUE_REQUIRED_ERROR_MESSAGE)
    .test(
      'mapped_value',
      MAPPED_VALUE_UNIQUE_ERROR_MESSAGE,
      (value, context) => {
        const currentValue = context?.from?.[0]?.value as
          | IUserDimensionValue
          | undefined;

        if (!currentValue) {
          return true;
        }

        return !context?.from?.[1].value.custom_values.some(
          (customValue: IUserDimensionValue) =>
            currentValue.id !== customValue.id &&
            customValue.mapped_value === currentValue.mapped_value,
        );
      },
    ),
  source_values: array(
    object().shape({
      text: string(),
      value: string(),
    }),
  ).min(1, SOURCE_VALUES_REQUIRED_ERROR_MESSAGE),
});

export const validationsSchemaBuilder = (dimensions: IDimension[]): unknown =>
  object().shape({
    type: string(),
    user_dimension_name: string()
      .required(DATASET_NAME_REQUIRED_ERROR_MESSAGE)
      .test(
        'user_dimension_name',
        DATASET_NAME_UNIQUE_ERROR_MESSAGE,
        (value, context: TestContext) =>
          !dimensions.some(
            (dimension) =>
              dimension.name?.trim() === value.trim() &&
              context.from?.[0]?.value?.dataset_id !==
                dimension.id.replace(/(user_dim_)(\d+)(_.+)/, '$2'),
          ),
      ),
    description: string().required(DESCRIPTION_REQUIRED_ERROR_MESSAGE),
    source_dimension: string().required(
      SOURCE_DIMENSION_REQUIRED_ERROR_MESSAGE,
    ),
    clients: array(object().shape({ text: string(), value: string() }))
      .required(CLIENT_REQUIRED_ERROR_MESSAGE)
      .min(1, CLIENT_REQUIRED_ERROR_MESSAGE),
    path: string().required(PATH_REQUIRED_ERROR_MESSAGE),
    fallback_value: string(),
    custom_values: array(customValueSchema)
      .min(1, CUSTOM_VALUES_ERROR_MESSAGE)
      .required(),
  });
