import ConfirmationDialog from 'components/ConfirmationDialog';
import Loading from 'components/Loading';
import IReport, { reportSubType, IBusinessData } from 'domains/reports/types';
import AttributionReportsEdit from 'features/attributionReports/EditFeature/edit.feature';
import useAttributionReports from 'hooks/useAttributionReports';
import { isEmpty } from 'lodash/fp';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as reportActions from 'store/actions/report';
import * as businessDataSelectors from 'store/selectors/businessData';
import * as reportSelectors from 'store/selectors/report';
import State from 'types/state';

type ConnectedProps = {
  businessData: IBusinessData;
  report: IReport;
  isBaseReportFullyLoaded: boolean;
};

type ConnectedActions = {
  loadNewAttributionReport: (arg0: string) => void;
  loadReportFull: (arg0: string, isTarget: boolean) => void;
  setReport: (report: IReport | null) => void;
};

interface OwnProps extends ConnectedProps, ConnectedActions {}

const AttributionReportsEditContainer = (props: OwnProps): ReactElement => {
  const {
    businessData,
    loadNewAttributionReport,
    loadReportFull,
    report,
    setReport,
    isBaseReportFullyLoaded,
  } = props;

  const { id, subType: paramSubType } = useParams<{
    id: string;
    subType: string;
  }>();
  const { getReportSubTypeKey } = useAttributionReports();
  const [errorMessage, setErrorMessage] = useState('');
  const subType = getReportSubTypeKey(
    paramSubType ?? reportSubType.tvTuneIn.name,
  );
  const [loading, setLoading] = useState(false);

  // Unmount
  useEffect(
    () => (): void => {
      setReport(null);
    },
    [id, setReport],
  );

  useEffect(() => {
    if (loading || (report?.id && report?.id === id)) return;
    setLoading(true);
    if (id && !loading) {
      loadReportFull(id, false);
    } else {
      loadNewAttributionReport(subType);
    }
  }, [id, loadNewAttributionReport, loadReportFull, loading, report, subType]);

  const reportIsLoaded =
    !isEmpty(businessData) &&
    id &&
    report?.id === id &&
    report.query &&
    !report.notPersistedProps?.isBaseReportNew &&
    isBaseReportFullyLoaded;
  const reportIsNew =
    !isEmpty(businessData) &&
    !id &&
    report?.query &&
    report?.notPersistedProps?.isBaseReportNew &&
    report.subType === subType &&
    isBaseReportFullyLoaded;

  return (
    <>
      <ConfirmationDialog
        isOpen={!!errorMessage}
        onConfirm={(): void => {
          setErrorMessage('');
        }}
      >
        <p>{errorMessage}</p>
      </ConfirmationDialog>
      {reportIsLoaded || reportIsNew ? <AttributionReportsEdit /> : <Loading />}
    </>
  );
};

const mapStateToProps = (state: State): ConnectedProps => ({
  report: reportSelectors.getReport(state),
  businessData: businessDataSelectors.getBusinessData(state),
  isBaseReportFullyLoaded: reportSelectors.isBaseReportFullyLoaded(state),
});

const mapDispatchToProps: ConnectedActions = {
  loadNewAttributionReport: reportActions.loadNewAttributionReport,
  loadReportFull: reportActions.loadReportFull,
  setReport: reportActions.setReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(AttributionReportsEditContainer));
