import { AggregationMethodOptions } from 'domains/reports/types';
import ITextValue from 'types/textValue';

export const LroiMethodOptions: ITextValue[] = [
  {
    text: 'Simple Control',
    value: AggregationMethodOptions.NAIVE,
  },
  {
    text: 'Matched Control',
    value: AggregationMethodOptions.ADVANCED,
  },
];
