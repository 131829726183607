import { SampleGroupNames } from '../../domains/reports/types';

export const getSeriesId = (
  target: SampleGroupNames,
  cumulative: boolean,
  suffix?: string,
): string =>
  [target, cumulative ? 'cumulative' : '', suffix]
    .filter((item) => item)
    .join('-');
