import Icon, {
  Type as IconType,
  Size as IconSize,
  Color as IconColor,
} from 'components/Icon';
import { getTestId, getId, getClass } from 'helpers/components';
import React, {
  forwardRef,
  KeyboardEventHandler,
  MouseEvent,
  ReactNode,
  Ref,
  RefObject,
  ReactElement,
} from 'react';

export const chipComponentName = 'chip';

export const ICON_CLOSE_DOM_KEY = 'close';

const kinds = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

type Kind = (typeof kinds)[keyof typeof kinds];

export interface Props {
  testId?: string;
  id?: string;
  type?: Kind;
  children?: ReactNode;
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void;
  disabled?: boolean;
  canRemove?: boolean;
  canReorder?: boolean;
}
type RefType = {
  chip: Ref<HTMLSpanElement>;
  removeIcon: Ref<HTMLSpanElement>;
};
const Chip = forwardRef<RefType, Props>((props, ref) => {
  const {
    id,
    testId,
    type,
    children,
    onClick,
    disabled,
    canRemove = true,
    canReorder,
  } = props;
  const { chip, removeIcon } = (ref as RefObject<RefType>)?.current ?? {};
  const handleClick = (event: MouseEvent<HTMLSpanElement>): void => {
    if (disabled || !onClick || !canRemove) {
      return;
    }

    onClick(event);
  };

  const chipId = getId(chipComponentName, id);
  const chipTestId = getTestId(chipComponentName, testId);
  const childrenElement = children as ReactElement;
  const lessThan40Chars =
    (typeof children === 'string' && children.length < 40) ||
    (typeof childrenElement?.props?.children === 'string' &&
      childrenElement?.props?.children.length < 40);
  const chipClass = getClass(chipComponentName, {
    text: [type],
    boolean: [
      { state: canReorder, class: 'draggable' },
      { state: canRemove && !disabled, class: 'canRemove' },
      { state: lessThan40Chars, class: 'short-content' },
    ],
  });
  const chipCloseClass = getClass(chipComponentName, {
    concat: [ICON_CLOSE_DOM_KEY],
  });
  const chipCloseTestId = `${chipTestId}-${ICON_CLOSE_DOM_KEY}`;

  return (
    <span id={chipId} className={chipClass} data-testid={chipTestId} ref={chip}>
      {children}
      {!disabled && canRemove && (
        <span
          className={chipCloseClass}
          data-testid={chipCloseTestId}
          onClick={handleClick}
          onKeyUp={
            handleClick as unknown as KeyboardEventHandler<HTMLSpanElement>
          }
          ref={removeIcon}
        >
          <Icon
            type={IconType.close2}
            size={IconSize.micro}
            color={IconColor.light}
          />
        </span>
      )}
    </span>
  );
});

export default Chip;
