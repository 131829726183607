import useSelectedClient from 'domains/clients/useSelectedClient';
import {
  IDataset,
  DatasetTypes,
  DatasetSubTypes,
} from 'domains/datasets/types';
import { AccumulatedEntries, getAccumulatedEntries } from 'hooks/helpers';
import useFetch from 'hooks/useFetch';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDatasets } from 'store/actions/domains';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import State from 'types/state';
import { Index as DOMAINS } from '../../../routes';

export type HookReturn = {
  datasets: IDataset[];
  indexedDatasets: AccumulatedEntries;
  setDatasets: (datasets: IDataset[]) => void;
  getDataset: (id?: string) => IDataset | undefined;
  getScheduleDatasets: () => IDataset[] | undefined;
  getScheduleDatasetsForSelectedClient: () => IDataset[] | undefined;
  getGenericEventDatasets: () => IDataset[] | undefined;
  getGenericEventDatasetsForSelectedClient: () => IDataset[] | undefined;
  getCrosswalkCombinationDatasetsForSelectedClient: () =>
    | IDataset[]
    | undefined;
};

export const useDatasets = (): HookReturn => {
  const datasets = useSelector(
    ({ domains: { datasets: datasetsState = [] } }: State) => datasetsState,
  );
  const { selectedClient } = useSelectedClient();

  const getDataset = (id?: string): IDataset | undefined =>
    datasets.find((dataset: IDataset) => dataset.id === id);

  const getScheduleDatasets = (): IDataset[] | undefined =>
    datasets.filter(
      (dataset: IDataset) => dataset?.type === DatasetTypes.schedule,
    );

  const getCrosswalkCombinationDatasets = (): IDataset[] | undefined =>
    datasets.filter(
      (dataset: IDataset) =>
        dataset?.type === DatasetTypes.crosswalkCombination,
    );

  const getScheduleDatasetsForSelectedClient = (): IDataset[] | undefined =>
    getScheduleDatasets()?.filter((dataset: IDataset) =>
      selectedClient.dataset_rights?.includes(dataset.id),
    );

  const getGenericEventDatasets = (): IDataset[] | undefined =>
    datasets.filter(
      (dataset: IDataset) =>
        dataset?.type === DatasetTypes.genericEvents &&
        dataset.subType !== DatasetSubTypes.conversions,
    );

  const getGenericEventDatasetsForSelectedClient = (): IDataset[] | undefined =>
    getGenericEventDatasets()?.filter((dataset: IDataset) =>
      selectedClient.dataset_rights?.includes(dataset.id),
    );

  const getCrosswalkCombinationDatasetsForSelectedClient = ():
    | IDataset[]
    | undefined =>
    getCrosswalkCombinationDatasets()?.filter((dataset: IDataset) =>
      selectedClient.dataset_rights?.includes(dataset.id),
    );

  const dispatch = useDispatch();
  const indexedDatasets: AccumulatedEntries = useMemo(
    () => getAccumulatedEntries(datasets),
    [datasets],
  );

  return {
    indexedDatasets,
    datasets,
    setDatasets: (d: IDataset[]): void => {
      dispatch(setDatasets(d));
    },
    getDataset,
    getScheduleDatasets,
    getScheduleDatasetsForSelectedClient,
    getGenericEventDatasets,
    getGenericEventDatasetsForSelectedClient,
    getCrosswalkCombinationDatasetsForSelectedClient,
  };
};

type GetConfiguration = {
  onSuccess?: (datasets: IDataset[]) => void;
  onError?: (error: IError) => void;
};

export type HookAPIReturn = {
  loading: boolean;
  get: (configuration: GetConfiguration) => void;
};

function useAPIDatasets(): HookAPIReturn {
  const { loading, doFetch } = useFetch<IDataset>();

  const get = useCallback(
    async (configuration: GetConfiguration): Promise<void> => {
      const { onSuccess, onError } = configuration;

      doFetch({
        endpoint: `/${DOMAINS.SEGMENT_DATASETS}`,
        method: FetchMethod.GET,
        onError,
        onSuccess,
      });
    },
    [doFetch],
  );

  return {
    loading,
    get,
  };
}

export default useAPIDatasets;
