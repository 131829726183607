import { get, set } from 'lodash/fp';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';
import ILoggedUserState from 'types/userState';

export const initialState: ILoggedUserState = {};

const setAllClients = set('allClients');
const setClients = set('clients');
const setEmail = set('email');
const setName = set('name');
const setPermissions = set('permissions');
const setSelectedClient = set('selectedClient');
const setToken = set('token');
const setMinDate = set('minDate');

const reducer = (
  user: ILoggedUserState = initialState,
  action: Action<ILoggedUserState>,
): ILoggedUserState => {
  const { payload, type } = action;
  const name = get('name', payload);

  switch (type) {
    case ActionType.USER_SET_ALL_CLIENTS:
      return setAllClients(payload, user);
    case ActionType.USER_SET_CLIENTS:
      return setClients(payload, user);
    case ActionType.USER_SET_EMAIL:
      return setEmail(payload, user);
    case ActionType.USER_SET_PERMISSIONS:
      return setPermissions(payload, user);
    case ActionType.USER_SET_SELECTED_CLIENT:
      return setSelectedClient(payload, user);
    case ActionType.USER_LOGIN_SUCCESS:
      return payload;
    case ActionType.USER_SET_TOKEN:
      return setToken(payload, user);
    case ActionType.USER_LOGIN_FAIL:
    case ActionType.USER_LOGOUT:
      return initialState;
    case ActionType.USER_UPDATE:
      return setName(name, user);
    case ActionType.USER_SET_MIN_DATE:
      return setMinDate(payload, user);
    default:
      return user;
  }
};

export default reducer;
