import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType, FunctionComponent } from 'react';
import { ConnectedComponent } from 'react-redux';

interface ProtectedRouteProps {
  component: ComponentType | ConnectedComponent<FunctionComponent, unknown>;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  component,
}: ProtectedRouteProps) => {
  const Feature = withAuthenticationRequired(component);
  return <Feature />;
};

export default ProtectedRoute;
