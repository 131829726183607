import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import { LOCALE_NO_BREAKOUTS_WITH_POSITIVE_LIFT } from 'domains/reports/locales/general';
import { getClass } from 'helpers/components';
import React, { ReactElement } from 'react';

const componentName = 'omission-alert-card';
const componentClass = getClass(componentName);

export const OmissionAlertCard = (): ReactElement => (
  <section className={componentClass}>
    <InlineAlert
      mode={AlertTypes.omission}
      message={LOCALE_NO_BREAKOUTS_WITH_POSITIVE_LIFT}
    />
  </section>
);
