import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';

const Redirect: React.FC<{ to: string }> = ({ to }) => {
  window.location.replace(to);

  return null;
};

const MaskedRedirects: React.FC = () => (
  <Switch>
    <Route
      path="/portals"
      element={
        <Redirect to="https://605tv.atlassian.net/servicedesk/customer/portals" />
      }
    />
    <Route
      path="/ticket"
      element={
        <Redirect to="https://605tv.atlassian.net/servicedesk/customer/portal/15/group/33/create/113" />
      }
    />
    <Route
      path="/faq"
      element={
        <Redirect to="https://605tv.atlassian.net/wiki/spaces/TSP/pages/3106439169/Custom+Uploads+FAQ" />
      }
    />
    <Route
      path="/*"
      element={
        <Redirect to="https://605tv.atlassian.net/servicedesk/customer/portal/15" />
      }
    />
  </Switch>
);

export default MaskedRedirects;
