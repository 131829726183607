import Accordion from 'components/Accordion';
import ActionsBar from 'components/ActionsBar';
import Box, { Type as BoxType } from 'components/Box';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Button';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Field from 'components/Field';
import Flex, { Horizontal, Vertical } from 'components/Flex';
import H3 from 'components/H3';
import Icon, {
  Color as IconColor,
  Size as IconSize,
  Type as IconType,
} from 'components/Icon';
import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import InlineEdit from 'components/InlineEdit';
import { NewInputValue, Type as InputType } from 'components/Input';
import KebabDrawer from 'components/KebabDrawer';
import List from 'components/List';
import Modal, { Type } from 'components/Modal';
import ReportEventsTable from 'components/ReportEventsTable';
import SkeletonChartBars from 'components/Skeleton/ChartBars';
import StickyHeader from 'components/StickyHeader';
import { Style as TooltipStyle } from 'components/Tooltip';
import useSelectedClient from 'domains/clients/useSelectedClient';
import {
  isBaseReportExporting,
  isBaseReportInitiallyExecuted,
} from 'domains/reports/adapters/rulesets';
import { LOCALE_NOT_ENOUGH_HH } from 'domains/reports/locales/general';
import { getUserCanSeeLroiOptions } from 'domains/reports/rulesets/attributionReport';
import IReport, {
  cacheMode,
  IAttributionReport,
  INotPersistedProps,
  ReportType,
  reportTypeEquivalences,
  UNSAVED_REPORT_ID,
} from 'domains/reports/types';
import AttributionQueryBuilder from 'features/attributionReports/components/AttributionQueryBuilder';
import { ModalType } from 'features/reports/EditFeature/constants';
import {
  LOCALE_OK,
  LOCALE_SAVE_AND_RUN,
} from 'features/reports/EditFeature/locale';
import { getClass, getId, getTestId } from 'helpers/components';
import { copyCurrentUrl, removeRunParamFromUrl } from 'helpers/general';
import { trackInlineValidationShown } from 'helpers/mixpanel';
import useCurrentUser from 'hooks/useCurrentUser';
import useItemAdmin from 'hooks/useItemAdmin';
import useModal from 'hooks/useModal';
import { useReportConfiguration } from 'hooks/useReportConfiguration';
import useReports from 'hooks/useReports';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import useUsers from 'hooks/useUsers';
import { omit, partial } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import React, {
  FunctionComponent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import RBButton from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Actions, Index } from 'routes';
import IError from 'types/error';
import { v4 as uuid } from 'uuid';
import DropdownWithHeaders, {
  IDropdowndWithHeadersOption,
} from 'components/DropdownWithHeaders';
import ReportToolbar from '../../../components/ReportToolbar';
import { IMeta } from '../../../types/apiReturn';
import ToggleIsPublicButton from '../../reports/components/ToggleIsPublic';
import ToggleIsReadOnlyButton from '../../reports/components/ToggleIsReadOnly';
import Methodology from '../components/Methodology';
import { QueryProgress } from '../components/QueryProgress/QueryProgress';
import Visualization from '../components/visualization';
import {
  DOM_KEY_ADD_NAME_BODY,
  DOM_KEY_ADD_NAME_HEADER,
  DOM_KEY_AUDIT_BODY,
  DOM_KEY_AUDIT_HEADER,
  DOM_KEY_CONTROL_SEGMENT,
  DOM_KEY_NO_DATA,
  DOM_KEY_NOT_ENOUGH,
  DOM_KEY_QUERY,
  DOM_KEY_REPORT_FORM,
  DOM_KEY_RESULTS,
  GET_KEY_RUN,
} from './constants';
import {
  LOCALE_AUDIT_BUTTON_TOOLTIP_LABEL,
  LOCALE_AUDIT_MODAL_TITLE,
  LOCALE_CANCEL_REPORT_RUN,
  LOCALE_COPY_LINK_BUTTON_TOOLTIP_LABEL,
  LOCALE_DELETE_STOP_QUERY_HEADER,
  LOCALE_DELETE_STOP_QUERY_TEXT,
  LOCALE_EXPORT_BUTTON_TEXT,
  LOCALE_EXPORT_LABEL_XLXS,
  LOCALE_DESKTOP_DOWNLOAD,
  LOCALE_S3_UPLOAD,
  LOCALE_EXPORT_RUNNING_BUTTON_TEXT,
  LOCALE_FIELD_METHODOLOGY,
  LOCALE_FIELD_QUERY,
  LOCALE_FIELD_RESULT,
  LOCALE_GO_BACK,
  LOCALE_INVALID_TARGET,
  LOCALE_IS_PRIVATE,
  LOCALE_IS_PUBLIC,
  LOCALE_LINK_COPIED,
  LOCALE_MISSING_REPORT_NAME_MODAL_TEXT,
  LOCALE_MISSING_REPORT_NAME_MODAL_TITLE,
  LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_MODAL_TITLE,
  LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_NAME_LABEL,
  LOCALE_NO_CHART_DATA,
  LOCALE_NOTIFICATION_HEADER,
  LOCALE_REPORT_CREATED,
  LOCALE_REPORT_UPDATED,
  LOCALE_REPORT_VALIDATION_ERROR,
} from './locale';
import { IProps } from './types';

export const editReportComponentName = 'edit-attribution-report';

const editReportAddNameHeaderClass = getClass(editReportComponentName, {
  concat: [DOM_KEY_ADD_NAME_HEADER],
});
const editReportAddNameBodyClass = getClass(editReportComponentName, {
  concat: [DOM_KEY_ADD_NAME_BODY],
});
const editReportAuditHeaderClass = getClass(editReportComponentName, {
  concat: [DOM_KEY_AUDIT_HEADER],
});
const editReportAuditBodyClass = getClass(editReportComponentName, {
  concat: [DOM_KEY_AUDIT_BODY],
});
const editFormNoDataClass = getClass(editReportComponentName, {
  concat: [DOM_KEY_NO_DATA],
});
const editReportQueryClass = getClass(editReportComponentName, {
  concat: [DOM_KEY_QUERY],
});
const formControlSegmentClass = getClass(editReportComponentName, {
  concat: [DOM_KEY_CONTROL_SEGMENT],
});
const formResultsClass = getClass(editReportComponentName, {
  concat: [DOM_KEY_RESULTS],
});
const editReportNotEnough = getClass(editReportComponentName, {
  concat: [DOM_KEY_NOT_ENOUGH],
});

export const EditFeature: FunctionComponent<IProps> = (props: IProps) => {
  const [nameIsEditing, setNameIsEditing] = useState<boolean>(false);
  const [missingName, setMissingName] = useState<string>();

  const {
    events,
    confirmationDialog,
    modalDialog,
    isBaseReportNew,
    isReportNameDisabled,
    attributionReportResult,
    invalidTargetList,
    isAccordionQueryOpen,
    isAccordionResultsOpen,
    isAccordionMethodologyOpen,
    isBaseReportSaving,
    isLoadingReadOnlyToggle,
    isDownloadingPresentation,
    report,
    resetAttributionReportResult,
    setAttributionReportList,
    setProgress,
    isReportInvalid,
    setReport,
    handleUpdateName,
    setType,
    validTargetList,
    runQuery,
    showReportPayload,
    runDownloadQuery,
    runFileUploadQuery,
    cancelExecutionJobAction,
    setUserConfirmationAction,
    setAcceptDialogModal,
    processRules,
    handleSubmitAttributionForm,
    handleRemoveMissingReportName,
    onSaveEmptyNameReport,
    run: toolbarRun,
    cancel: toolbarCancel,
    errorMessage,
    setAccordionQueryClosed,
    setAccordionQueryOpen,
    messageList,
    setIsReportInvalid,
    setErrorMessage,
    resetDashboardReport,
    handleToggleReadOnly,
    handleDownloadPresentation,
    handleCloneReport,
  } = props;

  const navigate = useNavigate();

  const {
    getSaveAsCopyReportName,
    isLoadingToggleReportPublicPrivate,
    isReportNameToSaveReportAsCopyValid,
    isReportNameValid,
    newReportNamePlaceholder,
    toggleReportPublicPrivate,
  } = useReports(false);

  const { checkPermissions } = useUserPermissions();
  const location = useLocation();
  const getKeyRun = location.search.includes(GET_KEY_RUN);
  const { id } = useParams<{ id: string }>();
  const { closeModal, isModalOpen, openModal } = useModal();
  const { doSuccessToast } = useToast();
  const { userId } = useCurrentUser();
  const { selectedClient } = useSelectedClient();
  const { users } = useUsers();
  const { exportReport } = useReportConfiguration();
  const [isVisualizationOpened, setIsVisualizationOpened] = useState(
    !!isAccordionResultsOpen,
  );

  const [modalType, setModalType] = useState(ModalType.audit);

  const [lastAttemptedRun, setLastAttemptedRun] = useState<number>(Date.now());

  const userCanAudit = checkPermissions(
    `${reportTypeEquivalences(report.type)}::audit`,
  );

  const userCanExport = checkPermissions(
    `${reportTypeEquivalences(report.type)}::export`,
  );

  const userCanSeeReport = useMemo(
    () =>
      checkPermissions(
        `${reportTypeEquivalences(report.type)}.settings.visibility::view`,
      ),
    [checkPermissions, report],
  );

  const isReportCreator = userId === report.createdBy;
  const reportIsReadOnly = report.isReadOnly;
  const canEdit = !reportIsReadOnly && checkPermissions('reports::update');
  const canToggleReadOnly =
    isReportCreator || checkPermissions('reports.read_only_toggle::view');

  const userCanSeeLroiOptions = useMemo(() => getUserCanSeeLroiOptions(), []);

  const [isStopQueryConfirmationShowing, setIsStopQueryConfirmationShowing] =
    useState(false);

  const [name, setName] = useState<string>(() =>
    report?.id && report?.id !== UNSAVED_REPORT_ID && report?.name
      ? report.name
      : '',
  );
  const [saveAsCopyReportName, setSaveAsCopyReportName] = useState<string>(
    getSaveAsCopyReportName(report.name),
  );
  const reportNameToSaveReportAsCopyIsValid =
    isReportNameToSaveReportAsCopyValid(saveAsCopyReportName);

  const { doCreate, doUpdate, loading } = useItemAdmin<IAttributionReport>({
    endpoint: `/${Index.SEGMENT_REPORTS}`,
  });

  const confirmationDialogMessage =
    confirmationDialog?.confirmationDialogMessage;
  const confirmationDialogHeader = confirmationDialog?.confirmationDialogHeader;
  const confirmationDialogOK = confirmationDialog?.confirmationDialogOK;
  const modalDialogMessage = modalDialog?.confirmationDialogMessage;
  const modalDialogHeader = modalDialog?.confirmationDialogHeader;
  const modalDialogOK = modalDialog?.confirmationDialogOK;

  const goToReports = (reportId?: string, doRun = false): void => {
    const entityUrl = `/${Index.SEGMENT_ATTRIBUTION_REPORTS}`;
    let url = reportId
      ? `${entityUrl}/${Actions.SEGMENT_EDIT}/${reportId}`
      : entityUrl;
    if (doRun) url += `?${GET_KEY_RUN}`;
    navigate(url);
  };

  const isModalReportNameAndRun = modalType === ModalType.addReportNameAndRun;

  const setReportWithRules = useCallback(
    async (
      newNotPersistentProps?: INotPersistedProps,
      updatedReport?: IAttributionReport,
    ) => {
      const newReport = {
        ...(updatedReport ?? report),
        notPersistedProps: {
          ...report?.notPersistedProps,
          ...newNotPersistentProps,
          userCanAudit,
          userCanExport,
        },
      };
      setReport(newReport);
      await processRules(newReport?.notPersistedProps);
    },
    [processRules, report, setReport, userCanAudit, userCanExport],
  );

  const handleUpdateReportSuccess = async (
    reportsUpdated: IAttributionReport[],
    meta?: IMeta<IAttributionReport>,
  ): Promise<void> => {
    setAttributionReportList(null);
    doSuccessToast(LOCALE_REPORT_UPDATED);
    const newRules = {
      isBaseReportSaved: true,
      isBaseReportSaving: false,
      isBaseReportSavingAs: false,
    };
    let newReport = {
      ...report,
    };
    if (meta?.changed) {
      newReport = {
        ...report,
        ...meta.changed,
      };
    }
    await setReportWithRules(newRules, newReport);
  };

  const handleCreateReportSuccess = async (
    reportsUpdated: IAttributionReport[],
    meta?: IMeta<IAttributionReport>,
  ): Promise<void> => {
    setAttributionReportList(null);
    doSuccessToast(LOCALE_REPORT_CREATED);
    let newReportCreated;
    if (meta?.id)
      newReportCreated = reportsUpdated.find(
        (r: IAttributionReport): boolean => r.id === meta.id,
      );
    if (newReportCreated?.name) {
      setReport(newReportCreated);
      setName(newReportCreated.name);
      setSaveAsCopyReportName(getSaveAsCopyReportName(newReportCreated.name));
    }
  };

  const handleError = (err: IError): void => {
    setErrorMessage(err?.message ?? 'There has been an error.');
  };

  const handleSubmit = async (
    draftReport: IAttributionReport,
    runOnFinish = false,
  ): Promise<void> => {
    if (id) {
      await doUpdate({
        item: {
          ...omit(draftReport, ['name']),
          id: report.id,
          client: selectedClient?.id,
          updatedBy: userId,
        },
        onError: handleError,
        onSuccess: (reportsUpdated: IAttributionReport[]) => {
          handleUpdateReportSuccess(reportsUpdated);
          resetDashboardReport(id);
        },
      });
    } else {
      await doCreate({
        item: {
          ...draftReport,
          client: selectedClient?.id,
          createdBy: userId ?? '',
        },
        onError: handleError,
        onSuccess: (
          reportsUpdated: IAttributionReport[],
          meta?: IMeta<IAttributionReport>,
        ) => {
          setAttributionReportList(null);
          handleCreateReportSuccess(reportsUpdated, meta);

          if (runOnFinish && meta?.id) {
            goToReports(meta.id, true);
          }

          if (!runOnFinish && meta?.id) {
            goToReports(meta.id);
          }
        },
      });
    }
  };

  const saveAsCopyAndOpenReport = async (): Promise<void> => {
    if (!userId) {
      setErrorMessage('Cannot get currentUser');
      return;
    }

    await setReportWithRules({
      isBaseReportSavingAs: true,
    });

    handleCloneReport(
      {
        ...report,
        createdBy: userId,
        name: saveAsCopyReportName,
        isReadOnly: false,
      },
      Actions.SEGMENT_COPY,
    );
  };

  const handleSaveAsCopyReport = (): void => {
    setModalType(ModalType.addReportNameSaveAndOpen);
    openModal();
  };

  const handleExportConfiguration = (): void => {
    // TODO - check that the report exists prior to exporting
    // i.e. prevent export of non-saved reports
    exportReport(report);
  };

  const toggleStopQueryConfirmationShowing = useCallback(
    partial(setIsStopQueryConfirmationShowing, (p) => !p),
    [],
  );
  const removeInvalidTargetsFromReport = useCallback(() => {
    const changedReport = {
      ...report,
      query: { ...report.query, targets: validTargetList || [] },
    };
    setReportWithRules({}, changedReport).then(noop);
  }, [report, setReportWithRules, validTargetList]);

  const handleDownloadQuery = useCallback(
    async (event: MouseEvent, isFileUpload = false): Promise<void> => {
      const isFileUploadFromEvent = !!event?.altKey || isFileUpload;

      setProgress(0);
      if (isFileUploadFromEvent) {
        runFileUploadQuery(cacheMode.default);
      } else {
        runDownloadQuery(cacheMode.default);
      }
    },
    [setReportWithRules, setProgress, report],
  ) as unknown as MouseEventHandler;

  const handleAudit = useCallback((): void => {
    setModalType(ModalType.audit);
    openModal();
  }, [openModal]);

  const handleAuditModalOK = (): void => {
    closeModal();
  };

  const handleCancelSaveAsCopyAndOpenReport = (): void => {
    closeModal();
    const backupReportName = getSaveAsCopyReportName(report.name);
    if (backupReportName) setSaveAsCopyReportName(backupReportName);
  };

  const handleSaveAsCopyAndOpenReport = async (): Promise<void> => {
    closeModal();
    await saveAsCopyAndOpenReport();
  };

  const handleChangeAddSaveAsCopyReportName = (
    newName: NewInputValue,
  ): void => {
    setSaveAsCopyReportName(newName as string);
  };

  const doSubmit = async (
    runOnFinish = false,
    reportToSubmit?: IAttributionReport,
  ): Promise<void> => {
    await setReportWithRules({
      isBaseReportSaved: false,
      isBaseReportSaving: true,
    });

    if (reportToSubmit) {
      await handleSubmit({ ...reportToSubmit }, runOnFinish);
    } else {
      await handleSubmit({ ...report }, runOnFinish);
    }
  };

  const handleChangeAddMissingReportName = (newName: NewInputValue): void => {
    setMissingName(newName as string);
  };

  const handleNameEditMode = (): void => {
    setNameIsEditing(true);
  };

  const handleNameChangeEdit = async (
    value: string,
    skipSave?: boolean,
  ): Promise<void> => {
    setName(value ?? '');

    if (skipSave) return;
    try {
      const { id: newId, name: newName } =
        (await handleUpdateName(value)) ?? {};

      if (!newId) {
        setNameIsEditing(false);
        return;
      }

      if (newName) {
        const saveAsName = getSaveAsCopyReportName(newName);
        setSaveAsCopyReportName(saveAsName);
      }
      if (id) {
        doSuccessToast(LOCALE_REPORT_UPDATED);
        resetDashboardReport(id);
        setNameIsEditing(false);
        return;
      }
      goToReports(`${newId}?norun=1`);
    } catch (e) {
      handleError(e as IError);
      setName(report?.name ?? '');
    }
    setNameIsEditing(false);
  };

  const handleCancelNameChanges = (): void => {
    setName(report?.name ?? '');
    setNameIsEditing(false);
  };

  const handleCopyLink = useCallback((): void => {
    copyCurrentUrl();
    doSuccessToast(`${LOCALE_LINK_COPIED}`);
  }, [doSuccessToast]);

  const handleFileUploadQuery = useCallback(
    partial(handleDownloadQuery, partial.placeholder, true),
    [handleDownloadQuery],
  );

  const EXPORT_DROPDOWN_OPTIONS = [
    {
      format: 'xlsx',
      handler: handleDownloadQuery,
      text: LOCALE_EXPORT_LABEL_XLXS,
      header: LOCALE_DESKTOP_DOWNLOAD,
    },
    {
      format: 's3',
      handler: handleFileUploadQuery,
      text: LOCALE_EXPORT_LABEL_XLXS,
      header: LOCALE_S3_UPLOAD,
    },
  ];

  const ALLOWED_EXPORT_OPTIONS = EXPORT_DROPDOWN_OPTIONS.filter((option) =>
    checkPermissions(
      `${reportTypeEquivalences(report.type as ReportType)}.${
        option.format
      }_export::view`,
    ),
  ) as IDropdowndWithHeadersOption[];

  const handleToggleIsPublic = useCallback(
    partial(toggleReportPublicPrivate, {
      id: report.id,
      onError: (error: IError): void =>
        setErrorMessage(error?.message ?? 'There has been an error.'),
      onSuccess: (res?: IReport): void => {
        setAttributionReportList(null);
        if (res) {
          setReport({
            ...report,
            isPublic: res.isPublic,
          });
        }

        if (res?.isPublic) {
          doSuccessToast(LOCALE_IS_PUBLIC);
          return;
        }

        doSuccessToast(LOCALE_IS_PRIVATE);
      },
    }),
    [report, setReport],
  );

  const hasInvalidTargets =
    !!id && invalidTargetList && invalidTargetList.length > 0;
  useEffect(() => {
    /**
     * This could be hardcoded, but in order to keep the same logic as
     * Target and common reports,
     * setType will continue receive report.type as parameter
     */
    setType(report.type ?? 'report');
    if (new URLSearchParams(window.location.search).has('norun')) {
      window.history.pushState(
        {},
        '',
        removeRunParamFromUrl(window.location.href),
      );
      setAccordionQueryOpen();
    } else if (getKeyRun && !isBaseReportInitiallyExecuted(report)) {
      runQuery(cacheMode.default);
      window.history.pushState(
        {},
        '',
        removeRunParamFromUrl(window.location.href),
      );
    } else if (id && !hasInvalidTargets) {
      runQuery(cacheMode.always);
    }

    // reset results when unmounting
    return () => {
      resetAttributionReportResult();
      setErrorMessage('');
    };
  }, []);

  const onVisualizationOpen = (): void => setIsVisualizationOpened(true);

  const editReportId = getId(editReportComponentName);
  const editReportTestId = getTestId(editReportComponentName);
  const editReportDefaultClass = getClass(editReportComponentName);
  const editReportFormTestId = getTestId(
    editReportComponentName,
    DOM_KEY_REPORT_FORM,
  );
  const hasPresentationExportPermission = checkPermissions(
    'attribution_reports.pptx_export::view',
  );
  const isDownloadPresentationButtonDisabled =
    isDownloadingPresentation ||
    isBaseReportExporting(report) ||
    isBaseReportSaving;
  const resultAccordionProps = useMemo(
    () => ({
      ...(ALLOWED_EXPORT_OPTIONS.length > 0 && userCanExport
        ? {
            headerToolbar: (
              <Flex
                style={hasPresentationExportPermission ? { width: 175 } : {}}
                horizontal={Horizontal.between}
              >
                <DropdownWithHeaders
                  options={ALLOWED_EXPORT_OPTIONS}
                  disabled={isBaseReportExporting(report) || isBaseReportSaving}
                  label={
                    isBaseReportExporting(report)
                      ? LOCALE_EXPORT_RUNNING_BUTTON_TEXT
                      : LOCALE_EXPORT_BUTTON_TEXT
                  }
                />
                {hasPresentationExportPermission && (
                  <div className="ppt-button">
                    <Dropdown
                      as={ButtonGroup}
                      style={{ width: 45, height: 45 }}
                    >
                      <RBButton
                        disabled={isDownloadPresentationButtonDisabled}
                        onClick={handleDownloadPresentation}
                        size="lg"
                        className="br-bl-4 br-tl-4 br-br-4 br-tr-4"
                        title={
                          isDownloadingPresentation
                            ? 'Downloading...'
                            : 'Download Presentation'
                        }
                      >
                        {isDownloadingPresentation ? (
                          <Icon
                            color={IconColor.primary}
                            size={IconSize.small}
                            type={IconType.loading}
                          />
                        ) : (
                          <img
                            className="ppt-icon"
                            src="/605/ppt.svg"
                            alt="PPT Icon"
                            width={20}
                            height={20}
                            style={{
                              filter: isDownloadPresentationButtonDisabled
                                ? 'grayscale(1)'
                                : 'grayscale(0)',
                            }}
                          />
                        )}
                      </RBButton>
                    </Dropdown>
                  </div>
                )}
              </Flex>
            ),
          }
        : {}),
    }),
    [
      ALLOWED_EXPORT_OPTIONS,
      report,
      userCanExport,
      isDownloadingPresentation,
      handleDownloadPresentation,
      isDownloadPresentationButtonDisabled,
    ],
  );

  return (
    <section className={editReportDefaultClass} data-testid={editReportTestId}>
      <ConfirmationDialog
        isOpen={!!confirmationDialogMessage?.length}
        onConfirm={(): void => setUserConfirmationAction(true)}
        onCancel={(): void => setUserConfirmationAction(false)}
        header={confirmationDialogHeader}
        confirmButtonText={confirmationDialogOK}
      >
        {Array.isArray(confirmationDialogMessage) ? (
          confirmationDialogMessage.map((message: string[] | string) =>
            Array.isArray(message) ? (
              <List key={uuid()} decorator="chevron" items={message} />
            ) : (
              <p key={uuid()}>{message}</p>
            ),
          )
        ) : (
          <p>{confirmationDialogMessage}</p>
        )}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={!!modalDialogMessage.length}
        onConfirm={setAcceptDialogModal}
        header={modalDialogHeader}
        confirmButtonText={modalDialogOK}
      >
        {Array.isArray(modalDialogMessage) ? (
          modalDialogMessage.map((message) =>
            Array.isArray(message) ? (
              <List decorator="chevron" items={message} key={uuid()} />
            ) : (
              <p key={uuid()}>{message}</p>
            ),
          )
        ) : (
          <p>{modalDialogMessage}</p>
        )}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={isStopQueryConfirmationShowing}
        onConfirm={(): void => {
          cancelExecutionJobAction();
          toolbarCancel(report);
          toggleStopQueryConfirmationShowing();
        }}
        onCancel={toggleStopQueryConfirmationShowing}
        header={LOCALE_DELETE_STOP_QUERY_HEADER}
        confirmButtonText={LOCALE_CANCEL_REPORT_RUN}
        cancelButtonText={LOCALE_GO_BACK}
      >
        <p>{LOCALE_DELETE_STOP_QUERY_TEXT}</p>
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={hasInvalidTargets}
        onConfirm={removeInvalidTargetsFromReport}
        header={LOCALE_NOTIFICATION_HEADER}
      >
        <p>{LOCALE_INVALID_TARGET}</p>
      </ConfirmationDialog>
      <Modal type={Type.auto} isOpen={isModalOpen} onCloseClick={closeModal}>
        {modalType === ModalType.audit ? (
          <div>
            <header className={editReportAuditHeaderClass}>
              <H3>{LOCALE_AUDIT_MODAL_TITLE}</H3>
            </header>
            <section className={editReportAuditBodyClass}>
              <ReportEventsTable events={events} users={users} />
            </section>
            <ActionsBar cancel={false} okLabel="Ok" onOK={handleAuditModalOK} />
          </div>
        ) : (
          []
        )}
        {[ModalType.addReportName, ModalType.addReportNameAndRun].includes(
          modalType,
        ) ? (
          <div>
            <header className={editReportAddNameHeaderClass}>
              <H3>{LOCALE_MISSING_REPORT_NAME_MODAL_TITLE}</H3>
            </header>
            <section className={editReportAddNameBodyClass}>
              <Field
                onKeyPressEnter={() =>
                  onSaveEmptyNameReport(
                    isModalReportNameAndRun,
                    missingName,
                    closeModal,
                    doSubmit,
                  )
                }
                label={LOCALE_MISSING_REPORT_NAME_MODAL_TEXT}
                type={InputType.text}
                id={`${editReportId}-report-name-input`}
                name={`${editReportId}-report-name-input`}
                onChange={handleChangeAddMissingReportName}
                placeholder={newReportNamePlaceholder}
              />
            </section>
            <ActionsBar
              onCancel={() =>
                handleRemoveMissingReportName(setMissingName, closeModal)
              }
              okLabel={
                isModalReportNameAndRun ? LOCALE_SAVE_AND_RUN : LOCALE_OK
              }
              onOK={() =>
                onSaveEmptyNameReport(
                  isModalReportNameAndRun,
                  missingName,
                  closeModal,
                  doSubmit,
                )
              }
              disabledOK={
                !missingName || missingName === newReportNamePlaceholder
              }
            />
          </div>
        ) : (
          []
        )}
        {modalType === ModalType.addReportNameSaveAndOpen ? (
          <div>
            <header className={editReportAddNameHeaderClass}>
              <H3>{LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_MODAL_TITLE}</H3>
            </header>
            <section className={editReportAddNameBodyClass}>
              <Field
                label={LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_NAME_LABEL}
                type={InputType.text}
                id={`${editReportId}-missing-report-name-input`}
                name={`${editReportId}-missing-report-name-input`}
                onKeyPressEnter={handleSaveAsCopyAndOpenReport}
                onChange={handleChangeAddSaveAsCopyReportName}
                value={saveAsCopyReportName}
                placeholder={newReportNamePlaceholder}
              />
            </section>
            <ActionsBar
              onCancel={handleCancelSaveAsCopyAndOpenReport}
              cancelLabel="Cancel"
              okLabel="Save and Open"
              onOK={handleSaveAsCopyAndOpenReport}
              disabledOK={!reportNameToSaveReportAsCopyIsValid}
            />
          </div>
        ) : (
          []
        )}
      </Modal>

      <StickyHeader>
        <section>
          {isReportInvalid && (
            <InlineAlert
              message={LOCALE_REPORT_VALIDATION_ERROR}
              mode={AlertTypes.validate}
              lastAttemptedRun={lastAttemptedRun}
            />
          )}

          {errorMessage && (
            <InlineAlert message={errorMessage} mode={AlertTypes.validate} />
          )}
        </section>
        <Flex horizontal={Horizontal.between}>
          <header className={`${editReportDefaultClass}-header`}>
            <InlineEdit
              disabled={isReportNameDisabled || reportIsReadOnly}
              value={name}
              placeholder={newReportNamePlaceholder}
              onEdit={handleNameChangeEdit}
              onCancel={handleCancelNameChanges}
              onEditMode={handleNameEditMode}
            />
            {!nameIsEditing && (
              <KebabDrawer>
                {userCanSeeReport &&
                  (!isLoadingToggleReportPublicPrivate ? (
                    <ToggleIsPublicButton
                      disabled={isBaseReportNew}
                      isPublic={report.isPublic}
                      onClick={handleToggleIsPublic}
                    />
                  ) : (
                    <Icon
                      color={IconColor.primary}
                      size={IconSize.small}
                      type={IconType.loading}
                    />
                  ))}
                {report.isPublic && (
                  <Button
                    iconColor={IconColor.secondary}
                    iconSize={IconSize.small}
                    iconType={IconType.link}
                    kind={ButtonKind.icon}
                    onClick={handleCopyLink}
                    size={ButtonSize.small}
                    tooltip={LOCALE_COPY_LINK_BUTTON_TOOLTIP_LABEL}
                    tooltipShowArrow={false}
                    tooltipStyle={TooltipStyle.tertiary}
                    disabled={isBaseReportNew}
                  />
                )}
                {canToggleReadOnly &&
                  (!isLoadingReadOnlyToggle ? (
                    <ToggleIsReadOnlyButton
                      disabled={isBaseReportNew}
                      isReadOnly={report.isReadOnly}
                      onClick={handleToggleReadOnly}
                    />
                  ) : (
                    <Icon
                      type={IconType.loading}
                      size={IconSize.small}
                      color={IconColor.primary}
                    />
                  ))}
                {userCanAudit && (
                  <Button
                    iconColor={IconColor.secondary}
                    iconSize={IconSize.small}
                    iconType={IconType.audit}
                    kind={ButtonKind.icon}
                    onClick={handleAudit}
                    size={ButtonSize.small}
                    tooltip={LOCALE_AUDIT_BUTTON_TOOLTIP_LABEL}
                    tooltipShowArrow={false}
                    tooltipStyle={TooltipStyle.tertiary}
                    disabled={isBaseReportNew}
                  />
                )}
              </KebabDrawer>
            )}
          </header>
          <ReportToolbar
            onCancelRun={(): void => toggleStopQueryConfirmationShowing()}
            onRefresh={(): void => {
              setLastAttemptedRun(Date.now());
              runQuery(cacheMode.force);
            }}
            onRun={(event): void => {
              setErrorMessage('');
              const reportCacheMode = event?.altKey
                ? cacheMode.force
                : cacheMode.default;
              const reportNameIsValid = isReportNameValid(report.name);
              if (!reportNameIsValid) {
                if (messageList.length > 0) {
                  setLastAttemptedRun(Date.now());
                  setIsReportInvalid(true);
                  trackInlineValidationShown(
                    report,
                    'message-list',
                    messageList,
                  );
                } else if (messageList.length === 0) {
                  if (isReportInvalid) {
                    setIsReportInvalid(false);
                  }
                  setModalType(ModalType.addReportNameAndRun);
                  openModal();
                }
              } else {
                setLastAttemptedRun(Date.now());
                runQuery(reportCacheMode);
                toolbarRun(report);
              }
            }}
            onSave={() =>
              handleSubmitAttributionForm(
                name,
                isReportNameValid,
                setModalType,
                openModal,
                doSubmit,
              )
            }
            onSaveAsCopy={handleSaveAsCopyReport}
            onExportConfiguration={handleExportConfiguration}
            onShowReportPayload={showReportPayload}
          />
        </Flex>
      </StickyHeader>
      <section className="atrribution-report-content-wrapper">
        <form
          data-testid={editReportFormTestId}
          onSubmit={() =>
            handleSubmitAttributionForm(
              name,
              isReportNameValid,
              setModalType,
              openModal,
              doSubmit,
            )
          }
        >
          {checkPermissions('attribution_reports.admin_tools::view') && (
            <QueryProgress />
          )}
          <section
            className={editReportQueryClass}
            id="EDIT_REPORT_QUERY_SECTION"
          >
            <Accordion
              open={isAccordionQueryOpen}
              title={LOCALE_FIELD_QUERY}
              onChange={(state: boolean) => !state && setAccordionQueryClosed()}
            >
              <AttributionQueryBuilder disabled={!canEdit} />
            </Accordion>
          </section>
          {userCanSeeLroiOptions && (
            <section className={formControlSegmentClass}>
              <Accordion
                title={LOCALE_FIELD_METHODOLOGY}
                open={isAccordionMethodologyOpen}
              >
                <Methodology />
              </Accordion>
            </section>
          )}

          <section className={formResultsClass}>
            {loading && <SkeletonChartBars />}
            {attributionReportResult ? (
              <Accordion
                open={isAccordionResultsOpen}
                title={LOCALE_FIELD_RESULT}
                {...resultAccordionProps}
                onOpeningFinished={onVisualizationOpen}
              >
                {!isEmpty(attributionReportResult) ? (
                  <Visualization isOpened={isVisualizationOpened} />
                ) : (
                  <div className={editReportNotEnough}>
                    <InlineAlert
                      message={LOCALE_NOT_ENOUGH_HH}
                      mode={AlertTypes.warn}
                      hideClose
                    />
                  </div>
                )}
              </Accordion>
            ) : (
              <Accordion
                open={isAccordionResultsOpen}
                title={LOCALE_FIELD_RESULT}
              >
                {!errorMessage ? (
                  <Box type={BoxType.primary}>
                    <div>
                      <div className={editFormNoDataClass}>
                        <Flex
                          vertical={Vertical.top}
                          horizontal={Horizontal.center}
                        >
                          {LOCALE_NO_CHART_DATA}
                        </Flex>
                      </div>
                    </div>
                  </Box>
                ) : (
                  <div className={editReportNotEnough}>
                    <InlineAlert
                      message={errorMessage}
                      mode={AlertTypes.warn}
                      hideClose
                    />
                  </div>
                )}
              </Accordion>
            )}
          </section>
        </form>
      </section>
    </section>
  );
};
