import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConfig } from 'store/actions/root';
import State from 'types/state';
import IConfig from './types';

export type HookReturn = {
  config: IConfig;
  configLoaded: boolean;
  setConfig: (config: IConfig) => void;
};

const useConfig = (): HookReturn => {
  const currentConfig = useSelector(({ config }: State) => config);
  const dispatch = useDispatch();

  const setConfiguration = useCallback(
    (config: IConfig): void => {
      dispatch(setConfig({ loaded: true, ...config }));
    },
    [dispatch],
  );

  return {
    config: currentConfig,
    configLoaded: !!currentConfig.loaded,
    setConfig: setConfiguration,
  };
};

export default useConfig;
