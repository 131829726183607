const getCookie = (name: string): string => {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : '';
};

const cookieLifetime = 3600000;
const cookieMaxSize = 4096;

const getCookieSize = (cookie: string): number =>
  new TextEncoder().encode(cookie).length;

const checkCookieSize = (cookieName: string, cookie: string): void => {
  const cookieSize = getCookieSize(`${cookieName}=${cookie}`);

  if (cookieSize > cookieMaxSize)
    console.error(`"${cookieName}" cookie size exceeded!`);
};

const setCookie = (
  name: string,
  value: string,
  days: number = cookieLifetime,
): void => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);

  checkCookieSize(name, value);

  document.cookie = `${name}=${value};path=/;expires=${d.toUTCString()}`;
};

const deleteCookie = (name: string): void => {
  setCookie(name, '', -1);
};

const deleteAllCookies = (): void => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    deleteCookie(name);
  }
};

const getUrlParamByName = (name: string): string => {
  const cleanedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${cleanedName}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export {
  getCookie,
  setCookie,
  deleteCookie,
  deleteAllCookies,
  getUrlParamByName,
  cookieMaxSize,
};
