import { Kind as ButtonTypes, Size as ButtonSizes } from 'components/Button';
import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';

export const skeletonButtonComponentName = 'skeleton-button';

export type Props = {
  size?: ButtonSizes;
  type?: ButtonTypes;
  testId?: string;
};

const SkeletonButton: FunctionComponent<Props> = (props): ReactElement => {
  const { size, type, testId } = props;

  const skeletonButtonClass = getClass(skeletonButtonComponentName, {
    add: [type, size],
  });
  const skeletonButtonTestId = getTestId(skeletonButtonComponentName, testId);

  return (
    <div className={skeletonButtonClass} data-testid={skeletonButtonTestId} />
  );
};

export default SkeletonButton;
