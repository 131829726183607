import { getFiltersFromQueryFilters } from 'components/QueryBuilder/adapters';
import { datasetSelectionAccessItem } from 'domains/datasets/types';
import IDimension from 'domains/dimensions/types';
import IMetric, { Metrics } from 'domains/metrics/types';
import IReport, {
  AttributionFilterType,
  IAttributionReport,
  IConfirmDialog,
  IDomainsDateRanges,
  ReportDatasetSelection,
  ReportDatasetsSelections,
  SubTypeKeys,
} from 'domains/reports/types';
import { getAttributionFiltersFromQueryFilters } from 'features/attributionReports/components/AttributionQueryBuilder/adapters';
import { showWarningToast } from 'helpers/general';
import { getReportDatasetSelection } from 'helpers/reports';
import { cloneDeep, filter, findIndex, isEmpty } from 'lodash';
import { difference, differenceWith, isEqual, uniqBy } from 'lodash/fp';
import { FilterValue } from 'react-table';
import { AnyAction, Dispatch } from 'redux';
import store from 'store';
import {
  setAvailableMetrics,
  setAvailableDimensions,
  setAvailableIntervals,
  setAvailableOrderFields,
} from 'store/actions/businessData';
import {
  setUpdatedReport,
  setShowConfirmationDialog,
  setQueryMode,
  setUserConfirmation,
  setReportAction,
  setRealQueryMode,
  setBaseReportNotSaved,
  setReportQueryFilterEndDate,
  setReportQueryFilterStartDate,
  setExposureFlightDateStartDate,
  setExposureFlightDateEndDate,
} from 'store/actions/report';
import * as reportReducer from 'store/reducers/report';
import * as domainsSelectors from 'store/selectors/domains';
import { IDateRange } from 'types/dateRange';
import { Modes, Mode } from 'types/query';
import {
  RuleFilter,
  IQueryFiltersChildren,
  Operator,
  FilterType,
  newFilter,
  IQueryFilters,
  IChildrenBase,
} from '../../../types/filter';
import {
  LOCALE_QUERY_BUILDER_DATASETS_CONTENT_CONFIRMATION_DIALOG_MESSAGE_TWO,
  LOCALE_DATASET_CHANGE_NO_FEEDBACK_NEEDED,
  LOCALE_QUERY_BUILDER__DATASETS_CONFIRMATION_DIALOG_CONFIRM_LABEL,
  LOCALE_QUERY_BUILDER_DATASETS_CONFIRMATION_DIALOG_TITLE,
} from '../locales/datasets';
import {
  getAvailableMetrics,
  isReport,
  isTarget,
  getMetric,
  getDimension,
  isAttributionReport,
} from './general';
import * as domainsActions from 'store/actions/domains';
import { initialDomainsDateRanges } from 'store/reducers/businessData';
import { getDatasetDefaultDateRange, hasReportInvalidDates } from './date';

export const getInvalidDatasetDialogMessage = (
  invalidDimensions: IDimension[],
  invalidMetrics: IMetric[],
  invalidRules: RuleFilter[],
  prunedInvalidRules: RuleFilter[],
  invalidBrandLiftRules: RuleFilter[],
): (string | string[])[] => {
  const messageLines = [];

  if (
    invalidDimensions.length ||
    invalidMetrics.length ||
    prunedInvalidRules.length ||
    invalidBrandLiftRules.length
  ) {
    messageLines.push(
      LOCALE_QUERY_BUILDER_DATASETS_CONTENT_CONFIRMATION_DIALOG_MESSAGE_TWO,
    );
  }

  const subList: string[] = [];
  if (invalidDimensions.length)
    subList.push(...invalidDimensions.map((element) => element.name ?? ''));
  if (invalidMetrics.length)
    subList.push(...invalidMetrics.map((element) => element.name || ''));
  if (invalidBrandLiftRules.length) {
    invalidBrandLiftRules.forEach((invalidRule) => {
      const rule = getDimension(invalidRule?.id ?? '');
      if (rule) subList.push(rule.name ?? invalidRule.id ?? '');
    });
  }
  if (messageLines.length) {
    const rulesToIterate = invalidRules;
    rulesToIterate.forEach((invalidRule) => {
      const rule = getDimension(invalidRule?.id ?? '');
      if (rule) subList.push(rule.name ?? invalidRule.id ?? '');
    });
  }

  if (subList.length) messageLines.push(subList);

  return messageLines;
};

const isDimensionOrRuleInvalid = (
  dimensionOrRule: IDimension | RuleFilter | IDateRange,
  dataset: Mode,
): boolean => {
  if (!dimensionOrRule?.id) return false;

  const rawDimension = getDimension(dimensionOrRule.id);
  const datasetToSuppress: ReportDatasetSelection =
    getReportDatasetSelection(dataset);

  const dataSetHideFor = `${datasetSelectionAccessItem}::${datasetToSuppress}`;

  return !!rawDimension?.hideFor?.includes(dataSetHideFor);
};

const isMetricInvalid = (metric: IMetric, dataset: Mode): boolean => {
  let shouldBeRemoved = false;

  if (dataset === 'MODE_LINEAR') {
    const isImpressionsOrFrequency =
      metric?.id === Metrics.impressions || metric?.id === Metrics.frequency;
    const isEnabledForSelectedDataset = !!metric?.hideFor?.includes(
      'dataset_selection::content',
    );
    shouldBeRemoved = isImpressionsOrFrequency || isEnabledForSelectedDataset;
  }

  return shouldBeRemoved;
};

export const getInvalidDimensions = (
  dataset: Mode,
  dimensions: string[],
): IDimension[] => {
  const dimensionsToRemove = [] as IDimension[];

  if (!dimensions || (dimensions?.length && dimensions.length === 0)) {
    return dimensionsToRemove;
  }

  dimensions.forEach((queryDimensionId: string): void => {
    const dimension = getDimension(queryDimensionId);
    if (dimension) {
      const dimensionShouldBeRemoveFromQuery = isDimensionOrRuleInvalid(
        dimension,
        dataset,
      );
      if (dimensionShouldBeRemoveFromQuery) {
        dimensionsToRemove.push(dimension);
      }
    }
  });

  return dimensionsToRemove;
};

export const getInvalidMetrics = (report: IReport): IMetric[] => {
  const allMetrics = domainsSelectors.getMetrics(store.getState());
  const dataset = reportReducer.getQueryMode(report);
  const metrics = reportReducer.getQueryMetrics(report);
  const availableMetrics = getAvailableMetrics(report);

  const invalidMetricsFromQueryMode = difference(
    metrics,
    availableMetrics.map((metric) => metric.id),
  ) as unknown as string[];
  const curatedInvalidMetricsFromQueryMode = allMetrics.filter(
    (metric: IMetric) =>
      invalidMetricsFromQueryMode.includes(metric.id as string),
  );
  const invalidMetricsFromDataset = [] as IMetric[];

  if (!metrics || (metrics?.length && metrics.length === 0)) {
    return invalidMetricsFromDataset;
  }

  metrics.forEach((metricId: string): void => {
    const metric = getMetric(metricId);
    if (metric) {
      const metricShouldBeRemovedFromQuery = isMetricInvalid(metric, dataset);
      if (metricShouldBeRemovedFromQuery) {
        invalidMetricsFromDataset.push(metric);
      }
    }
  });
  return uniqBy('id', [
    ...invalidMetricsFromDataset,
    ...curatedInvalidMetricsFromQueryMode,
  ]);
};

export const showViewingThresholdToast = (dataset: Mode): void => {
  let toastMessage =
    'The following Metrics, Dimensions and Rules have been removed from the report: Consecutive Viewing Threshold';
  if (dataset === 'MODE_LINEAR') {
    toastMessage =
      'The following Metrics, Dimensions and Rules have been removed from the report: Viewing Threshold';
  }
  showWarningToast(toastMessage);
};

export const addDefaultRules = (
  dataset: Mode,
  filters: IQueryFiltersChildren[],
): IQueryFiltersChildren[] => {
  let newRulesWithDefault = JSON.parse(JSON.stringify(filters));
  switch (dataset) {
    case 'MODE_LINEAR': {
      const defaultViewingThreshold = {
        ...newFilter(),
        field: 'CONSECUTIVE_TELECAST_VIEWING_HH',
        id: 'CONSECUTIVE_TELECAST_VIEWING_HH',
        type: FilterType.SIMPLE,
        operator: 'GT' as Operator,
        value: '59',
        operator_type: 'DURATION_COMPARISON',
      } as RuleFilter;
      if (
        findIndex(filters, ['id', 'CONSECUTIVE_TELECAST_VIEWING_HH']) === -1
      ) {
        newRulesWithDefault.splice(2, 0, defaultViewingThreshold);
      }
      break;
    }
    case 'MODE_ADVERTS': {
      const defaultViewingThreshold = {
        ...newFilter(),
        field: 'AD_TUNING_DURATION',
        id: 'AD_TUNING_DURATION',
        type: FilterType.SIMPLE,
        operator: 'GT' as Operator,
        value: '2',
        operator_type: 'DURATION_COMPARISON',
      } as RuleFilter;
      if (findIndex(filters, ['id', 'AD_TUNING_DURATION']) === -1) {
        newRulesWithDefault.splice(2, 0, defaultViewingThreshold);
      }
      break;
    }
    case 'MODE_CUSTOM_ADVERTS': {
      const defaultViewingThreshold = {
        ...newFilter(),
        field: 'AD_TUNING_DURATION',
        id: 'AD_TUNING_DURATION',
        type: FilterType.SIMPLE,
        operator: 'GT' as Operator,
        value: '2',
        operator_type: 'DURATION_COMPARISON',
      } as RuleFilter;
      if (findIndex(filters, ['id', 'AD_TUNING_DURATION']) === -1) {
        newRulesWithDefault.splice(2, 0, defaultViewingThreshold);
      }
      break;
    }
  }

  return newRulesWithDefault;
};

export const getPrunedInvalidRules = (
  dataset: Mode,
  invalidRules: RuleFilter[],
): RuleFilter[] => {
  let prunedInvalidRules = JSON.parse(JSON.stringify(invalidRules));
  if (dataset === 'MODE_CUSTOM_ADVERTS' || dataset === 'MODE_ADVERTS') {
    const consectutiveIndex = findIndex(prunedInvalidRules, [
      'id',
      'CONSECUTIVE_TELECAST_VIEWING_HH',
    ]);
    if (consectutiveIndex > -1) {
      prunedInvalidRules.splice(consectutiveIndex, 1);
    }
  } else if (dataset === 'MODE_LINEAR') {
    const consectutiveIndex = findIndex(prunedInvalidRules, [
      'id',
      'AD_TUNING_DURATION',
    ]);
    if (consectutiveIndex > -1) {
      prunedInvalidRules.splice(consectutiveIndex, 1);
    }
  }
  return prunedInvalidRules;
};

export const getInvalidRules = (
  dataset: Mode,
  filters: IQueryFiltersChildren = [],
): RuleFilter[] => {
  const queryRules = filters.length
    ? [...getFiltersFromQueryFilters(filters)]
    : [];

  return queryRules.filter((queryRule) => {
    return isDimensionOrRuleInvalid(queryRule, dataset);
  });
};

export const getInvalidBrandLiftRules = (
  dataset: Mode,
  filters: IQueryFilters<IChildrenBase>[] = [],
): RuleFilter[] => {
  const children = getAttributionFiltersFromQueryFilters(
    filters ?? [],
    AttributionFilterType.brandLift,
  ) as RuleFilter[];

  if (!children?.length) return [];

  return getInvalidRules(dataset, children);
};

export const setQueryModeDecision = (
  datasetMode: Mode,
  realDataset: Mode,
  dispatch: Dispatch,
  newReport: IReport,
  domainsDateRanges: IDomainsDateRanges,
): void => {
  let copyNewReport = cloneDeep(newReport);

  const { dateStart, dateEnd } = getDatasetDefaultDateRange(
    domainsDateRanges,
    datasetMode,
  );

  const invalidMetrics = getInvalidMetrics(copyNewReport);
  const invalidDimensions = getInvalidDimensions(
    realDataset,
    reportReducer.getQueryDimensions(copyNewReport) || [],
  );
  const invalidRules =
    getInvalidRules(
      realDataset,
      reportReducer.getQueryFilters(copyNewReport),
    ) || [];

  const invalidBrandLiftRules = getInvalidBrandLiftRules(
    realDataset,
    reportReducer.getQueryFilters(copyNewReport),
  );

  const showConfirmationDialog =
    invalidMetrics.length ||
    invalidDimensions.length ||
    invalidRules.length ||
    invalidBrandLiftRules.length;
  if (!showConfirmationDialog) {
    dispatch(setQueryMode(datasetMode));
    dispatch(setRealQueryMode(realDataset));
    dispatch(
      domainsActions.setDomainsDateRanges({
        ...domainsDateRanges,
        [Modes.customAdverts]: initialDomainsDateRanges.MODE_CUSTOM_ADVERTS,
        [Modes.genericEvents]: initialDomainsDateRanges.MODE_GENERIC_EVENTS,
      }),
    );
    if (hasReportInvalidDates(copyNewReport, domainsDateRanges)) {
      if (isReport(copyNewReport) || isTarget(copyNewReport)) {
        dispatch(setReportQueryFilterStartDate(dateStart));
        dispatch(setReportQueryFilterEndDate(dateEnd));
      } else {
        dispatch(setExposureFlightDateStartDate(dateStart));
        dispatch(setExposureFlightDateEndDate(dateEnd));
      }
    }

    dispatch(setAvailableDimensions());
    if (isReport(copyNewReport)) {
      dispatch(setAvailableMetrics());
      dispatch(setAvailableIntervals());
      dispatch(setAvailableOrderFields());
    }
    dispatch(setBaseReportNotSaved());
    showWarningToast(LOCALE_DATASET_CHANGE_NO_FEEDBACK_NEEDED);
  } else {
    if (hasReportInvalidDates(copyNewReport, domainsDateRanges)) {
      if (isReport(copyNewReport) || isTarget(copyNewReport)) {
        copyNewReport = reportReducer.setQueryFilterStartDate(
          dateStart,
          copyNewReport,
        );
        copyNewReport = reportReducer.setQueryFilterEndDate(
          dateEnd,
          copyNewReport,
        );
      } else {
        copyNewReport = reportReducer.setExposureFlightDateStart(
          dateStart,
          copyNewReport,
        );
        copyNewReport = reportReducer.setExposureFlightDateEnd(
          dateEnd,
          copyNewReport,
        );
      }
    }

    // Check dimensions
    if (!isEmpty(invalidDimensions)) {
      const newDimensions = difference(
        reportReducer.getQueryDimensions(copyNewReport),
        invalidDimensions.map((dimension) => dimension.id),
      );
      copyNewReport = reportReducer.setDimensions(newDimensions, copyNewReport);
    }

    // check metrics
    if (!isEmpty(invalidMetrics)) {
      const newMetrics = difference(
        reportReducer.getQueryMetrics(copyNewReport),
        invalidMetrics.map((metric) => metric.id),
      );
      copyNewReport = reportReducer.setMetrics(newMetrics, copyNewReport);
    }

    // check rules
    if (!isEmpty(invalidRules)) {
      const newRules = differenceWith(
        isEqual,
        reportReducer.getQueryFilters(copyNewReport) as IQueryFiltersChildren[],
        invalidRules,
      );
      const rulesWithDefault = isTarget(copyNewReport)
        ? newRules
        : addDefaultRules(realDataset, newRules);
      copyNewReport = reportReducer.setQueryFiltersChildren(
        rulesWithDefault,
        copyNewReport,
      );
    }

    //check brand lift rules
    if (!isEmpty(invalidBrandLiftRules)) {
      const filters: FilterValue[] = JSON.parse(
        JSON.stringify(reportReducer.getQueryFilters(copyNewReport)),
      );
      const [brandLiftFilter]: RuleFilter[] = filter(
        filters,
        ({ name }) => name === AttributionFilterType.brandLift,
      );
      const brandLiftIndex = findIndex(
        reportReducer.getQueryFilters(copyNewReport),
        ['name', AttributionFilterType.brandLift],
      );
      const newRules = differenceWith(isEqual, brandLiftFilter.children, [
        ...invalidBrandLiftRules,
      ]);
      const defaultRules = [
        {
          filterId: (brandLiftFilter?.children?.[0] as RuleFilter)?.filterId,
        },
      ];
      brandLiftFilter.children = newRules?.length
        ? newRules
        : (defaultRules as FilterValue[]);
      filters.splice(brandLiftIndex, 1, brandLiftFilter);

      copyNewReport = reportReducer.setQueryFilters(
        { type: 'LOGICAL', operator: 'AND', children: filters },
        copyNewReport,
      );
    }

    const prunedInvalidRules = isTarget(copyNewReport)
      ? invalidRules
      : getPrunedInvalidRules(realDataset, invalidRules);
    const confirmationDialogMessage = getInvalidDatasetDialogMessage(
      invalidDimensions,
      invalidMetrics,
      invalidRules,
      prunedInvalidRules,
      invalidBrandLiftRules,
    );
    if (isEmpty(confirmationDialogMessage)) {
      dispatch(setUpdatedReport(copyNewReport));
      dispatch(setUserConfirmation(true && [copyNewReport]));
      if (prunedInvalidRules.length < invalidRules.length) {
        showViewingThresholdToast(realDataset);
      }
      dispatch(setReportAction(copyNewReport) as unknown as AnyAction);
      return;
    }

    const confirmDialog = {
      confirmationDialogMessage,
      confirmationDialogHeader:
        LOCALE_QUERY_BUILDER_DATASETS_CONFIRMATION_DIALOG_TITLE,
      confirmationDialogOK:
        LOCALE_QUERY_BUILDER__DATASETS_CONFIRMATION_DIALOG_CONFIRM_LABEL,
    };

    if (
      isAttributionReport(copyNewReport as IAttributionReport) &&
      copyNewReport.subType === SubTypeKeys.BRAND_LIFT
    ) {
      copyNewReport = reportReducer.setQueryConversionType(copyNewReport);
      copyNewReport = reportReducer.setQueryPerformanceMetricGroups(
        ['converters'],
        copyNewReport,
      );
      copyNewReport = reportReducer.setReportConversionType('', copyNewReport);
      copyNewReport = reportReducer.setReportPerformanceMetricGroups(
        ['converters'],
        copyNewReport,
      );
    }

    dispatch(setUpdatedReport(copyNewReport));

    dispatch(
      setShowConfirmationDialog(confirmDialog as unknown as IConfirmDialog),
    );
  }
};

export const hasQueryContentDatasets = (mode?: Mode): boolean => {
  if (!mode) {
    return false;
  }
  return mode === Modes.linear;
};

export const hasQueryAdsDatasets = (mode?: Mode): boolean => {
  if (!mode) {
    return false;
  }

  return mode === Modes.adverts;
};

export const hasQueryCustomAdsDatasets = (mode?: Mode): boolean => {
  if (!mode) {
    return false;
  }

  return mode === Modes.customAdverts;
};

export const getDatasetSelection = (
  queryMode?: Mode,
): ReportDatasetSelection => {
  const queryHasContentDatasets = hasQueryContentDatasets(queryMode);

  if (queryHasContentDatasets) {
    return ReportDatasetsSelections.content;
  }

  const queryHasCustomAdsDatasets = hasQueryCustomAdsDatasets(queryMode);

  if (queryHasCustomAdsDatasets) {
    return ReportDatasetsSelections.customAds;
  }

  const queryHasAdsDatasets = hasQueryAdsDatasets(queryMode);

  if (queryHasAdsDatasets) {
    return ReportDatasetsSelections.ads;
  }

  return ReportDatasetsSelections.content;
};
