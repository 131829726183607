import { capitalize, find, flow, get, isEqual, startCase } from 'lodash/fp';
import ITextValue, {
  IBasicDropdownPayload,
  IOption,
} from '../../types/textValue';
import { DataRowGraphValue } from '../../domains/reports/types';
import IIdName from '../../types/idName';
import IDimension from '../../domains/dimensions/types';
import { AllFilterTypes, Operator, RuleFilter } from '../../types/filter';
import IMetric from '../../domains/metrics/types';

export const isArrayOfStrings = (array: (string | ITextValue)[]): boolean =>
  array.every((i: string | ITextValue) => typeof i === 'string');

export const toTextValuePairs = (
  object: IBasicDropdownPayload | { [key: string]: string },
  isCumulative?: boolean,
): ITextValue[] => {
  if (!object) return [];

  if (Array.isArray(object)) {
    return object.map((objElem) => {
      if (typeof objElem === 'object') {
        const {
          cume_name,
          name,
          value: currentValue,
          id,
          text: currentText,
          group,
          tooltip,
          description,
          is_unmatched: isUnmatched,
        } = objElem;

        const text = `${
          (isCumulative ? cume_name : name) ?? currentText ?? currentValue ?? ''
        }`;
        let value = '';

        if (id || (!id && id === 0)) {
          value = `${id}`;
        }

        if (!id && id !== 0 && currentValue) {
          value = `${currentValue}`;
        }

        return {
          text,
          value,
          tooltip: group ? description : tooltip,
          ...(group && { group }),
          ...(isUnmatched !== undefined && { isUnmatched }),
        };
      }

      return {
        text: objElem,
        value: objElem,
      };
    });
  }

  return Object.keys(object).map((item) => ({
    text: `${object[item]}`,
    value: `${item}`,
  }));
};

export const stringToTextValue = (values?: string[]): ITextValue[] =>
  values?.map((value) => ({
    text: startCase(value),
    value,
  })) ?? [];

export const performanceMetricsToTextValue = (
  pmList?: string[],
  conversionType = 'conversions',
  toolTips?: Record<string, string>,
): ITextValue[] =>
  pmList?.map((pm) => ({
    text:
      pm === 'conversions'
        ? startCase(conversionType)
        : capitalize(startCase(pm)),
    value: pm,
    fixed: pm === 'converters',
    tooltip: toolTips?.[pm] ?? '',
  })) ?? [];

export const performanceMetricsToTop25Option = (
  pmList: string[],
  conversionType: string = 'conversions',
): IOption[] =>
  pmList?.map((pm, index) => {
    const text = 'Top 25 by';
    const value = 'TOP_25';
    if (pm === 'conversions') {
      return {
        text: `${text} ${startCase(conversionType)}`,
        value: `${value}.${pm}`,
        id: index,
        color: '',
      };
    }
    return {
      text: `${text} ${startCase(pm)}`,
      value: `${value}.${pm}`,
      id: index,
      color: '',
    };
  }) ?? [];
export const performanceMetricsToTextValueToStrList = (
  textValueList: ITextValue[],
): string[] => textValueList.map(({ value }) => `${value}`);

export const isKeyPartOfCollection = (
  key: string,
  collection: object,
): boolean => Object.keys(collection).includes(key);

export const getByIdFromCollection = <
  T extends { id?: string | number } & unknown,
>(
  findId: string,
  collection: T[],
): T | undefined =>
  (findId && collection?.find(({ id }): boolean => id === findId)) || undefined;

export const filterArrayByHideFor = <T extends IDimension | IMetric>(
  arr: T[],
  suppressionConditioners?: string[],
): T[] => {
  if (suppressionConditioners?.length) {
    return arr.filter((d) => {
      return !d.hideFor?.some((item) => suppressionConditioners.includes(item));
    });
  }
  return arr;
};

export const getBreakoutName = (
  breakoutList: IIdName[],
  breakout: DataRowGraphValue,
): string => {
  const targetBreakout = breakoutList?.find(
    (item) => item.id === breakout.VALUE,
  );
  return targetBreakout?.name ?? '';
};

export const dimensionToFilter = (
  dimension: IDimension | undefined,
  filterType: AllFilterTypes,
): RuleFilter => {
  const { id, name, is_rule, operators } = dimension ?? {};
  const field: RuleFilter = {
    field: id ?? '',
    isRule: is_rule ?? false,
    filterId: id ?? '',
    operator: (operators?.[0] ?? 'IN') as Operator,
    value: id ?? '',
    name: name ?? '',
    type: filterType,
  };
  return field;
};

export const getByKeyFromCollection = <T, C>(
  key: string,
  value: T,
  collection: C[],
): C | undefined => find(flow(get(key), isEqual(value)), collection);
