import { getSeriesId } from '../../charts/getSeriesId';
import {
  SampleGroupNames,
  ISeriesDataItem,
} from '../../../domains/reports/types';
import { IOption } from '../../../types/textValue';
import { SeriesColumnOptions } from 'highcharts';

export type IChartTargetItem = {
  MEAN?: ISeriesDataItem[];
  REACH_PC?: ISeriesDataItem[];
  TOP_CHART?: ISeriesDataItem[];
  BOTTOM_CHART?: ISeriesDataItem[];
};

export const getColumnSeries = <T extends IChartTargetItem>(
  data: {
    genpop: T;
    target: T;
  },
  targets: IOption[],
  name: string,
  key: keyof IChartTargetItem,
): Array<SeriesColumnOptions> => {
  return targets.map(
    ({ secondaryColor, color, value, id }): SeriesColumnOptions => ({
      name,
      data: data[value as SampleGroupNames][key] ?? [],
      type: 'column',
      color:
        value === SampleGroupNames.target ? color : secondaryColor ?? color,
      id: getSeriesId(value as SampleGroupNames, false, key),
      index: id,
    }),
  );
};
