import { getClass, getTestId } from 'helpers/components';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { ITableColumns } from '.';

export const tableHeaderComponentName = 'table-header';

const tableHeaderClassName = getClass(tableHeaderComponentName);

interface Props {
  columns: ITableColumns;
  scrollLeft: number;
  testId?: string;
  onActiveColumnOffsetChange: (offset: number) => void;
  onHeaderCellClick: (id: string) => void;
}

const TableHeader: FC<Props> = ({
  columns,
  scrollLeft,
  testId,
  onActiveColumnOffsetChange,
  onHeaderCellClick,
}) => {
  const headerTestId = getTestId(tableHeaderComponentName, testId);
  const headerRef = useRef<HTMLDivElement>(null);
  const activeColumnRef = useRef<HTMLSpanElement>(null);
  const [activeColumnOffset, setActiveColumnOffset] = useState(0);

  useEffect(() => {
    const { current } = activeColumnRef;

    if (current && current.offsetLeft !== activeColumnOffset) {
      setActiveColumnOffset(current.offsetLeft);
      onActiveColumnOffsetChange(current.offsetLeft);
    }
  }, [onActiveColumnOffsetChange, activeColumnOffset]);

  return (
    <div
      className={tableHeaderClassName}
      data-testid={headerTestId}
      ref={headerRef}
      style={{ left: -scrollLeft }}
    >
      {columns.map((c) => {
        const sortDirectionIndicator = c.isSortedDesc ? ' ↓' : ' ↑';
        const tableHeaderContentClassName = getClass(tableHeaderComponentName, {
          concat: ['content'],
          boolean: [
            {
              state: c.active,
              class: 'active',
            },
          ],
        });

        const handleClick = (): void => {
          if (c.id && !c.disableSort) {
            onHeaderCellClick(c.id);
          }
        };

        const handleKeyDown: React.KeyboardEventHandler<HTMLSpanElement> = (
          event,
        ) => {
          if (event.key === 'Enter' && c.id) {
            handleClick();
          }
        };

        return (
          <span
            key={c.id}
            style={{ width: c.width ?? 'auto' }}
            className={tableHeaderContentClassName}
            ref={c.active ? activeColumnRef : undefined}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
          >
            {c.Header as ReactNode}
            {c.isSorted && (
              <span className="sort-direction-indicator">
                {sortDirectionIndicator}
              </span>
            )}
          </span>
        );
      })}
    </div>
  );
};

export default TableHeader;
