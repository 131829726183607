import Icon, {
  Type as IconType,
  Color as IconColor,
  Size as IconSize,
} from 'components/Icon';
import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';
import Flex, { Direction, Horizontal, Vertical } from '../Flex';

export const multiCardComponentName = 'multi-card';

const multiCardListClass = getClass(`${multiCardComponentName}__list`);

const multiCardItemClass = getClass(`${multiCardComponentName}__card-item`);

const baseCardComponentName = 'base-card';

const baseCardIconClass = getClass(`${baseCardComponentName}__icon`);

const baseCardContainerClass = getClass(`${baseCardComponentName}__container`);

const baseCardTitleContentClass = getClass(
  `${baseCardComponentName}__title-content`,
);

const baseCardTitleClass = getClass(`${baseCardComponentName}__title`);

const baseCardSubtitleClass = getClass(`${baseCardComponentName}__subtitle`);

const baseCardContentClass = getClass(`${baseCardComponentName}__content`);

const baseCardIconClickClass = getClass(`${baseCardComponentName}__icon-click`);

export interface IBaseCard {
  iconType: IconType;
  title?: string;
  subtitle?: string;
  content?: string;
  clickToScroll?: () => void;
  children?: ReactElement;
}

export interface IMultiCard {
  testId?: string;
  cards: Array<IBaseCard>;
}

export const BaseCard: FunctionComponent<IBaseCard> = (props) => {
  const { iconType, title, subtitle, content, clickToScroll, children } = props;

  const baseCardClass = getClass(baseCardComponentName);

  return (
    <Flex horizontal={Horizontal.left} className={baseCardClass}>
      <Flex
        horizontal={Horizontal.center}
        vertical={Vertical.middle}
        className={baseCardIconClass}
      >
        <Icon type={iconType} color={IconColor.gray} size={IconSize.large} />
      </Flex>
      <Flex
        direction={Direction.column}
        horizontal={Horizontal.center}
        vertical={Vertical.top}
        className={baseCardContainerClass}
      >
        {(title || subtitle) && (
          <div className={baseCardTitleContentClass}>
            {title && <span className={baseCardTitleClass}>{title}</span>}
            {subtitle && (
              <span className={baseCardSubtitleClass}>{subtitle}</span>
            )}
          </div>
        )}
        {content && <span className={baseCardContentClass}>{content}</span>}
        {children && <div>{children}</div>}
      </Flex>
      {clickToScroll && (
        <Flex
          horizontal={Horizontal.center}
          vertical={Vertical.middle}
          className={baseCardIconClickClass}
        >
          <button
            type="button"
            onClick={() => {
              clickToScroll();
            }}
          >
            <Icon
              type={IconType.clickToScrollDown}
              color={IconColor.gray}
              size={IconSize.medium}
            />
          </button>
        </Flex>
      )}
    </Flex>
  );
};

const MultiCard: FunctionComponent<IMultiCard> = (props) => {
  const { cards, testId } = props;

  const multiCardTestId = getTestId(multiCardComponentName, testId);

  const multiCardClass = getClass(multiCardComponentName);

  return (
    <div className={multiCardClass} data-testid={multiCardTestId}>
      <ul className={multiCardListClass}>
        {cards?.map((card, i) => {
          const {
            iconType,
            title,
            subtitle,
            content,
            clickToScroll,
            children,
          } = card;

          const key = `card_${i}`;
          const listItemTestId = getTestId(
            multiCardComponentName,
            `item-${i.toString()}`,
          );

          return (
            <li
              className={multiCardItemClass}
              key={key}
              data-testid={listItemTestId}
            >
              <BaseCard
                iconType={iconType}
                title={title}
                subtitle={subtitle}
                content={content}
                clickToScroll={clickToScroll}
              >
                {children}
              </BaseCard>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MultiCard;
