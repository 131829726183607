import Flex, { Horizontal, Vertical } from 'components/Flex';
import Label from 'components/Label';
import { getId, getTestId, getClass } from 'helpers/components';
import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useMemo,
} from 'react';

export const componentName = 'query-builder-item';

export const LabelAlignments = {
  top: 'top',
} as const;

type LabelAlignment = (typeof LabelAlignments)[keyof typeof LabelAlignments];

interface IProps {
  className?: string;
  disabled?: boolean;
  id?: string;
  label: string;
  labelAlignment?: LabelAlignment;
  labelMinWidth?: number;
  testId?: string;
  tooltip?: string;
}
export const QUERY_ITEM_WRAPPER_KEY = 'item-wrapper';

const QueryBuilderItem: FunctionComponent<PropsWithChildren<IProps>> = ({
  children,
  className,
  disabled,
  id,
  label,
  labelAlignment,
  labelMinWidth,
  testId,
  tooltip,
}): ReactElement => {
  const labelVerticalAlignment = useMemo(
    () =>
      labelAlignment && labelAlignment === LabelAlignments.top
        ? Vertical.top
        : Vertical.middle,
    [labelAlignment],
  );

  const componentId = useMemo(() => getId(componentName, id), [id]);
  const componentTestId = useMemo(
    () => getTestId(componentName, testId),
    [testId],
  );
  const componentClass = useMemo(
    () => getClass(componentName, { add: [className] }),
    [className],
  );
  const labelStyle = labelMinWidth ? { minWidth: labelMinWidth } : {};

  return (
    <div data-testid={componentTestId} className={componentClass}>
      <Flex horizontal={Horizontal.left} vertical={labelVerticalAlignment}>
        <div style={labelStyle}>
          <Label
            forId={componentId}
            text={label}
            disabled={disabled}
            tooltip={tooltip}
          />
        </div>
        <div id={componentId} className={QUERY_ITEM_WRAPPER_KEY}>
          {children}
        </div>
      </Flex>
    </div>
  );
};

export default QueryBuilderItem;
