import IEvent from 'domains/events/types';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';

export const initialState: IEvent[] = [];

export default (
  eventList: IEvent[] = initialState,
  action: Action<IEvent[]>,
): IEvent[] => {
  const { payload, type } = action;
  switch (type) {
    case ActionType.EVENTS_SET_EVENTS:
      return payload;
    default:
      return eventList;
  }
};
