import IReport, {
  ExposureConversionSetNames,
  ResultBase,
} from 'domains/reports/types';
import IResultSelection from 'domains/resultSelections/types';
import { getClass } from 'helpers/components';
import React, { FunctionComponent } from 'react';
import { IOption } from 'types/textValue';
import useUserPermissions from 'hooks/useUserPermissions';
import AudienceDemographicsByIndex from './AudienceDemographics';
import { CampaignExposure } from './CampaignExposure';
import ExecutiveSummary from './ExecutiveSummary';
import CampaignDeliveryAndInsights from './CampaignDeliveryAndInsights';
import SpecializedInsights from './SpecializedInsights';

export const visualizationComponentName = 'storytelling-visualization';
const visualizationClass = getClass(visualizationComponentName);

interface IResultPage {
  breakoutChartData: ResultBase;
  miscResultData: ResultBase;
  demographicChartData: ResultBase;
  targets?: string[];
  targetsObject: IOption[];
  breakoutList: Array<{ id: string; name: string }>;
  omissionMessages: ExposureConversionSetNames[];
  demographicBreakoutList: Array<{ id: string; name: string }>;
  segmentsList: IOption[];
  showLroiInVisualization: boolean;
  resultSelection: IResultSelection;
  lastValidReport: IReport | null;
  isVisualizationOpened: boolean;
  useEquivalizedMetrics: boolean;
}

const ResultPage: FunctionComponent<IResultPage> = (props: IResultPage) => {
  const {
    targets,
    targetsObject,
    breakoutList,
    breakoutChartData,
    miscResultData,
    demographicChartData,
    omissionMessages,
    demographicBreakoutList,
    segmentsList,
    showLroiInVisualization,
    resultSelection,
    lastValidReport,
    isVisualizationOpened,
    useEquivalizedMetrics = false,
  } = props;
  const { checkPermissions } = useUserPermissions();
  return (
    <div className={`container-fluid px-4 py-4 ${visualizationClass}`}>
      <div className="column">
        <ExecutiveSummary
          targets={targets}
          targetsObject={targetsObject}
          breakoutChartData={breakoutChartData}
          miscResultData={miscResultData}
          omissionMessages={omissionMessages}
          showLroiInVisualization={showLroiInVisualization}
          useEquivalizedMetrics={useEquivalizedMetrics}
          sectionFiltersSelection={resultSelection.filters?.executiveSummary}
          lastValidReport={lastValidReport}
        />
        <CampaignDeliveryAndInsights
          targets={targetsObject}
          breakoutChartData={breakoutChartData}
          timeseriesChartData={miscResultData as ResultBase}
          breakoutList={breakoutList}
          showLroiInVisualization={showLroiInVisualization}
          useEquivalizedMetrics={useEquivalizedMetrics}
          omissionMessages={omissionMessages}
          resultSelection={resultSelection}
          lastValidReport={lastValidReport}
        />
        {checkPermissions(
          'attribution_reports.incremental_reach_by_platform::view',
        ) && <SpecializedInsights />}
        {showLroiInVisualization && (
          <>
            {checkPermissions(
              'attribution_reports.demographic_attributes::view',
            ) && (
              <AudienceDemographicsByIndex
                chartData={demographicChartData}
                targets={targetsObject}
                demographicBreakoutList={demographicBreakoutList}
                segments={segmentsList}
                omissionMessages={
                  omissionMessages as ExposureConversionSetNames[]
                }
                sectionFiltersSelection={
                  resultSelection.filters?.demographicAttributes
                }
                lastValidReport={lastValidReport}
              />
            )}
            {checkPermissions([
              'attribution_reports.impression_frequency_of_exposed_households::view',
              'OR attribution_reports.ad_exposure_to_conversion_latency::view',
            ]) && (
              <CampaignExposure
                chartData={miscResultData}
                targets={targetsObject}
                omissionMessages={
                  omissionMessages as ExposureConversionSetNames[]
                }
                resultSelection={resultSelection}
                lastValidReport={lastValidReport}
                isVisualizationOpened={isVisualizationOpened}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ResultPage;
