import IReport, {
  IChartData,
  MetricConfig,
  ISGMConfig,
  IDateRangeQueryFilter,
} from '../../../domains/reports/types';
import { get, set } from 'lodash/fp';
import ActionType from '../../../store/actions/types';
import { Action, PayloadLessAction } from '../../../types/action';

export interface IReportResult {
  chartData?: IChartData;
  displayChartData?: IChartData;
  rowCount?: number;
  targetList?: string[];
  executedReport?: {
    report?: IReport;
  };
  lastExecutedCacheQueryId?: string;
  savedSegmentsFilenames?: Array<string>;
}

export const initialState = {
  executedReport: undefined,
  chartData: {
    interval: {},
    dimensions: [],
    result: [],
    select: [],
    spatialDimensions: [],
    data: [],
    metadata: [],
    SGMConfig: {
      groups: [],
      metrics: [],
      series: [],
    },
    dateRange: {
      startDate: '',
      endDate: '',
      dateTimeRange: {
        start: '',
        end: '',
      },
    },
    rowsDropped: 0,
    tableVisible: false,
    chartVisible: false,
  },
  rowCount: 0,
  lastExecutedCacheQueryId: '',
  savedSegmentsFilenames: [],
};

const setChartData = set('chartData');
const setDateRange = set('dateRange');
const setDisplayChartData = set('displayChartData');
const setSGMConfig = set('SGMConfig');
const setExecutedReport = set('executedReport');
const cancelExecutionJob = set('executedReport.cancelling', true);

type ReducerActionType =
  | IChartData
  | ISGMConfig
  | MetricConfig
  | MetricConfig[]
  | number
  | string[]
  | string
  | IReportResult
  | {};

export const reducer = (
  reportResult: IReportResult = initialState,
  action: Action<ReducerActionType> | PayloadLessAction,
): IReportResult => {
  const { payload, type } = action as Action<ReducerActionType>;

  const chartData = reportResult.chartData as IChartData;

  switch (type) {
    case ActionType.RESULT_RETRIEVED:
      return payload as IReportResult;
    case ActionType.RESULT_SET_DISPLAY_CHART_DATA:
      return setDisplayChartData(payload as IChartData, reportResult);
    case ActionType.RESULT_SET_DATE_RANGE:
      return setChartData(
        setDateRange(payload as IDateRangeQueryFilter, chartData),
        reportResult,
      );
    case ActionType.RESULT_SET_SGM_CONFIG:
      return setChartData(
        setSGMConfig(payload as ISGMConfig, chartData),
        reportResult,
      );
    case ActionType.RESULT_RESET_RESULT:
      return initialState;
    case ActionType.RESULT_SET_EXECUTED_REPORT:
      return setExecutedReport(payload, reportResult);
    case ActionType.RESULT_SET_EXECUTED_REPORT_STARTED: {
      return {
        ...setExecutedReport(
          {
            ...reportResult.executedReport,
            cacheQueryId: payload,
          },
          reportResult,
        ),
        lastExecutedCacheQueryId: '',
        savedSegmentsFilenames: [],
      };
    }
    case ActionType.RESULT_ATTRIBUTION_SEGMENTS_SAVE_SET_FILENAMES: {
      return {
        ...reportResult,
        savedSegmentsFilenames: payload as string[],
      };
    }
    case ActionType.RESULT_ATTRIBUTION_SEGMENTS_SAVE_RESET_FILENAMES: {
      return {
        ...reportResult,
        savedSegmentsFilenames: [],
      };
    }
    case ActionType.RESULT_SET_EXECUTED_REPORT_FINISHED:
      const lastExecutedCacheQueryId = get(
        'executedReport.cacheQueryId',
        reportResult,
      );
      return {
        ...setExecutedReport(payload, reportResult),
        lastExecutedCacheQueryId,
      };
    case ActionType.RESULT_SET_EXECUTED_REPORT_CANCELLING:
      return cancelExecutionJob(reportResult);
    case ActionType.RESULT_SET_EXECUTED_REPORT_CANCELLED:
      return setExecutedReport(payload, reportResult);
    default:
      return reportResult;
  }
};

export default reducer;
