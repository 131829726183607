import {
  ResultBaseSubStructure,
  ResultBaseTotalDataTarget,
} from 'domains/reports/types';

export const filterOnNetwork = (
  dataTarget: ResultBaseTotalDataTarget,
): ResultBaseTotalDataTarget => {
  const filterOnNetworkValue = (dataRow: ResultBaseSubStructure): boolean => {
    const onNetworkVal = dataRow.campaign?.ON_NETWORK?.VALUE;

    if (typeof onNetworkVal === 'boolean') return onNetworkVal;
    return false;
  };

  const dataTargetGenpop = dataTarget.genpop;
  const dataTargetTarget = dataTarget.target;

  const target = dataTargetTarget?.filter(filterOnNetworkValue);
  const genpop = dataTargetGenpop?.filter(filterOnNetworkValue);

  return { target, genpop };
};
