import {
  DataRowGraph,
  ISeriesDataItem,
  ResultBaseSubStructure,
} from '../../domains/reports/types';

export const getSeriesDataItem = (
  key: keyof DataRowGraph,
  data?: ResultBaseSubStructure[],
): ISeriesDataItem[] =>
  data?.map(
    (item) =>
      ({
        y: Number(item?.campaign?.[key]?.VALUE) || 0,
        custom: {
          displayValue: `${item?.campaign?.[key]?.DISPLAY_VALUE}%`,
        },
      }) as ISeriesDataItem,
  ) ?? [];
