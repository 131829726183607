import {
  ResultBaseSubStructure,
  ResultBaseTotalDataTarget,
} from 'domains/reports/types';
import { getBreakoutDataTarget } from './getBreakoutDataTarget';
import { getNetworkBreakoutDataTarget } from './getNetworkBreakoutDataTarget';
import { getUniqPlatformBreakoutData } from './getUniqPlatformBreakoutData';
import { IState } from './types';

const getDataObject = (state: IState): ResultBaseTotalDataTarget => {
  const { filters, hasNetworkOnOff, hasUniquePlatform, data } = state;
  const { breakout, netOnOff, uniqueOnOff } = filters;

  if (breakout === 'NETWORK' && hasNetworkOnOff) {
    return getNetworkBreakoutDataTarget(data, breakout, netOnOff);
  } else if (breakout === 'VIEWING_TYPE' && hasUniquePlatform && !uniqueOnOff) {
    // Combined On => VIEWING_TYPE
    // Combined Off => UNIQUE_VIEWING_TYPE_COMBINATIONS
    return getUniqPlatformBreakoutData(data);
  }
  return getBreakoutDataTarget(data, breakout);
};

export const getCurrentBreakoutData = (
  state: IState,
): ResultBaseTotalDataTarget => {
  const { netOnOff } = state.filters;

  const breakoutData: ResultBaseTotalDataTarget = getDataObject(state);

  // Don't sort data if netOnOff flag is true
  if (netOnOff) {
    return breakoutData;
  }

  return {
    target: breakoutData.target as ResultBaseSubStructure[],
    genpop: breakoutData.genpop as ResultBaseSubStructure[],
  };
};
