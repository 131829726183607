import { fetchApi } from 'helpers/fetching';
import { showErrorToast, showSuccessToast } from 'helpers/general';
import { delay } from 'helpers/utils';
import history from 'navigationHistory';
import { ParseResult } from 'papaparse';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Index } from 'routes';
import * as processingActions from 'store/actions/processing';
import ActionType from 'store/actions/types';
import * as editManyUsersSelector from 'store/selectors/edit-many-users';
import { Action, PayloadLessAction } from 'types/action';
import { IParsedCSV, IValidatedCell } from 'types/edit-many-users';
import FetchMethod from 'types/fetchMethod';
import State from 'types/state';

export const setPreviewTable = (
  payload: IValidatedCell[][],
): Action<IValidatedCell[][]> => ({
  type: ActionType.ADMIN_USERS_SET_EDIT_MANY_PREVIEW_TABLE,
  payload,
});

export const resetPreviewTable = (): PayloadLessAction => ({
  type: ActionType.ADMIN_USERS_RESET_EDIT_MANY_PREVIEW_TABLE,
});

export const validateUploadedFile =
  (payload: ParseResult<IParsedCSV>) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    const data = payload.data;
    dispatch(processingActions.startProcessing('Validating...'));
    const validationResult = await fetchApi({
      endpoint: `/${Index.SEGMENT_USERS}/edit-many-validation`,
      method: FetchMethod.POST,
      payload: data,
    });
    dispatch(processingActions.finishProcessing());
    if (validationResult.error) {
      showErrorToast(
        validationResult.error?.message ?? 'Failed to validate file payload!',
      );
      return;
    }
    dispatch(setPreviewTable(validationResult.data as IValidatedCell[][]));
  };

export const handleSubmit =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const payload = editManyUsersSelector.previewTable(state);
    dispatch(processingActions.startProcessing('Processing...'));
    const result = await fetchApi({
      endpoint: `/${Index.SEGMENT_USERS}/edit-many`,
      method: FetchMethod.POST,
      payload,
    });
    dispatch(processingActions.finishProcessing());

    if (result.error) {
      showErrorToast(result.error?.message ?? 'Failed to update users!');
      return;
    }

    history.push(`/${Index.SEGMENT_ADMIN}/${Index.SEGMENT_USERS}`);
    // need to wait a few milliseconds to render toast messages after navigating
    await delay(10);
    showSuccessToast('Users successfully updated!');
    dispatch(resetPreviewTable());
  };
