import { ResultBase, ResultBaseTotalDataTarget } from 'domains/reports/types';
import { get } from 'lodash/fp';

export const getBreakoutDataTarget = (
  currentData: ResultBase,
  breakout: string,
): ResultBaseTotalDataTarget => {
  if (!currentData.exposed_all) {
    return {
      genpop: [],
      target: [],
    };
  }

  const exposureConversionSetData = get(breakout, currentData.exposed_all);

  return (
    exposureConversionSetData ?? {
      genpop: [],
      target: [],
    }
  );
};
