import IError from 'types/error';
import FetchAction from 'types/fetchAction';
import FetchMethod from 'types/fetchMethod';
import { IMeta } from '../types/apiReturn';
import useFetch from './useFetch';
import { get } from 'lodash';

const actionToMethod: { [key: string]: FetchMethod } = {
  DELETE: FetchMethod.DELETE,
  CREATE: FetchMethod.POST,
  UPDATE: FetchMethod.PATCH,
  CLONE: FetchMethod.POST,
  COPY: FetchMethod.POST,
};

type ItemAndCallBack<T, U = void> = {
  item?: Partial<T>;
  params?: object;
  onError?: (err: IError) => void;
  onSuccess?: (data: T[], meta?: IMeta<T, U>) => void;
};

type hookReturn<T, U = void> = {
  doCreate: ({
    item,
    params,
    onError,
    onSuccess,
  }: ItemAndCallBack<T, U>) => Promise<void>;
  doDelete: ({
    item,
    onError,
    onSuccess,
  }: ItemAndCallBack<T, U>) => Promise<void>;
  doDeleteById: ({
    item,
    onError,
    onSuccess,
  }: ItemAndCallBack<T, U>) => Promise<void>;
  doUpdate: ({
    item,
    onError,
    onSuccess,
  }: ItemAndCallBack<T, U>) => Promise<void>;
  loading: boolean;
};

function useItemAdmin<T extends {}, U = void>({
  endpoint,
}: {
  endpoint: string;
}): hookReturn<T, U> {
  const { loading, doFetch } = useFetch<T, U>();

  const doDelete = async ({
    item,
    params,
    onSuccess,
    onError,
  }: ItemAndCallBack<T, U>): Promise<void> => {
    doFetch({
      endpoint,
      onSuccess,
      onError,
      payload: params ? { item, params } : item,
      method: actionToMethod[FetchAction.DELETE],
    });
  };

  const doDeleteById = async ({
    item,
    onSuccess,
    onError,
  }: ItemAndCallBack<T, U>): Promise<void> => {
    doFetch({
      endpoint: `${endpoint}/${get(item, 'id', '')}`,
      onSuccess,
      onError,
      method: actionToMethod[FetchAction.DELETE],
    });
  };

  const doCreate = async ({
    item,
    params,
    onSuccess,
    onError,
  }: ItemAndCallBack<T, U>): Promise<void> => {
    doFetch({
      endpoint,
      onSuccess,
      onError,
      payload: params ? { item, params } : item,
      method: actionToMethod[FetchAction.CREATE],
    });
  };

  const doUpdate = async ({
    item,
    params,
    onSuccess,
    onError,
  }: ItemAndCallBack<T, U>): Promise<void> => {
    doFetch({
      endpoint,
      onSuccess,
      onError,
      payload: params ? { item, params } : item,
      method: actionToMethod[FetchAction.UPDATE],
    });
  };

  return {
    doDelete,
    doDeleteById,
    doUpdate,
    doCreate,
    loading,
  };
}

export default useItemAdmin;
