import ChartOmissionAlert from 'components/ChartOmissionAlert';
import { ResultEnhancedDemographicGroups } from 'domains/reports/types';
import { getClass, getTestId } from 'helpers/components';
import Highcharts, { Chart, SeriesLineOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { merge } from 'lodash';
import React, { useMemo, useState } from 'react';
import { baseDumbbellChartConfig } from '../chartConfig';
import {
  ITransformerInputFilters,
  useDumbbellChartTransformer,
} from '../transformer';

export interface IDumbbellChartProps {
  data: ResultEnhancedDemographicGroups;
  filters: ITransformerInputFilters;
  yAxisTitle: string;
  showOverlay: boolean;
}

export const dumbbellChartName = 'dumbbell-chart';

const dumbbellChartWrapperClassName = getClass(dumbbellChartName, {
  concat: ['wrapper'],
});

const dumbbellChartOverlayClassName = getClass(dumbbellChartName, {
  concat: ['overlay'],
});

const dumbbellChartOmissionClassName = getClass(dumbbellChartName, {
  concat: ['omission'],
});

export const dumbbellChartTestId = getTestId(dumbbellChartName);

const DumbbellChart: React.FC<IDumbbellChartProps> = ({
  data,
  filters,
  yAxisTitle,
  showOverlay,
}) => {
  const [plotBoundary, setPlotBoundary] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const series = useDumbbellChartTransformer({
    data,
    filters,
  });

  const options = useMemo(() => {
    const categories = series.map((segment) => segment.name);

    return {
      ...baseDumbbellChartConfig,
      series,
      yAxis: merge({}, baseDumbbellChartConfig.yAxis, {
        title: {
          text: yAxisTitle,
        },
        categories,
      }),
      chart: merge({}, baseDumbbellChartConfig.chart, {
        events: {
          render() {
            const { plotHeight, plotLeft, plotTop, plotWidth } =
              this as unknown as Chart;
            const newBoundary = {
              top: plotTop as number,
              left: plotLeft as number,
              width: plotWidth as number,
              height: plotHeight as number,
            };

            setPlotBoundary(newBoundary);
          },
        },
      }),
    };
  }, [series, yAxisTitle]);

  const hasOmission = useMemo(() => {
    const doAllSeriesHaveEmptyData = series?.every(
      (s) => !(s as SeriesLineOptions).data?.length,
    );

    return doAllSeriesHaveEmptyData;
  }, [series]);

  return (
    <div
      className={dumbbellChartWrapperClassName}
      data-testid={dumbbellChartTestId}
    >
      {showOverlay && (
        <div
          className={dumbbellChartOverlayClassName}
          style={plotBoundary}
          data-testid={`${dumbbellChartTestId}-overlay`}
        />
      )}
      {hasOmission && (
        <div className={dumbbellChartOmissionClassName} style={plotBoundary}>
          <ChartOmissionAlert />
        </div>
      )}
      <HighchartsReact
        allowChartUpdate
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default DumbbellChart;
