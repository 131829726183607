import { IMappedChartData } from '../../../types/multiChartTransformers';
import { IMultiChartConfig } from '../../../types/MultiChart';
import {
  SampleGroupNames,
  ResultBaseSubStructure,
  ResultBase,
  ISeriesDataItem,
} from '../../../domains/reports/types';
import { getSeriesDataItem } from '../../charts/getSeriesDataItem';
import { IOption } from '../../../types/textValue';
import { getColumnSeries } from './getColunmSeries';
import { xAxisLabelFormatter } from './xAxisLabelFormartter';

export interface ITargetFilters {
  target: IOption[];
  percentileIndex: number;
}

export interface IChartTargetItem {
  TOP_CHART: ISeriesDataItem[];
  BOTTOM_CHART: ISeriesDataItem[];
}

const MIN_ZERO_PERCENT: number = 0;
const MAX_HUNDRED_PERCENT: number = 100;

const DEFAULT_SAMPLE_GROUPS = {
  genpop: [],
  target: [],
};

export const mapDataToChartConfig = ({
  exposed_all,
  exposed_converted,
}: ResultBase): IMappedChartData<IChartTargetItem> => {
  const allHHFrequency =
    exposed_all?.HH_FREQUENCY_cumulative ?? DEFAULT_SAMPLE_GROUPS;
  const convertedHHFrequency =
    exposed_converted?.HH_FREQUENCY_cumulative ?? DEFAULT_SAMPLE_GROUPS;

  const targetKeys = Object.keys(allHHFrequency) as SampleGroupNames[];

  const dataByTarget = targetKeys.reduce((acc, target) => {
    const allHHFrequencyData = allHHFrequency[target];
    const convertedHHFrequencyData = convertedHHFrequency?.[target];
    return {
      ...acc,
      [target]: {
        TOP_CHART: getSeriesDataItem('REACH_PC', convertedHHFrequencyData),
        BOTTOM_CHART: getSeriesDataItem('REACH_PC', allHHFrequencyData),
      },
    };
  }, {});

  return {
    categories:
      allHHFrequency.genpop?.map((item: ResultBaseSubStructure) => {
        return `${item?.name?.DISPLAY_VALUE?.toString()} IMPRESSIONS`;
      }) ?? [],
    ...dataByTarget,
  } as IMappedChartData<IChartTargetItem>;
};

export const getChartConfig = (
  data: IMappedChartData<IChartTargetItem>,
  { target, percentileIndex }: ITargetFilters,
): IMultiChartConfig => {
  const chartData = { genpop: data.genpop, target: data.target };
  return {
    shared: {
      xAxis: {
        categories: data.categories,
        title: {
          text: 'Impression Frequency',
        },
        labels: {
          formatter: xAxisLabelFormatter,
        },
        plotLines: [
          {
            value: percentileIndex,
            color: 'transparent',
          },
        ],
      },
    },
    charts: {
      top: {
        chart: { margin: [35, 1, 15, 80], height: 243 },
        plotOptions: {
          column: {
            minPointLength: 2,
          },
        },
        yAxis: {
          title: {
            text: 'Cumulative % of Exposed Converted Households',
          },
          labels: {
            format: '{value}%',
          },
          min: MIN_ZERO_PERCENT,
          max: MAX_HUNDRED_PERCENT,
        },
        series: getColumnSeries<IChartTargetItem>(
          chartData,
          target,
          'Cumulative % of <br> Exposed Converted Households',
          'TOP_CHART',
        ),
      },
      bottom: {
        chart: { margin: [15, 1, 80, 80], height: 287 },
        plotOptions: {
          column: {
            minPointLength: 2,
          },
        },
        yAxis: {
          title: {
            text: 'Cumulative % of Exposed Households',
          },
          labels: {
            format: '{value}%',
          },
          min: MIN_ZERO_PERCENT,
          max: MAX_HUNDRED_PERCENT,
        },
        series: getColumnSeries<IChartTargetItem>(
          chartData,
          target,
          'Cumulative % of <br> Exposed Households',
          'BOTTOM_CHART',
        ),
      },
    },
  };
};
