import { orderBy } from 'lodash';
import { IOption } from 'types/textValue';
import { performanceMetricsToTop25Option } from '../types';

export const getBreakoutOptions = (
  breakoutList: Array<{ id: string; name: string }>,
  performanceMetricGroups: string[],
  canViewPersuasionIndex: boolean,
  conversionType?: string,
): IOption[] => {
  const filteredBreakouts = breakoutList?.filter(
    (breakout) =>
      breakout.id !== 'NET_ON_OFF' &&
      breakout.id !== 'UNIQUE_VIEWING_TYPE_COMBINATIONS',
  );

  const sortedList = orderBy(filteredBreakouts, ({ name }) => name, ['asc']);

  const top25Options = canViewPersuasionIndex
    ? performanceMetricsToTop25Option(performanceMetricGroups, conversionType)
    : [];

  const options = sortedList.map(
    ({ name, id }, index): IOption => ({
      text: name,
      value: id,
      color: '',
      id: top25Options?.length + index,
    }),
  );
  return [...top25Options, ...options];
};
