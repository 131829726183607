import ChartOmissionAlert from 'components/ChartOmissionAlert';
import { TableDataType } from 'components/Table';
import { IChartConfig } from 'helpers/charts/commonChartDesignConfig';
import { getClass, getTestId } from 'helpers/components';
import Highcharts, {
  Point,
  YAxisPlotLinesOptions,
  Chart,
  SelectEventObject,
} from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useChartReflow } from 'hooks/useChartReflow';
import { merge } from 'lodash';
import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { baseBubbleChartConfig } from '../../chartConfig';

export interface IBubbleChartPoint {
  x: string | number;
  y: string | number;
  z: string | number;
  name: string | number;
  id: string;
  [key: string]: string | number | null;
}

export type IBubbleChartData = Array<IBubbleChartPoint>;

export type IBubbleChartSeries = Array<{
  data: IBubbleChartData;
  color: string;
  name: string;
}>;

export interface IPlotLineOptions {
  text: string;
  value: number;
  color?: string;
}

export type IPlotLines = Array<IPlotLineOptions>;

interface BubbleChartProps {
  series: IBubbleChartSeries;
  xAxisName: string;
  yAxisName: string;
  chartConfig?: IChartConfig;
  yPlotLines?: IPlotLines;
  hoveredPoint?: TableDataType;
  overlayElement?: ReactElement;
  showOverlay?: boolean;
  testId?: string;
}

export const bubbleChartName = 'bubble-chart';

const bubbleChartWrapperClassName = getClass(bubbleChartName, {
  concat: ['wrapper'],
});

const bubbleChartOverlayClassName = getClass(bubbleChartName, {
  concat: ['overlay'],
});

const renderPlotLineDot = (doRender: boolean, color?: string): string =>
  doRender && color
    ? `
  <span
    style="
      background-color: ${color};
      width: 6px;
      height: 6px;
      display: inline-block;
      border-radius: 50%;
      margin: 2px 4px 2px 0;"
  ></span>
`
    : '';

const createYAxisPlotLines = (
  options?: IPlotLines,
): YAxisPlotLinesOptions[] | undefined =>
  options?.map((option: IPlotLineOptions) => ({
    dashStyle: 'LongDash',
    color: '#383B41',
    value: option.value,
    zIndex: 1,
    label: {
      text: `
      <div>
      ${renderPlotLineDot(options?.length > 1, option.color)}
      <span>${option.text}</span>
      </div>
    `,
      align: 'left',
      verticalAlign: 'bottom',
      rotation: 0,
      y: 12,
      useHTML: true,
      style: {
        color: '#000',
        'font-weight': 400,
        'font-size': '12px',
      },
    },
  }));

const BubbleChart: FC<BubbleChartProps> = ({
  series,
  xAxisName,
  yAxisName,
  chartConfig,
  hoveredPoint,
  yPlotLines,
  overlayElement,
  showOverlay,
  testId,
}) => {
  const bubbleChartTestId = getTestId(bubbleChartName, testId);

  const [startEndOnTick, setStartEndOnTick] = useState(true);
  const [, setHoveredPoint] = useState<Highcharts.Point>();
  const [plotBoundary, setPlotBoundary] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const baseOptions = useMemo(
    () =>
      chartConfig
        ? merge({}, baseBubbleChartConfig, chartConfig)
        : baseBubbleChartConfig,
    [chartConfig],
  );

  const options = useMemo(
    () => ({
      ...baseOptions,
      series,
      xAxis: merge({}, baseOptions.xAxis, {
        title: {
          text: xAxisName,
        },
        startOnTick: startEndOnTick,
        endOnTick: startEndOnTick,
      }),
      yAxis: merge({}, baseOptions.yAxis, {
        plotLines: createYAxisPlotLines(yPlotLines),
        title: {
          text: yAxisName,
        },
      }),
      chart: merge({}, baseOptions.chart, {
        events: {
          render() {
            const { plotHeight, plotLeft, plotTop, plotWidth } =
              this as unknown as Chart;
            const newBoundary = {
              top: plotTop as number,
              left: plotLeft as number,
              width: plotWidth as number,
              height: plotHeight as number,
            };

            setPlotBoundary(newBoundary);
          },
          // Note: "startOnTick" and "endOnTick" destroys Panning
          // this hack disables them when zoomed in
          selection(event: SelectEventObject) {
            setStartEndOnTick(!!event.resetSelection);
          },
        },
      }),
    }),
    [series, yPlotLines, xAxisName, yAxisName, startEndOnTick, baseOptions],
  );
  useEffect(() => {
    setHoveredPoint(undefined);
  }, [options]);

  // NOTE: To eliminate race conditions I've used prev to toggle previous point's state
  useEffect(() => {
    if (hoveredPoint) {
      setHoveredPoint((prev) => {
        const targetSeries = chartRef.current?.chart.series.find(
          (s) => s.name === hoveredPoint.target,
        );

        const newPoint = targetSeries?.points.find(
          (p) => (p as Point & IBubbleChartPoint).id === hoveredPoint.id,
        );

        prev?.setState();
        newPoint?.setState('hover');

        return newPoint;
      });
    } else {
      setHoveredPoint((prev) => {
        prev?.setState();
        return undefined;
      });
    }
  }, [hoveredPoint]);

  const hasOmission = useMemo(() => {
    const doAllSeriesHaveEmptyData = series.every((s) => !s.data.length);
    return doAllSeriesHaveEmptyData;
  }, [series]);

  useChartReflow(chartRef.current?.chart, containerRef.current);

  return (
    <div
      className={bubbleChartWrapperClassName}
      data-testid={bubbleChartTestId}
      ref={containerRef}
    >
      {hasOmission && (
        <div className={bubbleChartOverlayClassName} style={plotBoundary}>
          <ChartOmissionAlert />
        </div>
      )}
      {overlayElement && showOverlay && (
        <div className={bubbleChartOverlayClassName} style={plotBoundary}>
          {overlayElement}
        </div>
      )}

      <HighchartsReact
        allowCharUpdate
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
        oneToOne
      />
    </div>
  );
};

export default BubbleChart;
