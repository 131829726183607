import { Popover, Style, Placement } from 'components/Popover';
import { getClass, getTestId } from 'helpers/components';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import noop from 'lodash/noop';
import partial from 'lodash/partial';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import PopoverMenuContent from './content';

export const popoverMenuComponentName = 'popover-menu';

export { Placement };

export interface IMenuOption {
  key: string;
  option: ReactNode;
  visible?: boolean;
}

type Props = {
  options: IMenuOption[];
  children: ReactElement | string;
  placement?: Placement;
  wrapperClassName?: string;
  customStickerWrapperClass?: string;
};

const PopoverMenu: React.FunctionComponent<Props> = (props: Props) => {
  const {
    options,
    children,
    placement,
    wrapperClassName,
    customStickerWrapperClass,
  } = props;

  const [showTooltip, setShowTooltip] = useState(false);
  const alwaysShowTooltip = useCallback(partial(setShowTooltip, true), []);
  const ref = useRef<HTMLDivElement>(null);

  const contentClass = getClass(popoverMenuComponentName);
  const contentTestId = getTestId(popoverMenuComponentName);

  useOnOutsideClick(ref, partial(setShowTooltip, false), { capture: false });
  const handleOnClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setShowTooltip(false);
  };

  return (
    <div
      onKeyDown={noop}
      ref={ref}
      className={contentClass}
      data-testid={contentTestId}
      onClick={alwaysShowTooltip}
    >
      <Popover
        visible={showTooltip}
        controlled
        style={Style.primary}
        placement={placement}
        matchTriggerWidth={false}
        customStickerWrapperClass={customStickerWrapperClass}
        content={
          <PopoverMenuContent
            onClick={handleOnClick}
            options={options}
            className={wrapperClassName}
          />
        }
      >
        {children}
      </Popover>
    </div>
  );
};

export default PopoverMenu;
