import IClient from 'domains/clients/types';
import {
  trackUserLogin,
  trackUserLogout,
  trackUserSelectedClient,
} from 'helpers/mixpanel';
import { get } from 'lodash/fp';
import { Action, PayloadLessAction } from 'types/action';
import ILoggedUserState from 'types/userState';
import ActionType from './types';

export const loginSuccess = (
  payload: ILoggedUserState,
): Action<ILoggedUserState> => {
  trackUserLogin(payload);
  return {
    type: ActionType.USER_LOGIN_SUCCESS,
    payload,
  };
};

export const setUserMinDate = (payload: string): Action<string> => {
  return {
    type: ActionType.USER_SET_MIN_DATE,
    payload,
  };
};

export const loginFail = (): PayloadLessAction => ({
  type: ActionType.USER_LOGIN_FAIL,
});

export const logoutSuccess = (): PayloadLessAction => {
  trackUserLogout();
  return {
    type: ActionType.USER_LOGOUT,
  };
};

export const updateUser = (
  payload: ILoggedUserState,
): Action<ILoggedUserState> => ({
  type: ActionType.USER_UPDATE,
  payload,
});

export const setToken = (payload: string): Action<string> => ({
  type: ActionType.USER_SET_TOKEN,
  payload,
});

export const setUserSelectedClient = (
  payload: IClient,
): Action<string | undefined> => {
  const userName = get('name', payload);
  const userId = get('id', payload);
  trackUserSelectedClient(userName);
  return {
    type: ActionType.USER_SET_SELECTED_CLIENT,
    payload: userId,
  };
};
