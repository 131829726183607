import {
  IConfigOptionState,
  IConfigOptionUiForm,
  IToggleConfigOptionValuePosition,
  IUpdateConfigOptionValue,
} from 'domains/configOptions/types';
import { cloneDeep, set } from 'lodash';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';
import { v4 as uuid } from 'uuid';

export const initialState = {
  isLoadingDetails: false,
  isSaving: false,
  form: {
    id: '',
    configOptionId: '',
    configOptionError: false,
    values: [
      {
        text: '',
        value: '',
        id: uuid(),
        hasTextError: false,
        hasValueError: false,
      },
    ],
  },
};

const reducer = (
  state: IConfigOptionState = initialState,
  action: Action<
    | IConfigOptionUiForm
    | IUpdateConfigOptionValue
    | number
    | string
    | IToggleConfigOptionValuePosition
    | number[]
  >,
): IConfigOptionState => {
  const { type, payload } = action;
  const currentFormValues = state.form.values;

  switch (type) {
    case ActionType.CONFIG_OPTIONS_DETAILS_LOAD_ENDED:
      return { ...state, isLoadingDetails: false };
    case ActionType.CONFIG_OPTIONS_DETAILS_LOAD_STARTED:
      return { ...state, isLoadingDetails: true };
    case ActionType.CONFIG_OPTIONS_DETAILS_FORM_SET:
      return {
        ...state,
        isLoadingDetails: false,
        form: payload as IConfigOptionUiForm,
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_FORM_ID_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          configOptionId: payload as string,
          configOptionError: false,
        },
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_UPDATE:
      const values = cloneDeep(currentFormValues);
      const { index, field, value } = payload as IUpdateConfigOptionValue;
      set(values, [index, field], value);
      if (field === 'text') {
        set(values, [index, 'hasTextError'], false);
      }
      if (field === 'value') {
        set(values, [index, 'hasValueError'], false);
      }
      return {
        ...state,
        form: {
          ...state.form,
          values,
        },
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_ADD:
      return {
        ...state,
        form: {
          ...state.form,
          values: [
            {
              text: '',
              value: '',
              id: uuid(),
              hasTextError: false,
              hasValueError: false,
            },
            ...state.form.values,
          ],
        },
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_DELETE:
      if (state.form.values.length === 1) return state;
      const removeIndex = payload as number;
      return {
        ...state,
        form: {
          ...state.form,
          values: state.form.values.filter((_, index) => index !== removeIndex),
        },
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_TOGGLE:
      const { pos1, pos2 } = payload as IToggleConfigOptionValuePosition;
      return {
        ...state,
        form: {
          ...state.form,
          values: currentFormValues.map((item, index) => {
            if (index === pos1) return currentFormValues[pos2];
            if (index === pos2) return currentFormValues[pos1];
            return item;
          }),
        },
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_SAVE_STARTED:
      return {
        ...state,
        isSaving: true,
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_SAVE_ENDED:
      return {
        ...state,
        isSaving: false,
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_SET_VALUE_ERRORS:
      const valueErrorIndexes = payload as number[];
      return {
        ...state,
        form: {
          ...state.form,
          values: currentFormValues.map((item, index) => {
            if (valueErrorIndexes.includes(index))
              return { ...item, hasValueError: true };
            return item;
          }),
        },
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_SET_TEXT_ERRORS:
      const textErrorIndexes = payload as number[];
      return {
        ...state,
        form: {
          ...state.form,
          values: currentFormValues.map((item, index) => {
            if (textErrorIndexes.includes(index))
              return { ...item, hasTextError: true };
            return item;
          }),
        },
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_FORM_SET_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          configOptionError: true,
        },
      };
    case ActionType.CONFIG_OPTIONS_DETAILS_FORM_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
