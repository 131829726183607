import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';
import SegmentedControl from 'components/SegmentedControl';
import { isBaseReportSaving } from 'domains/reports/adapters/rulesets';
import { getClass } from 'helpers/components';
import { find } from 'lodash/fp';
import React, { FunctionComponent, useMemo, useCallback } from 'react';
import ITextValue from 'types/textValue';
import { LroiMethodOptions } from './LroiMethodOptions.const';
import { IMethodologySectionProps } from './types';

const LOCALE_FIELD_AGGREGATION_METHOD = 'Method';

const DOM_KEY_AGGREGATION_METHOD = 'aggregation-method';

const editReportComponentName = 'edit-attribution-report';

const aggregationMethodClass = getClass(editReportComponentName, {
  concat: [DOM_KEY_AGGREGATION_METHOD],
});

export const MethodologySection: FunctionComponent<IMethodologySectionProps> = (
  props,
) => {
  const { report, toolbar, setAggregationMethodAction, aggregationMethod } =
    props;
  const { isRunLaunched } = toolbar;

  const isReportRunning = isRunLaunched || isBaseReportSaving(report);

  const handleSetAggregationMethod = useCallback(
    (selected: ITextValue): void =>
      setAggregationMethodAction(selected.value as string),
    [setAggregationMethodAction],
  );
  const selectedAggregationMethod = useMemo(
    () => find({ value: aggregationMethod }, LroiMethodOptions),
    [aggregationMethod],
  );

  return (
    <>
      <QueryBuilderItem label={LOCALE_FIELD_AGGREGATION_METHOD}>
        <div className={aggregationMethodClass}>
          <SegmentedControl
            upperCase
            disabled={isReportRunning}
            onSelectComplex={handleSetAggregationMethod}
            options={LroiMethodOptions}
            selected={selectedAggregationMethod?.text}
          />
        </div>
      </QueryBuilderItem>
    </>
  );
};
