import { getTestId, getClass } from 'helpers/components';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import React, { FunctionComponent, useState, useRef, useCallback } from 'react';
import { IOption } from 'types/textValue';
import { compact } from 'lodash';
import ChipDropdown from './ChipDropdown';
import { sortOptions } from './helpers';

export const multiSelectDropdownName = 'multi-select-dropdown';

export const multiSelectDropdownArrowClass = getClass(
  `${multiSelectDropdownName}__arrow`,
);

export const multiSelectDropdownInputClass = getClass(
  `${multiSelectDropdownName}__input`,
);

export const multiSelectDropdownInputPlaceholderClass = getClass(
  `${multiSelectDropdownName}__input__placeholder`,
);

export const multiSelectDropdownSelectedChipsClass = getClass(
  `${multiSelectDropdownName}__selected-chips`,
);

export const multiSelectDropdownOptionsClass = getClass(
  `${multiSelectDropdownName}__options`,
);

export const multiSelectDropdownOptionsListItemClass = getClass(
  `${multiSelectDropdownName}__option-item`,
);

export interface IMultiSelectDropdown {
  testId?: string;
  placeholder?: string;
  minimumChipsSelected?: number;
  maximumChipsSelected?: number;
  options: IOption[];
  selected: IOption[];
  multiSelect?: boolean;
  showLegend?: boolean;
  disabled?: boolean;
  onChange: (selected: IOption[]) => void;
}

const INFINITE_AMOUNT = -1;

const MultiSelectDropdown: FunctionComponent<IMultiSelectDropdown> = (
  props,
) => {
  const refDropdown = useRef<HTMLDivElement>(null);

  const {
    testId,
    placeholder,
    maximumChipsSelected = INFINITE_AMOUNT,
    minimumChipsSelected = 0,
    options,
    selected = [],
    multiSelect,
    showLegend = true,
    disabled,
    onChange,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggleStatus = (): void => {
    if (disabled) return;
    setIsOpen((prevState) => !prevState);
  };

  useOnOutsideClick(
    [refDropdown],
    useCallback(() => {
      setIsOpen(false);
    }, []),
  );

  const sortedOptions = compact(sortOptions(options));
  const sortedSelected = compact(sortOptions(selected));

  const removeOption = (option: IOption): void => {
    if (selected.length <= minimumChipsSelected) {
      return;
    }

    const newOptions: IOption[] =
      selected.filter((opt) => option.id !== opt.id) ?? [];
    onChange(newOptions);
  };

  const addOption = (option: IOption): void => {
    if (
      maximumChipsSelected !== INFINITE_AMOUNT &&
      selected.length >= maximumChipsSelected
    ) {
      return;
    }

    if (!multiSelect && selected && selected.length > 0) {
      onChange([option]);
      return;
    }

    onChange([...selected, option]);
  };

  const toggleOption = (option: IOption): void => {
    const isOptionSelected = selected.some((opt) => opt.id === option.id);
    if (isOptionSelected) {
      removeOption(option);
    } else {
      addOption(option);
      if (!multiSelect) {
        setIsOpen(false);
      }
    }
  };

  const isSelected = (i: number): boolean =>
    compact(selected).some((entry) => entry?.id === i);

  const multiSelectDropdownClass = getClass(multiSelectDropdownName, {
    boolean: [
      {
        state: isOpen,
        class: '--open',
      },
    ],
  });

  const multiSelectDropdownTestId = getTestId(multiSelectDropdownName, testId);
  const multiSelectDropdownWrapperTestId = getTestId(
    `${multiSelectDropdownName}__wrapper`,
    testId,
  );

  return (
    <div
      className={multiSelectDropdownClass}
      data-testid={multiSelectDropdownTestId}
      ref={refDropdown}
    >
      <div
        className={multiSelectDropdownInputClass}
        data-testid={multiSelectDropdownWrapperTestId}
        aria-hidden="true"
        onClick={toggleStatus}
      >
        <div className={multiSelectDropdownArrowClass} />
        {sortedSelected && sortedSelected.length > 0 ? (
          sortedSelected.map((option) => {
            const { id, text, value, color } = option;
            const multiSelectDropdownSelectedChipTestId = getTestId(
              `${multiSelectDropdownName}__selected-chip__${id}`,
              testId,
            );

            return (
              <div
                data-testid={multiSelectDropdownSelectedChipTestId}
                className={multiSelectDropdownSelectedChipsClass}
                onClick={(e) => {
                  e.stopPropagation();
                  if (disabled) return;
                  toggleOption(option);
                }}
                key={id}
                aria-hidden="true"
              >
                <ChipDropdown
                  id={id}
                  text={text}
                  value={value}
                  color={color}
                  simple={!multiSelect}
                  toggleStatus={toggleStatus}
                  showLegend={showLegend}
                  short
                />
              </div>
            );
          })
        ) : (
          <div className={multiSelectDropdownInputPlaceholderClass}>
            {placeholder}
          </div>
        )}
      </div>
      <div className={multiSelectDropdownOptionsClass}>
        <div>
          {sortedOptions?.map((option) => {
            const { id, text, value, color } = option;
            const multiSelectDropdownChipTestId = getTestId(
              `${multiSelectDropdownName}__option-chip__${id}`,
              testId,
            );

            return (
              <div
                className={multiSelectDropdownOptionsListItemClass}
                data-testid={multiSelectDropdownChipTestId}
                onClick={() => {
                  if (disabled) return;
                  toggleOption(option);
                }}
                key={id}
                aria-hidden="true"
              >
                <ChipDropdown
                  id={id}
                  text={text}
                  value={value}
                  color={color}
                  showLegend={showLegend}
                  simple
                  selected={isSelected(id)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
