import { IFeatureProp } from 'features';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Actions, Index } from 'routes';
import { SubTypeKeys } from 'domains/reports/types';
import ReportsEditContainer from './components/ReportsEditContainer';
import ReportsListContainer from './components/ReportsListContainer';

const Reports: FunctionComponent<IFeatureProp> = ({ path }) => {
  const { checkPermissions } = useUserPermissions();

  return (
    <Routes>
      {checkPermissions('reports::view') && (
        <Route
          path={`${Actions.SEGMENT_EDIT}/:id`}
          element={<ReportsEditContainer path={path} />}
        />
      )}
      {checkPermissions('reports::create') && (
        <Route
          path={`${Actions.SEGMENT_CREATE}/${Index.SEGMENT_PLATFORM_REPORT}`}
          element={<ReportsEditContainer path={path} />}
        />
      )}
      {checkPermissions('platf0rm_reports.audience_insights.module::view') && (
        <Route
          path={`${Actions.SEGMENT_CREATE}/${Index.SEGMENT_AUDIENCE_INSIGHTS_REPORT}`}
          element={
            <ReportsEditContainer
              path={path}
              subType={SubTypeKeys.AUDIENCE_INSIGHTS}
            />
          }
        />
      )}
      {checkPermissions('reports.list::view') && (
        <Route index element={<ReportsListContainer />} />
      )}

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default Reports;
