import bubbleLabelFormatter from 'helpers/charts/bubbleLabelFormatter';
import createChartConfigWithCommonDesign from 'helpers/charts/commonChartDesignConfig';
import pointTooltipFormatter from 'helpers/charts/pointTooltipFormatter';

export const baseBubbleChartConfig = createChartConfigWithCommonDesign({
  chart: {
    type: 'bubble',
    zooming: {
      type: 'x',
    },
    panning: {
      enabled: true,
      type: 'x',
    },
    panKey: 'shift',
    spacing: [10, 20, 0, 0],
    height: 400,
  },

  xAxis: {
    startOnTick: true,
    endOnTick: true,
    showFirstLabel: false,
    showLastLabel: false,
    tickLength: 0,
    gridLineWidth: 1,
    minRange: 1,
  },

  yAxis: {
    startOnTick: true,
    endOnTick: true,
    tickAmount: 10,
  },

  tooltip: {
    shared: false,
    formatter: pointTooltipFormatter,
  },

  plotOptions: {
    bubble: {
      stickyTracking: false,
      dataLabels: {
        enabled: true,
        formatter: bubbleLabelFormatter,
      },
      states: {
        hover: {
          halo: {
            opacity: 1,
            size: 0.01,
          },
        },
      },
      maxSize: '50%',
      zMin: 0,
      color: '#B86EF4',
    },
    series: {
      states: {
        inactive: {
          opacity: 1,
        },
      },
      marker: {
        lineColor: '#FFFFFF',
      },
    },
  },
});

export const baseBarChartConfig = createChartConfigWithCommonDesign({
  chart: {
    type: 'column',
    spacing: [10, 24, 0, 0],
    height: 400,
  },

  xAxis: {
    tickLength: 0,
    gridLineWidth: 0,
    startOnTick: false,
    endOnTick: false,
    type: 'category',
  },

  yAxis: {
    startOnTick: true,
    endOnTick: true,
    tickAmount: 10,
  },

  tooltip: {
    shared: false,
    formatter: pointTooltipFormatter,
  },

  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 1,
        },
      },
      marker: {
        lineColor: '#FFFFFF',
      },
    },
    column: {
      pointPadding: 0,
    },
  },
});
