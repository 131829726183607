import Accordion from 'components/Accordion';
import ActionsBar from 'components/ActionsBar';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Button';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Field from 'components/Field';
import Flex, { Direction, Horizontal, Vertical } from 'components/Flex';
import H3 from 'components/H3';
import Icon, {
  Color as IconColor,
  Size as IconSize,
  Type as IconType,
} from 'components/Icon';
import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import InlineEdit from 'components/InlineEdit';
import Input, { NewInputValue, Type as InputType } from 'components/Input';
import KebabDrawer from 'components/KebabDrawer';
import Label from 'components/Label';
import List from 'components/List';
import Modal, { Type } from 'components/Modal';
import QueryBuilder from 'components/QueryBuilder';
import ReportEventsTable from 'components/ReportEventsTable';
import ReportResultsForm, {
  reportResultsFormComponentName,
} from 'components/ReportResultsForm';
import ReportSettingsForm from 'components/ReportSettingsForm';
import ReportToolbar from 'components/ReportToolbar';
import SkeletonChartBars from 'components/Skeleton/ChartBars';
import StickyHeader from 'components/StickyHeader';
import TargetBuilder from 'components/TargetBuilder';
import { Style as TooltipStyle } from 'components/Tooltip';
import useSelectedClient from 'domains/clients/useSelectedClient';
import { isBaseReportInitiallyExecuted } from 'domains/reports/adapters/rulesets';
import {
  confirmDialogType,
  LOCALE_NO_DATA_RETURNED,
  LOCALE_NOT_ENOUGH_HH,
  LOCALE_PARTIAL_DATA,
} from 'domains/reports/locales/general';
import IReport, {
  cacheMode,
  INotPersistedProps,
  IRunReportProps,
  ReportType,
  reportType,
  reportTypeEquivalences,
  UNSAVED_REPORT_ID,
} from 'domains/reports/types';
import { getClass, getId, getTestId } from 'helpers/components';
import { copyCurrentUrl, removeRunParamFromUrl } from 'helpers/general';
import { trackInlineValidationShown } from 'helpers/mixpanel';
import useCurrentUser from 'hooks/useCurrentUser';
import useItemAdmin from 'hooks/useItemAdmin';

import useReports from 'hooks/useReports';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import useUsers from 'hooks/useUsers';
import { omit, partial } from 'lodash';
import { isEmpty, noop } from 'lodash/fp';
import get from 'lodash/fp/get';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Actions } from 'routes';
import { IMeta } from 'types/apiReturn';
import IError from 'types/error';
import { v4 as uuid } from 'uuid';
import DropdownWithHeaders from 'components/DropdownWithHeaders';
import TargetResult from '../../targets/result';
import ToggleIsPublicButton from '../components/ToggleIsPublic';
import ToggleIsReadOnlyButton from '../components/ToggleIsReadOnly';
import {
  DOM_KEY_ADD_NAME_BODY,
  DOM_KEY_ADD_NAME_HEADER,
  DOM_KEY_AUDIT_BODY,
  DOM_KEY_AUDIT_HEADER,
  DOM_KEY_LABEL,
  DOM_KEY_QUERY,
  DOM_KEY_REPORT_FORM,
  DOM_KEY_RESULTS,
  DOM_KEY_RESULTS_EXPORT_BUTTON,
  DOM_KEY_SETTINGS,
  GET_KEY_RUN,
  ModalType,
  reportsUrl,
  targetsUrl,
} from './constants';
import { editReportComponentName } from './edit.feature';
import {
  LOCALE_AUDIT_BUTTON_TOOLTIP_LABEL,
  LOCALE_AUDIT_REPORT_MODAL_TITLE,
  LOCALE_AUDIT_TARGET_MODAL_TITLE,
  LOCALE_CANCEL,
  LOCALE_COPY_LINK_BUTTON_TOOLTIP_LABEL,
  LOCALE_DATA_READY_TO_EXPORT,
  LOCALE_EXPORT_LABEL,
  LOCALE_EXPORT_LABEL_CSV,
  LOCALE_EXPORT_LABEL_SUMMARY,
  LOCALE_EXPORT_LABEL_XLXS,
  LOCALE_EXPORTING_LABEL,
  LOCALE_FIELD_RESULT,
  LOCALE_FIELD_SETTINGS,
  LOCALE_GENERIC_ERROR,
  LOCALE_INVALID_TARGET,
  LOCALE_IS_PRIVATE,
  LOCALE_IS_PUBLIC,
  LOCALE_LINK_COPIED,
  LOCALE_MISSING_REPORT_NAME_MODAL_TEXT,
  LOCALE_MISSING_REPORT_NAME_MODAL_TITLE,
  LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_MODAL_TITLE,
  LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_NAME_LABEL,
  LOCALE_MISSING_TARGET_NAME_MODAL_TITLE,
  LOCALE_MISSING_TARGET_NAME_SAVE_AS_COPY_MODAL_TITLE,
  LOCALE_NOTIFICATION_HEADER,
  LOCALE_OK,
  LOCALE_REPORT,
  LOCALE_REPORT_DOWNLOAD_SUCCEEDED,
  LOCALE_REPORT_DOWNLOAD_FAILED,
  LOCALE_REPORT_UPLOAD_SUCCEEDED,
  LOCALE_REPORT_UPLOAD_FAILED,
  LOCALE_DESKTOP_DOWNLOAD,
  LOCALE_REPORT_FIELD_QUERY,
  LOCALE_REPORT_VALIDATION_ERROR,
  LOCALE_SAVE_AND_OPEN,
  LOCALE_SAVE_AND_RUN,
  LOCALE_TARGET,
  LOCALE_TARGET_FIELD_QUERY,
  LOCALE_UPDATED,
  LOCALE_S3_UPLOAD,
  LOCALE_EXPORT_LABEL_ISPOT_SEGMENT,
} from './locale';
import { IOwnProps } from './types';
import ISpotSegmentModal from '../components/ISpotSegmentModal';

export const ReportEditFeature: FunctionComponent<IOwnProps> = (
  props: IOwnProps,
) => {
  const {
    cancelQuery,
    chartData,
    confirmationDialog,
    modalDialog,
    events,
    invalidTargetList,
    isAccordionQueryOpen,
    isAccordionResultsOpen,
    isAccordionSettingsOpen,
    isModalOpen,
    isBaseReportRunning,
    isBaseReportSaving,
    isReportInvalid,
    isExportDisabled,
    isNewTarget,
    lastValidReport,
    resetLastValidReport,
    runCachedQuery,
    processRules,
    report,
    showReportPayload,
    runQuery,
    saveAs,
    setAccordionQueryOpen,
    setAccordionResultsOpen,
    setAccordionSettingsOpen,
    setProgress,
    setReport,
    handleUpdateName,
    setReportList,
    setTargetList,
    setType,
    setUserConfirmationAction,
    fetchTargetsAction,
    isBaseReportNew,
    isReportNameDisabled,
    setAcceptDialogModal,
    targetData,
    validTargetList,
    toggleReportModal,
    handleSaveReportModal,
    handleSubmitPlatformForm,
    run: toolbarRun,
    cancelExecutionJobAction,
    path,
    messageList,
    setIsReportInvalid,
    errorMessage,
    setErrorMessage,
    resetReportResult,
    resetDashboardReport,
    handleToggleReadOnly,
    isLoadingReadOnlyToggle,
    handleCloneReport,
    handleDownload,
    isDownloading,
  } = props;

  const navigate = useNavigate();
  const { id } = useParams<{ id: string; norun?: string }>();
  const location = useLocation();
  const getKeyRun = location.search.includes(GET_KEY_RUN);
  const { doSuccessToast } = useToast();
  const { userId } = useCurrentUser();
  const { selectedClient } = useSelectedClient();
  const { users } = useUsers();

  const isTarget = report.type === reportType.target;
  const setRootList = isTarget ? setTargetList : setReportList;

  const { checkPermissions } = useUserPermissions();

  const isReportCreator = userId === report.createdBy;
  const reportIsReadOnly = report.isReadOnly;
  const canEdit = !reportIsReadOnly && checkPermissions('reports::update');
  const canToggleReadOnly =
    isReportCreator || checkPermissions('reports.read_only_toggle::view');

  const reportName: string = isTarget ? LOCALE_TARGET : LOCALE_REPORT;

  const showReadyToExport =
    lastValidReport &&
    isEmpty(chartData?.data) &&
    !chartData?.chartVisible &&
    !chartData?.tableVisible;

  const {
    getSaveAsCopyReportName,
    isLoadingToggleReportPublicPrivate,
    isReportNameToSaveReportAsCopyValid,
    isReportNameValid,
    newReportNamePlaceholder,
    setReports,
    toggleReportPublicPrivate,
  } = useReports(isTarget);

  const { doCreate, doUpdate, loading } = useItemAdmin<IReport>({
    endpoint: reportsUrl,
  });

  const confirmationDialogMessage =
    confirmationDialog?.confirmationDialogMessage;
  const confirmationDialogHeader = confirmationDialog?.confirmationDialogHeader;
  const confirmationDialogOK = confirmationDialog?.confirmationDialogOK;
  const confirmationDialogCANCEL = confirmationDialog?.confirmationDialogCancel;
  const modalDialogMessage = modalDialog?.confirmationDialogMessage;
  const modalDialogHeader = modalDialog?.confirmationDialogHeader;
  const modalDialogOK = modalDialog?.confirmationDialogOK;

  const isReport = useMemo(
    () => report.type === reportType.report,
    [report.type],
  );
  const hasInterval = get('query.interval.id', report) !== 'ALL_VIEWING';
  const defaultRowLimit = hasInterval ? 0 : 10;
  const [name, setName] = useState<string>(() =>
    report?.id && report?.id !== UNSAVED_REPORT_ID && report?.name
      ? report.name
      : '',
  );
  const [nameIsEditing, setNameIsEditing] = useState<boolean>(false);

  const [lastAttemptedRun, setLastAttemptedRun] = useState<number>(Date.now());

  const [missingName, setMissingName] = useState<string>();
  const [saveAsCopyReportName, setSaveAsCopyReportName] = useState<string>(
    getSaveAsCopyReportName(report.name),
  );
  const [modalType, setModalType] = useState(ModalType.audit);

  const reportNameToSaveReportAsCopyIsValid =
    isReportNameToSaveReportAsCopyValid(saveAsCopyReportName);
  const isModalReportNameAndRun = modalType === ModalType.addReportNameAndRun;

  const setReportWithRules = useCallback(
    async (
      newNotPersistentProps?: INotPersistedProps,
      updatedReport?: IReport,
    ) => {
      const newReport = {
        ...(updatedReport ?? report),
        userCanAudit:
          Boolean(newNotPersistentProps?.userCanAudit) || !!events.length,
        rowLimit: defaultRowLimit,
        notPersistedProps: {
          ...report?.notPersistedProps,
          ...newNotPersistentProps,
        },
      };
      setReport(newReport);
      await processRules(newReport?.notPersistedProps);
    },
    [defaultRowLimit, events.length, processRules, report, setReport],
  );

  const removeInvalidTargetsFromReport = useCallback(() => {
    const changedReport = {
      ...report,
      query: { ...report.query, targets: validTargetList || [] },
    };

    // TODO: We should be able to just call handleSubmit and pass the report
    // through as the meta.changed (maybe via a flag) so that it can just fire
    // setReport(meta.changed) in the success function but the modals don't
    // currently have any state that we can use to disable/lock the controls
    // during the async call; so, if we do that now it just looks like the modal
    // froze or didn't do anything until the AAPI responds.
    setReportWithRules({}, changedReport).then(noop);
  }, [report, setReportWithRules, validTargetList]);

  const getReportExecutionProps = useCallback(
    (
      getFromLastExecution: boolean,
      doConfirmQuery = false,
    ): IRunReportProps => {
      const reportExecution = getFromLastExecution
        ? lastValidReport ?? report
        : report;

      return {
        report: {
          ...reportExecution,
          name: report.name,
          use_async: true,
          ...(doConfirmQuery && { doConfirmQuery: true }),
        },
      };
    },
    [lastValidReport, report],
  );

  const handleDownloadQuery = useCallback(
    (format = 'xlsx', isFileUpload = false): void => {
      setProgress(0);
      const shouldDownload = !isFileUpload;
      handleDownload(
        isTarget ? 'targets/download' : 'reports/download',
        {
          runReportProps: getReportExecutionProps(true),
          format: isFileUpload && isTarget ? 'csv' : format,
          isFileUpload,
        },
        shouldDownload
          ? LOCALE_REPORT_DOWNLOAD_SUCCEEDED
          : LOCALE_REPORT_UPLOAD_SUCCEEDED,
        shouldDownload
          ? LOCALE_REPORT_DOWNLOAD_FAILED
          : LOCALE_REPORT_UPLOAD_FAILED,
        shouldDownload,
      );
    },
    [getReportExecutionProps, isTarget, setProgress],
  );

  const handleXLSXUploadQuery = useCallback(
    partial(handleDownloadQuery, 'xlsx', true),
    [handleDownloadQuery],
  );

  const handleCSVUploadQuery = useCallback(
    partial(handleDownloadQuery, 'csv', true),
    [handleDownloadQuery],
  );

  const handleXLSXDownload = useCallback(
    partial(handleDownloadQuery, 'xlsx', false),
    [handleDownloadQuery],
  );

  const handleCSVDownload = useCallback(
    partial(handleDownloadQuery, 'csv', false),
    [handleDownloadQuery],
  );

  const handleSummaryDownload = useCallback(
    partial(handleDownloadQuery, 'xlsx-summary', false),
    [handleDownloadQuery],
  );

  const handleISpotSegmentExport = (): void => {
    setModalType(ModalType.iSpotSegment);
    toggleReportModal(true);
  };

  useEffect(() => {
    resetLastValidReport();
  }, [resetLastValidReport]);

  useEffect(() => {
    setType(report.type ?? 'report');
    if (isTarget || new URLSearchParams(window.location.search).has('norun')) {
      setAccordionQueryOpen(true);
      window.history.pushState(
        {},
        '',
        removeRunParamFromUrl(window.location.href),
      );
      return;
    }
    if (getKeyRun && !isBaseReportInitiallyExecuted(report)) {
      runQuery(cacheMode.default);
      window.history.pushState(
        {},
        '',
        removeRunParamFromUrl(window.location.href),
      );
    } else if (id) {
      runCachedQuery();
    }
  }, [id, getKeyRun]);

  const EXPORT_DROPDOWN_OPTIONS = [
    {
      format: 'xlsx',
      handler: handleXLSXDownload,
      text: LOCALE_EXPORT_LABEL_XLXS,
      header: LOCALE_DESKTOP_DOWNLOAD,
    },
    {
      format: 'csv',
      handler: handleCSVDownload,
      text: LOCALE_EXPORT_LABEL_CSV,
      header: LOCALE_DESKTOP_DOWNLOAD,
    },
    {
      format: 'xlsx-summary',
      handler: handleSummaryDownload,
      text: LOCALE_EXPORT_LABEL_SUMMARY,
      header: LOCALE_DESKTOP_DOWNLOAD,
    },
    {
      format: 's3',
      handler: handleXLSXUploadQuery,
      text: 'XSLX',
      header: LOCALE_S3_UPLOAD,
    },
    {
      format: 's3',
      handler: handleCSVUploadQuery,
      text: LOCALE_EXPORT_LABEL_CSV,
      header: LOCALE_S3_UPLOAD,
    },
    {
      format: 'ispot_segment',
      handler: handleISpotSegmentExport,
      text: LOCALE_EXPORT_LABEL_ISPOT_SEGMENT,
      header: LOCALE_S3_UPLOAD,
    },
  ];

  useEffect(
    () => () => {
      setErrorMessage('');
      resetReportResult();
    },
    [setErrorMessage, resetReportResult],
  );

  const ALLOWED_EXPORT_OPTIONS = EXPORT_DROPDOWN_OPTIONS.filter((option) =>
    checkPermissions(
      `${reportTypeEquivalences(report.type as ReportType)}.${
        option.format
      }_export::view`,
    ),
  );

  const handleCopyLink = (): void => {
    copyCurrentUrl();
    doSuccessToast(`${LOCALE_LINK_COPIED}`);
  };

  const handleAudit = (): void => {
    setModalType(ModalType.audit);
    toggleReportModal(true);
  };

  const handleAuditModalOK = (): void => {
    toggleReportModal(false);
  };

  const goToReports = (reportId?: string, doRun = false): void => {
    const entityUrl = isTarget ? targetsUrl : reportsUrl;
    let url = reportId
      ? `${entityUrl}/${Actions.SEGMENT_EDIT}/${reportId}`
      : entityUrl;
    if (doRun) url += `?${GET_KEY_RUN}`;
    navigate(url);
  };

  const handleUpdateReportSuccess = async (
    reportsUpdated: IReport[],
    meta?: IMeta<IReport>,
  ): Promise<void> => {
    await processRules({
      isBaseReportSaved: true,
      isBaseReportSaving: false,
      isBaseReportSavingAs: false,
    });

    doSuccessToast(`${reportName} ${LOCALE_UPDATED}`);
    setReports(reportsUpdated);
    setRootList(null);

    if (isTarget) {
      fetchTargetsAction();
    }

    if (meta?.changed) {
      setReport(meta.changed);
    }
  };

  const handleError = (error: IError): void => {
    setErrorMessage(error?.message ?? LOCALE_GENERIC_ERROR);
  };

  const handleSubmit = async (
    draftReport: IReport,
    runOnFinish = false,
  ): Promise<void> => {
    if (id) {
      await doUpdate({
        item: {
          ...omit(draftReport, ['name']),
          id: report.id,
          client: selectedClient?.id,
          updatedBy: userId,
        },
        onError: (error: IError) => {
          handleError(error);
          processRules({
            isBaseReportSaved: false,
            isBaseReportSaving: false,
            isBaseReportSavingAs: false,
          });
        },
        onSuccess: (reportsUpdated: IReport[]): void => {
          handleUpdateReportSuccess(reportsUpdated);
          resetDashboardReport(report.id);
        },
      });
    } else {
      await doCreate({
        item: {
          ...draftReport,
          client: selectedClient?.id,
          createdBy: userId ?? '',
        },
        onError: handleError,
        onSuccess: async (reportsUpdated: IReport[], meta?: IMeta<IReport>) => {
          await processRules({
            isBaseReportSaved: true,
            isBaseReportSaving: false,
            isBaseReportSavingAs: false,
          });
          setRootList(null);

          if (isTarget) {
            fetchTargetsAction();
          }

          if (runOnFinish && meta?.id) {
            goToReports(meta.id, true);
          }

          if (!runOnFinish && meta?.id) {
            goToReports(meta.id);
          }
        },
      });
    }
  };

  const saveAsCopyAndOpenReport = async (): Promise<void> => {
    if (!userId) {
      setErrorMessage('Cannot get currentUser');
      return;
    }

    await processRules({
      isBaseReportSavingAs: true,
    });

    handleCloneReport(
      {
        ...report,
        createdBy: userId,
        name: saveAsCopyReportName,
        isReadOnly: false,
      },
      Actions.SEGMENT_COPY,
    );
  };

  const handleSaveAsCopyReport = (): void => {
    setModalType(ModalType.addReportNameSaveAndOpen);
    toggleReportModal(true);
  };

  const doSubmit = async (
    runOnFinish = false,
    reportToSubmit?: IReport,
  ): Promise<void> => {
    await processRules({
      isBaseReportSaved: false,
      isBaseReportSaving: true,
    });

    if (reportToSubmit) {
      await handleSubmit({ ...reportToSubmit }, runOnFinish);
    } else {
      await handleSubmit({ ...report }, runOnFinish);
    }
  };

  const handleRemoveMissingReportName = (): void => {
    setMissingName(undefined);
    toggleReportModal(false);
  };

  const handleChangeAddMissingReportName = (newName: NewInputValue): void => {
    setMissingName(newName as string);
  };

  const handleCancelSaveAsCopyAndOpenReport = (): void => {
    toggleReportModal(false);
    const backupReportName = getSaveAsCopyReportName(report.name);
    if (backupReportName) setSaveAsCopyReportName(backupReportName);
  };

  const handleSaveAsCopyAndOpenReport = async (): Promise<void> => {
    toggleReportModal(false);
    await saveAsCopyAndOpenReport();
    saveAs(report);
  };

  const handleChangeAddSaveAsCopyReportName = (
    newName: NewInputValue,
  ): void => {
    setSaveAsCopyReportName(newName as string);
  };

  const handleNameEditMode = (): void => {
    setNameIsEditing(true);
  };

  const handleNameChangeEdit = async (
    value: string,
    skipSave?: boolean,
  ): Promise<void> => {
    setName(value ?? '');
    if (skipSave) return;
    try {
      const { id: newId, name: newName } =
        (await handleUpdateName(value)) ?? {};

      if (!newId) {
        setNameIsEditing(false);
        return;
      }

      if (newName) {
        const saveAsName = getSaveAsCopyReportName(newName);
        setSaveAsCopyReportName(saveAsName);
      }
      if (id) {
        doSuccessToast(`${reportName} ${LOCALE_UPDATED}`);
        resetDashboardReport(id);
        setNameIsEditing(false);
        return;
      }
      goToReports(`${newId}?norun=1`);
    } catch (error) {
      setName(report?.name ?? '');
      handleError(error as IError);
    }
    setNameIsEditing(false);
  };

  const handleCancelNameChanges = (): void => {
    setName(report?.name ?? '');
    setNameIsEditing(false);
  };

  const handleToggleIsPublic = (): void => {
    toggleReportPublicPrivate({
      id: report.id,
      onError: (error: IError): void =>
        setErrorMessage(error?.message ?? 'There has been an error.'),
      onSuccess: (res?: IReport): void => {
        if (res) {
          setReport({
            ...report,
            isPublic: res.isPublic,
          });
        }

        if ((res as IReport)?.isPublic) {
          doSuccessToast(LOCALE_IS_PUBLIC);
          return;
        }

        doSuccessToast(LOCALE_IS_PRIVATE);
      },
    });
  };

  const editReportId = useMemo(() => getId(editReportComponentName), []);
  const editReportTestId = useMemo(
    () => getTestId(editReportComponentName),
    [],
  );
  const editReportFormTestId = useMemo(
    () => getTestId(editReportComponentName, DOM_KEY_REPORT_FORM),
    [],
  );
  const editReportDefaultClass = useMemo(
    () => getClass(editReportComponentName),
    [],
  );
  const editReportSettingsClass = useMemo(
    () => getClass(editReportComponentName, { concat: [DOM_KEY_SETTINGS] }),
    [],
  );
  const editReportQueryClass = useMemo(
    () => getClass(editReportComponentName, { concat: [DOM_KEY_QUERY] }),
    [],
  );
  const editReportResultsClass = useMemo(
    () => getClass(editReportComponentName, { concat: [DOM_KEY_RESULTS] }),
    [],
  );
  const editReportLabelClass = useMemo(
    () => getClass(editReportComponentName, { concat: [DOM_KEY_LABEL] }),
    [],
  );
  const reportFormResultWrapperClass = getClass(reportResultsFormComponentName);

  const editReportAddNameHeaderClass = useMemo(
    () =>
      getClass(editReportComponentName, {
        concat: [DOM_KEY_ADD_NAME_HEADER],
      }),
    [],
  );

  const editReportAddNameBodyClass = useMemo(
    () =>
      getClass(editReportComponentName, {
        concat: [DOM_KEY_ADD_NAME_BODY],
      }),
    [],
  );

  const editReportAuditHeaderClass = useMemo(
    () =>
      getClass(editReportComponentName, {
        concat: [DOM_KEY_AUDIT_HEADER],
      }),
    [],
  );

  const editReportAuditBodyClass = useMemo(
    () =>
      getClass(editReportComponentName, {
        concat: [DOM_KEY_AUDIT_BODY],
      }),
    [],
  );

  const canShowExportButton = useMemo(
    () =>
      checkPermissions(
        `${reportTypeEquivalences(report.type as ReportType)}::export`,
      ),
    [checkPermissions, report.type],
  );

  const canUserAudit = useMemo(
    () =>
      checkPermissions(
        `${reportTypeEquivalences(report.type as ReportType)}::audit`,
      ),
    [checkPermissions, report.type],
  );

  const editReportExportButtonClass = useMemo(
    () =>
      getClass(editReportComponentName, {
        concat: [DOM_KEY_RESULTS_EXPORT_BUTTON],
      }),
    [],
  );

  const ExportButton = useMemo(
    () => (): ReactElement | null =>
      ALLOWED_EXPORT_OPTIONS.length > 0 &&
      !isExportDisabled &&
      lastValidReport?.id === report.id &&
      canShowExportButton &&
      !isNewTarget ? (
        <div>
          <Flex horizontal={Horizontal.right}>
            {ALLOWED_EXPORT_OPTIONS.length > 0 && (
              <DropdownWithHeaders
                options={ALLOWED_EXPORT_OPTIONS}
                disabled={
                  isExportDisabled || isBaseReportSaving || isDownloading
                }
                label={
                  isDownloading ? LOCALE_EXPORTING_LABEL : LOCALE_EXPORT_LABEL
                }
              />
            )}
          </Flex>
        </div>
      ) : null,
    [
      ALLOWED_EXPORT_OPTIONS,
      canShowExportButton,
      isDownloading,
      editReportExportButtonClass,
      isExportDisabled,
      isNewTarget,
      lastValidReport,
      report.id,
    ],
  );

  const handleConfirmationDialogOnConfirm = useCallback((): void => {
    if (
      confirmationDialog?.confirmationDialogType === confirmDialogType.noResults
    ) {
      cancelExecutionJobAction();
      setUserConfirmationAction(false);
    } else {
      setUserConfirmationAction(true);
      if (
        confirmationDialog?.confirmationDialogType ===
        confirmDialogType.cancelReport
      ) {
        cancelExecutionJobAction();
      }
    }
  }, [
    confirmationDialog?.confirmationDialogType,
    confirmDialogType.cancelReport,
  ]);

  const handleConfirmationDialogOnCancel = useCallback((): void => {
    setUserConfirmationAction(false);
  }, []);

  return (
    <section className={editReportDefaultClass} data-testid={editReportTestId}>
      <ConfirmationDialog
        isOpen={!!confirmationDialogMessage?.length}
        onConfirm={handleConfirmationDialogOnConfirm}
        onCancel={handleConfirmationDialogOnCancel}
        header={confirmationDialogHeader}
        confirmButtonText={confirmationDialogOK}
        cancelButtonText={confirmationDialogCANCEL}
      >
        {Array.isArray(confirmationDialogMessage) ? (
          confirmationDialogMessage.map((message) =>
            Array.isArray(message) ? (
              <List decorator="chevron" items={message} key={uuid()} />
            ) : (
              <p key={uuid()}>{message}</p>
            ),
          )
        ) : (
          <p>{confirmationDialogMessage}</p>
        )}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={!!modalDialogMessage?.length}
        onConfirm={setAcceptDialogModal}
        header={modalDialogHeader}
        confirmButtonText={modalDialogOK}
      >
        {Array.isArray(modalDialogMessage) ? (
          modalDialogMessage.map((message) =>
            Array.isArray(message) ? (
              <List decorator="chevron" items={message} key={uuid()} />
            ) : (
              <p key={uuid()}>{message}</p>
            ),
          )
        ) : (
          <p>{modalDialogMessage}</p>
        )}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={!!id && invalidTargetList && invalidTargetList.length > 0}
        onConfirm={removeInvalidTargetsFromReport}
        header={LOCALE_NOTIFICATION_HEADER}
      >
        <p>{LOCALE_INVALID_TARGET}</p>
      </ConfirmationDialog>
      <Modal
        type={Type.auto}
        isOpen={isModalOpen}
        onCloseClick={() => toggleReportModal(false)}
      >
        {modalType === ModalType.iSpotSegment && (
          <ISpotSegmentModal onCancel={() => toggleReportModal(false)} />
        )}
        {modalType === ModalType.audit ? (
          <div>
            <header className={editReportAuditHeaderClass}>
              <H3>
                {isReport
                  ? LOCALE_AUDIT_REPORT_MODAL_TITLE
                  : LOCALE_AUDIT_TARGET_MODAL_TITLE}
              </H3>
            </header>
            <section className={editReportAuditBodyClass}>
              <ReportEventsTable events={events} users={users} />
            </section>
            <ActionsBar cancel={false} okLabel="Ok" onOK={handleAuditModalOK} />
          </div>
        ) : (
          []
        )}
        {[ModalType.addReportName, ModalType.addReportNameAndRun].includes(
          modalType,
        ) ? (
          <div>
            <header className={editReportAddNameHeaderClass}>
              <H3>
                {isReport
                  ? LOCALE_MISSING_REPORT_NAME_MODAL_TITLE
                  : LOCALE_MISSING_TARGET_NAME_MODAL_TITLE}
              </H3>
            </header>
            <section className={editReportAddNameBodyClass}>
              <Flex
                direction={Direction.row}
                className="flex-row justify-content-start"
              >
                <span className={editReportLabelClass}>
                  <Label text={LOCALE_MISSING_REPORT_NAME_MODAL_TEXT} />
                </span>
                <div className="col">
                  <Input
                    confirmOnEnter
                    type={InputType.text}
                    onEnterKeyDown={() =>
                      handleSaveReportModal(
                        isModalReportNameAndRun,
                        missingName,
                        isTarget,
                        doSubmit,
                      )
                    }
                    id={`${editReportId}-report-name-input`}
                    name={`${editReportId}-report-name-input`}
                    onChange={handleChangeAddMissingReportName}
                    placeholder={newReportNamePlaceholder}
                  />
                </div>
              </Flex>
            </section>
            <ActionsBar
              onCancel={handleRemoveMissingReportName}
              okLabel={
                isModalReportNameAndRun ? LOCALE_SAVE_AND_RUN : LOCALE_OK
              }
              onOK={() =>
                handleSaveReportModal(
                  isModalReportNameAndRun,
                  missingName,
                  isTarget,
                  doSubmit,
                )
              }
              disabledOK={
                !missingName ||
                missingName === newReportNamePlaceholder ||
                missingName === ''
              }
            />
          </div>
        ) : (
          []
        )}
        {modalType === ModalType.addReportNameSaveAndOpen ? (
          <div>
            <header className={editReportAddNameHeaderClass}>
              <H3>
                {isReport
                  ? LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_MODAL_TITLE
                  : LOCALE_MISSING_TARGET_NAME_SAVE_AS_COPY_MODAL_TITLE}
              </H3>
            </header>
            <section className={editReportAddNameBodyClass}>
              <Flex
                direction={Direction.row}
                className="flex-row justify-content-start"
              >
                <span className={editReportLabelClass}>
                  <Label
                    text={LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_NAME_LABEL}
                  />
                </span>
                <Field
                  className="col"
                  type={InputType.text}
                  onKeyPressEnter={handleSaveAsCopyAndOpenReport}
                  id={`${editReportId}-missing-report-name-input`}
                  name={`${editReportId}-missing-report-name-input`}
                  onChange={handleChangeAddSaveAsCopyReportName}
                  value={saveAsCopyReportName}
                  placeholder={newReportNamePlaceholder}
                />
              </Flex>
            </section>
            <ActionsBar
              onCancel={handleCancelSaveAsCopyAndOpenReport}
              cancelLabel={LOCALE_CANCEL}
              okLabel={LOCALE_SAVE_AND_OPEN}
              onOK={handleSaveAsCopyAndOpenReport}
              disabledOK={!reportNameToSaveReportAsCopyIsValid}
            />
          </div>
        ) : (
          []
        )}
      </Modal>

      <StickyHeader>
        <section>
          {isReportInvalid && (
            <InlineAlert
              message={LOCALE_REPORT_VALIDATION_ERROR}
              mode={AlertTypes.validate}
              lastAttemptedRun={lastAttemptedRun}
            />
          )}
          {errorMessage && (
            <InlineAlert message={errorMessage} mode={AlertTypes.validate} />
          )}
        </section>
        <Flex horizontal={Horizontal.between}>
          <header className={`${editReportDefaultClass}-header`}>
            <InlineEdit
              disabled={
                isReportNameDisabled || isBaseReportRunning || reportIsReadOnly
              }
              value={name}
              onEdit={handleNameChangeEdit}
              onCancel={handleCancelNameChanges}
              onEditMode={handleNameEditMode}
              placeholder={newReportNamePlaceholder}
            />
            {!nameIsEditing && (
              <KebabDrawer>
                {checkPermissions(
                  `${reportTypeEquivalences(
                    report.type,
                  )}.settings.visibility::view`,
                ) &&
                  (!isLoadingToggleReportPublicPrivate ? (
                    <ToggleIsPublicButton
                      disabled={isBaseReportNew}
                      isPublic={report.isPublic}
                      onClick={handleToggleIsPublic}
                    />
                  ) : (
                    <Icon
                      type={IconType.loading}
                      size={IconSize.small}
                      color={IconColor.primary}
                    />
                  ))}
                {report.isPublic && (
                  <Button
                    kind={ButtonKind.icon}
                    iconType={IconType.link}
                    iconColor={IconColor.secondary}
                    iconSize={IconSize.small}
                    size={ButtonSize.small}
                    onClick={handleCopyLink}
                    tooltip={LOCALE_COPY_LINK_BUTTON_TOOLTIP_LABEL}
                    tooltipStyle={TooltipStyle.tertiary}
                    tooltipShowArrow={false}
                    disabled={isBaseReportNew}
                  />
                )}
                {canToggleReadOnly &&
                  (!isLoadingReadOnlyToggle ? (
                    <ToggleIsReadOnlyButton
                      disabled={isBaseReportNew}
                      isReadOnly={reportIsReadOnly}
                      onClick={handleToggleReadOnly}
                    />
                  ) : (
                    <Icon
                      type={IconType.loading}
                      size={IconSize.small}
                      color={IconColor.primary}
                    />
                  ))}
                {canUserAudit && (
                  <Button
                    kind={ButtonKind.icon}
                    iconType={IconType.audit}
                    iconColor={IconColor.secondary}
                    iconSize={IconSize.small}
                    size={ButtonSize.small}
                    onClick={handleAudit}
                    tooltip={LOCALE_AUDIT_BUTTON_TOOLTIP_LABEL}
                    tooltipStyle={TooltipStyle.tertiary}
                    tooltipShowArrow={false}
                    disabled={isBaseReportNew}
                  />
                )}
              </KebabDrawer>
            )}
          </header>

          <ReportToolbar
            onCancelRun={(): void => cancelQuery()}
            onRefresh={(): void => {
              setLastAttemptedRun(Date.now());
              runQuery(cacheMode.force);
            }}
            onRun={(event): void => {
              setErrorMessage('');
              const reportCacheMode = event?.altKey
                ? cacheMode.force
                : cacheMode.default;
              const reportNameIsValid = isReportNameValid(report.name);
              if (isReport) {
                if (!reportNameIsValid) {
                  if (messageList.length > 0) {
                    setLastAttemptedRun(Date.now());
                    trackInlineValidationShown(
                      report,
                      'message-list',
                      messageList,
                    );
                    setIsReportInvalid(true);
                  } else if (messageList.length === 0) {
                    if (isReportInvalid) {
                      setIsReportInvalid(false);
                    }
                    setModalType(ModalType.addReportNameAndRun);
                    toggleReportModal(true);
                  }
                } else {
                  setLastAttemptedRun(Date.now());
                  runQuery(reportCacheMode);
                  toolbarRun(report);
                }
              } else {
                setLastAttemptedRun(Date.now());
                runQuery(reportCacheMode);
                toolbarRun(report);
              }
            }}
            onSave={() =>
              handleSubmitPlatformForm(
                name,
                isReportNameValid,
                setModalType,
                doSubmit,
              )
            }
            onSaveAsCopy={handleSaveAsCopyReport}
            onShowReportPayload={showReportPayload}
          />
        </Flex>
      </StickyHeader>
      <section
        className={editReportDefaultClass}
        data-testid={editReportTestId}
      >
        <form data-testid={editReportFormTestId}>
          {checkPermissions([
            `${reportTypeEquivalences(report.type)}.settings::view`,
            'AND',
            `(${reportTypeEquivalences(report.type)}.settings.query_mode::view`,
            `OR ${reportTypeEquivalences(
              report.type,
            )}.settings.time_frame::view`,
            `OR ${reportTypeEquivalences(
              report.type,
            )}.settings.weight_mode::view`,
            `OR ${reportTypeEquivalences(
              report.type,
            )}.settings.granularity::view`,
            `OR ${reportTypeEquivalences(
              report.type,
            )}.settings.time_zone::view`,
            `OR ${reportTypeEquivalences(
              report.type,
            )}.settings.sample_factor::view)`,
          ]) && (
            <section className={editReportSettingsClass}>
              <Accordion
                title={LOCALE_FIELD_SETTINGS}
                open={isAccordionSettingsOpen}
                onChange={(state: boolean): void =>
                  setAccordionSettingsOpen(state)
                }
              >
                <ReportSettingsForm />
              </Accordion>
            </section>
          )}
          <section className={editReportQueryClass}>
            <Accordion
              title={
                isReport ? LOCALE_REPORT_FIELD_QUERY : LOCALE_TARGET_FIELD_QUERY
              }
              open={isAccordionQueryOpen}
              onChange={(state: boolean): void => setAccordionQueryOpen(state)}
            >
              {isTarget ? (
                <TargetBuilder disabled={!canEdit} />
              ) : (
                <QueryBuilder disabled={!canEdit} />
              )}
            </Accordion>
          </section>
          <section className={editReportResultsClass}>
            <Accordion
              title={LOCALE_FIELD_RESULT}
              open={isAccordionResultsOpen}
              onChange={(state: boolean): void =>
                setAccordionResultsOpen(state)
              }
              headerToolbar={<ExportButton />}
            >
              <div className={reportFormResultWrapperClass}>
                {isReport && showReadyToExport && (
                  <div>
                    <Flex
                      vertical={Vertical.top}
                      horizontal={Horizontal.center}
                    >
                      {LOCALE_DATA_READY_TO_EXPORT}
                    </Flex>
                  </div>
                )}
                {isReport &&
                  (!isEmpty(chartData?.data) ? (
                    <>
                      {(chartData?.rowsDropped as number) > 0 && (
                        <div
                          className={`${editReportResultsClass}-partial-data`}
                        >
                          <InlineAlert
                            message={LOCALE_PARTIAL_DATA}
                            mode={AlertTypes.notify}
                          />
                        </div>
                      )}
                      <ReportResultsForm
                        isLoading={loading || isBaseReportRunning}
                        query={report?.query}
                      />
                    </>
                  ) : (
                    <>
                      {isEmpty(chartData?.data) &&
                        (chartData?.rowsDropped as number) > 0 && (
                          <div
                            className={`${editReportResultsClass}-not-enough-hh`}
                          >
                            <InlineAlert
                              message={LOCALE_NOT_ENOUGH_HH}
                              mode={AlertTypes.warn}
                              hideClose
                            />
                          </div>
                        )}
                      {isEmpty(chartData?.data) &&
                        !loading &&
                        !isBaseReportRunning &&
                        chartData?.rowsDropped === 0 && (
                          <div
                            className={`${editReportResultsClass}-not-enough-hh`}
                          >
                            <InlineAlert
                              message={LOCALE_NO_DATA_RETURNED}
                              mode={AlertTypes.warn}
                              hideClose
                            />
                          </div>
                        )}
                      {isBaseReportRunning && <SkeletonChartBars />}
                    </>
                  ))}
                {isTarget &&
                  (!isEmpty(targetData) ? (
                    <TargetResult
                      isLoading={loading || isBaseReportRunning}
                      path={path}
                      lastValidReport={lastValidReport}
                    />
                  ) : (
                    isBaseReportRunning && <SkeletonChartBars />
                  ))}
              </div>
            </Accordion>
          </section>
        </form>
      </section>
    </section>
  );
};
