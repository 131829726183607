import IMessage from 'domains/messageList/types';
import IReport, {
  IQueryResult,
  IReportJobCompleteResponse,
} from 'domains/reports/types';
import mixpanel from 'mixpanel-browser';
import store from 'store';
import ActionTypes from 'store/actions/types';
import { IExecuteTargetResult } from 'store/reducers/targetResult';
import * as reportSelectors from 'store/selectors/report';
import { FilterValue, RuleFilter } from 'types/filter';
import ILoggedUserState from 'types/userState';
import {
  getDatasetNameList,
  pickAllFromReport,
  pickAllFromResult,
  pickIdentityFromReport,
} from './utils';

// Report
export const trackResultRetrieved = (
  report: IReport,
  result: IQueryResult | IReportJobCompleteResponse | IExecuteTargetResult,
): void =>
  mixpanel.track(ActionTypes.RESULT_RETRIEVED, {
    ...pickAllFromReport(report),
    ...pickAllFromResult(result),
  });

// Report Click Events
export const trackRuleAdded = (rules: RuleFilter[]): void => {
  const state = store.getState();
  const report = reportSelectors.getReport(state);
  return mixpanel.track('RULE_ADDED', {
    rules,
    ...pickIdentityFromReport(report),
  });
};

export const trackReportInputChange = (
  field: string | undefined,
  option: string | number | boolean | FilterValue,
  text?: string,
  action?: string,
  ruleContext?: {
    filter: RuleFilter;
    index: number;
    parentRuleTracking?: RuleFilter;
  },
): void => {
  if (!option && option !== false) return;
  const eventName = `${field}_CHANGED`.toUpperCase();
  const optionValue =
    field === 'CUSTOM_EXPOSURES' || field === 'custom_ads'
      ? getDatasetNameList([option as string])[0]
      : option;
  const state = store.getState();
  const report = reportSelectors.getReport(state);
  return mixpanel.track(eventName, {
    field,
    option: optionValue,
    text,
    action,
    rule: ruleContext?.filter.field,
    ruleIndex: ruleContext?.index,
    ruleParentId: ruleContext?.parentRuleTracking?.id,
    ...pickIdentityFromReport(report),
  });
};

export const trackDateRangeChange = (
  field: string,
  startDate: string,
  endDate: string,
  relativeDateOffset?: string,
  smartSelection?: string,
): void => {
  return mixpanel.track('DATE_RANGE_CHANGED', {
    field,
    startDate,
    endDate,
    relativeDateOffset,
    smartSelection,
  });
};

export const trackRuleDeleted = (
  index: number,
  rule: RuleFilter,
  report?: IReport | undefined,
  ruleId?: string,
  parentRule?: string,
): void => {
  return mixpanel.track('RULE_DELETED', {
    index,
    rule,
    ruleId,
    parentRule,
    ...pickIdentityFromReport(report),
  });
};

// Inline Validation

export const trackInlineValidationShown = (
  report: IReport | undefined,
  reason: string,
  messageList?: IMessage[],
): void => {
  return mixpanel.track('REPORT_RAN_INVALID', {
    reason,
    messageList,
    ...pickIdentityFromReport(report),
  });
};

// Result
export const trackExport = (report: IReport, isFileUpload: boolean): void =>
  mixpanel.track(ActionTypes.RESULT_EXPORT, {
    ...pickAllFromReport(report),
    exportMethod: isFileUpload ? 'Send to S3' : 'Browser Download',
  });

// Root
export const trackCloneReport = (report: IReport): void =>
  mixpanel.track(ActionTypes.ROOT_CLONE_REPORT, {
    ...pickAllFromReport(report),
  });

export const trackCreateReport = (type: string): void =>
  mixpanel.track(ActionTypes.ROOT_CREATE_REPORT, { type });

export const trackDeleteReport = (report: IReport): void =>
  mixpanel.track(ActionTypes.ROOT_DELETE_REPORT, {
    ...pickAllFromReport(report),
  });

export const trackEditReport = (report: IReport): void =>
  mixpanel.track(ActionTypes.ROOT_EDIT_REPORT, {
    ...pickAllFromReport(report),
  });

export const trackDownloadPresentation = (report?: IReport): void =>
  mixpanel.track(ActionTypes.REPORT_SET_PRESENTATION_DOWNLOADED, {
    ...pickAllFromReport(
      report ? report : reportSelectors.getReport(store.getState()),
    ),
  });

// Toolbar
export const trackToolbarRefresh = (report: IReport): void =>
  mixpanel.track(ActionTypes.TOOLBAR_REFRESH, { ...pickAllFromReport(report) });

export const trackToolbarRun = (report: IReport): void =>
  mixpanel.track(ActionTypes.TOOLBAR_RUN, { ...pickAllFromReport(report) });

export const trackToolbarCancel = (report: IReport): void =>
  mixpanel.track(ActionTypes.TOOLBAR_CANCEL, { ...pickAllFromReport(report) });

export const trackToolbarSave = (report: IReport): void =>
  mixpanel.track(ActionTypes.TOOLBAR_SAVE, { ...pickAllFromReport(report) });

export const trackToolbarSaveAs = (report: IReport): void =>
  mixpanel.track(ActionTypes.TOOLBAR_SAVE_AS_COPY, {
    ...pickAllFromReport(report),
  });

// User
export const trackUserLogin = (user: ILoggedUserState): void => {
  const { email, name, id } = user;
  mixpanel.identify(id);
  mixpanel.people.set({
    $email: email,
    name,
  });
  // mixpanel.track(ActionTypes.USER_LOGIN_SUCCESS);
};

export const trackUserLogout = (): void =>
  mixpanel.track(ActionTypes.USER_LOGOUT);

export const trackUserSelectedClient = (name?: string): void =>
  mixpanel.track(ActionTypes.USER_SET_SELECTED_CLIENT, { name });

// Storytelling visualization

// Note: As those trackers by default are meant to be used in visualization
// there is default value visualization = true

export const trackFiltersChange = ({
  lastValidReport,
  actionType,
  sectionName,
  selection,
  visualization = true,
}: {
  lastValidReport: IReport | null;
  actionType: string;
  sectionName: string;
  selection: string | number | string[] | number[] | null;
  visualization?: boolean;
}): void => {
  mixpanel.track(actionType, {
    sectionName,
    selection,
    ...pickIdentityFromReport(lastValidReport),
    visualization,
  });
};

export const trackResetGraphClick = ({
  lastValidReport,
  sectionName,
  visualization = true,
}: {
  lastValidReport: IReport | null;
  sectionName: string;
  visualization?: boolean;
}): void =>
  mixpanel.track('RESET_GRAPH_CLICK', {
    sectionName,
    ...pickIdentityFromReport(lastValidReport),
    visualization,
  });

export const trackGraphRowRemoval = ({
  lastValidReport,
  sectionName,
  selection,
  visualization = true,
}: {
  lastValidReport: IReport | null;
  sectionName: string;
  selection: {
    breakout: string | number | null;
    target: string | number | null;
    name: string | number | null;
  };
  visualization?: boolean;
}): void =>
  mixpanel.track('REMOVE_GRAPH_ROW', {
    sectionName,
    selection,
    ...pickIdentityFromReport(lastValidReport),
    visualization,
  });

export const trackAnchorClick = ({
  lastValidReport,
  sectionName,
  destination,
  visualization = true,
}: {
  lastValidReport: IReport | null;
  sectionName: string;
  destination: string;
  visualization?: boolean;
}): void =>
  mixpanel.track('ANCHOR_CLICK', {
    sectionName,
    destination,
    ...pickIdentityFromReport(lastValidReport),
    visualization,
  });
