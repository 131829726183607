import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Button';
import {
  Color as IconColor,
  Type as IconType,
  Size as IconSize,
} from 'components/Icon';
import { Style as TooltipStyle } from 'components/Tooltip';
import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement, useMemo } from 'react';

export const componentName = 'toggle-is-read-only';

export const LOCALE_CLICK_TO_MAKE_EDITABLE =
  'Read only report, click to make editable';
export const LOCALE_CLICK_TO_MAKE_READ_ONLY =
  'Editable report, click to make read only';

export const MODIFIER_CLASS_IS_READ_ONLY = 'read-only';

export type Props = {
  disabled?: boolean;
  isReadOnly?: boolean;
  testId?: string;
  onClick?: (isPublic: boolean) => void;
};

const ToggleIsReadOnly: FunctionComponent<Props> = (props): ReactElement => {
  const { disabled, isReadOnly = false, testId, onClick } = props;

  const handleClickPublic = (): void => {
    if (onClick) onClick(false);
  };

  const handleClickPrivate = (): void => {
    if (onClick) onClick(true);
  };

  const rootTestId = useMemo(() => getTestId(componentName, testId), [testId]);
  const rootClass = useMemo(
    () =>
      getClass(componentName, {
        boolean: [{ state: isReadOnly, class: MODIFIER_CLASS_IS_READ_ONLY }],
      }),
    [isReadOnly],
  );

  return (
    <div className={rootClass} data-testid={rootTestId}>
      {isReadOnly ? (
        <Button
          kind={ButtonKind.icon}
          iconType={IconType.editInactive}
          iconColor={IconColor.secondary}
          iconSize={IconSize.small}
          size={ButtonSize.small}
          tooltip={LOCALE_CLICK_TO_MAKE_EDITABLE}
          tooltipStyle={TooltipStyle.tertiary}
          tooltipShowArrow={false}
          disabled={disabled}
          onClick={handleClickPublic}
        />
      ) : (
        <Button
          kind={ButtonKind.icon}
          iconType={IconType.edit}
          iconColor={IconColor.secondary}
          iconSize={IconSize.small}
          size={ButtonSize.small}
          tooltip={LOCALE_CLICK_TO_MAKE_READ_ONLY}
          tooltipStyle={TooltipStyle.tertiary}
          tooltipShowArrow={false}
          disabled={disabled}
          onClick={handleClickPrivate}
        />
      )}
    </div>
  );
};

export default ToggleIsReadOnly;
