import { get, flow, reject } from 'lodash/fp';

export const getBusinessData = get('businessData');
export const getIntervals = get('businessData.report.query.interval');
export const getAvailableIntervals = get('businessData.availableIntervals');
export const getAvailableMetrics = get('businessData.availableMetrics');
export const getAvailableDimensions = get('businessData.availableDimensions');
export const getAvailableRulesDimensions = get(
  'businessData.availableRulesDimensions',
);
export const getAvailableOrderFields = get('businessData.availableOrderFields');
export const getAvailableTargets = get('businessData.availableTargets');
export const getAttributionReportAvailableTargets = flow(
  getAvailableTargets,
  reject({ id: 'genpop' }),
);
