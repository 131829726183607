import { hash } from 'helpers/hash';
import { get, isEmpty, merge, set } from 'lodash';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';
import { IFormUpdatedPayload, IUploadForm, IUploadsState } from 'types/uploads';
import { v4 as uuid } from 'uuid';
import { DatasetTypes } from '../../../domains/datasets/types';

export const initialState = (): IUploadsState => {
  const hashedDatasetId = hash(uuid()).toString();
  return {
    formChanged: false,
    formConfigFieldsChanged: false,
    form: {
      status: '',
      fileType: 'SEGMENT',
      version: 0,
      display_name: '',
      dataset_id: hashedDatasetId,
      vendor_name: '',
      s3Source: false,
      s3Path: '',
      name: hashedDatasetId,
      clientsWithAccess: [],
      dataset_metadata: {
        attributes: [],
        vendor_name: '',
        s3Source: false,
        max_event_date: '',
        min_event_date: '',
        s3Path: '',
        name: '',
        fallback: {
          dataset: '',
          category: [],
          chain: [],
        },
      },
    },
    userDimensionDetails: {
      path: '',
      dataset_id: hashedDatasetId,
      custom_values: [],
      source_dimension: '',
      user_dimension_name: '',
      type: DatasetTypes.userDimension,
      fallback_value: '',
      clients: [],
      description: '',
    },
    crosswalkCombinationDetails: {
      type: DatasetTypes.crosswalkCombination,
      name: '',
      dataset_id: hashedDatasetId,
      client: {
        dataset_rights: [],
      },
      crosswalk_combinations: [],
      universe_label: [
        { text: 'All US TV Households', value: 'All US TV Households' },
      ],
      description: '',
    },
    dataProviderOptions: [
      {
        text: '605',
        value: '605',
      },
      {
        text: 'Charter',
        value: '0',
      },
    ],
    fileTypeOptions: [
      { text: 'Segment', value: 'SEGMENT' },
      { text: 'Schedule', value: 'SCHEDULE' },
      { text: 'Vendor Source', value: 'VENDOR_SOURCE' },
      { text: 'Generic Events', value: 'GENERIC_EVENTS' },
      { text: 'Crosswalk', value: 'CROSSWALK' },
      { text: 'Cat3 Segment', value: 'CAT3_SEGMENT' },
      { text: 'Custom Daypart Timetable', value: 'DAYPART_TIMETABLE' },
    ],
    errorMessage: '',
    validationErrors: [],
    isPolling: false,
    isLoadingUpdate: false,
    datasetLoading: false,
    crosswalkSources: [],
  };
};

const fieldsNeedToConfig = [
  'dataset_metadata.fallback',
  'dataset_metadata.attributes',
  'crosswalk_combination',
  'dataset_metadata.dataset_path',
];

const reducer = (
  state: IUploadsState = initialState(),
  action: Action<IFormUpdatedPayload>,
): IUploadsState => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.UPLOADS_SET_ERROR_MESSAGE:
      return { ...state, errorMessage: payload };
    case ActionType.UPLOADS_RESET_ERROR_MESSAGE:
      return { ...state, errorMessage: '' };
    case ActionType.UPLOADS_SET_VALIDATION_ERRORS:
      return { ...state, validationErrors: payload };
    case ActionType.UPLOADS_START_POLLING:
      return { ...state, isPolling: true };
    case ActionType.UPLOADS_END_POLLING:
      return { ...state, isPolling: false };
    case ActionType.UPLOADS_UPDATE_STARTED:
      return { ...state, isLoadingUpdate: true };
    case ActionType.UPLOADS_UPDATE_ENDED:
      return { ...state, isLoadingUpdate: false };
    case ActionType.UPLOADS_FORM_UPDATED:
      const currentForm = get(state, 'form', {}) as IUploadForm;
      const { key, value } = payload;
      const { formConfigFieldsChanged } = state;

      const needConfig = formConfigFieldsChanged
        ? formConfigFieldsChanged
        : fieldsNeedToConfig.some((fieldKey) => key.includes(fieldKey));

      // Reset entire fallback object on For Use in Imp4ct === no
      if (key === 'dataset_metadata.fallback.dataset' && isEmpty(value)) {
        return {
          ...state,
          form: {
            ...currentForm,
            dataset_metadata: {
              ...currentForm.dataset_metadata,
              fallback: null,
            },
          },
          formChanged: true,
          formConfigFieldsChanged: needConfig,
        };
      }

      if (key === 's3Source') {
        return {
          ...state,
          errorMessage: '',
          validationErrors: [],
          form: {
            ...currentForm,
            s3Path: '',
            s3Source: value,
          },
          formChanged: true,
          formConfigFieldsChanged: needConfig,
        };
      }
      return {
        ...state,
        form: set(currentForm, key, value),
        formChanged: true,
        formConfigFieldsChanged: needConfig,
      };
    case ActionType.UPLOADS_FORM_RESET:
      return initialState();
    case ActionType.UPLOADS_FORM_SET:
      return { ...state, form: merge(get(state, 'form', {}), payload) };
    case ActionType.UPLOADS_LOAD_STARTED:
      return { ...state, datasetLoading: true };
    case ActionType.UPLOADS_LOAD_ENDED:
      return { ...state, datasetLoading: false };
    case ActionType.UPLOADS_SET_CROSSWALK_SOURCES:
      return { ...state, crosswalkSources: payload };
    case ActionType.UPLOADS_SET_USER_DIMENSION_DETAILS:
      return { ...state, userDimensionDetails: payload };
    case ActionType.UPLOADS_SET_CROSSWALK_COMBINATION_DETAILS:
      return { ...state, crosswalkCombinationDetails: payload };
    default:
      return state;
  }
};

export default reducer;
