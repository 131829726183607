import { getTestId, getId, getClass } from 'helpers/components';
import React, { FunctionComponent } from 'react';

export const singleStatComponentName = 'single-stat';

export const VALUE_DOM_KEY = 'value';

export interface IProps {
  testId?: string;
  id?: string;
  value: string;
  color?: string;
}

const SingleStat: FunctionComponent<IProps> = (props) => {
  const { id, testId, value, color } = props;

  const singleStatClass = getClass(singleStatComponentName);
  const singleStatId = getId(singleStatComponentName, id);
  const singleStatTestId = getTestId(singleStatComponentName, testId);
  const singleStatValueTestId = `${singleStatTestId}-${VALUE_DOM_KEY}`;
  const valueStyle = { color };

  return (
    <article
      className={singleStatClass}
      data-testid={`${singleStatTestId}`}
      id={singleStatId}
    >
      <div data-testid={singleStatValueTestId} style={valueStyle}>
        {value}
      </div>
    </article>
  );
};

export default SingleStat;
