import { ResultBaseSubStructure } from '../../../domains/reports/types';

export const getCategories = (
  targetData: ResultBaseSubStructure[],
): string[] => {
  return (
    targetData?.map((item) => {
      const suffix = item?.name?.VALUE === '1' ? 'DAY' : 'DAYS';

      return `${item?.name?.DISPLAY_VALUE.toString()} ${suffix}`;
    }) ?? []
  );
};
