import Flex, { Horizontal } from 'components/Flex';
import H2 from 'components/H2';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import { SectionOmissionAlert } from 'components/SectionOmissionAlert';
import IReport, {
  ExposureConversionSetNames,
  ResultBase,
  PerformanceGroupNames,
  PerformanceGroupKeys,
} from 'domains/reports/types';
import { IResultSelectionFilters } from 'domains/resultSelections/types';
import { getClass } from 'helpers/components';
import { trackFiltersChange } from 'helpers/mixpanel';
import { getPersistedTargets } from 'helpers/resultSelection/getPersistedTargets';
import React from 'react';
import { connect } from 'react-redux';
import * as resultSelectionsActions from 'store/actions/resultSelections';
import { IOption } from 'types/textValue';
import Dropdown from 'components/Dropdown';
import State from 'types/state';
import {
  getConversionType,
  hasAdCostRelateResults,
} from 'store/selectors/reportResult';
import { performanceMetricsToTextValue } from 'helpers/types';
import { getPerformanceMetricGroupsFromLastValidReport } from 'store/selectors/report';
import { DeliveryAndOutcomes } from './components/DeliveryAndOutcomes';
import ReportOverview from './components/Report';
import { TopPersuadableAudiences } from './components/TopPersuadableAudiences';
import { TotalCampaign } from './components/TotalCampaign';
import { getReportOverviewConfig } from './components/Report/transformations';

export interface IExecutiveSummaryProps {
  targets?: string[];
  targetsObject: IOption[];
  breakoutChartData: ResultBase;
  miscResultData: ResultBase;
  omissionMessages: ExposureConversionSetNames[];
  sectionFiltersSelection: IResultSelectionFilters['executiveSummary'];
  lastValidReport: IReport | null;
  showLroiInVisualization?: boolean;
  useEquivalizedMetrics?: boolean;
  updateResultSelectionFilters: (
    payload: Partial<IResultSelectionFilters>,
  ) => Promise<void>;
  performanceMetricGroups: string[];
  conversionType: string;
  costData: {
    AD_COST: boolean;
    PERSUASION_INDEX_BY_COST: boolean;
    COST_PER_INCREMENTAL_LIFT: boolean;
  };
}

export const executiveSummaryComponentName = 'executive-summary';
const executiveSummaryClass = getClass(executiveSummaryComponentName);
const toggleWrapperClass = getClass(executiveSummaryComponentName, {
  concat: ['toggle-wrap'],
});

const rowsUsedInSection: ExposureConversionSetNames[] = [
  'exposed_all.total',
  'converted_total',
];

const sectionName = 'Executive Summary';

const ExecutiveSummary: React.FC<IExecutiveSummaryProps> = ({
  targets,
  targetsObject,
  breakoutChartData,
  miscResultData,
  omissionMessages,
  sectionFiltersSelection,
  lastValidReport,
  updateResultSelectionFilters,
  performanceMetricGroups,
  conversionType,
  showLroiInVisualization,
  useEquivalizedMetrics = false,
  costData,
}) => {
  const [selectedTarget, setSelectedTarget] = React.useState(() =>
    getPersistedTargets(targetsObject, [
      sectionFiltersSelection?.target ?? 'target',
    ]),
  );
  const [selectedPerformanceMetric, setSelectedPerformanceMetric] =
    React.useState(
      () => sectionFiltersSelection?.performanceMetric ?? 'converters',
    );

  const notSelectedTarget = targetsObject?.find(
    (target: IOption) => target.value !== selectedTarget[0]?.value,
  );

  const reportOverviewConfig = getReportOverviewConfig(useEquivalizedMetrics);

  const performanceMetricOptions = performanceMetricsToTextValue(
    performanceMetricGroups,
    conversionType,
  );

  const conversionsMetricName =
    performanceMetricOptions.find(
      (item) => item.value === PerformanceGroupKeys.conversions,
    )?.text ?? '';

  const onTargetChange = (selectedOptions: IOption[]): void => {
    trackFiltersChange({
      lastValidReport,
      actionType: 'CHANGE_TARGET',
      sectionName,
      selection: selectedOptions.map((o) => o.text),
    });

    setSelectedTarget(selectedOptions);

    updateResultSelectionFilters({
      executiveSummary: {
        target: selectedOptions[0].value as string,
        performanceMetric: selectedPerformanceMetric,
      },
    });
  };

  const onPerformanceMetricChange = (value: string): void => {
    setSelectedPerformanceMetric(value as PerformanceGroupNames);
    updateResultSelectionFilters({
      executiveSummary: {
        target: selectedTarget[0].value as string,
        performanceMetric: value,
      },
    });
  };
  const hasPersuasionCostData = costData.PERSUASION_INDEX_BY_COST;

  return (
    <section className="mb-4 mt-2">
      <Flex className="mb-4" horizontal={Horizontal.left}>
        <div>
          <H2>{sectionName}</H2>
        </div>
        <SectionOmissionAlert
          omissionMessages={omissionMessages}
          rowsUsedInSection={rowsUsedInSection}
        />
      </Flex>
      <div className={`px-4 pb-1 column ${executiveSummaryClass}`}>
        <div className="row">
          <div className={`col-4 pl-1 ${toggleWrapperClass}`}>
            <MultiSelectDropdown
              options={targetsObject}
              selected={selectedTarget}
              showLegend
              minimumChipsSelected={1}
              onChange={onTargetChange}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4 pl-1 pr-1 total-campaign-section">
            <header>Total Campaign</header>
            <div className="card-wrapper">
              <TotalCampaign
                data={miscResultData}
                selectedTarget={selectedTarget[0]}
                notSelectedTarget={notSelectedTarget}
                lastValidReport={lastValidReport}
                conversionsMetricName={conversionsMetricName}
              />
            </div>
          </div>
          <div className="col-4 pl-1 pr-1 top-performers-section">
            <header>
              <div className="header-wrapper">
                <div className="title-container">
                  <span>Top Performers by</span>
                </div>
                <div className="dropdown-container">
                  <Dropdown
                    options={performanceMetricOptions}
                    value={selectedPerformanceMetric as PerformanceGroupNames}
                    onChange={onPerformanceMetricChange}
                  />
                </div>
              </div>
            </header>
            <TopPersuadableAudiences
              performanceMetric={
                selectedPerformanceMetric as PerformanceGroupNames
              }
              selectedTarget={selectedTarget[0]}
              data={breakoutChartData}
              conversionsMetricName={conversionsMetricName}
            />
            <span className="cost-data-label">
              {hasPersuasionCostData
                ? '* Top Performing Breakout by Ad Cost Persuasion Index'
                : '* Top Performing Breakout by Persuasion Index'}
            </span>
          </div>
          <div className="col-4 pl-1 pr-1 delivery-and-outcomes-section">
            <header>Delivery and Outcomes</header>
            <div className="card-wrapper">
              <DeliveryAndOutcomes
                data={miscResultData}
                selectedTarget={selectedTarget[0]}
                lastValidReport={lastValidReport}
                conversionsMetricName={conversionsMetricName}
                showLroiInVisualization={showLroiInVisualization}
                useEquivalizedMetrics={useEquivalizedMetrics}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="executive-summary-report-overview">
        <ReportOverview
          chartData={miscResultData}
          targets={targets}
          performanceMetricGroups={performanceMetricGroups}
          conversionType={conversionType}
          omissionMessages={omissionMessages}
          {...reportOverviewConfig}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (
  state: State,
): {
  performanceMetricGroups: string[];
  conversionType: string;
  costData: {
    AD_COST: boolean;
    PERSUASION_INDEX_BY_COST: boolean;
    COST_PER_INCREMENTAL_LIFT: boolean;
  };
} => ({
  performanceMetricGroups: getPerformanceMetricGroupsFromLastValidReport(state),
  conversionType: getConversionType(state),
  costData: hasAdCostRelateResults(state),
});

const mapDispatchToProps = {
  updateResultSelectionFilters:
    resultSelectionsActions.updateResultSelectionFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutiveSummary);
