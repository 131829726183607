import { useAuth0 } from '@auth0/auth0-react';
import { deleteAllCookies } from '../helpers/cookies';

export type HookReturn = {
  logout: () => void;
};

const useLogout = (): HookReturn => {
  const { logout } = useAuth0();

  const logoutAndClean = (): void => {
    deleteAllCookies();
    logout(); // let Auth0 choose the default URL
  };

  return { logout: logoutAndClean };
};

export default useLogout;
