import { Auth0Client } from '@auth0/auth0-spa-js';
import axios, { AxiosRequestConfig, AxiosResponse, Canceler } from 'axios';
import { get } from 'lodash/fp';
import { Index as Segments } from 'routes';
import store from 'store';
import { setToken } from 'store/actions/user';
import ApiReturn, { IMeta } from 'types/apiReturn';
import FetchMethod from 'types/fetchMethod';
import { IAxiosConfig, IHeaderType, ISecureFetchType } from './types';

const { CancelToken } = axios;

const secureFetch = <T, U = void>({
  endpoint,
  accessToken,
  payload,
  method,
  isDownload,
}: ISecureFetchType): [Promise<ApiReturn<T, U>>, Canceler] => {
  let fetchMethod = method;

  if (!method) fetchMethod = payload ? FetchMethod.POST : FetchMethod.GET;

  const params: IHeaderType = {
    method: fetchMethod,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const source = CancelToken.source();
  const axiosConfig: IAxiosConfig = {
    ...params,
    responseType: isDownload ? 'blob' : undefined,
    cancelToken: source.token,
    url: `${window.location.protocol}//${window.location.hostname}:${
      process.env.REACT_APP_API_PORT ?? 443
    }/${Segments.SEGMENT_BASE_API}/${endpoint.replace(/^\//, '')}`,
    data: payload,
  };

  return [
    new Promise((resolve) =>
      axios
        .request(axiosConfig as AxiosRequestConfig)
        .then((res: AxiosResponse) => {
          if (res?.data?.error) throw Error(res.data.error);
          if (isDownload) resolve({ data: [res.data] });
          if (res?.data?.meta)
            resolve({
              data: res.data.data as T[],
              meta: res.data.meta as IMeta<T, U>,
            });

          resolve({ data: res.data.data as T[] });
        })
        .catch(async (err) => {
          if (!axios.isCancel(err)) {
            if (err.response) {
              // If it was an error during an upload request
              // then the response type will be blob, so convert it
              if (err.response.data instanceof Blob) {
                const errorText = await err.response.data.text();
                err.response.data = JSON.parse(errorText);
              }

              resolve({
                data: [],
                error: {
                  message:
                    err?.response?.data?.error?.message || 'Unknown error',
                  ...(err?.response?.data?.error?.meta && {
                    meta: err?.response?.data?.error?.meta,
                  }),
                },
              });
            } else if (err.request) {
              // client never received a response, or request never left
              resolve({
                data: [],
                error: { message: err?.message ?? 'Unknown error' },
              });
            } else {
              resolve({
                data: [],
                error: { message: err?.message ?? 'Unknown error' },
              });
            }
          }
        }),
    ),
    source.cancel,
  ];
};

export type FetchWithAuth0 = {
  endpoint: string;
  payload?: unknown;
  method: FetchMethod;
  isDownload?: boolean;
};

const MOCK_AUTH_CLIENT = { getTokenSilently: () => Promise.resolve('token') };

export const appendClientExtID = (endpoint: string, extID: string): string => {
  const hasQueryString = endpoint.includes('?');
  if (!hasQueryString) return `${endpoint}?clientExtID=${extID}`;
  const hasClientExtID = endpoint.includes('clientExtID=');
  if (hasClientExtID) return endpoint;
  return `${endpoint}&clientExtID=${extID}`;
};

export const fetchApi = async ({
  endpoint,
  payload,
  method,
  isDownload = false,
}: FetchWithAuth0): Promise<ApiReturn<unknown, void>> => {
  const config = get('config', store.getState());
  const user = get('user', store.getState());
  const token = get('token', user) as string;
  const selectedClient = get('selectedClient', user);
  const userClients = get('clients', user) ?? [];
  const selectedClientExtID =
    userClients.find((client) => client.id === selectedClient)?.extID ?? '';

  const auth0 = process.env.REACT_APP_CYPRESS
    ? MOCK_AUTH_CLIENT
    : new Auth0Client({
        domain: get('domain', config),
        clientId: get('clientId', config),
        authorizationParams: {
          redirect_uri: window.location.origin,
          audience: get('audience', config),
          scope: 'read:current_user update:current_user_metadata',
        },
      });

  let accessToken = token;
  if (!token) {
    accessToken = await auth0.getTokenSilently();
    store.dispatch(setToken(accessToken ?? ''));
  }

  const [promise] = secureFetch({
    endpoint: appendClientExtID(endpoint, selectedClientExtID),
    payload,
    accessToken,
    method,
    isDownload,
  });

  return promise;
};

export { secureFetch };
