import { getClass } from 'helpers/components';
import { flow, map, multiply } from 'lodash/fp';
import React, { FunctionComponent, useState } from 'react';
import DurationItem, { IDurationItem } from './durationItem';
import { calcHours, calcMins, calcSecs } from '../../helpers/humanizeDuration';

const componentName = 'duration-selector';
const componentClass = getClass(componentName);

type Props = {
  onChange: (value: number) => void;
  isMinutes?: boolean;
  total?: number;
};

const DurationSelector: FunctionComponent<Props> = ({
  onChange,
  isMinutes = false,
  total = 0,
}) => {
  const [seconds, setSeconds] = useState(calcSecs(total));
  const [minutes, setMinutes] = useState(multiply(60, calcMins(total)));
  const [hours, setHours] = useState(multiply(3600, calcHours(total)));

  const itemPropList = [
    {
      initialValue: calcSecs(total),
      onChange: (value: number): void => {
        flow(multiply(1), setSeconds)(value);
        onChange(value + minutes + hours);
      },
      autoFocus: true,
      max: 60,
      name: 'Seconds',
    },
    {
      initialValue: calcMins(total),
      onChange: (value: number): void => {
        flow(multiply(60), setMinutes)(value);
        onChange(seconds + multiply(60, value) + hours);
      },
      max: 60,
      autoFocus: isMinutes,
      name: 'Minutes',
    },
    {
      initialValue: calcHours(total),
      onChange: (value: number): void => {
        flow(multiply(3600), setHours)(value);
        onChange(seconds + minutes + multiply(3600, value));
      },
      autoFocus: false,
      name: 'Hours',
    },
  ];

  // Removes the seconds if it counts minutes.
  if (isMinutes) itemPropList.shift();

  return (
    <div className={componentClass}>
      {map((itemProps: IDurationItem) => DurationItem({ ...itemProps }))(
        itemPropList,
      )}
    </div>
  );
};

export default DurationSelector;
