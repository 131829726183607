import { useSortable } from '@dnd-kit/sortable';
import { CSS, Transform } from '@dnd-kit/utilities';
import Chip from 'components/Chip';
import { Placement } from 'components/Popover';
import { Style, Tooltip } from 'components/Tooltip';
import { getTestId } from 'helpers/components';
import React, {
  FC,
  MouseEvent,
  PointerEvent,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import ITextValue from 'types/textValue';
import { selectedChipsComponentName } from '..';

const TooltipWrapper = (props: {
  children: string;
  tooltip: string;
}): ReactElement => {
  const { children, tooltip } = props;
  return (
    <Tooltip
      placement={Placement.top}
      style={Style.secondary}
      content={tooltip}
    >
      {children}
    </Tooltip>
  );
};

interface SortableElementProps {
  item: string | ITextValue;
  canRemove?: boolean;
  testId?: string;
  chipDisabled?: boolean;
  onClick: (event: MouseEvent<HTMLSpanElement>) => void;
  canReorder?: boolean;
  disabled?: boolean;
  id: string;
  onWidthSet?: (id: string, width: number) => void;
  width?: number;
}

export const SortableItem: FC<SortableElementProps> = (props) => {
  const {
    id,
    item,
    onClick,
    canRemove,
    canReorder,
    chipDisabled,
    testId,
    onWidthSet,
    width,
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    node,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString({
      ...transform,
      scaleX: 1,
      scaleY: 1,
    } as Transform),
    flexBasis: width,
    width,
    flexGrow: 0,
    flexShrink: 0,
    opacity: isDragging ? 0.5 : 1,
    transition,
  };
  const chip = useRef<HTMLSpanElement>(null);
  const removeIcon = useRef<HTMLSpanElement>(null);
  const ref = useRef({ chip, removeIcon });

  const selectedChipsComponentTestId = getTestId(
    selectedChipsComponentName,
    testId,
  );
  useEffect(() => {
    if (!width && node.current) {
      const newWidth = node.current.getBoundingClientRect().width;
      onWidthSet?.(id, newWidth);
    }
  }, [node, width]);

  const value = typeof item === 'string' ? item : (item as ITextValue).text;
  const handlePointerDown = (event: PointerEvent<HTMLSpanElement>): void => {
    if (
      !event.nativeEvent
        .composedPath()
        .includes(removeIcon.current as HTMLSpanElement)
    )
      listeners?.onPointerDown(event);
  };
  return (
    <span
      ref={setNodeRef}
      style={
        isSorting
          ? style
          : {
              flexBasis: width,
              flexGrow: 0,
              flexShrink: 0,
              maxWidth: '100%',
            }
      }
      {...attributes}
      {...listeners}
      onPointerDown={handlePointerDown}
    >
      <Chip
        id={id}
        testId={`${selectedChipsComponentTestId}-${value}`}
        type="secondary"
        disabled={chipDisabled}
        onClick={onClick}
        canRemove={canRemove}
        ref={ref}
        canReorder={canReorder}
      >
        {!isDragging && typeof item === 'object' && item.tooltip ? (
          <TooltipWrapper tooltip={item.tooltip}>{value}</TooltipWrapper>
        ) : (
          value
        )}
      </Chip>
    </span>
  );
};
