import IConfig from 'domains/config/types';
import IReport, {
  CloneResponse,
  IAttributionReport,
} from 'domains/reports/types';
import { fetchApi } from 'helpers/fetching';
import { showSuccessToast, showErrorToast } from 'helpers/general';
import {
  trackCloneReport,
  trackCreateReport,
  trackDeleteReport,
  trackEditReport,
} from 'helpers/mixpanel';
import { AnyAction, Dispatch } from 'redux';
import { Index, Actions } from 'routes';
import ActionTypes from 'store/actions/types';
import { Action, PayloadLessAction } from 'types/action';
import FetchMethod from '../../../types/fetchMethod';
import { isTarget } from 'store/selectors/report';
import State from 'types/state';
import {
  isAttributionReport,
  isReport,
} from 'domains/reports/adapters/general';
import * as reportActions from 'store/actions/report';
import { ThunkDispatch } from 'redux-thunk';

export const handleCloneReport =
  (payload: IReport, action: Actions.SEGMENT_COPY | Actions.SEGMENT_CLONE) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    const cloneResponse = (await fetchApi({
      endpoint: `${Index.SEGMENT_REPORTS}/${action}`,
      payload,
      method: FetchMethod.POST,
    })) as CloneResponse;

    if (!cloneResponse.error?.message) {
      dispatch(cloneReport(payload));
      if (isTarget({ report: payload } as State)) {
        dispatch(setTargetList(null));
        showSuccessToast('Audience cloned.');
      } else if (isAttributionReport(payload as IAttributionReport)) {
        dispatch(setAttributionReportList(null));
        showSuccessToast('Report cloned.');
      } else if (isReport(payload)) {
        dispatch(setReportList(null));
        showSuccessToast('Report cloned.');
      }
      if (action === Actions.SEGMENT_COPY) {
        dispatch(
          reportActions.processRules({
            isBaseReportSaved: true,
            isBaseReportSaving: false,
            isBaseReportSavingAs: false,
          }),
        );

        if (cloneResponse.meta?.id)
          openReportInNewTab(cloneResponse.meta?.id, payload);
      }
    } else {
      if (action === Actions.SEGMENT_COPY) {
        dispatch(
          reportActions.processRules({
            isBaseReportSaved: false,
            isBaseReportSaving: false,
            isBaseReportSavingAs: false,
          }),
        );
      }
      showErrorToast(
        cloneResponse.error?.message ?? 'There has been an error.',
      );
    }
  };

function openReportInNewTab(reportCopyId: string, payload: IReport): void {
  let reportType;
  if (isTarget({ report: payload } as State)) {
    reportType = Index.SEGMENT_TARGETS;
  } else if (isReport(payload)) {
    reportType = Index.SEGMENT_REPORTS;
  } else if (isAttributionReport(payload as IAttributionReport)) {
    reportType = Index.SEGMENT_ATTRIBUTION_REPORTS;
  }

  const urlToOpen = `${window.location.protocol}//${window.location.host}${Index.SEGMENT_HOME}${reportType}/${Actions.SEGMENT_EDIT}/${reportCopyId}?norun=1`;

  if (urlToOpen) window.open(urlToOpen, '_blank');
}

export const cloneReport = (payload: IReport): Action<IReport> => {
  trackCloneReport(payload);
  return {
    type: ActionTypes.ROOT_CLONE_REPORT,
    payload,
  };
};

export const createReport = (payload: string): Action<string> => {
  trackCreateReport(payload);
  return {
    type: ActionTypes.ROOT_CREATE_REPORT,
    payload,
  };
};

export const handleDeleteReport =
  (payload: IReport) =>
  async (dispatch: Dispatch): Promise<void> => {
    const deleteResponse = (await fetchApi({
      endpoint: `${Index.SEGMENT_REPORTS}/`,
      payload,
      method: FetchMethod.DELETE,
    })) as CloneResponse;

    if (!deleteResponse.error?.message) {
      dispatch(deleteReport(payload));
      dispatch(setAttributionReportList(null));
      showSuccessToast('Report deleted');
    } else {
      showErrorToast(
        deleteResponse.error?.message ?? 'There has been an error.',
      );
    }
  };

export const deleteReport = (payload: IReport): Action<IReport> => {
  trackDeleteReport(payload);
  return {
    type: ActionTypes.ROOT_DELETE_REPORT,
    payload,
  };
};

export const editReport = (payload: IReport): Action<IReport> => {
  trackEditReport(payload);
  return {
    type: ActionTypes.ROOT_EDIT_REPORT,
    payload,
  };
};

export const setConfig = (config: IConfig): Action<IConfig> => ({
  type: ActionTypes.ROOT_SET_CONFIG,
  payload: config,
});

export const setReportList = (
  payload: IReport[] | null,
): Action<IReport[] | null> => ({
  type: ActionTypes.ROOT_SET_REPORT_LIST,
  payload,
});

export const setAttributionReportList = (
  payload: IReport[] | null,
): Action<IReport[] | null> => ({
  type: ActionTypes.ROOT_SET_ATTRIBUTION_REPORT_LIST,
  payload,
});

export const setTargetList = (
  payload: IReport[] | null,
): Action<IReport[] | null> => ({
  type: ActionTypes.ROOT_SET_TARGET_LIST,
  payload,
});

export const setStateLoaded = (): PayloadLessAction => ({
  type: ActionTypes.ROOT_SET_LOADED,
});

export const setStateNotLoaded = (): PayloadLessAction => ({
  type: ActionTypes.ROOT_SET_LOADING,
});
