import { fetchApi } from 'helpers/fetching';
import get from 'lodash/get';
import { Dispatch } from 'redux';
import { Index } from 'routes';
import ActionType from 'store/actions/types';
import { PayloadLessAction } from 'types/action';
import FetchMethod from 'types/fetchMethod';

const usersReportLoadStarted = (): PayloadLessAction => ({
  type: ActionType.ADMIN_USERS_REPORT_LOAD_STARTED,
});

const usersReportLoadFinished = (): PayloadLessAction => ({
  type: ActionType.ADMIN_USERS_REPORT_LOAD_FINISHED,
});

export const loadUsersReport =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(usersReportLoadStarted());
    const apiResponse = await fetchApi({
      endpoint: `/${Index.SEGMENT_REPORTS}/download-users`,
      method: FetchMethod.GET,
      isDownload: true,
    });
    const blob = new Blob(get(apiResponse, 'data') as unknown as BlobPart[], {
      type: 'text/csv',
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'users-report');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    dispatch(usersReportLoadFinished());
    return;
  };
