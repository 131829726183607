import { TableDataType } from 'components/Table';
import { IChartConfig } from 'helpers/charts/commonChartDesignConfig';
import { getClass, getTestId } from 'helpers/components';
import React, { FC, ReactElement, useState } from 'react';
import BarChart, { IBarChartSeries } from './components/BarChart';
import BubbleChart, {
  IBubbleChartSeries,
  IPlotLines,
} from './components/BubbleChart';
import TableWrapper, {
  ITableColumns,
  ITableData,
  ITableRow,
} from './components/TableWrapper';

export const chartWithTableComponentName = 'chart-with-table';

const chartWithTableClassName = getClass(chartWithTableComponentName);

export enum ChartType {
  BUBBLE = 'BUBBLE',
  BAR = 'BAR',
}

type ChartSeries =
  | {
      type: ChartType.BUBBLE;
      series: IBubbleChartSeries;
    }
  | {
      type: ChartType.BAR;
      series: IBarChartSeries;
    };

interface Props {
  tableColumns: ITableColumns;
  tableData: ITableData;
  chartSeries: ChartSeries;
  xAxisName: string;
  yAxisName: string;
  chartConfig: IChartConfig;
  yPlotLines?: IPlotLines;
  overlayElement?: ReactElement;
  showOverlay?: boolean;
  testId?: string;
  stickyFirstColumn?: boolean;
  rowRemoveClickAction?: (row: TableDataType) => void;
  tableHeaderCellClickAction?: (id: string) => void;
}

const ChartWithTable: FC<Props> = ({
  tableColumns,
  tableData,
  chartSeries,
  xAxisName,
  yAxisName,
  chartConfig,
  yPlotLines,
  overlayElement,
  showOverlay,
  testId,
  stickyFirstColumn = false,
  rowRemoveClickAction,
  tableHeaderCellClickAction,
}) => {
  const [hoveredPoint, setHoveredPoint] = useState<TableDataType>();

  const handleRowHover = (row: ITableRow): void => {
    setHoveredPoint(row?.original);
  };

  const handleRowClick = (row: ITableRow): void => {
    if (!row || !rowRemoveClickAction) return;

    rowRemoveClickAction(row.original);
  };

  const handleHeaderCellClick = (id: string): void => {
    if (tableHeaderCellClickAction) {
      tableHeaderCellClickAction(id);
    }
  };

  const chartWithTableTestId = getTestId(chartWithTableComponentName, testId);

  const chartProps = {
    chartConfig,
    xAxisName,
    yAxisName,
    yPlotLines,
    overlayElement,
    showOverlay,
    hoveredPoint,
    testId: `${chartWithTableTestId}-chart`,
  };

  const renderChartByType = (): JSX.Element | null => {
    switch (chartSeries.type) {
      case ChartType.BUBBLE:
        return <BubbleChart {...chartProps} series={chartSeries.series} />;
      case ChartType.BAR:
        return <BarChart {...chartProps} series={chartSeries.series} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`${chartWithTableClassName} col-${tableColumns.length}`}
      data-testid={chartWithTableTestId}
    >
      {renderChartByType()}
      <TableWrapper
        columns={tableColumns}
        data={tableData}
        onRowHover={handleRowHover}
        rowRemoveClickAction={handleRowClick}
        testId={`${chartWithTableTestId}-table`}
        onHeaderCellClick={handleHeaderCellClick}
        stickyFirstColumn={stickyFirstColumn}
      />
    </div>
  );
};

export default ChartWithTable;
