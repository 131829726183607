import { getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';

export const separatorComponentName = 'separator';

const separatorComponentClass = getClass(separatorComponentName);

const Separator: FunctionComponent = (): ReactElement => (
  <hr className={separatorComponentClass} />
);

export default Separator;
