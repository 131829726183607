import { DropdownPath } from 'components/DropdownSearch/DropdownPath';
import Flex, { Horizontal, Vertical } from 'components/Flex';
import H2 from 'components/H2';
import Input, { NewInputValue, Type as InputType } from 'components/Input';
import Label from 'components/Label';
import IDimension from 'domains/dimensions/types';
import { getClass, getTestId } from 'helpers/components';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  Fragment,
} from 'react';
import {
  IFormUpdatedPayload,
  IUploadForm,
  TAttributesConfig,
} from 'types/uploads';
import { getGroupsForMenuCategoryOptions } from 'helpers/uploader';
import { GenericEventFileDetails } from './GenericEventDetail';

export const FileDetailsComponentName = 'uploads-file-details';

const FileDetailsRowNameClass = getClass(
  `${FileDetailsComponentName}__details-row`,
);

const FileDetailsSegmentNameListClass = getClass(
  `${FileDetailsComponentName}__segment-name__list`,
);

const FileDetailsEntryClass = getClass(
  `${FileDetailsComponentName}__segment-name__entry`,
);

const FileDetailsSegmentNameKeyClass = getClass(
  `${FileDetailsComponentName}__segment-name__key`,
);

const FileDetailsSegmentNameLabelClass = getClass(
  `${FileDetailsComponentName}__segment-name__label`,
);

const FileDetailsTestId = getTestId(FileDetailsComponentName);
const FileDetailsDefaultClass = getClass(FileDetailsComponentName);

export interface IFileDetails {
  dimensions: IDimension[];
  attributes: IUploadForm['dataset_metadata']['attributes'];
  uploadFormUpdated: (arg0: IFormUpdatedPayload) => void;
  fileType: string;
  subType?: string;
  conversionType?: string;
  performanceMetricGroups?: string[];
  dataset_path?: string;
}

export const FileDetails: FunctionComponent<
  PropsWithChildren<IFileDetails>
> = ({
  fileType,
  dimensions,
  attributes,
  children,
  uploadFormUpdated,
  subType = 'exposures',
  conversionType,
  performanceMetricGroups,
  dataset_path,
}) => {
  const selectedGroups = getGroupsForMenuCategoryOptions(
    dimensions,
    dataset_path,
  );
  const [groups, setGroups] = useState<(string | undefined)[]>([
    ...selectedGroups,
  ]);

  const updateAttributesPath = (path: string): void => {
    if (fileType === 'SEGMENT') {
      const attributesWithPath = attributes?.map((attribute) => ({
        ...attribute,
        path,
      })) as TAttributesConfig[];
      uploadFormUpdated({
        key: 'dataset_metadata.attributes',
        value: attributesWithPath,
      });
      return;
    }
    uploadFormUpdated({
      key: 'dataset_metadata.dataset_path',
      value: path,
    });
  };

  // Double check that the file type is supported, it should not be possible to get here with an unsupported file type
  const supportedFileTypes = ['SEGMENT', 'GENERIC_EVENTS'];

  const segmentFileDetails = (
    <div className={FileDetailsRowNameClass}>
      <Flex horizontal={Horizontal.left} vertical={Vertical.top}>
        <Label text="Segment" />
        <div className={FileDetailsSegmentNameListClass}>
          {attributes?.map((attribute, i) => {
            const handleChangeSegmentName = (value: NewInputValue): void =>
              uploadFormUpdated({
                key: `dataset_metadata.attributes[${i}].name`,
                value,
              });

            const handleChangeDescription = (value: NewInputValue): void =>
              uploadFormUpdated({
                key: `dataset_metadata.attributes[${i}].description`,
                value,
              });

            return (
              <div className={FileDetailsEntryClass} key={attribute.id}>
                <Flex key={attribute.source_col}>
                  <Label text="Name" />
                  <div className={FileDetailsSegmentNameKeyClass}>
                    <span>{attribute.source_col}</span>
                  </div>
                  <div className={FileDetailsSegmentNameLabelClass}>
                    <span>display as</span>
                  </div>
                  <Input
                    placeholder="Display name"
                    type={InputType.text}
                    value={attribute.name}
                    onChange={handleChangeSegmentName}
                  />
                </Flex>
                <Flex>
                  <Label text="Description" />
                  <Input
                    placeholder="Type description here"
                    type={InputType.text}
                    value={attribute.description}
                    onChange={handleChangeDescription}
                  />
                </Flex>
              </div>
            );
          })}
        </div>
      </Flex>
    </div>
  );

  if (!supportedFileTypes.includes(fileType)) {
    return null;
  }
  const selectedPath =
    fileType === 'SEGMENT' ? attributes[0]?.path : dataset_path;
  return (
    <section
      className={FileDetailsDefaultClass}
      data-testid={FileDetailsTestId}
    >
      <H2>File Details</H2>

      {(fileType === 'SEGMENT' ||
        (fileType === 'GENERIC_EVENTS' && subType === 'conversions')) && (
        <div className="inline-field-container">
          <Label text="Menu Category" />

          <div className="field-container">
            <DropdownPath
              {...{ groups, setGroups }}
              selected={selectedPath}
              setSelected={updateAttributesPath}
            />
          </div>
        </div>
      )}
      {fileType === 'SEGMENT' && segmentFileDetails}
      {fileType === 'GENERIC_EVENTS' && (
        <GenericEventFileDetails
          subType={subType}
          conversionType={conversionType as string}
          performanceMetricGroups={performanceMetricGroups as string[]}
        />
      )}
      {children}
    </section>
  );
};
