import { IDataset } from 'domains/datasets/types';
import { IBusinessData } from 'domains/reports/types';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';

export const initialState = {
  datasetsOptions: [],
};

const reducer = (
  state: IBusinessData = initialState,
  action: Action<IBusinessData>,
): IBusinessData => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.DOMAINS_INIT:
      const datasetsOptions = ((payload?.datasets ?? []) as IDataset[]).map(
        (item) => ({
          text: item.display_name,
          value: item.id,
          type: item.type,
        }),
      );
      return {
        ...state,
        datasetsOptions,
      };
    default:
      return state;
  }
};

export default reducer;
