import { flatMap, get } from 'lodash';

export const handleChartTooltips = (
  hoverPoint: EventTarget | null,
  chart?: Highcharts.Chart,
  destroy = false,
): void => {
  if (!chart || !hoverPoint) {
    return;
  }

  const xPoint = get(hoverPoint, 'x');

  const series = get(chart, 'series', []);
  const points = flatMap(series, 'points').filter(
    (highchartPoint: Highcharts.Point) =>
      highchartPoint &&
      highchartPoint.x === xPoint &&
      (highchartPoint.series.options.visible ?? true),
  );

  if (destroy) {
    chart?.tooltip?.destroy();
    points.forEach((point) => point.setState(''));
    series.forEach((seriesRow) => seriesRow.setState(''));
    return;
  }

  if (points.length) {
    chart.tooltip.refresh([...points]);
    points.forEach((point) => point.setState('hover'));
  }
};
