import { roundTo10 } from './roundTo10';

export const getTicIncrementValues = ({
  dataMax,
  dataMin,
}: {
  dataMax: number;
  dataMin: number;
}): { tick: number; increment: number } => {
  const incrementBase = Math.ceil((dataMax - dataMin) / 5);
  const increment =
    incrementBase > 10 ? roundTo10(incrementBase) : incrementBase;

  const tickBase = Math.floor(dataMin) - increment;
  const tick = incrementBase > 10 ? roundTo10(tickBase) : tickBase;

  return { tick, increment };
};
