import { getDatasetDefaultDateRange } from 'domains/reports/adapters/date';
import { GENPOP_TARGET_ID } from 'store/actions/businessData';
import { IQuery, Mode, Modes } from 'types/query';
import {
  FilterType,
  IChildrenBase,
  IQueryFilters,
  newDateTimeFilter,
  newFilter,
  Operator,
} from '../../types/filter';
import { getDefaultOrderBy } from './adapters/results';
import IReport, {
  AggregationMethodOptions,
  AttributionFilterType,
  IAttributionReport,
  IDomainsDateRanges,
  INotPersistedProps,
  MetricTypes,
  ReportTimeDimension,
  reportType,
  SubTypeKeys,
  UNSAVED_REPORT_ID,
} from './types';
import { v4 as uuid } from 'uuid';

const getDefaultRuleFilters = (): IChildrenBase => [
  {
    ...newFilter(),
    field: 'VIEWING_TYPE',
    id: 'VIEWING_TYPE',
    type: FilterType.SIMPLE,
    operator: 'IN' as Operator,
    value: [{ id: 0, name: 'Live' }],
  },
  {
    ...newFilter(),
    field: 'CONSECUTIVE_TELECAST_VIEWING_HH',
    id: 'CONSECUTIVE_TELECAST_VIEWING_HH',
    type: FilterType.SIMPLE,
    operator: 'GT' as Operator,
    value: '59',
    operator_type: 'DURATION_COMPARISON',
  },
];

export const getExposureAdsFilters = (
  queryMode: Mode,
  domainsDateRanges: IDomainsDateRanges,
): IChildrenBase => {
  const { dateStart, dateEnd } = getDatasetDefaultDateRange(
    domainsDateRanges,
    queryMode,
  );

  return [
    {
      id: 'FLIGHT_DATES',
      ...newDateTimeFilter(dateStart, dateEnd),
    },
    {
      ...newFilter(),
      field: 'AD_TUNING_DURATION',
      id: 'AD_TUNING_DURATION',
      type: FilterType.SIMPLE,
      operator: 'GT' as Operator,
      value: '2',
      operator_type: 'DURATION_COMPARISON',
    },
    {
      ...newFilter(),
      field: 'VIEWING_TYPE',
      id: 'VIEWING_TYPE',
      type: FilterType.SIMPLE,
      operator: 'IN' as Operator,
      value: [{ id: 0, name: 'Live' }],
    },
    {
      ...newFilter(),
      field: 'BRAND_LABEL',
      id: 'BRAND_LABEL',
      type: FilterType.SIMPLE,
      operator: 'IN' as Operator,
    },
    {
      ...newFilter(),
      field: 'CREATIVE_NAME_GROUP',
      id: 'CREATIVE_NAME_GROUP',
      type: FilterType.SIMPLE,
      operator: 'IN' as Operator,
    },
  ];
};

const getOutcomeViewershipFilters = (
  queryMode: Mode,
  domainsDateRanges: IDomainsDateRanges,
): IChildrenBase => {
  const { dateStart, dateEnd } = getDatasetDefaultDateRange(
    domainsDateRanges,
    queryMode,
  );

  return [
    {
      id: 'FLIGHT_DATES',
      ...newDateTimeFilter(dateStart, dateEnd),
    },
    {
      ...newFilter(),
      field: 'TOTAL_WATCHED_HH',
      id: 'TOTAL_WATCHED_HH',
      type: FilterType.SIMPLE,
      operator: 'GT' as Operator,
      value: '59',
      operator_type: 'DURATION_COMPARISON',
    },
    {
      ...newFilter(),
      field: 'VIEWING_TYPE',
      id: 'VIEWING_TYPE',
      type: FilterType.SIMPLE,
      operator: 'IN' as Operator,
      value: [{ id: 0, name: 'Live' }],
    },
    {
      ...newFilter(),
      field: 'NETWORK',
      id: 'NETWORK',
      type: FilterType.SIMPLE,
      operator: 'IN' as Operator,
    },
    {
      ...newFilter(),
      field: 'CONTENT_SERIES',
      id: 'CONTENT_SERIES',
      type: FilterType.SIMPLE,
      operator: 'IN' as Operator,
    },
    {
      ...newFilter(),
      field: 'PROGRAM',
      id: 'PROGRAM',
      type: FilterType.SIMPLE,
      operator: 'IN' as Operator,
    },
  ];
};

const getOutcomeBrandLiftFilters = (): IChildrenBase => [newFilter()];

const getAttributionRuleFilters = (
  subType: string,
  queryMode: Mode,
  domainsDateRanges: IDomainsDateRanges,
): IQueryFilters<IChildrenBase>[] => {
  const tempFilters = [] as IQueryFilters<IChildrenBase>[];
  tempFilters.push({
    name: AttributionFilterType.adDetails,
    type: 'LOGICAL',
    operator: 'AND',
    children: getExposureAdsFilters(queryMode, domainsDateRanges),
  });

  tempFilters.push(
    subType === SubTypeKeys.TV_TUNE_IN
      ? {
          name: AttributionFilterType.viewership,
          type: 'LOGICAL',
          operator: 'AND',
          children: getOutcomeViewershipFilters(
            Modes.linear,
            domainsDateRanges,
          ),
        }
      : {
          name: AttributionFilterType.brandLift,
          type: 'LOGICAL',
          operator: 'AND',
          children: getOutcomeBrandLiftFilters(),
        },
  );

  return tempFilters;
};

export const DEFAULT_EXPERIAN_VALUES = [
  'AGE',
  'GENDER',
  'experian_person_1_education_model',
  'HH_INCOME',
  'experian_person_1_ethnic_group',
  'experian_person_1_marital_status',
  'experian_homeowner_combined_homeowner_or_renter',
  'experian_children_presence_of_child_0_18',
  'experian_census_region',
  'experian_census_division',
  'experian_number_of_household_members',
];

export const targetCategoryDefaultFilter = {
  filterId: uuid(),
  id: 'PIQ_CATEGORY',
  name: 'PIQ_CATEGORY',
  field: 'PIQ_CATEGORY',
  type: 'SIMPLE',
  operator: 'BREAKOUT_ON',
  value: [],
};

export const targetChainDefaultFilter = {
  filterId: uuid(),
  id: 'PIQ_CHAIN',
  name: 'PIQ_CHAIN',
  field: 'PIQ_CHAIN',
  type: 'SIMPLE',
  operator: 'BREAKOUT_ON',
  value: [],
};

export const targetInitialPIQFilterValue = {
  filterId: uuid(),
  field: 'PLACEIQ',
  id: 'PLACEIQ',
  type: 'PLACEIQ',
  isRule: true,
  operator: 'IN',
  children: [targetCategoryDefaultFilter, targetChainDefaultFilter],
};

export const initialBreakoutCategories = {
  type: 'LOGICAL',
  operator: 'AND',
  children: [targetInitialPIQFilterValue],
};

export const newAudienceInsightsQuery = (
  domainsDateRanges: IDomainsDateRanges,
): IQuery => {
  const { dateStart, dateEnd } = getDatasetDefaultDateRange(
    domainsDateRanges,
    Modes.linear,
  );

  const query: IQuery = {
    time_zone: 'America/New_York',
    sample_factor: 20,
    datasets: [],
    time_frame: 'BROADCAST',
    weight: 'WEIGHT_605_DVR',
    select: ['REACH', 'REACH_PC', 'AVG_AUDIENCE', 'INDEX', 'RATING'],
    level: 'ACCOUNT',
    targets: [],
    interval: { name: 'All Viewing', id: ReportTimeDimension.ALL_VIEWING },
    cumulative_group: '',
    mode: Modes.linear,
    group_advanced: [
      {
        name: 'Publisher',
        grouping_set: ['NETWORK_GROUP_OPENAP_SPLIT'],
      },
      {
        name: 'Publisher and Network',
        grouping_set: [
          'NETWORK_GROUP_OPENAP_SPLIT',
          'NETWORK_NAME_OPENAP_SPLIT',
        ],
      },
      {
        name: 'Publisher by Hour',
        grouping_set: ['NETWORK_GROUP_OPENAP_SPLIT', 'HOUR_OF_DAY'],
      },
      {
        name: 'Total TV by Genre',
        grouping_set: ['SERIES_GENRE'],
      },
      {
        name: 'Audience Density',
        grouping_set: [
          'NETWORK_GROUP_OPENAP_SPLIT',
          'NETWORK_NAME_OPENAP_SPLIT',
          'DAYPART',
        ],
      },
    ],
    filters: {
      type: 'LOGICAL',
      operator: 'AND',
      children: [
        {
          type: FilterType.DATETIME_RANGE,
          start: dateStart,
          end: dateEnd,
        },
        ...getDefaultRuleFilters(),
        {
          ...newFilter(),
          field: 'NETWORK_GROUP_OPENAP_SPLIT',
          id: 'NETWORK_GROUP_OPENAP_SPLIT',
          type: 'SIMPLE',
          isRule: true,
          operator: 'NOT IN',
          value: [
            {
              text: 'Outside of OpenAP',
              value: 'Outside of OpenAP',
            },
          ],
        },
        {
          ...newFilter(),
          field: 'NETWORK_NAME_OPENAP_SPLIT',
          id: 'NETWORK_NAME_OPENAP_SPLIT',
          type: 'SIMPLE',
          isRule: true,
          operator: 'NOT IN',
          value: [],
        },
      ],
    },
    tableVisible: false,
    chartVisible: false,
  };

  return query;
};

export const newQuery = (
  domainsDateRanges: IDomainsDateRanges,
  isTarget?: boolean,
  subType?: string,
): IQuery => {
  const { dateStart, dateEnd } = getDatasetDefaultDateRange(
    domainsDateRanges,
    Modes.linear,
  );

  const query: IQuery = {
    time_zone: 'America/New_York',
    sample_factor: 1,
    datasets: [],
    generic_events_datasets: [],
    time_frame: 'BROADCAST',
    weight: 'WEIGHT_605_DVR',
    select: isTarget ? ['ACCOUNT_ID'] : ['REACH'],
    level: 'ACCOUNT',
    sorting: getDefaultOrderBy(isTarget),
    targets: isTarget ? [] : [GENPOP_TARGET_ID],
    ...(isTarget && { selected_metric: 'ACCOUNT_ID' }),
    ...(subType && { subType }),
    interval: { name: 'All Viewing', id: ReportTimeDimension.ALL_VIEWING },
    group: [],
    cumulative_group: '',
    mode: Modes.linear,
    filters: {
      type: 'LOGICAL',
      operator: 'AND',
      children: [
        newDateTimeFilter(dateStart, dateEnd),
        ...(!isTarget ? getDefaultRuleFilters() : []),
      ],
    },
    ...(isTarget
      ? ({
          breakout_categories: initialBreakoutCategories,
        } as IQueryFilters)
      : {}),
    tableVisible: true,
    chartVisible: true,
    includeTargetBreakdown: false,
  };

  return query;
};

export const newAttributionQuery = (
  subType: string,
  queryMode: Mode,
  domainsDateRanges: IDomainsDateRanges,
): IQuery<IQueryFilters<IQueryFilters<IChildrenBase>[]>> => ({
  time_zone: 'America/New_York',
  sample_factor: 1,
  datasets: [],
  time_frame: 'BROADCAST',
  weight: 'WEIGHT_605_DVR',
  level: 'ACCOUNT',
  interval: { name: 'All Viewing', id: ReportTimeDimension.ALL_VIEWING },
  mode: Modes.adverts,
  targets: [],
  ...(subType === SubTypeKeys.BRAND_LIFT
    ? { performance_metric_groups: ['converters'] }
    : {}),
  filters: {
    type: 'LOGICAL',
    operator: 'AND',
    children: [
      ...getAttributionRuleFilters(subType, queryMode, domainsDateRanges),
    ],
  },
});

export const emptyConfirmationDialog = {
  confirmationDialogMessage: [],
  confirmationDialogHeader: '',
  confirmationDialogOK: '',
  confirmationDialogCancel: '',
  confirmationDialogType: '',
};

export const emptyModalDialog = {
  confirmationDialogMessage: [],
  confirmationDialogHeader: '',
  confirmationDialogOK: '',
  confirmationDialogType: '',
};

const newCommonPersistedProps = {
  isBaseReportNew: true,
  isBaseReportInitialized: true,
  isBaseReportRunning: false,
  confirmationDialog: emptyConfirmationDialog,
  modalDialog: emptyModalDialog,
  isReportInvalid: false,
  isBaseReportLoaded: false,
  isAccordionSettingsOpen: false,
  isAccordionQueryOpen: true,
  isAccordionResultsOpen: false,
  isModalOpen: false,
  isBaseReportFullyLoaded: false,
  isBaseReportSaved: false,
  isBaseReportSaving: false,
  isBaseReportSavingAs: false,
  reportHasChanged: true,
  dynamicBreakouts: [],
};

const newReportPersistedProps = (): {} => ({
  ...newCommonPersistedProps,
  metricType: MetricTypes.normal,
});

const newTargetPersistedProps = (): {} => ({
  ...newCommonPersistedProps,
  targetHasValidResults: false,
});

export const newAttributionReportPersistedProps = (
  domainsDateRanges: IDomainsDateRanges,
  subType: string,
): {} => {
  const dateRange = getDatasetDefaultDateRange(
    domainsDateRanges,
    Modes.adverts,
  );

  let complexOutcomes = {};
  if (subType === SubTypeKeys.BRAND_LIFT) {
    complexOutcomes = {
      performanceMetricGroups: ['converters'],
    };
  }
  return {
    ...newCommonPersistedProps,
    isAccordionMethodologyOpen: true,
    complexOutcomes,
    minDate: dateRange.dateMin,
    maxDate: dateRange.dateMax,
  };
};

export const newNotPersistedProps = (report: IReport): INotPersistedProps => ({
  isBaseReportInitialized: true,
  isBaseReportNew: false,
  isBaseReportInitiallyExecuted: false,
  isBaseReportSaved: true,
  isBaseReportSaving: false,
  isBaseReportSavingAs: false,
  isBaseReportFullyLoaded: false,
  confirmationDialog: emptyConfirmationDialog,
  modalDialog: emptyModalDialog,
  ...(report?.type === reportType.target && { targetHasValidResults: false }),
});

export const newBaseReport = (
  domainsDateRanges: IDomainsDateRanges,
  userId?: string,
  client?: string,
): IReport => ({
  cache_mode: 'default',
  client,
  createdAt: '',
  createdBy: userId ?? '',
  datasets: [],
  group: [],
  id: UNSAVED_REPORT_ID,
  name: undefined,
  isPublic: false,
  query: newQuery(domainsDateRanges),
  selectedCharts: [],
  selectedMetrics: [],
  sort_field: { id: 'REACH', name: 'REACH' },
  sort_order: { name: 'Descending', id: 'DESC' },
  type: undefined,
  updatedAt: '',
  use_async: null,
  rowLimit: 10,
  notPersistedProps: newReportPersistedProps(),
  persistedReport: false,
});

export const newAudienceInsightsReport = (
  domainsDateRanges: IDomainsDateRanges,
  userId?: string,
  client?: string,
): IReport => ({
  cache_mode: 'default',
  client,
  createdAt: '',
  createdBy: userId ?? '',
  datasets: [],
  id: UNSAVED_REPORT_ID,
  name: undefined,
  isPublic: false,
  query: newAudienceInsightsQuery(domainsDateRanges),
  selectedCharts: [],
  selectedMetrics: [],
  type: 'report',
  subType: SubTypeKeys.AUDIENCE_INSIGHTS,
  updatedAt: '',
  use_async: null,
  rowLimit: 10,
  notPersistedProps: newReportPersistedProps(),
  persistedReport: false,
});

export const emptyLastValidReport = null;

export const newReport = (
  domainsDateRanges: IDomainsDateRanges,
  userId?: string,
  client?: string,
  subType?: string,
): IReport =>
  subType === SubTypeKeys.AUDIENCE_INSIGHTS
    ? newAudienceInsightsReport(domainsDateRanges, userId, client)
    : {
        ...newBaseReport(domainsDateRanges, userId, client),
        type: reportType.report,
        subType: SubTypeKeys.PLATFORM,
      };

export const newTarget = (
  domainsDateRanges: IDomainsDateRanges,
  userId?: string,
  client?: string,
): IReport => ({
  cache_mode: 'default',
  client,
  createdAt: '',
  createdBy: userId ?? '',
  datasets: [],
  group: [],
  id: UNSAVED_REPORT_ID,
  name: undefined,
  query: newQuery(domainsDateRanges, true),
  selectedCharts: [],
  selectedMetrics: [],
  selected_metric: 'ACCOUNT_ID',
  sort_field: { id: 'ACCOUNT_ID', name: 'ACCOUNT_ID' },
  sort_order: { name: 'Descending', id: 'DESC' },
  sorting: {
    field: 'ACCOUNT_ID',
    order: 'DESC',
  },
  type: reportType.target,
  updatedAt: '',
  use_async: null,
  notPersistedProps: newTargetPersistedProps(),
  persistedReport: false,
});

export const newAttributionReport = (
  subType: string,
  domainsDateRanges: IDomainsDateRanges,
  userId?: string,
  queryMode?: Mode,
  client?: string,
): IAttributionReport => ({
  aggregation_method: AggregationMethodOptions.ADVANCED,
  cache_mode: 'default',
  client,
  createdAt: '',
  createdBy: userId ?? '',
  datasets: [],
  group: [],
  id: UNSAVED_REPORT_ID,
  name: undefined,
  query: newAttributionQuery(
    subType,
    queryMode ?? Modes.adverts,
    domainsDateRanges,
  ),
  subType,
  type: reportType.attributionReport,
  updatedAt: '',
  use_async: null,
  use_equivalized_metrics: false,
  notPersistedProps: newAttributionReportPersistedProps(
    domainsDateRanges,
    subType,
  ),
  persistedReport: false,
});
