import Button, { Kind, Type, Size } from 'components/Button';
import Flex from 'components/Flex';
import H1 from 'components/H1';
import ListActions from 'components/ListActions';
import Table, { textOverflows, alignments } from 'components/Table';
import { IConfigOption } from 'domains/configOptions/types';
import { getTestId, getComponentUuid, getClass } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Index, Actions, getUrl } from 'routes';
import IError from 'types/error';
import IUser from 'types/user';

const ADMIN_LIST_PAGE_SIZE = 10;

export const configOptionsListComponentName = 'config-options-list';
export const configOptionsListComponent = getComponentUuid(
  configOptionsListComponentName,
);

type ContentProps = {
  configOptions: IConfigOption[];
  testId?: string;
  resetList: () => void;
};

const ConfigOptionsList: FunctionComponent<ContentProps> = (
  props,
): ReactElement => {
  const { configOptions, testId, resetList } = props;

  const navigate = useNavigate();
  const { checkPermissions } = useUserPermissions();
  const { doSuccessToast, doErrorToast } = useToast();
  const { doDeleteById, loading } = useItemAdmin<IUser>({
    endpoint: Index.SEGMENT_CONFIG_OPTIONS,
  });

  const gotoCreate = (): void => {
    navigate(Actions.SEGMENT_CREATE);
  };

  const handleUpdateClick = (configOption: IConfigOption): void => {
    const updateUrl = getUrl([Actions.SEGMENT_EDIT, configOption.id ?? '']);
    navigate(updateUrl);
  };

  const handleDeleteClick = (configOption: IConfigOption): void => {
    doDeleteById({
      item: configOption,
      onSuccess: (): void => {
        resetList();
        doSuccessToast('Config option deleted!');
      },
      onError: (err: IError): void => {
        doErrorToast(err?.message ?? 'There has been an error.');
      },
    });
  };

  const configOptionsListTestId = getTestId(
    configOptionsListComponentName,
    testId,
  );
  const configOptionsListClass = getClass(configOptionsListComponentName);

  return (
    <div
      className={configOptionsListClass}
      data-testid={configOptionsListTestId}
    >
      <header className={`${configOptionsListClass}-header`}>
        <Flex>
          <H1>Config Options</H1>
          <Button
            kind={Kind.primary}
            type={Type.button}
            size={Size.small}
            onClick={gotoCreate}
          >
            New
          </Button>
        </Flex>
      </header>
      <section>
        <Table<IConfigOption>
          filter
          initialState={{ sortBy: [{ id: 'name' }] }}
          filterPlaceholder="Search for config options"
          goToEditOnRowClick
          noResultsMessage="No results found"
          textOverflow={textOverflows.ellipsis}
          editItemSegment={Actions.SEGMENT_EDIT}
          alignment={[alignments.left, alignments.right]}
          columns={[
            {
              Header: 'Config Option ID',
              accessor: 'configOptionId',
              width: '80%',
            },
            {
              Header: 'Actions',
              id: 'Actions',
              width: '20%',
              Cell: function UsersListTableActionsCell({
                row: { original: configOption },
              }: CellProps<IConfigOption>): JSX.Element {
                return (
                  <ListActions<IConfigOption>
                    loading={loading}
                    item={configOption}
                    canUpdate={checkPermissions('users::update')}
                    canDelete={checkPermissions('users::delete')}
                    onDeleteClick={(): void => handleDeleteClick(configOption)}
                    onUpdateClick={(): void => handleUpdateClick(configOption)}
                    testId={configOptionsListTestId}
                    id={configOption.id}
                  />
                );
              },
            },
          ]}
          data={configOptions}
          pageSize={ADMIN_LIST_PAGE_SIZE}
        />
      </section>
    </div>
  );
};

export default ConfigOptionsList;
