import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import ChartWithTable, { ChartType } from 'components/ChartWithTable';
import { IBarChartSeries } from 'components/ChartWithTable/components/BarChart';
import {
  IBubbleChartSeries,
  IPlotLines,
} from 'components/ChartWithTable/components/BubbleChart';
import {
  ITableColumns,
  ITableData,
} from 'components/ChartWithTable/components/TableWrapper';
import TableNameCell from 'components/ChartWithTable/components/TableWrapper/TableNameCell';
import { useBubbleChartAndTableTransformer } from 'components/ChartWithTable/transformer';
import { ITransformerInput } from 'components/ChartWithTable/transformer/types';
import { useBarChartAndTableTransformer } from 'components/ChartWithTable/transformer/useBarChartAndTableTransformer';
import { TableDataType } from 'components/Table';
import { ChartData, PerformanceGroupNames } from 'domains/reports/types';
import { IFilters } from 'helpers/charts/chartWithTable/types';
import { getMetricName } from 'helpers/groupNames/getMetricName';
import * as attributionReportResult from 'store/selectors/reportResult';
import React, { ReactElement, useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import ITextValue from 'types/textValue';
import State from 'types/state';
import { ITooltipValueFormatters } from 'helpers/charts/commonChartDesignConfig';
import { Overlay } from './Overlay';

interface IProps {
  chartType: ChartType;
  filteredData: ChartData;
  filters: IFilters;
  yPlotLines: IPlotLines;
  showOverlay: boolean;
  breakoutOptions: ITextValue[];
  removeRecord: (recordData: TableDataType) => void;
  changeSortedBy: (id: string) => void;
  performanceMetricGroups: PerformanceGroupNames[];
  conversionType?: string;
  useEquivalizedMetrics?: boolean;
  metrics?: string[];
}
type ColumnExtendedType = {
  [key: string]:
    | string
    | number
    | boolean
    | ((ctx: TableDataType) => string | number | boolean | null)
    | null;
};
interface IChartWithTableWrapperProps
  extends Omit<IProps, 'chartType' | 'filteredData'> {
  tableData: ITableData;
  chartSeries:
    | {
        type: ChartType.BAR;
        series: IBarChartSeries;
      }
    | {
        type: ChartType.BUBBLE;
        series: IBubbleChartSeries;
      };
}

const ChartWithTableWrapperComponent: React.FC<IChartWithTableWrapperProps> = ({
  chartSeries,
  breakoutOptions,
  tableData,
  filters,
  yPlotLines,
  showOverlay,

  removeRecord,
  changeSortedBy,
  conversionType,
  useEquivalizedMetrics,
}) => {
  const [isSortingChanged, setIsSortingChanged] = useState(false);

  const handleTableHeaderCellClick = (id: string): void => {
    setIsSortingChanged(true);
    changeSortedBy(id);
  };

  const tableColumns = useMemo(() => {
    const getColumnActiveState = (accessor: string): boolean => {
      if (filters.chartType !== ChartType.BAR) return false;

      return accessor === filters.yAxis;
    };
    const showX = chartSeries.type === ChartType.BUBBLE;
    const showY =
      (filters.xAxis !== filters.yAxis &&
        chartSeries.type === ChartType.BUBBLE) ||
      chartSeries.type !== ChartType.BUBBLE;
    const showZ =
      filters.bubbleSize !== filters.xAxis &&
      filters.bubbleSize !== filters.yAxis &&
      chartSeries.type === ChartType.BUBBLE;

    const getColumn = (metric: string): ColumnExtendedType => ({
      Header: getMetricName(metric, conversionType, useEquivalizedMetrics),
      accessor: get(metric),
      id: metric,
      width: 110,
      Cell: ({ value }: TableDataType) => value?.toString() ?? '',
      active: getColumnActiveState(metric),
      isSortedDesc: filters.isSortedDesc,
      isSorted: isSortingChanged && filters.sortedBy === metric,
    });

    const baseColumns = [
      {
        Header: 'Name',
        accessor: 'name',
        id: 'NAME',
        width: 160,
        Cell: ({ row, value }: CellProps<TableDataType>): ReactElement => (
          <TableNameCell
            name={value}
            color={(row?.original.color as string) ?? ''}
            showDot={filters.targets.length > 1}
          />
        ),
        disableSort: true,
      },
      showX ? getColumn(filters.xAxis) : null,
      showY ? getColumn(filters.yAxis) : null,
      showZ ? getColumn(filters.bubbleSize) : null,
    ];

    const columns = baseColumns.filter((col) => !!col);

    return columns as ITableColumns;
  }, [
    chartSeries.type,
    conversionType,
    filters.targets.length,
    filters.yAxis,
    filters.xAxis,
    filters.bubbleSize,
    filters.chartType,
    filters.isSortedDesc,
    filters.sortedBy,
    isSortingChanged,
  ]);

  const getTooltipFormatter = (axis: string): ITooltipValueFormatters => {
    const axisName =
      ['campaign.FREQUENCY', 'campaign.IMPRESSIONS'].includes(axis) &&
      useEquivalizedMetrics
        ? `${axis}_EQ`
        : axis;
    return {
      [axisName]: (value: string | number | null | undefined) =>
        value?.toString() ?? '',
    };
  };

  const convertFilterToEquivalized = (filter: string): string =>
    [
      'campaign.FREQUENCY',
      'campaign.IMPRESSIONS',
      'campaign.SPOT_COUNT',
    ].includes(filter) && useEquivalizedMetrics
      ? `${filter}_EQ`
      : filter;

  const barChartToolTipFormatter = getTooltipFormatter(filters.yAxis);
  const bubbleChartToolTipFormatter = {
    ...getTooltipFormatter(filters.xAxis),
    ...getTooltipFormatter(filters.yAxis),
    ...getTooltipFormatter(filters.bubbleSize),
  };

  const campaignDeliveryChartConfig = {
    plotOptions: {
      column: {
        minPointLength: 2,
      },
    },
    tooltip: {
      metricsList:
        filters.chartType === ChartType.BUBBLE
          ? [
              convertFilterToEquivalized(filters.xAxis),
              convertFilterToEquivalized(filters.yAxis),
              convertFilterToEquivalized(filters.bubbleSize),
            ]
          : [convertFilterToEquivalized(filters.yAxis)],
      tooltipValueFormatters: {
        ...(filters.chartType === ChartType.BUBBLE
          ? bubbleChartToolTipFormatter
          : barChartToolTipFormatter),
      },
    },
  };

  const getXAxisName = (): string =>
    filters.chartType === ChartType.BUBBLE
      ? getMetricName(filters.xAxis, conversionType, useEquivalizedMetrics)
      : breakoutOptions.find((breakout) => breakout.value === filters.breakout)
          ?.text ?? '';

  return (
    <ChartWithTable
      tableColumns={tableColumns as ITableColumns}
      tableData={tableData}
      chartSeries={chartSeries}
      xAxisName={getXAxisName()}
      yAxisName={getMetricName(
        filters.yAxis,
        conversionType,
        useEquivalizedMetrics,
      )}
      yPlotLines={yPlotLines}
      overlayElement={<Overlay />}
      showOverlay={showOverlay}
      rowRemoveClickAction={removeRecord}
      chartConfig={campaignDeliveryChartConfig}
      tableHeaderCellClickAction={handleTableHeaderCellClick}
      stickyFirstColumn
    />
  );
};

const BarChart: React.FC<
  IProps & { transformerInput: ITransformerInput; chartType: ChartType.BAR }
> = ({ transformerInput, chartType, ...props }) => {
  const { chartSeries, tableData } =
    useBarChartAndTableTransformer(transformerInput);
  return (
    <ChartWithTableWrapperComponent
      {...props}
      chartSeries={{ type: chartType, series: chartSeries }}
      tableData={tableData}
    />
  );
};

const BubbleChart: React.FC<
  IProps & { transformerInput: ITransformerInput; chartType: ChartType.BUBBLE }
> = ({ transformerInput, chartType, ...props }) => {
  const { chartSeries, tableData } =
    useBubbleChartAndTableTransformer(transformerInput);

  return (
    <ChartWithTableWrapperComponent
      {...props}
      chartSeries={{ type: chartType, series: chartSeries }}
      tableData={tableData}
    />
  );
};

const ChartWithTableWrapper: React.FC<IProps> = (props) => {
  const { filteredData, filters, chartType, conversionType, metrics } = props;

  const transformerInput = {
    filteredData,
    filters,
    metrics,
    conversionType,
  };

  if (chartType === ChartType.BAR) {
    return (
      <BarChart
        {...props}
        chartType={chartType}
        transformerInput={transformerInput as ITransformerInput}
      />
    );
  }
  if (chartType === ChartType.BUBBLE) {
    return (
      <BubbleChart
        {...props}
        chartType={chartType}
        transformerInput={transformerInput as ITransformerInput}
      />
    );
  }
  return null;
};

const mapStateToProps = (state: State): { metrics: string[] } => ({
  metrics: attributionReportResult.getAttributionResultMetrics(state),
});

export default connect(mapStateToProps, null)(ChartWithTableWrapper);
