import IReport from 'domains/reports/types';
import { fetchApi } from 'helpers/fetching';
import { Index } from 'routes';
import ApiReturn from 'types/apiReturn';
import FetchMethod from 'types/fetchMethod';
import { ApiUpdaterType } from '.';

export const handleUpdateReport: ApiUpdaterType = async (payload) => {
  const { data, meta, error } = (await fetchApi({
    endpoint: `/${Index.SEGMENT_REPORTS}`,
    method: FetchMethod.PATCH,
    payload,
  })) as ApiReturn<IReport>;
  return { reportList: data, report: meta?.changed as IReport, error };
};

export const handleCreateReport: ApiUpdaterType = async (payload) => {
  const { data, meta, error } = (await fetchApi({
    endpoint: `/${Index.SEGMENT_REPORTS}`,
    method: FetchMethod.POST,
    payload,
  })) as ApiReturn<IReport>;
  const report = data.find((r) => r.id === meta?.id);
  return { reportList: data, report, error };
};
