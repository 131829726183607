import { getTestId, getClass } from 'helpers/components';
import React, { ReactNode, FunctionComponent, ReactElement } from 'react';

// TODO: P2-129 Create box secondary theme to adscribe
// TODO: P2-130 Create box component tests

export const boxComponentName = 'box';

export enum Type {
  primary = 'primary',
  secondary = 'secondary',
}

export interface IBoxProps {
  type?: Type.primary | Type.secondary;
  testId?: string;
  children?: ReactNode;
  className?: string;
}

const Box: FunctionComponent<IBoxProps> = (props): ReactElement => {
  const { type, testId, children, className } = props;

  const boxClass = getClass(boxComponentName, {
    text: [type],
    add: [className ?? ''],
  });
  const boxTestId = getTestId(boxComponentName, testId);

  return (
    <div className={boxClass} data-testid={boxTestId}>
      {children}
    </div>
  );
};

export default Box;
