import Flex, { Horizontal, Vertical } from 'components/Flex';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import IReport, { ResultBase } from 'domains/reports/types';
import { IResultSelectionFilters } from 'domains/resultSelections/types';
import { getClass, getTestId } from 'helpers/components';
import { trackFiltersChange } from 'helpers/mixpanel';
import { getPersistedTargets } from 'helpers/resultSelection/getPersistedTargets';
import React from 'react';
import { connect } from 'react-redux';
import * as resultSelectionsActions from 'store/actions/resultSelections';
import { IOption } from 'types/textValue';
import { getPercentile } from 'helpers/visualization';
import { ChartHelper } from '../../../components/ChartHelper';
import { CAMPAIGN_EXPOSURE_IMPRESSION_FREQUENCY_TOOLTIP_TEXT } from '../../constants/tooltipText';
import { ExposedHouseholdsAndConvertersChart } from './ExposedHouseholdsAndConvertersChart';

export interface IExposedHouseholdsAndConvertersProps {
  targets: IOption[];
  chartData: ResultBase;
  sectionFiltersSelection: IResultSelectionFilters['averageFrequency'];
  lastValidReport: IReport | null;
  isVisualizationOpened: boolean;
  updateResultSelectionFilters: (
    payload: Partial<IResultSelectionFilters>,
  ) => Promise<void>;
}

const chartFiltersComponentName = 'chart-filters-wrapper';
const chartFiltersClass = getClass(chartFiltersComponentName);
const containerClass = getClass(chartFiltersComponentName, {
  concat: ['container'],
});
const headerClass = getClass(chartFiltersComponentName, {
  concat: ['header'],
});
const filtersClass = getClass(chartFiltersComponentName, {
  concat: ['filters'],
});

export const exposureHouseholdsAndConvertersComponentName =
  'exposure-households-and-converters';
const componentTitleClass = getClass(
  exposureHouseholdsAndConvertersComponentName,
  {
    concat: ['title'],
  },
);
const componentSubtitleClass = getClass(
  exposureHouseholdsAndConvertersComponentName,
  {
    concat: ['subtitle'],
  },
);
const componentSubtitleBoldClass = getClass(
  exposureHouseholdsAndConvertersComponentName,
  {
    concat: ['subtitle-bold'],
  },
);

const filtersId = getTestId(
  exposureHouseholdsAndConvertersComponentName,
  'filters-id',
);

const sectionName = 'Impression Frequency of Exposed Households and Converters';

const ExposedHouseholdsAndConverters: React.FC<
  IExposedHouseholdsAndConvertersProps
> = ({
  targets,
  chartData,
  sectionFiltersSelection,
  lastValidReport,
  isVisualizationOpened,
  updateResultSelectionFilters,
}) => {
  const [selectedTarget, setSelectedTarget] = React.useState(() =>
    getPersistedTargets(targets, [sectionFiltersSelection?.target ?? 'target']),
  );

  const onTargetChange = (selectedOptions: IOption[]): void => {
    trackFiltersChange({
      lastValidReport,
      actionType: 'CHANGE_TARGET',
      sectionName,
      selection: selectedOptions.map((target) => target.text),
    });

    setSelectedTarget(selectedOptions);

    updateResultSelectionFilters({
      averageFrequency: {
        target: selectedOptions[0].value as string,
      },
    });
  };

  const percentileItem = getPercentile(
    chartData?.exposed_all?.HH_FREQUENCY_cumulative,
    'HH_FREQUENCY_50TH_PERCENTILE',
    selectedTarget[0],
  );

  return (
    <section className={chartFiltersClass}>
      <Flex vertical={Vertical.between} className={`${containerClass} pb-1`}>
        <Flex vertical={Vertical.top} horizontal={Horizontal.between}>
          <header className={componentTitleClass}>
            <h3 className={headerClass}>{sectionName}</h3>
          </header>
          <ChartHelper
            tooltip={CAMPAIGN_EXPOSURE_IMPRESSION_FREQUENCY_TOOLTIP_TEXT}
          />
        </Flex>
      </Flex>
      <div className={`${containerClass} ${componentSubtitleClass} pt-0 pb-0`}>
        <span className={componentSubtitleBoldClass}>50%</span> of{' '}
        <span className={componentSubtitleBoldClass}>
          exposed converted households
        </span>{' '}
        had{' '}
        <span className={componentSubtitleBoldClass}>
          {percentileItem.VALUE} or fewer impressions
        </span>{' '}
        prior to conversion.
      </div>
      <div className={`${containerClass} pt-0 pb-1`}>
        {isVisualizationOpened && (
          <ExposedHouseholdsAndConvertersChart
            chartData={chartData}
            target={selectedTarget}
            percentileIndex={percentileItem.percentileIndex}
          />
        )}
      </div>
      <div className={filtersClass} data-testid={filtersId}>
        <MultiSelectDropdown
          options={targets}
          selected={selectedTarget}
          placeholder="Choose audience"
          showLegend
          onChange={onTargetChange}
          minimumChipsSelected={1}
        />
      </div>
    </section>
  );
};

const mapDispatchToProps = {
  updateResultSelectionFilters:
    resultSelectionsActions.updateResultSelectionFilters,
};

export default connect(
  null,
  mapDispatchToProps,
)(ExposedHouseholdsAndConverters);
