import {
  ResultEnhancedDataTarget,
  SegmentSetNames,
  ResultEnhancedDemographicGroups,
} from 'domains/reports/types';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { IOption } from 'types/textValue';
import { getSegmentedData } from './helpers/getSegmentedData';
import { getSegmentedDataByTargets } from './helpers/getSegmentedDataByTargets';
import { getSeriesDataFromSegmentedData } from './helpers/getSeriesDataFromSegmentedData';

export interface IDumbbellSeries {
  value: number;
  color: string;
  name: string;
  reach?: number;
  pcOfUniverse?: number;
}

export interface IDumbbellComputedSeries {
  marker: { fillColor: string; radius: number };
  x: number;
  y: number;
  name: string;
}

export interface ISegmentData {
  name: string;
  data: IDumbbellComputedSeries[];
}

export interface ITransformerInputFilters {
  demographic: string;
  targets: IOption[];
  segments: IOption[];
  multiTarget: boolean;
}

export interface ITransformerInput {
  data: ResultEnhancedDemographicGroups;
  filters: ITransformerInputFilters;
}

export type TSegmentedData = {
  [propName: string]: IDumbbellSeries[];
};

export type SegmentsDataSet = {
  [key in SegmentSetNames]?: ResultEnhancedDataTarget;
};

export const useDumbbellChartTransformer = ({
  data,
  filters,
}: ITransformerInput): ISegmentData[] => {
  const { targets, segments, multiTarget } = filters;

  const areFiltersValid = targets.length && segments.length;
  const dumbbellChartData = useMemo(() => {
    if (isEmpty(data)) {
      return [];
    }
    if (!areFiltersValid) {
      return [];
    }
    const segmentedData = multiTarget
      ? getSegmentedDataByTargets(data, filters)
      : getSegmentedData(data, filters);

    return getSeriesDataFromSegmentedData(segmentedData);
  }, [areFiltersValid, data, multiTarget, filters]);

  return dumbbellChartData;
};
