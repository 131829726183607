import Button, { Kind } from 'components/Button';
import Flex, { Horizontal } from 'components/Flex';
import { headerComponentName } from 'components/Header';
import Icon, { Color, Size, Type } from 'components/Icon';
import LogoutButton from 'components/LogoutButton';
import PopoverMenu, { Placement } from 'components/PopoverMenu';
import { getClass, getTestId } from 'helpers/components';
import useRedirect from 'hooks/useRedirect';
import React, { FunctionComponent, ReactElement } from 'react';
import { Index as Routes, Actions } from 'routes';

export const contextualUserMenuComponentName = 'contextual-user-menu';

interface IProps {
  userName?: string;
  userId?: string;
  testId?: string;
}

const USER_MENU_USER_SETTINGS = 'User Settings';

export const ContextualUserMenu: FunctionComponent<IProps> = (
  props: IProps,
): ReactElement => {
  const { testId, userName = '', userId } = props;
  const redirectTo = useRedirect();

  const goToUserSettings = (): void =>
    redirectTo(
      `/${Routes.SEGMENT_ADMIN}/${Routes.SEGMENT_USERS}/${Actions.SEGMENT_EDIT}/${userId}`,
    );

  const contextualUserMenuTestId = getTestId(
    contextualUserMenuComponentName,
    testId,
  );
  const settingsClasses = getClass(contextualUserMenuComponentName, {
    add: ['settings'],
  });

  return (
    <div data-testid={contextualUserMenuTestId}>
      <PopoverMenu
        customStickerWrapperClass={headerComponentName}
        placement={Placement.bottom}
        options={[
          {
            key: 'settings',
            option: (
              <div className={settingsClasses}>
                <Button onClick={goToUserSettings} kind={Kind.text}>
                  <Flex horizontal={Horizontal.left}>
                    <Icon
                      type={Type.user}
                      size={Size.small}
                      color={Color.dark}
                    />
                    <span>{USER_MENU_USER_SETTINGS}</span>
                  </Flex>
                </Button>
              </div>
            ),
          },
          {
            key: 'log out',
            option: <LogoutButton />,
          },
        ]}
      >
        {userName}
      </PopoverMenu>
    </div>
  );
};
