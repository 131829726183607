import ActionsBar from 'components/ActionsBar';
import Box, { Type as boxTypes } from 'components/Box';
import { Type as ButtonType, Size as ButtonSize } from 'components/Button';
import Checkbox from 'components/Checkbox';
import Checkboxes from 'components/Checkbox/Checkboxes';
import Field from 'components/Field';
import FieldWrapper, {
  sizes as fileWrapperSizes,
} from 'components/Field/Wrapper';
import Input, {
  Type as InputType,
  NewInputValue,
  Type,
} from 'components/Input';
import Label from 'components/Label';
import IPermission from 'domains/permissions/types';
import { getId, getTestId, getClass } from 'helpers/components';
import { escapeRegExp } from 'lodash';
import React, {
  useState,
  FunctionComponent,
  ReactElement,
  useRef,
  useEffect,
} from 'react';
import IRole from 'types/role';

const LOCALE_FIELD_NAME_LABEL = 'Name';
const LOCALE_FIELD_PERMISSIONS_LABEL = 'Permissions';
const LOCALE_ACTION_OK = 'Save';
const LOCALE_ACTION_CANCEL = 'Cancel';
const LOCALE_ACTION_OK_LOADING = 'Saving...';
const filterPlaceholder = 'Search for permissions';
export const formComponentName = 'role-form';

interface CheckedPermissions {
  [permission: string]: boolean;
}

export interface IFormProps {
  testId?: string;
  role: IRole;
  permissions: IPermission[];
  loading?: boolean;
  onSubmit: (name: string, permissions: string[]) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<IFormProps> = (props): ReactElement => {
  const { testId, loading, onSubmit, onCancel, permissions, role } = props;

  const getDefaultCheckedPermissionsState = (): CheckedPermissions => {
    const defaultState: CheckedPermissions = {};

    if (role?.permissions?.length === 0) {
      return defaultState;
    }

    role?.permissions?.forEach((rolePermission: string) => {
      permissions.forEach((permission) => {
        if (permission.id !== rolePermission) {
          defaultState[rolePermission] = false;
        }

        defaultState[rolePermission] = true;
      });
    });

    return defaultState;
  };

  const defaultCheckedPermissionsState = getDefaultCheckedPermissionsState();

  const [name, setName] = useState(role.name);
  const [searchString, setSearchString] = useState('');
  const [filteredData, setFilteredData] = useState(permissions);
  const [checkedPermissionsState, setCheckedPermissionsState] = useState(
    defaultCheckedPermissionsState,
  );
  const previousSearchString = useRef('');

  useEffect(() => {
    if (searchString !== previousSearchString.current) {
      previousSearchString.current = searchString;
      const regExp = new RegExp(escapeRegExp(searchString), 'i');
      const currentFilteredData = permissions.filter((item) =>
        Object.entries(item)
          .filter(([key]) => ['name', 'description'].includes(key))
          .some(([, value]) => regExp.test(value as string)),
      );
      setFilteredData(currentFilteredData);
    } else {
      setFilteredData(permissions);
    }
  }, [searchString, permissions]);

  const getCheckedPermissionState = (permission: string): boolean => {
    if (!checkedPermissionsState[permission]) return false;
    return checkedPermissionsState[permission];
  };

  const handlePermissionChange = (
    newCheckState: NewInputValue,
    permission: string,
  ): void => {
    setCheckedPermissionsState({
      ...checkedPermissionsState,
      [permission]: newCheckState as boolean,
    });
  };

  const handleNameChange = (newName: NewInputValue): void =>
    setName(newName as string);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit(
      name,
      Object.keys(checkedPermissionsState).filter(
        (item) => checkedPermissionsState[item],
      ),
    );
  };

  const handleChange = (value: NewInputValue): void => {
    setSearchString(value as string);
  };

  const formId = getId(formComponentName);
  const formTestId = getTestId(formComponentName, testId);
  const formDefaultClass = getClass(formComponentName);
  const hasPermissions = permissions && permissions.length > 0;

  return (
    <section className={formDefaultClass} data-testid={formTestId}>
      <Box type={boxTypes.primary}>
        <form onSubmit={handleSubmit} data-testid={`${formTestId}-form`}>
          <Field
            label={LOCALE_FIELD_NAME_LABEL}
            type={InputType.text}
            id={`${formId}-name`}
            name={`${formId}-input`}
            onChange={handleNameChange}
            value={name}
          />

          {hasPermissions && (
            <FieldWrapper size={fileWrapperSizes.auto}>
              <Label text={LOCALE_FIELD_PERMISSIONS_LABEL} />
              <div className={`${formDefaultClass}-search`}>
                <Input
                  type={Type.search}
                  value={searchString}
                  placeholder={filterPlaceholder}
                  onChange={handleChange}
                  testId={`${formId}-search`}
                />
              </div>
              <Checkboxes>
                {filteredData?.sort().map((permission) => (
                  <li
                    key={permission.id}
                    className={`${formDefaultClass}-permission`}
                  >
                    <Checkbox
                      testId={`${formTestId}-permission-${permission}`}
                      checked={getCheckedPermissionState(permission.id)}
                      id={permission.id}
                      label={permission.description}
                      onChange={(newCheckState: NewInputValue): void =>
                        handlePermissionChange(newCheckState, permission.id)
                      }
                    />
                    <small className={`${formDefaultClass}-permission-subText`}>
                      {permission.name}
                    </small>
                  </li>
                ))}
              </Checkboxes>
            </FieldWrapper>
          )}
          <ActionsBar
            cancel
            cancelLabel={LOCALE_ACTION_CANCEL}
            disabledOK={loading}
            margin
            ok
            okLabel={loading ? LOCALE_ACTION_OK_LOADING : LOCALE_ACTION_OK}
            okSize={ButtonSize.medium}
            okType={ButtonType.submit}
            onCancel={onCancel}
          />
        </form>
      </Box>
    </section>
  );
};

export default Form;
