import IResultSelection from 'domains/resultSelections/types';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';

const initialState = {};

const reducer = (
  state: IResultSelection = initialState,
  action: Action<IResultSelection>,
): IResultSelection => {
  switch (action.type) {
    case ActionType.RESULT_SELECTION_SET_RESULT_SELECTION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
