export const FILE_TYPE_LABEL = 'File Type';

export const DATASET_NAME_LABEL = 'Dataset name';
export const DATASET_NAME_PLACEHOLDER = 'Crosswalk combination name';
export const DATASET_NAME_REQUIRED_ERROR_MESSAGE =
  'The name field is required.';
export const DATASET_NAME_UNIQUE_ERROR_MESSAGE =
  'There is an existing crosswalk combination with the same name. Please update.';

export const DATASET_ID_LABEL = 'Dataset ID';

export const CLIENT_LABEL = 'Clients with access';

export const CROSSWALK_LABEL = 'Crosswalk';
export const CROSSWALK_REQUIRED_ERROR_MESSAGE =
  'The crosswalk value is mandatory. Please select an option to proceed.';

export const UNIVERSE_LABEL = 'Universe';
export const UNIVERSE_REQUIRED_ERROR_MESSAGE =
  'The universe value is mandatory. Please select an option to proceed.';

export const DESCRIPTION_LABEL = 'Description';
export const DESCRIPTION_PLACEHOLDER = 'Add a description';

export const CANCEL_BUTTON_LABEL = 'Cancel';
export const SAVE_BUTTON_LABEL = 'Save';

export const SAVE_ERROR_MESSAGE = 'Unable to save the dataset';
export const SAVE_SUCCESS_MESSAGE = 'Dataset saved';

export const DELETE_CROSSWALK_COMBINATION_WARNING_MESSAGE =
  'Deleting this crosswalk combination is irreversible and will affect any Advanced Audience or Report that uses the crosswalk combination. Do you want proceed?';
