import Highcharts from 'highcharts';
import { merge } from 'lodash';

export interface ITooltipValueFormatters {
  [key: string]: (
    value: number | null | undefined | string,
    additionalValue?: number | null | undefined | string,
  ) => string;
}

export interface IChartConfig extends Partial<Highcharts.Options> {
  tooltip?: Highcharts.TooltipOptions & {
    metricsList?: string[];
    tooltipValueFormatters?: ITooltipValueFormatters;
  };
}

const chartLabelsStyle = {
  color: '#000',
  'font-weight': 400,
  'font-size': '12px',
};

const chartAxisTitleStyle = {
  color: '#000',
  'font-weight': 700,
  'font-size': '12px',
  'font-family': 'SourceSansProBold',
};

const dataLabelsStyle = {
  color: '#000',
  textOutline: 'none',
  fontSize: '12px',
  fontFamily: 'SourceSansProBold',
};

const exportingStyle = {
  'font-family': 'Verdana',
};

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export const commonChartDesignConfig: Highcharts.Options = {
  chart: {
    plotBorderWidth: 1,
    plotBorderColor: '#E0E0E0',
    style: {
      'font-family': 'SourceSansProLight',
    },
  },
  title: {
    text: undefined,
  },
  credits: {
    enabled: false,
  },
  legend: { enabled: false },
  tooltip: {
    shared: false,
    useHTML: true,
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: '2',
    shadow: false,
    hideDelay: 0,
    padding: 0,
  },
  xAxis: {
    lineWidth: 0,
    labels: {
      style: chartLabelsStyle,
    },
    title: {
      style: chartAxisTitleStyle,
    },
  },
  yAxis: {
    labels: {
      style: chartLabelsStyle,
    },
    title: {
      style: chartAxisTitleStyle,
    },
  },
  exporting: {
    sourceWidth: 1920,
    sourceHeight: 1080,
    scale: 1,
    chartOptions: {
      chart: { style: exportingStyle },
      yAxis: [
        {
          title: { style: exportingStyle },
          labels: { style: exportingStyle },
        },
        {
          title: { style: exportingStyle },
          labels: { style: exportingStyle },
        },
      ],
      xAxis: {
        title: { style: exportingStyle },
      },
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
        style: dataLabelsStyle,
      },
    },
  },
};

const createChartConfigWithCommonDesign = (
  ...configs: IChartConfig[]
): IChartConfig => merge({}, commonChartDesignConfig, ...configs);

export default createChartConfigWithCommonDesign;
