import Button, { Kind, Size } from 'components/Button';
import Flex, { Horizontal } from 'components/Flex';
import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import Input, { Type, NewInputValue } from 'components/Input';
import SelectedChips from 'components/SelectedChips';
import { getTestId, getClass } from 'helpers/components';
import { trackReportInputChange } from 'helpers/mixpanel';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import React, { ReactElement, useState, useRef } from 'react';
import { RuleFilter } from 'types/filter';
import ITextValue from 'types/textValue';

export const componentName = 'multiple-text-select';

const SELECTOR_DOM_KEY = 'selector';
const INPUT_WRAPPER_DOM_KEY = 'input-wrapper';
const SELECTED_CHIPS_WRAPPER_DOM_KEY = 'selected-chips-wrapper';
const LOCALE_ADD_BUTTON_NAME = 'Add';

interface IProps {
  selected?: string[];
  onChange: (selected: string[]) => void;
  testId?: string;
  trackingId?: string;
  trackingRuleContext?: {
    filter: RuleFilter;
    index: number;
    parentRuleTracking?: RuleFilter;
  };
  disabled?: boolean;
  errorText?: string;
  isReportInvalid?: boolean;
}

const MultipleTextSelect = (props: IProps): ReactElement => {
  const {
    selected = [],
    onChange,
    testId,
    trackingId,
    trackingRuleContext,
    disabled = false,
    errorText,
    isReportInvalid,
  } = props;

  const [inputValue, setInputValue] = useState('');

  const componentTestId = getTestId(componentName, testId);
  const componentClass = getClass(componentName);
  const componentSelectorClass = getClass(componentName, {
    concat: [SELECTOR_DOM_KEY],
  });
  const componentInputWrapperClass = getClass(componentName, {
    concat: [INPUT_WRAPPER_DOM_KEY],
  });
  const componentSelectedChipsWrapperClass = getClass(componentName, {
    concat: [SELECTED_CHIPS_WRAPPER_DOM_KEY],
  });

  const wrapperRef = useRef<HTMLInputElement>(null);

  useOnOutsideClick(wrapperRef, () => {
    setInputValue('');
  });

  const handleInputValueChange = (text: NewInputValue): void => {
    setInputValue(text as string);
  };

  const handleAddClick = (): void => {
    const parsedInputValues = inputValue
      .split(',')
      .map((value) => value.trim())
      .filter((value) => value !== '');
    const newSelected = new Set([...selected, ...parsedInputValues]);
    if (trackingId)
      trackReportInputChange(
        trackingId,
        parsedInputValues[0],
        '',
        'changed',
        trackingRuleContext,
      );
    onChange(Array.from(newSelected));
    setInputValue('');
  };

  const handleChange = (newSelected: (string | ITextValue)[]): void => {
    onChange(newSelected as string[]);
  };

  return (
    <div data-testid={componentTestId} className={componentClass}>
      <Flex horizontal={Horizontal.left}>
        <div className={componentSelectorClass}>
          <div className={componentInputWrapperClass} ref={wrapperRef}>
            <Flex>
              <Input
                testId={componentTestId}
                type={Type.text}
                value={inputValue}
                onEnterKeyDown={handleAddClick}
                onChange={handleInputValueChange}
                disabled={disabled}
                hasError={!!errorText && isReportInvalid}
              />
              <Button
                testId={componentTestId}
                kind={Kind.linkPrimary}
                size={Size.small}
                onClick={handleAddClick}
                disabled={disabled || !inputValue}
              >
                {LOCALE_ADD_BUTTON_NAME}
              </Button>
            </Flex>
          </div>
          {errorText && isReportInvalid && (
            <InlineAlert
              mode={AlertTypes.error}
              message={errorText}
              hideClose
            />
          )}
          <div className={componentSelectedChipsWrapperClass}>
            {selected.length > 0 && (
              <SelectedChips
                testId={componentTestId}
                onChange={handleChange}
                selected={selected}
                disabled={disabled}
              />
            )}
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default MultipleTextSelect;
