import ITextValue, { ITextValueGroup, ITextValueObject } from 'types/textValue';

export const findGroupValuesByPath = (
  path: string,
  object: ITextValueObject,
): ITextValueObject => {
  if (!path) return object;
  const [searchedGroup, ...rest] = path?.split('|') || [];
  let foundGroup = object.filter(
    ({ group }) => group === searchedGroup,
  )[0] as ITextValueGroup;
  if (!foundGroup) {
    foundGroup = { group: searchedGroup, values: [] };
    object.push(foundGroup);
  }
  return findGroupValuesByPath(
    rest.join('|'),
    foundGroup?.values || ([] as ITextValueObject),
  );
};

export const getOptionsObject = (
  options: (ITextValue | string)[],
  ignoreGrouping?: boolean,
): ITextValueObject => {
  const result: ITextValueObject = [];
  options.forEach((item) => {
    if (typeof item === 'string') {
      result.push({ value: item, text: item });
      return;
    }
    const { value, text, group, icon, tooltip, isUnmatched } = item;

    const newItem: ITextValue = { value, text };
    if (icon) newItem.icon = icon;
    if (tooltip) newItem.tooltip = tooltip;
    if (isUnmatched !== undefined) newItem.isUnmatched = isUnmatched;

    if (!ignoreGrouping && group) {
      findGroupValuesByPath(group, result);
      const foundGroup = findGroupValuesByPath(group, result);
      foundGroup.push(newItem);
    } else {
      result.push(newItem);
    }
  });
  return result;
};

export const setDefaultExpandedForGroupByPath = (
  path: string,
  object: ITextValueObject,
): void => {
  if (!path) return;
  const [searchedGroup, ...rest] = path?.split('|') || [];
  const foundGroup = object.filter(
    ({ group }) => group === searchedGroup,
  )[0] as ITextValueGroup;
  foundGroup.expanded = true;
  setDefaultExpandedForGroupByPath(rest.join('|'), foundGroup?.values);
};

export const setDefaultExpandedForArrayOfGroups = (
  optionsTree: ITextValueObject,
  expandedGroups?: string[],
): ITextValueObject => {
  if (!expandedGroups?.length) return optionsTree;
  const newOptionsTree = [...optionsTree];
  expandedGroups.forEach((group) =>
    setDefaultExpandedForGroupByPath(group, newOptionsTree),
  );
  return newOptionsTree;
};

export default getOptionsObject;
