import DatePicker from 'components/DatePicker';
import { Anchor } from 'components/DatePicker/interface';
import Flex, { Horizontal, Vertical } from 'components/Flex';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import {
  LOCALE_DATE_RANGE_LABEL,
  LOCALE_DATE_RANGE_LABEL_TOOLTIP,
  PERFORMANCE_METRICS_LABEL_LOCALE,
} from 'components/QueryBuilder/locale';
import { Style } from 'components/Sticker';
import { Tooltip } from 'components/Tooltip';
import { PerformanceMetricToolTips } from 'features/attributionReports/components/AttributionQueryBuilder/locales';
import { getClass, getTestId } from 'helpers/components';
import {
  performanceMetricsToTextValue,
  performanceMetricsToTextValueToStrList,
} from 'helpers/types';
import { find } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as reportActions from 'store/actions/report';
import * as reportSelectors from 'store/selectors/report';
import IDateRange from 'types/dateRange';
import {
  FilterType,
  AllFilterTypes,
  FilterValue,
  IQueryFiltersChildren,
  RuleFilter,
} from 'types/filter';
import State from 'types/state';
import ITextValue from 'types/textValue';

export const componentName = 'generic-conversions-rules-builder-item';
const componentClass = getClass(componentName);

export interface GenericConversionRulesItemProps {
  mainFilter?: RuleFilter;
  minDate?: string;
  maxDate?: string;
  mainFilterIndex: number;
  performanceMetricGroups: string[];
  queryPerformanceMetricGroups: string[];
  setQueryPerformanceMetricGroups: (performanceMetricGroups: string[]) => void;
  setQueryConversionType: (conversionType?: string) => void;
  onChange: (filter: RuleFilter, index: number) => void;
  disabled?: boolean;
  conversionType: string;
}

const componentTestId = getTestId(componentName);

const GenericConversionRulesItem = (
  props: GenericConversionRulesItemProps,
): JSX.Element => {
  const {
    mainFilter,
    minDate,
    maxDate,
    mainFilterIndex,
    performanceMetricGroups,
    queryPerformanceMetricGroups,
    setQueryPerformanceMetricGroups,
    onChange,
    disabled,
    setQueryConversionType,
    conversionType,
  } = props;

  const dateFilter = find(
    mainFilter?.children as IQueryFiltersChildren,
    ({ type }: { type: AllFilterTypes }) => type === FilterType.DATETIME_RANGE,
  ) as IDateRange;
  const [startDate, setStartDate] = useState(dateFilter?.start);
  const [endDate, setEndDate] = useState(dateFilter?.end);

  const handleDateRangeChange = (start: string, end: string): void => {
    setStartDate(start);
    setEndDate(end);
  };
  const buildQueryData = useCallback((): Record<string, unknown> => {
    const dateTimeFilter = [
      {
        type: 'DATETIME_RANGE',
        start: startDate,
        end: endDate,
      } as RuleFilter,
    ];

    const children = [...dateTimeFilter];

    const filter = {
      ...mainFilter,
      type: 'GENERIC_EVENTS_DATASET',
      id: mainFilter?.id,
      value: children as FilterValue,
      children: children as FilterValue,
      logical_operator: 'AND',
      operator: 'IN',
      filterId: mainFilter?.filterId ?? '',
    } as RuleFilter;

    onChange(filter, mainFilterIndex);

    return filter;
  }, [startDate, endDate, mainFilter, onChange, mainFilterIndex]);

  const curatedPerformanceMetricGroup: ITextValue[] =
    performanceMetricsToTextValue(
      performanceMetricGroups,
      conversionType,
      PerformanceMetricToolTips,
    );
  const curatedSelectedPerformanceMetrics: ITextValue[] =
    performanceMetricsToTextValue(
      queryPerformanceMetricGroups,
      conversionType,
      PerformanceMetricToolTips,
    );

  const handlePerformanceMetricChange = (selected: ITextValue[]): void => {
    setQueryPerformanceMetricGroups(
      performanceMetricsToTextValueToStrList(selected),
    );
    setQueryConversionType();
    if (selected.find(({ value }) => value === 'conversions')) {
      setQueryConversionType(conversionType);
    }
  };

  useEffect(() => {
    if (startDate && endDate) buildQueryData();
  }, [startDate, endDate]);

  // Reset startDate && endDate every time a dataset changes
  useEffect(() => {
    if (!dateFilter && minDate && maxDate) {
      setStartDate(minDate);
      setEndDate(maxDate);
    }
  }, [minDate, maxDate, dateFilter]);

  return (
    <div
      data-testid={componentTestId}
      className={componentClass}
      style={{
        border: '1px solid #ccc',
        borderRadius: 4,
        padding: 10,
        marginTop: 10,
        width: '95%',
      }}
    >
      <Flex
        horizontal={Horizontal.left}
        vertical={Vertical.top}
        style={{ minHeight: 100 }}
      >
        <Tooltip style={Style.tertiary} content="">
          <label style={{ marginLeft: 20, marginTop: 10 }}>
            {PERFORMANCE_METRICS_LABEL_LOCALE}
          </label>
        </Tooltip>
        <MultipleDropdownSelect
          placeholder="add a metric"
          options={curatedPerformanceMetricGroup}
          onChange={handlePerformanceMetricChange}
          selected={curatedSelectedPerformanceMetrics}
          minimumChipsSelected={1}
          disabled={disabled}
          canReorder={false}
        />
      </Flex>

      <Flex
        horizontal={Horizontal.left}
        vertical={Vertical.top}
        style={{ marginTop: 20 }}
      >
        <Tooltip
          style={Style.tertiary}
          content={LOCALE_DATE_RANGE_LABEL_TOOLTIP}
        >
          <label style={{ marginLeft: 20, marginTop: 10 }}>
            {LOCALE_DATE_RANGE_LABEL}
          </label>
        </Tooltip>
        <DatePicker
          anchor={Anchor.right}
          endDate={endDate}
          startDate={startDate}
          testId="genericInput"
          id="geneircInput"
          handleChange={handleDateRangeChange}
          trackingId={`Compound Generic Rule - ${mainFilter?.field}`}
          showNumOfDays
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
        />
      </Flex>
    </div>
  );
};

interface MappedProps {
  minDate: string;
  maxDate: string;
  performanceMetricGroups: string[];
  queryPerformanceMetricGroups: string[];
  conversionType: string;
}

const mapStateToProps = (state: State): MappedProps => ({
  minDate: reportSelectors.getGenericConversionsMinDate(state),
  maxDate: reportSelectors.getGenericConversionsMaxDate(state),
  conversionType: reportSelectors.getConversionType(state),
  performanceMetricGroups: reportSelectors.getPerformanceMetricGroups(
    state,
  ) ?? ['converters'],
  queryPerformanceMetricGroups:
    reportSelectors.getPerformanceMetricGroupsFromQuery(state) ?? [
      'converters',
    ],
});

const mapDispatchToProps = {
  setQueryPerformanceMetricGroups:
    reportActions.setQueryPerformanceMetricGroups,
  setQueryConversionType: reportActions.setQueryConversionType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericConversionRulesItem);
