import { LegendOptions, TimeOptions } from 'highcharts';
import { TopLevelCondition } from 'json-rules-engine';
import { get } from 'lodash/fp';
import { ChartTypeBasic } from '../../../components/Chart/helpers';
import { IChildrenBase, IQueryFilters } from '../../../types/filter';
import {
  IQuery,
  IQueryAAPI,
  IReportQueryInterval,
  IReportSorting,
  Modes,
} from '../../../types/query';
import ITextValue from '../../../types/textValue';
import { IDataset } from '../../datasets/types';
import IDimension from '../../dimensions/types';
import IMetric, { Metric } from '../../metrics/types';
import { IPermissionTree } from '../../permissions/types';
import { daysOfWeek, optionAxisTypes, stackings } from '../constants';

interface IUndigestedDateRange {
  date_preset?: number;
  date_preset_offset?: number;
  end: string;
  endString?: string;
  id?: string;
  start: string;
  startString?: string;
  type?: 'DATETIME_RANGE';
  isRule?: boolean;
}

interface IDateRange extends IUndigestedDateRange {
  filterId?: string;
}

export interface IDateRangeQueryFilter {
  startDate: string;
  endDate: string;
  dateTimeRange: IDateRange;
  relativeDateOffset?: string;
}

export type IColumn = {
  colspan?: number;
  label?: string;
};

export enum cacheMode {
  default = 'default',
  always = 'always',
  force = 'force',
}

export type CacheMode = 'default' | 'always' | 'force';

export const reportType = {
  report: 'report',
  target: 'target',
  attributionReport: 'attribution-report',
} as const;

export const rulesWhiteList = ['BRAND_LABEL', 'NETWORK', 'CONTENT_SERIES'];

export const reportTypeEquivalences = (type?: string): string => {
  switch (type) {
    case reportType.attributionReport:
      return 'attribution_reports';
    case reportType.report:
      return 'platf0rm_reports';
    case reportType.target:
      return 'targets';
    default:
      return 'unknown';
  }
};

export type ReportType = (typeof reportType)[keyof typeof reportType];

export const HH_COUNT_KEY = 'Crosswalk HH Count';
export type Summary = {
  time_frame: string;
  rules: { name: string; operator: string; values: string[] }[];
  universe: string;
  [HH_COUNT_KEY]: string;
};

export const AggregationMethodOptions = {
  NAIVE: 'naive',
  ADVANCED: 'advanced',
} as const;

export type AggregationMethod =
  (typeof AggregationMethodOptions)[keyof typeof AggregationMethodOptions];

export enum SubTypeKeys {
  TV_TUNE_IN = 'TV_TUNE_IN',
  BRAND_LIFT = 'BRAND_LIFT',
  PLATFORM = 'AD_HOC',
  AUDIENCE_INSIGHTS = 'AUDIENCE_INSIGHTS',
}

export const reportSubType: IkeySubType = {
  tvTuneIn: {
    key: SubTypeKeys.TV_TUNE_IN,
    name: 'Tune-In',
    segment: 'tune-in',
  },
  outcome: {
    key: SubTypeKeys.BRAND_LIFT,
    name: 'Outcome',
    segment: 'outcome',
  },
  platform: {
    key: SubTypeKeys.PLATFORM,
    name: 'Standard Report',
    segment: 'standard',
  },
  audienceInsights: {
    key: SubTypeKeys.AUDIENCE_INSIGHTS,
    name: 'Audience Insights',
    segment: 'audience-insights',
  },
} as const;

export const UNSAVED_REPORT_ID = 'Unsaved Report';

export type IkeySubType = {
  [key: string]: ISubType;
};

export interface ISubType {
  key: SubTypeKeys;
  name: string;
  segment: string;
}

export const AttributionFilterType = {
  adDetails: 'exposures_ads',
  brandLift: 'outcomes_brandlift',
  viewership: 'outcomes_viewership',
  adhoc: 'adhoc',
} as const;

export type AttributionFilterTypes =
  (typeof AttributionFilterType)[keyof typeof AttributionFilterType];

export interface ISortOption {
  id: string;
  name: string;
}

export interface INotPersistedProps {
  mode?: string;

  [key: string]:
    | boolean
    | number
    | string
    | undefined
    | IBusinessData
    | string[];
}

export interface IBaseReport {
  _id?: string;
  cache_mode?: CacheMode;
  client?: string;
  createdAt?: string;
  createdBy: string;
  datasets?: string[];
  group?: string[];
  id: string;
  isPublic?: boolean;
  isReadOnly?: boolean;
  isOwnReport?: boolean;
  name?: string;
  rowLimit?: number;
  selectedCharts?: string[];
  selectedMetrics?: string[];
  selected_metric?: string;
  selectedTargets?: string[];
  sort_field?: ISortOption;
  sort_order?: ISortOption;
  sorting?: {
    field: string;
    order: string;
  };
  subType?: string;
  type?: ReportType;
  updatedAt?: string;
  updatedBy?: string;
  use_async?: boolean | null;
  use_equivalized_metrics?: boolean;
  aggregation_method?: AggregationMethod;
  doConfirmQuery?: boolean;
  notPersistedProps?: INotPersistedProps;
  cacheQueryId?: string;
  clientId?: string;
  persistedReport?: boolean;
  iSpotIngestClient?: string;
}

export interface IBaseReportAAPI {
  _id?: string;
  cache_mode?: CacheMode;
  client?: string;
  createdAt?: string;
  createdBy: string;
  datasets?: IDataset[];
  group?: string[];
  id: string;
  isPublic?: boolean;
  isReadOnly?: boolean;
  isOwnReport?: boolean;
  name?: string;
  rowLimit?: number;
  selectedCharts?: string[];
  selectedMetrics?: string[];
  selected_metric?: string;
  selectedTargets?: string[];
  sort_field?: ISortOption;
  sort_order?: ISortOption;
  sorting?: {
    field: string;
    order: string;
  };
  subType?: string;
  type?: ReportType;
  updatedAt?: string;
  updatedBy?: string;
  use_async?: boolean | null;
  aggregation_method?: AggregationMethod;
  doConfirmQuery?: boolean;
  notPersistedProps?: INotPersistedProps;
}

interface IReport extends IBaseReport {
  query: IQuery;
}

export default IReport;

export interface IReportAAPI extends IBaseReportAAPI {
  query: IQueryAAPI;
}

type StickyColumnConfig = {
  Header: string;
  accessor: string;
  sticky?: string;
};

export interface ITableConfig {
  unlinkedHeaders?: IColumn[][] | undefined;
  headers: StickyColumnConfig[];
  data: DataRowGraph[];
}

export interface IChartData {
  interval: IMetric | {};
  dimensions: string[] | [];
  result: DataRow[];
  metadata: IMetaData[];
  data?: DataRowGraph[];
  targetData?: IDataTarget;
  select: Metric[];
  spatialDimensions: string[] | [];
  SGMConfig: ISGMConfig;
  rowsDropped: number | RowsDropped;
  dateRange?: IDateRangeQueryFilter;
  tableVisible?: boolean;
  chartVisible?: boolean;
}

export interface IMetaData {
  description: string;
  display: boolean;
  name: string; // metric
  short_name?: string; // metric
  id: string; // metric
  type: string;
  LABEL?: string;
  accessor?: string;
}

export type DataTargetValue = {
  name: string;
  value: number;
};

export type TargetBreakoutValue = {
  display_name: string;
  data: DataTargetValue[];
  rows_dropped?: number;
};

export type DynamicBreakouts = {
  id: string;
  name: string;
}[];

export type DataTargetProp = {
  [key: string]: TargetBreakoutValue;
};

export interface IDataTarget {
  genpop?: DataTargetProp;
  target?: DataTargetProp;
}

export enum ReportJobExecutionStatuses {
  COMPLETED = 'completed',
  EXECUTING = 'executing',
  CANCELLED = 'cancelled',
  ERRORED = 'errored',
}

export interface IReportJobStatusResponse {
  id: string;
  status: ReportJobExecutionStatuses;
  report_hash: string;
  progress: { [key: string]: number };
  error: string;
  created: string;
  total_progress: number;
}

export interface IReportJobAttributionParams {
  query: IQuery | IQuery<IQueryFilters, IDataset[]>;
  sub_type: string;
  aggregation_method?: string;
}

export interface IReportJobStartPostBody {
  cache: string;
  client: string;
  created_by: string;
  created_on: string;
  name: string;
  report_id: string;
  report_params: IReportJobAttributionParams;
  report_type: string;
  user_id: string;
  feature_flags: IPermissionTree;
}

export interface IReportJobStartResponse {
  report_resource_id: string;
}

export interface IReportBusinessDataResponse {
  businessData: IBusinessData;
}

export interface IQueryResultBase {
  created: number;
  elapsed_api: number;
  executed_at: number;
  query_id: string;
  rows: number;
  rows_dropped: number | RowsDropped;
}

export interface IQueryResult extends IQueryResultBase {
  data: DataRowGraph[];
  targetData: IDataTarget;
  metadata: IMetaData[];
  result: DataRow[];
  status?: string;
  complexity?: {
    num_schedule_rows: number;
  };
  summary?: Summary;
}

export interface IReportJobCompleteResponse extends IQueryResultBase {
  client: string;
  status: number;
  created: number;
  result_misc: ResultBase;
  result_demographic_stats: ResultBase;
  result_breakouts: ResultBase;
  vendor_source_name?: string;
  rows_dropped: number | RowsDropped;
  demographic_breakouts: DynamicBreakouts;
  breakouts: DynamicBreakouts;
}

export interface IExecuteResponse {
  status: string;
  client?: string;
  result?: IQueryResult;
  query_ids?: string[];
  query_id?: string;
  complexity?: {
    num_schedule_rows: number;
  };
}

export interface IExecuteStatusResponse {
  err?: string;
  id: string;
  progress: number;
}

export enum ReportTimeDimension {
  ALL_VIEWING = 'ALL_VIEWING',
  QUARTER_HOUR = 'QUARTER_HOUR',
  HALF_HOUR = 'HALF_HOUR',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export const ReportTimeDimensions = [
  ReportTimeDimension.QUARTER_HOUR,
  ReportTimeDimension.HALF_HOUR,
  ReportTimeDimension.HOUR,
  ReportTimeDimension.DAY,
  ReportTimeDimension.WEEK,
  ReportTimeDimension.MONTH,
  ReportTimeDimension.QUARTER,
  ReportTimeDimension.YEAR,
];

export type IKeyString = {
  [key: string]: string;
};

export const ChartTypes = {
  table: 'table',
  bar: 'bar',
  column: 'column',
  line: 'line',
  area: 'area',
  pie: 'pie',
  map: 'map',
  areaspline: 'areaspline',
  singleStat: 'singleStat',
  barStacked: 'barStacked',
  columnStacked: 'columnStacked',
  lineStacked: 'lineStacked',
  areaStacked: 'areaStacked',
  barPercentageStacked: 'barPercentageStacked',
  columnPercentageStacked: 'columnPercentageStacked',
  linePercentageStacked: 'linePercentageStacked',
  areaPercentageStacked: 'areaPercentageStacked',
  heatmap: 'heatmap',
  packedbubble: 'packedbubble',
} as const;

export enum EChartTypes {
  table = 'table',
  bar = 'bar',
  column = 'column',
  line = 'line',
  area = 'area',
  pie = 'pie',
  map = 'map',
  areaspline = 'areaspline',
  singleStat = 'singleStat',
  barStacked = 'barStacked',
  columnStacked = 'columnStacked',
  lineStacked = 'lineStacked',
  areaStacked = 'areaStacked',
  barPercentageStacked = 'barPercentageStacked',
  columnPercentageStacked = 'columnPercentageStacked',
  linePercentageStacked = 'linePercentageStacked',
  areaPercentageStacked = 'areaPercentageStacked',
  heatmap = 'heatmap',
  packedbubble = 'packedbubble',
}

export const ReportDashboardTypes = {
  chart: 'chart',
  table: 'table',
  singleStat: 'singleStat',
} as const;

export type ChartType = (typeof ChartTypes)[keyof typeof ChartTypes] | string;

export type BusinessRules = {
  [key: string]: boolean;
};

export const PercentageStackedChartTypes = {
  barPercentageStacked: ChartTypes.barPercentageStacked,
  columnPercentageStacked: ChartTypes.columnPercentageStacked,
  linePercentageStacked: ChartTypes.linePercentageStacked,
  areaPercentageStacked: ChartTypes.areaPercentageStacked,
};

export type PercentageStackedChartType =
  (typeof PercentageStackedChartTypes)[keyof typeof PercentageStackedChartTypes];

export const PercentageStackedChartTypeList = [
  PercentageStackedChartTypes.barPercentageStacked,
  PercentageStackedChartTypes.areaPercentageStacked,
  PercentageStackedChartTypes.columnPercentageStacked,
  PercentageStackedChartTypes.linePercentageStacked,
];

export const StackedChartTypes = {
  barStacked: ChartTypes.barStacked,
  columnStacked: ChartTypes.columnStacked,
  lineStacked: ChartTypes.lineStacked,
  areaStacked: ChartTypes.areaStacked,
};

export type StackedChartType =
  (typeof StackedChartTypes)[keyof typeof StackedChartTypes];

export const StackedChartTypeList = [
  StackedChartTypes.areaStacked,
  StackedChartTypes.barStacked,
  StackedChartTypes.columnStacked,
  StackedChartTypes.lineStacked,
];

export const chartTypeIsStacking = (chartType: ChartType): boolean =>
  StackedChartTypeList.includes(chartType as StackedChartType);

export const chartTypeIsStackingPercent = (chartType: ChartType): boolean =>
  PercentageStackedChartTypeList.includes(
    chartType as PercentageStackedChartType,
  );

export const getStackType = (chartType: ChartType): string => {
  if (chartTypeIsStackingPercent(chartType as PercentageStackedChartType)) {
    return 'percent';
  }
  if (chartTypeIsStacking(chartType as StackedChartType)) {
    return 'normal';
  }
  return '';
};

export const defaultChartTypes = [
  ChartTypes.bar,
  ChartTypes.column,
  ChartTypes.line,
  ChartTypes.area,
  ChartTypes.areaspline,
  ChartTypes.barStacked,
  ChartTypes.columnStacked,
  ChartTypes.lineStacked,
  ChartTypes.areaStacked,
  ChartTypes.barPercentageStacked,
  ChartTypes.columnPercentageStacked,
  ChartTypes.linePercentageStacked,
  ChartTypes.areaPercentageStacked,
  ChartTypes.heatmap,
  ChartTypes.packedbubble,
];

export const reportDashoboardTypes = [
  ReportDashboardTypes.chart,
  ReportDashboardTypes.table,
  ReportDashboardTypes.singleStat,
];

export const defaultDasbhoardChartTypes = [
  ChartTypes.table,
  ChartTypes.bar,
  ChartTypes.column,
  ChartTypes.line,
  ChartTypes.area,
  ChartTypes.areaspline,
  ChartTypes.singleStat,
  ChartTypes.barStacked,
  ChartTypes.columnStacked,
  ChartTypes.lineStacked,
  ChartTypes.areaStacked,
  ChartTypes.barPercentageStacked,
  ChartTypes.columnPercentageStacked,
  ChartTypes.linePercentageStacked,
  ChartTypes.areaPercentageStacked,
];

export const ChartTypesLocales: IKeyString = {
  table: 'Table',
  bar: 'Bar',
  column: 'Column',
  line: 'Line',
  area: 'Area',
  areaspline: 'Area Spline',
  singleStat: 'Single Stat',
  barStacked: 'Bar Stacked',
  columnStacked: 'Column Stacked',
  lineStacked: 'Line Stacked',
  areaStacked: 'Area Stacked',
  barPercentageStacked: 'Bar % Stacked',
  columnPercentageStacked: 'Column % Stacked',
  linePercentageStacked: 'Line % Stacked',
  areaPercentageStacked: 'Area % Stacked',
  heatmap: 'Heat Map',
  packedbubble: 'Bubble',
};

export const ReportDashboardTypeLocales: IKeyString = {
  chart: 'Chart',
  table: 'Table',
  singleStat: 'Single Stat',
};

export const timezones = {
  utc: 'UTC',
  americaNewYork: 'America/New_York',
} as const;

export type UniverseDataLayer3 = {
  universe: DataRowGraphValue;
};

export type UniverseDataLayer2 = {
  universe: UniverseDataLayer3;
};

export type UniverseData = {
  [key in SampleGroups]?: UniverseDataLayer2;
};

export type DataRowGraphValue = {
  VALUE: string | number | boolean | null;
  DISPLAY_VALUE: string | number;
};

export type DataRowGraph = {
  [key: string]: DataRowGraphValue;
};

export type DataRowGraphWithKey = ChartDataSet & {
  key: string;
  target?: string;
};

export type DataRow = {
  [key: string]: string | number;
};

export type SimpleDataSerie = (string | number)[];

export interface IAttributionReport extends IBaseReport {
  audit_history?: string[];
  extNetworkId?: number | null;
  frequency?: number | null;
  name?: string;
  subType: string;
  query: IQuery<IQueryFilters<IQueryFilters<IChildrenBase>[]>>;
  recipients?: string[];
  timeOfDay?: null;
  type: 'attribution-report';
}

export function isIAttributionReport(
  report: object,
): report is IAttributionReport {
  return get('type', report) === reportType.attributionReport;
}

type ChartDataSetBase = {
  DAY?: DataRowGraphValue;
  FREQUENCY?: DataRowGraphValue;
  IMPRESSIONS?: DataRowGraphValue;
  REACH?: DataRowGraphValue;
};

export type ChartDataSet = ChartDataSetBase & {
  CONTENT_SERIES?: DataRowGraphValue;
  MEAN?: DataRowGraphValue;
  LIFT?: DataRowGraphValue;
  PERCENTAGE_POINT?: DataRowGraphValue;
  REACH_PC?: DataRowGraphValue;
  SPOT_COUNT?: DataRowGraphValue;
  AD_COST?: DataRowGraphValue;
  PERSUASION_INDEX?: DataRowGraphValue;
  PERSUASION_INDEX_BY_AD_COST?: DataRowGraphValue;
  INCREMENTAL_LIFT?: DataRowGraphValue;
  COST_PER_INCREMENTAL_LIFT?: DataRowGraphValue;
  NETWORK?: DataRowGraphValue;
  NETWORK_ON_OFF?: DataRowGraphValue;
  P_VALUE?: DataRowGraphValue;
  SIGNIFICANCE?: DataRowGraphValue;
  ON_NETWORK?: DataRowGraphValue;
  name?: DataRowGraphValue;
};

export type ChartData = {
  genpop: ChartDataSet[];
  target: ChartDataSet[];
};

export type BreakoutListNames = keyof typeof BreakoutList;

export enum BreakoutList {
  PLATFORMS = 'PLATFORMS',
  NETWORK = 'NETWORK',
  CREATIVE_LABEL = 'CREATIVE_LABEL',
  DAYPART = 'DAYPART',
  CONTENT_SERIES_TITLE = 'CONTENT_SERIES_TITLE',
  NETWORK_GENRE = 'NETWORK_GENRE',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  DURATION_ROUNDED_LABEL = 'DURATION_ROUNDED_LABEL',
  NET_ON_OFF = 'NET_ON_OFF',
  ALL_TOTAL = 'ALL_TOTAL',
  UNIQUE_VIEWING_TYPE_COMBINATIONS = 'UNIQUE_VIEWING_TYPE_COMBINATIONS',
}

export enum ExposureConversionSet {
  // Reach Timeseries
  'exposed_all.timeseries' = 'exposed_all.timeseries',
  'exposed_all.timeseries_cumulative' = 'exposed_all.timeseries_cumulative',
  'exposed_converted.timeseries' = 'exposed_converted.timeseries',
  'exposed_converted.timeseries_cumulative' = 'exposed_converted.timeseries_cumulative',
  'total_converted.timeseries' = 'total_converted.timeseries',
  'total_converted.timeseries_cumulative' = 'total_converted.timeseries_cumulative',

  // Exposure/Conversion Totals (No Segmentation)
  // Exposed Popoulation
  'exposed_all.total' = 'exposed_all.total',
  'exposed_converted.total' = 'exposed_converted.total',
  'exposed_not_converted.total' = 'exposed_not_converted.total',
  converted_total = 'converted_total',
  // Naive Unexposed
  'unexposed_all.total' = 'unexposed_all.total',
  'unexposed_converted.total' = 'unexposed_converted.total',
  'unexposed_not_converted.total' = 'unexposed_not_converted_total',
  // LROI Matched/Synthetic Unexposed
  control_all_total = 'control_all_total',
  control_converted_total = 'control_converted_total',
  control_not_converted_total = 'control_not_converted_total',

  // Exposure/Conversion Results By Segment
  // Ad Duration
  'control_all.DURATION_ROUNDED_LABEL' = 'control_all.DURATION_ROUNDED_LABEL',
  'exposed_all.DURATION_ROUNDED_LABEL' = 'exposed_all.DURATION_ROUNDED_LABEL',
  'exposed_converted.DURATION_ROUNDED_LABEL' = 'exposed_converted.DURATION_ROUNDED_LABEL',
  'exposed_not_converted.DURATION_ROUNDED_LABEL' = 'exposed_not_converted.DURATION_ROUNDED_LABEL',
  // Creative
  'control_all.CREATIVE_LABEL' = 'control_all.CREATIVE_LABEL',
  'exposed_all.CREATIVE_LABEL' = 'exposed_all.CREATIVE_LABEL',
  'exposed_converted.CREATIVE_LABEL' = 'exposed_converted.CREATIVE_LABEL',
  'exposed_not_converted.CREATIVE_LABEL' = 'exposed_not_converted.CREATIVE_LABEL',
  // Day of Week
  'control_all.DAY_OF_WEEK' = 'control_all.DAY_OF_WEEK',
  'exposed_all.DAY_OF_WEEK' = 'exposed_all.DAY_OF_WEEK',
  'exposed_converted.DAY_OF_WEEK' = 'exposed_converted.DAY_OF_WEEK',
  'exposed_not_converted.DAY_OF_WEEK' = 'exposed_not_converted.DAY_OF_WEEK',
  // Daypart
  'control_all.DAYPART' = 'control_all.DAYPART',
  'exposed_all.DAYPART' = 'exposed_all.DAYPART',
  'exposed_converted.DAYPART' = 'exposed_converted.DAYPART',
  'exposed_not_converted.DAYPART' = 'exposed_not_converted.DAYPART',
  // Network
  'control_all.NETWORK' = 'control_all.NETWORK',
  'exposed_all.NETWORK' = 'exposed_all.NETWORK',
  'exposed_converted.NETWORK' = 'exposed_converted.NETWORK',
  'exposed_not_converted.NETWORK' = 'exposed_not_converted.NETWORK',
  // Network Genre
  'control_all.NETWORK_GENRE' = 'control_all.NETWORK_GENRE',
  'exposed_all.NETWORK_GENRE' = 'exposed_all.NETWORK_GENRE',
  'exposed_converted.NETWORK_GENRE' = 'exposed_converted.NETWORK_GENRE',
  'exposed_not_converted.NETWORK_GENRE' = 'exposed_not_converted.NETWORK_GENRE',
  // Network On/Off
  'control_all.NET_ON_OFF' = 'control_all.NET_ON_OFF',
  'exposed_all.NET_ON_OFF' = 'exposed_all.NET_ON_OFF',
  'exposed_converted.NET_ON_OFF' = 'exposed_converted.NET_ON_OFF',
  'exposed_not_converted.NET_ON_OFF' = 'exposed_not_converted.NET_ON_OFF',
  // Series
  'control_all.CONTENT_SERIES_TITLE' = 'control_all.CONTENT_SERIES_TITLE',
  'exposed_all.CONTENT_SERIES_TITLE' = 'exposed_all.CONTENT_SERIES_TITLE',
  'exposed_converted.CONTENT_SERIES_TITLE' = 'exposed_converted.CONTENT_SERIES_TITLE',
  'exposed_not_converted.CONTENT_SERIES_TITLE' = 'exposed_not_converted.CONTENT_SERIES_TITLE',
  // Platforms
  'exposed_all.VIEWING_TYPE' = 'exposed_all.VIEWING_TYPE',

  'exposed_all.HH_FREQUENCY' = 'exposed_all.HH_FREQUENCY',
  'exposed_all.HH_FREQUENCY_cumulative' = 'exposed_all.HH_FREQUENCY_cumulative',
  'exposed_converted.HH_FREQUENCY_cumulative' = 'exposed_converted.HH_FREQUENCY_cumulative',

  'exposed_all.TOP_25' = 'exposed_all.TOP_25',
  'top_25_breakouts' = 'top_25_breakouts',
  total = 'total',

  'exposed_converted.AD_LATENCY' = 'exposed_converted.AD_LATENCY',
}

export type RowsDropped = {
  [key: string]: {
    [key: string]: number;
  };
};

export type ExposureConversionSetNames = keyof typeof ExposureConversionSet;

export type ResultEnhancedDataGroups = {
  [key in ExposureConversionSetNames]?: ResultEnhancedDataTarget;
};

// Demographic
export enum SegmentSet {
  all = 'all',
  exposed_all = 'exposed_all',
  exposed_converted = 'exposed_converted',
  exposed_not_converted = 'exposed_not_converted',
  unexposed_converted = 'unexposed_converted',
  unexposed_not_converted = 'unexposed_not_converted',
  exposed = 'exposed',
  unexposed = 'unexposed',
  converted_total = 'converted_total',
  total_converted = 'total_converted',
  total = 'total',
  TOP_25 = 'TOP_25',
}

export type SegmentSetNames = keyof typeof SegmentSet;

export enum DemographicSet {
  AGE = 'AGE',
  GENDER = 'GENDER',
  ETHNICITY = 'ETHNICITY',
  HH_INCOME = 'HH_INCOME',
  EDUCATION = 'EDUCATION',
  MARITAL_STATUS = 'MARITAL_STATUS',
  PRESENCE_OF_CHILDREN = 'PRESENCE_OF_CHILDREN',
  HOME_OWNERSHIP = 'HOME_OWNERSHIP',
}

type DemographicSetNames = keyof typeof DemographicSet;

export type DemographicBreakoutNames =
  `${SegmentSetNames}.${DemographicSetNames}`;

export type ResultEnhancedDemographicGroups = {
  [key in SegmentSetNames]?: {
    [key in DemographicSetNames]?: ResultBaseSampleGroups;
  };
};

export type ResultBase = {
  [key: string]: ResultBaseTotalStructure;
};

export type ResultBaseSampleGroups = {
  [key in SampleGroupNames]?: ResultBaseSubStructure[];
};

export type ResultBaseTotalStructure = {
  [key: string]: ResultBaseTotalDataTarget;
};

export type ResultBaseTotalDataTarget = {
  [key in SampleGroupNames]?: ResultBaseSubStructure[];
};

export type ResultBaseSubStructureKeys =
  | 'campaign'
  | 'converters'
  | 'conversions'
  | 'total_sales'
  | 'sales_per_transaction';

export type ResultBaseSubStructure = {
  name?: DataRowGraphValue;
  BREAKOUT?: DataRowGraphValue;
  campaign?: {
    [key: string]: DataRowGraphValue;
  };
  converters?: {
    [key: string]: DataRowGraphValue;
  };
  conversions?: {
    [key: string]: DataRowGraphValue;
  };
  total_sales?: {
    [key: string]: DataRowGraphValue;
  };
  sales_per_transaction?: {
    [key: string]: DataRowGraphValue;
  };
};

export type PerformanceMetricsTop25 = {
  [key in PerformanceGroupNames]?: ResultTop25;
};

export type ResultTop25 = {
  [key in SampleGroups]: Top25SubStructure[];
};

export type Top25SubStructure = ResultBaseSubStructure & {
  name: DataRowGraphValue;
  BREAKOUT: DataRowGraphValue;
};

export enum ResultMiscKeys {
  'exposed_all.timeseries' = 'exposed_all.timeseries',
  'exposed_all.timeseries_cumulative' = 'exposed_all.timeseries_cumulative',
  'exposed_converted.timeseries' = 'exposed_converted.timeseries',
  'exposed_converted.timeseries_cumulative' = 'exposed_converted.timeseries_cumulative',
  'total_converted.timeseries' = 'total_converted.timeseries',
  'total_converted.timeseries_cumulative' = 'total_converted.timeseries_cumulative',
  'exposed_all.HH_FREQUENCY' = 'exposed_all.HH_FREQUENCY',
  'exposed_converted.HH_FREQUENCY_cumulative' = 'exposed_converted.HH_FREQUENCY_cumulative',
  'exposed_converted.AD_LATENCY' = 'exposed_converted.AD_LATENCY',
}

export type ResultMiscKeysName =
  | 'timeseries'
  | 'timeseries_cumulative'
  | 'HH_FREQUENCY'
  | 'HH_FREQUENCY_cumulative'
  | 'AD_LATENCY';

export type PerformanceGroupNames = keyof typeof PerformanceGroupKeys;

export enum PerformanceGroupKeys {
  converters = 'converters',
  conversions = 'conversions',
  total_sales = 'total_sales',
  sales_per_transaction = 'sales_per_transaction',
}

export type ResultSegments = keyof typeof ResultSegmentNames;

export enum ResultSegmentNames {
  total_all = 'total_all',
  exposed_all = 'exposed_all',
  exposed_converted = 'exposed_converted',
  exposed_not_converted = 'exposed_not_converted',
  unexposed_all = 'unexposed_all',
  unexposed_converted = 'unexposed_converted',
  unexposed_not_converted = 'unexposed_not_converted',
  // Below are planned for Phase 4 CO
  total_converted = 'total_converted',
  total_not_converted = 'total_not_converted',
  exposed_high_conversion_frequency = 'exposed_high_conversion_frequency',
  exposed_low_conversion_frequency = 'exposed_low_conversion_frequency',
  unexposed_high_conversion_frequency = 'unexposed_high_conversion_frequency',
  unexposed_low_conversion_frequency = 'unexposed_low_conversion_frequency',
  exposed_high_spenders = 'exposed_high_spenders',
  unexposed_high_spenders = 'unexposed_high_spenders',
}

export type ResultEnhancedDataTarget = {
  [key in SampleGroups]?: DataRowGraph[];
};

export enum SampleGroupNames {
  genpop = 'genpop',
  target = 'target',
}

export type SampleGroups = keyof typeof SampleGroupNames;

export const ReportDatasetsSelections = {
  content: 'content',
  ads: 'ads',
  customAds: 'customAds',
  genericEvents: 'genericEvents',
} as const;

export type ReportDatasetSelection =
  (typeof ReportDatasetsSelections)[keyof typeof ReportDatasetsSelections];

export const MetricTypes = {
  normal: 'normal',
  cumulative: 'cumulative',
} as const;

export enum DisplayMode {
  dialog = 'dialog',
  toast = 'toast',
}

export enum Action {
  noAction = 'noAction',
  redirect = 'redirect',
}

export type ErrorMeta = {
  code?: number;
  displayMode?: DisplayMode;
  action?: Action;
};

export interface IModalDialog {
  confirmationDialogMessage: string | string[];
  confirmationDialogHeader?: string;
  confirmationDialogOK?: string;
  confirmationDialogType?: string;
}

export interface IConfirmDialog extends IModalDialog {
  confirmationDialogCancel?: string;
}

export type ModeSelection = (typeof Modes)[keyof typeof Modes];

export type IDomainsDateRanges = {
  [key in ModeSelection]: {
    min_date: string;
    max_date: string;
  };
};

export type IBusinessData = {
  [key: string]: ITextValue[] | {} | string | number | null;
};

export type CloneResponse = {
  meta?: {
    code?: number;
    id?: string;
  };
  data: [string | null];
  error?: {
    message: 'string';
  };
};

export type ISetSorting = {
  businessData: IBusinessData;
  sorting: IReportSorting;
};

export type ExecuteErrorResponse = {
  meta?: {
    code?: number;
  };
  message?: string;
};

export type DatasetDefaultDateRange = {
  dateMin: string;
  dateMax: string;
  dateStart: string;
  dateEnd: string;
};

export type DatasetDefaultDateRange2 = {
  dateStart: string;
  dateEnd: string;
};

/** Results related */

export type Stacking = (typeof stackings)[keyof typeof stackings];

export type OptionAxisType =
  (typeof optionAxisTypes)[keyof typeof optionAxisTypes];

export type OptionAnyTitle = {
  align?: string;
  text?: string;
  x?: number;
  y?: number;
};

export type OptionColors = string[] | undefined;

export type OptionChartBorderWidth = number;

export type OptionChart3D = {
  enabled: boolean;
  alpha: number;
  beta: number;
  depth: number;
  viewDistance: number;
};

export type OptionChart = {
  animation?: boolean;
  backgroundColor?: string;
  borderWidth?: OptionChartBorderWidth;
  height?: null | number | string;
  marginBottom?: number;
  marginTop?: number;
  options3d?: OptionChart3D;
  renderTo?: string;
  type?: AllowedChartTypes;
  style?: object | undefined;
};

export type OptionYAxisLabels = {
  format?: string;
  formatter?: () => void;
  overflow?: string;
  style?: object | undefined;
  x?: number;
  y?: number;
};

export type OptionCredits = {
  enabled: boolean;
};

export type OptionCategory = string;

export type AllowedChartTypes =
  | EChartTypes.table
  | EChartTypes.bar
  | EChartTypes.column
  | EChartTypes.line
  | EChartTypes.area
  | EChartTypes.pie
  | EChartTypes.map
  | EChartTypes.areaspline
  | EChartTypes.singleStat
  | EChartTypes.barStacked
  | EChartTypes.columnStacked
  | EChartTypes.lineStacked
  | EChartTypes.areaStacked
  | EChartTypes.barPercentageStacked
  | EChartTypes.columnPercentageStacked
  | EChartTypes.linePercentageStacked
  | EChartTypes.areaPercentageStacked
  | EChartTypes.heatmap
  | EChartTypes.packedbubble;

export type Legend = {
  align?: string;
  enabled?: boolean;
  floating?: boolean;
  labelFormatter?: (seriesOrPoint: unknown) => string | undefined;
  layout?: string;
  verticalAlign?: string;
  x?: number;
  y?: number;
};

export interface IConfiguration {
  backgroundColor?: string;
  boost?: { useGPUTranslations: boolean };
  chart?: OptionChart;
  colors?: OptionColors;
  credits?: { enabled?: boolean };
  dimensionIds?: string[];
  dimensions?: IDimension[];
  disableAnimationAfterFirstRender?: boolean;
  height?: null | number | string;
  interval?: IReportQueryInterval;
  legend?: Legend;
  metrics?: Metric[];
  plotOptions?: OptionPlotOptions | {};
  primaryColor?: string;
  time?: TimeOptions;
  title?: OptionAnyTitle;
  xAxis?: OptionXAxis[];
  yAxis?: OptionYAxis[];
  isTarget?: boolean;
}

export type OptionXAxis = {
  categories?: OptionCategory[];
  gridLineColor?: string;
  gridLineDashStyle?: string;
  gridLineWidth?: number;
  lineWidth?: number;
  labels?: {
    style?: {
      fontSize?: string;
    };
    formatter?: ({
      chart,
      pos,
      value,
      style,
    }: {
      chart?: ICustomChart | undefined;
      pos?: number;
      value?: string;
      style?: object | undefined;
    }) => string | undefined;
  };
  min?: number;
  max?: number;
  title?: OptionAnyTitle;
  type?: OptionAxisType;
};

export type OptionYAxis = {
  categories?: OptionCategory[];
  format?: string;
  gridLineColor?: string;
  labels?: OptionYAxisLabels;
  lineWidth?: number;
  min?: number;
  max?: number;
  softMin?: number; //https://api.highcharts.com/highcharts/yAxis.softMin
  opposite?: boolean;
  style?: object;
  title?: OptionAnyTitle;
  type?: OptionAxisType;
};

export type OptionSerieData =
  | string[]
  | number[]
  | string
  | number
  | DataRowGraphValue
  | SimpleDataSerie[];

export type OptionSerie = {
  name?: string;
  data?: OptionSerieData;
  dataSorting?: {
    enabled: boolean;
    matchByName: boolean;
  };
  type?: ChartTypeBasic;
  color?: string;
  yAxis?: number;
  tooltip?: object;
  group?: string;
  metric?: Metric;
  curatedData?: OptionSerieData;
  dimension?: string;
};

export type OptionPlotOptionsChartType = {
  minSize?: string;
  maxSize?: string;
  layoutAlgorithm?: {
    gravitationalConstant: number;
    splitSeries: boolean;
    seriesInteraction: boolean;
    dragBetweenSeries: boolean;
    parentNodeLimit: boolean;
  };
  stacking?: string;
  connectNulls?: boolean;
  series?: OptionPlotOptions;
  colors?: string[];
  colorByPoint?: boolean;
  groupPadding?: number;
  allowPointSelect?: boolean;
  cursor?: string;
  dataLabels?: {
    series?: {
      name: string;
    };
    curatedData?: string[];
    enabled?: boolean;
    formatter?: ({ chart }: { chart: ICustomChart }) => string;
    filter?: {
      property: string;
      operator: string;
      value: number;
    };
    style?: {
      color: string;
      textOutline: string;
      fontWeight: string;
    };
  };
};

export type OptionPlotOptions = {
  [key: string]: OptionPlotOptionsChartType;
};

export interface IOptions {
  title?: OptionAnyTitle;
  credits?: OptionCredits;
  colors?: OptionColors;
  exporting?: {
    enabled: boolean;
    sourceHeight?: number;
    sourceWidth?: number;
    scale?: number;
  };
  legend?: LegendOptions;
  time?: TimeOptions;
  chart?: OptionChart;
  xAxis?: OptionXAxis[];
  yAxis?: OptionYAxis[];
  series?: OptionSerie[] | OptionSerie;
  plotOptions?: OptionPlotOptions | {};
  tooltip?: {
    formatter: ({ chart }: { chart: ICustomChart }) => string;
  };
}

export type MetricConfig = {
  metric: Metric;
  chartType: ChartType;
  stacking?: Stacking;
};

export type ISGMConfigMeta = {
  ['3D']?: boolean;
  targetList?: string[];
  rowLimit?: number;
};

export type ISGMConfig = {
  series: string[];
  groups: string[];
  metrics: MetricConfig[];
  meta?: ISGMConfigMeta;
};

export type UniqRowsColumns = {
  uniqRows: string[];
  uniqColumns: string[];
};

export type MultiTargetData = {
  targetCuratedName?: string;
  target?: string;
};

export type SeriesType = {
  data: number[][];
  name?: string;
  tooltip?: {
    formatter?: ({ chart }: { chart: ICustomChart }) => string;
  };
  type?: string;
  yAxis?: number;
  categories?: string[];
  curatedData: string[][];
  multiMetric?: boolean;
  multiTargetData?: MultiTargetData[];
  target?: string;
  metric?: string;
  group?: string;
};

export type ChartGeneratedSeries = {
  series: SeriesType[];
  categories_x: string[];
  categories_y: string[];
};

export type MetricsAxisConfig = {
  categories?: OptionCategory[];
  type?: OptionAxisType;
  title?: {
    text?: string;
  };
  labels?: {
    formatter: ({
      chart,
      pos,
      value,
    }: {
      chart?: ICustomChart;
      pos?: number;
      value?: string;
    }) => string;
  };
  opposite?: boolean;
  max?: number;
  min?: number;
  ceiling?: number;
  lineWidth?: number;
  tickColor?: string;
};

export type GroupConfigAxis = {
  xAxis: MetricsAxisConfig[];
  yAxis: MetricsAxisConfig[];
};

export type MetricsYAxisConfig = {
  labels: {
    formatter: () => string;
  };
  opposite: boolean;
  title: {
    text: string;
  };
};

export type DaysOfWeek = keyof typeof daysOfWeek;

export interface ICustomChart {
  point?: {
    index?: number;
    x: number;
    category: string;
  };
  series?: {
    chart: {
      axes: [
        {
          categories: string[];
        },
      ];
      pos?: number;
      value?: string;
    };
  };
  hoverPoint?: {
    x: number;
    category: string;

    series: {
      point: {
        x: number;
      };
      chart: {
        axes: [
          {
            categories: string[];
          },
        ];
      };
      userOptions: {
        curatedData: string[][];
        metric: string;
        group: string;
        name?: string;
        groupDisplayName?: string;
        businessRules?: BusinessRules;
        target?: string;
        targetCuratedName?: string;
        metricCuratedName?: string;
        multiTargetData?: MultiTargetData[];
      };
      index: number;
    };
    color: string;
    index: number;
  };
}

export interface ISaveSegmentsResponse {
  files: Array<string>;
}

export type RulesetConfig = {
  [key: string]: {
    event: {
      type: string;
      elseType?: string;
    };
    conditions: TopLevelCondition;
  };
};

export type EngineEvent = {
  type: string;
};

export type NotPersistedFacts = {
  [key: string]:
    | boolean
    | number
    | string
    | undefined
    | string[]
    | IBusinessData
    | IReport;
};

export const inputsDataTestId = {
  dateInputStart: 'r2d2-date-picker-date-start',
  dateInputEnd: 'r2d2-date-picker-date-end',
  toggleSwitch: 'r2d2-toggle-switch-checkbox',
  dropdown: 'r2d2-input-dropdown',
  dropdownSearch: 'r2d2-input-dropdown-search',
  toggleButtonGroup: 'r2d2-button-r2d2-segmented-control',
  inputValueSelect: 'r2d2-input-value-select',
  deleteButton: 'r2d2-button-delete-rule',
  addFilterButton: 'r2d2-button-add-compound-filter',
  eqMetric: 'r2d2-toggle-eq-metric-switch',
};

export type BreakoutItem = {
  id: string;
  name: string;
};

export type ISeriesDataItem = {
  x: number;
  y: number;
  custom: { displayValue: string };
};

export interface IRunReportProps {
  report: IReport;
  cancelInProgress?: boolean;
  statsInProgress?: boolean;
}

export interface IHighchartsSeriesData {
  name: string;
  y?: number;
  isSum?: boolean;
  y_name?: string;
}
