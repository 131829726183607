import Icon, {
  Type as IconType,
  Size as IconSize,
  Color as IconColor,
} from 'components/Icon';
import { Placement, Style } from 'components/Sticker';
import { Tooltip } from 'components/Tooltip';
import { getClass, getTestId } from 'helpers/components';
import React from 'react';

export interface IChartHelper {
  title?: string;
  iconType?: IconType.info | IconType.questionMark;
  tooltip?: string | React.ReactNode;
  testId?: string;

  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const componentName = 'chart-helper';
const buttonClass = getClass(componentName);
const buttonIconClass = getClass(componentName, {
  concat: ['icon'],
});

export const ChartHelper: React.FC<IChartHelper> = ({
  title = 'Learn about this graph',
  iconType = IconType.info,
  tooltip,
  testId,
  ...handlers
}) => {
  const componentTestId = React.useMemo(
    () => getTestId(componentName, testId),
    [testId],
  );

  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    handlers.onClick?.(e);
  };

  return (
    <div
      {...handlers}
      onClick={handleClick}
      onKeyDown={undefined}
      data-testid={componentTestId}
    >
      <Tooltip
        content={tooltip}
        placement={Placement.left}
        style={Style.imp4ct}
        showArrow
      >
        <button className={buttonClass}>
          <div className={buttonIconClass}>
            <Icon
              type={iconType}
              color={IconColor.dark}
              size={IconSize.micro}
            />
          </div>
          <div>{title}</div>
        </button>
      </Tooltip>
    </div>
  );
};
