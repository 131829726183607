import Flex, { Direction } from 'components/Flex';
import Input, { NewInputValue, Type } from 'components/Input';
import { getClass, getId, getTestId } from 'helpers/components';
import { escapeRegExp, noop } from 'lodash/fp';
import React, {
  useEffect,
  useMemo,
  useState,
  ReactElement,
  useCallback,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, Row, usePagination, useTable, useSortBy } from 'react-table';
import { Pagination } from './components/Pagination';
import TableRow from './TableRow';
import ThRow, { IHeaderColumn } from './ThRow';

export const tableComponentName = 'table';

const DOM_KEY_HEAD = 'head';
const DOM_KEY_BODY = 'body';
const DOM_KEY_PIVOT_TABLE = 'pivot';

export const ROW_DOM_KEY = 'row';
const TOTAL_PAGES_TO_SHOW = 10;
const ROW_REMOVE_ACTION_DELAY = 1000;

export const alignments = {
  center: 'center',
  left: 'left',
  right: 'right',
} as const;

export type Alignment = (typeof alignments)[keyof typeof alignments];

export type StickyColumnConfig = {
  Header: string;
  accessor: string;
  sticky?: string;
};

export const textOverflows = {
  ellipsis: 'ellipsis',
  none: '',
  wrap: 'wrap',
} as const;

export type TextOverflow = (typeof textOverflows)[keyof typeof textOverflows];

const getPaginationSize = (
  pagesLength: number,
  pagination?: boolean,
  pageSize?: number,
): number => {
  if (!pagination && !pageSize) {
    return -1;
  }

  if (pageSize) {
    return pageSize;
  }

  const hasAutoPagination = pagesLength > TOTAL_PAGES_TO_SHOW;
  const autoPaginationPages = pagesLength / TOTAL_PAGES_TO_SHOW;
  const hasMinimumAutoPaginationPages = autoPaginationPages >= 2;

  if (hasAutoPagination && hasMinimumAutoPaginationPages) {
    return TOTAL_PAGES_TO_SHOW;
  }

  if (hasAutoPagination) {
    let paginationSize;

    for (let index = 0; index < pagesLength; index++) {
      const paginationSizeEvaluate = TOTAL_PAGES_TO_SHOW - index;
      const autoPaginationIndexPages = pagesLength / paginationSizeEvaluate;
      const hasMinimumAutoPaginationIndexPages = autoPaginationIndexPages >= 2;

      if (hasMinimumAutoPaginationIndexPages) {
        paginationSize = paginationSizeEvaluate;
      }
    }

    if (!paginationSize) {
      return -1;
    }

    return paginationSize;
  }

  return -1;
};

export type TableDataType = {
  [key: string]: string | number | null;
};

const getVisiblePages = (
  current: number,
  total: number,
  length: number,
): number[] => {
  const min = 1;
  let copyLength = length;
  if (copyLength > total) copyLength = total;
  let start = current - Math.floor(copyLength / 2);
  start = Math.max(start, min);
  start = Math.min(start, min + total - copyLength);
  return Array.from({ length: copyLength }, (el, i) => start + i);
};

interface ITableProps<T extends { [P in keyof T]: T[P] }> {
  alignment?: Alignment[];
  canEditItem?: boolean;
  cellspacing?: string;
  classes?: string;
  columns: Array<Column<T>>;
  data: T[];
  editItemSegment?: string;
  filter?: boolean;
  filterPlaceholder?: string;
  goToEditOnRowClick?: boolean;
  onRowClick?: (itemId?: string) => void;
  hasFooter?: boolean;
  hasHeader?: boolean;
  hiddenColumns?: string[];
  id?: string;
  indexable?: boolean;
  initialState?: Record<string, unknown>;
  noResultsMessage?: string;
  overrideHeaders?: IHeaderColumn[][];
  pageSize?: number;
  pagination?: boolean;
  sortable?: boolean;
  tbodyTdStyle?: Record<string, unknown>;
  testId?: string;
  textOverflow?: TextOverflow;
  onRowHover?: (row: Row<T> | null) => void;
  rowRemoveClickAction?: (row: Row<T> | null) => void;
  stickyFirstColumn?: boolean;
}

const Table = <T extends { [P in keyof T]: T[P] }>(
  props: ITableProps<T>,
): ReactElement => {
  const {
    alignment,
    canEditItem = true,
    cellspacing = '0',
    classes = '',
    columns = [],
    data,
    editItemSegment,
    filter,
    filterPlaceholder,
    goToEditOnRowClick,
    onRowClick,
    hasFooter = false,
    hasHeader = true,
    hiddenColumns,
    id,
    indexable = false,
    initialState = {},
    noResultsMessage,
    overrideHeaders,
    pagination,
    sortable = true,
    tbodyTdStyle,
    testId,
    textOverflow,
    stickyFirstColumn = false,
    onRowHover,
    rowRemoveClickAction,
  } = props;

  const { pageSize: propPageSize } = props;
  const pageSize = getPaginationSize(data.length, pagination, propPageSize);

  const [filteredData, setFilteredData] = useState(data);
  const [searchString, setSearchString] = useState('');
  const isPaginated = pageSize !== -1;

  const instance = useTable(
    {
      columns,
      data: filteredData,
      initialState,
      disableSortBy: !sortable,
    },
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex },
  } = instance;

  const tableData = isPaginated ? page : rows;

  const previousSearchString = useRef('');
  const navigate = useNavigate();

  const [visiblePages, setVisiblePages] = useState<number[]>([]);

  const doSetVisiblePages = useCallback(
    (currentData: T[]): void => {
      if (isPaginated) {
        const maxPages = Math.ceil(currentData.length / pageSize);
        const newVisiblePages = getVisiblePages(
          pageIndex,
          maxPages,
          TOTAL_PAGES_TO_SHOW,
        );
        setVisiblePages(newVisiblePages);
      }
    },
    [pageSize, isPaginated, pageIndex],
  );

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [setPageSize, pageSize]);

  useEffect(() => {
    doSetVisiblePages(filteredData);
  }, [pageIndex, doSetVisiblePages, filteredData]);

  useEffect(() => {
    if (hiddenColumns) {
      setHiddenColumns(hiddenColumns);
    }
  }, [setHiddenColumns, hiddenColumns]);

  const filterableColumns = useMemo(
    () =>
      columns
        .map((column) => column.accessor ?? column.id)
        .filter((item) => !!item) as string[],
    [columns],
  );

  useEffect(() => {
    if (searchString !== previousSearchString.current) {
      previousSearchString.current = searchString;
      const regExp = new RegExp(escapeRegExp(searchString), 'i');
      const currentFilteredData = data.filter((item) =>
        Object.entries(item)
          .filter(([key]) => filterableColumns.includes(key))
          .some(([, value]) => regExp.test(value as string)),
      );

      setFilteredData(currentFilteredData);
      doSetVisiblePages(currentFilteredData);
    }
  }, [searchString, filterableColumns, data, doSetVisiblePages]);

  const handleChange = (value: NewInputValue): void => {
    setSearchString(value as string);
  };

  const goToItem = (itemId?: string): void => {
    const url = `${itemId}`;
    navigate(url);
  };

  const goToEditItem = (itemId?: string): void => {
    const url = `${editItemSegment}/${itemId}`;
    navigate(url);
  };

  const itemClick =
    onRowClick ?? (goToEditOnRowClick ? goToEditItem : goToItem);

  const handleClickCell = (itemId?: string): void => {
    if (!itemId) {
      return;
    }

    itemClick(itemId);
  };

  const getCellAlignment = (columnIndex: number): string => {
    if (!alignment?.[columnIndex]) {
      return '';
    }

    return alignment[columnIndex];
  };

  const getCellTextWrap = (): string =>
    textOverflow ? textOverflows.wrap : '';
  const getCellTextOverflow = (): string =>
    textOverflow ? textOverflows.ellipsis : '';

  const getCellClass = (columnIndex: number): string => {
    const cellAlignment = getCellAlignment(columnIndex);
    const cellTextOverflow = getCellTextOverflow();

    return `${cellAlignment} ${cellTextOverflow}`;
  };

  const tableClass = useMemo(
    () =>
      getClass(tableComponentName, {
        boolean: [
          {
            state: !!overrideHeaders,
            class: DOM_KEY_PIVOT_TABLE,
            preventCollisions: true,
          },
        ],
      }),
    [overrideHeaders],
  );

  const tableHeadClass = useMemo(
    () => getClass(tableComponentName, { concat: [DOM_KEY_HEAD] }),
    [],
  );

  const tableBodyClass = getClass(tableComponentName, {
    concat: [DOM_KEY_BODY],
  });

  const tableRowClass = getClass('table-row');

  const tableId = getId(tableComponentName, id);
  const tableTestId = getTestId(tableComponentName, testId);

  const { role: tableRole } = getTableProps();
  const { role: tableBodyRole } = getTableBodyProps();

  return (
    <div
      className={
        stickyFirstColumn ? `${tableClass} sticky-first-column` : tableClass
      }
      data-testid={tableTestId}
      id={tableId}
    >
      <Flex direction={Direction.column}>
        {filter && (
          <div className={`${tableClass}-search`}>
            <Input
              type={Type.search}
              value={searchString}
              placeholder={filterPlaceholder}
              onChange={handleChange}
              testId={`${tableTestId}-search`}
            />
          </div>
        )}
        <table cellSpacing={cellspacing} className={classes} role={tableRole}>
          {hasHeader && (
            <>
              <ThRow<T>
                overrideHeaders={overrideHeaders}
                header
                getCellAlignment={getCellAlignment}
                getCellTextOverflow={getCellTextWrap}
                headerGroups={headerGroups}
                tableHeadClass={tableHeadClass}
              />
            </>
          )}
          <tbody role={tableBodyRole} className={tableBodyClass}>
            {tableData?.length ? (
              tableData.map((row: Row<T>, rowIndex: number) => {
                prepareRow(row);
                const { key, role } = row.getRowProps();

                return (
                  <TableRow<T>
                    row={row}
                    rowIndex={rowIndex}
                    pageIndex={pageIndex}
                    TOTAL_PAGES_TO_SHOW={TOTAL_PAGES_TO_SHOW}
                    ROW_REMOVE_ACTION_DELAY={ROW_REMOVE_ACTION_DELAY}
                    columns={columns}
                    canEditItem={canEditItem}
                    editItemSegment={editItemSegment}
                    indexable={indexable}
                    tbodyTdStyle={tbodyTdStyle}
                    getCellClass={getCellClass}
                    handleClickCell={data ? handleClickCell : noop}
                    onRowHover={onRowHover}
                    rowRemoveClickAction={rowRemoveClickAction}
                    key={key}
                    role={role}
                  />
                );
              })
            ) : (
              <tr className={tableRowClass}>
                <td colSpan={columns.length}>{noResultsMessage}</td>
              </tr>
            )}
          </tbody>
          {hasFooter && (
            <ThRow<T>
              footer
              getCellAlignment={getCellAlignment}
              getCellTextOverflow={getCellTextOverflow}
              headerGroups={headerGroups}
              tableHeadClass={tableHeadClass}
            />
          )}
        </table>
        {isPaginated && (
          <Pagination
            visiblePages={visiblePages}
            pageIndex={pageIndex}
            onPaginationIndexClick={gotoPage}
            testId={tableTestId}
          />
        )}
      </Flex>
    </div>
  );
};

export default Table;
