import { IProcessing } from 'domains/processing/types';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';

export const initialState: IProcessing = {
  running: false,
  percentage: null,
};

export default (
  processing: IProcessing = initialState,
  action: Action<IProcessing>,
): IProcessing => {
  const { payload, type } = action;
  switch (type) {
    case ActionType.PROCESSING_SET_STATUS:
      return payload;
    default:
      return processing;
  }
};
