import { getClass, getTestId } from 'helpers/components';
import noop from 'lodash/noop';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';

export const popoverMenuContentComponentName = 'popover-menu-content';

type Props = {
  options: { visible?: boolean; key: string; option: ReactNode }[];
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
};

const PopoverMenuContent: FunctionComponent<Props> = (props: Props) => {
  const { options, onClick, className } = props;

  const contentClass = useMemo(
    () => getClass(popoverMenuContentComponentName, { add: [className ?? ''] }),
    [className],
  );
  const contentTestId = useMemo(
    () => getTestId(popoverMenuContentComponentName),
    [],
  );

  return (
    <div className={contentClass} data-testid={contentTestId}>
      <ul>
        {options.map((entry) => {
          if (entry.visible === false) return undefined;
          return (
            <li
              onKeyDown={noop}
              onClick={onClick}
              key={entry.key}
              data-testid={`${contentTestId}-${entry.key}`}
            >
              {entry.option}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PopoverMenuContent;
