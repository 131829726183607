import { ResultBaseSubStructure } from 'domains/reports/types';

export const filterByMetricValues =
  (
    breakout: string | number | null | undefined,
    name: string | number | null | undefined,
  ) =>
  (targetData: ResultBaseSubStructure): boolean => {
    return breakout && name ? targetData.name?.DISPLAY_VALUE !== name : false;
  };
