import { get } from 'lodash';
import { IPermissionTree } from '../../types';
import { permissionToPath } from '../permissionToPath';

const andOpRegex = /\bAND\b/gi;
const orOpRegex = /\bOR\b/gi;
const notOpRegex = /\!/gi;

const buildPermissionOperation = (
  permissionArray: string[],
  permissionTree: IPermissionTree,
): string => {
  return permissionArray.reduce((acc: string, p: string): string => {
    let pCopy = p;

    const andOp = andOpRegex.test(pCopy) ? '&&' : '';
    const orOp = orOpRegex.test(pCopy) ? '||' : '';
    const notOp = notOpRegex.test(pCopy) ? '!' : '';

    pCopy = pCopy.replace(andOpRegex, '');
    pCopy = pCopy.replace(orOpRegex, '');
    pCopy = pCopy.replace(notOpRegex, '');

    const [openParenthesis] = pCopy.match(/\(/) ?? [''];
    const [closeParenthesis] = pCopy.match(/\)/) ?? [''];

    pCopy = pCopy.replace(/\(|\)|\s/gi, '');

    const evaluatedP = pCopy
      ? !!get(permissionTree, permissionToPath(pCopy))
      : '';
    return `${acc}${openParenthesis}${andOp}${orOp}${notOp}${evaluatedP}${closeParenthesis}`;
  }, '');
};

export const checkPermissionTree = (
  permission: string | string[],
  permissionTree: IPermissionTree,
): boolean => {
  if (Array.isArray(permission)) {
    return eval(buildPermissionOperation(permission, permissionTree));
  }

  if (typeof permission === 'string') {
    const path = permissionToPath(permission);
    return !!get(permissionTree, path);
  }
  return false;
};
