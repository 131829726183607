import ActionsBar from 'components/ActionsBar';
import H3 from 'components/H3';
import Modal, { Type } from 'components/Modal';
import React from 'react';
import {
  savingStatus,
  SegmentsSavingStatus,
} from 'types/attributionReportsResult';

type OwnProps = {
  saveSegmentsStatus: SegmentsSavingStatus;
  setSegmentsSavingStatus: (arg0: SegmentsSavingStatus) => void;
};

const SaveSegmentsFailModal = ({
  saveSegmentsStatus,
  setSegmentsSavingStatus,
}: OwnProps): React.ReactElement => (
  <Modal
    type={Type.auto}
    isOpen={saveSegmentsStatus === savingStatus.failed}
    onCloseClick={() => setSegmentsSavingStatus(savingStatus.none)}
    // testId={modalComponentTestId}
  >
    <div>
      <header>
        <H3>Unable to Save Segments</H3>
      </header>
      <section>
        <span>
          We were unable to save your segments. Please report to your account
          manager or try again.
        </span>
      </section>
      <ActionsBar
        cancel={false}
        okLabel="Ok"
        onOK={() => setSegmentsSavingStatus(savingStatus.none)}
      />
    </div>
  </Modal>
);
export default SaveSegmentsFailModal;
