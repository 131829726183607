import H1 from 'components/H1';
import IClient from 'domains/clients/types';
import { getTestId, getClass } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import { isEqual } from 'lodash/fp';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Index, getUrl } from 'routes';
import * as userActions from 'store/actions/user';
import * as userSelectors from 'store/selectors/user';
import { Action } from 'types/action';
import IError from 'types/error';
import IRole from 'types/role';
import State from 'types/state';
import IUser from 'types/user';
import ILoggedUserState from 'types/userState';
import UserForm from './components/Form';

export const editUserComponentName = 'edit-user';

const LOCALE_EDIT_TITLE = 'Edit User';
const LOCALE_CREATE_TITLE = 'New User';

interface IProps {
  user: IUser;
  roles: IRole[];
  clients: IClient[];
}

interface OwnProps extends IProps {
  currentUserId: string;
  updateCurrentUser: (arg0: ILoggedUserState) => Action<ILoggedUserState>;
}

const UserEdit: FunctionComponent<OwnProps> = (props: OwnProps) => {
  const { clients, currentUserId, roles, updateCurrentUser, user } = props;
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { doSuccessToast, doErrorToast } = useToast();

  const goToUsers = (): void => {
    navigate(`/${Index.SEGMENT_ADMIN}`);
  };

  const handleSuccess = (updatedUser?: IUser): void => {
    doSuccessToast(id ? 'User updated' : 'User created');
    navigate(`/${Index.SEGMENT_ADMIN}`);
    if (isEqual(id, currentUserId)) {
      updateCurrentUser(updatedUser as ILoggedUserState);
    }
  };

  const handleError = (err: IError): void => {
    doErrorToast(err?.message ?? 'There has been an error.');
  };

  const { doUpdate, doCreate, loading } = useItemAdmin<IUser>({
    endpoint: getUrl([Index.SEGMENT_USERS]),
  });

  const handleSubmit = async (updatedUser: IUser): Promise<void> => {
    if (id) {
      doUpdate({
        item: { ...updatedUser, id },
        onSuccess: () => handleSuccess(updatedUser),
        onError: handleError,
      });
    } else {
      doCreate({
        item: {
          ...updatedUser,
        },
        onSuccess: () => handleSuccess(),
        onError: handleError,
      });
    }
  };

  const editUserTestId = getTestId(editUserComponentName);
  const editUserDefaultClass = getClass(editUserComponentName);

  return (
    <section className={editUserDefaultClass} data-testid={editUserTestId}>
      <header className={`${editUserDefaultClass}-header`}>
        <H1>{user.id ? LOCALE_EDIT_TITLE : LOCALE_CREATE_TITLE}</H1>
      </header>
      <UserForm
        user={user}
        roles={roles}
        clients={clients}
        onSubmit={handleSubmit}
        onCancel={goToUsers}
        loading={loading}
        testId={editUserTestId}
      />
    </section>
  );
};

const mapStateToProps = (state: State): { currentUserId: string } => ({
  currentUserId: userSelectors.getId(state),
});

const mapDispatchToProps = {
  updateCurrentUser: userActions.updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
