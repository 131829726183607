import ActionType from 'store/actions/types';
import { PayloadLessAction, Action } from 'types/action';
import {
  IUpdateConfigOptionValue,
  IToggleConfigOptionValuePosition,
  IConfigOptionUiForm,
  IConfigOption,
} from 'domains/configOptions/types';
import { Dispatch } from 'redux';
import State from 'types/state';
import { fetchApi } from 'helpers/fetching';
import { delay } from 'helpers/utils';
import FetchMethod from 'types/fetchMethod';
import { showErrorToast, showSuccessToast } from 'helpers/general';
import * as configOptionsSelectors from 'store/selectors/configOptions';
import { Index } from 'routes';
import { get, isEmpty } from 'lodash';
import { NavigateFunction } from 'react-router-dom';
import {
  LOCALE_SAVE_SUCCEEDED,
  LOCALE_SAVE_FAILED,
  LOCALE_LOAD_FAILED,
  LOCALE_UPDATE_SUCCEEDED,
} from 'features/admin/configOptions/locale';
import { v4 as uuid } from 'uuid';

export const setLoadDetailsStart = (): PayloadLessAction => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_LOAD_STARTED,
});

export const setLoadDetailsEnd = (): PayloadLessAction => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_LOAD_ENDED,
});

export const setConfigOptionForm = (
  payload: IConfigOptionUiForm,
): Action<IConfigOptionUiForm> => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_FORM_SET,
  payload,
});

export const updateConfigOptionFormId = (payload: string): Action<string> => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_FORM_ID_UPDATE,
  payload,
});

export const updateConfigOptionValueItem = (
  payload: IUpdateConfigOptionValue,
): Action<IUpdateConfigOptionValue> => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_UPDATE,
  payload,
});

export const addConfigOptionValueItem = (): PayloadLessAction => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_ADD,
});

export const removeConfigOptionValueItem = (index: number): Action<number> => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_DELETE,
  payload: index,
});

export const toggleConfigOptionValueItemPosition = (
  pos1: number,
  pos2: number,
): Action<IToggleConfigOptionValuePosition> => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_TOGGLE,
  payload: { pos1, pos2 },
});

export const saveConfigOptionStarted = (): PayloadLessAction => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_SAVE_STARTED,
});

export const saveConfigOptionEnded = (): PayloadLessAction => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_SAVE_ENDED,
});

export const setConfigOptionValueErrors = (
  indexes: number[],
): Action<number[]> => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_SET_VALUE_ERRORS,
  payload: indexes,
});

export const setConfigOptionTextErrors = (
  indexes: number[],
): Action<number[]> => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_FORM_VALUE_SET_TEXT_ERRORS,
  payload: indexes,
});

export const setConfigOptionError = (): PayloadLessAction => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_FORM_SET_ERROR,
});

export const resetConfigOptionForm = (): PayloadLessAction => ({
  type: ActionType.CONFIG_OPTIONS_DETAILS_FORM_RESET,
});

export const loadDetails =
  (id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadDetailsStart());
    const detailsResponse = await fetchApi({
      endpoint: `${Index.SEGMENT_CONFIG_OPTIONS}/${id}`,
      method: FetchMethod.GET,
    });
    dispatch(setLoadDetailsEnd());
    const details = get(detailsResponse, 'data.0', {}) as IConfigOption;
    const mappedValues = get(details, 'values', []).map(
      (item: { value: string; text: string }) => ({
        ...item,
        id: uuid(),
        hasTextError: false,
        hasValueError: false,
      }),
    );
    if (detailsResponse?.error || !details?.id) {
      showErrorToast(LOCALE_LOAD_FAILED);
      return;
    }
    dispatch(
      setConfigOptionForm({
        ...details,
        configOptionError: false,
        values: mappedValues,
      }),
    );
  };

export const handleSave =
  (navigate: NavigateFunction) =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    const state = getState();
    const configOptionsForm = configOptionsSelectors.getConfigOptionForm(
      state,
    ) as IConfigOptionUiForm;
    if (!configOptionsForm.configOptionId) {
      dispatch(setConfigOptionError());
      return;
    }
    const valueErrorIndexes = configOptionsForm.values.reduce(
      (acc: number[], item, index) => {
        if (!item.value) return [...acc, index];
        return acc;
      },
      [],
    );
    const textErrorIndexes = configOptionsForm.values.reduce(
      (acc: number[], item, index) => {
        if (!item.text) return [...acc, index];
        return acc;
      },
      [],
    );
    if (!isEmpty(valueErrorIndexes) || !isEmpty(textErrorIndexes)) {
      dispatch(setConfigOptionTextErrors(textErrorIndexes));
      dispatch(setConfigOptionValueErrors(valueErrorIndexes));
      return;
    }
    dispatch(saveConfigOptionStarted());
    const isUpdating = !!configOptionsForm.id;
    const sanitizedForm = {
      ...(isUpdating && { id: configOptionsForm.id }),
      configOptionId: configOptionsForm.configOptionId,
      values: configOptionsForm.values.map(({ text, value }) => ({
        text,
        value,
      })),
    };
    const saveResult = await fetchApi({
      endpoint: Index.SEGMENT_CONFIG_OPTIONS,
      payload: sanitizedForm,
      method: isUpdating ? FetchMethod.PUT : FetchMethod.POST,
    });
    dispatch(saveConfigOptionEnded());
    if (saveResult?.error) {
      showErrorToast(saveResult?.error?.message ?? LOCALE_SAVE_FAILED);
      return;
    }
    navigate(`/${Index.SEGMENT_ADMIN}/${Index.SEGMENT_CONFIG_OPTIONS}`);
    await delay(500);
    showSuccessToast(
      isUpdating ? LOCALE_UPDATE_SUCCEEDED : LOCALE_SAVE_SUCCEEDED,
    );
  };
