import { get } from 'lodash/fp';
import { ChartDataSet, ResultBaseSubStructure } from 'domains/reports/types';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import {
  IBubbleChartData,
  IBubbleChartPoint,
  IBubbleChartSeries,
} from '../components/BubbleChart';
import { ITableDataItem } from '../components/TableWrapper';
import { computePointId } from './helpers/computePointId';
import { computePointName } from './helpers/computePointName';
import { mapValuesToMetrics } from 'helpers/charts/mapValuesToMetrics';
import {
  DataRowWithTarget,
  IChartAndTableTransformerInputData,
  InputFilters,
  ITransformerInput,
  ITransformerOutput,
} from './types';

export const useBubbleChartAndTableTransformer = (
  input: ITransformerInput,
): ITransformerOutput<IBubbleChartSeries> => {
  const { filters, filteredData, metrics, conversionType } = input;
  const {
    xAxis,
    yAxis,
    bubbleSize,
    breakout,
    targets,
    sortedBy,
    isSortedDesc,
  } = filters;

  const chartSeries = useMemo(() => {
    const transformData = (
      d: ResultBaseSubStructure,
      i: number,
      target: string | number,
    ): IBubbleChartPoint => {
      return {
        x: Number(get(xAxis, d)?.VALUE ?? 0),
        y: Number(get(yAxis, d)?.VALUE ?? 0),
        z: Number(get(bubbleSize, d)?.VALUE ?? 0),
        name: computePointName(d),
        id: computePointId(d, target) ?? `id${i}`,
        ...mapValuesToMetrics(metrics, d, conversionType),
      };
    };

    const getSeriesData = (
      dataGroup: ResultBaseSubStructure[],
      target: string | number,
    ): IBubbleChartData =>
      // NOTE: Use reduce instead of map to filter out 'FULL_CAMPAIGN' in one iteration
      dataGroup.reduce((prev, current, index) => {
        if (current.name?.VALUE === 'FULL_CAMPAIGN') {
          return prev;
        }

        return [...prev, transformData(current, index, target)];
      }, [] as IBubbleChartData);

    return targets.map((target) => {
      const dataGroup =
        filteredData[
          target.value as keyof IChartAndTableTransformerInputData
        ] ?? [];

      return {
        data: getSeriesData(dataGroup, target.value).filter(
          (item) =>
            !(
              Number(get('campaign.REACH', item)) === 0 &&
              Number(get('campaign.IMPRESSIONS', item)) === 0 &&
              Number(get('campaign.SPOT_COUNT', item)) === 0
            ),
        ),
        color: target.color,
        name: target.value as string,
      };
    });
  }, [
    targets,
    xAxis,
    yAxis,
    bubbleSize,
    metrics,
    conversionType,
    filteredData,
  ]);

  const tableData = useMemo(() => {
    const getTargetedTableData = (
      dataGroup: DataRowWithTarget,
      index: number,
    ): ITableDataItem => ({
      name: computePointName(dataGroup),
      [InputFilters.xAxis]: Number(get(xAxis, dataGroup)?.VALUE ?? 0),
      [InputFilters.bubbleSize]: Number(get(bubbleSize, dataGroup)?.VALUE ?? 0),
      [InputFilters.yAxis]: Number(get(yAxis, dataGroup)?.VALUE ?? 0),
      id: computePointId(dataGroup, dataGroup.target) ?? `id${index}`,
      target: dataGroup.target,
      color: dataGroup.color,
      breakout,
      ...mapValuesToMetrics(metrics, dataGroup, conversionType),
    });

    const mergedTargets = targets.reduce((prevTargetData, currentTarget) => {
      const dataGroup =
        filteredData[
          currentTarget.value as keyof IChartAndTableTransformerInputData
        ] ?? [];

      const currentTargetDataGroup = dataGroup.map((group) => ({
        ...group,
        target: currentTarget.value,
        color: currentTarget.color,
      }));

      return [...prevTargetData, ...currentTargetDataGroup];
    }, [] as ChartDataSet[]) as DataRowWithTarget[];

    const sortedData = orderBy(
      mergedTargets,
      (d) => get(sortedBy, d)?.VALUE ?? 0,
      [isSortedDesc ? 'desc' : 'asc'],
    );

    return sortedData.map(getTargetedTableData);
  }, [
    targets,
    isSortedDesc,
    xAxis,
    bubbleSize,
    yAxis,
    breakout,
    metrics,
    conversionType,
    filteredData,
    sortedBy,
  ]);

  return { chartSeries, tableData };
};
