import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';
import Flex, { Direction } from '../Flex';

export const reportFooterComponentName = 'report-footer';

export enum States {
  disclaimer = 'disclaimer',
  background = 'background',
}

export interface IFooterProps {
  testId?: string;
  background?: boolean;
  hasDisclaimer?: boolean;
  disclaimerHref?: string;
}

const currentYear: number = new Date().getFullYear();

const ReportFooter: FunctionComponent<IFooterProps> = (props): ReactElement => {
  const { background, hasDisclaimer, disclaimerHref, testId } = props;

  const reporterFooterTestId = getTestId(reportFooterComponentName, testId);
  const reportFooter = getClass(reportFooterComponentName);
  const reportFooterClass = getClass(reportFooterComponentName, {
    boolean: [
      {
        state: background,
        class: States.background,
      },
      {
        state: hasDisclaimer,
        class: States.disclaimer,
      },
    ],
  });

  return (
    <footer className={reportFooterClass} data-testid={reporterFooterTestId}>
      <Flex direction={Direction.column}>
        {hasDisclaimer && (
          <p
            className={`${reportFooter}-disclaimer`}
            data-testid={`${reporterFooterTestId}-disclaimer`}
          >
            Disclaimer: Access for authorized users only, subject to the 605
            User Agreement. By logging into the application, you read and agree
            to our
            <a
              href={disclaimerHref}
              target="_blank"
              rel="noopener noreferrer"
              data-testid={`${reporterFooterTestId}-disclaimer-link`}
            >
              {' Terms and Conditions'}
            </a>
          </p>
        )}
        <p className={`${reportFooter}-rights`}>
          © {currentYear} 605, LLC. All Rights Reserved.
        </p>
      </Flex>
    </footer>
  );
};

export default ReportFooter;
