import Flex, { Horizontal } from 'components/Flex';
import Input, { Type as InputType } from 'components/Input';
import Label from 'components/Label';
import { getTestId, getClass, getId } from 'helpers/components';
import React, { useState, FunctionComponent, ReactElement } from 'react';

export const checkboxComponentName = 'checkbox';

export enum State {
  disabled = 'disabled',
}

export enum Size {
  large = 'large',
  small = 'small',
}

export interface ICheckboxProps {
  id?: string;
  size?: Size;
  label?: string;
  testId?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (check: boolean) => void;
}

const Checkbox: FunctionComponent<ICheckboxProps> = (props): ReactElement => {
  const { disabled, size, testId, label, checked, id, onChange } = props;

  const [check, setCheck] = useState(!!checked);

  React.useEffect(() => {
    setCheck(!!checked);
  }, [checked]);

  const checkboxId = getId(checkboxComponentName, id);
  const checkboxTestId = getTestId(checkboxComponentName, testId);
  const checkboxClass = getClass(checkboxComponentName, {
    text: [size],
    boolean: [{ state: disabled, class: State.disabled }],
  });

  const handleChange = (): void => {
    setCheck(!check);
    if (onChange) onChange(!check);
  };

  return (
    <div className={checkboxClass} data-testid={checkboxTestId}>
      <Flex horizontal={Horizontal.center}>
        <Input
          id={checkboxId}
          type={InputType.checkbox}
          disabled={disabled}
          testId={`${checkboxTestId}-input`}
          checked={check}
          onChange={handleChange}
        />
        <Label
          forId={checkboxId}
          text={label}
          testId={`${checkboxTestId}-label`}
          disabled={disabled}
        />
      </Flex>
    </div>
  );
};

export default Checkbox;
