import { ResultEnhancedDemographicGroups } from 'domains/reports/types';
import { forEach, get } from 'lodash';
import { ITransformerInputFilters, TSegmentedData } from '..';
import { createPointName } from './createPointName';
import { updateSegmentData } from './updateSegmentData';

/**
 * Converts SegmentsDataSet data with filtered breakouts to the format of object with keys corresponding to segments names.
 * Segment value is an array of chart points, with shape: {value, color}, where value is POPULATION_INDEX.VALUE.
 *
 * @param data
 * filtered chart data from SegmentsDataSet object, should contain only the filtered segments
 * @param filters
 * provide the DEMOGRAPHIC metric, and segments
 * @returns
 * {
 *   'key1': [
 *     {
 *       value: [number],
 *       color: [string]
 *     }
 *   ],
 *   'key2': [
 *     {
 *       value: [number],
 *       color: [string]
 *     }
 *   ]
 * }
 */

export const getSegmentedData = (
  data: ResultEnhancedDemographicGroups,
  filters: ITransformerInputFilters,
): TSegmentedData => {
  let segmentedData: TSegmentedData = {};
  const { demographic, targets, segments } = filters;

  // Note: targetData is needed only to obtain Metric keys
  // For SegmentedData we allow just 1 target
  forEach(segments, (segment) => {
    const targetValue: string = targets[0].value as string;
    const targetText = targets[0].text;
    const sData = get(data, segment.value);
    const targetData = get(sData, demographic);
    const demographicData = get(targetData, targetValue);
    // TODO: handle empty demographicData scenario:
    // if (!demographicData) return;

    const color = segment?.color ?? '';
    const name = createPointName(segment?.text ?? '', targetText);
    segmentedData = updateSegmentData(
      segmentedData,
      demographicData,
      color,
      name,
    );
  });

  return segmentedData;
};
