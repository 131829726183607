import { useAuth0 } from '@auth0/auth0-react';
import IClient from 'domains/clients/types';
import useSelectedClient, {
  getClientInCookie,
  getClientInSessionStorage,
  clientIsValid,
} from 'domains/clients/useSelectedClient';
import { IPermissionTree } from 'domains/permissions/types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStateNotLoaded } from 'store/actions/root';
import { setToken as setStateToken } from 'store/actions/user';
import State from 'types/state';
import ILoggedUserState from 'types/userState';

export type HookReturn = {
  currentLoggedUser?: ILoggedUserState;
  email?: string;
  hasStateLoaded: boolean;
  isLogged: boolean;
  permissions?: IPermissionTree;
  selectedClient?: string;
  setCurrentUser: (user: ILoggedUserState) => void;
  setStateNotLoaded: () => void;
  setToken: (t?: string) => void;
  token?: string;
  userClients?: string[];
  userId?: string;
  userName?: string;
};

const useCurrentUser = (): HookReturn => {
  const { isAuthenticated } = useAuth0();

  const { currentLoggedUser, hasStateLoaded } = useSelector(
    ({ loaded, user: loggedUser }: State) => ({
      currentLoggedUser: loggedUser,
      hasStateLoaded: loaded,
    }),
  );

  const { setClientCookie } = useSelectedClient();

  const dispatch = useDispatch();

  const setCurrentUser = (u: ILoggedUserState): void => {
    let storedStorageClient: IClient = getClientInSessionStorage();
    let storedCookieClient: IClient = getClientInCookie();
    if (!clientIsValid(storedStorageClient, u.clients)) {
      // This checks the session storage first and falls back to the cookie.
      storedStorageClient = u?.clients?.[0] ?? { id: '', name: '' };
      if (storedStorageClient && clientIsValid(storedStorageClient)) {
        setClientCookie(storedStorageClient);
      }
    } else if (!clientIsValid(storedCookieClient, u.clients)) {
      storedCookieClient = u?.clients?.[0] ?? { id: '', name: '' };
      if (storedCookieClient && clientIsValid(storedCookieClient)) {
        setClientCookie(storedCookieClient);
      }
    }
  };

  const setToken = useCallback(
    (t?: string): void => {
      if (t) dispatch(setStateToken(t));
    },
    [dispatch],
  );

  const setStateNotLoadedFunction = useCallback(() => {
    dispatch(setStateNotLoaded());
  }, [dispatch]);

  return {
    currentLoggedUser,
    email: currentLoggedUser.email,
    hasStateLoaded,
    isLogged: isAuthenticated,
    permissions: currentLoggedUser.permissions,
    selectedClient: currentLoggedUser.selectedClient,
    setCurrentUser,
    setStateNotLoaded: setStateNotLoadedFunction,
    setToken,
    token: currentLoggedUser.token,
    userClients: currentLoggedUser.clients?.length
      ? (currentLoggedUser.clients?.map((client) => client.id) as string[])
      : [],
    userId: currentLoggedUser.id,
    userName: currentLoggedUser.name,
  };
};

export default useCurrentUser;
