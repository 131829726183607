import Button, { Kind } from 'components/Button';
import { Type } from 'components/Icon';
import { getClass, getId, getTestId } from 'helpers/components';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import useToast from 'hooks/useToast';
import { noop } from 'lodash';
import React, {
  ChangeEvent,
  FunctionComponent,
  MutableRefObject,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export const componentName = 'inline-edit';

const LOCALE_ENTER_NAME = 'Please enter a name.';

const DOM_KEY_VALUE_WRAPPER = 'value-wrapper';
const DOM_KEY_VALUE = 'value';

const CLASS_EDIT_MODE_STATE = 'edit-mode';

export interface IProps {
  disabled?: boolean;
  id?: string;
  onEdit?:
    | ((newValue: string, skipSave?: boolean) => void)
    | ((newValue: string, skipSave?: boolean) => Promise<void>);
  onCancel?: (newValue?: string) => void;
  onEditMode?: () => void;
  placeholder?: string;
  testId?: string;
  value?: string;
}

const InlineEdit: FunctionComponent<IProps> = (props): ReactElement => {
  const {
    disabled = false,
    id,
    onEdit,
    onCancel,
    onEditMode,
    placeholder,
    testId,
    value: defaultValue = '',
  } = props;

  const [value, setValue] = useState<string>(defaultValue);
  const [isEditing, setIsEditing] = useState(false);

  const container = useRef() as MutableRefObject<HTMLDivElement>;
  const input = useRef() as MutableRefObject<HTMLInputElement>;
  const { doWarningToast } = useToast();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (isEditing && input) input.current.focus();
  }, [input, isEditing]);

  const valueIsEmpty = useMemo(
    (): boolean => value === '' || value === undefined,
    [value],
  );

  const cancelChanges = (): void => {
    setValue(defaultValue);
    setIsEditing(false);

    if (onCancel) {
      onCancel();
    }
  };

  const handleNameSave = (): void => {
    if (!isEditing) {
      return;
    }

    if (valueIsEmpty) {
      doWarningToast(LOCALE_ENTER_NAME);
      return;
    }

    setIsEditing(false);

    if (onEdit) {
      onEdit(value);
    }
  };

  useOnOutsideClick(container, handleNameSave, { scoped: true });

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event?.target?.value;
    setValue(newValue);
    onEdit?.(newValue, true);
  };

  const handleOpenEditMode = (): void => {
    setIsEditing(true);

    if (onEditMode) {
      onEditMode();
    }
  };

  const handleOnFocus = (): void => {
    handleOpenEditMode();
  };

  const componentId = useMemo(() => getId(componentName, id), [id]);
  const inlineEditTestId = useMemo(
    () => getTestId(componentName, testId),
    [testId],
  );
  const inputFieldTestId = useMemo(() => getTestId(componentName, 'input'), []);
  const cancelButtonTestId = useMemo(
    () => getTestId(componentName, 'cancel'),
    [],
  );
  const confirmButtonTestId = useMemo(
    () => getTestId(componentName, 'confirm'),
    [],
  );
  const editComponentClass = useMemo(
    () => getClass(componentName, { concat: ['edit'] }),
    [],
  );
  const valueWrapperClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_VALUE_WRAPPER] }),
    [],
  );
  const valueClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_VALUE] }),
    [],
  );

  const componentClass = useMemo(
    () =>
      getClass(componentName, {
        boolean: [{ state: isEditing, class: CLASS_EDIT_MODE_STATE }],
      }),
    [isEditing],
  );

  return (
    <div
      className={componentClass}
      data-testid={inlineEditTestId}
      id={componentId}
    >
      {isEditing ? (
        <div ref={container} className={editComponentClass}>
          <h1 className={valueClass}>
            <input
              ref={input}
              type="text"
              value={value}
              onKeyDown={noop}
              onChange={handleChange}
              data-testid={inputFieldTestId}
              placeholder={placeholder}
              onFocus={handleOnFocus}
            />
          </h1>
          <Button
            kind={Kind.icon}
            iconType={Type.checkmark}
            onClick={handleNameSave}
            testId={confirmButtonTestId}
          />
          <Button
            kind={Kind.icon}
            iconType={Type.close}
            onClick={cancelChanges}
            testId={cancelButtonTestId}
          />
        </div>
      ) : (
        <div className={valueWrapperClass}>
          <h1
            onKeyDown={noop}
            className={valueClass}
            onClick={disabled ? noop : handleOpenEditMode}
          >
            {value || placeholder}
          </h1>
        </div>
      )}
    </div>
  );
};

export default InlineEdit;
