import Flex, { Horizontal } from 'components/Flex';
import H2 from 'components/H2';
import { SectionOmissionAlert } from 'components/SectionOmissionAlert';
import IReport, {
  ExposureConversionSetNames,
  PerformanceGroupNames,
  ResultBase,
} from 'domains/reports/types';
import IResultSelection from 'domains/resultSelections/types';
import React from 'react';
import { IOption } from 'types/textValue';
import State from 'types/state';
import { connect } from 'react-redux';
import { getPerformanceMetricGroupsFromLastValidReport } from 'store/selectors/report';
import { getConversionType } from 'store/selectors/reportResult';
import useUserPermissions from 'hooks/useUserPermissions';
import CampaignDeliveryOutcomes from '../CampaignDeliveryOutcomes';
import CampaignDeliveryConversions from '../CampaignDeliveryConversions';

export interface ICampaignDeliveryAndInsightsProps {
  targets: IOption[];
  breakoutChartData: ResultBase;
  timeseriesChartData: ResultBase;
  performanceMetricGroups: PerformanceGroupNames[];
  conversionType?: string;
  showLroiInVisualization?: boolean;
  useEquivalizedMetrics?: boolean;
  breakoutList: Array<{ id: string; name: string }>;
  omissionMessages: ExposureConversionSetNames[];
  resultSelection: IResultSelection;
  lastValidReport: IReport | null;
}

const rowsUsedInSection: ExposureConversionSetNames[] = [
  'exposed_all.total',
  'exposed_all.VIEWING_TYPE',
  'exposed_all.NETWORK',
  'exposed_all.NET_ON_OFF',
  'exposed_all.CREATIVE_LABEL',
  'exposed_all.DAYPART',
  'exposed_all.CONTENT_SERIES_TITLE',
  'exposed_all.NETWORK_GENRE',
  'exposed_all.DAY_OF_WEEK',
  'exposed_all.DURATION_ROUNDED_LABEL',
  'exposed_all.timeseries',
  'exposed_all.timeseries_cumulative',
  'exposed_converted.timeseries',
  'exposed_converted.timeseries_cumulative',
];

const CampaignDeliveryAndInsights: React.FC<
  ICampaignDeliveryAndInsightsProps
> = ({
  targets,
  breakoutChartData,
  performanceMetricGroups,
  conversionType,
  timeseriesChartData,
  breakoutList,
  omissionMessages,
  resultSelection,
  lastValidReport,
  showLroiInVisualization,
  useEquivalizedMetrics,
}) => {
  const { checkPermissions } = useUserPermissions();

  return (
    <section id="CAMPAIGN_DELIVERY_AND_INSIGHTS">
      <Flex className="mb-2" horizontal={Horizontal.left}>
        <div>
          <H2>Campaign Delivery and Insights</H2>
        </div>
        <SectionOmissionAlert
          omissionMessages={omissionMessages}
          rowsUsedInSection={rowsUsedInSection}
        />
      </Flex>
      <CampaignDeliveryOutcomes
        chartData={breakoutChartData}
        performanceMetricGroups={performanceMetricGroups}
        conversionType={conversionType}
        targets={targets}
        useEquivalizedMetrics={useEquivalizedMetrics}
        breakoutList={breakoutList}
        sectionFiltersSelection={
          resultSelection.filters?.campaignDeliveryOutcomes
        }
        sectionRowsRemovedSelection={
          resultSelection.rowsRemoved?.campaignDeliveryOutcomes
        }
        lastValidReport={lastValidReport}
      />
      {showLroiInVisualization &&
        checkPermissions(
          'attribution_reports.campaign_delivery_and_conversions_by_date::view',
        ) && (
          <CampaignDeliveryConversions
            chartData={timeseriesChartData}
            performanceMetricGroups={performanceMetricGroups}
            targets={targets}
            useEquivalizedMetrics={useEquivalizedMetrics}
            sectionFiltersSelection={
              resultSelection.filters?.campaignDeliveryConversions
            }
            lastValidReport={lastValidReport}
          />
        )}
    </section>
  );
};

const mapStateToProps = (
  state: State,
): {
  performanceMetricGroups: PerformanceGroupNames[];
  conversionType: string;
} => ({
  performanceMetricGroups: getPerformanceMetricGroupsFromLastValidReport(state),
  conversionType: getConversionType(state),
});

export default connect(mapStateToProps)(CampaignDeliveryAndInsights);
