import {
  ResultBaseSubStructure,
  ResultBaseTotalDataTarget,
} from 'domains/reports/types';

export const mergeBreakoutDataTargets = ({
  parentBreakoutData,
  childBreakoutData,
}: {
  parentBreakoutData: ResultBaseTotalDataTarget;
  childBreakoutData: ResultBaseTotalDataTarget;
  parentBreakout: string;
  childBreakout: string;
}): ResultBaseTotalDataTarget => {
  const target =
    childBreakoutData.target && parentBreakoutData.target
      ? [
          ...(parentBreakoutData.target as ResultBaseSubStructure[]),
          ...(childBreakoutData.target as ResultBaseSubStructure[]),
        ]
      : undefined;

  const genpop =
    childBreakoutData.genpop && parentBreakoutData.genpop
      ? [
          ...(parentBreakoutData.genpop as ResultBaseSubStructure[]),
          ...(childBreakoutData.genpop as ResultBaseSubStructure[]),
        ]
      : undefined;
  return { target, genpop };
};
