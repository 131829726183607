import Loading from 'components/Loading';
import IReport, { IBusinessData } from 'domains/reports/types';
import { get, isEmpty } from 'lodash/fp';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Index } from 'routes';
import * as reportActions from 'store/actions/report';
import * as businessDataSelectors from 'store/selectors/businessData';
import * as reportSelectors from 'store/selectors/report';
import State from 'types/state';
import ReportsEdit from '../../EditFeature/edit.feature';

const targetsUrl = `${Index.SEGMENT_HOME}${Index.SEGMENT_TARGETS}`;

export interface IReportsEditContainer {
  report?: IReport;
  savedReport?: IReport;
  availableTargets?: IReport[];
  businessData?: IBusinessData;
  subType?: string;
  loadNewReport?: (isTarget: boolean, subType?: string) => void;
  loadReportFull?: (reportId: string, isTarget: boolean) => void;
  setReport?: (report: IReport | null) => void;
  path: string;
}

const ReportsEditContainer = (props: IReportsEditContainer): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const {
    path,
    report,
    savedReport,
    businessData,
    availableTargets,
    subType,
    loadReportFull,
    loadNewReport,
    setReport,
  } = props;

  const isTarget = path.includes(targetsUrl);
  const [loading, setLoading] = useState(false);

  // Unmount
  useEffect(
    () => (): void => {
      setReport?.(null);
    },
    [setReport],
  );

  useEffect(() => {
    if (loading || (report?.id && report?.id === id)) return;
    setLoading(true);
    if (id && !loading) {
      loadReportFull?.(id, isTarget);
    } else {
      loadNewReport?.(isTarget, subType ?? '');
    }
  }, [id, isTarget, loadNewReport, loadReportFull, loading, report]);

  const reportIsLoaded =
    !isEmpty(businessData) &&
    id &&
    report?.id === id &&
    !report.notPersistedProps?.isBaseReportNew &&
    availableTargets;
  const targetIsLoaded =
    !isEmpty(businessData) &&
    id &&
    report?.id === id &&
    !report?.notPersistedProps?.isBaseReportNew;
  const reportIsNew =
    !id &&
    report?.type &&
    report?.notPersistedProps?.isBaseReportNew &&
    !isEmpty(businessData);

  return (
    <>
      {isTarget ? (
        <>
          {(targetIsLoaded || reportIsNew) && savedReport ? (
            <ReportsEdit path={path} />
          ) : (
            <Loading />
          )}
        </>
      ) : (
        <>
          {(reportIsLoaded || reportIsNew) && savedReport ? (
            <ReportsEdit path={path} />
          ) : (
            <Loading />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (
  state: State,
): {
  report: IReport;
  savedReport: IReport;
  availableTargets: IReport[];
  businessData: IBusinessData;
} => ({
  report: reportSelectors.getReport(state),
  savedReport: reportSelectors.getSavedReport(state),
  availableTargets: businessDataSelectors.getAvailableTargets(state),
  businessData: get('businessData', state) as IBusinessData,
});

const mapDispatchToProps = {
  loadReportFull: reportActions.loadReportFull,
  loadNewReport: reportActions.loadNewReport,
  setReport: reportActions.setReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsEditContainer);
