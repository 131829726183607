export const computeSignificanceValue = (
  pValue: string | number | undefined | null,
): string => {
  if (pValue === undefined || pValue === null) return '';

  const value = Number(pValue);

  if (value <= 0.01) return '***';
  if (value <= 0.05) return '**';
  if (value <= 0.1) return '*';
  return '';
};
