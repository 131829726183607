import { v4 as uuid } from 'uuid';
import { IDataset } from './types';

export default class Dataset implements IDataset {
  display_name: string;

  name: string;

  type: string;

  id: string;

  dataset_id: string;

  constructor(dataset?: IDataset) {
    this.id = dataset?.id ?? uuid();
    this.dataset_id = dataset?.name ?? '';
    this.name = dataset?.name ?? '';
    this.display_name = dataset?.display_name ?? '';
    this.type = dataset?.type ?? '';
  }
}
