import { MethodologySection } from 'features/attributionReports/components/Methodology/Methodology.component';
import { IMethodologySectionProps } from 'features/attributionReports/components/Methodology/types';
import { connect } from 'react-redux';
import * as reportActions from 'store/actions/report';
import * as reportSelectors from 'store/selectors/report';
import * as toolbarSelectors from 'store/selectors/toolbar';
import State from 'types/state';

const mapDispatchToProps = {
  setAggregationMethodAction: reportActions.setAggregationMethodAction,
};

type ConnectedProps = Omit<
  IMethodologySectionProps,
  keyof typeof mapDispatchToProps
>;

const mapStateToProps = (state: State): ConnectedProps => ({
  report: reportSelectors.getReport(state),
  toolbar: toolbarSelectors.getToolbar(state),
  aggregationMethod: reportSelectors.getAggregationMethod(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MethodologySection);
