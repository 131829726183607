import { Placement, Style } from 'components/Sticker';
import Table from 'components/Table';
import { Tooltip } from 'components/Tooltip';
import {
  DataRowGraphWithKey,
  IKeyString,
  ResultBase,
} from 'domains/reports/types';
import { getTableDataWithSignificance } from 'helpers/charts/getTableDataWithSignificance';
import { getClass } from 'helpers/components';
import get from 'lodash/get';
import pick from 'lodash/pick';
import set from 'lodash/set';
import React, { ReactNode, useMemo } from 'react';
import { Column } from 'react-table';
import { tableTransform } from './transformations';

const overviewComponentName = 'overview';

export type TableOptions = {
  columns: Column[];
  keyRename: { [key: string]: string };
  path: string;
};

export type Props = TableOptions & {
  chartData: ResultBase;
  targets?: string[];
  performanceMetricGroups: string[];
  conversionType?: string;
  omissionMessages?: string[];
};

export const REPORT_OVERVIEW_TOOLTIPS: IKeyString = {
  'Reach (000)': 'The number of unduplicated households that watched.',
  'Impressions (000)': 'The quantity of times that an ad was watched.',
  'Eq. Impressions (000)':
    'The quantity of times that an ad was watched, expressed in thousands, weighted.',
  'Impression Frequency': 'The average number of ads watched per household.',
  'Eq. Frequency': 'The average number of ads watched per household, weighted.',
  'Percent Lift':
    'The percentage difference between the exposed and unexposed.',
  'Exposed Conversion Rate':
    'The rate at which exposed households converted or performed a certain action.',
  Frequency: 'The average number of ads watched per household.',
  'Universe (000)': 'The size of the universe.',
  'Incremental Lift (000)':
    'The number of households that were converted because of their exposure to the ads',
};

export const TARGET_COLUMN_NAME_CHARACTER_LIMIT = 113;

export default function ReportOverview({
  chartData,
  columns,
  path,
  targets,
  omissionMessages,
  performanceMetricGroups,
  conversionType,
  ...reportOverviewOptions
}: Props): React.ReactElement {
  const columnsWithTooltips = useMemo(
    () =>
      columns.map(
        ({ Header, ...column }) =>
          ({
            ...column,
            Header: (
              <Tooltip
                content={REPORT_OVERVIEW_TOOLTIPS[Header as string]}
                placement={Placement.top}
                style={Style.secondary}
              >
                <span>{Header as ReactNode}</span>
              </Tooltip>
            ),
          }) as Column,
      ),
    [columns],
  );

  const genpopUniverse = get(chartData, 'total.genpop[0].campaign.REACH', {});
  const targetUniverse = get(chartData, 'total.target[0].campaign.REACH', {});
  let data: DataRowGraphWithKey[] = [];
  const hasTarget = targets?.length && targets.length > 0;
  const pathData = get(chartData, path);
  if (pathData) {
    data = tableTransform(
      pick(pathData, ['genpop'].concat(hasTarget ? ['target'] : [])),
      reportOverviewOptions,
      targets,
      performanceMetricGroups,
      conversionType,
    )
      .reverse()
      .map((datum) => {
        const universeData = {
          universe: datum.target === 'genpop' ? genpopUniverse : targetUniverse,
        };

        return {
          ...datum,
          ...universeData,
        };
      });
    if (data.length > 1) {
      const targetName = get(data, '[0].key', '');

      if (targetName.length > TARGET_COLUMN_NAME_CHARACTER_LIMIT) {
        set(
          data,
          '[0].key',
          `${targetName.substr(0, TARGET_COLUMN_NAME_CHARACTER_LIMIT - 3)}...`,
        );
      }
    }
  }

  const overviewDefaultClass = getClass(overviewComponentName);
  const reportOverviewCustomComponent = 'report-overview-custom';
  const reportOverviewCustomClass = getClass(reportOverviewCustomComponent);
  const currentTableData = getTableDataWithSignificance(data);

  return (
    <div
      className={`${overviewDefaultClass} ${reportOverviewCustomClass} ${!hasTarget ? 'genpop-only' : ''}`}
    >
      <Table
        cellspacing="0"
        columns={columnsWithTooltips}
        data={currentTableData}
        id="report_overview_table"
        sortable={false}
        goToEditOnRowClick
      />
    </div>
  );
}
