import Skeleton, { Size } from 'components/Skeleton';
import React from 'react';
import Flex, { Direction, Horizontal, Vertical } from '../Flex';

const Loading: React.FunctionComponent = () => (
  <Flex
    className="loading-wrapper"
    direction={Direction.column}
    horizontal={Horizontal.left}
    vertical={Vertical.top}
  >
    <Skeleton length="40%" />
    <Skeleton length="60%" size={Size.small} />
    <Skeleton length="100%" size={Size.cover} />
    <Skeleton length="100%" size={Size.small} />
  </Flex>
);

export default Loading;
