import IMessage from 'domains/messageList/types';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';

export const initialState: IMessage[] = [];

export default (
  eventList: IMessage[] = initialState,
  action: Action<IMessage[]>,
): IMessage[] => {
  const { payload, type } = action;
  switch (type) {
    case ActionType.MESSAGE_LIST_SET_MESSAGES:
      return payload;
    default:
      return eventList;
  }
};
