import IReport, {
  AttributionFilterType,
  ReportDatasetSelection,
  ReportDatasetsSelections,
  reportSubType,
} from 'domains/reports/types';
import { getAttributionFiltersFromQueryFilters } from 'features/attributionReports/components/AttributionQueryBuilder/adapters';
import difference from 'lodash/difference';
import { get, getOr } from 'lodash/fp';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Index } from 'routes';
import IDateRange from 'types/dateRange';
import IError from 'types/error';
import { IChildrenBase, IQueryFilters, RuleFilter } from 'types/filter';
import { IQuery } from 'types/query';
import State from 'types/state';
import FetchMethod from '../types/fetchMethod';
import useFetch from './useFetch';
import useReports from './useReports';

interface IFetchAttributionResults<T> {
  onError: (error: IError) => void;
  onSuccess: (result: T) => void;
}

interface IFetchAttributionReports extends IFetchAttributionResults<IReport[]> {
  clientExtID: string;
}

interface IFetchAttributionReportById
  extends IFetchAttributionResults<IReport> {
  id: string;
}

export type HookReturn = {
  reports: IReport[];
  isQueryValid: (
    query: IQuery<IQueryFilters<IQueryFilters<IChildrenBase>[]>>,
    datasetSelection: ReportDatasetSelection,
    subType: string,
  ) => boolean;
  getReportSubTypeKey: (segment?: string) => string;
  loading: boolean;
  fetchAttributionReports: (fetchOptions: IFetchAttributionReports) => void;
  fetchAttributionReportById: (
    fetchOptions: IFetchAttributionReportById,
  ) => void;
  hasFetchedAttributionReports: boolean;
  hasFetchedAttributionReport: boolean;
  getTargetName: (targets: IReport[], report: IReport) => string;
  checkInvalidTarget: (targets: IReport[], report: IReport) => boolean;
  hasSameFilterSelections(
    filtersA: IChildrenBase,
    filtersB: IChildrenBase,
  ): boolean;
};

export function getValidAndInvalidTargets(
  targets: IReport[],
  report: IReport,
): string[][] {
  if (targets.length > 0) {
    const { targets: queryTargets = [] } = report.query;

    if (queryTargets.length > 0) {
      const validQueryTargets = targets
        .filter((target) => queryTargets.includes(target.id))
        .map((target) => target.id);

      const invalidTargets = difference(queryTargets, validQueryTargets);

      return [validQueryTargets, invalidTargets];
    }
  }

  return [];
}

const useAttributionReports = (): HookReturn => {
  const { doFetch, loading } = useFetch<IReport>();

  const {
    hasQueryAdsDatasets,
    hasQueryCustomAdsDatasets,
    hasQueryCustomExposureDatasets,
  } = useReports();

  const reports = useSelector(
    ({
      domains: { attributionReports: attributionReportsState = [] },
    }: State) => attributionReportsState,
  );

  const [hasFetchedAttributionReports, setFetchedAttributionReports] =
    useState(false);
  const [hasFetchedAttributionReport, setFetchedAttributionReport] =
    useState(false);

  const MANDATORY_RULES: (string | undefined)[] = [
    'TOTAL_WATCHED',
    'BRAND_LABEL',
    'VIEWING_TYPE',
    'NETWORK',
    'CONTENT_SERIES',
  ];

  const fetchAttributionReports = useCallback(
    ({ clientExtID, onSuccess, onError }: IFetchAttributionReports) => {
      setFetchedAttributionReports(true);
      doFetch({
        endpoint: `${Index.SEGMENT_REPORTS}/${Index.SEGMENT_ATTRIBUTION_REPORTS}/?clientExtID=${clientExtID}`,
        method: FetchMethod.GET,
        onSuccess: (res: IReport[]) => {
          onSuccess(res);
        },
        onError: (error: IError) => {
          onError(error);
        },
      });
    },
    [doFetch],
  );

  const fetchAttributionReportById = useCallback(
    ({ id: reportId, onSuccess, onError }: IFetchAttributionReportById) => {
      setFetchedAttributionReport(true);
      doFetch({
        endpoint: `${Index.SEGMENT_REPORTS}/${Index.SEGMENT_ATTRIBUTION_REPORTS}/${reportId}`,
        payload: {},
        method: FetchMethod.GET,
        onSuccess: (res: IReport[]) => {
          onSuccess(res[0]);
        },
        onError: (error: IError) => {
          onError(error);
        },
      });
    },
    [doFetch],
  );

  const areAdDetailsRulesValid = (
    queryChildren: IQueryFilters<IChildrenBase>[],
  ): boolean => {
    const exposureAdsFilters = getAttributionFiltersFromQueryFilters(
      queryChildren,
      AttributionFilterType.adDetails,
    ) as RuleFilter[];

    return exposureAdsFilters.every(
      ({ id, value }) =>
        !MANDATORY_RULES.includes(id) || // Optional Rules
        (MANDATORY_RULES.includes(id) &&
          !Array.isArray(value) &&
          !isNil(value)) || // String values
        (MANDATORY_RULES.includes(id) &&
          Array.isArray(value) &&
          value?.length > 0), // Array values
    );
  };

  const areViewershipRulesValid = (
    queryChildren: IQueryFilters<IChildrenBase>[],
  ): boolean => {
    const outcomeViewershipFilters = getAttributionFiltersFromQueryFilters(
      queryChildren,
      AttributionFilterType.viewership,
    ) as RuleFilter[];

    return outcomeViewershipFilters.every(
      ({ id, value }) =>
        !MANDATORY_RULES.includes(id) || // Optional Rules
        (MANDATORY_RULES.includes(id) &&
          !Array.isArray(value) &&
          !isNil(value)) || // String values
        (MANDATORY_RULES.includes(id) &&
          Array.isArray(value) &&
          value?.length > 0), // Array values
    );
  };

  const areBrandLiftRulesValid = (
    queryChildren: IQueryFilters<IChildrenBase>[],
  ): boolean => {
    const outcomeBrandLiftFilters = getAttributionFiltersFromQueryFilters(
      queryChildren,
      AttributionFilterType.brandLift,
    ) as RuleFilter[];

    // TODO: Add Outcome specific Logic
    return (
      outcomeBrandLiftFilters.length > 0 &&
      outcomeBrandLiftFilters.every((filter) => filter.value?.length)
    );
  };

  const areDatasetsValid = (
    query: IQuery,
    datasetSelection: ReportDatasetSelection,
  ): boolean => {
    if (datasetSelection === ReportDatasetsSelections.ads) {
      return hasQueryAdsDatasets(query.datasets, query.mode);
    }

    if (datasetSelection === ReportDatasetsSelections.genericEvents) {
      return hasQueryCustomExposureDatasets(query.datasets, query.mode);
    }

    if (datasetSelection === ReportDatasetsSelections.customAds) {
      return hasQueryCustomAdsDatasets(query.datasets, query.mode);
    }

    return false;
  };

  const isQueryValid = (
    query: IQuery<IQueryFilters<IQueryFilters<IChildrenBase>[]>>,
    datasetSelection: ReportDatasetSelection,
    subType: string,
  ): boolean => {
    const queryChildren = get(
      'filters.children',
      query,
    ) as IQueryFilters<IChildrenBase>[];

    if (
      areAdDetailsRulesValid(queryChildren) &&
      areDatasetsValid(query, datasetSelection)
    ) {
      // Tune In reports
      if (
        subType === reportSubType.tvTuneIn.key &&
        areViewershipRulesValid(queryChildren)
      ) {
        return true;
      }
      // Outcome reports
      if (
        subType === reportSubType.outcome.key &&
        areBrandLiftRulesValid(queryChildren)
      ) {
        return true;
      }
    }

    return false;
  };

  const getReportSubTypeKey = (segment?: string): string => {
    switch (segment) {
      case reportSubType.tvTuneIn.segment:
        return reportSubType.tvTuneIn.key;
      case reportSubType.outcome.segment:
        return reportSubType.outcome.key;
      default:
        return '';
    }
  };

  const getTargetName = (targets: IReport[], report: IReport): string => {
    const filteredTarget = targets.filter(
      (target) => target.id === get('targets[0]', report?.query),
    );
    return getOr('', '[0].name', filteredTarget);
  };

  const checkInvalidTarget = useCallback(
    (targets: IReport[], report: IReport): boolean => {
      const [, invalidTargets] = getValidAndInvalidTargets(targets, report);

      return invalidTargets && invalidTargets.length > 0;
    },
    [],
  );

  const hasSameFilterSelections = (
    filtersA: IChildrenBase,
    filtersB: IChildrenBase,
  ): boolean =>
    filtersA.every((filterRuleA: IDateRange | RuleFilter) => {
      if (filterRuleA.type !== 'DATETIME_RANGE') {
        const { id: idA, value: valueA } = filterRuleA as RuleFilter;

        return filtersB.some((filterRuleB: IDateRange | RuleFilter) => {
          const { id: idB, value: valueB } = filterRuleB as RuleFilter;
          return idA === idB && isEqual(valueA, valueB);
        });
      }
      return true;
    });

  return {
    reports,
    isQueryValid,
    getReportSubTypeKey,
    fetchAttributionReports,
    fetchAttributionReportById,
    loading,
    hasFetchedAttributionReports,
    hasFetchedAttributionReport,
    getTargetName,
    checkInvalidTarget,
    hasSameFilterSelections,
  };
};

export default useAttributionReports;
