import { getClass, getTestId } from 'helpers/components';
import { noop } from 'lodash';
import React, { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';

export const stickerTriggerComponentId = 'sticker-trigger';

export enum State {
  noPointer = 'no-pointer',
}
interface IStickerTriggerProps
  extends PropsWithChildren<
    Pick<
      HTMLAttributes<HTMLSpanElement>,
      'onClick' | 'onTouchEnd' | 'onMouseEnter' | 'onMouseLeave'
    >
  > {
  testId?: string;
  tabIndex?: number;
  showTriggerPointer: boolean;
}

export const StickerTrigger = forwardRef<HTMLSpanElement, IStickerTriggerProps>(
  (props, ref): JSX.Element => {
    const {
      children,
      tabIndex,
      showTriggerPointer,
      testId,
      onClick,
      onTouchEnd,
      onMouseEnter,
      onMouseLeave,
    } = props;

    const stickerTriggerTestId = getTestId(stickerTriggerComponentId, testId);
    const stickerTriggerClass = getClass(stickerTriggerComponentId, {
      boolean: [{ state: !showTriggerPointer, class: State.noPointer }],
    });

    return (
      <span
        data-testid={stickerTriggerTestId}
        className={stickerTriggerClass}
        tabIndex={tabIndex ?? 0}
        role="button"
        ref={ref}
        onClick={onClick}
        onKeyDown={noop}
        onTouchEnd={onTouchEnd}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </span>
    );
  },
);
