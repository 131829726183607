import { CopyToClipboard } from 'react-copy-to-clipboard';
import React from 'react';
import useToast from '../../../hooks/useToast';
import Flex, { Horizontal } from '../../Flex';
import Button, { Kind } from '../../Button';
import { Color, Type } from '../../Icon';

export const EventReport = ({
  reportConfig,
}: {
  reportConfig: string;
}): JSX.Element => {
  const { doSuccessToast } = useToast();
  return (
    <Flex horizontal={Horizontal.between}>
      <div className="overflow-hidden text-truncate mr-1">{reportConfig}</div>
      <CopyToClipboard
        text={reportConfig}
        onCopy={() =>
          doSuccessToast('Report configuration copied to clipboard.')
        }
      >
        <Button
          kind={Kind.icon}
          iconType={Type.copy}
          iconColor={Color.primary}
          disabled={reportConfig === 'Missing report'}
        />
      </CopyToClipboard>
    </Flex>
  );
};
