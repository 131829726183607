import createChartConfigWithCommonDesign from 'helpers/charts/commonChartDesignConfig';
import Highcharts from 'highcharts';
import { merge } from 'lodash';
import { useMemo } from 'react';
import { commonMultichartConfig } from '../commonMultichartConfig';

export const useMultiseriesChartConfig = ({
  chartConfig,
}: {
  chartConfig: Highcharts.Options;
}): Highcharts.Options => {
  const config = useMemo(() => {
    const { yAxis: yAxisCommon, ...common } = createChartConfigWithCommonDesign(
      commonMultichartConfig,
    );

    return {
      ...merge({}, common, chartConfig, {
        yAxis: Array.isArray(chartConfig.yAxis)
          ? chartConfig.yAxis.map((axis) => merge({}, yAxisCommon, axis))
          : merge({}, yAxisCommon, chartConfig.yAxis),
      }),
    };
  }, [chartConfig]);

  return config;
};
