import Highcharts from 'highcharts';
import { useEffect } from 'react';

export const useChartReflow = (
  chart: Highcharts.Chart | undefined,
  container: HTMLDivElement | null,
): void => {
  useEffect(() => {
    function containerResize(): void {
      chart?.reflow();
    }

    const resizeObserver = new ResizeObserver(containerResize);

    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
      resizeObserver.disconnect();
    };
  }, [chart, container]);
};
