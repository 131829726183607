import { addDays } from 'date-fns';
import { FocusedInput } from './interface';

export const dateFocusedInputChanged = (
  value: string,
  startDate: string,
  endDate: string,
  focusedInput?: FocusedInput,
): boolean =>
  !(
    (focusedInput === FocusedInput.startDate && value === startDate) ||
    (focusedInput === FocusedInput.endDate && value === endDate)
  );

export const getNumberOfDaysBetweenTwoDays = (
  date1: string,
  date2: string,
): string => {
  const isIsoString = date1.includes('Z') && date2.includes('Z');
  const startDate = new Date(date1);
  const endDate = isIsoString ? new Date(date2) : addDays(new Date(date2), 1);
  const differenceInTime = endDate.getTime() - startDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  const roundedDifferenceInDays = Math.round(differenceInDays);
  const differenceInWeeks = Math.floor(roundedDifferenceInDays / 7);
  const remainderOfDays = Math.round(
    roundedDifferenceInDays - differenceInWeeks * 7,
  );
  const weekDisplayString = differenceInWeeks === 1 ? ' Week' : ' Weeks';
  const dayDisplayString = remainderOfDays === 1 ? ' Day' : ' Days';
  const fullDisplayString =
    (differenceInWeeks > 0 ? differenceInWeeks + weekDisplayString : '') +
    (differenceInWeeks > 0 && remainderOfDays > 0 ? ', ' : '') +
    (remainderOfDays > 0 ? remainderOfDays + dayDisplayString : '');
  return fullDisplayString;
};
