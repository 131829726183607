import Flex, { Direction, Horizontal } from 'components/Flex';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import SegmentedControl from 'components/SegmentedControl';
import { getClass } from 'helpers/components';
import React from 'react';
import { IOption } from 'types/textValue';
import { Action, ActionTypes } from '../../reducer';
import { ITransformerInputFilters } from '../../transformer';
import MultipleOptionsIcon from '../MultipleOptionsIcon';
import SegmentedSwitch from '../SegmentedSwitch';

interface IPointsControllerProps {
  segments: IOption[];
  targets: IOption[];
  filters: ITransformerInputFilters;
  dispatch: React.Dispatch<Action>;
}

const pointsControllerComponentName = 'points-controller';
const pointsControllerClassName = getClass(pointsControllerComponentName);
const pointsControllerFiltersClassName = getClass(
  pointsControllerComponentName,
  { concat: ['filters'] },
);

const chartFiltersComponentName = 'chart-filters-wrapper';
const labelClass = getClass(chartFiltersComponentName, {
  concat: ['label'],
});
const segmentedControlClass = getClass(chartFiltersComponentName, {
  concat: ['segmented-control-wrapper'],
});

const PointsController: React.FC<IPointsControllerProps> = ({
  segments,
  targets,
  filters,
  dispatch,
}) => (
  <Flex horizontal={Horizontal.left} className={pointsControllerClassName}>
    <SegmentedSwitch
      multipleTargets={filters.multiTarget}
      onChange={(multipleTargets: boolean) =>
        dispatch({
          type: ActionTypes.CHANGE_MULTI_TARGET_SWITCH,
          payload: multipleTargets,
        })
      }
    />

    <Flex
      direction={Direction.column}
      horizontal={Horizontal.between}
      className={pointsControllerFiltersClassName}
    >
      {filters.multiTarget ? (
        <>
          <div>
            <label className={`${labelClass} d-flex align-items-center`}>
              <span>Audiences</span> <MultipleOptionsIcon />
            </label>
            <MultiSelectDropdown
              showLegend
              multiSelect
              options={targets}
              selected={filters.targets}
              onChange={(selectedTargets: IOption[]) =>
                dispatch({
                  type: ActionTypes.SET_TARGETS,
                  payload: selectedTargets,
                })
              }
              minimumChipsSelected={1}
              placeholder="Choose audience"
            />
          </div>

          <div className={segmentedControlClass}>
            <label className={labelClass}>
              <b>605</b> Campaign Interaction Segments
            </label>
            <SegmentedControl
              options={segments}
              onSelect={(selectedSegment: string) =>
                dispatch({
                  type: ActionTypes.SET_ONE_SEGMENT,
                  payload: selectedSegment,
                })
              }
              selected={filters.segments[0]?.value}
              testId="segments-control"
              size="small"
            />
          </div>
        </>
      ) : (
        <>
          <div className={segmentedControlClass}>
            <label className={labelClass}>Audiences</label>
            <SegmentedControl
              options={targets}
              onSelect={(selectedTarget: string) =>
                dispatch({
                  type: ActionTypes.SET_ONE_TARGET,
                  payload: selectedTarget,
                })
              }
              selected={filters.targets[0]?.value}
              testId="targets-control"
              size="small"
            />
          </div>

          <div>
            <label className={`${labelClass} d-flex align-items-center`}>
              <span>Campaign Behavioral Segments</span> <MultipleOptionsIcon />
            </label>
            <MultiSelectDropdown
              showLegend
              multiSelect
              options={segments}
              onChange={(selectedSegments: IOption[]) =>
                dispatch({
                  type: ActionTypes.SET_SEGMENTS,
                  payload: selectedSegments,
                })
              }
              selected={filters.segments}
              minimumChipsSelected={1}
            />
          </div>
        </>
      )}
    </Flex>
  </Flex>
);

export default PointsController;
