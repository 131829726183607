import Button, { Type as ButtonType, Kind } from 'components/Button';
import Flex, { Horizontal } from 'components/Flex';
import Icon, { Color, Type as IconType } from 'components/Icon';
import { Style } from 'components/Sticker';
import {
  UNSAVED_REPORT_ID,
  reportTypeEquivalences,
} from 'domains/reports/types';
import { Props } from 'domains/toolbar/types';
import useUserPermissions from 'hooks/useUserPermissions';
import { cloneDeep, isEqual } from 'lodash';
import React, { ReactElement, useEffect, useRef } from 'react';
import { IQuery } from 'types/query';
import { getClass } from '../../helpers/components';

export const reportToolbarComponentName = 'report-toolbar';
export const loadingSpan = 'loading-progress';

const DOM_KEY_CANCEL_QUERY_ACTION_BUTTON = 'cancel-query';
const DOM_KEY_REFRESH_QUERY_ACTION_BUTTON = 'refresh-query';
const DOM_KEY_HEADER = 'header';
const DOM_KEY_RUN_QUERY_ACTION_BUTTON = 'run-query';
const DOM_KEY_SAVE_AS_COPY_ACTION_BUTTON = 'save-as-report';

const LOCALE_CANCEL_QUERY_BUTTON_TEXT = 'Cancel';
const LOCALE_RUN_QUERY_BUTTON_TEXT = 'Run';
const LOCALE_SAVE_AS_COPY_BUTTON_TEXT = 'Save As';
const LOCALE_REFRESH_QUERY_BUTTON_TEXT = 'Refresh';

const reportFormReportHeaderClass = getClass(reportToolbarComponentName, {
  concat: [DOM_KEY_HEADER],
});
const loadingSpanClass = getClass(loadingSpan);

export const ReportToolbar = (props: Props): ReactElement => {
  const {
    isCancelRunDisabled,
    isRefreshDisabled = false,
    isRefreshLaunched = false,
    isRunDisabled,
    isRunLaunched,
    isSaveAsCopyDisabled,
    isSaveDisabled,
    lastCachedDate,
    onCancelRun,
    onExportConfiguration,
    onShowReportPayload,
    onRefresh,
    onRun,
    onSave,
    onSaveAsCopy,
    progress,
    reportType,
    report,
    setReportHasChanged,
    handleEnableDisableToolbarButtons,
    handlePersistedReportChange,
  } = props;
  const unchangedReportQuery = useRef<IQuery>();

  useEffect(() => {
    if (!report?.query) return;

    if (!unchangedReportQuery.current && report?.query) {
      unchangedReportQuery.current = cloneDeep(report.query);
    }

    setReportHasChanged(!isEqual(report.query, unchangedReportQuery.current));
  }, [report?.query, unchangedReportQuery, setReportHasChanged]);

  useEffect(() => {
    handleEnableDisableToolbarButtons();
  }, [report?.notPersistedProps, handleEnableDisableToolbarButtons]);

  const { checkPermissions } = useUserPermissions();
  const userCanExecute = checkPermissions(
    `${reportTypeEquivalences(reportType)}::execute`,
  );
  const userCanRefresh = checkPermissions(
    `${reportTypeEquivalences(reportType)}::refresh`,
  );

  const showReportConfigExportButton =
    checkPermissions('reports.remote_copy::view') && onExportConfiguration;

  const showReportPayloadButton = checkPermissions(
    'reports.generate_aapi_payload::view',
  );

  const reportUpdatedAtText = lastCachedDate
    ? `Last refreshed on ${new Date(
        lastCachedDate * 1000,
      ).toDateString()}. Report may need to be refreshed.`
    : undefined;

  const isPersistedReport = report?.persistedReport;
  const isTarget = report?.type === 'target';
  const reportTypeLabel = isTarget ? 'Advanced Audience' : 'Report';
  const isNewReport = report?.id === UNSAVED_REPORT_ID;

  return (
    <div className={reportFormReportHeaderClass}>
      <Flex horizontal={Horizontal.between}>
        <Flex horizontal={Horizontal.left}>
          {showReportPayloadButton && (
            <Button onClick={onShowReportPayload} kind={Kind.toolbar}>
              <Icon color={Color.toolbar} type={IconType.settings} />
              <span>Show Report Payload</span>
            </Button>
          )}
          {showReportConfigExportButton && (
            <Button onClick={onExportConfiguration} kind={Kind.toolbar}>
              <Icon color={Color.toolbar} type={IconType.settings} />
              <span>Export Config</span>
            </Button>
          )}
          <Button
            onClick={() => handlePersistedReportChange(!isPersistedReport)}
            tooltip={`${reportTypeLabel} that hasn't been run or updated in 6 months will be deleted unless it is saved as Persisted`}
            disabled={(isRunDisabled && isRefreshDisabled) || isNewReport}
            kind={Kind.toolbar}
          >
            <Icon
              color={Color.toolbar}
              type={isPersistedReport ? IconType.link : IconType.warning}
            />
            <span>
              {isPersistedReport
                ? `Persisted ${reportTypeLabel}`
                : `Unpersisted ${reportTypeLabel}`}
            </span>
          </Button>
          {!isRefreshLaunched && isRefreshDisabled && (
            <Button
              testId={DOM_KEY_RUN_QUERY_ACTION_BUTTON}
              disabled={!userCanExecute || isRunDisabled || isRunLaunched}
              onClick={onRun}
              kind={Kind.toolbar}
            >
              {isRunLaunched ? (
                <>
                  <Icon color={Color.toolbar} type={IconType.loading} />
                  <span className={loadingSpanClass}>
                    {Math.floor(progress)}%
                  </span>
                </>
              ) : (
                <>
                  <Icon color={Color.toolbar} type={IconType.play} />
                  <span>{LOCALE_RUN_QUERY_BUTTON_TEXT}</span>
                </>
              )}
            </Button>
          )}
          {!isRefreshDisabled && (
            <>
              <Button
                disabled={
                  !userCanRefresh || isRefreshDisabled || isRefreshLaunched
                }
                onClick={onRefresh}
                testId={DOM_KEY_REFRESH_QUERY_ACTION_BUTTON}
                tooltip={reportUpdatedAtText}
                tooltipStyle={Style.tertiary}
                kind={Kind.toolbar}
              >
                {isRunLaunched ? (
                  <>
                    <Icon color={Color.toolbar} type={IconType.loading} />
                    <span className={loadingSpanClass}>
                      {Math.floor(progress)}%
                    </span>
                  </>
                ) : (
                  <>
                    <Icon color={Color.toolbar} type={IconType.play} />
                    <span>{LOCALE_REFRESH_QUERY_BUTTON_TEXT}</span>
                  </>
                )}
              </Button>
            </>
          )}
          {(isRefreshLaunched || isRunLaunched) && (
            <Button
              disabled={isCancelRunDisabled}
              testId={DOM_KEY_CANCEL_QUERY_ACTION_BUTTON}
              onClick={onCancelRun}
              kind={Kind.toolbar}
            >
              <Icon color={Color.toolbar} type={IconType.close} />
              <span>{LOCALE_CANCEL_QUERY_BUTTON_TEXT}</span>
            </Button>
          )}
          <Button
            testId={DOM_KEY_SAVE_AS_COPY_ACTION_BUTTON}
            onClick={onSaveAsCopy}
            kind={Kind.toolbar}
            disabled={
              isSaveAsCopyDisabled || !checkPermissions('reports::duplicate')
            }
          >
            <Icon color={Color.toolbar} type={IconType.saveAs} />
            <span>{LOCALE_SAVE_AS_COPY_BUTTON_TEXT}</span>
          </Button>
          <Button
            type={ButtonType.submit}
            onClick={onSave}
            kind={Kind.toolbar}
            disabled={
              isSaveDisabled ||
              !checkPermissions('reports::update') ||
              report?.isReadOnly
            }
          >
            <Icon color={Color.toolbar} type={IconType.save} />
            <span>Save</span>
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};
