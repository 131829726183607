import ChartOmissionAlert from 'components/ChartOmissionAlert';
import { TableDataType } from 'components/Table';
import { IChartConfig } from 'helpers/charts/commonChartDesignConfig';
import { getClass, getTestId } from 'helpers/components';
import Highcharts, { Point } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useChartReflow } from 'hooks/useChartReflow';
import { merge } from 'lodash';
import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { baseBarChartConfig } from '../../chartConfig';
import { getCategoriesFromSeries } from './helpers/getCategoriesFromSeries';

export interface IBarChartPoint {
  y: string | number;
  name: string | number;
  id: string;
  [key: string]: string | number;
}

export type IBarChartData = Array<IBarChartPoint>;

export type IBarChartSeries = Array<{
  data: IBarChartData;
  color: string;
  name: string;
}>;

interface BarChartProps {
  series: IBarChartSeries;
  xAxisName: string;
  yAxisName: string;
  chartConfig?: IChartConfig;
  hoveredPoint?: TableDataType;
  overlayElement?: ReactElement;
  showOverlay?: boolean;
  testId?: string;
}

export const barChartName = 'bar-chart';

const barChartWrapperClassName = getClass(barChartName, {
  concat: ['wrapper'],
});

const barChartOverlayClassName = getClass(barChartName, {
  concat: ['overlay'],
});

const BarChart: FC<BarChartProps> = ({
  series,
  xAxisName,
  yAxisName,
  chartConfig,
  overlayElement,
  showOverlay,
  hoveredPoint,
  testId,
}) => {
  const barChartTestId = getTestId(barChartName, testId);

  const [, setHoveredPoint] = useState<Highcharts.Point>();
  const [plotBoundary, setPlotBoundary] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const baseOptions = useMemo(
    () =>
      chartConfig
        ? merge({}, baseBarChartConfig, chartConfig)
        : baseBarChartConfig,
    [chartConfig],
  );

  const options = useMemo(
    () => ({
      ...baseOptions,
      series,
      xAxis: merge({}, baseOptions.xAxis, {
        title: {
          text: xAxisName,
        },
        categories: getCategoriesFromSeries(series),
      }),
      yAxis: merge({}, baseOptions.yAxis, {
        title: {
          text: yAxisName,
        },
      }),
      chart: merge({}, baseOptions.chart, {
        events: {
          render() {
            const { plotHeight, plotLeft, plotTop, plotWidth } = this as any;
            const newBoundary = {
              top: plotTop as number,
              left: plotLeft as number,
              width: plotWidth as number,
              height: plotHeight as number,
            };

            setPlotBoundary(newBoundary);
          },
        },
      }),
    }),
    [series, xAxisName, yAxisName, baseOptions],
  );

  useEffect(() => {
    setHoveredPoint(undefined);
  }, [options]);

  // NOTE: To eliminate race conditions I've used prev to toggle previous point's state
  useEffect(() => {
    if (hoveredPoint) {
      setHoveredPoint((prev) => {
        const targetSeries = chartRef.current?.chart.series.find(
          (s) => s.name === hoveredPoint.target,
        );

        const newPoint = targetSeries?.points.find(
          (p) => (p as Point & IBarChartPoint).id === hoveredPoint.id,
        );

        prev?.setState();
        newPoint?.setState('hover');

        return newPoint;
      });
    } else {
      setHoveredPoint((prev) => {
        prev?.setState();
        return undefined;
      });
    }
  }, [hoveredPoint]);

  const hasOmission = useMemo(() => {
    const doAllSeriesHaveEmptyData = series.every((s) => !s.data.length);
    return doAllSeriesHaveEmptyData;
  }, [series]);

  useChartReflow(chartRef.current?.chart, containerRef.current);

  return (
    <div
      className={barChartWrapperClassName}
      data-testid={barChartTestId}
      ref={containerRef}
    >
      {hasOmission && (
        <div className={barChartOverlayClassName} style={plotBoundary}>
          <ChartOmissionAlert />
        </div>
      )}
      {overlayElement && showOverlay && (
        <div className={barChartOverlayClassName} style={plotBoundary}>
          {overlayElement}
        </div>
      )}

      <HighchartsReact
        allowCharUpdate
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
        oneToOn
      />
    </div>
  );
};

export default BarChart;
