import { ResultBase, ResultBaseSubStructure } from 'domains/reports/types';
import { IResultSelectionRemovedRow } from 'domains/resultSelections/types';
import { get, set } from 'lodash';
import { filterByMetricValues } from './filterByMetricValues';

export const removeRowsFromData = (
  chartData: ResultBase,
  rowsRemoved: IResultSelectionRemovedRow[],
): ResultBase => {
  let data = chartData;

  rowsRemoved.forEach((item) => {
    const { breakout: chartBreakout, decodedBreakout, target, name } = item;

    const breakout = decodedBreakout ?? chartBreakout;

    const dataRowPath = `exposed_all.${breakout}.${target}`;

    if (breakout === 'exposed_all.total') {
      data = set({ ...data }, dataRowPath, []);
      return;
    }
    const targetDataRow =
      (get(data, dataRowPath) as unknown as ResultBaseSubStructure[]) ?? [];
    const filteredDataRow = targetDataRow.filter(
      filterByMetricValues(breakout, name),
    );

    data = set({ ...data }, dataRowPath, filteredDataRow);
  });

  return data;
};
