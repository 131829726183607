import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { getClass, getTestId } from 'helpers/components';
import React, { ReactElement } from 'react';

export const highlightedTextComponentName = 'highlighted-text';
export const coloredTextClass = 'colored';

const highlightedTextTestId = getTestId(highlightedTextComponentName);

export interface IHighLightedText {
  text: string;
  highlighted: string;
  textClass?: string;
}

export const HighlightedText = ({
  text,
  highlighted,
  textClass,
}: IHighLightedText): ReactElement => {
  const compoundClass = getClass(highlightedTextComponentName, {
    text: [textClass],
  });

  const matches = match(text, highlighted, { requireMatchAll: true });
  const parts = parse(text, matches);
  return (
    <span className={compoundClass} data-testid={highlightedTextTestId}>
      {parts.map((part, index) => (
        <span
          key={part + index.toString()}
          {...(part.highlight && { className: coloredTextClass })}
        >
          {part.text}
        </span>
      ))}
    </span>
  );
};
