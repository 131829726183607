import Button, { Kind } from 'components/Button';
import Icon, {
  Color as IconColor,
  Size as IconSize,
  Type as IconType,
} from 'components/Icon';
import { getTestId } from 'helpers/components';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

export const inlineAlertComponentName = 'inline-alert';

export enum AlertTypes {
  warn = 'warn',
  notify = 'notify',
  validate = 'validate',
  omission = 'omission',
  error = 'error',
}

export enum BackgroundColors {
  white = 'white',
}

export interface IMessagePanelProps {
  message: string;
  testId?: string;
  mode: AlertTypes;
  hideClose?: boolean;
  backgroundColor?: BackgroundColors;
  onClose?: () => void;
  lastAttemptedRun?: number;
}

const InlineAlert: FunctionComponent<IMessagePanelProps> = (
  props,
): ReactElement | null => {
  const {
    message,
    testId,
    mode,
    hideClose,
    backgroundColor,
    onClose,
    lastAttemptedRun,
  } = props;
  const [isVisible, toggleVisibility] = useState(true);

  useEffect(() => {
    toggleVisibility(true);
  }, [lastAttemptedRun]);

  if (!isVisible) return null;

  const errorTestId = getTestId(inlineAlertComponentName, testId);

  const defineIconColor = (alertMode: AlertTypes): IconColor => {
    if (alertMode === 'error' || alertMode === 'validate') return IconColor.red;
    return alertMode === AlertTypes.notify
      ? IconColor.gray200
      : IconColor.orange;
  };

  const defineTextColor = (alertMode: AlertTypes): IconColor => {
    if (alertMode === 'error') return IconColor.red;
    return alertMode === AlertTypes.notify
      ? IconColor.gray200
      : IconColor.orange;
  };

  return (
    <div
      className={`${inlineAlertComponentName} ${mode} ${backgroundColor}`}
      data-testid={errorTestId}
    >
      <Icon type={IconType.warning} color={defineIconColor(mode)} />
      <div className="message">
        <span className={defineTextColor(mode)}>{message}</span>
      </div>
      {!hideClose && (
        <Button
          kind={Kind.icon}
          iconType={IconType.close}
          iconSize={IconSize.small}
          onClick={() => (onClose ? onClose() : toggleVisibility(false))}
          testId="inline-alert-close-btn"
        />
      )}
    </div>
  );
};

export default InlineAlert;
