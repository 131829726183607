import { newDashboardReport } from 'domains/dashboard/initializers';
import {
  Dashboard,
  IDashboardReport,
  IDashboardReportData,
  IDashboardReportExecutionEndedPayload,
  IDashboardReportExecutionPayload,
  IDashboardReportExecutionUpdatedPayload,
  IDashboardReportFormUpdatedPayload,
  IDashboardState,
} from 'domains/dashboard/types';
import { getTypeOptions } from 'domains/reports/helpers';
import { get, uniqBy } from 'lodash';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';
import ITextValue from 'types/textValue';

export const initialState = {
  reports: [],
  allAvailableReports: [],
  isLoadingAllAvailableReports: false,
  isLoadingReportDetails: false,
  isUpdating: false,
  metricsOptions: [],
  typeOptions: getTypeOptions(),
  newReportForm: newDashboardReport(),
  userDashboard: undefined,
};

export default (
  state: IDashboardState = initialState,
  action: Action<
    | IDashboardReportData
    | IDashboardReportExecutionPayload
    | IDashboardReportExecutionUpdatedPayload
    | IDashboardReportExecutionEndedPayload
    | ITextValue[]
    | IDashboardReportFormUpdatedPayload
    | Dashboard
    | IDashboardReport
    | string
  >,
): IDashboardState => {
  const { payload, type } = action;
  const reports = state.reports;
  const newReportForm = get(state, 'newReportForm', {});
  const currentForm = { ...newReportForm };
  let updatedReports = [];
  switch (type) {
    case ActionType.DASHBOARD_REPORT_LOAD_STARTED:
      const id = payload as string;
      return {
        ...state,
        reports: uniqBy(
          [
            ...reports,
            {
              id,
              isRunning: false,
              isLoading: true,
            },
          ],
          'id',
        ),
      };
    case ActionType.DASHBOARD_REPORT_LOAD_ENDED:
      const reportData = payload as IDashboardReportData;
      updatedReports = reports.map((item) =>
        item.id === reportData.id ? reportData : item,
      );
      return {
        ...state,
        reports: uniqBy(updatedReports, 'id'),
      };
    case ActionType.DASHBOARD_REPORT_EXECUTION_STARTED:
      const executionStartedPayload =
        payload as IDashboardReportExecutionPayload;
      const { reportId, cacheQueryId, clientId } = executionStartedPayload;
      updatedReports = reports.map((item) =>
        item.id === reportId
          ? {
              ...item,
              execution: {
                cacheQueryId,
                clientId,
                progress: 0,
              },
            }
          : item,
      );
      return {
        ...state,
        reports: updatedReports,
      };
    case ActionType.DASHBOARD_REPORT_EXECUTION_UPDATED:
      const executionUpdatedPayload =
        payload as IDashboardReportExecutionUpdatedPayload;
      updatedReports = reports.map((item) =>
        item?.execution?.cacheQueryId === executionUpdatedPayload.cacheQueryId
          ? {
              ...item,
              execution: {
                cacheQueryId: executionUpdatedPayload.cacheQueryId,
                clientId: item?.execution?.clientId,
                progress: Math.round(executionUpdatedPayload.progress),
              },
            }
          : item,
      );
      return {
        ...state,
        reports: updatedReports,
      };
    case ActionType.DASHBOARD_REPORT_EXECUTION_ENDED:
      const executionEndedPayload =
        payload as IDashboardReportExecutionEndedPayload;
      updatedReports = reports.map((item) =>
        item?.execution?.cacheQueryId === executionEndedPayload.cacheQueryId
          ? {
              ...item,
              isLoading: false,
              isRunning: false,
              execution: {
                cacheQueryId: item?.execution?.cacheQueryId,
                clientId: item?.execution?.clientId,
                progress: 100,
                data: executionEndedPayload.data,
                metadata: executionEndedPayload.metadata,
                executed_at: executionEndedPayload.executed_at,
                rows_dropped: executionEndedPayload.rows_dropped,
              },
            }
          : item,
      );
      return {
        ...state,
        reports: updatedReports,
      };
    case ActionType.DASHBOARD_REPORT_RESET:
      const resetId = payload as string;
      updatedReports = reports.filter((item) => item.id !== resetId);
      return {
        ...state,
        reports: updatedReports,
      };
    case ActionType.DASHBOARD_REPORT_FORM_LOAD_ALL_REPORTS_STARTED:
      return {
        ...state,
        isLoadingAllAvailableReports: true,
      };
    case ActionType.DASHBOARD_REPORT_FORM_LOAD_ALL_REPORTS_ENDED:
      const allAvailableReports = payload as ITextValue[];
      return {
        ...state,
        allAvailableReports,
        isLoadingAllAvailableReports: false,
      };
    case ActionType.DASHBOARD_REPORT_FORM_UPDATE_STARTED:
      return {
        ...state,
        isUpdating: true,
      };
    case ActionType.DASHBOARD_REPORT_FORM_UPDATE_ENDED:
      return {
        ...state,
        isUpdating: false,
      };
    case ActionType.DASHBOARD_REPORT_FORM_LOAD_REPORT_DETAILS_STARTED:
      return {
        ...state,
        isLoadingReportDetails: false,
      };
    case ActionType.DASHBOARD_REPORT_FORM_SET_METRICS_OPTIONS:
      const metricsOptions = payload as ITextValue[];
      return {
        ...state,
        metricsOptions,
      };
    case ActionType.DASHBOARD_REPORT_FORM_LOAD_REPORT_DETAILS_ENDED:
      const newReportId = payload as string;
      return {
        ...state,
        newReportForm: {
          ...currentForm,
          reportId: newReportId,
          selectVal: '', // metric
        } as IDashboardReport,
      };
    case ActionType.DASHBOARD_REPORT_FORM_SET_FIELD_VALUE:
      const { key, value } = payload as IDashboardReportFormUpdatedPayload;
      return {
        ...state,
        newReportForm: {
          ...currentForm,
          [key]: value,
        } as IDashboardReport,
      };
    case ActionType.DASHBOARD_SET_USER_DASHBOARD:
      const userDashboard = payload as Dashboard;
      return {
        ...state,
        userDashboard,
      };
    case ActionType.DASHBOARD_REPORT_FORM_SET:
      const reportForm = payload as IDashboardReport;
      return {
        ...state,
        newReportForm: {
          ...reportForm,
          chart_color: (reportForm.chart_colors ?? []).join(', '),
        },
      };
    case ActionType.DASHBOARD_REPORT_FORM_RESET:
      return {
        ...state,
        isLoadingAllAvailableReports: false,
        isLoadingReportDetails: false,
        newReportForm: newDashboardReport(),
      };
    default:
      return state;
  }
};
