import { PerformanceGroupNames, ResultBase } from 'domains/reports/types';
import React from 'react';
import { IMappedChartData } from '../../../types/multiChartTransformers';

interface ITransformerMethods<IDataItem, IFilters, IChartConfig> {
  filters: IFilters;
  performanceMetricGroups?: PerformanceGroupNames[];
  reportResultMapper: (
    initialData: ResultBase,
    performanceGroups?: PerformanceGroupNames[],
  ) => IMappedChartData<IDataItem>;
  configGetter: (
    chartData: IMappedChartData<IDataItem>,
    filters: IFilters,
    performanceMetricGroups?: PerformanceGroupNames[],
  ) => IChartConfig;
}

export const useMultichartTransformer = <IDataItem, IFilters, IChartConfig>(
  initialData: ResultBase,
  {
    filters,
    performanceMetricGroups,
    reportResultMapper,
    configGetter,
  }: ITransformerMethods<IDataItem, IFilters, IChartConfig>,
): IChartConfig => {
  const mappedChartData = React.useMemo(
    () => reportResultMapper(initialData, performanceMetricGroups),
    [initialData, performanceMetricGroups, reportResultMapper],
  );

  return React.useMemo(
    () => configGetter(mappedChartData, filters),
    [configGetter, mappedChartData, filters],
  );
};
