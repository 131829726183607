import {
  DataRowGraphWithKey,
  ResultBaseSampleGroups,
} from 'domains/reports/types';
import { startCase } from 'lodash/fp';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import cloneDeep from 'lodash/cloneDeep';
import { TableOptions } from '.';
import reportOverviewConfig from '../../config.json';
import { checkPermissions } from 'domains/reports/adapters/general';

type TableTransformOptions = {
  keyRename: {
    [key: string]: string;
  };
};

export function tableTransform(
  chartData: ResultBaseSampleGroups,
  options: TableTransformOptions,
  targets?: string[],
  performanceMetricGroups?: string[],
  conversionType?: string,
): DataRowGraphWithKey[] {
  return reduce<ResultBaseSampleGroups, DataRowGraphWithKey[]>(
    chartData,
    // key is one of 'genpop' or 'target'
    (rows, value, key: string) => [
      ...rows,
      ...(performanceMetricGroups?.includes('sales_per_transaction')
        ? map(value, (row) => ({
            ...get(row, 'campaign'),
            ...get(row, 'sales_per_transaction'),
            performanceMetric: 'Sales Per Transaction',
            key: '',
            target: key,
          }))
        : []),
      ...(performanceMetricGroups?.includes('total_sales')
        ? map(value, (row) => ({
            ...get(row, 'campaign'),
            ...get(row, 'total_sales'),
            performanceMetric: 'Total Sales',
            key: '',
            target: key,
          }))
        : []),
      ...(performanceMetricGroups?.includes('conversions')
        ? map(value, (row) => ({
            ...get(row, 'campaign'),
            ...get(row, 'conversions'),
            performanceMetric: startCase(conversionType as string),
            key: '',
            target: key,
          }))
        : []),
      ...map(value, (row) => ({
        ...get(row, 'campaign'),
        ...get(row, 'converters'),
        performanceMetric: 'Converters',
        key:
          key === 'target' && targets && targets[0]
            ? targets[0]
            : get(options, `keyRename[${key}]`, key),
        target: key,
      })),
    ],
    [],
  );
}

export function getReportOverviewConfig(
  useEquivalizedMetrics: boolean,
): TableOptions {
  const config = cloneDeep(reportOverviewConfig);
  const hasReachPermission = checkPermissions(
    'attribution_reports.campaign.reach::view',
  );
  const hasImpressionPermission = checkPermissions(
    'attribution_reports.campaign.impressions::view',
  );
  if (useEquivalizedMetrics) {
    config.columns[6].Header = 'Eq. Impressions (000)';
    config.columns[6].width = 110;
    config.columns[7].Header = 'Eq. Frequency';
    config.columns[7].width = 80;
  } else {
    config.columns[6].Header = 'Impressions (000)';
    config.columns[6].width = 100;
    config.columns[7].Header = 'Frequency';
    config.columns[7].width = 60;
  }

  const columnsToRender = config.columns.filter((col) => {
    if (col.accessor === 'REACH.DISPLAY_VALUE' && !hasReachPermission)
      return false;
    if (
      col.accessor === 'IMPRESSIONS.DISPLAY_VALUE' &&
      !hasImpressionPermission
    )
      return false;
    return true;
  });

  return {
    ...config,
    columns: columnsToRender,
  };
}
