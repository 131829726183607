import {
  IReportToolbar,
  IReportToolbarDispatchProps,
} from 'domains/toolbar/types';
import { connect } from 'react-redux';
import * as reportActions from 'store/actions/report';
import * as actions from 'store/actions/toolbar';
import * as reportSelector from 'store/selectors/report';
import * as selector from 'store/selectors/toolbar';
import State from 'types/state';
import { ReportToolbar } from './ReportToolbar.component';

const mapStateToProps = (state: State): IReportToolbar => ({
  isCancelRunDisabled: selector.getCancelIsDisabled(state),
  isRefreshDisabled: selector.getRefreshIsDisabled(state),
  isRefreshLaunched: selector.getRefreshLaunched(state),
  isRunDisabled: selector.getRunIsDisabled(state),
  isRunLaunched: selector.getRunLaunched(state),
  isSaveAsCopyDisabled: selector.getSaveAsIsDisabled(state),
  isSaveDisabled: selector.getSaveIsDisabled(state),
  lastCachedDate: selector.getLastExecutedAtCacheDate(state),
  progress: selector.getProgress(state),
  reportType: selector.getType(state),
  report: reportSelector.getReport(state),
});

const mapDispatchToProps: IReportToolbarDispatchProps = {
  setReportHasChanged: reportActions.setReportHasChanged,
  handleEnableDisableToolbarButtons: actions.handleEnableDisableToolbarButtons,
  handlePersistedReportChange: reportActions.handlePersistedReportChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportToolbar);
