export const LOCALE_AUDIT_BUTTON_TOOLTIP_LABEL = 'Audit';
export const LOCALE_AUDIT_MODAL_TITLE = 'Report Audit';
export const LOCALE_COPY_LINK_BUTTON_TOOLTIP_LABEL =
  'Click to copy report link to clipboard';
export const LOCALE_DELETE_STOP_QUERY_HEADER =
  'Do you want to cancel the report?';
export const LOCALE_DELETE_STOP_QUERY_TEXT = 'This cannot be undone.';
export const LOCALE_EXPORT_BUTTON_TEXT = 'Export';
export const LOCALE_EXPORT_RUNNING_BUTTON_TEXT = 'Exporting';
export const LOCALE_EXPORT_LABEL_XLXS = 'XLSX';
export const LOCALE_DESKTOP_DOWNLOAD = 'Download to Desktop';
export const LOCALE_S3_UPLOAD = 'Send to S3';
export const LOCALE_FIELD_METHODOLOGY = 'Methodology';
export const LOCALE_FIELD_QUERY = 'Report Configuration';
export const LOCALE_FIELD_RESULT = 'Result';
export const LOCALE_INVALID_TARGET =
  'The audience attached to this report is invalid and has been removed. Please add another audience.';
export const LOCALE_IS_PRIVATE = 'Report is now private';
export const LOCALE_IS_PUBLIC = 'Report is now public';
export const LOCALE_LINK_COPIED = 'Link copied to clipboard';
export const LOCALE_MISSING_REPORT_NAME_MODAL_TEXT = 'Name';
export const LOCALE_MISSING_REPORT_NAME_MODAL_TITLE =
  'Please enter a name for this report';
export const LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_MODAL_TITLE =
  'Please specify a name for your report';
export const LOCALE_MISSING_REPORT_NAME_SAVE_AS_COPY_NAME_LABEL = 'Name';
export const LOCALE_NOTIFICATION_HEADER = 'Notification';
export const LOCALE_NO_CHART_DATA = 'Run a query to display visualization';
export const LOCALE_REPORT_CREATED = 'Report created';
export const LOCALE_REPORT_DOWNLOADED =
  'Downloaded report export successfully.';
export const LOCALE_REPORT_UPDATED = 'Report updated';
export const LOCALE_CANCEL_REPORT_RUN = 'Cancel Report Run';
export const LOCALE_GO_BACK = 'Go Back';
export const LOCALE_REPORT_UPLOADED = 'This has been sent to your S3 bucket.';
export const LOCALE_REPORT_VALIDATION_ERROR =
  'There are errors with your report configuration. See highlighted fields below.';
