import { addDays } from 'date-fns';
import IReport from 'domains/reports/types';
import { IUndigestedDateRange } from 'types/dateRange';
import { FilterType } from 'types/filter';
import { IClient } from '../../domains/clients/types';
import IUser from '../../types/user';

export type AccumulatedEntries = {
  [key: string]: IUser | IClient;
};

const getAccumulatedEntries = (
  entries: IClient[] | IUser[],
): AccumulatedEntries =>
  (entries as (IClient | IUser)[])?.reduce(
    (accumulatedEntries: AccumulatedEntries, currentEntry: IClient | IUser) => {
      if (currentEntry.id) accumulatedEntries[currentEntry.id] = currentEntry;
      return accumulatedEntries;
    },
    {} as { [key: string]: IClient },
  ) || {};

export { getAccumulatedEntries };

export function addDayToEndDateInReport(report: IReport): void {
  const children = report?.query?.filters?.children;
  if (children?.length) {
    const dateRangeData = (children as IUndigestedDateRange[]).find(
      (childrenTypes: IUndigestedDateRange) =>
        childrenTypes.type === FilterType.DATETIME_RANGE,
    );
    if (dateRangeData)
      dateRangeData.end = addDays(new Date(dateRangeData.end), 1).toISOString();
  }
}
