import Loading from 'components/Loading';
import useFetch from 'hooks/useFetch';
import usePermissions from 'hooks/usePermissions';
import useToast from 'hooks/useToast';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Index } from 'routes';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import IRole, { Role } from 'types/role';
import RolesEdit from '../../edit.feature';

const RolesEditContainer = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState<IRole>();
  const { doErrorToast } = useToast();
  const { doFetch } = useFetch<IRole>();
  const { permissions, loading: loadingPermissions } = usePermissions();

  useEffect(() => {
    if (role) return;
    if (id) {
      if (loading || !id) return;
      setLoading(true);
      doFetch({
        endpoint: `/${Index.SEGMENT_ROLES}/${id}`,
        method: FetchMethod.GET,
        onSuccess: (res: IRole[]) => {
          setRole(res[0]);
          setLoading(false);
        },
        onError: (e: IError) => {
          doErrorToast(e?.message ?? 'There has been an error.');
          setLoading(false);
        },
      });
    } else {
      setRole(new Role());
    }
  }, [doErrorToast, doFetch, id, loading, role]);

  return (
    <>
      {!(loadingPermissions || loading) && role ? (
        <RolesEdit role={role} permissions={permissions ?? []} />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default RolesEditContainer;
