import { EVENT_DROPDOWN_SEARCH_SHOULD_CLOSE } from 'components/DropdownSearch';
import { Metric } from 'domains/metrics/types';
import IReport, {
  ChartType,
  ChartTypes,
  IBusinessData,
  reportType,
  reportTypeEquivalences,
  IDateRangeQueryFilter,
} from 'domains/reports/types';
import { cloneDeep } from 'lodash';
import find from 'lodash/find';
import { filter, get, map } from 'lodash/fp';
import isEmpty from 'lodash/isEmpty';
import { getQueryMode } from 'store/reducers/report';
import IDateRange, { newDateRange } from 'types/dateRange';
import {
  FilterType,
  IChildrenBase,
  IQueryFilters,
  IQueryFiltersChildren,
  RuleFilter,
  UndigestedBase,
  UndigestedFiltersChildren,
} from 'types/filter';
import { HasId, HasValue } from 'types/object';
import { IQuery } from 'types/query';
import ITextValue from 'types/textValue';
import { v4 as uuid } from 'uuid';
import { IDataset } from '../../domains/datasets/types';
import { LOCALE_DATASETS_SEGMENTS_TOOLTIP } from './locale';
import { checkPermissions } from 'domains/reports/adapters/general';

type ChartFilter = {
  selectedMetrics: Metric[];
  selectedCharts: ChartType[];
  selectedTargets: Array<string>;
};

export const defaultMetric = 'REACH';
export const defaultTarget = 'genpop';

export function getDateRangeFromQueryFilters(
  filters?: IQueryFiltersChildren | UndigestedFiltersChildren,
): IDateRangeQueryFilter {
  const dateTimeRange = (find(filters, ['type', FilterType.DATETIME_RANGE]) ??
    {}) as IDateRange;

  if (isEmpty(dateTimeRange)) {
    return {} as IDateRangeQueryFilter;
  }

  const { start, end, relativeDateOffset } = dateTimeRange as IDateRange;
  return {
    dateTimeRange,
    startDate: start,
    endDate: end,
    relativeDateOffset,
  };
}

export function getArrayOfIdsFromArrayOfMixedObjects(
  arr?: (number | string | HasValue | HasId)[],
): string[] {
  if (!arr || arr?.length === 0) return [];
  return arr.map((item) => {
    if (typeof item === 'string' || typeof item === 'number') return `${item}`;
    if (item.hasOwnProperty('value')) return `${item.value}`;
    if (item.hasOwnProperty('id')) return `${item.id}`;
    return '';
  });
}

const getType = get('type');
const getIsRule = get('isRule');
const getFilterId = (queryFilter: RuleFilter): string =>
  get('filterId', queryFilter) || uuid();

export const isDisabled = (report: IReport, mode: ITextValue): boolean => {
  if (mode.value === 'MODE_ADVERTS') {
    if (report.type !== reportType.attributionReport)
      return !checkPermissions(
        `${reportTypeEquivalences(
          report.type,
        )}.dataset_mode.mode_adverts::view`,
      );
  } else if (mode.value === 'MODE_CUSTOM_ADVERTS') {
    return !checkPermissions(
      `${reportTypeEquivalences(
        report.type,
      )}.dataset_mode.mode_custom_adverts::view`,
    );
  } else if (mode.value === 'MODE_GENERIC_EVENTS') {
    return !checkPermissions(
      `${reportTypeEquivalences(
        report.type,
      )}.dataset_mode.mode_generic_events::view`,
    );
  }

  return false;
};

export const isCumulativeDisabled = (): boolean => {
  return !checkPermissions('platf0rm_reports.cume_toggle::view');
};

export const injectDatasetControl = (
  businessData: IBusinessData,
  report: IReport,
): ITextValue[] =>
  getQueryMode(
    report.type === reportType.attributionReport
      ? businessData.attributionReport
      : businessData.report,
  )?.map((mode: ITextValue) => ({
    ...mode,
    tooltip: LOCALE_DATASETS_SEGMENTS_TOOLTIP[mode.text],
    disabled: isDisabled(report, mode),
  }));

export function getFiltersFromQueryFilters(
  filters?: IQueryFiltersChildren | UndigestedFiltersChildren,
): RuleFilter[] {
  const filteredFilters = filter(
    (x: IChildrenBase | IQueryFilters[] | UndigestedBase) =>
      (getType(x) !== 'DATETIME_RANGE' &&
        get('id', x) !== 'breakouts_categories') ||
      (getType(x) === 'DATETIME_RANGE' && getIsRule(x)),
    filters,
  );

  const ruleFilterList = map(
    (queryFilter: RuleFilter): RuleFilter => ({
      ...queryFilter,
      filterId: getFilterId(queryFilter),
    }),
    filteredFilters,
  );

  return ruleFilterList as unknown as RuleFilter[];
}

export const getTextValueArrayFromArray = (
  selected: string[],
  options: ITextValue[],
): ITextValue[] =>
  (selected ?? []).reduce((aggregated, current) => {
    const found = options.find(({ value }) => current === value);
    if (found) aggregated.push(found);
    return aggregated;
  }, [] as ITextValue[]);

export const getArrayFromTextValueArray = (options: ITextValue[]): string[] =>
  options.map(({ value }) => `${value}`);

export const getParametersFromQuery = (
  query: IQuery,
): {
  startDate?: string;
  endDate?: string;
  selectedMetrics: string[];
  selectedDimensions: string[];
  filters: RuleFilter[];
  tableVisible?: boolean;
  chartVisible?: boolean;
  relativeDateOffset?: string;
} => ({
  ...getDateRangeFromQueryFilters(query?.filters.children),
  selectedMetrics: query.select ?? [],
  selectedDimensions: query.group ?? [],
  filters: getFiltersFromQueryFilters(query.filters.children),
  tableVisible: query.tableVisible,
  chartVisible: query.chartVisible,
});

export const getQueryFiltersWithNewDateRange = (
  startDate: string,
  endDate: string,
  filters: IQueryFilters,
): IQueryFilters => {
  const rules = getFiltersFromQueryFilters(filters.children);
  return {
    type: FilterType.LOGICAL,
    operator: 'AND',
    children: [newDateRange(startDate, endDate), ...rules],
  };
};

export const getQueryFiltersWithNewRules = (
  rules: RuleFilter[],
  filters: IQueryFilters,
): IQueryFilters => {
  const { dateTimeRange } = getDateRangeFromQueryFilters(filters.children);
  return {
    type: FilterType.LOGICAL,
    operator: 'AND',
    children: [dateTimeRange, ...rules],
  };
};

export const metricConfigIsValid = (
  configMetrics: string[],
  configCharts: string[],
  updatedQuery: IQuery,
): boolean => {
  const metricIsUnsynced = configMetrics?.some(
    (metric: string) => !updatedQuery.select?.includes(metric),
  );
  const configIsEmpty = !configMetrics?.length || !configCharts?.length;
  return !configIsEmpty && !metricIsUnsynced;
};

export const getDefaultSelectedConfig = (queryData: IQuery): ChartFilter => ({
  selectedCharts: [ChartTypes.column],
  selectedMetrics: [
    (queryData?.select && queryData?.select[0]) || defaultMetric,
  ],
  selectedTargets: queryData?.targets ?? [defaultTarget],
});

export const getTextValueDatasets = (
  datasetsOptions: IDataset[] | undefined,
): ITextValue[] =>
  datasetsOptions?.map((datasetOption: IDataset) => ({
    text: datasetOption?.display_name?.toString() || '',
    value: datasetOption?.id?.toString() || '',
  })) ?? [];

export const eventDropdownShouldClose = new CustomEvent(
  EVENT_DROPDOWN_SEARCH_SHOULD_CLOSE,
);

export const getDefaultTargetSpecificValues = (
  filter: RuleFilter,
): RuleFilter => {
  interface ITargetSpecificDefaults {
    [key: string]: string;
  }

  let copyOfFilter = cloneDeep(filter);
  // Key is equal to a filter id, value is default value
  const targetSpecificDefaults: ITargetSpecificDefaults = {
    CONSECUTIVE_TELECAST_VIEWING_HH: '59',
    AD_TUNING_DURATION: '2',
  };

  // If value is undefined it has just been added to the query.
  if (filter.value === undefined && targetSpecificDefaults[filter.id!]) {
    copyOfFilter.value = targetSpecificDefaults[filter.id!];
  }

  return copyOfFilter;
};
