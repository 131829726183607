import { IFeatureProp } from 'features';
import ReportsEditContainer from 'features/reports/components/ReportsEditContainer';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Actions } from 'routes';
import TargetsListContainer from './components/TargetsListContainer';

const Reports: FunctionComponent<IFeatureProp> = ({ path }) => {
  const { checkPermissions } = useUserPermissions();

  return (
    <>
      <Routes>
        {checkPermissions('targets.module::view') && (
          <>
            <Route index element={<TargetsListContainer path={path} />} />
            <Route
              path={Actions.SEGMENT_CREATE}
              element={<ReportsEditContainer path={path} />}
            />
            <Route
              path={`${Actions.SEGMENT_EDIT}/:id`}
              element={<ReportsEditContainer path={path} />}
            />
          </>
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};
export default Reports;
