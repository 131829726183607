import { getOrSetDefault } from '../../../../helpers/getOrSetDefault';
import { IPermissionTree } from '../../types';
import { permissionToPath } from '../permissionToPath';

export const permissionStrToTree = (
  permissionStr: string[],
): IPermissionTree => {
  const permissionTree = {};
  permissionStr.forEach((permission) => {
    const path = permissionToPath(permission);
    getOrSetDefault<IPermissionTree>(permissionTree, path);
  });
  return permissionTree;
};
