import { getClass } from 'helpers/components';
import { get } from 'lodash/fp';
import React, { FunctionComponent, useState, ChangeEvent } from 'react';

const componentName = 'duration-item';
const componentClass = getClass(componentName);

export type IDurationItem = {
  initialValue?: number;
  max?: number;
  name?: string;
  autoFocus?: boolean;
  onChange: (value: number) => void;
};

const DurationItem: FunctionComponent<IDurationItem> = ({
  onChange,
  initialValue = 0,
  max = Number.MAX_SAFE_INTEGER,
  autoFocus = false,
  name,
}) => {
  const [curVal, setCurVal] = useState(initialValue);
  const maxOrZero = max >= Number.MAX_SAFE_INTEGER ? 0 : +max - 1;
  const minOrValue = (value: number): number => (value <= 0 ? 0 : +value);

  const decreaseValue = (): void => {
    const newValue = curVal <= 0 ? maxOrZero : +curVal - 1;
    setCurVal(newValue);
    onChange(newValue);
  };
  const increaseValue = (): void => {
    const newValue = curVal >= +max - 1 ? 0 : +curVal + 1;
    setCurVal(newValue);
    onChange(newValue);
  };

  const handleOnChange = (event: ChangeEvent): void => {
    const value = get('target.value', event);
    const newValue = value >= max ? maxOrZero : minOrValue(value);
    setCurVal(newValue);
    onChange(newValue);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  const handleOnBlur = (
    event: ChangeEvent<{ value: number | string }>,
  ): void => {
    const value = +get('target.value', event);

    setCurVal(value);
    onChange(value);
  };

  return (
    <div className={componentClass} key={name}>
      <span className="name">{name}</span>
      <input
        max={max - 1}
        min={0}
        autoFocus={autoFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        type="number"
        value={curVal}
      />
      <button
        aria-label={`Decrease ${name}`}
        onClick={decreaseValue}
        type="button"
      >
        &ndash;
      </button>
      <button
        aria-label={`Increase ${name}`}
        onClick={increaseValue}
        type="button"
      >
        +
      </button>
    </div>
  );
};

export default DurationItem;
