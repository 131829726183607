import { IProcessing } from 'domains/processing/types';
import { Dispatch } from 'redux';
import ActionType from 'store/actions/types';
import * as processingSelectors from 'store/selectors/processing';
import { Action } from 'types/action';
import State from 'types/state';

export const setProccessing = (payload: IProcessing): Action<IProcessing> => ({
  type: ActionType.PROCESSING_SET_STATUS,
  payload,
});

export const startProcessing =
  (label = 'Processing...') =>
  (dispatch: Dispatch): void => {
    dispatch(
      setProccessing({
        label,
        running: true,
        percentage: null,
      }),
    );
  };

export const updateLabel =
  (label: string) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const processing: IProcessing =
      processingSelectors.getProcessing(getState());
    dispatch(
      setProccessing({
        ...processing,
        label,
      }),
    );
  };

export const updatePercentage =
  (percentage: number) =>
  (dispatch: Dispatch, getState: () => State): void => {
    const processing: IProcessing =
      processingSelectors.getProcessing(getState());
    dispatch(
      setProccessing({
        ...processing,
        percentage,
      }),
    );
  };

export const finishProcessing =
  () =>
  (dispatch: Dispatch): void => {
    dispatch(
      setProccessing({
        running: false,
        percentage: null,
      }),
    );
  };
