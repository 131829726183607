import IPermission from 'domains/permissions/types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Index } from 'routes';
import { setPermissions } from 'store/actions/domains';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import State from 'types/state';
import useFetch from './useFetch';
import useToast from './useToast';

const useUserPermissions = (): {
  permissions?: IPermission[];
  loading: boolean;
} => {
  const { doFetch } = useFetch<IPermission>();
  const { doErrorToast } = useToast();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const currentPermissions = useSelector(
    ({ domains: { permissions: permissionsState } }: State) => permissionsState,
  );

  useEffect(() => {
    if (loading || currentPermissions) return;
    setLoading(true);
    doFetch({
      endpoint: `/${Index.SEGMENT_PERMISSIONS}`,
      method: FetchMethod.GET,
      onSuccess: (res) => {
        dispatch(setPermissions(res));
        setLoading(false);
      },
      onError: (e: IError) => {
        dispatch(setPermissions([]));
        doErrorToast(e?.message ?? 'There has been an error.');
        setLoading(false);
      },
    });
  }, [currentPermissions, dispatch, doErrorToast, doFetch, loading]);

  return { permissions: currentPermissions, loading };
};

export default useUserPermissions;
