import {
  ResultBase,
  ResultBaseSubStructure,
  ResultBaseTotalDataTarget,
} from 'domains/reports/types';
import { getBreakoutDataTarget } from 'helpers/charts/chartWithTable/getBreakoutDataTarget';

export const getUniqPlatformBreakoutData = (
  data: ResultBase,
): ResultBaseTotalDataTarget => {
  const breakoutData = getBreakoutDataTarget(
    data,
    'UNIQUE_VIEWING_TYPE_COMBINATIONS',
  );
  const breakoutDataTarget =
    (breakoutData.target as ResultBaseSubStructure[]) ?? [];
  const breakoutDataGenpop =
    (breakoutData.genpop as ResultBaseSubStructure[]) ?? [];

  return {
    target: breakoutDataTarget,
    genpop: breakoutDataGenpop,
  };
};
