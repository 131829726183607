export const LOCALE_FIELD_CONFIG_OPTION_ID = 'Config Option ID';
export const LOCALE_ACTION_SAVE = 'Save';
export const LOCALE_ACTION_CANCEL = 'Cancel';
export const LOCALE_ACTION_SAVING = 'Saving...';
export const LOCALE_EDIT_TITLE = 'Edit Config Option';
export const LOCALE_CREATE_TITLE = 'New Config Option';
export const LOCALE_EMPTY_VALUE_FIELD_ERROR = 'The value field is required';
export const LOCALE_EMPTY_TEXT_FIELD_ERROR = 'The text field is required';
export const LOCAL_CONFIG_OPTION_ID_ERROR = 'The config option id is required';
export const LOCALE_SAVE_SUCCEEDED = 'Config Option saved!';
export const LOCALE_UPDATE_SUCCEEDED = 'Config Option updated!';
export const LOCALE_SAVE_FAILED = 'Failed to save Config Option!';
export const LOCALE_LOAD_FAILED = 'Failed to load Config Option!';
