import Button, { Kind } from 'components/Button';
import H1 from 'components/H4';
import useToast from 'hooks/useToast';
import { isEmpty } from 'lodash';
import CSVParser from 'papaparse';
import React, {
  useState,
  useRef,
  FunctionComponent,
  useCallback,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUrl, Index } from 'routes';
import * as editManyUsersActions from 'store/actions/editManyUsers';
import * as editManyUsersSelectors from 'store/selectors/edit-many-users';
import { IEditManyUsersProps } from 'types/edit-many-users';
import State from 'types/state';
import { EditManyTable } from './components/EditManyTable';

const UsersEditMany: FunctionComponent<IEditManyUsersProps> = (
  props: IEditManyUsersProps,
) => {
  const {
    validateUploadedFile,
    resetPreviewTable,
    handleSubmit,
    previewTable,
  } = props;
  const [file, setFile] = useState<File | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const { doErrorToast } = useToast();

  const navigate = useNavigate();

  const cancelAction = useCallback((): void => {
    const createUrl = getUrl([Index.SEGMENT_ADMIN, Index.SEGMENT_USERS]);
    resetPreviewTable();
    navigate(`/${createUrl}`);
  }, [navigate, resetPreviewTable]);

  // reset table when unmounting
  useEffect(
    () => () => {
      resetPreviewTable();
    },
    [resetPreviewTable],
  );

  return (
    <div className="edit-many-users">
      <header>
        <H1>Bulk Update Users</H1>
      </header>
      <section>
        <div className="browse-input-container">
          <div className="browse-text-container">
            <span>Upload CSV file with users to be updated.</span>
          </div>
          <div className="template-link-container">
            <a
              href="/605/docs/users-update-template.csv"
              target="_blank"
              rel="noreferrer"
            >
              Download template.
            </a>
          </div>
          <input
            type="file"
            accept=".csv"
            multiple={false}
            onClick={() => {
              setFile(undefined);
              const fileInputElement = inputRef.current;
              if (fileInputElement) fileInputElement.value = '';
            }}
            onChange={(event) => {
              const uploadedFile = event.target.files?.[0] as File;
              setFile(uploadedFile);
              CSVParser.parse(uploadedFile, {
                error: () => {
                  doErrorToast('Failed to parse CSV file.');
                },
                complete: validateUploadedFile,
                skipEmptyLines: true,
              });
            }}
            ref={inputRef}
            className="hidden-input"
          />
          <Button
            kind={Kind.outline}
            onClick={() => inputRef.current?.click()}
            className="spaced-btn"
          >
            Browse
          </Button>
          <span className="file-name">{file?.name}</span>
        </div>
      </section>
      {!isEmpty(previewTable) && (
        <section>
          <EditManyTable content={previewTable} />
          <div className="actions-container">
            <Button onClick={cancelAction} kind={Kind.outline}>
              Cancel
            </Button>
            <Button kind={Kind.primary} onClick={handleSubmit}>
              Continue
            </Button>
          </div>
        </section>
      )}
    </div>
  );
};

const mapStateToProps = (
  state: State,
): Pick<IEditManyUsersProps, 'previewTable'> => ({
  previewTable: editManyUsersSelectors.previewTable(state),
});

const mapDispatchToProps = {
  validateUploadedFile: editManyUsersActions.validateUploadedFile,
  resetPreviewTable: editManyUsersActions.resetPreviewTable,
  handleSubmit: editManyUsersActions.handleSubmit,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersEditMany);
