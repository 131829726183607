import ChartOmissionAlert from 'components/ChartOmissionAlert';
import { getClass, getTestId } from 'helpers/components';
import Highcharts, {
  Chart,
  SeriesBarOptions,
  SeriesLineOptions,
} from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useChartReflow } from 'hooks/useChartReflow';
import { merge } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { useMultiseriesChartConfig } from './tools/useMultiseriesChartConfig';

export const multiseriesChartName = 'multiseries-chart';
const legendTestId = getTestId(multiseriesChartName, 'legend');
const multiseriesClass = getClass(multiseriesChartName);

interface IMultiseriesChart {
  chartConfig: Highcharts.Options;
  legend?: React.ReactNode;
  testId?: string;
}

export const MultiseriesChart: React.FC<IMultiseriesChart> = ({
  chartConfig,
  legend,
  testId,
}) => {
  const [plotBoundary, setPlotBoundary] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const multiseriesChartConfig = useMultiseriesChartConfig({
    chartConfig,
  });

  const options = useMemo(
    () => ({
      ...multiseriesChartConfig,
      exporting: {
        ...multiseriesChartConfig.exporting,
        buttons: {
          contextButton: {
            x: -67,
          },
        },
      },
      chart: merge({}, multiseriesChartConfig.chart, {
        events: {
          render() {
            const { plotHeight, plotLeft, plotTop, plotWidth } =
              this as unknown as Chart;
            const newBoundary = {
              top: plotTop as number,
              left: plotLeft as number,
              width: plotWidth as number,
              height: plotHeight as number,
            };

            setPlotBoundary(newBoundary);
          },
        },
      }),
    }),
    [multiseriesChartConfig],
  );

  const multichartTestId = useMemo(
    () => getTestId(multiseriesChartName, testId),
    [testId],
  );

  const hasOmission = useMemo(() => {
    const doAllSeriesHaveEmptyData = options.series?.every(
      (s) => !(s as SeriesBarOptions | SeriesLineOptions).data?.length,
    );
    return doAllSeriesHaveEmptyData;
  }, [options.series]);

  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useChartReflow(chartRef.current?.chart, containerRef.current);

  return (
    <div
      className={multiseriesClass}
      data-testid={multichartTestId}
      ref={containerRef}
    >
      <div className={multiseriesClass}>
        {legend && <div data-testid={legendTestId}>{legend}</div>}
        {hasOmission && (
          <div className={`${multiseriesClass}__overlay`} style={plotBoundary}>
            <ChartOmissionAlert />
          </div>
        )}
        <HighchartsReact
          allowChartUpdate
          highcharts={Highcharts}
          options={options}
          oneToOne
          ref={chartRef}
        />
      </div>
    </div>
  );
};

export default MultiseriesChart;
