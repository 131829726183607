import Button, { Size } from 'components/Button';
import { ChartType } from 'components/ChartWithTable';
import { IPlotLines } from 'components/ChartWithTable/components/BubbleChart';
import Flex, { Horizontal, Vertical } from 'components/Flex';
import { Type as IconType } from 'components/Icon';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import SegmentedControl from 'components/SegmentedControl';
import { TableDataType } from 'components/Table';
import { ToggleSwitch } from 'components/ToggleSwitch';
import IReport, {
  ChartData,
  ChartDataSet,
  PerformanceGroupNames,
  ResultBase,
} from 'domains/reports/types';
import {
  IResultSelectionFilters,
  IResultSelectionRowsRemoved,
} from 'domains/resultSelections/types';
import { getBreakoutOptions } from 'helpers/charts/getBreakoutOptions';
import { getClass } from 'helpers/components';
import { trackResetGraphClick } from 'helpers/mixpanel';
import { decodeBreakout } from 'helpers/resultSelection/decodeBreakout';
import { getPersistedTargets } from 'helpers/resultSelection/getPersistedTargets';
import { find } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { get } from 'lodash/fp';
import { connect } from 'react-redux';
import * as resultSelectionsActions from 'store/actions/resultSelections';
import * as resultSelectors from 'store/selectors/reportResult';
import { IOption } from 'types/textValue';
import State from 'types/state';
import useUserPermissions from 'hooks/useUserPermissions';
import { ChartHelper } from '../components/ChartHelper';
import ChartWithTableWrapper from './components/ChartWithTableWrapper';
import { ActionTypes, reducer } from './reducer';
import {
  DEFAULT_SORTED_BY,
  DROPDOWN_DEFAULT_KEYS,
  getDefaultSelection,
} from './helpers/getDefaultSelection';
import { getChartTitle } from './helpers/getChartTitle';

export interface ICampaignDeliveryOutcomesProps {
  chartData: ResultBase;
  targets: IOption[];
  breakoutList: Array<{ id: string; name: string }>;
  performanceMetricGroups: PerformanceGroupNames[];
  conversionType?: string;
  sectionFiltersSelection: IResultSelectionFilters['campaignDeliveryOutcomes'];
  sectionRowsRemovedSelection: IResultSelectionRowsRemoved['campaignDeliveryOutcomes'];
  lastValidReport: IReport | null;
  useEquivalizedMetrics?: boolean;
  updateResultSelectionFilters: (
    payload: Partial<IResultSelectionFilters>,
  ) => Promise<void>;
  updateResultSelectionRowsRemoved: (
    payload: Partial<IResultSelectionRowsRemoved>,
  ) => Promise<void>;
  axisOptions: IOption[];
}

const resultsCountOptions = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
];

const chartFiltersComponentName = 'chart-filters-wrapper';
const chartFiltersClass = getClass(chartFiltersComponentName);
const containerClass = getClass(chartFiltersComponentName, {
  concat: ['container'],
});
const headerClass = getClass(chartFiltersComponentName, {
  concat: ['header'],
});
const filtersClass = getClass(chartFiltersComponentName, {
  concat: ['filters'],
});
const labelClass = getClass(chartFiltersComponentName, {
  concat: ['label'],
});
const segmentedControlClass = getClass(chartFiltersComponentName, {
  concat: ['segmented-control-wrapper'],
});

const multiSelectDropdownClass = getClass(chartFiltersComponentName, {
  concat: ['multiselect-dropdown-wrapper'],
});

const chartWrapperComponentName = 'chart-wrapper';
export const chartWrapperClass = getClass(chartWrapperComponentName);

export const sectionName = 'Campaign Delivery Outcomes';

export const DEFAULT_IS_SORTED_DESC = true;

const chartTypeOptions = [
  { text: 'Bar', value: 'BAR', iconType: IconType.chartTypeColumn },
  {
    text: 'Scatter',
    value: 'BUBBLE',
    iconType: IconType.chartTypeBubble,
  },
];

export const CampaignDeliveryOutcomesComponent: React.FC<
  ICampaignDeliveryOutcomesProps
> = ({
  chartData,
  targets,
  breakoutList,
  performanceMetricGroups,
  conversionType,
  sectionFiltersSelection,
  sectionRowsRemovedSelection,
  lastValidReport,
  useEquivalizedMetrics,
  updateResultSelectionFilters,
  updateResultSelectionRowsRemoved,
  axisOptions,
}) => {
  const { checkPermissions } = useUserPermissions();
  const didMount = useRef(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const hasNetworkOnOff = useMemo(
    () => !!find(breakoutList, (breakout) => breakout.id === 'NET_ON_OFF'),
    [breakoutList],
  );

  const canViewScatterPlot = checkPermissions(
    'attribution_reports.campaign_delivery_and_insights.scatter_plot::view',
  );

  const canViewPersuasionIndex = checkPermissions(
    'attribution_reports.campaign_delivery_and_insights.persuasion_index::view',
  );

  const initialChartType = useMemo(() => {
    if (canViewScatterPlot) {
      return (sectionFiltersSelection?.chartType as ChartType) ?? ChartType.BAR;
    }
    return ChartType.BAR;
  }, [canViewScatterPlot, sectionFiltersSelection]);
  const hasUniquePlatform = useMemo(
    () =>
      !!find(
        breakoutList,
        (breakout) => breakout.id === 'UNIQUE_VIEWING_TYPE_COMBINATIONS',
      ),
    [breakoutList],
  );

  const breakoutOptions: IOption[] = getBreakoutOptions(
    breakoutList,
    performanceMetricGroups,
    canViewPersuasionIndex,
    conversionType,
  );

  const getOptionFromFilter = (filter: string): IOption =>
    axisOptions.find((option) => option.value === filter) ?? axisOptions[0];

  const [{ filteredData, filters }, dispatch] = useReducer(reducer, {
    data: {},
    filteredData: { target: [], genpop: [] },
    filters: {
      breakout: getDefaultSelection(
        DROPDOWN_DEFAULT_KEYS.BREAKOUT,
        breakoutOptions,
        sectionFiltersSelection,
      ),
      netOnOff: false,
      uniqueOnOff: false,
      targets: getPersistedTargets(targets, sectionFiltersSelection?.targets),
      xAxis: getDefaultSelection(
        DROPDOWN_DEFAULT_KEYS.X,
        axisOptions,
        sectionFiltersSelection,
      ),
      yAxis: getDefaultSelection(
        DROPDOWN_DEFAULT_KEYS.Y,
        axisOptions,
        sectionFiltersSelection,
      ),
      bubbleSize: getDefaultSelection(
        DROPDOWN_DEFAULT_KEYS.BUBBLE_SIZE,
        axisOptions,
        sectionFiltersSelection,
      ),
      resultsCount: sectionFiltersSelection?.results ?? 25,
      sortedBy: getDefaultSelection(
        DROPDOWN_DEFAULT_KEYS.SORTED_BY,
        axisOptions,
        sectionFiltersSelection,
      ),
      isSortedDesc:
        sectionFiltersSelection?.isSortedDesc ?? DEFAULT_IS_SORTED_DESC,
      canViewLift: false,
      prevYAxis: getDefaultSelection(
        DROPDOWN_DEFAULT_KEYS.Y,
        axisOptions,
        sectionFiltersSelection,
      ),
      prevXAxis: getDefaultSelection(
        DROPDOWN_DEFAULT_KEYS.X,
        axisOptions,
        sectionFiltersSelection,
      ),
      prevBubbleSize: getDefaultSelection(
        DROPDOWN_DEFAULT_KEYS.BUBBLE_SIZE,
        axisOptions,
        sectionFiltersSelection,
      ),
      chartType: initialChartType,
      axisOptions,
    },
    lastValidReport,
    currBreakoutData: {},
    hasNetworkOnOff,
    hasUniquePlatform,
  });

  const handleXAxisChange = (selection: IOption[]): void => {
    dispatch({
      type: ActionTypes.CHANGE_X_AXIS,
      payload: selection[0],
    });
  };

  const handleYAxisChange = (selection: IOption[]): void => {
    dispatch({
      type: ActionTypes.CHANGE_Y_AXIS,
      payload: selection[0],
    });
  };

  const handleZAxisChange = (selection: IOption[]): void => {
    dispatch({
      type: ActionTypes.CHANGE_BUBBLE_SIZE,
      payload: selection[0],
    });
  };

  const isBreakoutTop25 = filters.breakout?.includes('TOP_25');
  const isBreakoutNETWORK = filters.breakout === 'NETWORK';
  const isBreakoutPLATFORM = filters.breakout === 'VIEWING_TYPE';

  useEffect(() => {
    dispatch({
      type: ActionTypes.INITIALIZE,
      payload: {
        chartData,
        rowsRemoved: sectionRowsRemovedSelection,
        sortedBy: sectionFiltersSelection?.sortedBy ?? DEFAULT_SORTED_BY,
        isSortedDesc:
          sectionFiltersSelection?.isSortedDesc ?? DEFAULT_IS_SORTED_DESC,
      },
    });

    // NOTE: We need to initialize only once for rowsRemoved, so it's not added to dependency array.
    // Otherwise, the chart would reinitialize every time the row is removed.
  }, []);

  const removeRecord = useCallback(
    (recordData: TableDataType): void => {
      const { breakout, target, name } = recordData;

      const decodedBreakout = decodeBreakout(
        breakout as string,
        name as string,
      );

      dispatch({
        type: ActionTypes.REMOVE_RECORD_FROM_DATA,
        payload: { ...recordData, decodedBreakout },
      });

      updateResultSelectionRowsRemoved({
        campaignDeliveryOutcomes: [
          ...(sectionRowsRemovedSelection ?? []),
          {
            breakout: decodedBreakout,
            target: target as string,
            name: name as string,
          },
        ],
      });
    },
    [sectionRowsRemovedSelection, updateResultSelectionRowsRemoved],
  );

  const handleResetGraphClick = (): void => {
    trackResetGraphClick({ sectionName, lastValidReport });

    dispatch({
      type: ActionTypes.RESET_CHART_DATA,
      payload: {
        chartData,
        rowsRemoved: [],
        sortedBy: DEFAULT_SORTED_BY,
        isSortedDesc: DEFAULT_IS_SORTED_DESC,
      },
    });

    updateResultSelectionRowsRemoved({
      campaignDeliveryOutcomes: [],
    });
  };

  useEffect(() => {
    if (didMount.current) {
      const filtersTargets = filters.targets.map((t) => t.value);

      updateResultSelectionFilters({
        campaignDeliveryOutcomes: {
          netOnOff: filters.netOnOff,
          uniqueOnOff: filters.uniqueOnOff,
          breakout: filters.breakout,
          targets: filtersTargets as string[],
          x: filters.xAxis,
          y: filters.yAxis,
          bubbleSize: filters.bubbleSize,
          results: filters.resultsCount,
          chartType: filters.chartType,
          sortedBy: filters.sortedBy,
          isSortedDesc: filters.isSortedDesc,
        },
      });
    } else {
      didMount.current = true;
    }
  }, [updateResultSelectionFilters, filters]);

  const yPlotLines: IPlotLines = useMemo(
    () =>
      filters.targets.map((target: IOption) => {
        const fullCampaign = filteredData[
          target.value as keyof ChartData
        ]?.find((set: ChartDataSet) => set.name?.VALUE === 'FULL_CAMPAIGN');

        return {
          text: 'Full campaign',
          color: target.color,
          value: Number(get(filters.yAxis, fullCampaign)?.VALUE),
        };
      }),

    [filteredData, filters.targets, filters.yAxis],
  );

  return (
    <section className={chartFiltersClass}>
      <div className={`${containerClass} pb-0 grid`}>
        <Flex vertical={Vertical.between} style={{ paddingRight: 20 }}>
          <h3 className={headerClass}>{getChartTitle(axisOptions, filters)}</h3>
          {filters.chartType === ChartType.BUBBLE && (
            <ChartHelper
              onMouseEnter={() => setShowOverlay(true)}
              onMouseLeave={() => setShowOverlay(false)}
            />
          )}
        </Flex>
        <Flex horizontal={Horizontal.right} style={{ width: '100%' }}>
          <Button size={Size.small} onClick={handleResetGraphClick}>
            Reset Data
          </Button>
        </Flex>
      </div>

      <div className={`${chartWrapperClass} pt-0`}>
        <ChartWithTableWrapper
          filters={filters}
          performanceMetricGroups={performanceMetricGroups}
          filteredData={filteredData as ChartData}
          yPlotLines={yPlotLines}
          showOverlay={showOverlay}
          removeRecord={removeRecord}
          breakoutOptions={breakoutOptions}
          conversionType={conversionType}
          useEquivalizedMetrics={useEquivalizedMetrics}
          chartType={filters.chartType}
          changeSortedBy={(id: string) =>
            dispatch({
              type: ActionTypes.CHANGE_SORTED_BY,
              payload: id,
            })
          }
        />
        {hasUniquePlatform && isBreakoutPLATFORM && (
          <div
            className={`${chartWrapperClass}__switch-button`}
            data-testid={`${chartWrapperClass}__switch-button`}
          >
            <Flex>
              <label className={`${labelClass}--small-switch mb-0`}>
                Combined Platform Viewership
              </label>
              <ToggleSwitch
                checked={filters.uniqueOnOff}
                name="Combined Platform Viewership"
                onChange={(event: boolean) =>
                  dispatch({
                    type: ActionTypes.CHANGE_UNIQUE_ON_OFF,
                    payload: {
                      uniqueOnOff: event,
                    },
                  })
                }
                yesLabel="ON"
                noLabel="OFF"
                small
              />
            </Flex>
          </div>
        )}
        {hasNetworkOnOff && isBreakoutNETWORK && (
          <div className={`${chartWrapperClass}__switch-button`}>
            <Flex>
              <label className={`${labelClass}--small-switch mb-0`}>
                On/Off Network Grouping
              </label>
              <ToggleSwitch
                checked={filters.netOnOff}
                name="On/Off Network Grouping"
                onChange={(event: boolean) =>
                  dispatch({
                    type: ActionTypes.CHANGE_NET_ON_OFF,
                    payload: event,
                  })
                }
                yesLabel="ON"
                noLabel="OFF"
                small
              />
            </Flex>
          </div>
        )}
      </div>
      <div className={filtersClass}>
        <div>
          <label className={labelClass}>Audiences</label>
          <MultiSelectDropdown
            options={targets}
            selected={filters.targets}
            placeholder="Choose audience"
            multiSelect
            showLegend
            onChange={(selectedTargets) =>
              dispatch({
                type: ActionTypes.CHANGE_TARGETS,
                payload: selectedTargets,
              })
            }
            minimumChipsSelected={1}
          />
        </div>
        <Flex horizontal={Horizontal.between}>
          <div className={`${multiSelectDropdownClass} mt-1`}>
            <label className={labelClass}>Breakout</label>
            <MultiSelectDropdown
              options={breakoutOptions}
              testId="breakout"
              onChange={(breakout) =>
                dispatch({
                  type: ActionTypes.CHANGE_BREAKOUT,
                  payload: breakout?.[0] ?? breakoutOptions?.[0],
                })
              }
              selected={[
                breakoutOptions?.find(
                  (breakoutOption) => breakoutOption.value === filters.breakout,
                ) ?? breakoutOptions?.[0],
              ]}
              multiSelect={false}
              showLegend={false}
              minimumChipsSelected={1}
            />
          </div>
          {filters.chartType === ChartType.BUBBLE && (
            <div className={`${multiSelectDropdownClass} mt-1`}>
              <label className={labelClass}>Bubble Size</label>
              <MultiSelectDropdown
                options={axisOptions}
                testId="z-axis"
                onChange={handleZAxisChange}
                selected={[getOptionFromFilter(filters.bubbleSize)]}
                disabled={false}
                showLegend={false}
                multiSelect={false}
                minimumChipsSelected={1}
              />
            </div>
          )}
          {filters.chartType === ChartType.BAR && (
            <div className={`${multiSelectDropdownClass} mt-1`}>
              <label className={labelClass}>Y-Axis</label>
              <MultiSelectDropdown
                options={axisOptions}
                testId="y-axis"
                onChange={handleYAxisChange}
                selected={[getOptionFromFilter(filters.yAxis)]}
                multiSelect={false}
                showLegend={false}
                minimumChipsSelected={1}
              />
            </div>
          )}
        </Flex>
        <Flex horizontal={Horizontal.between}>
          <Flex horizontal={Horizontal.left} className=" w-50">
            <div className={`${segmentedControlClass} mt-1`}>
              <label className={labelClass}># of Results</label>
              <SegmentedControl
                options={resultsCountOptions}
                onSelect={(resultsCount) =>
                  dispatch({
                    type: ActionTypes.CHANGE_RESULTS_COUNT,
                    payload: Number(resultsCount),
                  })
                }
                selected={filters.resultsCount}
                disabled={isBreakoutTop25}
                size="small"
              />
            </div>
            <div className={`${segmentedControlClass} mt-1 ml-1`}>
              <label className={labelClass}>Graph</label>
              <SegmentedControl
                options={chartTypeOptions}
                onSelect={(chartType) =>
                  dispatch({
                    type: ActionTypes.CHANGE_CHART_TYPE,
                    payload:
                      chartTypeOptions.find(
                        (option) => option.value === chartType,
                      ) ?? chartTypeOptions[0],
                  })
                }
                selected={filters.chartType}
                disabled={!canViewScatterPlot}
                size="small"
              />
            </div>
          </Flex>
          {filters.chartType === ChartType.BUBBLE && (
            <div className="mt-1 w-50">
              <Flex horizontal={Horizontal.right}>
                <div className={`${multiSelectDropdownClass} mr-2`}>
                  <label className={labelClass}>X-Axis</label>
                  <MultiSelectDropdown
                    options={axisOptions}
                    testId="x-axis"
                    onChange={handleXAxisChange}
                    selected={[getOptionFromFilter(filters.xAxis)]}
                    multiSelect={false}
                    showLegend={false}
                    minimumChipsSelected={1}
                  />
                </div>
                <div className={`${multiSelectDropdownClass}`}>
                  <label className={labelClass}>Y-Axis</label>
                  <MultiSelectDropdown
                    options={axisOptions}
                    testId="y-axis"
                    onChange={handleYAxisChange}
                    selected={[getOptionFromFilter(filters.yAxis)]}
                    multiSelect={false}
                    showLegend={false}
                    minimumChipsSelected={1}
                  />
                </div>
              </Flex>
            </div>
          )}
        </Flex>
      </div>
    </section>
  );
};

const mapDispatchToProps = {
  updateResultSelectionFilters:
    resultSelectionsActions.updateResultSelectionFilters,
  updateResultSelectionRowsRemoved:
    resultSelectionsActions.updateResultSelectionRowsRemoved,
};

const mapStateTopProps = (state: State): { axisOptions: IOption[] } => ({
  axisOptions: resultSelectors.getAxisOptions(state),
});

export default connect(
  mapStateTopProps,
  mapDispatchToProps,
)(CampaignDeliveryOutcomesComponent);
