import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';

export const skeletonChartBarComponentName = 'skeleton-chart-bar';

export enum Direction {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export enum Size {
  large = 'large',
  medium = 'medium',
  small = 'small',
  full = 'full',
}

export interface ISkeletonChartBarProps {
  size?: Size;
  length?: string;
  direction?: Direction;
  testId?: string;
}

const SkeletonChartBar: FunctionComponent<ISkeletonChartBarProps> = (
  props,
): ReactElement => {
  const { size, length, direction, testId } = props;

  const skeletonChartBarClass = getClass(skeletonChartBarComponentName, {
    text: [size, direction],
  });
  const skeletonChartBarTestId = getTestId(
    skeletonChartBarComponentName,
    testId,
  );
  const skeletonChartBarStyle = { width: length };

  return (
    <div
      className={skeletonChartBarClass}
      data-testid={skeletonChartBarTestId}
      style={skeletonChartBarStyle}
    />
  );
};

export default SkeletonChartBar;
