import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';

const getUploads = get('uploads');

export const getUploadsForm = flow(getUploads, get('form'));
export const getUploadsFormConfigFieldsChanged = flow(
  getUploads,
  get('formConfigFieldsChanged'),
);
export const getUploadsFormChanged = flow(getUploads, get('formChanged'));
export const dataProviderOptions = flow(getUploads, get('dataProviderOptions'));
export const fileTypeOptions = flow(getUploads, get('fileTypeOptions'));
export const validationErrors = flow(getUploads, get('validationErrors'));
export const errorMessage = flow(getUploads, get('errorMessage'));
export const isPolling = flow(getUploads, get('isPolling'));
export const isLoadingUpdate = flow(getUploads, get('isLoadingUpdate'));
export const datasetLoading = flow(getUploads, get('datasetLoading'));
export const crosswalkSources = flow(getUploads, get('crosswalkSources'));

export const userDimensionDetails = flow(
  getUploads,
  get('userDimensionDetails'),
);

export const crosswalkCombinationDetails = flow(
  getUploads,
  get('crosswalkCombinationDetails'),
);
