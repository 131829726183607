import useSelectedClient from 'domains/clients/useSelectedClient';
import { newDashboard } from 'domains/dashboard/initializers';
import IDashboard from 'domains/dashboard/types';
import useCurrentUser from 'hooks/useCurrentUser';
import useFetch from 'hooks/useFetch';
import useToast from 'hooks/useToast';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Index } from 'routes';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import DashboardEditFeature from '../../edit.feature';

interface IProps {
  testId?: string;
}

const LOCALE_EDIT_TITLE = 'Edit Dashboard';
const LOCALE_CREATE_TITLE = 'New Dashboard';

const DashboardEditContainer = (props: IProps): ReactElement => {
  const [dashboard, setDashboard] = useState<IDashboard>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState<boolean>();

  const { id } = useParams<{ id: string }>();
  const {
    selectedClient: { extID },
  } = useSelectedClient();
  const { doErrorToast } = useToast();
  const { testId } = props;
  const { doFetch } = useFetch<IDashboard>();
  const { userId } = useCurrentUser();
  const { selectedClient } = useSelectedClient();

  useEffect(() => {
    if (dashboard || loading || hasError) return;

    if (id) {
      setLoading(true);
      doFetch({
        endpoint: `/${Index.SEGMENT_DASHBOARDS}/${id}?clientExtID=${extID}`,
        method: FetchMethod.GET,
        onSuccess: (data: IDashboard[]) => {
          setDashboard(data[0]);
          setLoading(false);
        },
        onError: (error: IError) => {
          setHasError(true);
          setLoading(false);
          doErrorToast(error?.message ?? 'There has been an error.');
        },
      });
    } else {
      const blankDashboard = newDashboard(userId, selectedClient?.id);
      setDashboard(blankDashboard);
    }
  }, [
    doErrorToast,
    doFetch,
    loading,
    extID,
    dashboard,
    id,
    userId,
    selectedClient,
    hasError,
  ]);

  return (
    <DashboardEditFeature
      id={id}
      title={id ? LOCALE_EDIT_TITLE : LOCALE_CREATE_TITLE}
      testId={testId}
      dashboard={dashboard}
      setDashboard={setDashboard}
      loading={loading}
    />
  );
};

export default DashboardEditContainer;
