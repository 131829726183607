import IClient from 'domains/clients/types';
import { Dashboard } from 'domains/dashboard/types';
import { IDataset } from 'domains/datasets/types';
import IDimension from 'domains/dimensions/types';
import IMetric from 'domains/metrics/types';
import IPermission from 'domains/permissions/types';
import IReport, { IDomainsDateRanges } from 'domains/reports/types';
import { fetchApi } from 'helpers/fetching';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as rootActions from 'store/actions/root';
import ActionType from 'store/actions/types';
import * as userSelectors from 'store/selectors/user';
import { Action } from 'types/action';
import Domains from 'types/domains';
import FetchMethod from 'types/fetchMethod';
import State from 'types/state';

export const setAttributionReports = (
  payload: IReport[],
): Action<IReport[]> => ({
  type: ActionType.DOMAINS_SET_ATTRIBUTION_REPORTS,
  payload,
});

export const setClients = (payload: IClient[]): Action<IClient[]> => ({
  type: ActionType.DOMAINS_SET_CLIENTS,
  payload,
});

export const setDashboards = (payload: Dashboard[]): Action<Dashboard[]> => ({
  type: ActionType.DOMAINS_SET_DASHBOARDS,
  payload,
});

export const setDatasets = (payload: IDataset[]): Action<IDataset[]> => ({
  type: ActionType.DOMAINS_SET_DATASETS,
  payload,
});

export const setDimensions = (payload: IDimension[]): Action<IDimension[]> => ({
  type: ActionType.DOMAINS_SET_DIMENSIONS,
  payload,
});

export const setMetrics = (payload: IMetric[]): Action<IMetric[]> => ({
  type: ActionType.DOMAINS_SET_METRICS,
  payload,
});

export const setUserDomains = (payload: Domains): Action<Domains> => ({
  type: ActionType.DOMAINS_INIT,
  payload,
});

export const setPermissions = (
  permissions: IPermission[],
): Action<IPermission[]> => ({
  type: ActionType.DOMAINS_SET_PERMISSIONS,
  payload: permissions,
});

export const setDomainsDateRanges = (
  payload: IDomainsDateRanges,
): Action<IDomainsDateRanges> => ({
  type: ActionType.DOMAINS_SET_DATE_RANGES,
  payload,
});

export const fetchDomains =
  (refreshClients = false) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    dispatch(rootActions.setStateNotLoaded());
    const state = getState();
    const selectedClient = userSelectors.getSelectedClient(state);
    const userDomainsResponse = await fetchApi({
      endpoint: 'users/domains',
      method: FetchMethod.POST,
      payload: {
        client: selectedClient,
      },
    });
    const domains = userDomainsResponse?.data[0] as Domains;
    dispatch(setUserDomains(domains));
    if (refreshClients) {
      const clientsResponse = await fetchApi({
        endpoint: 'clients',
        method: FetchMethod.GET,
      });
      const updatedClients = clientsResponse.data as IClient[];
      dispatch(setClients(updatedClients));
    }
    dispatch(rootActions.setStateLoaded());
  };
