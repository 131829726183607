import Table from 'components/Table';
import IEvent from 'domains/events/types';
import { getClass, getId, getTestId } from 'helpers/components';
import React, { FunctionComponent } from 'react';
import IUser from 'types/user';
import getTableEvents from './adapters';
import { EventReport } from './components/EventReport';

export const EventsTableComponentName = 'events-table';

const LOCALE_DATE_HEADER = 'Date';
const LOCALE_USER_HEADER = 'User';
const LOCALE_ACTION_HEADER = 'Action';
const LOCALE_REPORT_HEADER = 'Report';

export type TableEvent = {
  dateTime?: string;
  userName?: string;
  actionType?: string;
  report?: string;
};

export interface IProps {
  testId?: string;
  id?: string;
  events?: IEvent[];
  users?: IUser[];
}

const ReportEventsTable: FunctionComponent<IProps> = (props) => {
  const { id, testId, events, users } = props;

  const tableEvents = getTableEvents(events, users);

  const eventsTableClass = getClass(EventsTableComponentName);
  const eventsTableId = getId(EventsTableComponentName, id);
  const eventsTableTestId = getTestId(EventsTableComponentName, testId);

  return (
    <div
      id={eventsTableId}
      className={eventsTableClass}
      data-testid={eventsTableTestId}
    >
      <Table
        columns={[
          { Header: LOCALE_DATE_HEADER, accessor: 'dateTime' },
          { Header: LOCALE_USER_HEADER, accessor: 'userName' },
          { Header: LOCALE_ACTION_HEADER, accessor: 'actionType' },
          {
            Header: LOCALE_REPORT_HEADER,
            accessor: ({ report }: TableEvent) => (
              <EventReport reportConfig={report as string} />
            ),
          },
        ]}
        goToEditOnRowClick
        noResultsMessage="No results found"
        data={tableEvents}
        pageSize={8}
        pagination
      />
    </div>
  );
};

export default ReportEventsTable;
