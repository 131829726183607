import { numberFormatter } from 'helpers/visualization';
import createChartConfigWithCommonDesign from './commonChartDesignConfig';
import pointTooltipFormatter from './pointTooltipFormatter';

export const waterfallChartConfig = createChartConfigWithCommonDesign({
  chart: {
    type: 'waterfall',
  },
  xAxis: {
    tickLength: 0,
    gridLineWidth: 0,
    startOnTick: false,
    endOnTick: false,
    type: 'category',
    title: {
      text: 'Platform',
    },
  },
  yAxis: {
    title: {
      text: 'Incremental Reach',
    },
    type: 'linear',
  },
  tooltip: {
    shared: false,
    formatter: pointTooltipFormatter,
    metricsList: ['y'],
    tooltipValueFormatters: {
      y: (value: string | number | null | undefined) => numberFormatter(value),
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true, // y value visible for each bar
        inside: true, // y value positioned in the center of each bar (false = top positioned)
      },
      borderWidth: 0,
    },
    waterfall: {
      minPointLength: 3,
    },
  },
});
