import FieldWrapper from 'components/Field/Wrapper';
import { Direction } from 'components/Flex';
import Label from 'components/Label';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import { IMetaData } from 'domains/reports/types';
import { getClass, getTestId } from 'helpers/components';
import React, { ReactElement } from 'react';
import ITextValue from 'types/textValue';
import {
  getArrayFromTextValueArray,
  getTextValueArrayFromArray,
} from '../QueryBuilder/adapters';

const DOM_KEY_GROUP_BY = 'group-by';
const LOCALE_GROUP_BY_LABEL = 'Group By';
const LOCALE_GROUP_BY_PLACEHOLDER = 'Add Dimensions';

export const componentName = 'group-filter';

type Props = {
  dimensions: string[];
  metadata: IMetaData[];
  onChange: (value: string[]) => void;
  series: string[];
  testId?: string;
};

const GroupFilter = (props: Props): ReactElement => {
  const { metadata, series, dimensions, onChange, testId } = props;

  const componentTestId = getTestId(componentName, testId);
  const componentClass = getClass(componentName);
  const componentBodyClass = getClass(componentName, { concat: ['body'] });

  const curatedDimensions = metadata
    .filter((dimension: IMetaData) => dimensions.includes(dimension.id))
    .map((dimension: IMetaData) => ({
      text: dimension.name,
      value: dimension.id,
    }));

  const handleChangeDimensions = (selected: ITextValue[]): void => {
    const dimensionsArray = getArrayFromTextValueArray(selected);
    onChange(dimensionsArray);
  };

  const selectedGroupDimensions = getTextValueArrayFromArray(
    series,
    curatedDimensions,
  );
  return (
    <div data-testid={componentTestId} className={componentClass}>
      <section className={componentBodyClass}>
        <FieldWrapper direction={Direction.row}>
          <Label text={LOCALE_GROUP_BY_LABEL} />
          <MultipleDropdownSelect
            minimumChipsSelected={1}
            ignoreGrouping
            options={curatedDimensions}
            onChange={handleChangeDimensions}
            selected={selectedGroupDimensions}
            testId={`${componentTestId}-${DOM_KEY_GROUP_BY}`}
            placeholder={LOCALE_GROUP_BY_PLACEHOLDER}
          />
        </FieldWrapper>
      </section>
    </div>
  );
};

export default GroupFilter;
