import { IKeyString } from 'domains/reports/types';

export const LOCALE_INTERVAL_LABEL = 'Interval';
export const LOCALE_INTERVAL_LABEL_TOOLTIP =
  'Groups results by the given time period.';

export const LOCALE_DIMENSIONS_LABEL = 'Dimensions';
export const LOCALE_DIMENSIONS_PLACEHOLDER = 'Add a Dimension';
export const LOCALE_DIMENSIONS_LABEL_TOOLTIP =
  'Group metrics and results by other attributes.';

export const LOCALE_DATE_RANGE_LABEL = 'Date Range';
export const LOCALE_DATE_RANGE_LABEL_TOOLTIP =
  'The time period your results will measure.';

export const PERFORMANCE_METRICS_LABEL_LOCALE = 'Performance Metrics';

export const LOCALE_METRICS_LABEL = 'Metrics';
export const LOCALE_METRICS_LABEL_TOOLTIP =
  'Quantitative measurements of attributes that will be displayed in your results.';

export const LOCALE_METRICS_PLACEHOLDER = 'Add a Metric';

export const LOCALE_RULES_LABEL = 'rules';
export const LOCALE_RULES_LABEL_TOOLTIP = 'Criteria to filter your results by.';

export const LOCALE_ORDER_BY_LABEL = 'Order by';
export const LOCALE_ORDER_BY_LABEL_TOOLTIP = 'How your data will be sorted.';

export const LOCALE_EXPERIAN_BREAKOUTS = 'Breakouts';
export const LOCALE_EXPERIAN_BREAKOUT_PLACEHOLDER = 'Add Breakouts';

export const LOCALE_PLACEIQ_LABEL = 'Place IQ Breakout';

export const LOCALE_TARGET = 'Audience';
export const LOCALE_TARGET_TOOLTIP =
  'A group of households that represent a portion of All US TV Households based on viewership habits or demographic criteria.';

export const LOCALE_TARGET_SELECT_TARGET = 'Add Audience';

export const LOCALE_METRIC_TYPE_LABEL = 'Cumulative';
export const LOCALE_METRIC_TYPE_LABEL_TOOLTIP =
  'Flags whether the report will produce metrics which are accumulated over time.';

export const LOCALE_DATASETS_LABEL = 'Datasets';
export const LOCALE_DATASETS_TOOLTIP = 'TV viewership dataset.';

export const LOCALE_DATASETS_SEGMENTS_TOOLTIP: IKeyString = {
  Content:
    'Allows a user to generate metrics based on viewership of all content.',
  Ads: 'Allows a user to generate metrics based on ad viewership only.',
  'Custom Ads':
    'Allows a user to generate a report based on a client specific custom ad schedule.',
  'Custom Exposures':
    'Metrics are based on impressions provided to 605 via a custom exposure segment.',
};
