import { find, flow, get, isEmpty, isEqual, negate } from 'lodash/fp';

const isNotEmpty = negate(isEmpty);

export const getUser = get('user');
export const getEmail = flow(getUser, get('email'));
export const getId = flow(getUser, get('id'));
export const getSelectedClient = flow(getUser, get('selectedClient'));
export const getSelectedClientExtId = flow(getUser, (user) => {
  const selectedClientId = get('selectedClient', user);
  const selectedClient = find(
    (client) => isEqual(get('id', client), selectedClientId),
    get('clients', user),
  );
  return get('extID', selectedClient);
});

export const getPermissions = flow(getUser, get('permissions'));

export const isUserLoaded = flow(getUser, isNotEmpty);
export const isUserLogged = flow(getUser, get('isLogged'));
