import { headerComponentName } from 'components/Header';
import { Placement, Sticker, Type, Style } from 'components/Sticker';
import React, { ReactNode, ReactElement } from 'react';

export const tooltipComponentName = 'tooltip';

export { Style };

interface ITooltipProps {
  children: string | ReactNode;
  content: string | ReactNode;
  placement?: Placement;
  showArrow?: boolean;
  stickToBottom?: boolean;
  style?: Style;
  testId?: string;
  color?: string;
}

export const Tooltip: React.FunctionComponent<ITooltipProps> = (
  props: ITooltipProps,
): ReactElement => {
  const {
    children,
    content,
    placement,
    showArrow,
    stickToBottom,
    style = Style.primary,
    testId,
    color,
  } = props;

  if (!content) return <>{children}</>;

  return (
    <Sticker
      customStickerWrapperClass={headerComponentName}
      style={style}
      type={Type.tooltip}
      content={content}
      placement={placement}
      stickToBottom={stickToBottom}
      showArrow={showArrow}
      testId={testId}
      color={color}
      controlled={false}
      matchTriggerWidth={false}
    >
      {children}
    </Sticker>
  );
};
