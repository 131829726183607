export const DEFAULT_REPORT_NAME = 'New Report';
export const DOM_KEY_AUDIT_BODY = 'audit-body';
export const DOM_KEY_AUDIT_HEADER = 'audit-header';
export const DOM_KEY_REPORT_FORM = 'form';
export const DOM_KEY_NO_DATA = 'no-data';
export const DOM_KEY_QUERY = 'query';
export const DOM_KEY_CONTROL_SEGMENT = 'control-segment';
export const DOM_KEY_RESULTS = 'result';
export const DOM_KEY_ADD_NAME_HEADER = 'add-name-header';
export const DOM_KEY_ADD_NAME_BODY = 'add-name-body';
export const GET_KEY_RUN = 'run';
export const DOM_KEY_NOT_ENOUGH = 'not-enough-hh';
