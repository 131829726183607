import { ChartDataSet, ResultBaseSubStructure } from 'domains/reports/types';
import { useMemo } from 'react';
import {
  IBarChartData,
  IBarChartPoint,
  IBarChartSeries,
} from '../components/BarChart';
import { ITableDataItem } from '../components/TableWrapper';
import { computePointId } from './helpers/computePointId';
import { computePointName } from './helpers/computePointName';
import { mapValuesToMetrics } from 'helpers/charts/mapValuesToMetrics';
import { sortData } from './helpers/sortData';
import {
  DataRowWithTarget,
  IChartAndTableTransformerInputData,
  ITransformerInput,
  ITransformerOutput,
} from './types';
import { get } from 'lodash/fp';

export const useBarChartAndTableTransformer = (
  input: ITransformerInput,
): ITransformerOutput<IBarChartSeries> => {
  const { filters, filteredData, metrics, conversionType } = input;
  const { yAxis, breakout, targets, sortedBy, isSortedDesc } = filters;

  const chartSeries = useMemo(() => {
    const transformData = (
      d: ResultBaseSubStructure,
      i: number,
      target: string | number,
    ): IBarChartPoint => ({
      y: Number(get(yAxis, d)?.VALUE ?? 0),
      // Note: BarChart uses categories for xAxis; categories use 'name' property of the data series so there is no need to use xAxis
      name: computePointName(d),
      id: computePointId(d, target) ?? `id${i}`,
      ...mapValuesToMetrics(metrics, d, conversionType),
    });

    const getSeriesData = (
      dataGroup: ResultBaseSubStructure[],
      target: string | number,
    ): IBarChartData =>
      dataGroup.reduce((prev, current, index) => {
        if (current.name?.VALUE === 'FULL_CAMPAIGN') {
          return prev;
        }

        return [...prev, transformData(current, index, target)];
      }, [] as IBarChartData);

    return targets.map((target) => {
      const dataGroup =
        filteredData[
          target.value as keyof IChartAndTableTransformerInputData
        ] ?? [];

      const sortedData = sortData(
        dataGroup as DataRowWithTarget[],
        sortedBy,
        isSortedDesc,
      );
      return {
        data: getSeriesData(sortedData, target.value),
        color: target.color,
        name: target.value as string,
      };
    });
  }, [
    yAxis,
    conversionType,
    targets,
    filteredData,
    sortedBy,
    metrics,
    isSortedDesc,
  ]);

  const tableData = useMemo(() => {
    const getTargetedTableData = (
      dataGroup: DataRowWithTarget,
      index: number,
    ): ITableDataItem => ({
      name: computePointName(dataGroup),
      id: computePointId(dataGroup, dataGroup.target) ?? `id${index}`,
      target: dataGroup.target,
      color: dataGroup.color,
      breakout,
      ...mapValuesToMetrics(metrics, dataGroup, conversionType),
    });
    const mergedTargets = targets.reduce((prevTargetData, currentTarget) => {
      const dataGroup =
        filteredData[
          currentTarget.value as keyof IChartAndTableTransformerInputData
        ] ?? [];
      const currentTargetDataGroup = dataGroup.map((group) => ({
        ...group,
        target: currentTarget.value,
        color: currentTarget.color,
      }));

      return [...prevTargetData, ...currentTargetDataGroup];
    }, [] as ChartDataSet[]) as DataRowWithTarget[];

    const sortedData = sortData(
      mergedTargets,
      sortedBy,
      isSortedDesc,
    ) as DataRowWithTarget[];
    return sortedData.map(getTargetedTableData);
  }, [
    targets,
    sortedBy,
    isSortedDesc,
    breakout,
    metrics,
    conversionType,
    filteredData,
  ]);

  return { chartSeries, tableData };
};
