import { getSeriesId } from 'helpers/charts/getSeriesId';
import { IMappedChartData } from 'types/multiChartTransformers';
import {
  PerformanceGroupKeys,
  PerformanceGroupNames,
  ResultBase,
  ResultBaseSubStructure,
  SampleGroupNames,
  ISeriesDataItem,
} from 'domains/reports/types';
import { MetricNamesMap } from 'helpers/groupNames/getMetricName';
import { SeriesColumnOptions, SeriesLineOptions } from 'highcharts';
import { IOption } from 'types/textValue';
import { getSeriesDataItemWithPerformanceMetric } from './helpers/getSeriesDateItem';

export enum YAxisOptions {
  'campaign.REACH' = 'reach',
  'campaign.IMPRESSIONS' = 'impressions',
  'campaign.IMPRESSIONS_EQ' = 'impressionsEQ',
  'conversions.EXPOSED_CONVERSIONS' = 'exposedConversions',
  'conversions.ALL_CONVERTED_HOUSEHOLDS' = 'allConvertedHouseHolds',
  'total_sales.EXPOSED_SALES' = 'exposedSales',
  'total_sales.ALL_EXPOSED_SALES' = 'allConvertedSales',
  NONE = 'none',
}

export const YAxisTitles = {
  [YAxisOptions['campaign.REACH']]: MetricNamesMap['campaign.REACH'],
  [YAxisOptions['conversions.EXPOSED_CONVERSIONS']]:
    MetricNamesMap['conversions.EXPOSED_CONVERSIONS'],
  [YAxisOptions['total_sales.EXPOSED_SALES']]:
    MetricNamesMap['total_sales.EXPOSED_SALES'],
  [YAxisOptions['campaign.IMPRESSIONS']]:
    MetricNamesMap['campaign.IMPRESSIONS'],
  [YAxisOptions['campaign.IMPRESSIONS_EQ']]:
    MetricNamesMap['campaign.IMPRESSIONS_EQ'],
  [YAxisOptions['conversions.ALL_CONVERTED_HOUSEHOLDS']]:
    MetricNamesMap['conversions.ALL_CONVERTED_HOUSEHOLDS'],
  [YAxisOptions['total_sales.ALL_EXPOSED_SALES']]:
    MetricNamesMap['total_sales.ALL_EXPOSED_SALES'],

  [YAxisOptions.NONE]: '',
};

export enum AggregationMethods {
  STANDARD = 'standard',
  CUMULATIVE = 'cumulative',
}

export interface IMultiseriesTargetAndAxisFilters {
  targets: IOption[];
  rightYAxis: YAxisOptions;
  leftYAxis: YAxisOptions;
  aggregation: AggregationMethods;
}

export interface IChartTargetItem {
  [AggregationMethods.CUMULATIVE]: {
    [key in YAxisOptions]: ISeriesDataItem[];
  };
  [AggregationMethods.STANDARD]: {
    [key in YAxisOptions]: ISeriesDataItem[];
  };
}

/**
 * Maps Attribution Report raw data from AAPI to a data structure used in campaign delivery & outcomes chart.
 * Groups data by targets. Each target has cumulative and standard values.
 * Creates categories (days/weeks) that serve as a shared xAxis for all series.
 */

export const mapDataToChartConfig = (
  {
    exposed_all = {
      timeseries: { target: [], genpop: [] },
      timeseries_cumulative: { target: [], genpop: [] },
    },
    exposed_converted = {
      timeseries: { target: [], genpop: [] },
      timeseries_cumulative: { target: [], genpop: [] },
    },
    total_converted = {
      timeseries: { target: [], genpop: [] },
      timeseries_cumulative: { target: [], genpop: [] },
    },
  }: ResultBase,
  performanceMetricGroups: PerformanceGroupNames[] = ['converters'],
): IMappedChartData<IChartTargetItem> => {
  const targetKeys = Object.keys(exposed_all.timeseries) as SampleGroupNames[];

  const hasSales = performanceMetricGroups.includes(
    PerformanceGroupKeys.total_sales,
  );

  const dataByTarget = targetKeys.reduce(
    (acc, target) => {
      return {
        ...acc,
        [target]: {
          [AggregationMethods.STANDARD]: {
            reach: getSeriesDataItemWithPerformanceMetric(
              'REACH',
              exposed_all.timeseries[target] as ResultBaseSubStructure[],
              'campaign',
            ),
            impressions: getSeriesDataItemWithPerformanceMetric(
              'IMPRESSIONS',
              exposed_all.timeseries[target] as ResultBaseSubStructure[],
              'campaign',
            ),
            exposedConversions: getSeriesDataItemWithPerformanceMetric(
              'DAILY_CONVERSIONS',
              exposed_converted.timeseries[target] as ResultBaseSubStructure[],
              'conversions',
            ),
            allConvertedHouseHolds: getSeriesDataItemWithPerformanceMetric(
              'DAILY_CONVERSIONS',
              total_converted.timeseries[target] as ResultBaseSubStructure[],
              'conversions',
            ),
            ...(hasSales && {
              exposedSales: getSeriesDataItemWithPerformanceMetric(
                'TOTAL_SALES',
                exposed_converted.timeseries[
                  target
                ] as ResultBaseSubStructure[],
                'total_sales',
              ),
              allConvertedSales: getSeriesDataItemWithPerformanceMetric(
                'TOTAL_SALES',
                total_converted.timeseries[target] as ResultBaseSubStructure[],
                'total_sales',
              ),
            }),
            none: [],
          },
          [AggregationMethods.CUMULATIVE]: {
            reach: getSeriesDataItemWithPerformanceMetric(
              'REACH',
              exposed_all.timeseries_cumulative[
                target
              ] as ResultBaseSubStructure[],
              'campaign',
            ),
            impressions: getSeriesDataItemWithPerformanceMetric(
              'IMPRESSIONS',
              exposed_all.timeseries_cumulative[
                target
              ] as ResultBaseSubStructure[],
              'campaign',
            ),
            exposedConversions: getSeriesDataItemWithPerformanceMetric(
              'DAILY_CONVERSIONS',
              exposed_converted.timeseries_cumulative[
                target
              ] as ResultBaseSubStructure[],
              'conversions',
            ),
            allConvertedHouseHolds: getSeriesDataItemWithPerformanceMetric(
              'DAILY_CONVERSIONS',
              total_converted.timeseries_cumulative[
                target
              ] as ResultBaseSubStructure[],
              'conversions',
            ),
            ...(hasSales && {
              exposedSales: getSeriesDataItemWithPerformanceMetric(
                'TOTAL_SALES',
                exposed_converted.timeseries_cumulative[
                  target
                ] as ResultBaseSubStructure[],
                'total_sales',
              ),
              allConvertedSales: getSeriesDataItemWithPerformanceMetric(
                'TOTAL_SALES',
                total_converted.timeseries_cumulative[
                  target
                ] as ResultBaseSubStructure[],
                'total_sales',
              ),
            }),
            none: [],
          },
        },
      };
    },
    {} as { [key in SampleGroupNames]: IChartTargetItem },
  );

  return dataByTarget;
};

const isSales = (selection: string): boolean =>
  selection.includes(YAxisOptions['total_sales.EXPOSED_SALES']) ||
  selection.includes(YAxisOptions['total_sales.ALL_EXPOSED_SALES']);

/**
 * Gets chart config depending on filters - right and left yAxis, aggregation method and chosen targets. It creates new series and renames yAxis
 */

export const getChartConfig = (
  chartData: IMappedChartData<IChartTargetItem>,
  filters: IMultiseriesTargetAndAxisFilters,
): Highcharts.Options => {
  const { targets, rightYAxis, leftYAxis, aggregation } = filters;
  const leftAxisUsingEQ = leftYAxis === YAxisOptions['campaign.IMPRESSIONS_EQ'];
  const rightAxisUsingEQ =
    rightYAxis === YAxisOptions['campaign.IMPRESSIONS_EQ'];
  const eqAdjustedLeftYAxis =
    leftYAxis === YAxisOptions['campaign.IMPRESSIONS_EQ']
      ? YAxisOptions['campaign.IMPRESSIONS']
      : leftYAxis;
  const eqAdjustedRightYAxis =
    rightYAxis === YAxisOptions['campaign.IMPRESSIONS_EQ']
      ? YAxisOptions['campaign.IMPRESSIONS']
      : rightYAxis;
  const columnSeries = getColumnSeries(
    chartData,
    targets,
    eqAdjustedLeftYAxis,
    aggregation,
    leftAxisUsingEQ,
  );
  const lineSeries = getLineSeries(
    chartData,
    targets,
    eqAdjustedRightYAxis,
    aggregation,
    rightAxisUsingEQ,
  );

  const ONE_DAY = 1000 * 60 * 60 * 24;

  return {
    chart: {
      height: 350,
      spacingTop: 20,
      spacingLeft: 0,
      spacingRight: 1,
    },
    yAxis: [
      {
        title: {
          text: YAxisTitles[leftYAxis],
        },
        labels: {
          formatter: function format() {
            return (
              (isSales(leftYAxis) ? '$' : '') +
              this.axis.defaultLabelFormatter.call(this)
            );
          },
        },
      },
      {
        title: {
          text: YAxisTitles[rightYAxis],
        },
        labels: {
          formatter: function format() {
            return (
              (isSales(rightYAxis) ? '$' : '') +
              this.axis.defaultLabelFormatter.call(this)
            );
          },
        },
        opposite: true,
      },
    ],
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        millisecond: '%m-%d-%Y',
        second: '%m-%d-%Y',
        minute: '%m-%d-%Y',
        hour: '%m-%d-%Y',
        day: '%m-%d-%Y',
        week: '%m-%d-%Y',
        month: '%m-%d-%Y',
        year: '%m-%d-%Y',
      },
      minTickInterval: ONE_DAY,
      tickLength: 0,
    },
    tooltip: {
      borderColor: targets.length > 1 ? '#a7afb2' : undefined,
      outside: false,
    },
    series: [...columnSeries, ...lineSeries],
  };
};

const getLineSeries = (
  data: { [key in SampleGroupNames]: IChartTargetItem },
  targets: IOption[],
  rightYAxis: YAxisOptions,
  aggregation: AggregationMethods,
  useEqMetric?: boolean,
): Array<SeriesLineOptions> => {
  return [
    ...targets.map(({ value, color, secondaryColor }): SeriesLineOptions => {
      const targetData =
        data[value as SampleGroupNames]?.[aggregation]?.[rightYAxis];
      const name = useEqMetric
        ? YAxisTitles['impressionsEQ']
        : YAxisTitles[rightYAxis];
      return {
        name,
        data: targetData,
        type: 'line',
        yAxis: 1,
        dashStyle: 'Solid',
        color:
          value === SampleGroupNames.target ? color : secondaryColor ?? color,
        zIndex: 1,
        id: getSeriesId(
          value as SampleGroupNames,
          aggregation === AggregationMethods.CUMULATIVE,
          'line',
        ),
        marker: {
          enabled: targetData?.length === 1,
          symbol: 'circle',
        },
      };
    }),
  ];
};

const getColumnSeries = (
  data: { [key in SampleGroupNames]: IChartTargetItem },
  targets: IOption[],
  leftYAxis: YAxisOptions,
  aggregation: AggregationMethods,
  useEqMetric?: boolean,
): Array<SeriesColumnOptions> => {
  return [
    ...targets.flatMap(
      ({ value, color, secondaryColor, id }): SeriesColumnOptions[] => {
        const name = useEqMetric
          ? YAxisTitles['impressionsEQ']
          : YAxisTitles[leftYAxis];
        return [
          {
            name,
            data: data[value as SampleGroupNames]?.[aggregation]?.[leftYAxis],
            type: 'column',
            yAxis: 0,
            minPointLength: 2,
            color:
              value === SampleGroupNames.target
                ? color
                : secondaryColor ?? color,
            // this condition is needed to trigger highcharts to refresh column series position on charts
            index: targets.length > 1 ? id : targets.length + 1,
            id: getSeriesId(
              value as SampleGroupNames,
              aggregation === AggregationMethods.CUMULATIVE,
              'column',
            ),
          },
        ];
      },
    ),
  ];
};
