import {
  Dashboard,
  IDashboardReport,
  PanelLayout,
} from 'domains/dashboard/types';
import DashboardPanel from 'features/dashboards/components/Panel';
import { getTestId, getClass } from 'helpers/components';
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
  ReactElement,
  useCallback,
} from 'react';
import GridLayoutModule, { WidthProvider, Layout } from 'react-grid-layout';
import ObserverNodeSize from './helpers/observerNodeSize';

export const panelsComponentName = 'dashboard-panels';

const PANEL_DOM_KEY = 'react-grid-item';
const DEFAULT_NUMBER_OF_COLUMNS = 12;
const DEFAULT_ROW_HEIGHT = 30;

const GridLayoutWithWidth = WidthProvider(
  GridLayoutModule,
) as unknown as FunctionComponent<
  GridLayoutModule.ReactGridLayoutProps &
    GridLayoutModule.WidthProviderProps & { children: ReactElement | ReactNode }
>;

export type Panel = {
  content: ReactNode;
  key: string;
};

type Size = {
  width: string | undefined;
  height: string | undefined;
};

interface IProps {
  dashboard: Dashboard;
  draggableHandle?: string;
  testId?: string;
  rowHeight?: number;
  cols?: number;
  defaultLayout?: PanelLayout[];
  onLayoutChange?: (layout: PanelLayout[]) => void;
  canRemoveReport: boolean;
  canUpdateReport: boolean;
  canUpdateDashboard?: boolean;
  onReportRemove: (report: IDashboardReport) => void;
  onReportUpdate: (report: IDashboardReport) => void;
}

const Panels: FunctionComponent<IProps> = (props): ReactElement => {
  const {
    draggableHandle,
    dashboard,
    onLayoutChange,
    rowHeight,
    cols,
    defaultLayout,
    canUpdateReport,
    canRemoveReport,
    canUpdateDashboard,
    onReportRemove,
    onReportUpdate,
    testId,
  } = props;

  const [panelNodesObserversSize, setPanelNodesObserversSize] = useState(
    [] as ObserverNodeSize[],
  );
  const [panelsSize, setPanelsSize] = useState([] as Size[]);

  const getPanelBodyHeight = (index?: number): string | undefined => {
    if (index && panelsSize.length > 0 && panelsSize[index]?.height) {
      return panelsSize[index].height;
    }

    return undefined;
  };

  const getPanelsNodesObservers = (
    panelsNodes: NodeListOf<Element>,
  ): ObserverNodeSize[] => {
    const observersNodesSize = [] as ObserverNodeSize[];

    for (let index = 0; index < panelsNodes.length; index++) {
      const panelNode = panelsNodes[index];
      const observerNodeSize = new ObserverNodeSize(panelNode);
      observersNodesSize.push(observerNodeSize);
    }

    return observersNodesSize;
  };

  const connectNodesObservers = useCallback((): void => {
    panelNodesObserversSize.forEach((observer: ObserverNodeSize): void => {
      observer.connect();
    });
  }, [panelNodesObserversSize]);

  const disconnectNodesObservers = useCallback((): void => {
    panelNodesObserversSize.forEach((observer: ObserverNodeSize): void => {
      observer.disconnect();
    });
  }, [panelNodesObserversSize]);

  const setObservePanelsSizes = useCallback((): void => {
    const sizes = [] as Size[];

    if (panelNodesObserversSize && panelNodesObserversSize.length > 0) {
      for (let index = 0; index < panelNodesObserversSize.length; index++) {
        const panelNodeObserverSize = panelNodesObserversSize[index];
        sizes.push({
          width: panelNodeObserverSize.width,
          height: panelNodeObserverSize.height,
        });
      }
    }

    setPanelsSize(sizes);
  }, [panelNodesObserversSize]);

  useEffect(() => {
    const panelsNodes = document.querySelectorAll(`.${PANEL_DOM_KEY}`);
    const panelNodesObservers = panelsNodes
      ? getPanelsNodesObservers(panelsNodes)
      : [];

    setPanelNodesObserversSize(panelNodesObservers);
  }, [setPanelNodesObserversSize]);

  useEffect(() => {
    setObservePanelsSizes();
  }, [setObservePanelsSizes]);

  useEffect(() => {
    connectNodesObservers();
  }, [connectNodesObservers]);

  useEffect(
    () => (): void => {
      disconnectNodesObservers();
    },
    [disconnectNodesObservers],
  );

  const handleLayoutChange = (l: Layout[]): void => {
    if (onLayoutChange) onLayoutChange(l);
    setObservePanelsSizes();
  };

  const panelsTestId = getTestId(panelsComponentName, testId);
  const panelsClass = getClass(panelsComponentName);

  return (
    <div data-testid={panelsTestId} className={panelsClass}>
      <GridLayoutWithWidth
        cols={cols ?? DEFAULT_NUMBER_OF_COLUMNS}
        rowHeight={rowHeight ?? DEFAULT_ROW_HEIGHT}
        draggableHandle={draggableHandle}
        containerPadding={[0, 0]}
        layout={defaultLayout}
        onLayoutChange={handleLayoutChange}
        isDraggable={canUpdateDashboard}
        isResizable={canUpdateDashboard}
      >
        {dashboard.reports.map((dashboardReport, index) => (
          <div key={dashboardReport.id}>
            <DashboardPanel
              dashboardReport={dashboardReport}
              testId={`${panelsTestId}-${dashboardReport.id}`}
              canUpdateReport={canUpdateReport}
              canRemoveReport={canRemoveReport}
              canUpdateDashboard={canUpdateDashboard}
              onReportRemove={onReportRemove}
              onReportUpdate={onReportUpdate}
              pageSize={dashboardReport.limit}
              bodyHeight={getPanelBodyHeight(index)}
            />
          </div>
        ))}
      </GridLayoutWithWidth>
    </div>
  );
};

export default Panels;
