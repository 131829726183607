import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Button';
import {
  Color as IconColor,
  Type as IconType,
  Size as IconSize,
} from 'components/Icon';
import { getClass, getId, getTestId } from 'helpers/components';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import React, {
  FunctionComponent,
  useState,
  ReactElement,
  useMemo,
  useRef,
  MutableRefObject,
  ReactNode,
} from 'react';

export const componentName = 'kebab-drawer';

const DOM_KEY_CHILDREN = 'children';
const DOM_KEY_MENU_WRAPPER = 'menu-wrapper';
const DOM_KEY_WRAPPER = 'wrapper';

const STATE_OPEN = 'open';

export interface IProps {
  id?: string;
  testId?: string;
  children?: ReactNode;
}

const KebabDrawer: FunctionComponent<IProps> = (
  props: IProps,
): ReactElement => {
  const { id, testId, children } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const root = useRef() as MutableRefObject<HTMLDivElement>;

  const close = (): void => setIsOpen(false);

  const open = (): void => setIsOpen(true);

  useOnOutsideClick(root, () => {
    close();
  });

  const rootId = useMemo(() => getId(componentName, id), [id]);
  const rootTestId = useMemo(() => getTestId(componentName, testId), [testId]);

  const rootClass = useMemo(
    () =>
      getClass(componentName, {
        boolean: [
          {
            state: isOpen,
            class: STATE_OPEN,
            preventCollisions: true,
          },
        ],
      }),
    [isOpen],
  );

  const wrapperClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_WRAPPER] }),
    [],
  );
  const menuWrapperClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_MENU_WRAPPER] }),
    [],
  );
  const childrenClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_CHILDREN] }),
    [],
  );

  return (
    <div className={rootClass} data-testid={rootTestId} id={rootId} ref={root}>
      <div className={wrapperClass}>
        <Button
          kind={ButtonKind.icon}
          iconType={IconType.moreVertical}
          iconColor={IconColor.primary}
          onClick={open}
        />
        <div className={menuWrapperClass}>
          <div className={childrenClass}>{children}</div>
          <Button
            kind={ButtonKind.icon}
            iconType={IconType.close}
            iconColor={IconColor.primary}
            iconSize={IconSize.small}
            size={ButtonSize.small}
            onClick={close}
          />
        </div>
      </div>
    </div>
  );
};

export default KebabDrawer;
