import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import { LOCALE_PARTIAL_DATA } from 'domains/reports/locales/general';
import { ExposureConversionSetNames } from 'domains/reports/types';
import { getClass } from 'helpers/components';
import React, { useMemo } from 'react';
import { checkIfHasOmission } from './helpers/checkIfHasOmission';

const sectionOmissionAlertComponentName = 'section-omission-alert';
const sectionOmissionAlertComponentClass = getClass(
  sectionOmissionAlertComponentName,
);

interface Props {
  omissionMessages: ExposureConversionSetNames[];
  rowsUsedInSection: ExposureConversionSetNames[];
}

export const SectionOmissionAlert: React.FC<Props> = ({
  omissionMessages,
  rowsUsedInSection,
}) => {
  const hasOmission = useMemo(
    () => checkIfHasOmission(omissionMessages, rowsUsedInSection),
    [omissionMessages, rowsUsedInSection],
  );

  if (!hasOmission) return null;

  return (
    <div className={sectionOmissionAlertComponentClass}>
      <InlineAlert mode={AlertTypes.omission} message={LOCALE_PARTIAL_DATA} />
    </div>
  );
};
