import HighlightCard from 'components/HighlightCard';
import { Color as IconColor, Type as IconType } from 'components/Icon';
import React from 'react';
import { generateTotalCampaignCardContent } from 'helpers/visualization';
import { IExecutiveSummaryComponentProps } from './types';

export const totalCampaignComponentName = 'total-campaign';

export const TotalCampaign: React.FC<IExecutiveSummaryComponentProps> = ({
  data: { exposed_all },
  selectedTarget: { value: target, color, secondaryColor },
  notSelectedTarget,
  conversionsMetricName,
}) => {
  const notSelectedTargetName = notSelectedTarget?.text ?? '';
  const {
    convertersContent,
    convertersSubContent,
    convertersFooter,
    convertersCostContent,
    convertersCostFooter,
    conversionsContent,
    conversionsSubContent,
    conversionsFooter,
    totalSalesContent,
    totalSalesSubContent,
    totalSalesFooter,
    salesPerTransactionContent,
    salesPerTransactionSubContent,
    salesPerTransactionFooter,
  } = generateTotalCampaignCardContent(
    exposed_all,
    target as string,
    notSelectedTargetName,
  );

  const cardTheme = React.useMemo(
    () => ({
      iconColor: IconColor.light,
      backgroundColor: color,
      borderColor: secondaryColor ?? color,
    }),
    [color, secondaryColor],
  );

  return (
    <>
      <HighlightCard
        iconType={IconType.user}
        theme={cardTheme}
        title="Incremental Converters"
        content={convertersContent}
        subcontent={convertersSubContent}
        testId={`${totalCampaignComponentName}-converters`}
        footer={convertersFooter}
      />
      {!convertersCostContent?.includes('N/A') && (
        <HighlightCard
          iconType={IconType.door}
          theme={cardTheme}
          title="Cost per Incremental Converters"
          content={convertersCostContent}
          testId={`${totalCampaignComponentName}-converters-ad-cost`}
          footer={convertersCostFooter}
        />
      )}
      {!conversionsContent.includes('N/A') && (
        <HighlightCard
          iconType={IconType.door}
          theme={cardTheme}
          title={`Incremental ${conversionsMetricName}`}
          content={conversionsContent}
          subcontent={conversionsSubContent}
          testId={`${totalCampaignComponentName}-conversions`}
          footer={conversionsFooter}
        />
      )}
      {!totalSalesContent.includes('N/A') && (
        <HighlightCard
          iconType={IconType.houseMoney}
          theme={cardTheme}
          title="Incremental Total Sales"
          content={totalSalesContent}
          subcontent={totalSalesSubContent}
          testId={`${totalCampaignComponentName}-total-sales`}
          footer={totalSalesFooter}
        />
      )}
      {!salesPerTransactionContent.includes('N/A') && (
        <HighlightCard
          iconType={IconType.eye}
          theme={cardTheme}
          title="Incremental Sales per Transaction"
          content={salesPerTransactionContent}
          subcontent={salesPerTransactionSubContent}
          testId={`${totalCampaignComponentName}-sales-per-transaction`}
          footer={salesPerTransactionFooter}
        />
      )}
    </>
  );
};
