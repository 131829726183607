import { PerformanceGroupKeys } from 'domains/reports/types';
import {
  IAttributionReportResult,
  savingStatus,
} from 'types/attributionReportsResult';
import { set } from 'lodash/fp';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';

export const initialState: IAttributionReportResult = {
  performanceMetricGroups: [PerformanceGroupKeys.converters],
  showLroiInVisualization: true,
  omissionMessages: [],
  segmentsSavingStatus: savingStatus.none,
  errorMessage: '',
  downloadQuery: false,
  fileUpload: false,
  resultData: undefined,
};

const setResultData = set('resultData.breakouts');
const setDemographicData = set('resultData.demographicStats');
const setSpecializedInsights = set('resultData.specializedInsights.chartData');
const setSpecializedInsightsTarget = set(
  'resultData.specializedInsights.selectedTarget',
);
const setSpecializedInsightsTargetOptions = set(
  'resultData.specializedInsights.targetOptions',
);
const setMiscResultData = set('resultData.miscResultData');
const setConversionType = set('conversionType');
const setShowLroiInVisualization = set('showLroiInVisualization');
const setDownloadQuery = set('downloadQuery');
const setFileUpload = set('fileUpload');
const setVendorSourceName = set('vendorSourceName');
const setOmissionMessages = set('omissionMessages');
const setSegmentsSavingStatus = set('segmentsSavingStatus');
const setBreakoutList = set('breakoutList');
const setDemographicBreakoutList = set('demographicBreakoutList');
const setErrorMessage = set('errorMessage');
const setMetrics = set('metrics');

export default (
  attributionReportResult: IAttributionReportResult = initialState,
  action: Action<IAttributionReportResult>,
): IAttributionReportResult => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.RESULT_ATTRIBUTION_SEGMENTS_SAVE_STATUS_UPDATED:
      return setSegmentsSavingStatus(payload, attributionReportResult);
    case ActionType.RESULT_ATTRIBUTION_REPORT_RETRIEVED:
      return setResultData(payload, attributionReportResult);
    case ActionType.RESULT_ATTRIBUTION_REPORT_DEMOGRAPHICS_RETRIEVED:
      return setDemographicData(payload, attributionReportResult);
    case ActionType.RESULT_ATTRIBUTION_REPORT_MISC_RETRIEVED:
      return setMiscResultData(payload, attributionReportResult);
    case ActionType.RESULT_ATTRIBUTION_REPORT_SET_CONVERSION_TYPE:
      return setConversionType(payload, attributionReportResult);
    case ActionType.RESULT_RESET_ATTRIBUTION_REPORT_RESULT:
      return initialState;
    case ActionType.RESULT_SET_SHOW_LROI:
      return setShowLroiInVisualization(payload, attributionReportResult);
    case ActionType.RESULT_SET_DOWNLOAD_QUERY:
      return setDownloadQuery(payload, attributionReportResult);
    case ActionType.RESULT_SET_FILE_UPLOAD:
      return setFileUpload(payload, attributionReportResult);
    case ActionType.RESULT_ATTRIBUTION_SET_VENDOR_SOURCE_NAME:
      return setVendorSourceName(payload, attributionReportResult);
    case ActionType.RESULT_SET_ATTRIBUTION_OMISSION_MESSAGES:
      return setOmissionMessages(payload, attributionReportResult);
    case ActionType.RESULT_SET_ATTRIBUTION_REPORT_METRICS:
      return setMetrics(payload, attributionReportResult);
    case ActionType.RESULT_SET_BREAKOUT_LIST:
      return setBreakoutList(payload, attributionReportResult);
    case ActionType.RESULT_SET_DEMOGRAPHIC_BREAKOUT_LIST:
      return setDemographicBreakoutList(payload, attributionReportResult);
    case ActionType.RESULT_SET_ATTRIBUTION_REPORT_ERROR_MESSAGE:
      return setErrorMessage(payload, attributionReportResult);
    case ActionType.RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_CHART:
      return setSpecializedInsights(payload, attributionReportResult);
    case ActionType.RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_TARGET_OPTIONS:
      return setSpecializedInsightsTargetOptions(
        payload,
        attributionReportResult,
      );
    case ActionType.RESULT_ATTRIBUTION_SET_SPECIALIZED_INSIGHTS_TARGET:
      return setSpecializedInsightsTarget(payload, attributionReportResult);
    default:
      return attributionReportResult;
  }
};
