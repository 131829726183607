import Button from 'components/Button';
import Flex, { Horizontal, Vertical } from 'components/Flex';
import Icon, { Color as IconColor, Type as IconType } from 'components/Icon';
import { SectionOmissionAlert } from 'components/SectionOmissionAlert';
import IReport, {
  ExposureConversionSetNames,
  ResultEnhancedDemographicGroups,
} from 'domains/reports/types';
import { IResultSelectionFilters } from 'domains/resultSelections/types';
import { getClass } from 'helpers/components';
import { getPersistedTargets } from 'helpers/resultSelection/getPersistedTargets';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as resultActions from 'store/actions/reportResult';
import * as resultSelectionsActions from 'store/actions/resultSelections';
import * as resultSelectors from 'store/selectors/reportResult';
import {
  savingStatus,
  SegmentsSavingStatus,
} from 'types/attributionReportsResult';
import State from 'types/state';
import { IOption } from 'types/textValue';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import { ChartHelper } from '../components/ChartHelper';
import DumbbellChart from './components/DumbbellChart';
import PointsController from './components/PointsController/PointsController';
import SaveSegmentsFailModal from './components/SaveSegmentsFailModal';
import SaveSegmentsSuccessModal from './components/SaveSegmentsSuccessModal';
import { getRowsUsedInSectionDemographic } from './helpers/getRowsUsedInSectionDemographic';
import { ActionTypes, reducer } from './reducer';

interface MappedProps {
  saveSegmentsStatus: SegmentsSavingStatus;
  saveSegmentsFilenames: string[];
}

interface MappedActions {
  closeSaveSegmentsSuccessModal: () => void;
  setSegmentsSavingStatus: (arg0: SegmentsSavingStatus) => void;
  handleAttributionSegmentsSave: () => void;
  updateResultSelectionFilters: (
    payload: Partial<IResultSelectionFilters>,
  ) => Promise<void>;
}

interface IAudienceDemographicsByIndexProps extends MappedProps, MappedActions {
  chartData: ResultEnhancedDemographicGroups;
  targets: IOption[];
  demographicBreakoutList: Array<{ id: string; name: string }>;
  segments: IOption[];
  omissionMessages: ExposureConversionSetNames[];
  sectionFiltersSelection: IResultSelectionFilters['demographicAttributes'];
  lastValidReport: IReport | null;
}

const chartFiltersComponentName = 'chart-filters-wrapper';
const chartFiltersClass = getClass(chartFiltersComponentName);
const containerClass = getClass(chartFiltersComponentName, {
  concat: ['container'],
});
const headerClass = getClass(chartFiltersComponentName, {
  concat: ['header'],
});
const filtersClass = getClass(chartFiltersComponentName, {
  concat: ['filters'],
});
const labelClass = getClass(chartFiltersComponentName, {
  concat: ['label'],
});

const multiSelectControlClass = getClass(chartFiltersComponentName, {
  concat: ['multiselect-dropdown-wrapper'],
});
const headerComponentName = 'audience-demographic-header';
const headerComponentClassName = getClass(headerComponentName);
const h2ClassName = getClass(headerComponentName, { concat: ['h2'] });
const saveButtonClassName = getClass(headerComponentName, {
  concat: ['save-button'],
});

const rowsUsedInSection: ExposureConversionSetNames[] =
  getRowsUsedInSectionDemographic();

export const getPersistedSegments = (
  segmentsList: IOption[],
  persistedSegments?: Array<IOption['value']>,
): IOption[] =>
  persistedSegments
    ? persistedSegments.reduce((prev, curr) => {
        const segment = segmentsList.find((s) => s.value === curr);
        return segment ? [...prev, segment] : prev;
      }, [] as IOption[])
    : [segmentsList[1], segmentsList[2]];

export const sectionName = 'Demographic Attributes';

const AudienceDemographicsByIndex: React.FC<
  IAudienceDemographicsByIndexProps
> = ({
  chartData,
  targets,
  saveSegmentsStatus,
  saveSegmentsFilenames,
  demographicBreakoutList,
  segments,
  omissionMessages,
  sectionFiltersSelection,
  lastValidReport,
  updateResultSelectionFilters,
  closeSaveSegmentsSuccessModal,
  setSegmentsSavingStatus,
  handleAttributionSegmentsSave,
}) => {
  const didMount = useRef(false);

  const [showOverlay, setShowOverlay] = useState(false);

  const demographics = useMemo(
    () =>
      demographicBreakoutList?.map(({ id, name }) => ({
        value: id,
        text: name,
      })) ?? [],
    [demographicBreakoutList],
  );

  const [filters, dispatch] = useReducer(reducer, {
    demographic:
      sectionFiltersSelection?.demographic ?? demographics[0]?.value ?? '',
    targets: getPersistedTargets(targets, sectionFiltersSelection?.targets),
    segments: getPersistedSegments(segments, sectionFiltersSelection?.segments),
    multiTarget: !!sectionFiltersSelection?.multiTarget,
    segmentsList: segments,
    targetsList: targets,
    lastValidReport,
  });

  const handleSaveBehavioralSegmentsClick = (): void => {
    handleAttributionSegmentsSave();
  };

  useEffect(() => {
    if (didMount.current) {
      const filtersTargets = filters.targets.map((t) => t.value);
      const filtersSegments = filters.segments.map((s) => s.value);

      updateResultSelectionFilters({
        demographicAttributes: {
          demographic: filters.demographic,
          targets: filtersTargets as string[],
          segments: filtersSegments as string[],
          multiTarget: filters.multiTarget,
        },
      });
    } else {
      didMount.current = true;
    }
  }, [updateResultSelectionFilters, filters]);

  const demographicOptions = useMemo(
    () =>
      demographics.map(({ value, text }, i) => ({
        value,
        text,
        id: i,
        color: '',
      })),
    [demographics],
  );

  return (
    <>
      <SaveSegmentsSuccessModal
        saveSegmentsStatus={saveSegmentsStatus}
        onClose={closeSaveSegmentsSuccessModal}
        filenames={saveSegmentsFilenames}
      />
      <SaveSegmentsFailModal
        saveSegmentsStatus={saveSegmentsStatus}
        setSegmentsSavingStatus={setSegmentsSavingStatus}
      />

      <section>
        <Flex className="mb-2 mt-4" horizontal={Horizontal.between}>
          <div className={headerComponentClassName}>
            <h2 className={h2ClassName}>{sectionName}</h2>
            <SectionOmissionAlert
              omissionMessages={omissionMessages}
              rowsUsedInSection={rowsUsedInSection}
            />
          </div>
          <Button
            className={saveButtonClassName}
            onClick={handleSaveBehavioralSegmentsClick}
            disabled={saveSegmentsStatus === savingStatus.saving}
          >
            <Icon color={IconColor.primary} type={IconType.save} />
            <span>
              {saveSegmentsStatus === savingStatus.saving
                ? 'Saving...'
                : 'Save Behavioral Segments'}
            </span>
          </Button>
        </Flex>

        <div className={chartFiltersClass}>
          <Flex vertical={Vertical.between} className={containerClass}>
            <Flex vertical={Vertical.between}>
              <header>
                <h3 className={headerClass}>Audience Demographics By Index</h3>
              </header>
              <ChartHelper
                onMouseEnter={() => setShowOverlay(true)}
                onMouseLeave={() => setShowOverlay(false)}
              />
            </Flex>
          </Flex>
          <DumbbellChart
            data={chartData}
            filters={filters}
            yAxisTitle={
              demographics.find((d) => d.value === filters.demographic)?.text ??
              'Y Axis'
            }
            showOverlay={showOverlay}
          />
          <div className={filtersClass}>
            <div className={multiSelectControlClass}>
              <label className={labelClass}>Y Axis</label>
              <MultiSelectDropdown
                options={demographicOptions}
                multiSelect={false}
                showLegend={false}
                onChange={([demographic]) =>
                  dispatch({
                    type: ActionTypes.SET_DEMOGRAPHIC,
                    payload: demographics.find(
                      (d) => d.value === demographic.value,
                    ),
                  })
                }
                selected={demographicOptions.filter(
                  (d) => d.value === filters.demographic,
                )}
                testId="demographic-control"
              />
            </div>

            <div className="mt-2">
              <label className={labelClass}>Points</label>
              <PointsController
                segments={segments}
                targets={targets}
                filters={filters}
                dispatch={dispatch}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state: State): MappedProps => ({
  saveSegmentsStatus: resultSelectors.getSegmentsSavingStatus(state),
  saveSegmentsFilenames: resultSelectors.getSavedSegmentsFilenames(state),
});

const mapDispatchToProps = {
  closeSaveSegmentsSuccessModal: resultActions.closeSaveSegmentsSuccessModal,
  setSegmentsSavingStatus: resultActions.setSegmentsSavingStatus,
  handleAttributionSegmentsSave: resultActions.handleAttributionSegmentsSave,
  updateResultSelectionFilters:
    resultSelectionsActions.updateResultSelectionFilters,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceDemographicsByIndex);
