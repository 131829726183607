import match from 'autosuggest-highlight/match';
import Button, { Kind } from 'components/Button';
import DatePicker from 'components/DatePicker';
import { Anchor } from 'components/DatePicker/interface';
import Dropdown from 'components/Dropdown';
import DropdownSearch, {
  DropdownSearchQueryProvider,
} from 'components/DropdownSearch';
import DurationInput from 'components/DurationSelector/input';
import Flex, { Horizontal } from 'components/Flex';
import { Color as iconColors, Type } from 'components/Icon';
import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import Input, { Type as InputType } from 'components/Input';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import MultipleTextSelect from 'components/MultipleTextSelect';
import CompoundRulesBuilderItem from 'components/RulesBuilder/components/CompoundRulesBuilderItem';
import { IDataset, IDatasetDetails } from 'domains/datasets/types';
import IDimension from 'domains/dimensions/types';
import useDimensions from 'domains/dimensions/useDimensions';
import IMessage from 'domains/messageList/types';
import { isAttributionReport } from 'domains/reports/adapters/general';
import IReport, { IAttributionReport } from 'domains/reports/types';
import { getClass, getTestId } from 'helpers/components';
import { showErrorToast } from 'helpers/general';
import { trackRuleDeleted } from 'helpers/mixpanel';
import { isKeyPartOfCollection, toTextValuePairs } from 'helpers/types';
import { getOperatorTooltip } from 'helpers/utils';
import useCurrentUser from 'hooks/useCurrentUser';
import useFetch from 'hooks/useFetch';
import { compact, List } from 'lodash';
import {
  find,
  flow,
  get,
  head,
  isEmpty,
  isEqual,
  isNil,
  LodashFind2x1,
} from 'lodash/fp';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Actions, Index } from 'routes';
import * as messageListActions from 'store/actions/messageList';
import * as reportActions from 'store/actions/report';
import * as domainSelectors from 'store/selectors/domains';
import * as messageListSelectors from 'store/selectors/messageList';
import * as reportSelectors from 'store/selectors/report';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import {
  AllFilterTypes,
  allOperators,
  comparisonOperators,
  containsOperators,
  FilterType,
  FilterValue,
  IFilterValuePayload,
  inOperators,
  IQueryFiltersChildren,
  newDateTimeFilter,
  Operator,
  RuleFilter,
} from 'types/filter';
import { Modes } from 'types/query';
import State from 'types/state';
import ITextValue from 'types/textValue';
import IIdName from '../../../types/idName';
import GenericConversionRulesItem from './GenericConversionRulesItem';
import { handleMetricRetrive } from './helpers';

export const componentName = 'rules-builder-item';

const DOM_KEY_SUB_ITEM = 'subitem';
const DOM_KEY_SUB_ITEM_OPERATOR = 'operator';
const DOM_KEY_SUB_ITEM_FIELD = 'field';
const DOM_KEY_SUB_ITEM_VALUE = 'value';
const DOM_KEY_SUB_ITEM_DELETE = 'delete';

export const subItemFieldClass = getClass(componentName, {
  concat: [DOM_KEY_SUB_ITEM],
  add: [DOM_KEY_SUB_ITEM_FIELD],
});

export const subItemOperatorClass = getClass(componentName, {
  concat: [DOM_KEY_SUB_ITEM],
  add: [DOM_KEY_SUB_ITEM_OPERATOR],
});

export const subItemValueClass = getClass(componentName, {
  concat: [DOM_KEY_SUB_ITEM],
  add: [DOM_KEY_SUB_ITEM_VALUE],
});

const deleteClass = getClass(componentName, {
  concat: [DOM_KEY_SUB_ITEM],
  add: [DOM_KEY_SUB_ITEM_DELETE],
});

const componentClass = getClass(componentName);

export const dimensionTypeList = {
  datetime: 'datetime',
  duration_mins: 'duration_mins',
  duration_secs: 'duration_secs',
  lookup_str: 'lookup_str',
  numeric: 'numeric',
  string: 'string',
  compound_event_based: 'compound_event_based',
  compound_event_based_generic: 'compound_event_based_generic',
};

export const inaccessibleRuleMessage =
  'Please remove unknown or inaccessible Rule selection.';

const getFilterType = (filterDimensionType = ''): AllFilterTypes => {
  switch (filterDimensionType) {
    case dimensionTypeList.datetime:
      return FilterType.DATETIME_RANGE;
    case dimensionTypeList.compound_event_based:
    case dimensionTypeList.compound_event_based_generic:
      return FilterType.COMPOUND_FILTER;
    default:
      return FilterType.SIMPLE;
  }
};

export enum InputTestId {
  parentRule = 'field-select',
  operator = 'operator-select',
  ruleValue = 'value-select',
}

const getDimension = (dimensionId?: string): LodashFind2x1<{ id: string }> =>
  find<{ id: string }>({ id: dimensionId });
const getOperatorList = get('operators');

const getDimensionOperatorList = (
  dimensionId?: string,
): ((collection: List<IDimension> | null | undefined) => string[]) =>
  flow(getDimension(dimensionId), getOperatorList);

const getDimensionType = (
  dimensionId?: string,
): ((collection: List<IDimension> | null | undefined) => string | undefined) =>
  flow(getDimension(dimensionId), get('dimension_type'));

const isDurationMins = isEqual(get('duration_mins', dimensionTypeList));
const isDurationSecs = isEqual(get('duration_secs', dimensionTypeList));
const isNumeric = isEqual(get('numeric', dimensionTypeList));
const isDateTime = isEqual(get('datetime', dimensionTypeList));

export const operatorListToTextValue = (
  operators: string[],
  dimensionGroup?: string,
): ITextValue[] =>
  operators?.map((operator: string) => ({
    value: operator,
    text: get(operator, allOperators),
    tooltip: getOperatorTooltip(operator, dimensionGroup),
  }));

export interface IProps {
  datasets?: string[];
  dimensions?: IDimension[];
  disabled?: boolean;
  filter: RuleFilter;
  fixed?: boolean;
  index: number;
  isDeletable?: boolean;
  onChange: (f: RuleFilter) => void;
  onDelete?: (id: RuleFilter) => void;
  onError?: (err: IError) => void;
  placeholder?: string;
  testId?: string;
  section?: string;
  queryMinDate?: string;
  queryMaxDate?: string;
  report?: IReport;
  isReportInvalid: boolean;
  getExposureAds: IQueryFiltersChildren;
  getOutcomeViewership: IQueryFiltersChildren;
  getFiltersChildren: IQueryFiltersChildren;
  availableDatasets?: IDataset[];
  addWarningMessage: (message: IMessage) => void;
  deleteWarningMessage: (message: IMessage) => void;
  messageList: IMessage[];
  parentId?: string;
  parentRuleTracking?: RuleFilter;
  optionalRules?: string[];
  setReportPerformanceMetricGroups: (performanceMetricGroups: string[]) => void;
  setReportConversionType: (conversionType: string) => void;
  setGenericConversionsMinDate: (minDate: string) => void;
  setGenericConversionsMaxDate: (maxDate: string) => void;
}

const RulesBuilderItem: FunctionComponent<IProps> = (
  props: IProps,
): ReactElement => {
  const {
    datasets,
    dimensions = [],
    disabled,
    filter,
    index,
    isDeletable = true,
    onChange,
    onDelete,
    onError,
    placeholder,
    testId,
    section,
    queryMinDate,
    queryMaxDate,
    report,
    availableDatasets,
    fixed,
    addWarningMessage,
    deleteWarningMessage,
    messageList,
    parentId,
    parentRuleTracking,
    isReportInvalid,
    getOutcomeViewership,
    getExposureAds,
    getFiltersChildren,
    optionalRules = [
      'CREATIVE_LABEL',
      'CREATIVE_NAME_GROUP',
      'PROGRAM',
      'NETWORK_NAME_OPENAP_SPLIT',
    ],
    setReportPerformanceMetricGroups,
    setReportConversionType,
    setGenericConversionsMinDate,
    setGenericConversionsMaxDate,
  } = props;
  const {
    field,
    operator,
    value,
    start,
    end,
    subDimensions,
    mainField,
    childField,
    filterRules,
    description,
    filterId,
    id,
    type,
  } = filter;
  const [valueOptions, setValueOptions] = useState<ITextValue[]>();
  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>();
  const [isRuleInaccessible, setIsRuleInaccessible] = useState(false);
  const prevField = useRef('');
  const [loading, setLoading] = useState(false);
  const [dateLimitsAreLoaded, setDateLimitsAreLoaded] = useState(false);
  const [fieldMessage, setFieldMessage] = useState<string>();
  const [operatorMessage, setOperatorMessage] = useState<string>();
  const [valueMessage, setValueMessage] = useState<string>();
  const { doFetch } = useFetch<ITextValue>();
  const { getDimensionGroup } = useDimensions();

  const { email, selectedClient } = useCurrentUser();

  const dimensionGroup = getDimensionGroup(field);

  const operatorList = getDimensionOperatorList(field)(dimensions);

  const dimensionType = getDimensionType(field)(dimensions);

  const curatedDimensions = toTextValuePairs(dimensions);
  const daypartDatasetId = get(['query', 'daypart_dataset_id'], report);

  const isCustomExposure = report?.query?.mode === Modes.genericEvents;
  const handleFuzzyDimensionSearch: DropdownSearchQueryProvider = (
    query,
  ): Promise<ITextValue[]> =>
    new Promise((resolve) => {
      resolve(
        query
          ? curatedDimensions.filter(
              (item) =>
                match(item.text, query, { requireMatchAll: true }).length > 0,
            )
          : curatedDimensions,
      );
    });

  const handleDateRangeChange = useCallback(
    (startDate: string, endDate: string): void => {
      const newDate = newDateTimeFilter(startDate, endDate);
      const newFilter = {
        ...filter,
        ...newDate,
        value: [startDate, endDate].join(','),
        isRule: true,
      } as RuleFilter;
      onChange(newFilter);
    },
    [filter, index, onChange],
  );

  const retrieveMetric = (): void => {
    setLoading(true);
    setDateLimitsAreLoaded(false);
    setMinDate(undefined);
    setMaxDate(undefined);
    doFetch({
      endpoint: '/filterValues/metric',
      payload: {
        userEmail: email ?? '',
        datasetIds: datasets ?? [],
        daypart_dataset_id: daypartDatasetId,
        field,
        selectedClient,
      },
      method: FetchMethod.POST,
      onError: () => {
        setDateLimitsAreLoaded(true);
        setLoading(false);
        setMinDate(undefined);
        setMaxDate(undefined);
      },
      onSuccess: (response) => {
        handleMetricRetrive(
          response,
          start,
          end,
          handleDateRangeChange,
          setMinDate,
          setMaxDate,
        );
      },
    });
  };

  const retrieveCurrentDataset = useCallback(
    (dimensionId: string | number): void => {
      setLoading(true);
      setDateLimitsAreLoaded(false);
      doFetch({
        endpoint: `/${Index.SEGMENT_FILTER_VALUES}/${Actions.SEGMENT_CUSTOM_DATASET_DATA}?generic_events_dataset_ids=${dimensionId}`,
        method: FetchMethod.GET,
        onError: () => {
          setDateLimitsAreLoaded(true);
          setLoading(false);
          setGenericConversionsMinDate('');
          setGenericConversionsMaxDate('');
        },
        onSuccess: (response) => {
          if (!isNil(response)) {
            const {
              min_date: minDateValue,
              max_date: maxDateValue,
              conversion_type: conversionType,
              performance_metric_groups: performanceMetricGroups,
            } = response as unknown as IDatasetDetails;
            setReportPerformanceMetricGroups(
              performanceMetricGroups ?? ['converters'],
            );
            setReportConversionType(conversionType ?? '');

            setGenericConversionsMinDate(minDateValue);
            setGenericConversionsMaxDate(maxDateValue);

            setDateLimitsAreLoaded(true);
            setLoading(false);
          }
        },
      });
    },
    [
      doFetch,
      setReportConversionType,
      setDateLimitsAreLoaded,
      setGenericConversionsMinDate,
      setGenericConversionsMaxDate,
      setReportPerformanceMetricGroups,
    ],
  );

  const selectedDataset = (availableDatasets ?? []).find(
    (dimension: IDataset) => dimension.id === (datasets ?? [])[0],
  );

  const getDatasetMode = (): string | null => {
    if (report?.type !== 'attribution-report') {
      // For Target and PLATF0RM reports, we need to use the mode from the report
      return report?.query?.mode ?? '';
    }
    // For IMP4CT reports we use a different mode based on where the call is made
    // In the Ad Details section, all of the dropdowns have a 'fixed' prop set to true
    if (section === 'adDetails') {
      return report?.query?.mode ?? '';
    }
    // Else here is the Viewership Details section in Imp4ct reports
    // The Outcomes section calls to 'filterValues/metric' and won't have a mode
    return 'MODE_LINEAR';
  };

  const getFilterRulesForFilterValueCall = (
    mode: string | null,
  ): IQueryFiltersChildren => {
    const isIAttributionReport = report?.type === 'attribution-report';
    if (isIAttributionReport) {
      // Similar logic to the above function. We need to return the correct filter rules
      // base on where in the report the call is made. FIRE-5493
      if (mode === report?.query?.mode) {
        return getExposureAds;
      }
      return getOutcomeViewership;
    }
    return getFiltersChildren;
  };

  const datasetId = selectedDataset?.dataset_id;
  const retrieveValues: DropdownSearchQueryProvider = useCallback(
    (valueFilter = '', lineBegin = 0, lineEnd = 0) =>
      new Promise((resolve, reject) => {
        setValueOptions([]);
        if (
          filter.type === 'COHORT' ||
          filter.type === 'GENERIC_EVENTS_DATASET'
        )
          return;
        doFetch({
          endpoint: '/filterValues',
          payload: {
            userEmail: email ?? '',
            datasetIds: isCustomExposure ? [] : datasets ?? [],
            genericEventDatasets: isCustomExposure ? compact([datasetId]) : [],
            field: mainField ?? field,
            mode: getDatasetMode(),
            filter_rules: getFilterRulesForFilterValueCall(getDatasetMode()),
            daypart_dataset_id: daypartDatasetId,
            lineBegin,
            lineEnd,
            valueFilter,
            childField,
            filterRules,
            selectedClient,
            reportType: report?.type,
          } as IFilterValuePayload,
          method: FetchMethod.POST,
          onError: (e) => {
            setValueOptions([]);
            showErrorToast(e.message ?? 'Cannot retrieve rules values');
            reject(e);
            if (onError) onError(e);
          },
          onSuccess: (d) => {
            setLoading(false);
            setValueOptions(d);
            resolve(d);
          },
        });
      }),
    [
      datasets,
      doFetch,
      email,
      getOutcomeViewership,
      getExposureAds,
      getFiltersChildren,
      datasetId,
      field,
      mainField,
      childField,
      filterRules,
      onError,
      filter.type,
      selectedClient,
      isCustomExposure,
    ],
  );

  useEffect(() => {
    const newDimensionType = getDimensionType(field)(dimensions);

    if (operator === 'BETWEEN' || operator === 'UNEXPOSED BETWEEN') {
      if (dateLimitsAreLoaded) {
        handleDateRangeChange(filter.start as string, filter.end as string);
      }
    }

    if (
      !field ||
      newDimensionType !== dimensionTypeList.datetime ||
      loading ||
      dateLimitsAreLoaded ||
      type === 'GENERIC_EVENTS_DATASET'
    ) {
      return;
    }

    if (operator) retrieveMetric();
  }, [field, operator]);

  useEffect(() => {
    const currDim = dimensions?.find(({ id: dId }) => dId === id);
    if (
      currDim?.dimension_type === dimensionTypeList.compound_event_based_generic
    ) {
      retrieveCurrentDataset(currDim.id);
    }
  }, [dimensions, id]);

  useEffect(() => {
    if (!field || !operator || !isKeyPartOfCollection(operator, inOperators))
      return;
    if (field === prevField.current) return;
    prevField.current = field;
  }, [field, operator]);

  const handleFieldChange = (newField: string | number | boolean): void => {
    setValueOptions([]);
    setDateLimitsAreLoaded(false);
    const fieldValue = newField.toString();
    const filterField = dimensions?.find((x) => x.id === fieldValue);
    onChange({
      ...filter,
      field: fieldValue,
      id: fieldValue,
      type: getFilterType(filterField?.dimension_type),
      operator: undefined,
      start: undefined,
      end: undefined,
      value: undefined,
      children: undefined,
      isRule: true,
      subDimensions: filterField?.dimensions,
      description: filterField?.description,
    });
  };

  const handleOperatorChange = useCallback(
    (newOperator: Operator): void => {
      onChange({
        ...filter,
        operator: newOperator,
        value: undefined,
      });
    },
    [filter, index, onChange],
  );

  const handleValueChange = (
    newValue: FilterValue | boolean | number,
  ): void => {
    onChange({
      ...filter,
      value: newValue as FilterValue,
    });
  };

  const handleRuleDelete = useCallback(() => {
    trackRuleDeleted(index, filter, report, filter.id, parentRuleTracking?.id);
    onDelete?.(filter);
  }, [onDelete, index, filter, parentRuleTracking?.id, report]);

  const componentTestId = getTestId(componentName, testId);

  useEffect(() => {
    if (!isEmpty(operatorList) && isNil(operator)) {
      const firstOperator = head(operatorList) as Operator;
      handleOperatorChange(firstOperator);
    }
  }, [operator, operatorList, handleOperatorChange]);

  const isCompoundFilter =
    dimensionType === dimensionTypeList.compound_event_based;
  const isGenericConversion =
    dimensionType === dimensionTypeList.compound_event_based_generic;

  const clearError = (
    fieldType: 'field' | 'value' | 'operator',
    message: string,
    setMessage: (msg: string) => void,
  ): void => {
    const isInError = messageList.filter(
      (m) => m.id === filterId && m.field === fieldType,
    );
    if (message !== '') setMessage('');
    if (isInError.length) {
      deleteWarningMessage({
        id: filterId,
        field: fieldType,
      });
    }
  };

  const validateField = (): void => {
    if (!field && filter.type !== 'COHORT') {
      setFieldMessage('Please enter a value for this rule.');
      addWarningMessage({
        id: filterId,
        message: `Please enter a value for this rule. ${field ?? placeholder}`,
        field: 'field',
        parentId,
      });
    } else if (
      field &&
      !curatedDimensions.find((item) => item.value === field)
    ) {
      setIsRuleInaccessible(true);
      setFieldMessage(inaccessibleRuleMessage);
      addWarningMessage({
        id: filterId,
        message: `${inaccessibleRuleMessage} ${field ?? placeholder}`,
        field: 'field',
        parentId,
      });
    } else if (filter.type !== 'COHORT') {
      clearError('field', fieldMessage as string, setFieldMessage);
    }
  };

  const validateOperator = (): void => {
    if (!operator && id) {
      setOperatorMessage('Operator cannot be empty.');
      addWarningMessage({
        id: filterId,
        message: `Operator cannot be empty. ${field ?? placeholder}`,
        field: 'operator',
        parentId,
      });
    } else {
      clearError('operator', operatorMessage as string, setOperatorMessage);
    }
  };

  const validateCohort = (): void => {
    if (filter.type === 'COHORT') {
      const children =
        (filter.children as RuleFilter[])?.filter(
          (child) => child.type !== 'DATETIME_RANGE',
        ) ?? [];
      if (children?.length === 0) {
        setFieldMessage(
          'This rule needs at least one child rule with selected value',
        );
        addWarningMessage({
          id: filterId,
          message: `This rule needs at least one child rule with selected value`,
          field: 'field',
          parentId,
        });
      } else {
        clearError('field', fieldMessage as string, setFieldMessage);
      }
    }
  };

  const validateValue = (): void => {
    const invalidNumericInput =
      (isNumeric(dimensionType) ||
        isDurationMins(dimensionType) ||
        isDurationSecs(dimensionType)) &&
      value === undefined;
    const invalidArrayInput = Array.isArray(value) && !value.length;
    const isOptionalRule = fixed && optionalRules.includes(field ?? '');

    if (
      (invalidNumericInput || !value || invalidArrayInput) &&
      !isOptionalRule &&
      id
    ) {
      setValueMessage('Please enter a value for this field.');
      addWarningMessage({
        id: filterId,
        message: `Please enter a value for this field. ${field ?? placeholder}`,
        field: 'value',
        parentId,
      });
    } else {
      clearError('value', valueMessage as string, setValueMessage);
    }
  };

  const validateRulesBuilderItem = (): void => {
    validateField();
    validateOperator();
    validateCohort();
    validateValue();
  };
  useEffect(() => {
    validateRulesBuilderItem();
  }, [field, operator, value, isReportInvalid, messageList]);

  const deleteButton = (
    <div className={deleteClass}>
      <Button
        kind={Kind.icon}
        iconType={Type.trash}
        iconColor={iconColors.primary}
        onClick={handleRuleDelete}
        disabled={disabled}
        testId="delete-rule"
      />
    </div>
  );
  const showSubItem =
    field && operator && !isCompoundFilter && !isGenericConversion;
  return (
    <div data-testid={componentTestId} className={componentClass}>
      <Flex horizontal={Horizontal.left}>
        <div className={subItemFieldClass}>
          {fixed ? (
            <Dropdown
              value={field}
              disabled={Boolean(disabled) || isRuleInaccessible}
              fixed
              testId={InputTestId.parentRule}
              trackingId="rule_field"
              trackingRuleContext={{ filter, index, parentRuleTracking }}
              placeholder={placeholder}
              options={curatedDimensions}
              onChange={handleFieldChange}
              description={description}
              errorText={fieldMessage}
              isReportInvalid={isReportInvalid}
            />
          ) : (
            <DropdownSearch
              onChange={handleFieldChange}
              errorText={fieldMessage}
              queryProvider={handleFuzzyDimensionSearch}
              fetchedOptions={false}
              placeholder={placeholder}
              testId={InputTestId.parentRule}
              cancelTracking={false}
              trackingId="rule_field"
              trackingRuleContext={{ filter, index, parentRuleTracking }}
              selectedOptions={[field ?? '']}
              disabled={Boolean(disabled) || isRuleInaccessible}
              enableHighlight
              dimensions={curatedDimensions}
              isReportInvalid={isReportInvalid}
            />
          )}
        </div>
        {field && !isCompoundFilter && !isGenericConversion && (
          <div className={subItemOperatorClass}>
            <Dropdown
              value={operator}
              errorText={operatorMessage}
              disabled={Boolean(disabled) || isRuleInaccessible}
              testId={InputTestId.operator}
              trackingId="rule_operator"
              trackingRuleContext={{ filter, index, parentRuleTracking }}
              placeholder="Select an operator"
              options={operatorListToTextValue(operatorList, dimensionGroup)}
              onChange={(op): void => handleOperatorChange(op as Operator)}
              isReportInvalid={isReportInvalid}
              isAttributionReport={isAttributionReport(
                report as IAttributionReport,
              )}
            />
          </div>
        )}
        <div className={subItemValueClass}>
          {showSubItem && isDateTime(dimensionType) && (
            <DatePicker
              testId="date-picker"
              anchor={Anchor.right}
              disabled={Boolean(disabled) || isRuleInaccessible}
              endDate={end}
              trackingId={`Rule - ${field}`}
              handleChange={handleDateRangeChange}
              maxDate={maxDate}
              minDate={minDate}
              showNumOfDays
              startDate={start}
              message={valueMessage}
            />
          )}
          {showSubItem && isDurationSecs(dimensionType) && (
            <DurationInput
              disabled={loading || Boolean(disabled) || isRuleInaccessible}
              onChange={handleValueChange}
              trackingId="rule_value"
              trackingRuleContext={{ filter, index, parentRuleTracking }}
              value={value}
              errorText={valueMessage}
              isReportInvalid={isReportInvalid}
            />
          )}
          {showSubItem && isDurationMins(dimensionType) && (
            <DurationInput
              disabled={loading || Boolean(disabled) || isRuleInaccessible}
              isMinutes
              trackingId="rule_value"
              trackingRuleContext={{ filter, index, parentRuleTracking }}
              onChange={handleValueChange}
              value={value}
              errorText={valueMessage}
              isReportInvalid={isReportInvalid}
            />
          )}
          {showSubItem &&
            isKeyPartOfCollection(operator as string, inOperators) && (
              <MultipleDropdownSelect
                queryProvider={retrieveValues}
                fetchedOptions
                loading={loading}
                disabled={Boolean(disabled) || isRuleInaccessible}
                testId={InputTestId.ruleValue}
                trackingId="rule_value"
                trackingRuleContext={{ filter, index, parentRuleTracking }}
                placeholder={loading ? 'Loading values' : 'Select value'}
                selected={toTextValuePairs(value as IIdName[])}
                options={valueOptions}
                onChange={handleValueChange}
                type={InputType.search}
                errorText={valueMessage}
                isReportInvalid={isReportInvalid}
              />
            )}
          {showSubItem &&
            isKeyPartOfCollection(operator as string, containsOperators) && (
              <MultipleTextSelect
                disabled={loading || Boolean(disabled) || isRuleInaccessible}
                testId={InputTestId.ruleValue}
                trackingId="rule_value"
                trackingRuleContext={{ filter, index, parentRuleTracking }}
                selected={value as string[]}
                onChange={handleValueChange}
                errorText={valueMessage}
                isReportInvalid={isReportInvalid}
              />
            )}
          {showSubItem &&
            isKeyPartOfCollection(operator as string, comparisonOperators) &&
            !(
              isDurationMins(dimensionType) || isDurationSecs(dimensionType)
            ) && (
              <>
                <Input
                  disabled={loading || Boolean(disabled) || isRuleInaccessible}
                  onChange={handleValueChange}
                  hasError={!!valueMessage && isReportInvalid}
                  placeholder="Value"
                  testId={InputTestId.ruleValue}
                  type={
                    isNumeric(dimensionType) ? InputType.number : InputType.text
                  }
                  value={(value as string) || ''}
                />
                {valueMessage && isReportInvalid && (
                  <InlineAlert
                    mode={AlertTypes.error}
                    message={valueMessage}
                    hideClose
                  />
                )}
              </>
            )}
        </div>
        {isDeletable &&
          !isCompoundFilter &&
          !isGenericConversion &&
          deleteButton}
      </Flex>
      {isDeletable && isCompoundFilter && !isGenericConversion && (
        <div style={{ float: 'right' }}>{deleteButton}</div>
      )}
      {isCompoundFilter && !isGenericConversion && (
        <CompoundRulesBuilderItem
          key={field}
          mainField={field}
          mainFilter={filter}
          dimensions={subDimensions ?? []}
          queryMaxDate={queryMaxDate}
          queryMinDate={queryMinDate}
          onChange={onChange}
          fieldIndex={index}
          disabled={Boolean(disabled) || isRuleInaccessible}
        />
      )}
      {isGenericConversion && !isCompoundFilter && (
        <GenericConversionRulesItem
          mainFilter={filter}
          onChange={onChange}
          mainFilterIndex={index}
          disabled={Boolean(disabled) || isRuleInaccessible}
        />
      )}
    </div>
  );
};

const mapStateToProps = (
  state: State,
): {
  report: IReport;
  availableDatasets: IDataset[];
  messageList: IMessage[];
  isReportInvalid: boolean;
  getExposureAds: IQueryFiltersChildren;
  getOutcomeViewership: IQueryFiltersChildren;
  getFiltersChildren: IQueryFiltersChildren;
} => ({
  report: reportSelectors.getReport(state),
  availableDatasets: domainSelectors.getDatasets(state),
  messageList: messageListSelectors.getMessageList(state),
  isReportInvalid: reportSelectors.isReportInvalid(state),
  getExposureAds: reportSelectors.getExposureAds(state),
  getOutcomeViewership: reportSelectors.getOutcomeViewership(state),
  getFiltersChildren: reportSelectors.getFiltersChildren(state),
});

const mapDispatchToProps = {
  addWarningMessage: messageListActions.addMessage,
  deleteWarningMessage: messageListActions.deleteMessage,
  setReportPerformanceMetricGroups:
    reportActions.setReportPerformanceMetricGroups,
  setReportConversionType: reportActions.setReportConversionType,
  setGenericConversionsMinDate: reportActions.setGenericConversionsMinDate,
  setGenericConversionsMaxDate: reportActions.setGenericConversionsMaxDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(RulesBuilderItem);
