import get from 'lodash/get';
import set from 'lodash/set';
import { IOption } from './DropdownPath';
import { v4 } from 'uuid';

interface IOptionTree {
  value: string;
  id: string;
  children?: IOption[];
}

/**
 * Get the path group children
 *
 * @param auxTree path item ex.: 'firstlevel|secondlevel|etc'
 * @param tree parent group item
 * @param path parent group item
 * @returns {array}
 */
const getOrSetItemOption = (
  auxTree: IOptionTree,
  tree: IOption[],
  path: string,
): void => {
  // split the path string in the (|) chars into array os paths
  const arrayPath = `${path}`.split('|');
  // get the first/current item
  const currentPath = arrayPath.shift() as string;
  // get the rest subpath items
  const restPath = arrayPath;
  // get the already exists item in the auxiliar tree object
  const node = get(auxTree, currentPath);
  // set the index of exists item as null
  let index = null;
  // if the item first/current item no exists so set the new item in the tree
  if (!node) {
    // get the current tree new index
    index = tree.length;
    // set the new item
    set(auxTree, currentPath, {
      index,
    });

    // add the new empty option in the tree
    tree.push({
      id: v4(),
      value: currentPath,
      children: [],
    });
  } else {
    // set the already exists item index to be used in the next getOrSetItemOption logic if there
    index = node.index;
  }
  // if there is no more subpaths finish the tree
  if (restPath.length === 0) return;

  // else get the remaining subpath items
  getOrSetItemOption(
    get(auxTree, currentPath),
    tree[index]?.children ?? [],
    restPath.join('|'),
  );
};

/**
 * Get the option tree array based on the group path strings
 *
 * @param pathArray ex.: ['path','path|subpath','secontpath']
 * @return {IOption[]}
 */
export const getOptions = (pathArray: string[]): IOption[] => {
  // set initial auxiliar map
  const auxTree = {} as IOptionTree;
  // start the ampty option tree array
  const tree: IOption[] = [];

  // iterate path array to set the options
  pathArray.forEach((path) => {
    getOrSetItemOption(auxTree, tree, path);
  });

  // return the array of options
  return tree;
};
