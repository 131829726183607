import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Actions } from 'routes';
import DashboardDetailContainer from './components/DashboardDetailContainer';
import DashboardsEditContainer from './components/DashboardEditContainer';
import DashboardsListContainer from './components/DashboardsListContainer';

const Dashboards: FunctionComponent = () => {
  const { checkPermissions } = useUserPermissions();
  return (
    <>
      <Routes>
        {checkPermissions('dashboards::view') && (
          <>
            <Route path="list/:id" element={<DashboardDetailContainer />} />
            <Route path="list" element={<DashboardsListContainer />} />
          </>
        )}
        {checkPermissions('dashboards::create') && (
          <Route
            path={Actions.SEGMENT_CREATE}
            element={<DashboardsEditContainer />}
          />
        )}
        {checkPermissions('dashboards::update') && (
          <Route
            path={`${Actions.SEGMENT_EDIT}/:id`}
            element={<DashboardsEditContainer />}
          />
        )}

        <Route index element={<DashboardDetailContainer />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};
export default Dashboards;
