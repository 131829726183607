import { IFilters } from 'helpers/charts/chartWithTable/types';
import { IOption } from 'types/textValue';
import { ChartType } from 'components/ChartWithTable';

export const getChartTitle = (
  axisOptions: IOption[],
  filters: IFilters,
): string => {
  if (filters.chartType === ChartType.BAR) {
    return axisOptions.find((op) => filters.yAxis === op.value)?.text ?? '';
  }
  return `${axisOptions.find((op) => filters.xAxis === op.value)?.text} by ${
    axisOptions.find((op) => filters.yAxis === op.value)?.text
  } by ${axisOptions.find((op) => filters.bubbleSize === op.value)?.text}`;
};
