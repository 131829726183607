/**
 * THis function was extracted from this repository https://github.com/mstdokumaci/string-hash-64
 * with th intention to hash a string into a Int64
 *
 * This function generate 64 bit integer hashes. Returns a number between 0 and 18446744073709552000
 *
 * @param str String
 * @returns {Number} Hashed Int64
 */
export function hash(str: string): number {
  var i = str.length;
  var hash1 = 5381;
  var hash2 = 52711;

  while (i--) {
    const char = str.charCodeAt(i);
    hash1 = (hash1 * 33) ^ char;
    hash2 = (hash2 * 33) ^ char;
  }

  return (hash1 >>> 0) * 4096 + (hash2 >>> 0);
}
