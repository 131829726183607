import { SVGPathArray } from 'highcharts';
import { ChartPosition } from 'types/chartPosition';

const renderCustomPlotLine = (
  chart: Highcharts.Chart,
  position: ChartPosition,
  plotOn: number,
): Highcharts.SVGElement => {
  const { plotTop, plotHeight, chartHeight, xAxis } = chart;
  const isBottomChart = position === ChartPosition.Bottom;
  const dashLength = 12;

  const parent = chart.renderer.g('customPlotline').attr({ zIndex: 8 }).add();

  chart.renderer
    .path([
      'M',
      xAxis[0].toPixels(plotOn, false),
      isBottomChart ? dashLength : plotTop,
      'L',
      xAxis[0].toPixels(plotOn, false),
      isBottomChart ? plotHeight + plotTop : chartHeight,
    ] as unknown as SVGPathArray)
    .attr({
      'stroke-width': 1,
      stroke: '#383B41',
      'stroke-dasharray': `3 ${dashLength}`,
    })
    .add(parent);

  return parent;
};

export default renderCustomPlotLine;
