import { useEffect, useState } from 'react';
import { Index } from 'routes';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import useFetch from './useFetch';
import useToast from './useToast';
import { IConfigOption } from 'domains/configOptions/types';

export type HookReturn = {
  configOptions: IConfigOption[];
  resetConfigOptions: () => void;
  loading: boolean;
};

const useConfigOptions = (): HookReturn => {
  const [configOptions, setConfigOptions] = useState<IConfigOption[]>();
  const [loading, setLoading] = useState(false);
  const { doErrorToast } = useToast();
  const { doFetch } = useFetch<IConfigOption>();

  useEffect(() => {
    if (loading || configOptions) return;
    setLoading(true);
    doFetch({
      endpoint: `/${Index.SEGMENT_CONFIG_OPTIONS}`,
      method: FetchMethod.GET,
      onSuccess: (res: IConfigOption[]) => {
        setConfigOptions(res);
        setLoading(false);
      },
      onError: (e: IError) => {
        setConfigOptions([]);
        doErrorToast(e?.message ?? 'There has been an error.');
        setLoading(false);
      },
    });
  }, [doErrorToast, doFetch, loading, configOptions]);

  const resetConfigOptions = (): void => setConfigOptions(undefined);

  return {
    configOptions: configOptions ?? [],
    resetConfigOptions,
    loading,
  };
};

export default useConfigOptions;
