import { IClient } from 'domains/clients/types';
import { IAttributionReport } from 'domains/reports/types';
import { ModalType } from 'features/reports/EditFeature/constants';
import { get } from 'lodash/fp';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as businessDataActions from 'store/actions/businessData';
import * as reportActions from 'store/actions/report';
import * as rootActions from 'store/actions/root';
import * as toolbarActions from 'store/actions/toolbar';
import * as userActions from 'store/actions/user';
import * as reportSelectors from 'store/selectors/report';
import State from 'types/state';

export const handleSelectClient =
  (
    client: IClient,
    setClientCookieHook: (arg0: IClient) => void,
    setStateNotLoadedHook: () => void,
  ) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    const extID = get('extID', client);
    const selectedClient = get('id', client) ?? '';
    setClientCookieHook(client);
    dispatch(userActions.setUserSelectedClient(client));
    setStateNotLoadedHook();
    await dispatch(reportActions.loadBusinessData(selectedClient));
    await dispatch(businessDataActions.fetchTargetsAction(extID));
    dispatch(rootActions.setReportList(null));
    dispatch(rootActions.setTargetList(null));
    dispatch(rootActions.setAttributionReportList(null));
  };

export const handleSubmitAttributionForm =
  (
    name: string,
    reportNameValidationHook: (name: string) => boolean,
    setModalTypeHook: (modalType: number) => void,
    openModalHook: () => void,
    doSubmit: (
      runOnFinish?: boolean,
      reportToSubmit?: IAttributionReport,
    ) => Promise<void>,
  ) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const report = reportSelectors.getReport(getState());
    const reportNameIsValid = reportNameValidationHook(name);
    const updatedReport = { ...report, name };

    if (!reportNameIsValid) {
      setModalTypeHook(ModalType.addReportName);
      openModalHook();
    } else {
      await doSubmit(false, updatedReport);
    }
  };

export const handleSubmitPlatformForm =
  (
    name: string,
    reportNameValidationHook: (name: string) => boolean,
    setModalTypeHook: (modalType: number) => void,
    doSubmit: (
      runOnFinish?: boolean,
      reportToSubmit?: IAttributionReport,
    ) => Promise<void>,
  ) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const report = reportSelectors.getReport(getState());
    const reportNameIsValid = reportNameValidationHook(name);
    const updatedReport = { ...report, name };
    if (!reportNameIsValid) {
      setModalTypeHook(ModalType.addReportName);
      dispatch(reportActions.toggleReportModal(true));
    } else {
      await doSubmit(false, updatedReport);
      dispatch(toolbarActions.save(updatedReport));
    }
  };
