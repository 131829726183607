import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import Flex, { Horizontal } from 'components/Flex';
import { getClass, getTestId } from 'helpers/components';
import React, { ReactElement } from 'react';
import ITextValue from 'types/textValue';
import { SortableList } from './components/SortableList';

export const selectedChipsComponentName = 'selected-chips';
interface IProps<T> {
  canReorder?: boolean;
  disabled?: boolean;
  minimumChipsSelected?: number;
  onChange: (selected: T[]) => void;
  selected?: T[];
  testId?: string;
}

const SelectedChips = (props: IProps<string | ITextValue>): ReactElement => {
  const {
    canReorder = false,
    disabled,
    minimumChipsSelected = 0,
    onChange,
    selected = [],
    testId,
  } = props;
  const selectedChipsComponentClass = getClass(selectedChipsComponentName);
  const selectedChipsComponentTestId = getTestId(
    selectedChipsComponentName,
    testId,
  );

  const newReorder = (event: DragEndEvent): void => {
    const { active, over } = event;
    const oldIndex = active.data.current?.sortable?.index;
    const newIndex = over?.data.current?.sortable?.index;
    const result = arrayMove(selected, oldIndex, newIndex);
    document.body.classList.remove('grabbing');
    onChange(result);
  };

  const handleRemove = (index: number): void => {
    const result = Array.from(selected);
    result.splice(index, 1);
    onChange(result);
  };

  return (
    <div
      data-testid={selectedChipsComponentTestId}
      className={selectedChipsComponentClass}
    >
      <Flex horizontal={Horizontal.left} multiLine>
        <SortableList
          testId={testId}
          items={selected}
          onSortEnd={newReorder}
          canReorder={canReorder}
          disabled={disabled}
          minimumChipsSelected={minimumChipsSelected}
          onChipClick={handleRemove}
          onSortStart={(): void => {
            document.body.className = 'grabbing';
          }}
        />
      </Flex>
    </div>
  );
};

export default SelectedChips;
