import { getClass } from 'helpers/components';

const targetResultsFormComponentName = 'target-report-results';

export const DOM_KEY_NO_DATA = 'no-data';

export const LOCALE_DEFAULT_UNIVERSE_NAME = 'Universe';
export const LOCALE_NO_VENDOR_PLACEHOLDER_SUFFIX = 'US TV Households';
export const LOCALE_SINGLE_VENDOR_PLACEHOLDER_SUFFIX = 'TV Households';
export const LOCALE_TARGET_SIZE = 'Audience size';
export const LOCALE_TARGET_BREAKDOWN = 'Audience Breakdown';
export const LOCALE_NO_TARGET_DATA = 'Run a query to display audience data';
export const LOCALE_HOUSEHOLDS_VISITED_BETWEEN =
  'Households that visited between';
export const ISPOT_SEGMENT_CONFIG_OPTION_ID = 'iSpotIngestClients';

export const LOCAL_UNIVERSE_METRICS = '% Of Universe';
export const LOCAL_POPULATION_INDEX_METRICS = 'Population Index';

export const reportFormResultsClass = getClass(targetResultsFormComponentName);
export const reportFormNoDataClass = getClass(targetResultsFormComponentName, {
  concat: [DOM_KEY_NO_DATA],
});
