import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Button';
import Field from 'components/Field';
import FieldWrapper from 'components/Field/Wrapper';
import Flex, { Horizontal, Vertical } from 'components/Flex';
import Icon, { Type as IconType } from 'components/Icon';
import { Type, NewInputValue } from 'components/Input';
import { Metric } from 'domains/metrics/types';
import { getHeatmapAvailable } from 'domains/reports/adapters/results';
import {
  ChartType,
  ChartTypesLocales,
  IMetaData,
  BusinessRules,
} from 'domains/reports/types';
import { filter, flow, head, includes, map } from 'lodash/fp';
import React, { ReactElement, ReactNode } from 'react';

const LOCALE_METRICS_LABEL = 'Metric displayed';
const LOCALE_CHART_TYPE = 'Chart Type';
const LOCALE_CHART_ADD = 'Add metric';
const LOCALE_CHART_REMOVE = 'Remove metric';

type ChartFilterProps = {
  metrics?: Metric[];
  onChangeFilters: (filters: Filter[]) => void;
  selectedMetrics: Metric[];
  selectedCharts: ChartType[];
  selectedDimensions: string[];
  metadata: IMetaData[];
  businessRules: BusinessRules;
  allowMoreFilters: boolean;
};

export type Filter = {
  metric?: Metric;
  chartGroup?: ChartType;
  chart?: ChartType;
};

export type Chart = {
  value: string;
  text: string;
  icon?: ReactNode;
  enabled?: boolean;
  charts?: Chart[];
};

const getCharts = (
  metrics: string[],
  dimensions: string[],
  businessRules: BusinessRules,
): Chart[] => [
  {
    value: 'column',
    text: ChartTypesLocales.column,
    icon: <Icon type={IconType.chartTypeColumn} />,
    enabled: true,
    charts: [
      {
        value: 'column',
        text: ChartTypesLocales.column,
        icon: <Icon type={IconType.chartTypeColumn} />,
        enabled: true,
      },
      {
        value: 'columnStacked',
        text: ChartTypesLocales.columnStacked,
        icon: <Icon type={IconType.chartTypeColumnStacked} />,
        enabled: businessRules.canShowGroupCharts,
      },
      {
        value: 'columnPercentageStacked',
        text: ChartTypesLocales.columnPercentageStacked,
        icon: <Icon type={IconType.chartTypeColumnPercentageStacked} />,
        enabled: businessRules.canShowGroupCharts,
      },
    ],
  },
  {
    value: 'line',
    text: ChartTypesLocales.line,
    icon: <Icon type={IconType.chartTypeLine} />,
    enabled: true,
    charts: [
      {
        value: 'line',
        text: ChartTypesLocales.line,
        icon: <Icon type={IconType.chartTypeLine} />,
        enabled: true,
      },
    ],
  },
  {
    value: 'area',
    text: ChartTypesLocales.area,
    icon: <Icon type={IconType.chartTypeAreaDefault} />,
    enabled: true,
    charts: [
      {
        value: 'area',
        text: ChartTypesLocales.area,
        icon: <Icon type={IconType.chartTypeAreaDefault} />,
        enabled: true,
      },
      {
        value: 'areaStacked',
        text: ChartTypesLocales.areaStacked,
        icon: <Icon type={IconType.chartTypeAreaStacked} />,
        enabled: businessRules.canShowGroupCharts,
      },
      {
        value: 'areaPercentageStacked',
        text: ChartTypesLocales.areaPercentageStacked,
        icon: <Icon type={IconType.chartTypeAreaStackedPercentage} />,
        enabled: businessRules.canShowGroupCharts,
      },
    ],
  },
  {
    value: 'bar',
    text: ChartTypesLocales.bar,
    icon: <Icon type={IconType.chartTypeBar} />,
    enabled: true,
    charts: [
      {
        value: 'bar',
        text: ChartTypesLocales.bar,
        icon: <Icon type={IconType.chartTypeBar} />,
        enabled: true,
      },
      {
        value: 'barStacked',
        text: ChartTypesLocales.barStacked,
        icon: <Icon type={IconType.chartTypeBarStacked} />,
        enabled: businessRules.canShowGroupCharts,
      },
      {
        value: 'barPercentageStacked',
        text: ChartTypesLocales.barPercentageStacked,
        icon: <Icon type={IconType.chartTypeBarStackedPercentage} />,
        enabled: businessRules.canShowGroupCharts,
      },
    ],
  },
  // {
  //   value: 'heatmap',
  //   text: ChartTypesLocales.heatmap,
  //   icon: <Icon type={IconType.cog} />,
  //   enabled: businessRules.heatmapAvailable,
  //   charts: [
  //     {
  //       value: 'heatmap',
  //       text: ChartTypesLocales.heatmap,
  //       icon: <Icon type={IconType.cog} />,
  //       enabled: true,
  //     },
  //   ],
  // },
  // {
  //   value: 'packedbubble',
  //   text: ChartTypesLocales.packedbubble,
  //   icon: <Icon type={IconType.chartTypeBubble} />,
  //   enabled: businessRules.packedbubbleAvailable,
  //   charts: [
  //     {
  //       value: 'packedbubble',
  //       text: ChartTypesLocales.packedbubble,
  //       icon: <Icon type={IconType.chartTypeBubble} />,
  //       enabled: true,
  //     },
  //   ],
  // },
];

const getChartGroup = (charts: Chart[], chartName: string): string =>
  charts.find((chart: Chart) =>
    chart.charts?.find((subChart: Chart) => subChart.value === chartName),
  )?.value ?? '';

const getMetricDisplayName = (metric: string, metadata: IMetaData[]): string =>
  metadata.length
    ? metadata.find((metricIndex: IMetaData) => metricIndex.id === metric)
        ?.name ?? metric
    : metric;

const ChartFilter = (props: ChartFilterProps): ReactElement => {
  const {
    onChangeFilters,
    metrics = [],
    selectedMetrics = [],
    selectedCharts = [],
    selectedDimensions = [],
    metadata = [],
    businessRules,
    allowMoreFilters,
  } = props;

  businessRules.heatmapAvailable = getHeatmapAvailable(
    selectedDimensions,
    selectedMetrics,
  );

  const charts = getCharts(
    selectedMetrics,
    selectedDimensions,
    businessRules,
  ).filter((chart: Chart) => chart.enabled);

  const chartGroups = charts.map(({ text, value, icon }) => ({
    text,
    value,
    icon,
  }));

  const filters = selectedMetrics.map((metric: Metric, index: number) => ({
    metric,
    chartGroup:
      selectedCharts[index] && getChartGroup(charts, selectedCharts[index]),
    chart: selectedCharts[index],
  }));

  const getFirstUnusedMetric = flow(
    filter((metric) => !includes(metric, map('metric', filters))),
    head,
  );

  const getFirstUnusedChart = flow(
    map('value'),
    filter((chart) => !includes(chart, map('chart', filters))),
    head,
  );

  const handleAddFilter = (): void => {
    const newMetric = getFirstUnusedMetric(metrics) as string;
    const newChart = getFirstUnusedChart(charts) as string;

    const newFilter: Filter = {
      chart: newChart,
      chartGroup: newChart,
      metric: newMetric,
    };

    const newFilterList: Filter[] = [...filters, newFilter];

    onChangeFilters(newFilterList);
  };

  const handleChangeMetric = (metric: Metric, selectorIndex: number): void => {
    const newFilters: Filter[] = [...filters];
    if (!newFilters[selectorIndex]) {
      newFilters.push({});
    }
    newFilters[selectorIndex].metric = metric;
    onChangeFilters(newFilters);
  };

  const handleChangeChart = (chart: ChartType, selectorIndex: number): void => {
    const newFilters: Filter[] = [...filters];
    if (!newFilters[selectorIndex]) newFilters.push({});
    newFilters[selectorIndex].chart = chart;
    onChangeFilters(newFilters);
  };

  const handleChangeChartGroup = (
    chart: ChartType,
    selectorIndex: number,
  ): void => {
    const newFilters: Filter[] = [...filters];
    if (!newFilters[selectorIndex]) newFilters.push({});
    newFilters[selectorIndex].chartGroup = chart;
    newFilters[selectorIndex].chart = chart;
    onChangeFilters(newFilters);
  };

  const handleRemoveMetric = (filterIndex: number): void => {
    const newFilters = [...filters];
    newFilters.splice(filterIndex, 1);
    onChangeFilters(newFilters);
  };

  const notIncludedInMetric = (metric: Metric): boolean =>
    !filters.map((element: Filter) => element.metric).includes(metric);
  const isCurrentValue = (metric: Metric, filterIndex: number): boolean =>
    filters[filterIndex].metric === metric;

  const canAddMoreFilters = (filterIndex: number): boolean =>
    filterIndex === filters.length - 1 && allowMoreFilters;

  const canRemoveFilters = filters.length > 1;

  const metricsMetadata = metadata
    .filter((x) => metrics.includes(x.id))
    .map((x) => ({
      text: getMetricDisplayName(x.id, metadata),
      value: x.id,
    }));

  return (
    <div>
      {filters.map((element: Filter, filterIndex: number) => {
        const metricsFiltered = metricsMetadata.filter(
          (metric) =>
            notIncludedInMetric(metric.value) ||
            isCurrentValue(metric.value, filterIndex),
        );

        const enabledChartTypes = charts
          .find((x) => x.value === element.chartGroup)
          ?.charts?.filter((x) => x.enabled);
        return (
          <div
            className="chart-filter"
            key={`filter_${element.metric}_${element.chart}`}
          >
            <Flex horizontal={Horizontal.left} vertical={Vertical.top}>
              <Field
                id={`metric-${element?.chart}-metric`}
                type={Type.select}
                options={metricsFiltered}
                value={filters[filterIndex]?.metric}
                label={LOCALE_METRICS_LABEL}
                onChange={(valueMetric: NewInputValue): void =>
                  handleChangeMetric(valueMetric as Metric, filterIndex)
                }
              />
              <Field
                id={`metric-${element?.chart}-group-chart`}
                type={Type.select}
                options={chartGroups}
                value={
                  filters[filterIndex] &&
                  getChartGroup(charts, filters[filterIndex].chartGroup || '')
                }
                label={LOCALE_CHART_TYPE}
                onChange={(valueChart: NewInputValue): void =>
                  handleChangeChartGroup(valueChart as ChartType, filterIndex)
                }
              />
              {filters[filterIndex].chart &&
              businessRules.canShowGroupCharts ? (
                <Field
                  id={`metric-${element}-chart`}
                  type={Type.select}
                  options={enabledChartTypes}
                  value={filters[filterIndex]?.chart}
                  onChange={(valueChart: NewInputValue): void =>
                    handleChangeChart(valueChart as ChartType, filterIndex)
                  }
                />
              ) : (
                []
              )}
              {canRemoveFilters && (
                <FieldWrapper id={`metric-${element?.chart}-remove`}>
                  <Button
                    kind={ButtonKind.outline}
                    type={ButtonType.button}
                    onClick={(): void => handleRemoveMetric(filterIndex)}
                  >
                    {LOCALE_CHART_REMOVE}
                  </Button>
                </FieldWrapper>
              )}
              {canAddMoreFilters(filterIndex) && (
                <FieldWrapper id={`metric-${element?.chart}-add`}>
                  <Button
                    kind={ButtonKind.outline}
                    type={ButtonType.button}
                    onClick={handleAddFilter}
                  >
                    {LOCALE_CHART_ADD}
                  </Button>
                </FieldWrapper>
              )}
            </Flex>
          </div>
        );
      })}
    </div>
  );
};

export default ChartFilter;
