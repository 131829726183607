import Button, { Kind, Size, Type } from 'components/Button';
import Field from 'components/Field';
import Flex, { Direction, Horizontal } from 'components/Flex';
import H2 from 'components/H2';
import { Type as InputType, NewInputValue } from 'components/Input';
import { IDashboardReport } from 'domains/dashboard/types';
import { ReportDashboardTypes } from 'domains/reports/types';
import { getClass, getTestId } from 'helpers/components';
import useToast from 'hooks/useToast';
import React from 'react';
import { connect } from 'react-redux';
import dashboardActions from 'store/actions/dashboard';
import * as dashboardSelectors from 'store/selectors/dashboard';
import State from 'types/state';
import ITextValue from 'types/textValue';

export const dashboardReportFormComponentName = 'dashboard-report-form';

const LOCALE_EDIT_REPORT_HEADER = 'Edit report';
const LOCALE_ADD_REPORT_HEADER = 'Add report';
const LOCALE_SELECT_REPORT_FIELD_LABEL = 'Select Report';
const LOCALE_NAME_FIELD_LABEL = 'Name';
const LOCALE_TYPE_FIELD_LABEL = 'Type';
const LOCALE_SELECTED_METRIC_FIELD_LABEL = 'Metric';
const LOCALE_SELECTED_METRIC_COLOR_FIELD_LABEL = 'Color';
const LOCALE_LIMIT_FIELD_LABEL = 'Rows per page';
const LOCALE_SELECT_REPORT_FIELD_GOTO_REPORT_LABEL = 'Go to Report';
const LOCALE_USE_REPORT_TITLE = 'Use Report Title';

type OwnState = {
  metricOptionList: ITextValue[];
  typeOptions: ITextValue[];
  dashboardReportForm: IDashboardReport;
  isUpdating: boolean;
};

type Props = {
  dashboardReport: IDashboardReport;
  onAddReport: () => void;
  onCancel: () => void;
  testId?: string;
  reports: ITextValue[];
  onGoToReportClick: (reportId: string) => void;
  handleChangeSelectedReport: (reportId: NewInputValue) => void;
  setFieldValue: (key: string, value: NewInputValue) => void;
  handleReportFormSubmit: () => void;
};

interface OwnProps extends OwnState, Props {}

const DashboardReportForm: React.FunctionComponent<OwnProps> = (
  props: OwnProps,
) => {
  const {
    onCancel,
    dashboardReport,
    onAddReport,
    reports,
    testId,
    onGoToReportClick,
    handleChangeSelectedReport,
    metricOptionList,
    dashboardReportForm,
    typeOptions,
    setFieldValue,
    handleReportFormSubmit,
    isUpdating,
  } = props;

  const { doErrorToast } = useToast();
  const dashboardReportFormClass = getClass(dashboardReportFormComponentName);
  const dashboardReportFormTestId = getTestId(
    dashboardReportFormComponentName,
    testId,
  );
  const dashboardReportFormContentClass = getClass(
    dashboardReportFormComponentName,
    { add: ['content'] },
  );
  const { type, selectVal, reportId, title, limit } = dashboardReportForm;

  const handleChangeType = (val: NewInputValue): void => {
    setFieldValue('type', val);
  };

  const handleChangeMetricColor = (val: NewInputValue): void => {
    setFieldValue('chart_color', `${val}`);
  };

  const handleChangeSelectedMetric = (val: NewInputValue): void => {
    setFieldValue('selectVal', val);
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();
    try {
      await handleReportFormSubmit();
      onAddReport();
    } catch {
      doErrorToast('There has been an error.');
    }
  };

  return (
    <form
      className={dashboardReportFormClass}
      data-testid={dashboardReportFormTestId}
      onSubmit={handleSubmit}
    >
      <Flex direction={Direction.column}>
        <header>
          <H2>
            {dashboardReport?.reportId
              ? LOCALE_EDIT_REPORT_HEADER
              : LOCALE_ADD_REPORT_HEADER}
          </H2>
        </header>
        <section className={dashboardReportFormContentClass}>
          <div>
            <Flex horizontal={Horizontal.left}>
              <Field
                label={LOCALE_SELECT_REPORT_FIELD_LABEL}
                type={InputType.dropdownSearch}
                name="reportId"
                onChange={handleChangeSelectedReport}
                options={reports}
                portalContainerClass="ReactModal__Body--open"
              />
              {reportId && (
                <Button
                  size={Size.small}
                  kind={Kind.primary}
                  onClick={(): void => {
                    onGoToReportClick(reportId);
                  }}
                  disabled={isUpdating}
                >
                  {LOCALE_SELECT_REPORT_FIELD_GOTO_REPORT_LABEL}
                </Button>
              )}
            </Flex>
            <Flex horizontal={Horizontal.left}>
              <Field
                type={InputType.text}
                label={LOCALE_NAME_FIELD_LABEL}
                onChange={(val: NewInputValue): void =>
                  setFieldValue('title', val)
                }
                options={reports}
                value={title}
                placeholder={LOCALE_USE_REPORT_TITLE}
              />
            </Flex>
            <Flex horizontal={Horizontal.left}>
              <Field
                type={InputType.select}
                label={LOCALE_TYPE_FIELD_LABEL}
                onChange={handleChangeType}
                options={typeOptions}
                value={type}
                portalContainerClass="ReactModal__Body--open"
              />
              <Field
                type={InputType.select}
                options={metricOptionList ?? []}
                label={LOCALE_SELECTED_METRIC_FIELD_LABEL}
                value={selectVal}
                disabled={!reportId}
                onChange={(val: NewInputValue): void =>
                  handleChangeSelectedMetric(val)
                }
                portalContainerClass="ReactModal__Body--open"
              />
              <Field
                onChange={handleChangeMetricColor}
                type={InputType.text}
                disabled={type === ReportDashboardTypes.table}
                label={LOCALE_SELECTED_METRIC_COLOR_FIELD_LABEL}
                value={dashboardReportForm.chart_color}
              />
            </Flex>

            <Flex horizontal={Horizontal.left}>
              <Field
                type={InputType.number}
                label={LOCALE_LIMIT_FIELD_LABEL}
                onChange={(val: NewInputValue): void =>
                  setFieldValue('limit', val)
                }
                disabled={type === ReportDashboardTypes.singleStat}
                value={+(limit ?? 0)}
              />
              <Field
                type={InputType.text}
                label="On hover details"
                onChange={(val: NewInputValue): void =>
                  setFieldValue('on_hover_details', val)
                }
                value={dashboardReportForm.on_hover_details}
              />
            </Flex>
          </div>
        </section>
        <footer>
          <Flex horizontal={Horizontal.between}>
            <Button
              kind={Kind.text}
              size={Size.small}
              onClick={onCancel}
              testId={`${dashboardReportFormTestId}-cancel`}
            >
              Cancel
            </Button>
            <Button
              type={Type.submit}
              kind={Kind.primary}
              size={Size.small}
              testId={`${dashboardReportFormTestId}-save`}
              disabled={!reportId || !type || !selectVal || isUpdating}
            >
              Save
            </Button>
          </Flex>
        </footer>
      </Flex>
    </form>
  );
};

const mapStateToProps = (state: State): OwnState => ({
  metricOptionList:
    dashboardSelectors.getDashboardReportFormMetricsOptions(state),
  dashboardReportForm: dashboardSelectors.getDashboardReportForm(state),
  typeOptions: dashboardSelectors.getDashboardReportFormTypeOptions(state),
  isUpdating: dashboardSelectors.isUpdating(state),
});

const mapDispatchToProps = {
  handleChangeSelectedReport: dashboardActions.handleChangeSelectedReport,
  setFieldValue: dashboardActions.dashboardReportFormSetFieldValue,
  handleReportFormSubmit: dashboardActions.handleReportFormSubmit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(DashboardReportForm));
