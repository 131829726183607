import { IDataTarget } from 'domains/reports/types';
import { set } from 'lodash/fp';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';
import ITextValue from 'types/textValue';

export interface ITargetResult {
  targetData?: IDataTarget;
  exportConfigOptions?: ITextValue[];
}

interface ITargetAge {
  data: {
    [key: string]: number;
  };
  rows_dropped: number;
}

interface ITargetUniverse {
  universe: number;
}

interface ITargetGender {
  data: {
    male: number;
    female: number;
  };
  rows_dropped: number;
}

interface ITargetDemographic {
  age: ITargetAge;
  gender: ITargetGender;
  universe: ITargetUniverse[];
}

export interface IExecuteTargetResult {
  genpop: ITargetDemographic;
  target: ITargetDemographic;
  vendors: [];
  universeLabel: string;
}

export const initialState = {
  targetData: {},
  exportConfigOptions: [],
};

const setTargetData = set('targetData');
const setExportConfigOptions = set('exportConfigOptions');

export default (
  targetResult: ITargetResult = initialState,
  action: Action<ITargetResult | ITextValue[]>,
): ITargetResult => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.RESULT_TARGET_RETRIEVED:
      return setTargetData(payload, targetResult);
    case ActionType.RESULT_SET_TARGET_RESULT_EXPORT_CONFIG_OPTIONS:
      return setExportConfigOptions(payload, targetResult);
    case ActionType.RESULT_RESET_TARGET_RESULT:
      return initialState;
    default:
      return targetResult;
  }
};
