import ITextValue from 'types/textValue';

export const calculatePopOverHeight = (
  options: ITextValue[],
  enableHighlight: boolean,
): number => {
  const optionsLength = options.length;
  const uniqueGroups = getUniqueGroups(options);
  let optionsGroupLength = uniqueGroups.size;

  // Calculate additional group length if there are sub-groups
  if (optionsGroupLength <= 10) {
    optionsGroupLength = calculateGroupLengthWithSubGroups(
      uniqueGroups,
      optionsGroupLength,
    );
  }

  const height = calculateHeight(
    optionsLength,
    optionsGroupLength,
    enableHighlight,
  );
  return Math.min(height, 350);
};

/**
 * Extracts unique groups from the options.
 */
function getUniqueGroups(options: ITextValue[]): Set<string> {
  return new Set(
    options
      .map((item) => item.group)
      .filter((group): group is string => group !== undefined),
  );
}

/**
 * Calculates the length of groups including sub-groups.
 */
function calculateGroupLengthWithSubGroups(
  uniqueGroups: Set<string>,
  initialLength: number,
): number {
  let length = initialLength;
  uniqueGroups.forEach((group) => {
    if (group) {
      const subGroups = group.split('|');
      if (subGroups.length > 1) {
        subGroups.forEach((subGroup) => {
          length += 1;
          if (subGroup.length > 10) {
            length += 1;
          }
        });
      }
    }
  });
  return length;
}

/**
 * Calculates the final height based on options length and group length.
 */
function calculateHeight(
  optionsLength: number,
  optionsGroupLength: number,
  enableHighlight: boolean,
): number {
  const lineHeight = 30;
  const paddingTop = 10;
  const heightByGroup =
    optionsGroupLength === 1 && !enableHighlight ? 0 : optionsGroupLength;
  return (optionsLength + heightByGroup) * lineHeight + paddingTop;
}
