import useRoles from 'hooks/useRoles';
import React, { ReactElement } from 'react';
import RolesList from '../../list.feature';

const RolesListContainer = (): ReactElement => {
  const { roles, loading, resetRoles } = useRoles();

  return <RolesList roles={roles} resetList={resetRoles} loading={loading} />;
};

export default RolesListContainer;
