import { getFiltersFromQueryFilters } from 'components/QueryBuilder/adapters';
import IDimension from 'domains/dimensions/types';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import IDateRange from 'types/dateRange';
import {
  AllFilterTypes,
  FilterType,
  IChildrenBase,
  IQueryFilters,
  IQueryFiltersChildren,
  RuleFilter,
  UndigestedFiltersChildren,
} from 'types/filter';

export function getFlightDatesFromQueryFilters(
  filters?: IQueryFiltersChildren | UndigestedFiltersChildren,
): IDateRange[] {
  const dateRangeFilters = filter(
    filters,
    ({ type }: { type: AllFilterTypes }) => type === FilterType.DATETIME_RANGE,
  ) as IDateRange[];

  return dateRangeFilters;
}

export function getAttributionFiltersFromQueryFilters(
  filters: IQueryFilters<IChildrenBase>[],
  filterName: string,
  dateRange = false,
  dimensions?: IDimension[],
): RuleFilter[] | IDateRange[] {
  const selectedFilterSet = filter(filters, ({ name }) => name === filterName);
  if (dateRange) {
    return getFlightDatesFromQueryFilters(selectedFilterSet[0]?.children);
  }

  const result = getFiltersFromQueryFilters(selectedFilterSet[0]?.children).map(
    (item: RuleFilter): RuleFilter => {
      const id = item?.id!;
      const dimension = dimensions?.find((dim) => dim?.id === id)!;
      const description = dimension?.description;
      return description ? { ...item, description } : item;
    },
  );

  return result;
}

export function updateAttributionDateFilterInQueryFilters(
  filters: IQueryFilters[],
  start: string,
  end: string,
  filterName: string,
): IChildrenBase {
  const index = findIndex(filters, ({ name }) => name === filterName);

  const nonDateFilters = getFiltersFromQueryFilters(filters[index].children);
  const dateFilters = getFlightDatesFromQueryFilters(filters[index]?.children);

  dateFilters[0] = {
    ...dateFilters[0],
    start,
    end,
  };

  filters[index].children = [...dateFilters, ...nonDateFilters];

  return filters as IChildrenBase;
}

export function updateNonDateAttributionFiltersFromQueryFilters(
  filters: IQueryFilters[],
  changedFilters: RuleFilter[],
  filterName: string,
): IChildrenBase {
  if (filters.length === 0) {
    return [];
  }
  const index = findIndex(filters, ({ name }) => name === filterName);

  const dateFilters = getFlightDatesFromQueryFilters(filters[index]?.children);

  filters[index].children = [...dateFilters, ...changedFilters];

  return filters as IChildrenBase;
}

//TODO: Recator the function to use filterName string instead of an array and use `getAttributionFiltersFromQueryFilters` method
export function updateAttributionFiltersFromQueryFilters(
  filters: IQueryFilters[],
  changedFilters: RuleFilter[],
  filtersName: string[],
): IChildrenBase {
  const updatedFilter = filters ?? [];
  const filtersIndex = [] as number[];

  filtersName.forEach((filterName: string): void => {
    const index = findIndex(updatedFilter, ({ name }) => name === filterName);
    filtersIndex.push(index);
  });

  filtersIndex.forEach((i: number): void => {
    if (updatedFilter[i]) updatedFilter[i].children = changedFilters;
  });

  return filters as IChildrenBase;
}
