import { prefix as prefixComponents, getTestId } from 'helpers/components';
import React, { FunctionComponent, ReactNode } from 'react';
import Flex from '../../Flex';

export const itemComponentName = 'top-bar-item';

export interface IItemProps {
  testId?: string;
  children?: ReactNode;
}

const Item: FunctionComponent<IItemProps> = ({
  children,
  testId: originalTestId,
}) => {
  const getClassName = (): string => `${prefixComponents}-${itemComponentName}`;

  const testId = getTestId(itemComponentName, originalTestId);
  const className = getClassName();

  return (
    <div className={className} data-testid={testId}>
      <Flex>{children}</Flex>
    </div>
  );
};

export default Item;
