import { compact, omit } from 'lodash';
import { DataRowGraphWithKey } from 'domains/reports/types/index';
import { computeSignificanceValue } from './computeSignificanceValue';
import { checkPermissions } from 'domains/reports/adapters/general';

export const getTableDataWithSignificance = (
  tableData: DataRowGraphWithKey[],
): DataRowGraphWithKey[] => {
  const hasReachPermission = checkPermissions(
    'attribution_reports.campaign.reach::view',
  );
  const hasImpressionPermission = checkPermissions(
    'attribution_reports.campaign.impressions::view',
  );
  const metricsToOmit = compact([
    !hasReachPermission && 'REACH',
    !hasImpressionPermission && 'IMPRESSIONS',
  ]);
  return tableData.map((dataItem) =>
    omit(
      {
        ...dataItem,
        // LIFT DISPLAY_VALUE already has percentage format
        LIFT: {
          VALUE: `${dataItem.LIFT?.VALUE}`,
          DISPLAY_VALUE: `${dataItem.LIFT?.DISPLAY_VALUE}${computeSignificanceValue(
            `${dataItem.P_VALUE?.VALUE}`,
          )}`,
        },
      },
      metricsToOmit,
    ),
  );
};
