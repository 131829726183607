import { DataRowGraph } from 'domains/reports/types';
import { toUpper } from 'lodash/fp';

export const getStandardChartData = (
  data: {
    name: string;
    PC_OF_UNIVERSE: number;
    POPULATION_INDEX: number;
    value: number;
  }[],
  targetTitle: string,
  id: string,
  selectedMetric: string,
): DataRowGraph[] =>
  data.map(({ name, PC_OF_UNIVERSE, POPULATION_INDEX }) => {
    const datum: DataRowGraph = {
      TARGET: { VALUE: targetTitle, DISPLAY_VALUE: targetTitle },
    };
    datum[toUpper(id)] = { VALUE: name, DISPLAY_VALUE: name };

    let selectedValue;
    if (selectedMetric === '% Of Universe') {
      selectedValue = (100 * PC_OF_UNIVERSE).toFixed(1);
    } else {
      selectedValue = (100 * POPULATION_INDEX).toFixed(2);
    }

    datum[selectedMetric] = {
      VALUE: selectedValue,
      DISPLAY_VALUE: selectedValue,
    };
    return datum;
  });
