import Button, { Kind, Type, Size } from 'components/Button';
import Flex from 'components/Flex';
import H1 from 'components/H1';
import Loading from 'components/Loading';
import TargetsList from 'components/ReportsList';
import IReport, { reportType } from 'domains/reports/types';
import { getTestId, getClass } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Actions, getUrl, Index } from 'routes';
import * as rootActions from 'store/actions/root';
import * as userSelectors from 'store/selectors/user';
import IError from 'types/error';
import State from 'types/state';

export const listComponentName = 'targets-list';

const LOCALE_REPORT_LIST_NEW = 'New';
const LOCALE_TARGET_DELETED = 'Audience deleted';
const LOCALE_CANNOT_GET_CURRENT_USER = 'Cannot get currentUser';
const LOCALE_GENERIC_ERROR = 'There has been an error.';
const LOCALE_TARGET_SEARCH_PLACEHOLDER = 'Search for audience';

type ContentProps = {
  createReport: (arg0: string) => void;
  deleteReport: (arg0: IReport) => void;
  editReport: (arg0: IReport) => void;
  loading: boolean;
  setTargetList: (reports: IReport[] | null) => void;
  testId?: string;
  title: string;
  userId: string;
  path: string;
  handleCloneReport: (
    payload: IReport,
    action: Actions.SEGMENT_COPY | Actions.SEGMENT_CLONE,
  ) => void;
};

const List: FunctionComponent<ContentProps> = (props): ReactElement => {
  const {
    createReport,
    deleteReport,
    editReport,
    loading,
    setTargetList,
    testId,
    title,
    userId,
    path,
    handleCloneReport,
  } = props;
  const resetList = (): void => {
    setTargetList(null);
  };

  const navigate = useNavigate();
  const { checkPermissions } = useUserPermissions();
  const { doSuccessToast, doErrorToast } = useToast();
  const { doDelete } = useItemAdmin<IReport>({
    endpoint: `/${Index.SEGMENT_REPORTS}`,
  });

  const gotoCreate = (): void => {
    const createUrl = getUrl([path, Actions.SEGMENT_CREATE]);
    createReport(reportType.target);
    navigate(createUrl);
  };

  const handleUpdateClick = (target: IReport): void => {
    const updateUrl = getUrl([path, Actions.SEGMENT_EDIT, target.id || '']);
    editReport({ ...target, type: reportType.target });
    navigate(updateUrl);
  };

  const handleDeleteClick = (target: IReport): void => {
    doDelete({
      item: target,
      onSuccess: (): void => {
        deleteReport({ ...target, type: reportType.target });
        resetList();
        doSuccessToast(LOCALE_TARGET_DELETED);
      },
      onError: (err: IError): void => {
        doErrorToast(err?.message ?? LOCALE_GENERIC_ERROR);
      },
    });
  };

  const handleCloneClick = (target: IReport): void => {
    if (!userId) {
      doErrorToast(LOCALE_CANNOT_GET_CURRENT_USER);
      return;
    }

    handleCloneReport(
      { ...target, createdBy: userId, type: reportType.target },
      Actions.SEGMENT_CLONE,
    );
  };

  const listTestId = getTestId(listComponentName, testId);
  const listClass = getClass(listComponentName);

  return (
    <div className={listClass} data-testid={listTestId}>
      <header className={`${listClass}-header`}>
        <Flex>
          <H1>{title}</H1>
          {checkPermissions('reports::create') && (
            <Button
              kind={Kind.primary}
              type={Type.button}
              size={Size.small}
              onClick={gotoCreate}
            >
              {LOCALE_REPORT_LIST_NEW}
            </Button>
          )}
        </Flex>
      </header>
      <section>
        {!loading ? (
          <TargetsList
            onUpdateClick={handleUpdateClick}
            onDeleteClick={handleDeleteClick}
            onCloneClick={handleCloneClick}
            placeholder={LOCALE_TARGET_SEARCH_PLACEHOLDER}
            type="target"
          />
        ) : (
          <Loading />
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state: State): { userId: string } => ({
  userId: userSelectors.getId(state),
});

const mapDispatchToProps = {
  createReport: rootActions.createReport,
  deleteReport: rootActions.deleteReport,
  editReport: rootActions.editReport,
  setTargetList: rootActions.setTargetList,
  handleCloneReport: rootActions.handleCloneReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
