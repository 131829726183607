import ActionType from 'store/actions/types';
import { Action } from 'types/action';
import { IDownloadState } from 'types/downloads';

export const initialState = {
  isDownloading: false,
};

export default (
  downloadState = initialState,
  action: Action<Partial<IDownloadState>>,
): IDownloadState => {
  const { type } = action;
  switch (type) {
    case ActionType.DOWNLOAD_STARTED:
      return {
        isDownloading: true,
      };
    case ActionType.DOWNLOAD_FAILED:
    case ActionType.DOWNLOAD_ENDED:
      return {
        isDownloading: false,
      };
    default:
      return downloadState;
  }
};
