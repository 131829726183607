import Button, {
  Type as buttonType,
  Kind as buttonKind,
} from 'components/Button';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { Type as iconType, Color as iconColor } from 'components/Icon';
import { getTestId, getId, getClass } from 'helpers/components';
import useModal from 'hooks/useModal';
import React, { ReactElement, useCallback } from 'react';

export const listActionsComponentName = 'list-actions';

const LOCALE_DELETE_CANCEL_BUTTON_TEXT = 'Cancel';
const LOCALE_DELETE_CONFIRMATION_BUTTON_TEXT = 'Delete';
const LOCALE_DELETE_CONFIRMATION_HEADER = 'Delete item?';
const LOCALE_DELETE_CONFIRMATION_BODY = 'This cannot be undone.';
export interface IListActionsProps<T> {
  testId?: string;
  id?: string;
  item: T;
  canUpdate: boolean;
  canDelete: boolean;
  canClone?: boolean;
  disableUpdate?: boolean;
  disableDelete?: boolean;
  disableClone?: boolean;
  loading?: boolean;
  deleteConfirmationMessage?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onDeleteClick: (item: T) => void;
  onUpdateClick: (item: T) => void;
  onCloneClick?: (item: T) => void;
}

const ListActions = <T,>(props: IListActionsProps<T>): ReactElement => {
  const {
    canClone,
    canDelete,
    canUpdate,
    disableClone,
    disableDelete,
    disableUpdate,
    id,
    item,
    loading = false,
    onCloneClick,
    onDeleteClick,
    onUpdateClick,
    testId,
    deleteConfirmationMessage = LOCALE_DELETE_CONFIRMATION_BODY,
    confirmButtonText = LOCALE_DELETE_CONFIRMATION_BUTTON_TEXT,
    cancelButtonText = LOCALE_DELETE_CANCEL_BUTTON_TEXT,
  } = props;

  const {
    openModal: openRemoveReportConfirmation,
    closeModal,
    isModalOpen,
  } = useModal();

  const listActionClass = getClass(listActionsComponentName);
  const listActionsId = getId(listActionsComponentName, id);
  const listActionsTestId = getTestId(listActionsComponentName, testId);

  const handleUpdateClick = useCallback(() => {
    onUpdateClick(item);
  }, [item, onUpdateClick]);

  const handleOpenInNewTabClick = (): void => {
    window.open(`${window.location.pathname}/edit/${id}`, '_blank');
  };
  const canOpenInNewTab = canUpdate;

  const handleCloneClick = useCallback(() => {
    if (onCloneClick) onCloneClick(item);
  }, [item, onCloneClick]);

  const handleDeleteClick = useCallback(() => {
    onDeleteClick(item);
  }, [item, onDeleteClick]);

  return (
    <div
      className={listActionClass}
      data-testid={listActionsTestId}
      id={listActionsId}
    >
      <ConfirmationDialog
        isOpen={isModalOpen}
        onConfirm={handleDeleteClick}
        onCancel={closeModal}
        header={LOCALE_DELETE_CONFIRMATION_HEADER}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
      >
        <p>{deleteConfirmationMessage}</p>
      </ConfirmationDialog>
      {canUpdate && (
        <Button
          type={buttonType.button}
          kind={buttonKind.icon}
          iconType={iconType.edit}
          iconColor={iconColor.primary}
          onClick={handleUpdateClick}
          testId={`${listActionsTestId}-update`}
          disabled={loading || disableUpdate}
        />
      )}
      {canOpenInNewTab && (
        <Button
          type={buttonType.button}
          kind={buttonKind.icon}
          iconType={iconType.boxArrowUpRight}
          iconColor={iconColor.primary}
          onClick={handleOpenInNewTabClick}
          testId={`${listActionsTestId}-openInNewTab`}
          disabled={loading || disableUpdate}
        />
      )}
      {canClone && (
        <Button
          type={buttonType.button}
          kind={buttonKind.icon}
          iconType={iconType.copy}
          iconColor={iconColor.primary}
          onClick={handleCloneClick}
          testId={`${listActionsTestId}-clone`}
          disabled={loading || disableClone}
        />
      )}
      {canDelete && (
        <Button
          type={buttonType.button}
          kind={buttonKind.icon}
          iconType={iconType.trash}
          iconColor={iconColor.primary}
          onClick={openRemoveReportConfirmation}
          testId={`${listActionsTestId}-delete`}
          disabled={loading || disableDelete}
        />
      )}
    </div>
  );
};

export default ListActions;
