import { numberFormatter } from 'helpers/visualization';
import {
  Point,
  Series,
  SeriesOptionsType,
  TooltipFormatterContextObject,
} from 'highcharts';
import { merge } from 'lodash';
import { isNil } from 'lodash/fp';
import { ITooltipValueFormatters } from './commonChartDesignConfig';

interface IPointMarker {
  height: number;
  radius: number;
  width: number;
}

type IPointExtended = Point & {
  [key: string]: string | number;
} & {
  marker: IPointMarker;
};

export interface ISeriesExtended extends Series {
  isBubble: boolean;
  tooltipOptions: {
    metricsList: string[];
    tooltipValueFormatters: ITooltipValueFormatters;
  };
  setOptions: (options: SeriesOptionsType) => void;
}

export default function pointTooltipFormatter(
  this: TooltipFormatterContextObject,
): string {
  const point = this.point as IPointExtended;
  const series = this.series as ISeriesExtended;

  const formatters = series?.tooltipOptions?.tooltipValueFormatters;
  const metricsList = series?.tooltipOptions?.metricsList;

  if (series?.isBubble) {
    series.setOptions(
      merge({}, series.options, {
        tooltip: {
          distance: point.marker.radius + 6,
        },
      }),
    );
  }
  const metricsListHTMLString =
    metricsList
      ?.map((metric) => {
        const cleanedMetric = metric.includes('_EQ')
          ? metric.replace('_EQ', '')
          : metric;
        const pointMetricName = metric.includes('_EQ')
          ? 'Eq. ' + point[`${cleanedMetric}_name`]
          : point[`${cleanedMetric}_name`];
        if (!isNil(point[cleanedMetric])) {
          return `
            <div class="tooltip-target-item">
              <div class="metric-indicator" style="background:${
                point.color
              }" data-testid="metric-indicator-${point.color}"/></div>
              <div class="metric-title">
                ${pointMetricName ?? 'X'}:
              </div>
              <div class="metric-value">
                ${
                  formatters?.[metric]?.(point[cleanedMetric]) ??
                  numberFormatter(point[cleanedMetric])
                }
              </div>
            </div>
          `;
        }
      })
      .join('') ?? '';

  return `
    <div class="point-tooltip">
      <div class="tooltip-title" data-testid="tooltip-title">
        ${point.name}
      </div>
      <div class="tooltip-separator"></div>
      <div class='tooltip-target-wrapper'>
        ${metricsListHTMLString}
      </div>
    </div>
  `;
}
