import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import React, { MouseEventHandler } from 'react';
import { getClass } from 'helpers/components';

export interface IDropdowndWithHeadersOption {
  text: string;
  handler: () => void;
  header: string;
  format?: string;
}

interface IProps {
  options: IDropdowndWithHeadersOption[];
  label?: string;
  disabled?: boolean;
}

export const componentName = 'dropdown-with-headers';
const componentClass = getClass(componentName);

const DropdownWithHeaders: React.FC<IProps> = ({
  options,
  label = 'Export',
  disabled,
}): React.ReactElement => (
  <div className={componentClass}>
    <Dropdown as={ButtonGroup}>
      <Dropdown.Toggle
        split
        className="br-br-4 br-tr-4"
        id="dropdown-split-basic"
        variant="success"
        disabled={disabled}
      >
        <span data-testid={componentName}>{label}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options?.map((option, index) => {
          const currentHeader = option.header;
          const previousHeader = index === 0 ? '' : options[index - 1].header;
          const renderHeader = currentHeader !== previousHeader;
          if (renderHeader)
            return (
              <div key={`${componentName}-option-with-header-${option.text}`}>
                <Dropdown.Header
                  key={`${componentName}-option-header-${option.header}`}
                  data-testid={`${componentName}-option-header-${option.header}`}
                >
                  {option.header}
                </Dropdown.Header>
                <Dropdown.Item
                  as="li"
                  onClick={option.handler as unknown as MouseEventHandler}
                  key={`${componentName}-option-${option.text}`}
                  data-testid={`${componentName}-option-${index}`}
                >
                  {option.text}
                </Dropdown.Item>
              </div>
            );
          return (
            <Dropdown.Item
              as="li"
              onClick={option.handler as unknown as MouseEventHandler}
              key={`${componentName}-option-${option.text}`}
              data-testid={`${componentName}-option-${index}`}
            >
              {option.text}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

export default DropdownWithHeaders;
