import Button, { Kind } from 'components/Button';
import Dialog from 'components/Dialog';
import Flex, { Horizontal } from 'components/Flex';
import { LOCALE_CANCEL } from 'features/reports/EditFeature/locale';
import { getTestId, getClass } from 'helpers/components';
import React, { ReactNode, FunctionComponent } from 'react';

export const confirmationDialogComponentName = 'confirmation-dialog';

const confirmationDialogTestId = getTestId(confirmationDialogComponentName);

const LOCALE_DEFAULT_CONFIRM_BUTTON_TEXT = 'Ok';
const LOCALE_DEFAULT_HEADER_TEXT = 'Notification';

const DOM_KEY_CONFIRM = 'confirm';
const DOM_KEY_CANCEL = 'cancel';
const DOM_KEY_CONTENT = 'content';
const DOM_KEY_FOOTER = 'footer';

type Props = {
  children: ReactNode;
  customClass?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  header?: string;
  isOpen?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
};

const ConfirmationDialog: FunctionComponent<Props> = (props: Props) => {
  const {
    children,
    customClass,
    confirmButtonText,
    cancelButtonText,
    header,
    isOpen = false,
    onCancel,
    onConfirm,
  } = props;

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>): void => {
    event.persist();
    if (onConfirm && event.key === 'Enter') {
      onConfirm();
    }
  };

  const handleConfirm = (): void => {
    if (onConfirm) onConfirm();
  };

  const handleCancel = (): void => {
    if (onCancel) onCancel();
  };

  const confirmationDialogClass = getClass(confirmationDialogComponentName);
  const confirmationDialogConfirmClass = getClass(
    confirmationDialogComponentName,
    { concat: [DOM_KEY_CONFIRM] },
  );
  const confirmationDialogCancelClass = getClass(
    confirmationDialogComponentName,
    { concat: [DOM_KEY_CANCEL] },
  );
  const confirmationDialogContentClass = getClass(
    confirmationDialogComponentName,
    { concat: [DOM_KEY_CONTENT] },
  );
  const confirmationDialogFooterClass = getClass(
    confirmationDialogComponentName,
    {
      concat: [DOM_KEY_FOOTER],
      boolean: [
        {
          state: !onConfirm && !!onCancel,
          class: 'only-cancel',
        },
        {
          state: !!onConfirm && !onCancel,
          class: 'only-confirm',
        },
      ],
    },
  );

  return (
    <aside className={confirmationDialogClass} onKeyPress={handleKeyPress}>
      <Dialog
        customClass={customClass}
        header={header ?? LOCALE_DEFAULT_HEADER_TEXT}
        footer={
          <div className={confirmationDialogFooterClass}>
            <Flex horizontal={Horizontal.right}>
              {onCancel && (
                <div className={confirmationDialogCancelClass}>
                  <Button
                    kind={Kind.outline}
                    onClick={handleCancel}
                    testId={`${confirmationDialogTestId}-cancel`}
                    upperCase
                  >
                    {cancelButtonText ?? LOCALE_CANCEL}
                  </Button>
                </div>
              )}
              {onConfirm && (
                <div className={confirmationDialogConfirmClass}>
                  <Button
                    kind={Kind.primary}
                    onClick={handleConfirm}
                    testId={`${confirmationDialogTestId}-confirm`}
                    upperCase
                  >
                    {confirmButtonText ?? LOCALE_DEFAULT_CONFIRM_BUTTON_TEXT}
                  </Button>
                </div>
              )}
            </Flex>
          </div>
        }
        isOpen={isOpen}
        onCloseClick={onCancel}
      >
        <div className={confirmationDialogContentClass}>{children}</div>
      </Dialog>
    </aside>
  );
};

export default ConfirmationDialog;
