import Loading from 'components/Loading';
import useClients from 'hooks/useClients';
import useFetch from 'hooks/useFetch';
import useRoles from 'hooks/useRoles';
import useToast from 'hooks/useToast';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Index } from 'routes';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import IUser, { User } from 'types/user';
import UserEdit from '../../edit.feature';

const UsersEditContainer = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser>();
  const { doErrorToast } = useToast();
  const { doFetch } = useFetch<IUser>();
  const { roles, loading: loadingRoles } = useRoles();
  const { clients } = useClients();

  useEffect(() => {
    if (user) return;
    if (id) {
      if (loading || !id) return;
      setLoading(true);
      doFetch({
        endpoint: `/${Index.SEGMENT_USERS}/${id}`,
        method: FetchMethod.GET,
        onSuccess: (res: IUser[]) => {
          setUser(res[0]);
          setLoading(false);
        },
        onError: (e: IError) => {
          doErrorToast(e?.message ?? 'There has been an error.');
          setLoading(false);
        },
      });
    } else {
      setUser(new User());
    }
  }, [doErrorToast, doFetch, id, loading, user]);

  return (
    <>
      {!(loadingRoles || loading) && user ? (
        <UserEdit user={user} roles={roles} clients={clients} />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default UsersEditContainer;
