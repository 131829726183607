import { FieldArray, Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import IDimension from 'domains/dimensions/types';
import Field from 'components/Field';
import { Type as InputType } from 'components/Input';
import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';
import ITextValue from 'types/textValue';
import { DropdownPath } from 'components/DropdownSearch/DropdownPath';
import { getGroupsUserDimensions } from 'helpers/uploader';
import Button, { Kind, Type as ButtonType } from 'components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Flex, { Direction, Horizontal, Vertical } from 'components/Flex';
import { startCase } from 'lodash/fp';
import { lowerCase } from 'lodash';
import Tabs, { ITabPanelProps } from 'components/Tabs';
import Icon, { Color, Size, Type as IconType } from 'components/Icon';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import { toTextValuePairs } from 'helpers/types';
import { IModalDialogProps, IUserDimensionsProps } from './types';
import {
  addCustomValues,
  canUserSave,
  changeHandler,
  getPath,
  handleMultiSelectChange,
  postUserDimension,
  sourceDimensionChangeHandler,
  handleEditCancel,
} from './adapters';
import { ModalDialog } from './components/ModalDialog';
import { validationsSchemaBuilder } from './validationSchema';
import { CustomValuesTabPanel } from './components/CustomValuesTabPanel';
import { CustomValuesTab } from './components/CustomValuesTab';
import {
  ADD_CUSTOM_VALUES_BUTTON_LABEL,
  CANCEL_BUTTON_LABEL,
  CLIENT_LABEL,
  CLIENT_PLACEHOLDER,
  DATASET_ID_LABEL,
  DATASET_NAME_LABEL,
  DATASET_NAME_PLACEHOLDER,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
  FALLBACK_LABEL,
  FALLBACK_PLACEHOLDER,
  FILE_TYPE_LABEL,
  PATH_LABEL,
  PATH_PLACEHOLDER,
  SAVE_BUTTON_LABEL,
  SOURCE_DIMENSION_LABEL,
  SOURCE_DIMENSION_PLACEHOLDER,
} from './locale';
import InlineAlert, { AlertTypes } from '../../../../../components/InlineAlert';

export const UserDimensions = ({
  userDimensionDetails,
  dimensions,
  isEdit,
  fetchDomains,
  clients,
}: IUserDimensionsProps): JSX.Element => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const selectedGroups = useMemo(
    () => getGroupsUserDimensions(dimensions),
    [dimensions],
  );
  const navigate = useNavigate();
  const path = useMemo(() => getPath(isEdit, id), [isEdit, id]);
  const [groups, setGroups] = useState<(string | undefined)[]>([
    ...selectedGroups,
  ]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const sourceDimensions = useMemo(
    () =>
      dimensions.reduce((acc: ITextValue[], dimension: IDimension) => {
        if (dimension.user_dimension_source) {
          return [
            ...acc,
            { text: dimension.name, value: dimension.id } as ITextValue,
          ];
        }
        return acc;
      }, []),
    [],
  );
  const [modalState, setModalState] = useState<IModalDialogProps>({
    isOpen: false,
  });
  return (
    <div className="user-dimension-form">
      <ModalDialog
        isOpen={modalState.isOpen}
        onConfirm={modalState.onConfirm}
        message={modalState.message}
        onClose={modalState.onClose}
        closeLabel={modalState.closeLabel}
        confirmLabel={modalState.confirmLabel}
      />
      <Formik
        initialValues={userDimensionDetails}
        onSubmit={(values) =>
          postUserDimension(values, navigate, setLoading, fetchDomains)
        }
        validationSchema={validationsSchemaBuilder(dimensions)}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          handleBlur,
          dirty,
          setTouched,
        }) => (
          <Form>
            <QueryBuilderItem
              label={FILE_TYPE_LABEL}
              id="type"
              testId="type"
              labelAlignment="top"
            >
              <Field
                type={InputType.select}
                id="type"
                testId="type"
                value={values.type}
                onChange={changeHandler(handleChange, 'type')}
                onBlur={handleBlur}
                name="type"
                errorMessage={errors.type}
                hasError={!!(touched.type && errors.type)}
                disabled
                options={[
                  {
                    text: startCase(lowerCase(values.type)),
                    value: values.type,
                  },
                ]}
              />
            </QueryBuilderItem>
            <QueryBuilderItem
              label={DATASET_ID_LABEL}
              id="dataset_id"
              testId="dataset_id"
              labelAlignment="top"
            >
              <Field
                type={InputType.text}
                id="dataset_id"
                testId="dataset_id"
                value={values.dataset_id}
                onChange={changeHandler(handleChange, 'dataset_id')}
                errorMessage={errors.dataset_id}
                hasError={!!(touched.dataset_id && touched.dataset_id)}
                name="dataset_id"
                onBlur={handleBlur}
                disabled
              />
            </QueryBuilderItem>
            <QueryBuilderItem
              label={DATASET_NAME_LABEL}
              id="user_dimension_name"
              testId="user_dimension_name"
              labelAlignment="top"
            >
              <Field
                type={InputType.text}
                id="user_dimension_name"
                testId="user_dimension_name"
                disabled={loading}
                placeholder={DATASET_NAME_PLACEHOLDER}
                onChange={changeHandler(handleChange, 'user_dimension_name')}
                onBlur={handleBlur}
                value={values.user_dimension_name}
                errorMessage={errors.user_dimension_name}
                hasError={
                  !!(touched.user_dimension_name && errors.user_dimension_name)
                }
                name="user_dimension_name"
              />
            </QueryBuilderItem>
            <QueryBuilderItem
              label={DESCRIPTION_LABEL}
              id="description"
              testId="description"
              labelAlignment="top"
            >
              <Field
                type={InputType.text}
                id="description"
                testId="description"
                disabled={loading}
                onBlur={handleBlur}
                placeholder={DESCRIPTION_PLACEHOLDER}
                value={values.description}
                errorMessage={errors.description}
                hasError={!!(touched.description && errors.description)}
                name="description"
                onChange={changeHandler(handleChange, 'description')}
              />
            </QueryBuilderItem>
            <QueryBuilderItem
              label={CLIENT_LABEL}
              id="client"
              testId="client"
              labelAlignment="top"
            >
              <MultipleDropdownSelect
                name="clients"
                testId="clients"
                disabled={loading}
                placeholder={CLIENT_PLACEHOLDER}
                onBlur={handleBlur}
                selected={values.clients}
                errorText={errors.clients as string}
                isReportInvalid={!!(touched.clients && errors.clients)}
                withSearch
                options={toTextValuePairs(clients)}
                onChange={handleMultiSelectChange(
                  handleChange,
                  touched,
                  setTouched,
                  'clients',
                )}
              />
            </QueryBuilderItem>
            <QueryBuilderItem
              label={PATH_LABEL}
              id="path"
              testId="path"
              labelAlignment="top"
            >
              <Flex
                direction={Direction.column}
                vertical={Vertical.top}
                horizontal={Horizontal.right}
              >
                <DropdownPath
                  stickToBottom
                  id="path"
                  testId="path"
                  hasError={!!(touched.path && errors.path)}
                  groups={groups}
                  selected={values.path}
                  placeholder={PATH_PLACEHOLDER}
                  onBlur={handleBlur}
                  name="path"
                  setSelected={changeHandler(handleChange, 'path')}
                  setGroups={setGroups}
                />
                {touched.path && errors.path && (
                  <InlineAlert
                    message={errors.path}
                    mode={AlertTypes.error}
                    hideClose
                  />
                )}
              </Flex>
            </QueryBuilderItem>
            <QueryBuilderItem
              label={FALLBACK_LABEL}
              id="falback_value"
              testId="falback_value"
              labelAlignment="top"
            >
              <Field
                type={InputType.text}
                id="fallback_value"
                testId="fallback_value"
                disabled={loading}
                onBlur={handleBlur}
                placeholder={FALLBACK_PLACEHOLDER}
                value={values.fallback_value}
                name="fallback_value"
                onChange={changeHandler(handleChange, 'fallback_value')}
              />
            </QueryBuilderItem>
            <QueryBuilderItem
              label={SOURCE_DIMENSION_LABEL}
              id="source_dimension"
              testId="source_dimension"
              labelAlignment="top"
            >
              <Field
                type={InputType.select}
                id="source_dimension"
                testId="source_dimension"
                disabled={loading}
                placeholder={SOURCE_DIMENSION_PLACEHOLDER}
                value={values.source_dimension}
                errorMessage={errors.source_dimension}
                hasError={
                  !!(touched.source_dimension && errors.source_dimension)
                }
                name="source_dimension"
                onChange={sourceDimensionChangeHandler(
                  setModalState,
                  handleChange,
                  values,
                )}
                onBlur={handleBlur}
                options={sourceDimensions}
              />
            </QueryBuilderItem>
            <FieldArray name="custom_values">
              {(arrayFieldHelpers) => (
                <QueryBuilderItem label="Dimension Values" labelAlignment="top">
                  <div>
                    <Button
                      type={ButtonType.button}
                      className="mt-1 mb-2"
                      kind={Kind.linkPrimary}
                      onClick={addCustomValues(
                        values,
                        setModalState,
                        arrayFieldHelpers,
                        setActiveTab,
                      )}
                    >
                      <Icon
                        type={IconType.plus}
                        color={Color.primary}
                        size={Size.medium}
                        customClass="mr-1"
                      />
                      {ADD_CUSTOM_VALUES_BUTTON_LABEL}
                    </Button>
                    {!!values.custom_values?.length && (
                      <Tabs
                        path={path}
                        currentTabIndex={activeTab}
                        reportCurrentTab={setActiveTab}
                        panels={values.custom_values.map(
                          (customValue, index): ITabPanelProps => ({
                            title: (
                              <CustomValuesTab
                                customValue={customValue}
                                index={index}
                                errors={errors}
                                touched={touched}
                              />
                            ),
                            path: '',
                            id: customValue.id ?? '',
                            panelContent: (
                              <CustomValuesTabPanel
                                values={values}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                modalStateSetter={setModalState}
                                setActiveTab={setActiveTab}
                                index={index}
                                errors={errors}
                                setTouched={setTouched}
                                loading={loading}
                                arrayFieldHelpers={arrayFieldHelpers}
                              />
                            ),
                          }),
                        )}
                      />
                    )}
                  </div>
                </QueryBuilderItem>
              )}
            </FieldArray>
            <Flex horizontal={Horizontal.between} className="mt-2">
              <Button
                type={ButtonType.button}
                kind={Kind.outline}
                onClick={() =>
                  handleEditCancel(
                    values,
                    userDimensionDetails,
                    setModalState,
                    navigate,
                  )
                }
              >
                {CANCEL_BUTTON_LABEL}
              </Button>
              <Button
                type={ButtonType.submit}
                kind={Kind.primary}
                disabled={!canUserSave(values, errors, dirty, loading)}
              >
                {loading ? (
                  <Icon color={Color.primary} type={IconType.loading} />
                ) : (
                  SAVE_BUTTON_LABEL
                )}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </div>
  );
};
