import { get, orderBy } from 'lodash';
import { DataRowWithTarget } from '../types';

export const sortData = (
  dataGroup: DataRowWithTarget[],
  sortedBy: string,
  isSortedDesc: boolean,
): DataRowWithTarget[] =>
  orderBy(dataGroup, (data) => get(data, sortedBy)?.VALUE ?? 0, [
    isSortedDesc ? 'desc' : 'asc',
  ]);
