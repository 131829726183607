export const LOCALE_DATASETS_LABEL = 'Datasets';
export const LOCALE_DATASETS_TOOLTIP = 'TV viewership dataset.';

export const LOCALE_DATE_RANGE_LABEL = 'Date Range';
export const LOCALE_DATE_RANGE_TOOLTIP =
  'The time period your results will measure.';

export const LOCALE_RULES_LABEL = 'rules';
export const LOCALE_RULES_LABEL_TOOLTIP = 'Criteria to filter your results by.';

export const LOCALE_BREAKDOWN_TOGGLE_TOOLTIP =
  'Flags whether the report results should show Audience Breakdown chart';

export const LOCALE_HH_FOOTPRINT = 'HH Footprint';
export const LOCALE_HH_FOOTPRINT_PLACEHOLDER = 'Select HH Footprint';
export const LOCALE_HH_TOOLTIP =
  'The HH Footprint defines the set of households that the population will based off of.';
