import ConfirmationDialog from 'components/ConfirmationDialog';
import List from 'components/List';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { isEmpty } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import QueryBuilderItem from './QueryBuilderItem';

const LOCALE_METRIC_TYPE_LABEL = 'Cumulative';
export const LOCALE_CONFIRMATION_DIALOG_TITLE = 'Notification';
export const LOCALE_CONFIRMATION_DIALOG_CONFIRM_LABEL = 'Okay';
export const LOCALE_CONFIRMATION_DIALOG_TEXT_ONE =
  'By selecting this option, all metrics will become cumulative.';
export const LOCALE_CONFIRMATION_DIALOG_TEXT_TWO =
  'The following metrics are not supported and will be removed from the report:';
export const LOCALE_INVALID_VOD_METRICS_MESSAGE =
  'AA and Rating are not valid metrics for VOD and have been removed.';

type Props = {
  checked: boolean;
  name: string;
  onChange: (event: boolean) => void;
  standardOnlySelectedMetricList?: string[];
};

const MetricType: FunctionComponent<Props> = (props) => {
  const { checked, name, onChange, standardOnlySelectedMetricList } = props;

  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);

  const handleOnChange = (checkedEvent: boolean): void => {
    const hasStandardOnlyMetrics = !isEmpty(standardOnlySelectedMetricList);

    if (hasStandardOnlyMetrics && !checked) {
      setConfirmationDialog(true);
    } else {
      onChange(checkedEvent);
    }
  };

  const handleCancel = (): void => setConfirmationDialog(false);

  const handleConfirm = (): void => {
    setConfirmationDialog(false);
    onChange(true);
  };

  return (
    <QueryBuilderItem label={LOCALE_METRIC_TYPE_LABEL} className="large">
      <ConfirmationDialog
        isOpen={confirmationDialog}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        header={LOCALE_CONFIRMATION_DIALOG_TITLE}
        confirmButtonText={LOCALE_CONFIRMATION_DIALOG_CONFIRM_LABEL}
      >
        <p>{LOCALE_CONFIRMATION_DIALOG_TEXT_ONE}</p>
        <p>{LOCALE_CONFIRMATION_DIALOG_TEXT_TWO}</p>
        <List decorator="chevron" items={standardOnlySelectedMetricList} />
      </ConfirmationDialog>
      <ToggleSwitch name={name} checked={checked} onChange={handleOnChange} />
    </QueryBuilderItem>
  );
};

export default MetricType;
