import { isBefore, startOfDay } from 'date-fns';
import { get } from 'lodash/fp';
import { isAttributionReportValid } from '../rulesets/attributionReport';
import IReport, {
  ReportDatasetSelection,
  ReportDatasetsSelections,
  IBaseReport,
  IAttributionReport,
} from '../types';
import {
  hasQueryAdsDatasets,
  hasQueryContentDatasets,
  hasQueryCustomAdsDatasets,
} from './datasets';
import { isAttributionReport, areQueryRulesValid } from './general';

export const isCacheExpired = (reportCreatedTime?: number): boolean => {
  if (!reportCreatedTime) return false;

  const todayBroadcastTime = startOfDay(new Date());
  const created = new Date(reportCreatedTime * 1000);
  const hasPassedBroadcast = isBefore(created, todayBroadcastTime);

  if (hasPassedBroadcast)
    console.info(`Report Cache Stale: Last Ran ${created.toString()}`);
  return hasPassedBroadcast;
};

export const isBaseReportInitiallyExecuted = (report: IBaseReport): boolean =>
  get('notPersistedProps.isBaseReportInitiallyExecuted', report);

export const isBaseReportNew = (report: IBaseReport): boolean =>
  get('notPersistedProps.isBaseReportNew', report);

export const isBaseReportExporting = (report: IBaseReport): boolean =>
  get('notPersistedProps.isBaseReportExporting', report);

export const isBaseReportRunDisabled = (ruleEvents: string[]): boolean =>
  ruleEvents.includes('RUN_BUTTON_DISABLED');

export const isBaseReportSaving = (report: IBaseReport): boolean =>
  get('notPersistedProps.isBaseReportSaving', report);

export const isBaseReportInitialized = (report: IBaseReport): boolean =>
  get('notPersistedProps.isBaseReportInitialized', report);

export const isReportValid = (
  currentBaseReport: IReport,
  datasetSelection: ReportDatasetSelection,
): boolean => {
  if (
    datasetSelection === ReportDatasetsSelections.content &&
    hasQueryContentDatasets(currentBaseReport.query.mode) &&
    areQueryRulesValid(currentBaseReport.query)
  ) {
    return true;
  }

  if (
    datasetSelection === ReportDatasetsSelections.ads &&
    hasQueryAdsDatasets(currentBaseReport.query.mode) &&
    areQueryRulesValid(currentBaseReport.query)
  ) {
    return true;
  }

  return (
    datasetSelection === ReportDatasetsSelections.customAds &&
    hasQueryCustomAdsDatasets(currentBaseReport.query.mode) &&
    areQueryRulesValid(currentBaseReport.query)
  );
};

export const isBaseReportValid = (
  currentBaseReport: IReport,
  datasetSelection: ReportDatasetSelection,
): boolean => {
  if (isAttributionReport(currentBaseReport as IAttributionReport)) {
    return isAttributionReportValid(
      currentBaseReport as IAttributionReport,
      datasetSelection,
    );
  }
  return isReportValid(currentBaseReport as IReport, datasetSelection);
};
