import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';

export const flexComponentName = 'flex';

export enum Vertical {
  top = 'top',
  bottom = 'bottom',
  middle = 'middle',
  between = 'between',
  baseline = 'baseline',
}

export enum Horizontal {
  left = 'left',
  right = 'right',
  between = 'between',
  center = 'center',
}

export enum Direction {
  column = 'column',
  row = 'row',
}

export enum Line {
  multi = 'multi',
}

export interface IFlexProps {
  direction?: Direction;
  vertical?: Vertical;
  horizontal?: Horizontal;
  multiLine?: boolean;
  testId?: string;
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Flex: FunctionComponent<IFlexProps> = (props): ReactElement => {
  const {
    testId,
    multiLine,
    direction,
    vertical,
    horizontal,
    children,
    className,
    style,
  } = props;

  const flexTestId = getTestId(flexComponentName, testId);
  const flexClass = getClass(flexComponentName, {
    text: [direction, vertical, horizontal],
    boolean: [{ state: multiLine, class: Line.multi }],
    add: [className ?? ''],
  });

  return (
    <div className={flexClass} data-testid={flexTestId} style={style}>
      {children}
    </div>
  );
};

export default Flex;
