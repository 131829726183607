import { filterArrayByHideFor } from 'helpers/types';
import { useDispatch, useSelector } from 'react-redux';
import { setDimensions } from 'store/actions/domains';
import State from 'types/state';
import IDimension, { Group as DimensionGroup } from './types';

export type HookReturn = {
  dimensions: IDimension[];
  filteredDimensions: (suppressionConditioners?: string[]) => IDimension[];
  getDimension: (id?: string) => IDimension | undefined;
  getDimensionGroup: (id?: string) => string | undefined;
  getDimensionName: (id?: string) => string | undefined;
  setDimensions: (dimensions: IDimension[]) => void;
  getIsDimensionInGroup: (id?: string, group?: DimensionGroup) => boolean;
};

const useDimensions = (): HookReturn => {
  const dimensions = useSelector(
    ({ domains: { dimensions: dimensionsState = [] } }: State) =>
      dimensionsState,
  );

  const getDimension = (id?: string): IDimension | undefined =>
    dimensions.find((item: IDimension) => item.id === id);

  const getDimensionName = (id?: string): string | undefined => {
    const dimension = getDimension(id);
    return dimension?.name;
  };

  const getDimensionGroup = (id?: string): string | undefined => {
    const dimension = getDimension(id);
    return dimension?.group;
  };

  const getIsDimensionInGroup = (
    id?: string,
    group?: DimensionGroup,
  ): boolean => {
    const dimension = getDimension(id);
    return dimension?.group === group;
  };

  const filteredDimensions = (
    suppressionConditioners?: string[],
  ): IDimension[] => filterArrayByHideFor(dimensions, suppressionConditioners);

  const dispatch = useDispatch();

  return {
    dimensions,
    filteredDimensions,
    getDimension,
    getDimensionName,
    getDimensionGroup,
    setDimensions: (dimensionsToSet: IDimension[]): void => {
      dispatch(setDimensions(dimensionsToSet));
    },
    getIsDimensionInGroup,
  };
};

export default useDimensions;
