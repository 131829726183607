import React, { useMemo } from 'react';
import Domains from 'types/domains';
import IError from 'types/error';
import useCurrentUser from './useCurrentUser';
import useDomains from './useDomains';
import useFetch from './useFetch';
import { Modes } from 'types/query';
import { initialDomainsDateRanges } from 'store/reducers/businessData';

type Props = {
  onSuccess?: () => void;
  onError?: (e: IError) => void;
};

type Return = {
  hasStateLoaded: boolean;
  loading: boolean;
};
const useFetchDomains = (props: Props = {}): Return => {
  const [loading, setLoading] = React.useState(false);
  const { setDomains, setDomainsLoaded } = useDomains();

  const { hasStateLoaded, isLogged, selectedClient } = useCurrentUser();
  const { doFetch } = useFetch<Domains>();

  const { onSuccess, onError } = props;

  const skipFetchingDomains = useMemo(
    () => hasStateLoaded || !isLogged || loading || !selectedClient,
    [hasStateLoaded, isLogged, loading, selectedClient],
  );

  React.useEffect(() => {
    if (skipFetchingDomains) return;
    setLoading(true);

    doFetch({
      endpoint: '/users/domains',
      payload: { client: selectedClient },
      onSuccess: (res) => {
        delete res[0].clients;
        setDomains({
          ...res[0],
          dateRanges: {
            ...res[0].dateRanges,
            [Modes.customAdverts]: initialDomainsDateRanges.MODE_CUSTOM_ADVERTS,
            [Modes.genericEvents]: initialDomainsDateRanges.MODE_GENERIC_EVENTS,
          },
        });
        setDomainsLoaded();
        setLoading(false);
        if (onSuccess) onSuccess();
      },
      onError: (e) => {
        if (onError) onError(e);
      },
    });
  }, [
    doFetch,
    onError,
    onSuccess,
    selectedClient,
    setDomains,
    setDomainsLoaded,
    skipFetchingDomains,
  ]);

  return { hasStateLoaded, loading };
};

export default useFetchDomains;
