import Flex, { Horizontal } from 'components/Flex';
import H2 from 'components/H2';
import { SectionOmissionAlert } from 'components/SectionOmissionAlert';
import IReport, {
  ExposureConversionSetNames,
  ResultBase,
} from 'domains/reports/types';
import IResultSelection from 'domains/resultSelections/types';
import React from 'react';
import { IOption } from 'types/textValue';
import useUserPermissions from 'hooks/useUserPermissions';
import ExposedHouseholdsAndConverters from './components/ExposedHouseholdsAndConverters';
import ExposureConversionLatency from './components/ExposureConversionLatency';

export interface ICampaignExposureProps {
  targets: IOption[];
  chartData: ResultBase;
  omissionMessages: ExposureConversionSetNames[];
  resultSelection: IResultSelection;
  lastValidReport: IReport | null;
  isVisualizationOpened: boolean;
}

const CAMPAIGN_EXPOSURE = 'CAMPAIGN_EXPOSURE';

const rowsUsedInSection: ExposureConversionSetNames[] = [
  'exposed_all.HH_FREQUENCY_cumulative',
  'exposed_converted.AD_LATENCY',
];

export const CampaignExposure: React.FC<ICampaignExposureProps> = ({
  targets,
  chartData,
  omissionMessages,
  resultSelection,
  lastValidReport,
  isVisualizationOpened,
}) => {
  const { checkPermissions } = useUserPermissions();
  return (
    <section id={CAMPAIGN_EXPOSURE}>
      <Flex className="mb-2 mt-4" horizontal={Horizontal.left}>
        <div>
          <H2>Campaign Exposure</H2>
        </div>
        <SectionOmissionAlert
          omissionMessages={omissionMessages}
          rowsUsedInSection={rowsUsedInSection}
        />
      </Flex>
      <div className="row">
        {checkPermissions(
          'attribution_reports.impression_frequency_of_exposed_households::view',
        ) && (
          <div className="col-6">
            <ExposedHouseholdsAndConverters
              targets={targets}
              chartData={chartData}
              sectionFiltersSelection={
                resultSelection.filters?.averageFrequency
              }
              lastValidReport={lastValidReport}
              isVisualizationOpened={isVisualizationOpened}
            />
          </div>
        )}
        {checkPermissions(
          'attribution_reports.ad_exposure_to_conversion_latency::view',
        ) && (
          <div className="col-6">
            <ExposureConversionLatency
              targets={targets}
              chartData={chartData}
              sectionFiltersSelection={resultSelection.filters?.averageLatency}
              lastValidReport={lastValidReport}
            />
          </div>
        )}
      </div>
    </section>
  );
};
