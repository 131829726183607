import { fromUnixTime, isValid, startOfDay } from 'date-fns';
import {
  DataRowGraph,
  ISeriesDataItem,
  ResultBaseSubStructure,
  ResultBaseSubStructureKeys,
} from 'domains/reports/types';
import { numberFormatter } from 'helpers/visualization';

export const getSeriesDataItemWithPerformanceMetric = (
  key: keyof DataRowGraph,
  data?: ResultBaseSubStructure[],
  performanceMetricGroup: ResultBaseSubStructureKeys = 'campaign',
): ISeriesDataItem[] =>
  data?.map((item) => {
    // NOTE: startOfDay allows to place ticks at the center of each day-tick on graph
    const timeValue = startOfDay(fromUnixTime(Number(item.name?.VALUE)));

    const isTimeValueValid = isValid(timeValue);

    return {
      x: isTimeValueValid ? timeValue.getTime() : 0,
      y: Number(item[performanceMetricGroup]?.[key]?.VALUE) || 0,
      custom: {
        displayValue: numberFormatter(
          item[performanceMetricGroup]?.[key]?.VALUE,
          performanceMetricGroup === 'total_sales' ? 'money' : 'number',
        ),
      },
    };
  }) ?? [];
