import ChartOmissionAlert from 'components/ChartOmissionAlert';
import { useMultichartTransformer } from 'components/Multichart/transformers';
import { ResultBase } from 'domains/reports/types';
import createChartConfigWithCommonDesign, {
  IChartConfig,
} from 'helpers/charts/commonChartDesignConfig';
import sharedTooltipFormatter from 'helpers/charts/sharedTooltipFormatter';
import { getClass } from 'helpers/components';
import Highcharts, { Chart as IChart, SeriesBarOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { merge } from 'lodash';
import React, { useMemo, useState } from 'react';
import { IOption } from 'types/textValue';
import {
  getChartConfig,
  IChartTargetItem,
  ITargetFilters,
  mapDataToChartConfig,
} from 'helpers/visualization/CampaignExposure/exposure-conversion-latency';

interface IExposureConversionLatencyChartProps {
  target: IOption[];
  chartData: ResultBase;
  percentileIndex: number;
}

const exposureConversionLatencyChartName = 'exposure-conversion-latency-chart';
const exposureConversionLatencyChartClass = getClass(
  exposureConversionLatencyChartName,
);

const Chart: React.FC<{ chartConfig: IChartConfig }> = ({ chartConfig }) => {
  const [plotBoundary, setPlotBoundary] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  const options = useMemo(
    () =>
      createChartConfigWithCommonDesign(chartConfig, {
        chart: merge({}, chartConfig.chart, {
          events: {
            render() {
              const { plotHeight, plotLeft, plotTop, plotWidth } =
                this as unknown as IChart;
              const newBoundary = {
                top: plotTop as number,
                left: plotLeft as number,
                width: plotWidth as number,
                height: plotHeight as number,
              };

              setPlotBoundary(newBoundary);
            },
          },
        }),
        tooltip: { formatter: sharedTooltipFormatter },
        exporting: {
          buttons: {
            contextButton: {
              x: 5,
              y: 28,
            },
          },
        },
      }),
    [chartConfig],
  );

  const hasOmission = useMemo(() => {
    const doAllSeriesHaveEmptyData = options.series?.every(
      (s) => !(s as SeriesBarOptions).data?.length,
    );

    return doAllSeriesHaveEmptyData;
  }, [options.series]);

  return (
    <div className={exposureConversionLatencyChartClass}>
      {hasOmission && (
        <div
          className={`${exposureConversionLatencyChartClass}__omission-alert`}
          style={plotBoundary}
        >
          <ChartOmissionAlert />
        </div>
      )}
      <HighchartsReact
        allowChartUpdate
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export const ExposureConversionLatencyChart: React.FC<
  IExposureConversionLatencyChartProps
> = ({ target, percentileIndex, chartData }) => {
  const chartConfig = useMultichartTransformer<
    IChartTargetItem,
    ITargetFilters,
    IChartConfig
  >(chartData as ResultBase, {
    filters: {
      target,
      percentileIndex,
    },
    reportResultMapper: mapDataToChartConfig,
    configGetter: getChartConfig,
  });

  return <Chart chartConfig={chartConfig} />;
};
