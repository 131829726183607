import { parseISO } from 'date-fns';
import { isEmpty, get } from 'lodash';
import { formatUS } from '../../domains/reports/adapters/date';
import { DEFAULT_UNIVERSE_NAME } from '../../domains/reports/constants';
import IReport, {
  ReportDatasetsSelections,
  ReportDatasetSelection,
  ResultBase,
  ResultBaseTotalDataTarget,
  SampleGroupNames,
  IHighchartsSeriesData,
  SubTypeKeys,
} from '../../domains/reports/types';
import { UndigestedFilter } from '../../types/filter';
import { Mode, Modes } from '../../types/query';
import IMessage from '../../domains/messageList/types';

export const listTargetsName = (
  targetsIds: string[],
  availableTargets: IReport[],
): string => {
  if (isEmpty(targetsIds) || isEmpty(availableTargets)) return '';
  const result = targetsIds.reduce((acc: string, id: string, index: number) => {
    const targetName =
      availableTargets.find((item) => item.id === id)?.name ??
      DEFAULT_UNIVERSE_NAME;
    return index === targetsIds.length - 1
      ? `${acc} ${targetName}`
      : `${acc} ${targetName},`;
  }, 'Targets:');
  return result;
};

export const getReportDatasetSelection = (
  mode: Mode,
): ReportDatasetSelection => {
  let reportDatasetSelection: ReportDatasetSelection;
  if (mode === Modes.linear) {
    reportDatasetSelection = ReportDatasetsSelections.content;
  } else if (mode === Modes.adverts) {
    reportDatasetSelection = ReportDatasetsSelections.ads;
  } else {
    reportDatasetSelection = ReportDatasetsSelections.customAds;
  }
  return reportDatasetSelection;
};

export const getDateRangeString = (report: IReport | undefined): string => {
  const dateFilter = (
    (report?.query?.filters?.children ?? []) as UndigestedFilter[]
  ).find((item: UndigestedFilter) => item.type === 'DATETIME_RANGE');
  const dateStart = dateFilter?.start ?? '';
  const dateEnd = dateFilter?.end ?? '';
  if (!dateStart || !dateEnd) return '';
  return `Date range: ${formatUS(parseISO(dateStart))} to ${formatUS(
    parseISO(dateEnd),
  )}`;
};

export const getWaterfallChartData = (
  miscResult: ResultBase,
  target: SampleGroupNames,
  sumLabel = 'Full Campaign',
): IHighchartsSeriesData[] => {
  const viewingTypeData = get(
    miscResult,
    'exposed_all.VIEWING_TYPE',
    {},
  ) as ResultBaseTotalDataTarget;
  const waterfallChartData = (viewingTypeData[target] ?? []).map((item) => ({
    y: get(item, 'campaign.INCREMENTAL_REACH.VALUE'),
    name: get(item, 'name.DISPLAY_VALUE'),
    y_name: 'Incremental Reach',
  }));
  return isEmpty(waterfallChartData)
    ? []
    : ([
        ...waterfallChartData,
        {
          name: sumLabel,
          isSum: true,
          y_name: 'Incremental Reach',
        },
      ] as IHighchartsSeriesData[]);
};

export const validateAudienceSelection = (
  report: IReport,
  messageList: IMessage[],
  addWarningMessage: (message: IMessage) => void,
  deleteWarningMessage: (message: IMessage) => void,
): string => {
  let errorMessage = '';
  const reportSubtype = report.subType;
  const isAudienceInsights = reportSubtype === SubTypeKeys.AUDIENCE_INSIGHTS;
  const hasAudiences = !isEmpty(report?.query?.targets ?? []);
  if (isAudienceInsights && !hasAudiences) {
    errorMessage = 'At least one audience required';
    addWarningMessage({
      id: 'target',
      message: errorMessage,
      field: 'Audience',
    });
    return errorMessage;
  }
  if (isAudienceInsights && hasAudiences) {
    errorMessage = '';
    const valueIsInError = messageList.some(
      (m) => m.id === 'target' && m.field === 'Audience',
    );
    if (valueIsInError) {
      deleteWarningMessage({
        id: 'target',
        field: 'Audience',
      });
    }
    return errorMessage;
  }
  return errorMessage;
};
