import { useCallback } from 'react';
import { toast, Slide } from 'react-toastify';

export type hookReturn = {
  doSuccessToast: (message: string) => void;
  doErrorToast: (message: string) => void;
  doInfoToast: (message: string) => void;
  doWarningToast: (message: string) => void;
  doToast: (message: string) => void;
};

const useToast = (): hookReturn => {
  const doSuccessToast = useCallback((message: string): void => {
    toast.success(message, {
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      pauseOnHover: true,
      position: 'bottom-right',
      transition: Slide,
    });
  }, []);

  const doInfoToast = useCallback((message: string): void => {
    toast.info(message, {
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      pauseOnHover: true,
      position: 'bottom-right',
      transition: Slide,
    });
  }, []);

  const doWarningToast = useCallback((message: string): void => {
    toast.warn(message, {
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      pauseOnHover: true,
      position: 'bottom-right',
      transition: Slide,
    });
  }, []);

  const doErrorToast = useCallback((message: string): void => {
    toast.error(message, {
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      pauseOnHover: true,
      position: 'bottom-right',
      transition: Slide,
    });
  }, []);

  const doToast = useCallback((message: string): void => {
    toast(message, {
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      pauseOnHover: true,
      position: 'bottom-right',
      transition: Slide,
    });
  }, []);

  return {
    doSuccessToast,
    doErrorToast,
    doInfoToast,
    doWarningToast,
    doToast,
  };
};

export default useToast;
