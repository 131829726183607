import { getTestId, getId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactNode } from 'react';

export const h2ComponentName = 'h2';

export interface Props {
  testId?: string;
  id?: string;
  children?: ReactNode;
}

const H2: FunctionComponent<Props> = (props) => {
  const { testId, id, children } = props;

  const h2Class = getClass(h2ComponentName);
  const h2Id = getId(h2ComponentName, id);
  const h2TestId = getTestId(h2ComponentName, testId);

  return (
    <h2 className={h2Class} data-testid={h2TestId} id={h2Id}>
      {children}
    </h2>
  );
};

export default H2;
