import { connect, ResolveThunks } from 'react-redux';
import * as processingActions from 'store/actions/processing';
import * as uploadsActions from 'store/actions/uploads';
import * as domainSelector from 'store/selectors/domains';
import * as processingSelectors from 'store/selectors/processing';
import * as uploadsSelector from 'store/selectors/uploads';
import * as userSelector from 'store/selectors/user';
import State from 'types/state';
import { IDataset } from 'domains/datasets/types';
import * as domainsActions from 'store/actions/domains';
import { OwnActions, OwnState, Uploads } from './uploads';

const mapStateToProps = (state: State): OwnState => ({
  uploadFormChanged: uploadsSelector.getUploadsFormChanged(state),
  uploadForm: uploadsSelector.getUploadsForm(state),
  dataProviderOptions: uploadsSelector.dataProviderOptions(state),
  fileTypeOptions: uploadsSelector.fileTypeOptions(state),
  validationErrors: uploadsSelector.validationErrors(state),
  errorMessage: uploadsSelector.errorMessage(state),
  userEmail: userSelector.getEmail(state),
  selectedClient: userSelector.getSelectedClient(state),
  clients: domainSelector.getClients(state),
  dimensions: domainSelector.getDimensions(state),
  uploadPercentage: processingSelectors.getProcessingPercentage(state),
  isLoadingUpdate: uploadsSelector.isLoadingUpdate(state),
  datasetLoading: uploadsSelector.datasetLoading(state),
  crosswalkSources: uploadsSelector.crosswalkSources(state),
  userDimensionDetails: uploadsSelector.userDimensionDetails(state),
  crosswalkCombinationDetails:
    uploadsSelector.crosswalkCombinationDetails(state),
});

const mapDispatchToProps: ResolveThunks<OwnActions> = {
  uploadFormUpdated: uploadsActions.uploadFormUpdated,
  handleValidation: uploadsActions.handleValidation,
  resetErrorMessage: uploadsActions.resetErrorMessage,
  handleFileUpload: uploadsActions.handleFileUpload,
  handleSaveAction: uploadsActions.handleSaveAction,
  uploadFormReset: uploadsActions.uploadFormReset,
  startProcessing: processingActions.startProcessing,
  updatePercentage: processingActions.updatePercentage,
  finishProcessing: processingActions.finishProcessing,
  fetchUploadsMetadata: uploadsActions.fetchUploadsMetadata as unknown as (
    id?: string,
  ) => Promise<IDataset>,
  fetchDomains: domainsActions.fetchDomains as unknown as (
    refresh?: boolean,
  ) => Promise<void>,
  handleUploadSubmit: uploadsActions.handleUploadSubmit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Uploads);
