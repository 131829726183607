import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Button';
import Flex, { Direction, Horizontal, Vertical } from 'components/Flex';
import { getTestId, getClass } from 'helpers/components';
import React from 'react';
import MultipleOptionsIcon from '../MultipleOptionsIcon';

export const segmentedSwitchComponentName = 'segmented-switch';
const segmentedSwitchTestId = getTestId(segmentedSwitchComponentName);
const segmentedSwitchClassName = getClass(segmentedSwitchComponentName);
const segmentedSwitchButtonContentClassName = getClass(
  segmentedSwitchComponentName,
  { concat: ['button-content'] },
);

export interface IProps {
  multipleTargets: boolean;
  onChange: (multipleTargets: boolean) => void;
}

const SegmentedSwitch: React.FC<IProps> = ({ multipleTargets, onChange }) => (
  <Flex
    data-testid={segmentedSwitchTestId}
    direction={Direction.column}
    vertical={Vertical.middle}
    horizontal={Horizontal.center}
    className={segmentedSwitchClassName}
  >
    <Button
      kind={ButtonKind.outline}
      testId={segmentedSwitchTestId}
      type={ButtonType.button}
      onClick={(): void => onChange(false)}
      className={multipleTargets ? '' : 'active'}
    >
      <Flex
        data-testid={segmentedSwitchTestId}
        direction={Direction.column}
        vertical={Vertical.middle}
        horizontal={Horizontal.center}
        className={segmentedSwitchButtonContentClassName}
      >
        {!multipleTargets && <MultipleOptionsIcon />}
        Multiple Behavioral Segments
      </Flex>
    </Button>
    <Button
      kind={ButtonKind.outline}
      testId={segmentedSwitchTestId}
      type={ButtonType.button}
      onClick={(): void => onChange(true)}
      className={multipleTargets ? 'active' : ''}
    >
      <Flex
        data-testid={segmentedSwitchTestId}
        direction={Direction.column}
        vertical={Vertical.middle}
        horizontal={Horizontal.center}
        className={segmentedSwitchButtonContentClassName}
      >
        {multipleTargets && <MultipleOptionsIcon />}
        Multiple Audiences
      </Flex>
    </Button>
  </Flex>
);

export default SegmentedSwitch;
