import Chart from 'components/Chart/index';
import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import Tabs from 'components/Tabs/index';
import {
  LOCALE_NOT_ENOUGH_HH,
  LOCALE_PARTIAL_DATA,
} from 'domains/reports/locales/general';
import {
  ChartTypes,
  DataRowGraph,
  EChartTypes,
  IDataTarget,
  TargetBreakoutValue,
} from 'domains/reports/types';
import { flow, get, identity, keys, omit, sortBy, toUpper } from 'lodash/fp';
import React, { FC, useMemo } from 'react';
import { generateSGMConfig } from '../../domains/reports/adapters/results';
import {
  IOptions,
  OptionXAxis,
  OptionYAxis,
} from '../../domains/reports/types';
import * as constants from './constants';
import * as helpers from './helpers';
import { getStandardChartData } from './utils';

export const notEnoughHHMessageTestId = 'not-enough-hh-test-id';
export const partialDataMessageTestId = 'partial-data-test-id';
const getData = get('data');
const getKeyList = flow(helpers.getTarget, omit('universe'), keys);
const getGroupList = (group: string): ((group: DataRowGraph[]) => string[]) =>
  flow(helpers.getTarget, get(group), keys, sortBy(identity));

interface IBreakdownChartProps {
  id: string;
  data: IDataTarget;
  selectedMetric: string;
  universeName: string;
}

const BreakdownChart: FC<IBreakdownChartProps> = React.memo(
  ({ id, data, selectedMetric, universeName }) => {
    // Iterate through the data from the target stats call, and put it into
    // the same structure as is normally returned from PAPI

    const targetForTitle: TargetBreakoutValue = helpers.getTarget(data)[id];
    const genpopForTitle: TargetBreakoutValue = helpers.getGenPop(data)[id];

    const chartTooltipSerie0Label = `Audience ${constants.LOCALE_DEFAULT_UNIVERSE_NAME}`;
    const chartTooltipSerie1Label = `${universeName} Universe`;
    const targetRows = getStandardChartData(
      getData(targetForTitle),
      'AUDIENCE',
      id,
      selectedMetric,
    );
    const genpopRows = getStandardChartData(
      getData(genpopForTitle),
      'UNIVERSE',
      id,
      selectedMetric,
    );

    let notEnoughHH = false;
    let partialData = false;

    if (targetForTitle.rows_dropped && targetForTitle.rows_dropped > 0) {
      if (targetForTitle.rows_dropped >= targetRows.length) {
        notEnoughHH = true;
      } else {
        partialData = true;
      }
    }

    if (genpopForTitle.rows_dropped && genpopForTitle.rows_dropped > 0) {
      if (genpopForTitle.rows_dropped >= genpopRows.length) {
        notEnoughHH = true;
      } else {
        partialData = true;
      }
    }

    const newData = [...targetRows, ...genpopRows];
    const yAxisMax =
      selectedMetric === constants.LOCAL_UNIVERSE_METRICS ? 100 : undefined;

    const SGMConfig = generateSGMConfig(
      ['TARGET'],
      [toUpper(id)],
      [selectedMetric],
      [ChartTypes.column],
    );

    const categories: string[] = getGroupList(id)(newData);

    const yAxis: OptionYAxis[] = [
      {
        labels: {
          style: { fontSize: '14px' },
        },
        title: {
          text: selectedMetric,
        },
        softMin: yAxisMax,
      },
    ];

    const xAxis: OptionXAxis[] = [
      {
        categories,
        lineWidth: 0,
        labels: { style: { fontSize: '14px' } },
        title: {
          text: targetForTitle.display_name,
        },
      },
    ];

    const config: IOptions = {
      chart: {
        type: EChartTypes.column,
        marginTop: 48,
        style: {
          fontFamily: 'SourceSansProLight',
          fontSize: '14px',
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      legend: {
        align: 'right',
        floating: true,
        verticalAlign: 'top',
        symbolRadius: 0,
      },
      plotOptions: {
        column: {
          colorByPoint: false,
        },
      },
      series: [
        {
          name: chartTooltipSerie0Label,
          color: '#77bd22',
        },
        {
          name: chartTooltipSerie1Label,
          color: '#52a2c9',
        },
      ],
      title: {
        text: '',
      },
      xAxis,
      yAxis,
    };

    return (
      <>
        {notEnoughHH && (
          <div
            data-testid={notEnoughHHMessageTestId}
            className={`${constants.reportFormResultsClass}-not-enough-hh`}
          >
            <InlineAlert
              message={LOCALE_NOT_ENOUGH_HH}
              mode={AlertTypes.warn}
              hideClose
            />
          </div>
        )}
        {partialData && (
          <div
            data-testid={partialDataMessageTestId}
            className={`${constants.reportFormResultsClass}-partial-data`}
          >
            <InlineAlert
              message={LOCALE_PARTIAL_DATA}
              mode={AlertTypes.notify}
            />
          </div>
        )}
        <Chart config={config} data={newData} SGMConfig={SGMConfig} isTarget />
      </>
    );
  },
);

export const BreakdownChartTabs: FC<{
  targetData: IDataTarget;
  selectedMetric: string;
  path: string;
  universeName: string;
  startDate?: string;
  endDate?: string;
}> = React.memo(
  ({ targetData, selectedMetric, path, startDate, endDate, universeName }) => {
    const breakdown = useMemo(
      () =>
        targetData?.target
          ? getKeyList(targetData).map((key) => ({
              title:
                (targetData.target?.[key] as TargetBreakoutValue)
                  .display_name ?? '',
              id:
                (targetData.target?.[key] as TargetBreakoutValue)
                  .display_name ?? '',
              panelContent: (
                <BreakdownChart
                  key={key}
                  id={key}
                  data={targetData}
                  selectedMetric={selectedMetric}
                  universeName={universeName}
                />
              ),
            }))
          : [],
      [targetData, selectedMetric],
    );

    return (
      <Tabs
        panels={breakdown}
        path={path}
        startDate={startDate}
        endDate={endDate}
      />
    );
  },
);
