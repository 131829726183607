import Loading from 'components/Loading';
import useUsers from 'hooks/useUsers';
import React, { ReactElement } from 'react';
import UsersList from '../../list.feature';

const UsersListContainer = (): ReactElement => {
  const { users, loading, resetUsers } = useUsers();

  return (
    <>
      {!loading ? (
        <UsersList users={users} resetList={resetUsers} />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default UsersListContainer;
