import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Button';
import Field from 'components/Field';
import Flex, { Horizontal } from 'components/Flex';
import H1 from 'components/H1';
import { Type as InputType } from 'components/Input';
import { getClass, getId, getTestId } from 'helpers/components';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import State from 'types/state';
import * as configOptionsSelectors from 'store/selectors/configOptions';
import * as configOptionsActions from 'store/actions/configOptions';
import Icon, { Color, Size, Type as IconType } from 'components/Icon';
import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Index } from 'routes';
import Loading from 'components/Loading';
import { IConfigOptionState } from 'domains/configOptions/types';
import ValueCard from './components/ValueCard';
import {
  LOCAL_CONFIG_OPTION_ID_ERROR,
  LOCALE_ACTION_CANCEL,
  LOCALE_ACTION_SAVE,
  LOCALE_ACTION_SAVING,
  LOCALE_CREATE_TITLE,
  LOCALE_EDIT_TITLE,
  LOCALE_FIELD_CONFIG_OPTION_ID,
} from './locale';

interface IConnectedProps {
  updateForm: (payload: string) => void;
  addConfigOptionValueItem: () => void;
  handleSave: (navigate: NavigateFunction) => void;
  loadDetails: (id: string) => void;
  resetForm: () => void;
}

export const testId = 'config-option-test-id';
export const formComponentName = 'config-option-form';
export const editConfigOptionComponentName = 'edit-config-option';

const ConfigOptionEditFeature: FunctionComponent<
  IConfigOptionState & IConnectedProps
> = (props): ReactElement => {
  const {
    form,
    isLoadingDetails,
    isSaving,
    updateForm,
    addConfigOptionValueItem,
    handleSave,
    loadDetails,
    resetForm,
  } = props;
  const navigate = useNavigate();

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    handleSave(navigate);
  };
  const editConfigOptionTestId = getTestId(editConfigOptionComponentName);
  const editConfigOptionDefaultClass = getClass(editConfigOptionComponentName);
  const formId = getId(formComponentName);
  const formTestId = getTestId(formComponentName, testId);
  const formDefaultClass = getClass(formComponentName);
  const { id: configOptionId } = useParams<{ id: string }>();

  useEffect(() => () => resetForm(), []); // reset form when unmounting
  useEffect(() => {
    if (configOptionId && !form.id) loadDetails(configOptionId);
  }, []);

  if (isLoadingDetails || (configOptionId && !form.id)) return <Loading />;

  return (
    <section
      className={editConfigOptionDefaultClass}
      data-testid={editConfigOptionTestId}
    >
      <header className={`${editConfigOptionDefaultClass}-header`}>
        <H1>{form?.id ? LOCALE_EDIT_TITLE : LOCALE_CREATE_TITLE}</H1>
      </header>
      <section className={formDefaultClass} data-testid={formTestId}>
        <form onSubmit={handleSubmit} data-testid={`${formTestId}-form`}>
          <Field
            label={LOCALE_FIELD_CONFIG_OPTION_ID}
            type={InputType.text}
            id={`${formId}-config-option-id`}
            name={`${formId}-input`}
            onChange={(value) => updateForm(value as string)}
            value={form.configOptionId}
            hasError={form.configOptionError}
            errorMessage={LOCAL_CONFIG_OPTION_ID_ERROR}
          />
          <QueryBuilderItem
            label="Values"
            labelAlignment="top"
            labelMinWidth={200}
          >
            <Button
              type={ButtonType.button}
              className="add-value-btn"
              kind={ButtonKind.linkPrimary}
              onClick={addConfigOptionValueItem}
            >
              <Icon
                type={IconType.plus}
                color={Color.primary}
                size={Size.medium}
                customClass="mr-1"
              />
              Add value
            </Button>
            {form.values.map(
              ({ text, value, id, hasTextError, hasValueError }, index) => (
                <ValueCard
                  key={`config-option-value-card-${id}`}
                  text={text}
                  value={value}
                  hasTextError={hasTextError}
                  hasValueError={hasValueError}
                  index={index}
                  canDelete={form.values.length > 1}
                  canMoveDown={index !== form.values.length - 1}
                  canMoveUp={index !== 0}
                />
              ),
            )}
          </QueryBuilderItem>
          <footer>
            <Flex horizontal={Horizontal.between}>
              <Button
                type={ButtonType.button}
                kind={ButtonKind.link}
                onClick={() =>
                  navigate(
                    `${Index.SEGMENT_ADMIN}/${Index.SEGMENT_CONFIG_OPTIONS}`,
                  )
                }
              >
                {LOCALE_ACTION_CANCEL}
              </Button>
              <Button
                type={ButtonType.submit}
                kind={ButtonKind.primary}
                size={ButtonSize.small}
                disabled={isSaving}
              >
                {isSaving ? LOCALE_ACTION_SAVING : LOCALE_ACTION_SAVE}
              </Button>
            </Flex>
          </footer>
        </form>
      </section>
    </section>
  );
};

const mapStateToProps = (state: State): IConfigOptionState => ({
  isLoadingDetails: configOptionsSelectors.isLoadingDetails(state),
  form: configOptionsSelectors.getConfigOptionForm(state),
  isSaving: configOptionsSelectors.isSaving(state),
});

const mapDispatchToProps: IConnectedProps = {
  updateForm: configOptionsActions.updateConfigOptionFormId,
  addConfigOptionValueItem: configOptionsActions.addConfigOptionValueItem,
  handleSave: configOptionsActions.handleSave,
  resetForm: configOptionsActions.resetConfigOptionForm,
  loadDetails: configOptionsActions.loadDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfigOptionEditFeature);
