import { combineReducers } from 'redux';
import admin from './admin';
import attributionReportResult from './attributionReportResult';
import businessData from './businessData';
import clients from './clients';
import dashboard from './dashboard';
import domains from './domains';
import editManyUsers from './editManyUsers';
import events from './events';
import messageList from './messageList';
import processing from './processing';
import {
  reportReducer as report,
  updatedReport,
  savedReport,
  lastValidReport,
} from './report';
import reportResult from './reportResult';
import resultSelection from './resultSelection';
import {
  attributionReportList,
  config,
  loaded,
  reportList,
  targetList,
} from './root';
import targetResult from './targetResult';
import toolbar from './toolbar';
import uploads from './uploads';
import user from './user';
import downloads from './downloads';
import configOptions from './configOptions';

export default combineReducers({
  admin,
  attributionReportList,
  attributionReportResult,
  businessData,
  config,
  domains,
  loaded,
  report,
  reportList,
  messageList,
  reportResult,
  resultSelection,
  targetList,
  targetResult,
  toolbar,
  updatedReport,
  savedReport,
  lastValidReport,
  user,
  events,
  uploads,
  processing,
  clients,
  editManyUsers,
  dashboard,
  downloads,
  configOptions,
});
