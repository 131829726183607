import useClients from 'hooks/useClients';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Actions } from 'routes';
import ClientsEdit from './edit.feature';
import ClientsList from './list.feature';

const Clients: FunctionComponent = () => {
  const { clients } = useClients();
  const { checkPermissions } = useUserPermissions();

  return (
    <>
      <Routes>
        {checkPermissions('clients::create') && (
          <Route path={Actions.SEGMENT_CREATE} element={<ClientsEdit />} />
        )}
        {checkPermissions('clients::update') && (
          <Route
            path={`${Actions.SEGMENT_EDIT}/:id`}
            element={<ClientsEdit />}
          />
        )}
        {checkPermissions('clients::view') && (
          <Route index element={<ClientsList clients={clients} />} />
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};
export default Clients;
