import Button, { Kind } from 'components/Button';
import Flex from 'components/Flex';
import H1 from 'components/H1';
import Icon, { Type as IconType, Color, Size } from 'components/Icon';
import PopoverMenu, { Placement } from 'components/PopoverMenu';
import {
  Dashboard,
  IDashboardReport,
  PanelLayout,
} from 'domains/dashboard/types';
import DashboardPanels from 'features/dashboards/components/Panels';
import { getTestId, getClass } from 'helpers/components';
import useRedirect from 'hooks/useRedirect';
import React, { FunctionComponent, useMemo } from 'react';
import { Index as Routes, Actions } from 'routes';

interface IProps {
  canAddReport: boolean;
  canRemoveDashboard: boolean;
  canRemoveReport: boolean;
  canUpdateDashboard: boolean;
  canUpdateReport: boolean;
  dashboard: Dashboard;
  onLayoutChange?: (layout: PanelLayout[]) => void;
  onReportAdd: () => void;
  onReportRemove: (report: IDashboardReport) => void;
  onReportUpdate: (report: IDashboardReport) => void;
  testId?: string;
}

export const dashboardViewComponentName = 'dashboard-view';

const LOCALE_NO_REPORT_MESSAGE =
  'There are no reports added to your new dashboard dashboard yet.';

export const DOM_KEY_CONFIG_PANEL = 'general-config-panel';
export const DOM_KEY_NO_REPORT_MESSAGE = 'no-reports-message';
export const DOM_KEY_HEADER = 'header';

const DashboardView: FunctionComponent<IProps> = (props) => {
  const {
    dashboard,
    onReportAdd,
    canUpdateReport,
    canAddReport,
    canUpdateDashboard,
    canRemoveDashboard,
    canRemoveReport,
    onReportRemove,
    onReportUpdate,
    onLayoutChange,
  } = props;

  const redirectTo = useRedirect();

  const handleAddReport = (): void => {
    onReportAdd();
  };
  const goToDashboardSettings = (): void =>
    redirectTo(
      `/${Routes.SEGMENT_DASHBOARDS}/${Actions.SEGMENT_EDIT}/${dashboard.id}`,
    );

  const handleLayoutChange = (layout: PanelLayout[]): void => {
    if (onLayoutChange) onLayoutChange(layout);
  };

  const currentLayout = useMemo((): PanelLayout[] | undefined => {
    if (dashboard?.reports?.map) {
      return dashboard?.reports?.map((dashboardReport) => ({
        w: dashboardReport.sizeX,
        h: dashboardReport.sizeY,
        x: dashboardReport.col,
        y: dashboardReport.row,
        i: dashboardReport.id,
      }));
    }

    return undefined;
  }, [dashboard]);

  const contextualMenuOptions = [
    {
      visible: canAddReport,
      key: 'add',
      option: (
        <Button kind={Kind.text} onClick={handleAddReport}>
          Add a report
        </Button>
      ),
    },
    {
      visible: canUpdateDashboard,
      key: 'edit',
      option: (
        <Button kind={Kind.text} onClick={goToDashboardSettings}>
          Edit dashboard
        </Button>
      ),
    },
  ];

  const dashboardViewTestId = useMemo(
    () => getTestId(dashboardViewComponentName, props?.testId),
    [props],
  );
  const dashboardViewClass = useMemo(
    () => getClass(dashboardViewComponentName),
    [],
  );

  const dashboardViewHeaderClass = useMemo(
    () => getClass(dashboardViewComponentName, { concat: [DOM_KEY_HEADER] }),
    [],
  );

  const dashboardViewNoReportMessageClass = useMemo(
    () =>
      getClass(dashboardViewComponentName, {
        concat: [DOM_KEY_NO_REPORT_MESSAGE],
      }),
    [],
  );

  return (
    <div className={dashboardViewClass} data-testid={dashboardViewTestId}>
      <header className={dashboardViewHeaderClass}>
        <Flex>
          <H1>{dashboard?.name}</H1>
          {(canAddReport || canUpdateDashboard || canRemoveDashboard) && (
            <PopoverMenu
              options={contextualMenuOptions}
              placement={Placement.auto}
              wrapperClassName={DOM_KEY_CONFIG_PANEL}
            >
              <Icon type={IconType.cog} size={Size.large} testId="settings" />
            </PopoverMenu>
          )}
        </Flex>
      </header>
      <section>
        {!dashboard?.reports || dashboard?.reports?.length === 0 ? (
          <Flex testId={`${dashboardViewTestId}-no-reports-message`}>
            <div className={dashboardViewNoReportMessageClass}>
              <p>{LOCALE_NO_REPORT_MESSAGE}</p>
              <p>
                Click on the <Icon type={IconType.cog} color={Color.primary} />{' '}
                to add a created report to this dashboard
              </p>
            </div>
          </Flex>
        ) : (
          <DashboardPanels
            defaultLayout={currentLayout}
            draggableHandle=".drag-handle"
            onLayoutChange={handleLayoutChange}
            dashboard={dashboard}
            canUpdateReport={canUpdateReport}
            canUpdateDashboard={canUpdateDashboard}
            canRemoveReport={canRemoveReport}
            onReportRemove={onReportRemove}
            onReportUpdate={onReportUpdate}
          />
        )}
      </section>
    </div>
  );
};

export default DashboardView;
