import Field from 'components/Field';
import Flex, { Direction, Horizontal, Vertical } from 'components/Flex';
import { NewInputValue, Type } from 'components/Input';
import { getCuratedTargets } from 'domains/reports/adapters/general';
import IReport, { BusinessRules } from 'domains/reports/types';
import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import ITextValue from 'types/textValue';
import { getClass } from '../../../../helpers/components';
import { getTextValueArrayFromArray } from '../../../QueryBuilder/adapters';

export interface ITargetFilterProps {
  onChange: (newFieldValue: ITextValue[]) => void;
  selectedTargetList: string[];
  optionList?: IReport[];
  businessRules: BusinessRules;
  canReorder?: boolean;
  hasInterval?: boolean;
}

const MINIMUM_TARGETS = 1;
const LOCALE_LABEL_TARGETS = 'Audiences';
const LOCALE_LABEL_TARGET = 'Audience';
const LOCALE_NO_MULTIPLE_TARGETS =
  'When dimensions, metrics, audiences, and an interval are selected in a combination, only 1 target can be visualized at a time';
const LOCALE_PROMPT = 'Click to add audiences';

export const componentName = 'target-filter';
const DOM_INFO_MESSAGE = 'info-message';

const TargetFilter: FunctionComponent<ITargetFilterProps> = (
  props,
): ReactElement => {
  const {
    onChange,
    selectedTargetList,
    optionList,
    businessRules,
    canReorder = true,
    hasInterval,
  } = props;

  const rootClass = useMemo(() => getClass(componentName), []);
  const infoMessageClass = useMemo(
    () => getClass(componentName, { add: [DOM_INFO_MESSAGE] }),
    [],
  );
  const curatedOptionList = useMemo(
    () => getCuratedTargets(optionList),
    [optionList],
  );
  const valueList = getTextValueArrayFromArray(
    selectedTargetList,
    curatedOptionList,
  );

  return (
    <Flex
      className={rootClass}
      vertical={Vertical.top}
      horizontal={Horizontal.between}
    >
      {(businessRules?.hasSpatialDimensions &&
        !businessRules?.isAllViewing &&
        valueList &&
        valueList?.length > 0) ||
      hasInterval ? (
        <>
          <Flex vertical={Vertical.top} direction={Direction.column}>
            <Field
              type={Type.select}
              value={valueList[0]?.value?.toString()}
              options={curatedOptionList}
              label={LOCALE_LABEL_TARGET}
              onChange={(valueMetric: NewInputValue): void => {
                onChange([
                  {
                    text: valueMetric?.toString(),
                    value: valueMetric?.toString(),
                  },
                ]);
              }}
            />
            <span className={infoMessageClass}>
              {LOCALE_NO_MULTIPLE_TARGETS}
            </span>
          </Flex>
        </>
      ) : (
        <Field
          label={LOCALE_LABEL_TARGETS}
          minLength={MINIMUM_TARGETS}
          multiDropdownValue={valueList}
          multipleDropdownOptions={curatedOptionList}
          onMultipleDropdownChange={onChange}
          placeholder={LOCALE_PROMPT}
          type={Type.multipleDropdownSelect}
          canReorder={canReorder}
        />
      )}
    </Flex>
  );
};

export default TargetFilter;
