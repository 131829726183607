import { getTestId, getId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactNode } from 'react';

export const h1ComponentName = 'h1';

export interface IProps {
  testId?: string;
  id?: string;
  children?: ReactNode;
}

const H1: FunctionComponent<IProps> = (props) => {
  const { testId, id, children } = props;

  const h1Class = getClass(h1ComponentName);
  const h1Id = getId(h1ComponentName, id);
  const h1TestId = getTestId(h1ComponentName, testId);

  return (
    <h1 className={h1Class} data-testid={h1TestId} id={h1Id}>
      {children}
    </h1>
  );
};

export default H1;
