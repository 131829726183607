import Button, { Kind } from 'components/Button';
import { getClass, getTestId } from 'helpers/components';
import React, { ReactElement } from 'react';

export const paginationComponentName = 'pagination';

const DOM_KEY_INDEX = 'index';
const DOM_KEY_ITEMS = 'items';

interface IItem {
  testId?: string;
  className?: string;
  value: string | number;
  onClick: () => void;
}

const Item = (props: IItem): ReactElement => {
  const { testId, className, onClick, value } = props;

  return (
    <Button
      kind={Kind.link}
      testId={testId}
      className={className}
      onClick={onClick}
    >
      {value}
    </Button>
  );
};

export interface IPaginationProps {
  visiblePages: number[];
  pageIndex: number;
  onPaginationIndexClick: (pageNumber: number) => void;
  testId?: string;
}

export const Pagination = (props: IPaginationProps): ReactElement => {
  const { visiblePages, pageIndex, onPaginationIndexClick, testId } = props;

  const paginationClass = getClass(paginationComponentName);
  const paginationItemsClass = getClass(paginationComponentName, {
    concat: [DOM_KEY_ITEMS],
  });
  const paginationTestId = getTestId(paginationComponentName, testId);

  return (
    <footer className={paginationClass} data-testid={paginationTestId}>
      <div className={paginationItemsClass}>
        {visiblePages.map(
          (iterPage: number, index: number, array: number[]) => {
            const paginationIndexClass = getClass(
              `${paginationComponentName}-${DOM_KEY_INDEX}`,
              {
                add: [pageIndex + 1 === iterPage ? 'active' : ''],
              },
            );
            return (
              <span key={iterPage.toString()}>
                <Item
                  value={
                    array[index - 1] + 2 < iterPage
                      ? `...${iterPage}`
                      : iterPage
                  }
                  testId={`${paginationTestId}-item-${index}`}
                  className={paginationIndexClass}
                  onClick={(): void => onPaginationIndexClick(iterPage - 1)}
                />
              </span>
            );
          },
        )}
      </div>
    </footer>
  );
};
