export enum FocusedInput {
  startDate,
  endDate,
}

export enum LastUpdatedDate {
  startDate,
  endDate,
  notUpdated,
}

export type RangeValues = {
  startDate: Date;
  endDate: Date;
  key?: string;
};

export type DatePickerState = {
  selection: RangeValues;
  range1: RangeValues;
};

export enum Anchor {
  left = 'left',
  right = 'right',
}

export interface IDatePickerProps {
  testId?: string;
  id?: string;
  disabled?: boolean;
  startDate?: string;
  endDate?: string;
  relativeDateOffset?: string;
  limitToRange?: boolean;
  handleChange?: (
    startDate: string,
    endDate: string,
    relativeDateOffset?: string,
  ) => void;
  minDate?: string;
  maxDate?: string;
  trackingId?: string;
  showDatePresets?: boolean;
  showSmartSelection?: boolean;
  showFloating?: boolean;
  anchor?: Anchor;
  showNumOfDays?: boolean;
  footerMessage?: string;
  movingDates?: boolean;
  message?: string;
}

export type IUseDatePickerProps = Pick<
  IDatePickerProps,
  | 'startDate'
  | 'endDate'
  | 'disabled'
  | 'handleChange'
  | 'minDate'
  | 'maxDate'
  | 'limitToRange'
  | 'relativeDateOffset'
  | 'trackingId'
>;
