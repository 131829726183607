const color = {
  primary: '#52A2C9',
  primary800: '#a33fff',
  primary600: '#B15DFF',
  primary400: '#d8aafd',
  primary200: '#ebd2ff',
  white: '#ffffff',
  black: '#000000',
};

export const colorChart = {
  bar1: color.primary,
  bar2: '#77bd22',
  bar3: '#5d7f9b',
  bar4: '#00bcd8',
  bar5: '#abd11d',
  bar6: '#6d94b5',
  bar8: '#0255d2',
  bar7: '#029688',
};

export const colorChartSeries = {
  bar1: '#52A2C9',
  bar2: '#77bd22',
  bar3: colorChart.bar3,
  bar4: colorChart.bar4,
  bar5: colorChart.bar5,
  bar6: colorChart.bar6,
  bar7: colorChart.bar7,
  bar8: colorChart.bar8,
};

export const colorDatePicker = {
  range1: '#77bd22',
};

export default color;
