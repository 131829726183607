import match from 'autosuggest-highlight/match';
import { dropdownComponentName } from 'components/Dropdown';
import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import Input, { NewInputValue, Type } from 'components/Input';
import Loading from 'components/Loading';
import OptionsTree from 'components/OptionsTree';
import { Popover } from 'components/Popover';
import { Placement } from 'components/Sticker';
import { getClass, getId, getTestId } from 'helpers/components';
import { trackReportInputChange } from 'helpers/mixpanel';
import { useDebounce } from 'hooks/useDebounce';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { isArray } from 'lodash';
import debounce from 'lodash/debounce';
import React, {
  FocusEvent,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RuleFilter } from 'types/filter';
import ITextValue from 'types/textValue';
import Icon, { Color as IconColor, Type as IconType } from '../Icon';
import { calculatePopOverHeight } from './calculatePopOverHeight';

export const EVENT_DROPDOWN_SEARCH_SHOULD_CLOSE = 'dropdown:should:close';

const featureComponentName = 'feature';
const DOM_KEY_FEATURE_RIGHTSIDE_CONTENT = 'right-side-content';
const featureRightSideContentClass = getClass(featureComponentName, {
  concat: [DOM_KEY_FEATURE_RIGHTSIDE_CONTENT],
});

const dropdownStickerClass = getClass(dropdownComponentName);

const iconLoadingClass = getClass(dropdownComponentName, {
  text: ['icon-loading-wrapper'],
});

const OptionsTreeClass = getClass(dropdownComponentName, {
  text: ['tooltip-wrapper'],
});

export type DropdownSearchQueryProvider = (
  query?: string,
  lineBegin?: number,
  lineEnd?: number,
) => Promise<ITextValue[]> | ITextValue[];

export interface IDropdownSearchProps {
  testId?: string;
  trackingId?: string;
  trackingRuleContext?: {
    filter: RuleFilter;
    index: number;
    parentRuleTracking?: RuleFilter;
  };
  cancelTracking?: boolean;
  id?: string;
  options?: ITextValue[];
  selectedOptions?: string[] | ITextValue[];
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  pageSize?: number;
  onChange?: (newValue: string | number | NewInputValue) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  queryProvider?: DropdownSearchQueryProvider;
  stickToBottom?: boolean;
  enableHighlight?: boolean;
  fixed?: boolean;
  dimensions?: ITextValue[];
  errorText?: string;
  isReportInvalid?: boolean;
  portalContainerClass?: string;
  type?: Type.text | Type.search | Type.select;
  clearOnSelect?: boolean;
  name?: string;
  fetchedOptions?: boolean;
}

export const dropdownSearchComponentName = 'dropdown-search';
export const defaultPlaceholder = 'Search';
export const dropdownSearchIconLoading = 'icon-loading-wrapper';

export enum States {
  error = 'error',
}

const defaultWidth = '100%';

const DropdownSearch: FunctionComponent<IDropdownSearchProps> = (
  props,
): ReactElement => {
  const {
    placeholder = defaultPlaceholder,
    disabled,
    id,
    testId,
    hasError,
    trackingId,
    trackingRuleContext,
    cancelTracking = false,
    onChange,
    onBlur,
    queryProvider,
    pageSize = 10,
    selectedOptions,
    options: initialOptions,
    stickToBottom,
    enableHighlight = false,
    fixed = false,
    dimensions,
    errorText = '',
    isReportInvalid = false,
    portalContainerClass,
    type = Type.text,
    clearOnSelect = false,
    fetchedOptions = false,
    name,
  } = props;

  const [opened, setOpened] = useState(false);
  const [options, setOptions] = useState<ITextValue[]>(initialOptions ?? []);
  const [filterText, setFilterText] = useState('');
  const [queryText, setQueryText] = useState('');
  const [loading, setLoading] = useState(false);
  const [newPageLoading, setNewPageLoading] = useState(false);
  const [selectingOptions, setSelectingOptions] = useState(false);
  const [page, setPage] = useState(1);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const refOptions = useRef<HTMLDivElement>(null);
  const hasFilterTextDefined = useRef<boolean>(false);
  const debouncedValue = useDebounce(
    [fixed, selectedOptions, filterText, queryText, dimensions, opened],
    200,
  );
  const dropdownSearchId = getId(dropdownSearchComponentName, id);
  const dropdownSearchTestId = getTestId(dropdownSearchComponentName, testId);
  const dropdownClass = getClass(dropdownComponentName, {
    boolean: [
      {
        state: hasError,
        class: States.error,
      },
      {
        state: disabled,
        class: 'disabled',
      },
    ],
  });

  const getOptions = (
    initOptions: ITextValue[],
    searchText: string,
  ): ITextValue[] =>
    searchText.length > 1
      ? (initOptions ?? []).filter(
          (item) =>
            match(item.text, searchText, { requireMatchAll: true }).length > 0,
        )
      : initOptions ?? [];

  useEffect(() => {
    if (inputRef?.current && !fixed && !enableHighlight && queryText) {
      inputRef.current.focus();
    }
  }, [options, queryText, enableHighlight, fixed]);

  const delayedQuery = useRef(
    debounce((q: string) => {
      setSelectingOptions(false);
      setQueryText(q);
    }, 750),
  ).current;

  const handleFilterTextChange = useCallback(
    (text: NewInputValue): void => {
      const searchText = text?.toString() ?? '';
      setFilterText(searchText);
      delayedQuery(searchText);
    },
    [delayedQuery],
  );

  useEffect(() => {
    if (opened) return;
    /**
     * FIRE-5009
     * needed to clear field text when
     * selectedOptions is empty
     */
    if (
      !isArray(selectedOptions) ||
      selectedOptions.length !== 1 ||
      !selectedOptions[0]
    ) {
      if (!fixed && filterText) {
        setFilterText('');
      }
      return;
    }

    if (!isArray(dimensions)) return;

    if (!filterText) return;

    const selectedOption = dimensions?.find(
      (dimension) => dimension.value === selectedOptions[0],
    );
    if (!selectedOption) return;

    if (filterText !== selectedOption?.text) {
      setFilterText(selectedOption.text);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!opened && fetchedOptions) return;
    if (selectingOptions) return;
    const runQuery = async (): Promise<void> => {
      if (!newPageLoading) setLoading(true);
      const newItems = queryProvider
        ? await queryProvider(queryText, 0, page * pageSize)
        : getOptions(initialOptions ?? [], queryText);
      setOptions(newItems ?? []);
      setQueryText(queryText);
      setLoading(false);
      setNewPageLoading(false);
      setSelectingOptions(false);
      if (!hasFilterTextDefined.current) {
        hasFilterTextDefined.current = true;
        const text =
          (newItems ?? []).find((item) => item.value === selectedOptions?.[0])
            ?.text ?? '';
        if (!clearOnSelect) handleFilterTextChange(text);
      }
    };

    runQuery();
  }, [
    opened,
    queryText,
    page,
    pageSize,
    queryProvider,
    handleFilterTextChange,
    setQueryText,
  ]);

  useEffect(() => {
    setPage(1);
  }, [queryText]);

  const nextPageOfResults = (): void => {
    setPage((p) => p + 1);
    setSelectingOptions(false);
    setNewPageLoading(true);
  };

  useOnOutsideClick(
    [ref, refOptions],
    useCallback(() => {
      setOpened(false);
      if (!enableHighlight) {
        handleFilterTextChange('');
        setLoading(false);
        setNewPageLoading(false);
        setSelectingOptions(false);
      }
    }, [handleFilterTextChange, enableHighlight]),
  );

  const handleOnChange = (newValue: string | number): void => {
    if (enableHighlight) {
      const text =
        options.find((option) => option.value === newValue)?.text ?? '';
      handleFilterTextChange(clearOnSelect ? '' : text);
      setOpened(false);

      if (!cancelTracking && trackingId)
        trackReportInputChange(
          trackingId,
          newValue,
          text,
          'changed',
          trackingRuleContext,
        );
    }
    setSelectingOptions(true);
    onChange?.(newValue);
  };

  const handleClick = (): false | void => {
    if (!disabled && !fixed) {
      setOpened(true);
      setQueryText('');
    }
  };

  const handleKeyDown = (): false | void => {};

  const handleDropdownShouldClose = useCallback((e: Event): void => {
    e.preventDefault();
    setOpened(false);
  }, []);

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>): void =>
    event.currentTarget.select();

  useEffect((): void => {
    document.addEventListener(
      EVENT_DROPDOWN_SEARCH_SHOULD_CLOSE,
      handleDropdownShouldClose,
    );
  }, [handleDropdownShouldClose]);

  const optionsStyle = {
    width: `${ref.current?.offsetWidth ?? defaultWidth}px`,
  };

  const infiniteScrollHeight = useMemo(
    (): number => calculatePopOverHeight(options, enableHighlight),
    [options, enableHighlight],
  );

  const popoverContent = (): ReactNode => {
    if (loading)
      return (
        <div
          style={{
            padding: '10px',
          }}
        >
          <Loading />
        </div>
      );
    if (fixed) return <></>;
    return (
      <>
        <div className={OptionsTreeClass} style={optionsStyle} ref={refOptions}>
          <InfiniteScroll
            dataLength={options.length}
            next={nextPageOfResults}
            hasMore
            style={{
              height: `${infiniteScrollHeight}px`,
              overflow: 'inherit',
              width: inputRef.current?.getBoundingClientRect?.()?.width,
            }}
            loader=""
            scrollableTarget="scrollingSticker"
          >
            <OptionsTree
              allGroupsExpanded={filterText.length > 1}
              selectedOptions={selectedOptions}
              options={options}
              onClick={handleOnChange}
              highlighted={filterText}
            />
          </InfiniteScroll>
        </div>
      </>
    );
  };

  return (
    <div
      id={dropdownSearchId}
      data-testid={dropdownSearchTestId}
      ref={ref}
      className={dropdownClass}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      title={filterText ?? ''}
    >
      <Popover
        placement={Placement.bottomStart}
        visible={opened}
        controlled
        showArrow={false}
        customStickerClass={dropdownStickerClass}
        stickToBottom={stickToBottom}
        customPortalContainer={
          portalContainerClass
            ? `.${portalContainerClass}`
            : `.${featureRightSideContentClass}`
        }
        matchTriggerWidth
        content={popoverContent()}
      >
        <Input
          ref={inputRef}
          autocomplete={false}
          value={filterText}
          placeholder={placeholder}
          name={name}
          onBlur={onBlur}
          type={type}
          testId={dropdownSearchComponentName}
          onChange={handleFilterTextChange}
          disabled={disabled}
          wrapperStyle={{
            width: defaultWidth,
          }}
          loading={loading || newPageLoading}
          readonly={fixed}
          hasError={!!errorText && isReportInvalid}
          truncateText
          onClick={handleInputClick}
        />
        {(loading || newPageLoading) && (
          <div className={iconLoadingClass}>
            <Icon type={IconType.loading} color={IconColor.primary} />
          </div>
        )}
      </Popover>
      {errorText && isReportInvalid && (
        <InlineAlert mode={AlertTypes.error} message={errorText} hideClose />
      )}
    </div>
  );
};

export default DropdownSearch;
