import {
  DemographicSet,
  ExposureConversionSetNames,
  SegmentSet,
} from 'domains/reports/types';

export const getRowsUsedInSectionDemographic =
  (): ExposureConversionSetNames[] =>
    Object.values(SegmentSet).flatMap((segment) =>
      Object.values(DemographicSet).map(
        (demographic) => `${segment}_by_${demographic}`,
      ),
    ) as ExposureConversionSetNames[];
