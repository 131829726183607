import { getTestId, getId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactNode, useMemo } from 'react';

export const CheckboxesComponentName = 'checkboxes';

export type Props = {
  testId?: string;
  id?: string;
  children?: ReactNode;
};

const CheckboxesName: FunctionComponent<Props> = (props) => {
  const { id, testId, children } = props;

  const CheckboxesClass = useMemo(() => getClass(CheckboxesComponentName), []);
  const CheckboxesId = useMemo(() => getId(CheckboxesComponentName, id), [id]);
  const CheckboxesTestId = useMemo(
    () => getTestId(CheckboxesComponentName, testId),
    [testId],
  );

  return (
    <ul
      className={CheckboxesClass}
      id={CheckboxesId}
      data-testid={CheckboxesTestId}
    >
      {children}
    </ul>
  );
};

export default CheckboxesName;
