import { FormikConfig, FormikErrors, FormikValues, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { RuleFilter } from 'types/filter';

export type hookReturn = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (
    field: string,
    value: number | string | string[] | boolean | RuleFilter,
    shouldValidate?: boolean | undefined,
  ) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  submitCount: number;
  submitted: boolean;
};

export const useForm = <T extends FormikValues>(
  initialValues: T,
  validationSchema: FormikConfig<T>['validationSchema'],
  onSubmit: (values: T) => void,
): hookReturn => {
  const { values, errors, setFieldValue, handleSubmit, submitCount } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: (val) => {
        if (onSubmit) onSubmit(val);
      },
    });

  useEffect(() => {
    const rawKeys = validationSchema.getDefault();
    Object.keys(rawKeys).forEach((field) => {
      setFieldValue(field, (initialValues as Record<string, unknown>)[field]);
    });
  }, [initialValues, setFieldValue, validationSchema]);

  const submitted = !!submitCount;
  return {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    submitCount,
    submitted,
  };
};
