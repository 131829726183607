import Button, { Kind, Type, Size } from 'components/Button';
import Flex from 'components/Flex';
import H1 from 'components/H1';
import ListActions from 'components/ListActions';
import Loading from 'components/Loading';
import Table, { textOverflows, alignments } from 'components/Table';
import IDashboard, { Dashboard } from 'domains/dashboard/types';
import { getTestId, getClass } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Actions, Index as Routes } from 'routes';
import IError from 'types/error';

const DASHBOARD_LIST_PAGE_SIZE = 10;

export const listComponentName = 'dashboards-list';

type ContentProps = {
  dashboards: Dashboard[];
  loading: boolean;
  resetList: () => void;
  setDashboards: (reports: IDashboard[]) => void;
  testId?: string;
  title: string;
};

const List: FunctionComponent<ContentProps> = (props): ReactElement => {
  const { dashboards, loading, resetList, setDashboards, testId, title } =
    props;

  const navigate = useNavigate();
  const { checkPermissions } = useUserPermissions();
  const { doSuccessToast, doErrorToast } = useToast();
  const { doDelete } = useItemAdmin<IDashboard>({
    endpoint: `/${Routes.SEGMENT_DASHBOARDS}`,
  });

  useEffect(() => {
    if (dashboards) {
      setDashboards(dashboards);
    }
  }, [dashboards, setDashboards]);

  const gotoCreate = (): void => {
    navigate(`/${Routes.SEGMENT_DASHBOARDS}/${Actions.SEGMENT_CREATE}`);
  };

  const handleUpdateClick = (dashboard: IDashboard): void => {
    navigate(
      `/${Routes.SEGMENT_DASHBOARDS}/${Actions.SEGMENT_EDIT}/${dashboard.id}`,
    );
  };

  const handleDeleteClick = (dashboard: IDashboard): void => {
    doDelete({
      item: dashboard,
      onSuccess: (): void => {
        resetList();
        doSuccessToast('Dashboard deleted');
      },
      onError: (err: IError): void => {
        doErrorToast(err?.message ?? 'There has been an error.');
      },
    });
  };

  const listTestId = getTestId(listComponentName, testId);
  const listClass = getClass(listComponentName);

  return (
    <div className={listClass} data-testid={listTestId}>
      <header className={`${listClass}-header`}>
        <Flex>
          <H1>{title}</H1>
          {checkPermissions('dashboards::create') && (
            <Button
              kind={Kind.primary}
              type={Type.button}
              size={Size.small}
              onClick={gotoCreate}
            >
              New
            </Button>
          )}
        </Flex>
      </header>
      <section>
        {loading ? (
          <Loading />
        ) : (
          <Table
            filter
            filterPlaceholder="Search for dashboards"
            noResultsMessage="No results found"
            textOverflow={textOverflows.ellipsis}
            editItemSegment={`/${Routes.SEGMENT_DASHBOARDS}/${Actions.SEGMENT_EDIT}`}
            canEditItem={checkPermissions('dashboards::update')}
            alignment={[alignments.left, alignments.right]}
            columns={[
              {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ value }: CellProps<IDashboard>): ReactElement => (
                  <>{value || ''}</>
                ),
              },
              {
                Header: 'Actions',
                Cell: function ListTableActionsCell({
                  row: { original: dashboard },
                }: CellProps<IDashboard>): JSX.Element {
                  return (
                    <ListActions
                      loading={loading}
                      item={dashboard}
                      canUpdate={checkPermissions('dashboards::update')}
                      canDelete={checkPermissions('dashboards::delete')}
                      onDeleteClick={(): void => handleDeleteClick(dashboard)}
                      onUpdateClick={(): void => handleUpdateClick(dashboard)}
                      testId={listTestId}
                      id={dashboard.id}
                    />
                  );
                },
              },
            ]}
            data={dashboards}
            pageSize={DASHBOARD_LIST_PAGE_SIZE}
          />
        )}
      </section>
    </div>
  );
};

export default List;
