import { NewInputValue } from 'components/Input';
import IClient from 'domains/clients/types';
import { LOCALE_DATASET_UPDATE_SUCCESS } from 'features/admin/datasets/edit.feature';
import { CATEGORY_MENU_ROOT_PATH } from 'features/admin/uploads/constants';
import { fetchApi } from 'helpers/fetching';
import { showErrorToast, showSuccessToast } from 'helpers/general';
import { toTextValuePairs } from 'helpers/types';
import { convertValuestoTextValuePairs, Uploader } from 'helpers/uploader';
import { delay } from 'helpers/utils';
import { compact, isEmpty } from 'lodash';
import { NavigateFunction } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Actions, Index } from 'routes';
import * as processingActions from 'store/actions/processing';
import ActionType from 'store/actions/types';
import * as domainSelector from 'store/selectors/domains';
import { getClients, getDatasets } from 'store/selectors/domains';
import * as uploadsSelector from 'store/selectors/uploads';
import * as userSelector from 'store/selectors/user';
import { getSelectedClientExtId } from 'store/selectors/user';
import { Action, PayloadLessAction } from 'types/action';
import FetchMethod from 'types/fetchMethod';
import State from 'types/state';
import ITextValue from 'types/textValue';
import {
  IFormUpdatedPayload,
  IUploaderCallback,
  IUploadForm,
  TAttributesConfig,
  TDatasetInfo,
  TUploadFormFallback,
  UploadStatus,
} from 'types/uploads';
import { v4 as uuid } from 'uuid';
import { fetchDomains, setClients, setDatasets } from '../domains';
import {
  finishProcessing,
  startProcessing,
  updatePercentage,
} from '../processing';
import { get, getOr } from 'lodash/fp';
import {
  DatasetTypes,
  ICrosswalkCombinationDetails,
  IDataset,
  IUserDimensionDetails,
} from '../../../domains/datasets/types';

type Payload = IFormUpdatedPayload;

interface IStatusPayload {
  status: string;
  ingest_messages: string[];
  dataset_metadata: {
    attributes?: TAttributesConfig[];
  };
}

export const FAILED_INGESTION_ERROR_MESSAGE =
  'Upload failed. Please contact account management or ask@605.tv';
export const FAILED_VALIDATION_ERROR_MESSAGE =
  'We had trouble validating the file. Please address the errors below and reselect your segment.';
export const FAILED_SUBMIT_VALIDATION =
  'We had trouble uploading the file. Please try again or contact your account manager.';
export const FAILED_TO_ATTACH_ERROR_MESSAGE =
  'We had trouble uploading the file. Please try again.';
export const FAILED_TO_FIND_IN_S3_ERROR_MESSAGE =
  'This file does not exist at this location';
export const SUCCESS_UPLOAD_SUBMIT_MESSAGE =
  'Your Custom Segment is being processed.';

export const uploadFormUpdated = (payload: Payload): Action<Payload> => ({
  type: ActionType.UPLOADS_FORM_UPDATED,
  payload,
});

export const uploadFormSet = (
  payload: Partial<IUploadForm>,
): Action<Partial<IUploadForm>> => ({
  type: ActionType.UPLOADS_FORM_SET,
  payload,
});

export const uploadFormReset = (): PayloadLessAction => ({
  type: ActionType.UPLOADS_FORM_RESET,
});

export const setValidationErrors = (payload: string[]): Action<string[]> => ({
  type: ActionType.UPLOADS_SET_VALIDATION_ERRORS,
  payload,
});

export const setErrorMessage = (payload: string): Action<string> => ({
  type: ActionType.UPLOADS_SET_ERROR_MESSAGE,
  payload,
});

export const resetErrorMessage = (): PayloadLessAction => ({
  type: ActionType.UPLOADS_RESET_ERROR_MESSAGE,
});

export const startPolling = (): PayloadLessAction => ({
  type: ActionType.UPLOADS_START_POLLING,
});

export const runningPolling = (): PayloadLessAction => ({
  type: ActionType.UPLOADS_RUNNING_POLLING,
});

export const endPolling = (): PayloadLessAction => ({
  type: ActionType.UPLOADS_END_POLLING,
});

export const startUpdating = (): PayloadLessAction => ({
  type: ActionType.UPLOADS_UPDATE_STARTED,
});

export const endUpdating = (): PayloadLessAction => ({
  type: ActionType.UPLOADS_UPDATE_ENDED,
});

export const uploadsLoadStarted = (): PayloadLessAction => ({
  type: ActionType.UPLOADS_LOAD_STARTED,
});

export const uploadsLoadEnded = (): PayloadLessAction => ({
  type: ActionType.UPLOADS_LOAD_ENDED,
});

export const setCrosswalkSources = (
  payload: ITextValue[],
): Action<ITextValue[]> => ({
  type: ActionType.UPLOADS_SET_CROSSWALK_SOURCES,
  payload,
});

export const setUserDimensionDetails = (
  payload: IUserDimensionDetails,
): Action<IUserDimensionDetails> => ({
  type: ActionType.UPLOADS_SET_USER_DIMENSION_DETAILS,
  payload,
});

export const setCrosswalkCombinationDetails = (
  payload: ICrosswalkCombinationDetails,
): Action<ICrosswalkCombinationDetails> => ({
  type: ActionType.UPLOADS_SET_CROSSWALK_COMBINATION_DETAILS,
  payload,
});

export const updateValidationStatusPolling =
  () =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const isPolling = uploadsSelector.isPolling(state);
    const form = uploadsSelector.getUploadsForm(state);
    const version = form.version;

    if (!isPolling) {
      dispatch(startPolling());
    }
    dispatch(runningPolling());
    dispatch(processingActions.startProcessing());

    const versionParam = version && version > 0 ? `?version=${version}` : '';
    const statusResponse = await fetchApi({
      endpoint: `${Index.SEGMENT_DATASETS}/${Actions.SEGMENT_STATUS}/${form.dataset_id}${versionParam}`,
      method: FetchMethod.GET,
    });
    const statusData = statusResponse?.data?.[0] as IStatusPayload;
    const status = statusData?.status as UploadStatus;
    let attributes = statusData?.dataset_metadata?.attributes ?? [];
    const validationErrors = compact(statusData?.ingest_messages ?? []);

    if (statusResponse?.error) {
      dispatch(setErrorMessage(FAILED_TO_ATTACH_ERROR_MESSAGE));
      dispatch(endPolling());
      dispatch(processingActions.finishProcessing());
      return;
    }

    if (
      ![
        UploadStatus.completed,
        UploadStatus.failed,
        UploadStatus.validated,
      ].includes(status)
    ) {
      await delay(3000);
      dispatch(updateValidationStatusPolling());
      return;
    }

    if (status === UploadStatus.failed) {
      dispatch(setErrorMessage(FAILED_VALIDATION_ERROR_MESSAGE));
      dispatch(setValidationErrors(validationErrors));
    }

    if (attributes.length > 0) {
      attributes = attributes.map((attr) => ({
        ...attr,
        path: CATEGORY_MENU_ROOT_PATH,
      }));
    }

    dispatch(
      uploadFormUpdated({
        key: 'dataset_metadata.attributes',
        value: attributes.map((attr) => ({
          ...attr,
          name: '',
        })),
      }),
    );
    dispatch(endPolling());
    dispatch(processingActions.finishProcessing());
  };

// to-do: replace mocks with real logic and unit test it
export const handleValidation =
  (
    form: IUploadForm,
    ingestType: string,
    version?: number,
    skipPathValidation?: boolean,
  ) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    dispatch(setValidationErrors([]));
    dispatch(resetErrorMessage());
    dispatch(processingActions.startProcessing());
    const state = getState();
    const clientId = getSelectedClientExtId(state);
    const validationVersion = version ?? form.version;
    const endpoint = skipPathValidation
      ? `/${Index.SEGMENT_UPLOADS}/${Index.SEGMENT_UPLOADS_CONFIG}?skipPathValidation=true`
      : `/${Index.SEGMENT_UPLOADS}/${Index.SEGMENT_UPLOADS_CONFIG}`;
    const validationResponse = await fetchApi({
      endpoint,
      method: FetchMethod.POST,
      payload: {
        ingestType,
        clientId,
        form: { ...form, version: validationVersion },
      },
    });

    if (validationResponse?.error) {
      dispatch(setErrorMessage(FAILED_TO_FIND_IN_S3_ERROR_MESSAGE));
      dispatch(processingActions.finishProcessing());
      return;
    }

    dispatch(updateValidationStatusPolling());
    return;
  };

export const getCrosswalkSources =
  () =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    const apiResults = await fetchApi({
      endpoint: `/${Index.SEGMENT_DATASETS}/${Actions.SEGMENT_CROSSWALK_SOURCE}`,
      method: FetchMethod.GET,
    });
    dispatch(setCrosswalkSources(apiResults.data as unknown as ITextValue[]));
  };

export const fetchUploadsMetadata =
  (id?: string) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<IDataset | undefined> => {
    const state = getState();
    dispatch(uploadsLoadStarted());
    await dispatch(getCrosswalkSources());

    if (!id) {
      dispatch(uploadsLoadEnded());
      return;
    }
    const selectedDataset = (getDatasets(state) as IDataset[]).find(
      (item) => item.id === id,
    );

    if (!selectedDataset) {
      showErrorToast('Dataset not found');
      return;
    }

    if (selectedDataset.type === DatasetTypes.userDimension) {
      await dispatch(fetchUserDimensionMetadata(id));
    } else if (selectedDataset.type === DatasetTypes.crosswalkCombination) {
      await dispatch(fetchCrossWalkCombination(id));
    } else {
      await dispatch(fetchDatasetMetadata(selectedDataset));
    }

    dispatch(uploadsLoadEnded());
    return selectedDataset;
  };

export const fetchDatasetMetadata =
  (dataset: IDataset) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ) => {
    const state = getState();

    dispatch(
      uploadFormSet({
        status: dataset?.status,
        display_name: dataset.display_name,
        dataset_id: dataset.dataset_id,
        name: dataset.name,
        fileType: dataset.type,
        clientsWithAccess: toTextValuePairs(
          getClients(state).filter((client: IClient): boolean =>
            Boolean(
              client.dataset_rights?.includes(dataset.id) &&
                client.id &&
                client.name,
            ),
          ),
        ),
      }),
    );

    const res = await fetchApi({
      endpoint: `${Index.SEGMENT_DATASETS}/${Actions.SEGMENT_STATUS}/${dataset.dataset_id}`,
      method: FetchMethod.GET,
    });

    if (res.error) {
      showErrorToast(res.error?.message ?? '');
      return;
    }

    const datasetInfo = res.data[0] as TDatasetInfo;
    if (!res.data?.length) {
      showErrorToast('Dataset not found');
      return;
    }

    // Transform plain value array back into ITextValue[]
    const uploadFormFallbackCategory = convertValuestoTextValuePairs(
      datasetInfo?.dataset_metadata?.fallback?.category,
    );
    const uploadFormFallbackChain = convertValuestoTextValuePairs(
      datasetInfo?.dataset_metadata?.fallback?.chain,
    );

    const uploadFormFallback: TUploadFormFallback = {
      dataset:
        !isEmpty(uploadFormFallbackCategory) ||
        !isEmpty(uploadFormFallbackChain)
          ? 'piq'
          : '',
      category: uploadFormFallbackCategory,
      chain: uploadFormFallbackChain,
    };

    const crosswalk_combination = (
      datasetInfo?.dataset_metadata?.crosswalk_combination ?? []
    ).map((crw) => {
      const text = crw.join(',');
      const value = crw.join('+').toLowerCase();
      return {
        text,
        value,
      };
    });
    const genericEventFields = {
      conversion_type: datasetInfo?.dataset_metadata?.conversion_type,
      performance_metric_groups:
        datasetInfo?.dataset_metadata?.performance_metric_groups,
      dataset_path: datasetInfo?.dataset_metadata?.dataset_path,
    };

    dispatch(
      uploadFormSet({
        status: datasetInfo?.status,
        name: datasetInfo?.dataset_id,
        s3Path: datasetInfo?.dataset_metadata?.source_path,
        version: datasetInfo?.dataset_metadata?.version,
        dataset_metadata: {
          attributes: datasetInfo?.dataset_metadata?.attributes ?? [],
          vendor_name: datasetInfo?.dataset_metadata?.vendor_name,
          fallback: uploadFormFallback,
          subType: datasetInfo?.dataset_metadata?.sub_type,
          max_event_date: datasetInfo?.dataset_metadata?.max_event_date ?? '',
          min_event_date: datasetInfo?.dataset_metadata?.min_event_date ?? '',
          ...(datasetInfo?.dataset_metadata?.type === 'generic_events'
            ? genericEventFields
            : {}),
        },
        crosswalk_combination,
      }),
    );
  };

export const handleFileUpload =
  (name: string | undefined, file: File | undefined, uploadForm: IUploadForm) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    if (!file) return;

    const { version } = uploadForm;
    const versionParam = version && version > 0 ? `?version=${version}` : '';
    const { data } = await fetchApi({
      endpoint: `/${Index.SEGMENT_DATASETS}/${Actions.SEGMENT_STATUS}/${uploadForm.dataset_id}${versionParam}`,
      method: FetchMethod.GET,
    });
    const datasetInfo = data as unknown as TDatasetInfo[];
    const newVersion =
      (datasetInfo[0]?.dataset_metadata?.version ?? version) + 1;
    dispatch(
      uploadFormUpdated({
        key: 'version',
        value: newVersion as NewInputValue,
      }),
    );

    let currentPercentage = 0;

    const fileName = file?.name;
    const uploaderOptions = {
      fileName,
      file,
      fileExt: fileName?.split('.')?.pop(),
      name,
      version: newVersion,
    };
    const newUploader = new Uploader(uploaderOptions);
    dispatch(startProcessing('Uploading...'));

    newUploader
      ?.onProgress((callback: IUploaderCallback): void => {
        const newPercentage = callback.percentage;
        if (newPercentage !== currentPercentage) {
          currentPercentage = newPercentage;
          dispatch(updatePercentage(currentPercentage));
        }
      })
      .onSuccess((): void => {
        const uploadFormWithS3Path = {
          ...uploadForm,
          s3Path: newUploader.fileKey,
        };
        dispatch(
          uploadFormUpdated({
            key: 's3Path',
            value: newUploader.fileKey,
          }),
        );
        dispatch(handleValidation(uploadFormWithS3Path, 'validation'));
      })
      .onError((error: unknown): void => {
        console.error(error);
        dispatch(finishProcessing());
      });

    newUploader?.start();
  };

export const handleUploadSubmit =
  (navigate: NavigateFunction) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    const state = getState();
    const uploadForm = uploadsSelector.getUploadsForm(state);
    const { name, s3Source } = uploadForm;
    dispatch(startProcessing('Uploading...'));

    const datasetStatusResponse = await fetchApi({
      endpoint: `/${Index.SEGMENT_DATASETS}/${Actions.SEGMENT_STATUS}/${name}`,
      method: FetchMethod.GET,
    });

    if (datasetStatusResponse?.error) {
      dispatch(
        setErrorMessage(
          datasetStatusResponse?.error?.message ??
            FAILED_INGESTION_ERROR_MESSAGE,
        ),
      );
      dispatch(processingActions.finishProcessing());
      return;
    }

    const datasetInfo = datasetStatusResponse.data as unknown as TDatasetInfo[];
    const latestVersion = Number(datasetInfo[0]?.dataset_metadata?.version) + 1;
    const allClients = domainSelector.getClients(state);
    const selectedClientId = userSelector.getSelectedClient(state);
    const selectedClient = allClients.find(
      (client: IClient) => client.id === selectedClientId,
    );
    const clientId = getSelectedClientExtId(state);
    const endpoint = s3Source
      ? `/${Index.SEGMENT_UPLOADS}/${Index.SEGMENT_UPLOADS_CONFIG}?skipPathValidation=true`
      : `/${Index.SEGMENT_UPLOADS}/${Index.SEGMENT_UPLOADS_CONFIG}`;
    const createJSONConfigResponse = await fetchApi({
      endpoint,
      method: FetchMethod.POST,
      payload: {
        ingestType: 'full',
        clientId,
        form: {
          ...uploadForm,
          version: latestVersion,
          clientsWithAccess: [
            {
              text: selectedClient.name,
              value: selectedClient.id,
            },
          ],
          id: uuid(),
        },
      },
    });

    if (createJSONConfigResponse?.error) {
      dispatch(setErrorMessage(FAILED_SUBMIT_VALIDATION));
      dispatch(processingActions.finishProcessing());
      return;
    }

    dispatch(finishProcessing());
    navigate(`/${Index.SEGMENT_ADMIN}/${Index.SEGMENT_UPLOADS}`);
    setTimeout(() => {
      showSuccessToast(SUCCESS_UPLOAD_SUBMIT_MESSAGE);
    }, 1000);
    dispatch(fetchDomains(true));
  };

export const handleSaveAction =
  (id: string, navigate: NavigateFunction) =>
  async (
    dispatch: ThunkDispatch<State, {}, AnyAction>,
    getState: () => State,
  ): Promise<void> => {
    dispatch(startUpdating());
    const state = getState();
    const uploadsFormConfigFieldsChanged =
      uploadsSelector.getUploadsFormConfigFieldsChanged(state);

    if (uploadsFormConfigFieldsChanged) {
      const uploadForm = uploadsSelector.getUploadsForm(state) as IUploadForm;
      const clientId = getSelectedClientExtId(state);
      const incrementedVersion = uploadForm.version + 1;
      const createJSONConfigResponse = await fetchApi({
        endpoint: `/${Index.SEGMENT_UPLOADS}/${Index.SEGMENT_UPLOADS_CONFIG}?skipPathValidation=true`,
        method: FetchMethod.POST,
        payload: {
          ingestType: 'metadata',
          clientId,
          form: { ...uploadForm, version: incrementedVersion },
        },
      });

      if (createJSONConfigResponse.error) {
        dispatch(endUpdating());
        dispatch(
          setErrorMessage(
            createJSONConfigResponse.error?.message ?? 'Unknown error',
          ),
        );
        return;
      }
    }

    // Mongo updates
    const {
      display_name,
      fileType: type,
      clientsWithAccess,
    } = uploadsSelector.getUploadsForm(state);

    const payload = {
      item: {
        id,
        display_name,
        type,
      },
      params: {
        clientIds: (clientsWithAccess ?? []).map(
          (client: ITextValue) => client.value,
        ),
      },
    };

    const data = await fetchApi({
      endpoint: `/${Index.SEGMENT_DATASETS}`,
      method: FetchMethod.PATCH,
      payload,
    });

    dispatch(endUpdating());

    if (data.error) {
      dispatch(setErrorMessage(data.error?.message ?? 'Unknown error'));
      return;
    }

    dispatch(setDatasets(data.data as IDataset[]));
    if (data.meta?.updated) dispatch(setClients(data.meta?.updated));
    navigate(`/${Index.SEGMENT_ADMIN}/${Index.SEGMENT_UPLOADS}`);
    setTimeout(() => {
      showSuccessToast(LOCALE_DATASET_UPDATE_SUCCESS);
    }, 1000);
    dispatch(fetchDomains());
  };

export const fetchCrossWalkCombination =
  (id: string) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    try {
      const datasetData = await fetchApi({
        endpoint: `${Index.SEGMENT_DATASETS}/${Index.SEGMENT_CROSSWALK_COMBINATION}/${id}`,
        method: FetchMethod.GET,
      });
      if (isEmpty(datasetData?.data)) {
        dispatch(setErrorMessage('Crosswalk combination not found'));
        return;
      }
      const crosswalkCombinationDetails = get(
        'data',
        datasetData,
      ) as unknown as ICrosswalkCombinationDetails;
      dispatch(setCrosswalkCombinationDetails(crosswalkCombinationDetails));
    } catch (e) {
      dispatch(
        setErrorMessage(getOr('Crosswalk combination not found', 'message', e)),
      );
    }
  };

export const fetchUserDimensionMetadata =
  (id?: string) =>
  async (dispatch: ThunkDispatch<State, {}, AnyAction>): Promise<void> => {
    if (id) {
      try {
        const datasetData = await fetchApi({
          endpoint: `${Index.SEGMENT_DATASETS}/${Index.SEGMENT_USER_DIMENSION}/${id}`,
          method: FetchMethod.GET,
        });

        if (isEmpty(datasetData?.data)) {
          dispatch(setErrorMessage('User dimension not found'));
          return;
        }

        const userDimensionDetails = get(
          'data',
          datasetData,
        ) as unknown as IUserDimensionDetails;

        dispatch(
          setUserDimensionDetails({
            user_dimension_id: userDimensionDetails.user_dimension_id,
            id: userDimensionDetails.id,
            dataset_id: userDimensionDetails.dataset_id,
            clients: userDimensionDetails.clients,
            description: userDimensionDetails.description,
            source_dimension: userDimensionDetails.source_dimension,
            custom_values: userDimensionDetails.custom_values,
            path: userDimensionDetails.path,
            user_dimension_name: userDimensionDetails.user_dimension_name,
            fallback_value: userDimensionDetails.fallback_value,
            type: DatasetTypes.userDimension,
          }),
        );
      } catch (e) {
        dispatch(
          setErrorMessage(getOr('User dimension not found', 'message', e)),
        );
      }
    }
  };
