import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Button';
import Checkbox from 'components/Checkbox';
import Field from 'components/Field';
import Flex, { Horizontal } from 'components/Flex';
import { NewInputValue, Type as InputType } from 'components/Input';
import Label from 'components/Label';
import IClient from 'domains/clients/types';
import { getClass, getId, getTestId } from 'helpers/components';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import IRole from 'types/role';
import IUser from 'types/user';

const LOCALE_FIELD_NAME_LABEL = 'Name';
const LOCALE_FIELD_EMAIL_LABEL = 'Email';
const LOCALE_FIELD_ROLES_LABEL = 'Roles';
const LOCALE_FIELD_CLIENTS_LABEL = 'Clients';
const LOCALE_ACTION_OK = 'Save';
const LOCALE_ACTION_CANCEL = 'Cancel';
const LOCALE_ACTION_OK_LOADING = 'Saving...';

export const formComponentName = 'user-form';

// TODO: P2-275: Create <Checkboxes /> component
// Extract there this interface
interface CheckedRoles {
  [roleId: string]: boolean;
}

interface CheckedClients {
  [clientId: string]: boolean;
}
export interface IFormProps {
  testId?: string;
  user?: IUser;
  loading?: boolean;
  roles?: IRole[];
  clients?: IClient[];
  onSubmit: (user: IUser) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<IFormProps> = (props): ReactElement => {
  const { loading, onSubmit, onCancel, testId, roles, clients } = props;

  const { user } = props;

  // TODO: P2-275: Create <Checkboxes /> component
  // Extract there this function
  const { checkPermissions } = useUserPermissions();
  const getDefaultCheckedRolesState = (): CheckedRoles => {
    const defaultState: CheckedRoles = {};

    if (!user?.roles || roles?.length === 0) {
      return defaultState;
    }

    user?.roles?.forEach((userRoleId: string) => {
      roles?.forEach((role) => {
        if (userRoleId && role.id !== userRoleId) {
          defaultState[userRoleId] = false;
        }

        defaultState[userRoleId] = true;
      });
    });

    return defaultState;
  };

  const getDefaultCheckedClientsState = (): CheckedClients => {
    const defaultState: CheckedClients = {};

    if (!user?.clients || clients?.length === 0) {
      return defaultState;
    }

    user?.clients?.forEach((userClientId: string) => {
      clients?.forEach((client) => {
        if (userClientId && client.id !== userClientId) {
          defaultState[userClientId] = false;
        }

        defaultState[userClientId] = true;
      });
    });

    return defaultState;
  };

  const defaultCheckedRolesState = getDefaultCheckedRolesState();
  const defaultCheckedClientsState = getDefaultCheckedClientsState();

  const [checkedRolesState, setCheckedRolesState] = useState(
    defaultCheckedRolesState,
  );
  const [checkedClientsState, setCheckedClientsState] = useState(
    defaultCheckedClientsState,
  );
  const [name, setName] = useState(user?.name ?? '');
  const [email, setEmail] = useState(user?.email ?? '');

  const getCheckedRoleStateById = (id: string): boolean => {
    if (!checkedRolesState[id]) return false;
    return checkedRolesState[id];
  };

  const getCheckedClientsStateById = (id: string): boolean => {
    if (!checkedClientsState[id]) return false;
    return checkedClientsState[id];
  };

  const handleNameChange = (newName: NewInputValue): void => {
    setName(newName as string);
  };

  const handleEmailChange = (newEmail: NewInputValue): void => {
    setEmail(newEmail as string);
  };

  const handleRoleChange = (newCheckState: NewInputValue, id: string): void => {
    setCheckedRolesState({
      ...checkedRolesState,
      [id]: newCheckState as boolean,
    });
  };

  const handleClientChange = (
    newCheckState: NewInputValue,
    id: string,
  ): void => {
    setCheckedClientsState({
      ...checkedClientsState,
      [id]: newCheckState as boolean,
    });
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();

    const filteredRoles = Object.keys(checkedRolesState).filter(
      (checkedRoleId: string) => checkedRolesState[checkedRoleId],
    );

    const filteredClients = Object.keys(checkedClientsState).filter(
      (checkedClientId: string) => checkedClientsState[checkedClientId],
    );

    onSubmit({
      roles: filteredRoles || [],
      clients: filteredClients || [],
      name,
      email,
    });
  };
  const formId = getId(formComponentName);
  const formTestId = getTestId(formComponentName, testId);
  const rolesTestId = getTestId(formComponentName, 'roles');
  const clientsTestId = getTestId(formComponentName, 'clients');
  const formDefaultClass = getClass(formComponentName);
  const hasRoles = roles && roles.length > 0;
  const hasClients = clients && clients.length > 0;

  return (
    <section className={formDefaultClass} data-testid={formTestId}>
      <form onSubmit={handleSubmit} data-testid={`${formTestId}-form`}>
        <Field
          label={LOCALE_FIELD_NAME_LABEL}
          type={InputType.text}
          id={`${formId}-name`}
          name={`${formId}-input`}
          onChange={handleNameChange}
          value={name}
        />
        <Field
          label={LOCALE_FIELD_EMAIL_LABEL}
          type={InputType.text}
          id={`${formId}-input`}
          name={`${formId}-email`}
          onChange={handleEmailChange}
          value={email}
          disabled={!!user?.id}
        />
        {hasRoles && checkPermissions('users.roles::update') && (
          <>
            <Label text={LOCALE_FIELD_ROLES_LABEL} />
            <ul
              className={`${formDefaultClass}-roles`}
              data-testid={rolesTestId}
            >
              {roles?.sort().map((role: IRole) => (
                <li key={role.id} className={`${formDefaultClass}-role`}>
                  <Checkbox
                    testId={`${formTestId}-role-${role?.id}`}
                    checked={getCheckedRoleStateById(role?.id)}
                    label={role?.name}
                    onChange={(newCheckState: NewInputValue): void =>
                      handleRoleChange(newCheckState, role?.id)
                    }
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {hasClients && checkPermissions('users.clients::update') && (
          <>
            <Label text={LOCALE_FIELD_CLIENTS_LABEL} />
            <ul
              className={`${formDefaultClass}-clients`}
              data-testid={clientsTestId}
            >
              {clients?.sort().map((client: IClient) => (
                <li key={client.id} className={`${formDefaultClass}-client`}>
                  <Checkbox
                    testId={`${formTestId}-client-${client?.id}`}
                    checked={getCheckedClientsStateById(client?.id ?? '')}
                    label={client?.name}
                    onChange={(newCheckState: NewInputValue): void =>
                      handleClientChange(newCheckState, client?.id ?? '')
                    }
                  />
                </li>
              ))}
            </ul>
          </>
        )}

        <footer>
          <Flex horizontal={Horizontal.between}>
            <Button
              type={ButtonType.button}
              kind={ButtonKind.link}
              onClick={onCancel}
            >
              {LOCALE_ACTION_CANCEL}
            </Button>
            <Button
              type={ButtonType.submit}
              kind={ButtonKind.primary}
              size={ButtonSize.small}
              disabled={loading}
            >
              {loading ? LOCALE_ACTION_OK_LOADING : LOCALE_ACTION_OK}
            </Button>
          </Flex>
        </footer>
      </form>
    </section>
  );
};

export default Form;
