import Flex, { Horizontal, Vertical } from 'components/Flex';
import { Type as IconType } from 'components/Icon';
import { MultiseriesChart } from 'components/Multichart';
import { SampleLegend } from 'components/Multichart/components/SampleLegend';
import { useMultichartTransformer } from 'components/Multichart/transformers';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import SegmentedControl from 'components/SegmentedControl';
import IReport, {
  PerformanceGroupNames,
  ResultBase,
} from 'domains/reports/types';
import { IResultSelectionFilters } from 'domains/resultSelections/types';
import { getClass } from 'helpers/components';
import { getPersistedTargets } from 'helpers/resultSelection/getPersistedTargets';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as resultSelectionsActions from 'store/actions/resultSelections';
import ITextValue, { IOption } from 'types/textValue';
import { ChartHelper } from '../components/ChartHelper';
import {
  ChartFilterActions,
  chartFiltersReducer,
} from './reducer/chartFiltersReducer';
import {
  AggregationMethods,
  getChartConfig,
  IChartTargetItem,
  IMultiseriesTargetAndAxisFilters,
  mapDataToChartConfig,
  YAxisOptions,
  YAxisTitles,
} from './transformer/campaign-timeseries';
import {
  getAxisInputOptions,
  convertEqValue,
  getAxisLabel,
} from './transformer/helpers/chartAxis';

export interface ICampaignTimeseriesProps {
  targets: IOption[];
  chartData: ResultBase;
  performanceMetricGroups: PerformanceGroupNames[];
  sectionFiltersSelection: IResultSelectionFilters['campaignDeliveryConversions'];
  lastValidReport: IReport | null;
  useEquivalizedMetrics?: boolean;
  updateResultSelectionFilters: (
    payload: Partial<IResultSelectionFilters>,
  ) => Promise<void>;
}

const aggregationOptions: ITextValue[] = [
  {
    text: 'Non-Cumulative',
    value: AggregationMethods.STANDARD,
  },
  {
    text: 'Cumulative',
    value: AggregationMethods.CUMULATIVE,
  },
];

const chartFiltersComponentName = 'chart-filters-wrapper';
const chartFiltersClass = getClass(chartFiltersComponentName);
const containerClass = getClass(chartFiltersComponentName, {
  concat: ['container'],
});
const headerClass = getClass(chartFiltersComponentName, {
  concat: ['header'],
});
const filtersClass = getClass(chartFiltersComponentName, {
  concat: ['filters'],
});
const labelClass = getClass(chartFiltersComponentName, {
  concat: ['label'],
});
const segmentedControlClass = getClass(chartFiltersComponentName, {
  concat: ['segmented-control-wrapper'],
});

const helperTooltipText =
  'Use this plot to identify the relationship between impressions, reach, and conversions over time. When visualizing exposed converters, check to see if certain time periods saw naturally higher numbers of exposed converters than others and examine how those peaks and valleys relate to the impressions and reach patterns that precede them.';

export const sectionName = 'Campaign Delivery and Conversions by Date';

const CampaignDeliveryConversions: React.FC<ICampaignTimeseriesProps> = ({
  targets,
  chartData,
  performanceMetricGroups,
  sectionFiltersSelection,
  lastValidReport,
  updateResultSelectionFilters,
  useEquivalizedMetrics = false,
}) => {
  const didMount = useRef(false);
  const { leftYAxisInputOptions, rightYAxisInputOptions } = getAxisInputOptions(
    useEquivalizedMetrics,
    performanceMetricGroups,
  );

  const getSelectedValue = (
    axis: string,
    axisValue: string | number,
  ): IOption[] => {
    const options =
      axis === 'left' ? leftYAxisInputOptions : rightYAxisInputOptions;
    const eqAdjustedAxisValue =
      axisValue === YAxisOptions['campaign.IMPRESSIONS_EQ']
        ? YAxisOptions['campaign.IMPRESSIONS']
        : axisValue;
    return options.filter((option) => option.value === eqAdjustedAxisValue);
  };

  const [{ selectedTargets, leftYAxis, rightYAxis, aggregation }, dispatch] =
    React.useReducer(chartFiltersReducer, {
      selectedTargets: getPersistedTargets(
        targets,
        sectionFiltersSelection?.targets,
      ),
      leftYAxis: getAxisLabel(
        sectionFiltersSelection?.yLeft,
        useEquivalizedMetrics,
        'left',
      ),
      rightYAxis: getAxisLabel(
        sectionFiltersSelection?.yRight,
        useEquivalizedMetrics,
        'right',
      ),
      aggregation:
        sectionFiltersSelection?.aggregationMethod ??
        (aggregationOptions[1].value as string),
      lastValidReport,
    });

  const chartConfig = useMultichartTransformer<
    IChartTargetItem,
    IMultiseriesTargetAndAxisFilters,
    Highcharts.Options
  >(chartData, {
    filters: {
      targets: selectedTargets,
      leftYAxis: convertEqValue(
        leftYAxis as YAxisOptions,
        useEquivalizedMetrics,
      ),
      rightYAxis: convertEqValue(
        rightYAxis as YAxisOptions,
        useEquivalizedMetrics,
      ),
      aggregation: aggregation as AggregationMethods,
    },
    performanceMetricGroups,
    reportResultMapper: mapDataToChartConfig,
    configGetter: getChartConfig,
  });

  const legendItems = React.useMemo(
    () =>
      rightYAxis === YAxisOptions.NONE
        ? [
            {
              title:
                YAxisTitles[
                  convertEqValue(
                    leftYAxis as YAxisOptions,
                    useEquivalizedMetrics,
                  )
                ],
              icon: IconType.rectangleFilled,
            },
          ]
        : [
            {
              title:
                YAxisTitles[
                  convertEqValue(
                    leftYAxis as YAxisOptions,
                    useEquivalizedMetrics,
                  )
                ],
              icon: IconType.rectangleFilled,
            },
            {
              title:
                YAxisTitles[
                  convertEqValue(
                    rightYAxis as YAxisOptions,
                    useEquivalizedMetrics,
                  )
                ],
              icon: IconType.standardLegend,
            },
          ],
    [rightYAxis, leftYAxis],
  );

  useEffect(() => {
    if (didMount.current) {
      const filtersTargets = selectedTargets.map((t) => t.value);

      const convertedLeftYAxis = convertEqValue(
        leftYAxis as YAxisOptions,
        useEquivalizedMetrics,
      );
      const convertedRightYAxis = convertEqValue(
        rightYAxis as YAxisOptions,
        useEquivalizedMetrics,
      );
      updateResultSelectionFilters({
        campaignDeliveryConversions: {
          targets: filtersTargets as string[],
          yLeft: convertedLeftYAxis,
          yRight: convertedRightYAxis,
          aggregationMethod: aggregation,
        },
      });
    } else {
      didMount.current = true;
    }
  }, [
    useEquivalizedMetrics,
    updateResultSelectionFilters,
    selectedTargets,
    leftYAxis,
    rightYAxis,
    aggregation,
  ]);

  return (
    <section className={chartFiltersClass} id="CONVERTED_REACH">
      <Flex vertical={Vertical.between} className={containerClass}>
        <Flex vertical={Vertical.between}>
          <header>
            <h3 className={headerClass}>{sectionName}</h3>
          </header>
          <ChartHelper tooltip={helperTooltipText} />
        </Flex>
      </Flex>
      <div className={`${containerClass} pt-0 pb-1`}>
        <MultiseriesChart
          chartConfig={chartConfig}
          legend={
            <SampleLegend
              position={{ right: 20, top: 0, margin: '0' }}
              items={legendItems}
            />
          }
        />
      </div>
      <div className={filtersClass}>
        <div>
          <label className={labelClass}>Audiences</label>
          <MultiSelectDropdown
            options={targets}
            selected={selectedTargets}
            placeholder="Choose audience"
            multiSelect
            showLegend
            onChange={(target) =>
              dispatch({
                type: ChartFilterActions.CHANGE_TARGET,
                payload: target,
              })
            }
            minimumChipsSelected={1}
          />
        </div>
        <div className="mt-1">
          <Flex horizontal={Horizontal.between}>
            <Flex horizontal={Horizontal.left} className="w-50">
              <div className={`${segmentedControlClass} pr-2 w-50`}>
                <label className={labelClass}>Y-Axis (Left)</label>
                <MultiSelectDropdown
                  options={leftYAxisInputOptions}
                  onChange={(yAxis) =>
                    dispatch({
                      type: ChartFilterActions.CHANGE_LEFT_AXIS,
                      payload:
                        leftYAxisInputOptions.find(
                          (option) => option.value === yAxis[0].value,
                        ) ?? leftYAxisInputOptions[0],
                    })
                  }
                  selected={getSelectedValue('left', leftYAxis)}
                  testId="left-axis-control"
                  multiSelect={false}
                  minimumChipsSelected={1}
                  showLegend={false}
                />
              </div>
              <div className={`${segmentedControlClass} w-50`}>
                <label className={labelClass}>Y-Axis (Right)</label>
                <MultiSelectDropdown
                  options={rightYAxisInputOptions}
                  onChange={(yAxis) =>
                    dispatch({
                      type: ChartFilterActions.CHANGE_RIGHT_AXIS,
                      payload:
                        rightYAxisInputOptions.find(
                          (option) => option.value === yAxis[0].value,
                        ) ?? rightYAxisInputOptions[0],
                    })
                  }
                  selected={getSelectedValue('right', rightYAxis)}
                  testId="right-axis-control"
                  multiSelect={false}
                  minimumChipsSelected={1}
                  showLegend={false}
                />
              </div>
            </Flex>
            <div className={segmentedControlClass}>
              <label className={labelClass} style={{ textAlign: 'right' }}>
                Aggregation Method
              </label>
              <SegmentedControl
                options={aggregationOptions}
                onSelect={(agg) =>
                  dispatch({
                    type: ChartFilterActions.CHANGE_AGGREGATION,
                    payload:
                      aggregationOptions.find(
                        (option) => option.value === agg,
                      ) ?? aggregationOptions[0],
                  })
                }
                selected={aggregation}
                testId="aggregation-control"
                size="small"
              />
            </div>
          </Flex>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = {
  updateResultSelectionFilters:
    resultSelectionsActions.updateResultSelectionFilters,
};

export default connect(null, mapDispatchToProps)(CampaignDeliveryConversions);
