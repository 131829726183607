import ITextValue from 'types/textValue';

export const options: ITextValue[] = [
  {
    text: 'Platf0rm Reports',
    value: 'platf0rm_reports',
  },
  {
    text: 'Advanced Audiences',
    value: 'targets',
  },
  {
    text: 'Imp4ct Reports',
    value: 'attribution_reports',
  },
];

export const DROPDOWN_PLACEHOLDER = 'Select a report type';
export const FEATURE_FLAGS_HEADER = 'Feature Flags';
export const DROPDOWN_LABEL = 'Report Type';
export const INPUT_LABEL = 'Flag Name';
export const INPUT_PLACEHOLDER = 'Enter the flag name';
export const CTA_LABEL = 'Add';
export const ERROR_MESSAGE = 'Only numbers, letters, and "_" are allowed';
