import { useEffect, useMemo, useState } from 'react';
import { Index } from 'routes';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import IUser from '../types/user';
import { AccumulatedEntries, getAccumulatedEntries } from './helpers';
import useFetch from './useFetch';
import useToast from './useToast';

export type HookReturn = {
  users: IUser[];
  indexedUsers: AccumulatedEntries;
  resetUsers: () => void;
  loading: boolean;
};

const useUsers = (): HookReturn => {
  const [users, setUsers] = useState<IUser[]>();
  const [loading, setLoading] = useState(false);
  const { doErrorToast } = useToast();
  const { doFetch } = useFetch<IUser>();

  useEffect(() => {
    if (loading || users) return;
    setLoading(true);
    doFetch({
      endpoint: `/${Index.SEGMENT_USERS}`,
      method: FetchMethod.GET,
      onSuccess: (res: IUser[]) => {
        setUsers(res);
        setLoading(false);
      },
      onError: (e: IError) => {
        setUsers([]);
        doErrorToast(e?.message ?? 'There has been an error.');
        setLoading(false);
      },
    });
  }, [doErrorToast, doFetch, loading, users]);

  const resetUsers = (): void => setUsers(undefined);

  const indexedUsers: AccumulatedEntries = useMemo(
    () => getAccumulatedEntries(users ?? []),
    [users],
  );

  return {
    indexedUsers,
    users: users ?? [],
    resetUsers,
    loading,
  };
};

export default useUsers;
