import { Size, Type } from 'components/Icon';
import { getTestId, getClass } from 'helpers/components';
import React, {
  FunctionComponent,
  ReactElement,
  useMemo,
  useContext,
} from 'react';
import { Index as Routes } from 'routes';
import Separator from 'components/Separator';
import SidebarContext from './sidebar.context';
import SidebarItem from './SidebarItem';

export const sidebarComponentName = 'sidebar';

export enum States {
  expanded = 'expanded',
  active = 'active',
}

export type SidebarConfigurationItem = {
  linkTo?: string;
  filePath?: string;
  show?: boolean;
  iconType?: Type;
  text: string;
  subItems?: SidebarConfigurationItem[];
  separator?: boolean;
  size?: Size;
  customClass?: string;
};

export interface IProps {
  canAccessAdminPanel?: boolean;
  canAccessAll?: boolean;
  canAccessAttributionReports?: boolean;
  canAccessClients?: boolean;
  canAccessDashboards?: boolean;
  canAccessReports?: boolean;
  canAccessRoles?: boolean;
  canAccessTargets?: boolean;
  hasDashboards?: boolean;
  testId?: string;
}

const LOCALE_DASHBOARDS_ITEM_LABEL = 'Main Dashboard';
const LOCALE_ALL_DASHBOARDS_ITEM_LABEL = 'All Dashboards';
const LOCALE_REPORTS_ITEM_LABEL = 'Measurement';
const LOCALE_ATTRIBUTION_REPORTS_ITEM_LABEL = 'IMP4CT';
const LOCALE_ROLES_ITEM_LABEL = 'Roles';
const LOCALE_FAQ_ITEM_LABEL = 'FAQ';
const LOCALE_SUPPORT_LABEL = 'Support';
const LOCALE_CLIENTS_ITEM_LABEL = 'Clients';
const LOCALE_TARGETS_ITEM_LABEL = 'Advanced Audiences';
const LOCALE_ADMIN_ITEM_LABEL = 'Admin';

const DOM_KEY_SIDEBAR_WRAPPER = 'wrapper';

const faqFilePath = '/605/docs/faq.pdf';

const Sidebar: FunctionComponent<IProps> = (props): ReactElement => {
  const {
    canAccessAdminPanel,
    canAccessAll = false,
    canAccessAttributionReports,
    canAccessClients,
    canAccessDashboards,
    canAccessReports,
    canAccessRoles,
    canAccessTargets,
    hasDashboards = true,
    testId,
  } = props;

  const { expanded, setExpanded } = useContext(SidebarContext);

  const sidebarConfiguration: SidebarConfigurationItem[] = useMemo(
    () => [
      {
        show: (canAccessAll || canAccessDashboards) && hasDashboards,
        linkTo: `/${Routes.SEGMENT_DASHBOARDS}`,
        iconType: Type.dashboard,
        text: LOCALE_DASHBOARDS_ITEM_LABEL,
        subItems: [
          {
            show: true,
            linkTo: `/${Routes.SEGMENT_DASHBOARDS}/list`,
            text: LOCALE_ALL_DASHBOARDS_ITEM_LABEL,
          },
        ],
      },
      {
        show: canAccessAll || canAccessReports,
        linkTo: `/${Routes.SEGMENT_REPORTS}`,
        iconType: Type.reportDetails,
        text: LOCALE_REPORTS_ITEM_LABEL,
      },
      {
        show: canAccessAll || canAccessAttributionReports,
        linkTo: `/${Routes.SEGMENT_ATTRIBUTION_REPORTS}`,
        iconType: Type.imp4ct,
        text: LOCALE_ATTRIBUTION_REPORTS_ITEM_LABEL,
      },
      {
        show: canAccessAll || canAccessTargets,
        linkTo: `/${Routes.SEGMENT_TARGETS}`,
        iconType: Type.target,
        text: LOCALE_TARGETS_ITEM_LABEL,
      },
      {
        show: canAccessAll || canAccessRoles,
        linkTo: `/${Routes.SEGMENT_ROLES}`,
        iconType: Type.userRoles,
        text: LOCALE_ROLES_ITEM_LABEL,
      },
      {
        show: canAccessAll || canAccessAdminPanel,
        linkTo: `/${Routes.SEGMENT_ADMIN}`,
        iconType: Type.edit,
        text: LOCALE_ADMIN_ITEM_LABEL,
      },
      {
        show: canAccessAll || canAccessClients,
        linkTo: `/${Routes.SEGMENT_CLIENTS}`,
        iconType: Type.database,
        text: LOCALE_CLIENTS_ITEM_LABEL,
      },
      {
        show: true,
        separator: true,
        text: '',
      },
      {
        show: true,
        filePath: faqFilePath,
        iconType: Type.questionMark,
        text: LOCALE_FAQ_ITEM_LABEL,
        size: Size.small,
        customClass: 'icon-mt-4-px',
      },
      {
        show: true,
        filePath: 'https://platform.605.tv/support',
        iconType: Type.wrench,
        text: LOCALE_SUPPORT_LABEL,
        size: Size.small,
        customClass: 'icon-mt-4-px',
      },
    ],
    [
      canAccessAdminPanel,
      canAccessAll,
      canAccessAttributionReports,
      canAccessClients,
      canAccessDashboards,
      hasDashboards,
      canAccessReports,
      canAccessRoles,
      canAccessTargets,
    ],
  );

  const handleMouseEnter = (): void => {
    setExpanded(true);
  };

  const handleMouseLeave = (): void => {
    setExpanded(false);
  };

  const sidebarTestId = getTestId(sidebarComponentName, testId);
  const sidebarWrapperClass = getClass(sidebarComponentName, {
    concat: [DOM_KEY_SIDEBAR_WRAPPER],
  });

  const sidebarClass = getClass(sidebarComponentName, {
    boolean: [
      {
        state: expanded,
        class: States.expanded,
      },
    ],
  });

  return (
    <nav
      className={sidebarClass}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={sidebarTestId}
    >
      <div className={sidebarWrapperClass}>
        {sidebarConfiguration.map((item) =>
          item.separator ? (
            <Separator key={item.text} />
          ) : (
            <SidebarItem
              sidebarExpanded={expanded}
              show={item.show}
              linkTo={item.linkTo}
              iconType={item.iconType}
              text={item.text}
              subItems={item.subItems}
              filePath={item.filePath}
              key={item.text}
              size={item.size}
              customClass={item.customClass}
            />
          ),
        )}
      </div>
    </nav>
  );
};

export default Sidebar;
