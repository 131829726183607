import { isValid } from 'date-fns';
import IClient from 'domains/clients/types';
import { IBaseReport } from 'domains/reports/types';
import { get } from 'lodash/fp';
import { IValidatedCell } from 'types/edit-many-users';
import { allOperators, inOperators, containsOperators } from 'types/filter';
import { Modes } from 'types/query';
import ITextValue, { IDynamicDropdownPayload } from 'types/textValue';

export const parseDynamicDropdownData = (
  data: IDynamicDropdownPayload,
): Array<ITextValue> => {
  const objectKeys = Object.keys(data);
  return objectKeys.map((key: string) => {
    const dropDownNode = data[key];
    return {
      value: key,
      text: key,
      ...dropDownNode,
    };
  });
};

export const sortUserClients = (clients: Array<IClient>): Array<IClient> => {
  const userClients = [...clients].sort(
    (clientA: IClient, clientB: IClient) => {
      if (!clientA?.name || !clientB?.name) return 0;
      const firstCharA = clientA.name[0];
      const firstCharB = clientB.name[0];
      const firstCharAIsLetter = /^[a-zA-Z]+$/.test(firstCharA);
      const firstCharBIsLetter = /^[a-zA-Z]+$/.test(firstCharB);
      if (!firstCharAIsLetter && firstCharBIsLetter) return 1;
      if (firstCharAIsLetter && !firstCharBIsLetter) return -1;
      if (clientA?.name > clientB?.name) return 1;
      if (clientA?.name < clientB?.name) return -1;
      return 0;
    },
  );
  return userClients;
};

export const getOperatorTooltip = (
  operator: string,
  dimensionGroup?: string,
): string | undefined => {
  const isViewing =
    dimensionGroup &&
    ['Time', 'Network', 'Program', 'Ads'].includes(dimensionGroup);
  if (isViewing) {
    switch (get(operator, allOperators)) {
      case inOperators.IN:
        return 'Only viewing of the specified value(s) is included';
      case inOperators['NOT IN']:
        return 'All viewing except of the specified value(s) is included';
      case containsOperators.CONTAINS:
        return 'Only viewing of values containing the specified substring is included';
      case containsOperators['NOT CONTAINS']:
        return 'All viewing except of values containing the specified substring is included';
      case inOperators['NOT HAVE']:
        return 'Only households that were not exposed to the value(s) are included';
      default:
        return undefined;
    }
  }

  // houlseholds
  switch (get(operator, allOperators)) {
    case inOperators.IN:
      return 'Only households with the specified value(s) are included';
    case inOperators['NOT IN']:
      return 'All households except those with the specified value(s) are included';
    case containsOperators.CONTAINS:
      return 'Only households with values containing the specified substring are included';
    case containsOperators['NOT CONTAINS']:
      return 'All households except those with values containing the specified substring are included';
    default:
      return undefined;
  }
};

export const getCSVFilename = (report: IBaseReport): string => {
  if (!report.name) {
    return `export_${new Date().toISOString()}.csv`;
  }

  return `${report.name
    .replace(/[^a-z0-9]/gi, '_')
    .toLowerCase()}_${new Date().toISOString()}.csv`;
};

export const filterNotIndex =
  <T>(index: number) =>
  (_: T, i: number) =>
    i !== index;

export const defineDatePickerFooterMessage = (
  mode: string,
  maxDate: string,
): string => {
  const showDatePickerFooterMessage =
    mode === Modes.linear || mode === Modes.adverts;
  if (!showDatePickerFooterMessage) return '';
  return `Dates after ${new Date(maxDate).getMonth() + 1}/${new Date(
    maxDate,
  ).getDate()} may have incomplete data due to viewership data provider lag.`;
};

export const handleDropdownSearchSyncFilter = (
  valueFilter = '',
  datasets: ITextValue[],
): ITextValue[] => {
  if (!valueFilter) {
    return datasets;
  }
  const filteredOptions = datasets.filter((item) =>
    item.text.toLowerCase().includes(valueFilter.toLowerCase()),
  );
  return filteredOptions;
};

export const delay = (milliseconds: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export const defineUsersPreviewTableErrorMessage = (
  rowData: IValidatedCell[],
): string => {
  const erroredCell = rowData.find((item) => item.error);
  if (!erroredCell) return '';
  if (typeof erroredCell.value !== 'string') {
    const erroredValues = (erroredCell.value ?? []).filter(
      (item) => item.error,
    );
    const erroredValuesString = erroredValues.reduce(
      (acc, item, index, source) => {
        if (index === source.length - 1) {
          return acc + item.name;
        }
        if (index === 0) {
          return `${item.name} | `;
        }
        return `${acc}${item.name} | `;
      },
      '',
    );
    return `Invalid ${erroredCell.key} found: ${erroredValuesString}`;
  }
  if (erroredCell.errorMessage) {
    return `Invalid ${
      erroredCell.key
    } found - ${erroredCell.errorMessage.toLocaleLowerCase()}`;
  }
  return `Invalid ${erroredCell.key} found.`;
};

export const getUSLocaleStringFromSeconds = (
  seconds: number | undefined,
): string | undefined => {
  if (!seconds) return;
  return isValid(new Date(seconds * 1000))
    ? new Date(seconds * 1000).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : undefined;
};
