import Icon, {
  Type as IconType,
  Size as IconSize,
  Color as IconColor,
} from 'components/Icon';
import { getTestId, getId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactNode } from 'react';

export const listComponentName = 'list';

export const Decorators = {
  chevron: 'chevron',
} as const;

type Decorator = (typeof Decorators)[keyof typeof Decorators];

export type Props = {
  testId?: string;
  id?: string;
  items?: string[];
  decorator?: Decorator;
};

const ComponentName: FunctionComponent<Props> = (props) => {
  const { id, testId, items, decorator } = props;

  const listId = getId(listComponentName, id);
  const listTestId = getTestId(listComponentName, testId);
  const listClass = getClass(listComponentName, {
    boolean: [
      {
        state: !!(decorator && decorator === Decorators.chevron),
        class: Decorators.chevron,
      },
    ],
  });
  const listItemLabelClass = getClass(listComponentName, {
    concat: ['item-label'],
  });

  return (
    <ul className={listClass} data-testid={listTestId} id={listId}>
      {items?.map(
        (item: string): ReactNode => (
          <li key={item}>
            {decorator === Decorators.chevron && (
              <span>
                <Icon
                  type={IconType.chevron}
                  size={IconSize.small}
                  color={IconColor.gray}
                />
              </span>
            )}
            <span className={listItemLabelClass}>{item}</span>
          </li>
        ),
      )}
    </ul>
  );
};

export default ComponentName;
