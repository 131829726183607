import { IRunReportProps } from 'domains/reports/types';
import { fetchApi } from 'helpers/fetching';
import { Dispatch } from 'redux';
import ActionType from 'store/actions/types';
import { PayloadLessAction } from 'types/action';
import FetchMethod from '../../../types/fetchMethod';
import {
  downloadBlobFile,
  showErrorToast,
  showSuccessToast,
} from 'helpers/general';

export const setDownloadStart = (): PayloadLessAction => ({
  type: ActionType.DOWNLOAD_STARTED,
});

export const setDownloadEnd = (): PayloadLessAction => ({
  type: ActionType.DOWNLOAD_ENDED,
});

export const setDownloadFail = (): PayloadLessAction => ({
  type: ActionType.DOWNLOAD_ENDED,
});

export const handleDownload =
  (
    endpoint: string,
    downloadParams: {
      runReportProps: IRunReportProps;
      format: string;
      isFileUpload: boolean;
      cacheQueryId?: string;
    },
    successMessage: string = 'Download completed!',
    errorMessage: string = 'Download failed!',
    shouldDownload = true,
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setDownloadStart());
    const { report } = downloadParams.runReportProps;
    const { format, isFileUpload, cacheQueryId } = downloadParams;
    const downloadResult = await fetchApi({
      endpoint,
      payload: {
        report,
        format,
        isFileUpload,
        cacheQueryId,
      },
      method: FetchMethod.POST,
      isDownload: shouldDownload,
    });
    if (downloadResult?.error) {
      dispatch(setDownloadFail());
      showErrorToast(downloadResult.error?.message ?? errorMessage);
      return;
    }
    dispatch(setDownloadEnd());
    showSuccessToast(successMessage);
    if (shouldDownload) {
      downloadBlobFile(
        downloadResult?.data[0] as unknown as Blob,
        report?.name ?? '',
        format,
      );
    }
  };
