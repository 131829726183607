interface IRole {
  name: string;
  id: string;
  permissions?: string[];
  noDelete?: boolean;
}

export default IRole;

export class Role implements IRole {
  name: string;

  id: string;

  permissions: string[];

  noDelete: boolean;

  constructor(role?: IRole) {
    this.name = role?.name ?? '';
    this.id = role?.id ?? '';
    this.permissions = role?.permissions ?? [];
    this.noDelete = role?.noDelete ?? false;
  }
}
