import React, {
  createContext,
  useState,
  ReactNode,
  ReactElement,
  Dispatch,
  SetStateAction,
} from 'react';

const defaultExpanded = false;
const defaultSetExpanded = (): void => {};

const SidebarContext = createContext({
  expanded: defaultExpanded,
  setExpanded: defaultSetExpanded as Dispatch<SetStateAction<boolean>>,
});

export default SidebarContext;

type Props = {
  children?: ReactNode;
};

const SidebarProvider = ({ children }: Props): ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <SidebarContext.Provider value={{ expanded, setExpanded }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const SidebarConsumer = SidebarContext.Consumer;
export { SidebarProvider };
