import IReport from 'domains/reports/types';
import { Dispatch } from 'redux';
import * as rootActions from 'store/actions/root';
import * as reportSelectors from 'store/selectors/report';
import State from 'types/state';
import * as reportActions from '.';

export const handleSaveReportModal =
  (
    isModalReportNameAndRun: boolean,
    missingName: string | undefined,
    isTarget: boolean,
    onSubmit: (arg0: boolean, reportToSubmit?: IReport) => void,
  ) =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(reportActions.toggleReportModal(false));
    const state = getState();
    const report = reportSelectors.getReport(state);

    if (missingName) {
      dispatch(
        reportActions.setReport({
          ...report,
          name: missingName,
        }),
      );
    }

    if (!isModalReportNameAndRun) {
      if (isTarget) {
        dispatch(rootActions.setTargetList(null));
      } else {
        dispatch(rootActions.setReportList(null));
      }
    }

    await onSubmit(isModalReportNameAndRun, {
      ...report,
      name: missingName,
    });
  };
