import H1 from 'components/H1';
import IPermission from 'domains/permissions/types';
import RoleForm from 'features/roles/components/Form';
import { getTestId, getClass } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import React, { FunctionComponent, ReactElement } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import IError from 'types/error';
import IRole from 'types/role';

export const editRoleComponentName = 'edit-role';

const LOCALE_EDIT_TITLE = 'Edit Role';
const LOCALE_CREATE_TITLE = 'New Role';

const RolesEdit: FunctionComponent<{
  role: IRole;
  permissions: IPermission[];
}> = (props: { role: IRole; permissions: IPermission[] }): ReactElement => {
  const navigate = useNavigate();
  const { role, permissions } = props;

  const { id } = useParams<{ id: string }>();
  const { doSuccessToast, doErrorToast } = useToast();

  const goToRoles = (): void => {
    navigate('/roles');
  };

  const handleSuccess = (): void => {
    doSuccessToast(id ? 'Role updated' : 'Role created');
    navigate('/roles');
  };

  const handleError = (err: IError): void => {
    doErrorToast(err?.message ?? 'There has been an error.');
  };

  const { doUpdate, doCreate, loading } = useItemAdmin<IRole>({
    endpoint: '/roles',
  });

  const handleSubmit = (name: string, p: string[]): void => {
    const item = { name, permissions: p, id };
    if (id) {
      doUpdate({
        item,
        onSuccess: handleSuccess,
        onError: handleError,
      });
    } else {
      doCreate({
        item,
        onSuccess: handleSuccess,
        onError: handleError,
      });
    }
  };

  const editRoleTestId = getTestId(editRoleComponentName);
  const editRoleDefaultClass = getClass(editRoleComponentName);

  return (
    <section className={editRoleDefaultClass} data-testid={editRoleTestId}>
      <header className={`${editRoleDefaultClass}-header`}>
        <H1>{role ? LOCALE_EDIT_TITLE : LOCALE_CREATE_TITLE}</H1>
      </header>
      <RoleForm
        role={role}
        permissions={permissions}
        onSubmit={handleSubmit}
        onCancel={goToRoles}
        loading={loading}
      />
    </section>
  );
};

export default RolesEdit;
