import Loading from 'components/Loading';
import useConfigOptions from 'hooks/useConfigOptions';
import React, { ReactElement } from 'react';
import ConfigOptionsList from '../../list.feature';

const ConfigOptionsListContainer = (): ReactElement => {
  const { configOptions, loading, resetConfigOptions } = useConfigOptions();

  return (
    <>
      {!loading ? (
        <ConfigOptionsList
          configOptions={configOptions}
          resetList={resetConfigOptions}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ConfigOptionsListContainer;
