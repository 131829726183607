import ITextValue from 'types/textValue';
import IReport, {
  ChartTypes,
  ChartType,
  defaultChartTypes,
  defaultDasbhoardChartTypes,
  ReportTimeDimension,
  IKeyString,
  reportDashoboardTypes,
  ReportDashboardTypeLocales,
} from '../types';

export const removeChartTypes = (typesToRemove: string[]): string[] =>
  defaultChartTypes.filter((chartType) => typesToRemove.includes(chartType));

export const getTypesQueryReportGrouped = (): string[] =>
  removeChartTypes([
    ChartTypes.bar,
    ChartTypes.area,
    ChartTypes.column,
    ChartTypes.line,
    ChartTypes.barStacked,
    ChartTypes.barPercentageStacked,
    ChartTypes.columnStacked,
    ChartTypes.columnPercentageStacked,
    ChartTypes.areaStacked,
    ChartTypes.areaPercentageStacked,
  ]);

export const getTypesQueryIntervalIsAllViewing = (): string[] =>
  removeChartTypes([
    ChartTypes.barStacked,
    ChartTypes.barPercentageStacked,
    ChartTypes.columnStacked,
    ChartTypes.columnPercentageStacked,
    ChartTypes.areaStacked,
    ChartTypes.areaPercentageStacked,
  ]);

export const getDefaultChartTypes = (): string[] => defaultDasbhoardChartTypes;

export const getAvailableChartTypes = (report: IReport): string[] => {
  const reportHasGroup = !!report.query?.group;
  const reportHasIntervalId = !!report.query?.interval?.id;
  const queryReportDoesGroup = reportHasGroup && !!report.query?.group?.length;
  const queryReportIsAllViewing =
    reportHasIntervalId &&
    report.query?.interval?.id === ReportTimeDimension.ALL_VIEWING;

  let chartTypeList = [];
  if (!queryReportDoesGroup && queryReportIsAllViewing) {
    chartTypeList = getTypesQueryReportGrouped();
  } else if (queryReportIsAllViewing) {
    chartTypeList = getTypesQueryIntervalIsAllViewing();
  } else {
    chartTypeList = getDefaultChartTypes();
  }

  return chartTypeList;
};

export const chartTypeHasMetrics = (type: ChartType): boolean =>
  type === ChartTypes.bar || type === ChartTypes.singleStat;

export const getTypeOptions = (): ITextValue[] => {
  const mapSelectedOptionsToDropdown = (
    list: string[],
    locales: IKeyString,
  ): ITextValue[] =>
    list.map((selectOption) => ({
      text: locales[selectOption],
      value: selectOption,
    }));

  const newTypeOptions = mapSelectedOptionsToDropdown(
    reportDashoboardTypes,
    ReportDashboardTypeLocales,
  );
  return newTypeOptions;
};
