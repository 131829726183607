import Table, { Alignment, alignments, textOverflows } from 'components/Table';
import { IHeaderColumn } from 'components/Table/ThRow';
import { DataRow, DataRowGraph, IMetaData } from 'domains/reports/types';
import { getClass } from 'helpers/components';
import React, { memo, ReactElement } from 'react';
import { CellProps, Column, Row } from 'react-table';

const getData = (data: DataRowGraph[]): DataRowGraph[] =>
  data.map((row: DataRowGraph) =>
    Object.keys(row).reduce(
      (newRow, key) => ({
        ...newRow,
        [key]: {
          value: row[key].VALUE,
          name: row[key].DISPLAY_VALUE,
        },
      }),
      {},
    ),
  );

const firstColumn = {
  Header: '#',
  id: 'number',
  width: '100px',
  Cell: function QueryTableGetColumnsCell({
    row,
  }: CellProps<DataRow>): JSX.Element {
    return <span>{row.index + 1}</span>;
  },
};

export const SORT_BY_VALUE = (a: Row, b: Row, key: string): number => {
  if (
    typeof a.values[key].value === 'string' &&
    !Number.isNaN(Number(a.values[key].value))
  ) {
    if (Number(a.values[key].value) < Number(b.values[key].value)) return -1;
    if (Number(a.values[key].value) > Number(b.values[key].value)) return 1;
    // N/A case
    return -10;
  }
  return a.values[key].value < b.values[key].value ? -1 : 1;
};

const getColumns = (metadata: IMetaData[]): Array<Column<DataRowGraph>> => {
  const columnsConfig: Array<Column<DataRowGraph>> = metadata
    .filter((meta: IMetaData) => meta.display)
    .map(
      (meta: IMetaData) =>
        ({
          Header: meta.name,
          width: `calc(${(1 / metadata.length) * 100}% - 70px)}`,
          accessor: meta.accessor ?? meta.id,
          tooltip: meta.description ?? '',
          Cell: (props: CellProps<DataRow>) => props.value.name,
          sortType: SORT_BY_VALUE,
        }) as unknown as Column<DataRowGraph>,
    );

  columnsConfig.unshift(firstColumn as unknown as Column<DataRowGraph>);
  return columnsConfig;
};

const getAlignment = (metadata: IMetaData[]): Alignment[] => {
  const alignment: Alignment[] = [];

  alignment.push(alignments.left);

  metadata.forEach((m) => {
    if (m.type === 'Integer' || m.type === 'Float') {
      alignment.push(alignments.right);
    } else {
      alignment.push(alignments.left);
    }
  });

  return alignment;
};

export const queryTableComponentName = 'query-table';

type QueryTableProps = {
  data: DataRowGraph[];
  metadata: IMetaData[];
  overrideHeaders?: IHeaderColumn[][];
  pageSize?: number;
  pagination?: boolean;
};

const QueryTable = (props: QueryTableProps): ReactElement => {
  const { data, metadata, overrideHeaders, pagination, pageSize } = props;

  const tableData = data && getData(data);
  const columns = getColumns(metadata);
  const alignment = getAlignment(metadata);
  const queryTableClass = getClass(queryTableComponentName);

  return (
    <div className={queryTableClass}>
      <Table
        alignment={alignment}
        columns={columns}
        data={tableData}
        goToEditOnRowClick
        indexable
        noResultsMessage="No results found"
        overrideHeaders={overrideHeaders}
        pageSize={pageSize}
        pagination={pagination}
        sortable
        textOverflow={textOverflows.ellipsis}
      />
    </div>
  );
};

export default memo(QueryTable);
