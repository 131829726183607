import Flex, { Direction } from 'components/Flex';
import { Placement } from 'components/Sticker';
import { Tooltip } from 'components/Tooltip';
import { isAfter, isBefore } from 'date-fns';
import { endOfDay, format } from 'date-fns/fp';
import { dateFormat } from 'domains/reports/adapters/date';
import { getClass } from 'helpers/components';
import { BUTTONS } from 'helpers/datepicker';
import { IButtonMetadata } from 'helpers/datepicker/types';
import { trackDateRangeChange } from 'helpers/mixpanel';
import React, { FC } from 'react';
import { HandleButtonClickMeta, ISmartSelectionProps } from './interface';

const smartSelectionClass = getClass('smart-selection');

const formatISO = format('yyyy-MM-dd');

const handleButtonClick =
  ({ handleChange, start, end, key, trackingId }: HandleButtonClickMeta) =>
  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    trackDateRangeChange(
      `${trackingId} Smart Selection`,
      formatISO(start),
      formatISO(end),
      undefined,
      key,
    );
    handleChange(formatISO(start), formatISO(end), key);
  };

export const formatToDateFormat = format(dateFormat(navigator.language));

export const SmartSelection: FC<ISmartSelectionProps> = ({
  handleChange,
  trackingId,
  buttons,
  disabled = false,
  maxDate = new Date(),
  minDate = new Date(),
  relativeDateOffset,
}) => (
  <Flex direction={Direction.column} className={smartSelectionClass}>
    {Object.entries({ ...BUTTONS, ...buttons })
      .filter((value): value is [string, IButtonMetadata] => value[1] !== false)
      .map(([key, value]) => {
        if (React.isValidElement(value)) {
          return value;
        }

        const { start, end } = value.getRange(maxDate);
        const content = `${formatToDateFormat(start)} - ${formatToDateFormat(
          end,
        )}`;
        const isDisabled =
          disabled ||
          isBefore(start, minDate) ||
          isAfter(end, endOfDay(maxDate));
        const button = (
          <button
            onClick={handleButtonClick({
              handleChange,
              start,
              end,
              key,
              trackingId,
            })}
            className={relativeDateOffset === key ? 'active' : undefined}
            disabled={isDisabled}
          >
            {value.text}
          </button>
        );

        if (isDisabled) {
          return <span key={key}>{button}</span>;
        }

        return (
          <Tooltip key={key} content={content} placement={Placement.left}>
            {button}
          </Tooltip>
        );
      })}
  </Flex>
);
