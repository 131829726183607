import IMetric from 'domains/metrics/types';
import {
  getAvailableMetrics,
  getAvailableDimensions,
  getAvailableIntervals,
  getAvailableOrderFields,
  sortTargets,
  AvailableDimensions,
} from 'domains/reports/adapters/general';
import IReport, { IBusinessData } from 'domains/reports/types';
import { fetchApi } from 'helpers/fetching';
import { union, find, get } from 'lodash/fp';
import { Dispatch } from 'redux';
import { Index } from 'routes';
import store from 'store';
import ActionType from 'store/actions/types';
import { getBusinessData } from 'store/selectors/businessData';
import * as userSelector from 'store/selectors/user';
import { Action, PayloadLessAction } from 'types/action';
import FetchMethod from '../../../types/fetchMethod';
import ITextValue from '../../../types/textValue';

export const GENPOP_TARGET_ID = 'genpop';

export const generateGenpopTarget = (): IReport => {
  const { universeName } = getBusinessData(store.getState());
  return {
    createdBy: '',
    id: GENPOP_TARGET_ID,
    name: universeName,
    query: {
      filters: {},
    },
  };
};

export const resetBusinessAvailableData = (): PayloadLessAction => ({
  type: ActionType.BUSINESS_DATA_RESET_AVAILABLE_DATA,
});

export const setBusinessData = (
  payload: IBusinessData,
): Action<IBusinessData> => ({
  type: ActionType.BUSINESS_DATA_SET_BUSINESS_DATA,
  payload,
});

export const addBusinessData = (
  payload: IBusinessData,
): Action<IBusinessData> => ({
  type: ActionType.BUSINESS_DATA_ADD_BUSINESS_DATA,
  payload,
});

export const setAvailableMetrics = (
  updatedReport?: IReport,
): Action<IMetric[]> => {
  const report = get('report', store.getState());
  const selectedReport = updatedReport ?? report;
  let newAvailableMetrics;
  if (selectedReport) {
    newAvailableMetrics = getAvailableMetrics(selectedReport as IReport);
  }
  return {
    type: ActionType.BUSINESS_DATA_SET_AVAILABLE_METRICS,
    payload: newAvailableMetrics ?? [],
  };
};

export const setAvailableDimensions = (
  updatedReport?: IReport,
): Action<AvailableDimensions> | PayloadLessAction => {
  const report = get('report', store.getState());
  const selectedReport = updatedReport ?? report;
  let newAvailableDimensions;
  if (selectedReport) {
    newAvailableDimensions = getAvailableDimensions(selectedReport);
  }
  return {
    type: ActionType.BUSINESS_DATA_SET_AVAILABLE_DIMENSIONS,
    payload: newAvailableDimensions,
  };
};

export const setAvailableIntervals = (
  updatedReport?: IReport,
): Action<ITextValue[] | undefined> => {
  const report = get('report', store.getState());
  const selectedReport = updatedReport ?? report;

  let newAvailableIntervals: ITextValue[] = [];
  if (selectedReport) {
    newAvailableIntervals = getAvailableIntervals(selectedReport);
  }

  return {
    type: ActionType.BUSINESS_DATA_SET_AVAILABLE_INTERVALS,
    payload: newAvailableIntervals,
  };
};

export const setAvailableOrderFields = (
  updatedReport?: IReport,
): Action<ITextValue[] | undefined> => {
  const report = get('report', store.getState());
  const selectedReport = updatedReport ?? report;
  let newAvailableOrderFields: ITextValue[] = [];
  if (selectedReport) {
    newAvailableOrderFields = getAvailableOrderFields(selectedReport);
  }

  return {
    type: ActionType.BUSINESS_DATA_SET_AVAILABLE_ORDER_FIELDS,
    payload: newAvailableOrderFields,
  };
};

export const setAvailableTargets = (
  targets?: IReport[],
): Action<IReport[] | undefined> => ({
  type: ActionType.BUSINESS_DATA_SET_AVAILABLE_TARGETS,
  payload: targets,
});

export const fetchTargetsAction =
  (extId?: string | undefined) =>
  async (dispatch: Dispatch): Promise<void> => {
    const user = userSelector.getUser(store.getState());
    const selectedClient = find({ id: user.selectedClient }, user.clients);
    const selectedExtID = get('extID', selectedClient);
    const targetExtID = extId ?? selectedExtID;
    const fetch = await fetchApi({
      endpoint: `/${Index.SEGMENT_REPORTS}/targets/?clientExtID=${targetExtID}`,
      payload: {},
      method: FetchMethod.GET,
    });
    const targets = sortTargets(
      union(fetch.data as IReport[], [generateGenpopTarget()]),
    );
    dispatch(setAvailableTargets(targets));
  };
