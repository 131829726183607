import { ResultBaseSubStructure } from 'domains/reports/types';
import { camelCase } from 'lodash';

export const computePointId = (
  dataRow: ResultBaseSubStructure,
  target: string | number,
): string | undefined => {
  const pointId = dataRow.name
    ? camelCase(`${target}${dataRow.name.DISPLAY_VALUE}`)
    : undefined;
  return pointId;
};
