import { getClass, getTestId } from 'helpers/components';
import { trackReportInputChange } from 'helpers/mixpanel';
import React, { FunctionComponent, useMemo } from 'react';

interface IProps {
  checked?: boolean;
  name: string;
  onChange: (event: boolean) => void;
  yesLabel?: string;
  noLabel?: string;
  topOffset?: number;
  disabled?: boolean;
  testId?: string;
  trackingId?: string;
  small?: boolean;
}
const toggleSwitchComponentName = 'toggle-switch';
const toggleSwitchLabelClass = getClass(toggleSwitchComponentName, {
  concat: ['label'],
});
const toggleSwitchSwitchClass = getClass(toggleSwitchComponentName, {
  concat: ['switch'],
});

export const ToggleSwitch: FunctionComponent<IProps> = ({
  checked,
  name,
  onChange,
  yesLabel = 'Yes',
  noLabel = 'No',
  topOffset = 0,
  disabled,
  testId,
  trackingId,
  small,
}) => {
  const toggleSwitchClass = getClass(toggleSwitchComponentName, {
    boolean: [
      { state: small, class: 'small' },
      { state: disabled, class: 'disabled' },
    ],
  });
  const toggleSwitchInnerClass = getClass(toggleSwitchComponentName, {
    concat: ['inner'],
    boolean: [{ state: disabled, class: 'disabled' }],
  });
  const inputClass = getClass(toggleSwitchComponentName, {
    concat: ['checkbox'],
    boolean: [{ state: disabled, class: 'disabled' }],
  });

  const toggleTestId = useMemo(
    () => getTestId(toggleSwitchComponentName, testId),
    [testId],
  );

  const inputTestId = useMemo(
    () => getTestId(`${toggleSwitchComponentName}-checkbox`, testId),
    [testId],
  );
  return (
    <div className={toggleSwitchClass} data-testid={toggleTestId}>
      <input
        className={inputClass}
        id={name}
        name={name}
        type="checkbox"
        data-testid={inputTestId}
        checked={checked}
        disabled={disabled}
        onChange={(e): void => {
          if (trackingId)
            trackReportInputChange(trackingId, e.target.checked || false);
          onChange(e.target.checked);
        }}
      />
      <label className={toggleSwitchLabelClass} htmlFor={name}>
        <span className={toggleSwitchInnerClass}>
          <span className="yes-label">{yesLabel}</span>
          <span className="no-label">{noLabel}</span>
        </span>
        <span className={toggleSwitchSwitchClass} style={{ top: topOffset }} />
      </label>
    </div>
  );
};
