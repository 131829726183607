import startCase from 'lodash/startCase';

export function stringOrDefaultCapitalized(
  defaultString: string,
  stringToCapitalize?: string,
): string {
  return startCase((stringToCapitalize ?? defaultString).toLowerCase());
}

/**
 * Converts error string to modalDialog compatible Array
 * inputString = "This is an error. These are the reasons: ['Reason 1', 'Reason 2'] " to
 * output = ['This is an error. These are the reasons: ', ['Reason 1', 'Reason 2']]
 */
export function convertStringToArray(inputString: string): unknown {
  // Find the index of the opening square bracket
  const openingBracketIndex = inputString.indexOf('[');

  // Split the string into two parts
  const mainString = inputString.slice(0, openingBracketIndex);
  const reasonsString = inputString.slice(openingBracketIndex);

  // Remove the opening and closing square bracket from the reasonsString
  const cleanedReasonsString = reasonsString
    .replace('[', '')
    .replace(']', '')
    .replace(/'/gm, '');

  // Convert the reasonsString into an array
  const reasonsArray = cleanedReasonsString.split(',');

  // Return the final array
  return [mainString, reasonsArray];
}

export const sanitizeExportFileNameWDate = (string: string): string => {
  return `${string
    .replace(/[^a-z0-9]/gi, '_')
    .toLowerCase()}_${new Date().toISOString()}`;
};
