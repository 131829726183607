import {
  LOCALE_CONFIRMATION_DIALOG_CONFIRM_LABEL,
  LOCALE_CONFIRMATION_DIALOG_TEXT_ONE,
  LOCALE_CONFIRMATION_DIALOG_TEXT_TWO,
  LOCALE_CONFIRMATION_DIALOG_TITLE,
} from 'components/QueryBuilder/components/QueryBuilderType';
import { dimensionTypeList } from 'components/RulesBuilder/components/RulesBuilderItem';
import {
  datasetSelectionAccessItem,
  DatasetTypes,
  IDataset,
} from 'domains/datasets/types';
import IDimension from 'domains/dimensions/types';
import IMetric from 'domains/metrics/types';
import { checkPermissionTree } from 'domains/permissions/helpers';
import { IPermissionTree } from 'domains/permissions/types';
import { surface } from 'helpers/general';
import { filterArrayByHideFor, toTextValuePairs } from 'helpers/types';
import {
  difference,
  filter,
  find,
  flow,
  get,
  head,
  isEmpty,
  matchesProperty,
} from 'lodash/fp';
import { Dispatch } from 'redux';
import store from 'store';
import {
  setAvailableDimensions,
  setAvailableIntervals,
  setAvailableMetrics,
  setAvailableOrderFields,
} from 'store/actions/businessData';
import {
  setReport,
  setShowConfirmationDialog,
  setUpdatedReport,
} from 'store/actions/report';
import {
  getDimensions,
  getInterval,
  getMetrics,
  getQueryMetrics,
  getQueryMode,
  setBaseReportSaved,
  setMetrics,
} from 'store/reducers/report';
import * as businessDataSelectors from 'store/selectors/businessData';
import * as domainsSelectors from 'store/selectors/domains';
import { RuleFilter } from 'types/filter';
import ITextValue from 'types/textValue';
import { IQuery, IReportQueryInterval, Mode, Modes } from 'types/query';
import IReport, {
  IAttributionReport,
  IConfirmDialog,
  ReportDatasetSelection,
  ReportDatasetsSelections,
  reportSubType,
  reportType,
  reportTypeEquivalences,
  rulesWhiteList,
} from '../types';
import {
  getInvalidMetrics,
  hasQueryAdsDatasets,
  hasQueryContentDatasets,
  hasQueryCustomAdsDatasets,
} from './datasets';
import { snakeCase, toLower } from 'lodash';
import State from 'types/state';

export const isCumulative = flow(get('cumulative_group'), Boolean);

export const getFilteredMetrics = (
  metrics: IMetric[],
  suppressionConditioners?: string[],
): IMetric[] => filterArrayByHideFor(metrics, suppressionConditioners);

export const getFilteredDimensions = (
  dimensions: IDimension[],
  suppressionConditioners?: string[],
): IDimension[] => filterArrayByHideFor(dimensions, suppressionConditioners);

export const reportFiltersHasVOD = (report: IReport): boolean =>
  !!report.query?.filters?.children?.some((childrenFilter: unknown) => {
    const isViewingType = get('field', childrenFilter) === 'VIEWING_TYPE';
    const hasVOD =
      isViewingType &&
      get('value', childrenFilter)?.some((val: ITextValue) =>
        val?.text?.includes('VOD'),
      );
    return isViewingType && hasVOD;
  });

export const getReportAvailableMetrics = (
  metrics: IMetric[],
  report: IReport,
): IMetric[] => {
  const hasPlatformVODSelected = reportFiltersHasVOD(report);
  const queryIsCumulative = isCumulative(report.query);
  let filteredMetrics = queryIsCumulative
    ? metrics.filter((x) => x.cume_name && x.cume_description)
    : metrics;
  if (hasPlatformVODSelected) {
    filteredMetrics = filteredMetrics.filter(
      (metric: IMetric) =>
        metric?.id !== 'AVG_AUDIENCE' && metric?.id !== 'RATING',
    );
  }
  const curatedMetrics = filteredMetrics.map((metric) => {
    const foundMetric = metrics.find((m) => m.id === metric.id);
    return {
      ...metric,
      tooltip: get(
        `${queryIsCumulative ? 'cume_description' : 'description'}`,
        foundMetric,
      ),
      isCumulative,
    };
  });
  return curatedMetrics;
};

export const isAttributionReport = (
  attributionReport: IAttributionReport,
): boolean => get('type', attributionReport) === reportType.attributionReport;

export const isTarget = (attributionReport: IReport): boolean =>
  get('type', attributionReport) === reportType.target;

export const isReport = (attributionReport: IReport): boolean =>
  get('type', attributionReport) === reportType.report;

export const newReportNamePlaceholder = (report: IReport): string =>
  isReport(report) ? 'New Report' : 'New Target';

const getSaveAsCopyReportName = (report: IReport): string =>
  !report.name
    ? `Copy of ${newReportNamePlaceholder(report)}`
    : `Copy of ${report.name}`;

export const isReportNameToSaveReportAsCopyValid = (report: IReport): boolean =>
  [
    undefined,
    getSaveAsCopyReportName(report),
    '',
    newReportNamePlaceholder,
  ].every((name) => report.name !== name);

export const getDatasetSelection = (
  queryMode?: Mode,
): ReportDatasetSelection => {
  const queryHasContentDatasets = hasQueryContentDatasets(queryMode);

  if (queryHasContentDatasets) {
    return ReportDatasetsSelections.content;
  }

  const queryHasCustomAdsDatasets = hasQueryCustomAdsDatasets(queryMode);

  if (queryHasCustomAdsDatasets) {
    return ReportDatasetsSelections.customAds;
  }

  const queryHasAdsDatasets = hasQueryAdsDatasets(queryMode);

  if (queryHasAdsDatasets) {
    return ReportDatasetsSelections.ads;
  }

  if (queryMode === Modes.genericEvents) {
    return ReportDatasetsSelections.genericEvents;
  }

  return ReportDatasetsSelections.content;
};

export const areCohortRulesValid = (
  filters: RuleFilter[],
  rulesToIgnore: string[] = [],
): boolean => {
  const allRules: RuleFilter[] = filters.filter(
    (queryFilter: RuleFilter) => !rulesToIgnore.includes(queryFilter.id ?? ''),
  );
  return allRules.every(
    ({ end, start, value }) =>
      (start && end) || (value && !Array.isArray(value)) || value?.length,
  );
};

export const areQueryRulesValid = (
  query: IQuery,
  rulesToIgnore: string[] = [],
): boolean => {
  const children = get('filters.children', query);
  const isNestedSchema = children && !isEmpty(get('[0].children', children));
  const filters = (isNestedSchema ? surface(children) : children) ?? [];
  const allRules: RuleFilter[] = filters
    .filter((queryFilter: RuleFilter) => !!queryFilter?.filterId)
    .filter(
      (queryFilter: RuleFilter) =>
        !rulesToIgnore.includes(queryFilter.id ?? ''),
    );
  const validRules = allRules.filter(
    ({ end, field, filterId, id, operator, start, type, value }) =>
      [field, id, operator, filterId, type].every(
        (queryFilterElem) => queryFilterElem,
      ) &&
      ((start && end) || (value && !Array.isArray(value)) || value?.length) &&
      (type === 'COHORT' ? areCohortRulesValid(<RuleFilter[]>value) : true),
  );
  return allRules.length === validRules.length;
};

export const areAttributionQueryRulesValid = (query: IQuery): boolean => {
  const rulesToIgnore = ['CREATIVE_LABEL', 'PROGRAM'];
  const queryRulesValid = areQueryRulesValid(query, rulesToIgnore);
  return queryRulesValid;
};

const defineDatasetFromMode = (mode: string): string => {
  if (mode === Modes.linear) return ReportDatasetsSelections.content;
  if (mode === Modes.adverts) return ReportDatasetsSelections.ads;
  if (mode === Modes.customAdverts) return ReportDatasetsSelections.customAds;
  return ReportDatasetsSelections.content;
};

export const getAvailableMetrics = (report: IReport): IMetric[] => {
  const metrics = domainsSelectors.getMetrics(store.getState());
  const mode = getQueryMode(report);
  const datasetSelection = defineDatasetFromMode(mode);
  const suppresionChain = [
    `${datasetSelectionAccessItem}::${datasetSelection}`,
    'platf0rm_reports.metrics',
  ];
  const filteredMetrics = getFilteredMetrics(metrics, suppresionChain);
  const newAvailableMetrics = getReportAvailableMetrics(
    filteredMetrics,
    report,
  );
  return newAvailableMetrics;
};

export type AvailableDimensions = {
  dimensions: IDimension[];
  rulesDimensions: IDimension[];
};

export const checkPermissions = (permission: string | string[]): boolean => {
  const { permissions } = store.getState().user || {};
  return checkPermissionTree(permission, permissions as IPermissionTree);
};

export const filterOutcomeRules = (dimensions: IDimension[]): IDimension[] =>
  dimensions.filter(
    (dimension: IDimension) =>
      Boolean(dimension.custom_segment) ||
      dimension.dimension_type === dimensionTypeList.compound_event_based ||
      dimension.dimension_type ===
        dimensionTypeList.compound_event_based_generic,
  );

export const getAvailableDimensions = (
  report: IReport,
): AvailableDimensions => {
  let dimensions = domainsSelectors.getDimensions(store.getState());
  const mode = getQueryMode(report);
  const datasetSelection = getDatasetSelection(mode);

  if (isAttributionReport(report as IAttributionReport)) {
    const suppressionConditioners = [
      `${datasetSelectionAccessItem}::${datasetSelection}`,
      'attribution_reports.outcomes_brandlift',
    ];
    const outcomeRules = filterOutcomeRules(dimensions);
    const outcomeRuleIdsToHide = filterArrayByHideFor(
      outcomeRules,
      suppressionConditioners,
    ) as IDimension[];

    return {
      dimensions,
      rulesDimensions: outcomeRuleIdsToHide,
    };
  }

  const suppressionConditioners = [
    `${datasetSelectionAccessItem}::${datasetSelection}`,
  ];
  dimensions = filterArrayByHideFor(dimensions, suppressionConditioners);

  if (mode === Modes.customAdverts || mode === Modes.adverts) {
    dimensions = dimensions.filter((item: IDimension) => {
      return item.group === 'General'
        ? !['Source Name', 'Source Type'].includes(item.name as string)
        : item;
    });
  }
  const filteredDimensionsByPermission = (
    filterArrayByHideFor(dimensions, [
      `${reportTypeEquivalences(report.type)}.dimensions`,
    ]) as IDimension[]
  ).filter((item) => {
    if (!(item.custom_segment && item.is_dimension === false)) {
      // Add in BRAND_LABEL, CREATIVE_LABEL, AD_START, HH_FREQUENCY and DURATION_ROUNDED_LABEL into the available dimensions
      if (mode === Modes.customAdverts) {
        return item.group === 'Ads'
          ? [
              'BRAND_LABEL',
              'CREATIVE_LABEL',
              'CREATIVE_NAME_GROUP',
              'AD_START',
              'HH_FREQUENCY',
              'DURATION_ROUNDED_LABEL',
            ].includes(item.id as string)
          : item;
      }

      return true;
    }
  });

  const filteredDimensionsForRulesDropdown = (
    filterArrayByHideFor(dimensions, [
      `${reportTypeEquivalences(report.type)}.rules`,
    ]) as IDimension[]
  ).filter((item) => {
    if (!(item.custom_segment && item.is_rule === false)) {
      // Add in BRAND_LABEL, CREATIVE_LABEL, HH_FREQUENCY and DURATION_ROUNDED_LABEL into the available rules
      if (mode === Modes.customAdverts) {
        const id = item.id.replace(/user_dim_\d+/, '');
        return item.group === 'Ads'
          ? [
              'BRAND_LABEL',
              'CREATIVE_LABEL',
              'HH_FREQUENCY',
              'DURATION_ROUNDED_LABEL',
            ].includes(id)
          : item;
      }

      return true;
    }
  });

  return {
    dimensions: filteredDimensionsByPermission,
    rulesDimensions: filteredDimensionsForRulesDropdown,
  };
};

export const getAvailableIntervals = (report: IReport): ITextValue[] => {
  const intervals = businessDataSelectors.getIntervals(store.getState());
  const queryIsCumulative = isCumulative(report.query);
  const reportQueryIntervals = intervals as IReportQueryInterval[];
  const curatedReportQueryInterval = (
    queryIsCumulative
      ? difference(reportQueryIntervals, [head(reportQueryIntervals)])
      : reportQueryIntervals
  ) as IReportQueryInterval[];
  const curatedIntervals = toTextValuePairs(curatedReportQueryInterval);

  return curatedIntervals;
};

export const getTextValueTargets = (
  targets: IReport[] | undefined,
): ITextValue[] =>
  targets?.map((target: IReport) => ({
    text: target?.name?.toString() ?? '',
    value: target?.id?.toString() || '',
  })) ?? [];

export const sortTargets = (
  targets: IReport[] | undefined,
): IReport[] | undefined =>
  targets?.sort((a: IReport, b: IReport) => {
    const aDate = new Date(a.createdAt ?? '').getTime();
    const bDate = new Date(b.createdAt ?? '').getTime();
    return bDate - aDate;
  });

export const getCuratedTargets = (
  targets: IReport[] | undefined,
): ITextValue[] => {
  const sortedTargets = sortTargets(targets);
  return getTextValueTargets(sortedTargets) || [];
};

export const getAvailableOrderFields = (report: IReport): ITextValue[] => {
  const queryIsCume = isCumulative(report.query);
  if (queryIsCume) {
    const interval = getInterval(report);
    return [interval];
  }
  const metrics = getMetrics(report) || [];
  const dimensions = getDimensions(report) || [];
  const options = [...metrics, ...dimensions];
  const metricsFull: IMetric[] = getAvailableMetrics(report);
  const dimensionsFull: IMetric[] = getAvailableDimensions(report)
    .dimensions as IMetric[];
  const optionsFull: IMetric[] = [...metricsFull, ...dimensionsFull];

  const curatedOptions = toTextValuePairs(
    optionsFull.filter((el) => options.includes(el.id)),
  );
  return curatedOptions;
};

export const getAvailableTargets = (report: IReport): ITextValue[] => {
  const queryIsCume = isCumulative(report.query);
  if (queryIsCume) {
    const interval = getInterval(report);
    return [interval];
  }
  const metrics = getMetrics(report) || [];
  const dimensions = getDimensions(report) || [];
  const options = [...metrics, ...dimensions];
  const metricsFull: IMetric[] = getAvailableMetrics(report);
  const dimensionsFull: IMetric[] = getAvailableDimensions(report)
    .dimensions as IMetric[];
  const optionsFull: IMetric[] = [...metricsFull, ...dimensionsFull];

  const curatedOptions = toTextValuePairs(
    optionsFull.filter((el) => options.includes(el.id)),
  );
  return curatedOptions;
};

const getInvalidMetricsDialogMessage = (
  invalidMetrics: IMetric[],
): (string | string[])[] => {
  const messageLines = [];
  messageLines.push(LOCALE_CONFIRMATION_DIALOG_TEXT_ONE);
  messageLines.push(LOCALE_CONFIRMATION_DIALOG_TEXT_TWO);

  const subList: string[] = invalidMetrics.map((metric) => metric.name);
  if (subList.length) messageLines.push(subList);

  return messageLines;
};

export const setMetricTypeDecision = (
  dispatch: Dispatch,
  newReport: IReport,
): void => {
  let copyNewReport = newReport;
  const invalidMetrics = getInvalidMetrics(copyNewReport);

  if (!invalidMetrics.length) {
    dispatch(setReport(copyNewReport));
    dispatch(setAvailableDimensions(copyNewReport));
    if (isReport(copyNewReport)) {
      dispatch(setAvailableMetrics(copyNewReport));
      dispatch(setAvailableIntervals(copyNewReport));
      dispatch(setAvailableOrderFields(copyNewReport));
    }
  } else {
    const invalidMetricStrings = invalidMetrics.map((metric) => metric.id);

    const confirmDialog = {
      confirmationDialogMessage: getInvalidMetricsDialogMessage(invalidMetrics),
      confirmationDialogHeader: LOCALE_CONFIRMATION_DIALOG_TITLE,
      confirmationDialogOK: LOCALE_CONFIRMATION_DIALOG_CONFIRM_LABEL,
    };

    // If no metrics found, default to REACH
    const reportMetrics = getQueryMetrics(copyNewReport);
    const newMetrics = difference(
      reportMetrics,
      invalidMetricStrings,
    ) as unknown as string[];
    if (!newMetrics.length) {
      newMetrics.push('REACH');
    }

    copyNewReport = setMetrics(newMetrics, copyNewReport);
    copyNewReport = setBaseReportSaved(false, copyNewReport);
    dispatch(setUpdatedReport(copyNewReport));

    dispatch(
      setShowConfirmationDialog(confirmDialog as unknown as IConfirmDialog),
    );
  }
};

export const getDimension = (dimension: string): IDimension | undefined => {
  const dimensions = get(
    'domains.dimensions',
    store.getState(),
  ) as IDimension[];
  const found = dimensions.find(
    (currentDimension) => dimension === currentDimension.id,
  );
  return found;
};

export const getMetric = (metric: string): IMetric | undefined => {
  const metrics = get('domains.metrics', store.getState()) as IMetric[];
  const found = metrics.find((currentMetric) => metric === currentMetric.id);
  return found;
};

export const getTargetAvailableBreakouts = flow(
  businessDataSelectors.getAvailableRulesDimensions,
  filter(
    (dim: IDimension) =>
      checkPermissions(
        `targets.dimensions.${snakeCase(
          dim.group ?? '',
        )}.${dim.id.toLowerCase()}::view`,
      ) ||
      (!!dim?.custom_segment && dim.value_type !== 'DateTime'),
  ),
);

export const getDaypartDatasets: (state: State) => IDataset[] = flow(
  (state) => domainsSelectors.getDatasets(state),
  filter(matchesProperty('type', DatasetTypes.daypartTimetable)),
);

export const getStandartDaypartDatasetId: (datasets: IDataset[]) => string =
  flow(find(matchesProperty('name', 'Standard Daypart')), get('id'));

export const getAttributionRulePermission = (
  filterName: string,
  ruleId: string,
): string =>
  `${reportTypeEquivalences(
    reportType.attributionReport,
  )}.${filterName}.${toLower(ruleId)}::view`;

export const curateDisplayedRules = (
  filterName: string,
  children: RuleFilter[],
  checkPermissions: (permission: string) => boolean,
): RuleFilter[] =>
  children.filter(
    (d) =>
      checkPermissions(getAttributionRulePermission(filterName, d.id ?? '')) ||
      d.type === 'DATETIME_RANGE' ||
      rulesWhiteList.includes(d.id ?? ''),
  );

export const getReportSubTypeName = (subTypeKey?: string): string => {
  switch (subTypeKey) {
    case reportSubType.tvTuneIn.key:
      return reportSubType.tvTuneIn.name;
    case reportSubType.outcome.key:
      return reportSubType.outcome.name;
    case reportSubType.platform.key:
      return reportSubType.platform.name;
    case reportSubType.audienceInsights.key:
      return reportSubType.audienceInsights.name;
    default:
      return '';
  }
};
