import { Index } from 'routes';

export const DOM_KEY_ADD_NAME_BODY = 'add-name-body';
export const DOM_KEY_ADD_NAME_HEADER = 'add-name-header';
export const DOM_KEY_AUDIT_BODY = 'audit-body';
export const DOM_KEY_AUDIT_HEADER = 'audit-header';
export const DOM_KEY_LABEL = 'label';
export const DOM_KEY_QUERY = 'query';
export const DOM_KEY_REPORT_FORM = 'form';
export const DOM_KEY_RESULTS = 'results';
export const DOM_KEY_RESULTS_EXPORT_BUTTON = 'results-export-button';
export const DOM_KEY_SETTINGS = 'settings';
export const GET_KEY_RUN = 'run';

export enum ModalType {
  audit,
  addReportName,
  addReportNameSaveAndOpen,
  addReportNameAndRun,
  confirmQuery,
  iSpotSegment,
}

export const reportsUrl = `${Index.SEGMENT_HOME}${Index.SEGMENT_REPORTS}`;
export const targetsUrl = `${Index.SEGMENT_HOME}${Index.SEGMENT_TARGETS}`;
