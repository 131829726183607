import { ChangeHandlerType } from './types';
import { NewInputValue } from 'components/Input';
import ITextValue from 'types/textValue';
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';
import { ICrosswalkCombinationDetails } from 'domains/datasets/types';
import { get, isEmpty, noop } from 'lodash';
import { FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import { Index } from 'routes';
import { NavigateFunction } from 'react-router-dom';
import { fetchApi } from 'helpers/fetching';
import FetchMethod from 'types/fetchMethod';
import { showErrorToast, showSuccessToast } from 'helpers/general';
import { delay } from 'helpers/utils';
import { getOr } from 'lodash/fp';
import {
  SAVE_ERROR_MESSAGE,
  SAVE_SUCCESS_MESSAGE,
  DATASET_NAME_UNIQUE_ERROR_MESSAGE,
} from './locale';

export const changeHandler: ChangeHandlerType =
  (handleChange, name, setDuplicatedNameError = noop) =>
  (value: NewInputValue | ITextValue[]) => {
    const target = {
      name,
      value,
    } as ChangeEvent<HTMLInputElement>['target'];
    if (name === 'name') {
      setDuplicatedNameError('');
    }
    const event = { target } as ChangeEvent<HTMLInputElement>;
    handleChange(event);
  };

export const handleMultiSelectChange =
  (
    handleChange: ChangeEventHandler<HTMLInputElement>,
    touched: FormikTouched<ICrosswalkCombinationDetails>,
    setTouched: FormikHelpers<ICrosswalkCombinationDetails>['setTouched'],
    name: string,
  ) =>
  (value: string | ITextValue[]) => {
    setTouched({
      ...touched,
      [name]: true,
    });
    changeHandler(handleChange, name)(value);
  };

export const canUserSave = (
  errors: FormikErrors<ICrosswalkCombinationDetails>,
  dirty: boolean,
  loading: boolean,
): boolean => !loading && dirty && isEmpty(errors);

export const postCrosswalkCombination = async (
  crosswalkCombination: ICrosswalkCombinationDetails,
  navigate: NavigateFunction,
  setLoading: Dispatch<SetStateAction<boolean>>,
  fetchDomains: (refreshClients: boolean) => Promise<void>,
  setDuplicatedNameError: (message: string) => void,
): Promise<void> => {
  try {
    setLoading(true);
    const id = get(crosswalkCombination, 'id');
    const response = await fetchApi({
      endpoint: `/${Index.SEGMENT_DATASETS}/${Index.SEGMENT_CROSSWALK_COMBINATION}/${
        id ?? ''
      }`,
      method: id ? FetchMethod.PUT : FetchMethod.POST,
      payload: crosswalkCombination,
    });
    setLoading(false);

    if (!response.data || response.error) {
      if (response?.error?.meta?.code === 409) {
        setDuplicatedNameError(DATASET_NAME_UNIQUE_ERROR_MESSAGE);
      }
      throw new Error(getOr(SAVE_ERROR_MESSAGE, 'error.message', response));
    }

    fetchDomains(true);
    navigate(`${Index.SEGMENT_ADMIN}/${Index.SEGMENT_UPLOADS}`);
    await delay(10);
    showSuccessToast(SAVE_SUCCESS_MESSAGE);
  } catch (e) {
    showErrorToast(getOr(SAVE_ERROR_MESSAGE, 'message', e));
  }
  setLoading(false);
};
