import { flow, get } from 'lodash/fp';

export const getDashboard = get('dashboard');
export const getDashboardReports = flow(getDashboard, get('reports'));
export const getAllDashboardReportsOptions = flow(
  getDashboard,
  get('allAvailableReports'),
);
export const getDashboardReportFormMetricsOptions = flow(
  getDashboard,
  get('metricsOptions'),
);
export const getDashboardReportFormTypeOptions = flow(
  getDashboard,
  get('typeOptions'),
);
export const getDashboardReportForm = flow(getDashboard, get('newReportForm'));
export const getUserDashboard = flow(getDashboard, get('userDashboard'));
export const isUpdating = flow(getDashboard, get('isUpdating'));
