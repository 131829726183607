import Flex, { Horizontal } from 'components/Flex';
import { getClass, getTestId } from 'helpers/components';
import React, { ReactElement, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import ITextValue, { ITextValueGroup } from 'types/textValue';
import { isSelectedOption } from './index';
import OptionsTreeItem from './OptionsTreeItem';

export const componentName = 'options-tree-group';

interface IProps {
  allGroupsExpanded?: boolean;
  expanded?: boolean;
  group: ITextValueGroup;
  level?: number;
  onClick: (e: string) => void;
  selectedOptions?: string[] | ITextValue[];
  testId?: string;
  highlighted?: string;
}

const OptionsTreeGroup = (props: IProps): ReactElement => {
  const {
    group,
    onClick,
    selectedOptions,
    testId,
    level = 0,
    allGroupsExpanded = false,
    expanded = false,
    highlighted = '',
  } = props;
  const [deployed, setDeployed] = useState(expanded || false);

  useEffect(() => {
    setDeployed(expanded);
  }, [expanded]);

  const dataTestId = getTestId(
    componentName,
    `${group.group}${testId ? `-${testId}` : ''}`,
  );

  const defaultClass = getClass(componentName, {
    boolean: [{ state: deployed, class: 'deployed' }],
    add: [`level-${level}`],
  });

  const nameClass = getClass(componentName, { concat: ['name'] });

  const toggleDeployed = (): void => {
    setDeployed((d) => !d);
  };

  return (
    <>
      <div
        onClick={toggleDeployed}
        onKeyPress={toggleDeployed}
        className={defaultClass}
        data-testid={dataTestId}
      >
        <Flex horizontal={Horizontal.left}>
          <div
            className={`${nameClass} ${
              deployed ? 'deployed' : ''
            } level-${level}-group`}
          >
            {group.group}
          </div>
        </Flex>
      </div>
      <AnimateHeight duration={300} height={deployed ? 'auto' : 0}>
        {group.values.map((item: ITextValueGroup | ITextValue) => {
          if (item.group) {
            return (
              <OptionsTreeGroup
                expanded={
                  allGroupsExpanded || (item as ITextValueGroup).expanded
                }
                allGroupsExpanded={allGroupsExpanded}
                group={item as ITextValueGroup}
                key={`${item.group}-${level}`}
                level={level + 1}
                onClick={onClick}
                selectedOptions={selectedOptions}
                highlighted={highlighted}
              />
            );
          }
          const currentItem = item as ITextValue;
          return (
            <OptionsTreeItem
              isSelected={isSelectedOption(selectedOptions, currentItem)}
              item={currentItem}
              key={`${currentItem.value}-${level}`}
              level={level + 1}
              onClick={onClick}
              highlighted={highlighted}
            />
          );
        })}
      </AnimateHeight>
    </>
  );
};

export default OptionsTreeGroup;
