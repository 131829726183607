import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
  Type as ButtonType,
} from 'components/Button';
import Flex, { Horizontal } from 'components/Flex';
import { getTestId, getId, getClass } from 'helpers/components';
import React, { FunctionComponent, useMemo } from 'react';

export const actionsBarComponentName = 'actions-bar';

const LOCALE_ACTION_CANCEL = 'Cancel';
const LOCALE_ACTION_OK = 'Save';

const STATE_MARGIN = 'margin';
const STATE_NO_CANCEL = 'no-cancel';

export interface Props {
  cancel?: boolean;
  cancelLabel?: string;
  cancelType?: ButtonType;
  disabledCancel?: boolean;
  disabledOK?: boolean;
  id?: string;
  margin?: boolean;
  ok?: boolean;
  okLabel?: string;
  okSize?: ButtonSize;
  okType?: ButtonType;
  onCancel?: () => void;
  onOK?: () => void;
  testId?: string;
}

const ActionsBar: FunctionComponent<Props> = (props) => {
  const {
    cancel = true,
    cancelLabel,
    cancelType,
    disabledCancel,
    disabledOK,
    id,
    margin = false,
    ok = true,
    okLabel,
    okSize = ButtonSize.small,
    okType,
    onCancel,
    onOK,
    testId,
  } = props;

  const actionsBarId = useMemo(() => getId(actionsBarComponentName, id), [id]);
  const actionsBarTestId = useMemo(
    () => getTestId(actionsBarComponentName, testId),
    [testId],
  );

  const actionsBarClass = useMemo(
    () =>
      getClass(actionsBarComponentName, {
        boolean: [
          {
            class: STATE_NO_CANCEL,
            state: !cancel,
          },
          {
            class: STATE_MARGIN,
            state: margin,
          },
        ],
      }),
    [cancel, margin],
  );

  return (
    <footer
      id={actionsBarId}
      className={actionsBarClass}
      data-testid={actionsBarTestId}
    >
      <Flex horizontal={Horizontal.between}>
        {cancel && (
          <Button
            type={cancelType ?? ButtonType.button}
            kind={ButtonKind.text}
            onClick={onCancel}
            disabled={disabledCancel}
            upperCase
          >
            {cancelLabel ?? LOCALE_ACTION_CANCEL}
          </Button>
        )}
        {ok && (
          <Button
            type={okType ?? ButtonType.submit}
            kind={ButtonKind.primary}
            size={okSize}
            disabled={disabledOK}
            onClick={onOK}
            upperCase
          >
            {okLabel ?? LOCALE_ACTION_OK}
          </Button>
        )}
      </Flex>
    </footer>
  );
};

export default ActionsBar;
