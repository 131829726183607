import createChartConfigWithCommonDesign from 'helpers/charts/commonChartDesignConfig';
import dumbbellTooltipFormatter from 'helpers/charts/dumbbellTooltipForamtter';
import { XAxisPlotLinesOptions } from 'highcharts';
import { dumbbellTickPositioner } from './helpers/dumbbellTickPositioner';

const xAxisPlotLne: XAxisPlotLinesOptions = {
  dashStyle: 'LongDash',
  color: '#383B41',
  value: 100,
  zIndex: 1,
  label: {
    text: '100',
    align: 'center',
    verticalAlign: 'bottom',
    rotation: 0,
    x: 0,
    y: 20,
    style: {
      color: '#000',
      'font-weight': 400,
      'font-size': '12px',
    },
  },
};

export const baseDumbbellChartConfig = createChartConfigWithCommonDesign({
  chart: {
    type: 'line',
    spacing: [1, 20, 20, 20],
    height: 400,
  },

  yAxis: {
    type: 'category',
    title: {
      text: 'Age',
    },
    reversed: true,
    gridLineColor: 'transparent',
    gridLineWidth: 0,
    startOnTick: true,
  },

  xAxis: {
    title: {
      text: 'Population Index',
    },
    plotLines: [xAxisPlotLne],
    gridLineWidth: 1,
    showFirstLabel: false,
    showLastLabel: false,
    tickLength: 0,
    tickAmount: 7,
    startOnTick: true,
    endOnTick: true,
    min: 100,
    max: 100,
    tickInterval: 10,
    tickPositioner: dumbbellTickPositioner,
  },

  plotOptions: {
    line: {
      color: '#000',
      lineWidth: 1,
      marker: {
        enabledThreshold: 0,
      },
    },
  },

  tooltip: {
    formatter: dumbbellTooltipFormatter,
    borderColor: '#a7afb2',
  },
});
