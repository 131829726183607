import IConfig from 'domains/config/types';
import IReport from 'domains/reports/types';
import ActionType from 'store/actions/types';
import initialState from 'store/initialState';
import { Action } from 'types/action';

export const attributionReportList = (
  state: IReport[] = [],
  action: Action<IReport[]>,
): IReport[] => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.ROOT_SET_ATTRIBUTION_REPORT_LIST:
      return payload;
    default:
      return state;
  }
};

export const loaded = (state = false, action: Action<boolean>): boolean => {
  const { type } = action;

  switch (type) {
    case ActionType.ROOT_SET_LOADED:
      return true;
    case ActionType.ROOT_SET_LOADING:
      return false;
    default:
      return state;
  }
};

export const config = (
  state: IConfig = initialState.config,
  action: Action<IConfig>,
): IConfig => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.ROOT_SET_CONFIG:
      return payload;
    default:
      return state;
  }
};

export const reportList = (
  state: IReport[] | null = null,
  action: Action<IReport[] | null>,
): IReport[] | null => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.ROOT_SET_REPORT_LIST:
      return payload;
    default:
      return state;
  }
};

export const targetList = (
  state: IReport[] | null = null,
  action: Action<IReport[]>,
): IReport[] | null => {
  const { payload, type } = action;

  switch (type) {
    case ActionType.ROOT_SET_TARGET_LIST:
      return payload;
    default:
      return state;
  }
};
