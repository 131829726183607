import 'bootstrap/dist/css/bootstrap-grid.css';
import Auth from 'Auth';
import MaskedRedirects from 'features/MaskedRedirects';
import { getClass, getTestId } from 'helpers/components';
import FontLoader from 'helpers/fontLoader/index';
import React, { FunctionComponent } from 'react';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { Route, Routes as Switch, BrowserRouter } from 'react-router-dom';
import { Index as Routes } from 'routes';
import store from 'store';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const appComponentName = 'app';

const appComponentClass = getClass(appComponentName);
const appComponentTestId = getTestId(appComponentName);

const tagManagerArgs = {
  gtmId: 'GTM-5RKXXGH',
};

TagManager.initialize(tagManagerArgs);

const App: FunctionComponent = () => (
  <div className={appComponentClass} data-testid={appComponentTestId}>
    <FontLoader />
    <BrowserRouter window={global.window}>
      <Provider store={store}>
        <Switch>
          <Route
            path={`${Routes.SEGMENT_SUPPORT}/*`}
            element={<MaskedRedirects />}
          />
          <Route path="*" element={<Auth />} />
        </Switch>
      </Provider>
    </BrowserRouter>
  </div>
);
export default App;
