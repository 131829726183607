import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Actions } from 'routes';
import RolesEditContainer from './components/RolesEditContainer';
import RolesListContainer from './components/RolesListContainer';

const Roles: FunctionComponent = () => {
  const { checkPermissions } = useUserPermissions();
  return (
    <Routes>
      {checkPermissions('roles::create') && (
        <Route path={Actions.SEGMENT_CREATE} element={<RolesEditContainer />} />
      )}
      {checkPermissions('roles::update') && (
        <Route
          path={`${Actions.SEGMENT_EDIT}/:id`}
          element={<RolesEditContainer />}
        />
      )}
      {checkPermissions('roles::view') && (
        <Route index element={<RolesListContainer />} />
      )}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
export default Roles;
