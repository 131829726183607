import { ResultBaseSubStructure } from '../../domains/reports/types';
import { getMetricName } from '../groupNames/getMetricName';
import { get } from 'lodash/fp';
import { FormatType, numberFormatter } from '../visualization';
import { computeSignificanceValue } from './computeSignificanceValue';

export enum FormatMapNames {
  'campaign.AD_COST' = 'money',
  'campaign.REACH_PC' = 'percent',
  'converters.LIFT' = 'percent',
  'conversions.LIFT' = 'percent',
  'total_sales.LIFT' = 'percent',
  'sales_per_transaction.LIFT' = 'percent',
  'converters.MEAN' = 'percent',
  'converters.COST_PER_INCREMENTAL_LIFT' = 'money',
  'total_sales.MEAN' = 'money',
  'sales_per_transaction.MEAN' = 'money',
  'total_sales.INCREMENTAL_LIFT' = 'money',
  'sales_per_transaction.INCREMENTAL_LIFT' = 'money',
}
export type FormatMapKeys = keyof typeof FormatMapNames;
export const mapMetricToSuffixName = (metric: string): FormatType => {
  return FormatMapNames[metric as FormatMapKeys] ?? 'number';
};
export const axisValue = (
  axis: string,
  dataGroup: ResultBaseSubStructure,
): string => {
  let value: string = numberFormatter(
    get(axis, dataGroup)?.VALUE ?? 0,
    mapMetricToSuffixName(axis),
  );

  if (axis.indexOf('.LIFT') > 0) {
    const significanceKey = axis.replace('LIFT', 'P_VALUE');
    const significanceValue = computeSignificanceValue(
      get(significanceKey, dataGroup)?.VALUE ?? 0,
    );
    return `${value}${significanceValue}`;
  }
  return value;
};

export const mapValuesToMetrics = (
  metrics: string[],
  dataGroup: ResultBaseSubStructure,
  conversionType?: string,
): { [key: string]: number | string } =>
  metrics.reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: axisValue(curr, dataGroup),
      [`${curr}_name`]: getMetricName(curr, conversionType),
    };
  }, {});
