import Field from 'components/Field';
import { Type as InputType } from 'components/Input';
import Label from 'components/Label';
import { stringToTextValue } from 'helpers/types';
import React, { FunctionComponent, useMemo } from 'react';

export interface IGenericEventFileDetails {
  subType: string;
  conversionType: string;
  performanceMetricGroups: string[];
}

const performanceMetricGroupsOptions = [
  {
    text: 'Converters',
    value: 'converters',
  },
  {
    text: 'Conversions',
    value: 'conversions',
  },
  {
    text: 'Sales',
    value: 'sales',
  },
];

const conversionTypeOptions = [
  {
    text: 'Visits',
    value: 'visits',
  },
  {
    text: 'Transactions',
    value: 'transactions',
  },
  {
    text: 'Events',
    value: 'events',
  },
];

const subTypeOptions = [
  { text: 'Conversions', value: 'conversions' },
  { text: 'Exposures', value: 'exposures' },
];

export const GenericEventFileDetails: FunctionComponent<
  IGenericEventFileDetails
> = ({ subType, conversionType, performanceMetricGroups }) => {
  const pmTextValue = useMemo(
    () => stringToTextValue(performanceMetricGroups ?? ['converters']),
    [performanceMetricGroups],
  );
  return (
    <>
      <div className="inline-field-container">
        <Label text="Sub Type" />
        <div className="field-container imp4ct-advanced-settings">
          <Field
            type={InputType.select}
            id="sub-type"
            name="sub-type"
            options={subTypeOptions}
            placeholder="Select file type..."
            value={subType}
            disabled
          />
        </div>
      </div>
      {subType === 'conversions' && (
        <>
          <div className="inline-field-container">
            <Label text="Conversion Type" />
            <div className="field-container imp4ct-advanced-settings">
              <Field
                type={InputType.select}
                id="conversion-type"
                name="conversion-type"
                options={conversionTypeOptions}
                placeholder="Select file type..."
                value={conversionType}
                disabled
              />
            </div>
          </div>
          <div className="inline-field-container">
            <Label text="Performance Metrics" />
            <div className="field-container imp4ct-advanced-settings">
              <Field
                type={InputType.multipleDropdownSelect}
                id="performance-metric-groups"
                name="performance-metric-groups"
                options={performanceMetricGroupsOptions}
                placeholder="Select file type..."
                multiDropdownValue={pmTextValue}
                disabled
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
