export const FILE_TYPE_LABEL = 'File Type';
export const DATASET_NAME_LABEL = 'Dataset name';

export const DATASET_NAME_PLACEHOLDER = 'Dimension category name';
export const DATASET_NAME_REQUIRED_ERROR_MESSAGE =
  'The breakout category name field is required. Please provide the dimension category name.';
export const DATASET_NAME_UNIQUE_ERROR_MESSAGE =
  'There is an existing dimension with this name. Please update the name.';

export const DATASET_ID_LABEL = 'Dataset ID';

export const SOURCE_DIMENSION_LABEL = 'Source Dimension';
export const SOURCE_DIMENSION_PLACEHOLDER = 'Search for a source dimension';
export const SOURCE_DIMENSION_REQUIRED_ERROR_MESSAGE =
  'The source dimension field is required. Please provide the source dimension.';
export const SOURCE_DIMENSION_CHANGE_WARNING_MESSAGE =
  'Updating the source dimension will delete the underlying category values and fallback value. Do you wish to make this change?';

export const PATH_LABEL = 'Menu Category';
export const PATH_PLACEHOLDER = 'Select the Dimension menu category';
export const PATH_REQUIRED_ERROR_MESSAGE =
  'The menu category is a required. Please provide the menu category';
export const FALLBACK_LABEL = 'Fallback Value';
export const FALLBACK_PLACEHOLDER = 'Add the fallback value name';

export const DESCRIPTION_LABEL = 'Description';
export const DESCRIPTION_PLACEHOLDER = 'Add a description';
export const DESCRIPTION_REQUIRED_ERROR_MESSAGE =
  'The description field is required. Please provide the description.';

export const CLIENT_LABEL = 'Clients with access';
export const CLIENT_PLACEHOLDER = 'Clients with access';
export const CLIENT_REQUIRED_ERROR_MESSAGE =
  'The clients with access field is required. Please provide the clients with access.';

export const ADD_CUSTOM_VALUES_BUTTON_LABEL = 'New Empty Dimension Value';
export const CUSTOM_VALUES_ERROR_MESSAGE =
  'The dimension values are required. Please provide the dimension values.';
export const CANCEL_BUTTON_LABEL = 'Cancel';

export const SAVE_BUTTON_LABEL = 'Save';
export const CONTINUE_BUTTON_LABEL = 'Continue';
export const GO_BACK_BUTTON_LABEL = 'Go back';
export const YES_BUTTON_LABEL = 'Yes';
export const NO_BUTTON_LABEL = 'No';

export const ADD_CUSTOM_VALUE_ERROR_DIALOG =
  'The Source dimension is required in order to add dimension values. Please provide the dimension source.';
export const ADD_CUSTOM_VALUE_ERROR_DIALOG_CANCEL_LABEL = 'Ok';

export const DELETE_CUSTOM_VALUE_WARNING_MESSAGE =
  'Deleting the user dimension will delete associated custom values and affect any report or advanced audience that is using this user dimension. Do you want to proceed?';

export const MAPPED_VALUE_LABEL = 'Custom Value';
export const MAPPED_VALUE_PLACEHOLDER = 'Empty Custom Value';
export const MAPPED_VALUE_REQUIRED_ERROR_MESSAGE =
  'The value name is a required field. Please provide the value name.';
export const MAPPED_VALUE_UNIQUE_ERROR_MESSAGE =
  'There is an existing value of the same name for this dimension. Please update the name.';

export const SOURCE_VALUES_LABEL = 'Source Values';
export const SOURCE_VALUES_PLACEHOLDER = 'Search for source values';
export const SOURCE_VALUES_REQUIRED_ERROR_MESSAGE =
  'The source values are a required field. Please provide the values.';
export const SAVE_ERROR_MESSAGE = 'Unable to save the dataset';
export const SAVE_SUCCESS_MESSAGE = 'Dataset saved';

export const CANCEL_EDIT_WARNING_DIALOG_MESSAGE =
  'There are unsaved changes in the user dimension. Unsaved changes to the user dimension will be discarded if you continue without saving.';
