import { getTestId, getId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactNode } from 'react';

export const h3ComponentName = 'h3';

export interface IProps {
  testId?: string;
  id?: string;
  children?: ReactNode;
}

const H3: FunctionComponent<IProps> = (props) => {
  const { testId, id, children } = props;

  const h3Class = getClass(h3ComponentName);
  const h3Id = getId(h3ComponentName, id);
  const h3TestId = getTestId(h3ComponentName, testId);

  return (
    <h3 className={h3Class} data-testid={h3TestId} id={h3Id}>
      {children}
    </h3>
  );
};

export default H3;
