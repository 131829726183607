const LOCALE_MIN_LENGTH = (args: (string | undefined | number)[]): string =>
  args?.length ? `${args[0]} must be at least ${args[1]} characters` : '';
const LOCALE_MAX_LENGTH = (args: (string | undefined | number)[]): string =>
  args?.length ? `${args[0]} must be no more than ${args[1]} characters` : '';
const LOCALE_IS_REQUIRED = (args: (string | undefined)[]): string =>
  args?.length ? `${args[0]} is required` : '';

export interface ISchemaProperties {
  name?: string;
  minLength?: number | string;
  maxLength?: number | string;
  required?: string;
  pattern?: string;
  email?: string;
}

export const validationSchemaType = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
};

export const getCommonErrors = (
  propertyConfig: ISchemaProperties,
): ISchemaProperties => {
  const { name, minLength, maxLength } = propertyConfig;
  return {
    minLength: LOCALE_MIN_LENGTH([name, minLength]),
    maxLength: LOCALE_MAX_LENGTH([name, maxLength]),
    required: LOCALE_IS_REQUIRED([name]),
  };
};
