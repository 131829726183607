import { NewInputValue, Type as InputType } from 'components/Input';
import { getClass, getId, getTestId } from 'helpers/components';
import React, {
  FocusEventHandler,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from 'react';
import ITextValue from 'types/textValue';
import Fields from './Fields';
import Wrapper from './Wrapper';

export const fieldComponentName = 'field';

export interface IFieldProps {
  type: InputType;
  value?: string | number;
  multiDropdownValue?: ITextValue[];
  testId?: string;
  id?: string;
  name?: string;
  label?: string;
  minLength?: number;
  placeholder?: string;
  hasError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onKeyPressEnter?: () => void;
  onBlur?: FocusEventHandler;
  onChange?: (newFieldValue: NewInputValue) => void;
  onMultipleDropdownChange?: (selected: ITextValue[]) => void;
  className?: string;
  options?: string[] | ITextValue[];
  multipleDropdownOptions?: ITextValue[];
  preventEnterKeyDown?: boolean;
  canReorder?: boolean;
  portalContainerClass?: string;
}

const Field: FunctionComponent<PropsWithChildren<IFieldProps>> = (
  props,
): ReactElement => {
  const {
    type,
    label,
    value,
    multiDropdownValue,
    name,
    placeholder,
    disabled,
    onKeyPressEnter,
    hasError,
    errorMessage,
    id,
    testId,
    onChange,
    onBlur,
    onMultipleDropdownChange,
    className,
    options,
    multipleDropdownOptions,
    preventEnterKeyDown = true,
    children,
    minLength,
    canReorder = true,
    portalContainerClass,
  } = props;

  const fieldClass = getClass(fieldComponentName, { add: [className ?? ''] });
  const fieldId = getId(fieldComponentName, id);
  const fieldTestId = getTestId(fieldComponentName, testId);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>): void => {
    event.persist();
    if (onKeyPressEnter && event.key === 'Enter') {
      onKeyPressEnter();
    }
  };

  return (
    <div
      id={fieldId}
      className={fieldClass}
      data-testid={fieldTestId}
      onKeyPress={handleKeyPress}
    >
      <Wrapper>
        <Fields
          testId={fieldTestId}
          type={type}
          label={label}
          value={value}
          multiDropdownValue={multiDropdownValue}
          name={name}
          className={className}
          placeholder={placeholder}
          disabled={disabled}
          hasError={hasError}
          errorMessage={errorMessage}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPressEnter={handleKeyPress}
          onMultipleDropdownChange={onMultipleDropdownChange}
          options={options}
          multipleDropdownOptions={multipleDropdownOptions}
          preventEnterKeyDown={preventEnterKeyDown}
          minLength={minLength}
          canReorder={canReorder}
          portalContainerClass={portalContainerClass}
        >
          {children}
        </Fields>
      </Wrapper>
    </div>
  );
};

export default Field;
