import ActionType from 'store/actions/types';
import { Action } from 'types/action';
import { IEditManyUsersState, IValidatedCell } from 'types/edit-many-users';

export const initialState = {
  previewTable: [],
};

export default (
  state = initialState,
  action: Action<IValidatedCell[][]>,
): IEditManyUsersState => {
  const { payload, type } = action;
  switch (type) {
    case ActionType.ADMIN_USERS_SET_EDIT_MANY_PREVIEW_TABLE:
      return { ...state, previewTable: payload };
    case ActionType.ADMIN_USERS_RESET_EDIT_MANY_PREVIEW_TABLE:
      return { ...state, previewTable: [] };
    default:
      return state;
  }
};
