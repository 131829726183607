import Button, { Kind, Type, Size } from 'components/Button';
import Flex from 'components/Flex';
import H1 from 'components/H1';
import ListActions from 'components/ListActions';
import Table, { textOverflows, alignments } from 'components/Table';
import { getTestId, getComponentUuid, getClass } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Index, Actions, getUrl } from 'routes';
import IError from 'types/error';
import IUser from 'types/user';

const ADMIN_LIST_PAGE_SIZE = 10;

export const usersListComponentName = 'users-list';
export const usersListComponent = getComponentUuid(usersListComponentName);

type ContentProps = {
  users: IUser[];
  testId?: string;
  resetList: () => void;
};

const UsersList: FunctionComponent<ContentProps> = (props): ReactElement => {
  const { users, testId, resetList } = props;

  const navigate = useNavigate();
  const { checkPermissions } = useUserPermissions();
  const { doSuccessToast, doErrorToast } = useToast();
  const { doDelete, loading } = useItemAdmin<IUser>({
    endpoint: Index.SEGMENT_USERS,
  });

  const gotoCreate = (): void => {
    navigate(Actions.SEGMENT_CREATE);
  };

  const gotoEditMany = (): void => {
    navigate(Actions.SEGMENT_EDIT_MANY);
  };

  const handleUpdateClick = (user: IUser): void => {
    const updateUrl = getUrl([Actions.SEGMENT_EDIT, user.id ?? '']);
    navigate(updateUrl);
  };

  const handleDeleteClick = (user: IUser): void => {
    doDelete({
      item: user,
      onSuccess: (_, metaData): void => {
        const statusCode = metaData?.code;
        resetList();
        doSuccessToast(
          statusCode === 202
            ? 'User deleted in database. Auth0 counterpart not found'
            : 'User deleted',
        );
      },
      onError: (err: IError): void => {
        doErrorToast(err?.message ?? 'There has been an error.');
      },
    });
  };

  const usersListTestId = getTestId(usersListComponentName, testId);
  const usersListClass = getClass(usersListComponentName);

  return (
    <div className={usersListClass} data-testid={usersListTestId}>
      <header className={`${usersListClass}-header`}>
        <Flex>
          <H1>Users</H1>
          {checkPermissions('users::create') &&
            checkPermissions('users::delete') &&
            checkPermissions('users::update') && (
              <div className="bulk-update-btn-wrapper">
                <Button
                  kind={Kind.primary}
                  type={Type.button}
                  size={Size.small}
                  onClick={gotoEditMany}
                >
                  Bulk Update
                </Button>
              </div>
            )}
          {checkPermissions('users::create') && (
            <Button
              kind={Kind.primary}
              type={Type.button}
              size={Size.small}
              onClick={gotoCreate}
            >
              New
            </Button>
          )}
        </Flex>
      </header>
      <section>
        <Table<IUser>
          filter
          initialState={{ sortBy: [{ id: 'name' }] }}
          filterPlaceholder="Search for users"
          goToEditOnRowClick
          noResultsMessage="No results found"
          textOverflow={textOverflows.ellipsis}
          editItemSegment={Actions.SEGMENT_EDIT}
          alignment={[alignments.left, alignments.right]}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              width: '80%',
            },
            {
              Header: 'Actions',
              id: 'Actions',
              width: '20%',
              Cell: function UsersListTableActionsCell({
                row: { original: user },
              }: CellProps<IUser>): JSX.Element {
                return (
                  <ListActions<IUser>
                    loading={loading}
                    item={user}
                    canUpdate={checkPermissions('users::update')}
                    canDelete={checkPermissions('users::delete')}
                    onDeleteClick={(): void => handleDeleteClick(user)}
                    onUpdateClick={(): void => handleUpdateClick(user)}
                    testId={usersListTestId}
                    id={user.id}
                  />
                );
              },
            },
          ]}
          data={users}
          pageSize={ADMIN_LIST_PAGE_SIZE}
        />
      </section>
    </div>
  );
};

export default UsersList;
