export const calcHours = (total: number): number => Math.floor(total / 3600);
export const calcMins = (total: number): number =>
  Math.floor((total - calcHours(total) * 3600) / 60);
export const calcSecs = (total: number): number => total % 60;

export const humanizeDuration = (total: number): string => {
  const hours = calcHours(total);
  const minutes = calcMins(total);
  const seconds = calcSecs(total);

  const humanHours = hours ? `${hours} Hours` : null;
  const humanMinutes = minutes ? `${minutes} Minutes` : null;
  const humanSeconds = seconds ? `${seconds} Seconds` : null;

  return [humanHours, humanMinutes, humanSeconds].join(' ').trim();
};
