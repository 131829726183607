import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Actions } from 'routes';
import AttributionReportsEditContainer from './components/AttributionReportsEditContainer';
import AttributionReportsListContainer from './components/AttributionReportsListContainer';

const AttributionReports: FunctionComponent = () => {
  const { checkPermissions } = useUserPermissions();

  return (
    <Routes>
      {checkPermissions('reports::view') && (
        <Route
          path={`${Actions.SEGMENT_EDIT}/:id`}
          element={<AttributionReportsEditContainer />}
        />
      )}
      {checkPermissions('reports::create') && (
        <Route
          path={`${Actions.SEGMENT_CREATE}/:subType`}
          element={<AttributionReportsEditContainer />}
        />
      )}

      {(checkPermissions('attribution_reports.tv_tune_in.module::view') ||
        checkPermissions('attribution_reports.outcome.module::view')) && (
        <Route index element={<AttributionReportsListContainer />} />
      )}
      <Route
        path="*"
        element={
          <>
            <Navigate to="/" />
          </>
        }
      />
    </Routes>
  );
};
export default AttributionReports;
