import ClientSelector from 'components/ClientSelector';
import { ContextualUserMenu } from 'components/ContextualUserMenu';
import Flex, { Horizontal } from 'components/Flex';
import { format } from 'date-fns';
import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';
import { IClient } from '../../domains/clients/types';
import Item from './Item';

export const topBarComponentName = 'top-bar';

const dateTag = format(new Date(), 'EEE, MMM do, yyyy');

export interface ITopBarProps {
  clients?: IClient[];
  onSelectClient: (client: IClient) => void;
  selectedClient: IClient | undefined;
  testId?: string;
  userClients?: string[];
  userId?: string;
  userName?: string;
}

const TopBar: FunctionComponent<ITopBarProps> = (props): ReactElement => {
  const { testId } = props;

  const {
    userId,
    userName,
    userClients,
    clients,
    onSelectClient,
    selectedClient,
  } = props;

  const topBarClass = getClass(topBarComponentName);
  const topBarTestId = getTestId(topBarComponentName, testId);

  const hasClients = !!clients?.length;

  return (
    <div className={topBarClass} data-testid={topBarTestId}>
      <Flex horizontal={Horizontal.right} testId={topBarComponentName}>
        <div className={`${topBarClass}--item`}>
          <Item testId={`${topBarTestId}-date-tag`}>{dateTag}</Item>
        </div>
        {hasClients && (
          <div className={`${topBarClass}--item`}>
            <Item testId={`${topBarTestId}-client-tag`}>
              <ClientSelector
                userClients={userClients}
                clients={clients ?? []}
                selectedClient={selectedClient}
                onSelectClient={onSelectClient}
              />
            </Item>
          </div>
        )}
        <div className={`${topBarClass}--item`}>
          <Item testId={`${topBarTestId}-user-tag`}>
            <ContextualUserMenu userId={userId} userName={userName} />
          </Item>
        </div>
      </Flex>
    </div>
  );
};

export default TopBar;
