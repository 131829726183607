import { useNavigate } from 'react-router-dom';

const useRedirect = (): ((s: string) => void) => {
  const navigate = useNavigate();

  const redirectTo = (s: string): void => {
    navigate(s);
  };
  return redirectTo;
};

export default useRedirect;
