import { PerformanceGroupNames, ResultBase } from 'domains/reports/types';
import { isEmpty } from 'lodash';
import React from 'react';
import { IOption } from 'types/textValue';
import { getTop4BreakoutsByTarget } from 'helpers/visualization';
import { CardContent } from './CardContent';
import { OmissionAlertCard } from './OmissionAlertCard';

export interface ITopPersuadableAudiencesProps {
  selectedTarget: IOption;
  performanceMetric: PerformanceGroupNames;
  data: ResultBase;
  conversionsMetricName: string;
}

export const TopPersuadableAudiences: React.FC<
  ITopPersuadableAudiencesProps
> = ({
  selectedTarget: { value: target },
  data,
  performanceMetric,
  conversionsMetricName,
}) => {
  const top4BreakoutsByTarget = React.useMemo(
    () => getTop4BreakoutsByTarget(data, performanceMetric, target),
    [target, performanceMetric, data],
  );

  if (isEmpty(top4BreakoutsByTarget)) return <OmissionAlertCard />;

  return (
    <>
      {top4BreakoutsByTarget.map((card) => (
        <CardContent
          card={card}
          key={card.name?.DISPLAY_VALUE}
          performanceMetric={performanceMetric}
          conversionsMetricName={conversionsMetricName}
          selectedTarget={target as string}
        />
      ))}
    </>
  );
};
