import {
  addHours,
  endOfDay,
  isWithinInterval,
  parse,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { format as formatFP, formatWithOptions } from 'date-fns-tz/fp';
import { isWithinInterval as isWithinIntervalFP } from 'date-fns/fp';

import IReport, {
  DatasetDefaultDateRange,
  IDomainsDateRanges,
  reportType,
  timezones,
} from '../types';
import { Mode } from '../../../types/query';
import IDateRange from '../../../types/dateRange';
import { get } from 'lodash';
import * as reportSelectors from '../../../store/selectors/report';

export const getEasternTimeDate = (utcDate: string): Date =>
  utcToZonedTime(new Date(utcDate), 'America/New_York');

export const getBroadcastDayFromUtcDate = (utcDate: string): Date => {
  const localizedDate = getEasternTimeDate(utcDate);

  if (localizedDate.getHours() < 6) return subDays(localizedDate, 1);

  return localizedDate;
};

export const dateFormat = (locale: string): string => {
  if (locale === 'en-US') return 'MM-dd-yyyy';
  return 'dd-MM-yyyy';
};

export const getStartDate = (startDate: string): number => {
  const timezone = timezones.americaNewYork;
  const timezonedDay = zonedTimeToUtc(
    new Date(startDate + ' 00:00:00'),
    timezone,
  );
  const startOfBroadcastTimezonedDay = addHours(timezonedDay, 6);
  return startOfBroadcastTimezonedDay.getTime();
};

export const getEndDate = (endDate: string): number => {
  const timezone = timezones.americaNewYork;
  const timezonedDay = zonedTimeToUtc(
    new Date(endDate + ' 23:59:59'),
    timezone,
  );
  const endOfBroadcastTimezonedDay = addHours(timezonedDay, 6);
  return endOfBroadcastTimezonedDay.getTime();
};

export const formatISO = formatFP('yyyy-MM-dd');
export const formatUS = formatFP('MM-dd-yyyy');

export const getDatasetDefaultDateRange = (
  domainsDateRange: IDomainsDateRanges,
  dataset: Mode,
): DatasetDefaultDateRange => {
  const dateMin = domainsDateRange[dataset].min_date;
  const dateMax = domainsDateRange[dataset].max_date;

  return { dateMin, dateMax, dateStart: dateMax, dateEnd: dateMax };
};

export const datesInBounds = (
  val: string,
  min: string,
  max: string,
): boolean => {
  const date = parse(val, dateFormat(navigator.language), new Date());
  const start = startOfDay(new Date(min));
  const end = endOfDay(new Date(max));

  return isWithinInterval(date, { start, end });
};

export const rangesAreValid = (
  startDateString: string,
  endDateString: string,
  minDateString: string,
  maxDateString: string,
): boolean => {
  const start = parseISO(startDateString);
  const end = parseISO(endDateString);
  const min = parseISO(minDateString);
  const max = parseISO(maxDateString);

  const isBetweenMinAndMax = isWithinIntervalFP({
    start: startOfDay(min),
    end: endOfDay(max),
  });

  return isBetweenMinAndMax(start) && isBetweenMinAndMax(end);
};

const formatWithISO = formatWithOptions(
  { timeZone: 'America/New_York' },
  "yyyy-MM-dd'T'HH:mm:ssxxx",
);
export const getToday = (): string =>
  formatWithISO(getBroadcastDayFromUtcDate(new Date().toISOString()));

export const hasReportInvalidDates = (
  report: IReport,
  domainsDateRanges: IDomainsDateRanges,
): boolean => {
  let reportStartDate, reportEndDate;
  const newChosenMode = report.query.mode;
  if (isReport(report) || isTarget(report)) {
    const children = report.query.filters
      .children?.[0] as unknown as IDateRange;
    reportStartDate = Date.parse(children.start);
    reportEndDate = Date.parse(children.end);
  } else {
    const flightDates = reportSelectors.getExposureAdsFlightDates({
      report,
    });
    reportStartDate = Date.parse(flightDates.start);
    reportEndDate = Date.parse(flightDates.end);
  }

  const { dateMin: defaultDateMin, dateMax: defaultDateMax } =
    getDatasetDefaultDateRange(domainsDateRanges, newChosenMode as Mode);

  let isDateRangeValid = false;
  const startDateChosenByUserGreaterThanDateMin =
    reportStartDate > Date.parse(defaultDateMin);
  const endDateChosenByUserLessThanDateMax =
    reportEndDate < Date.parse(defaultDateMax);
  const startDateChosenByUserLessThanEndDateChosenByUser =
    reportStartDate < reportEndDate;

  isDateRangeValid =
    startDateChosenByUserGreaterThanDateMin &&
    endDateChosenByUserLessThanDateMax &&
    startDateChosenByUserLessThanEndDateChosenByUser;

  return !isDateRangeValid;
};

//isTarget and isReport are here because importing was not possible
export const isTarget = (report: IReport): boolean =>
  get(report, 'type') === reportType.target;

export const isReport = (report: IReport): boolean =>
  get(report, 'type') === reportType.report;
