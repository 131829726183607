import { defineUsersPreviewTableErrorMessage } from 'helpers/utils';
import { uniqueId } from 'lodash';
import React, { FunctionComponent, ReactElement } from 'react';
import {
  IValidatedCell,
  IOperationType,
  IValidatedData,
} from 'types/edit-many-users';

interface IEditManyTableProps {
  content: IValidatedCell[][];
}

const renderAction = (action: IOperationType): string => {
  if (action === IOperationType.create) return 'Create';
  if (action === IOperationType.update) return 'Update';
  if (action === IOperationType.delete) return 'Delete';
  return '';
};

export const EditManyTable: FunctionComponent<IEditManyTableProps> = ({
  content,
}): ReactElement | null => {
  if (!content) return null;
  return (
    <div className="edit-many-users-table">
      <table>
        <thead>
          <tr>
            <th>Action</th>
            <th>Name</th>
            <th>Email</th>
            <th>Clients</th>
            <th>Roles</th>
            <th>Modules</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          {content.map((contentRow) => (
            <tr key={uniqueId()}>
              <td>{renderAction(contentRow[0].value as IOperationType)}</td>
              <td>
                <span
                  className={
                    contentRow.some((item) => item.error)
                      ? 'errored'
                      : 'succeeded'
                  }
                >
                  {contentRow[1].value as string}
                </span>
              </td>
              <td>{contentRow[2].value as string}</td>
              <td>
                {(contentRow[3].value as IValidatedData[]).map(
                  (client, index) => (
                    <span
                      className={client.toDelete ? 'toDelete' : ''}
                      key={uniqueId()}
                    >
                      {index === (contentRow[3].value ?? []).length - 1
                        ? `${client.name}`
                        : `${client.name} | `}
                    </span>
                  ),
                )}
              </td>
              <td>
                {(contentRow[4].value as IValidatedData[]).map(
                  (role, index) => (
                    <span
                      className={role.toDelete ? 'toDelete' : ''}
                      key={uniqueId()}
                    >
                      {index === (contentRow[4].value ?? []).length - 1
                        ? `${role.name}`
                        : `${role.name} | `}
                    </span>
                  ),
                )}
              </td>
              <td>
                {(contentRow[5].value as IValidatedData[]).map(
                  (module, index) => (
                    <span
                      className={module.toDelete ? 'toDelete' : ''}
                      key={uniqueId()}
                    >
                      {index === (contentRow[5].value ?? []).length - 1
                        ? `${module.name}`
                        : `${module.name} | `}
                    </span>
                  ),
                )}
              </td>
              <td>{defineUsersPreviewTableErrorMessage(contentRow)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
