import { INITIAL_REPORTS_DATE } from 'domains/reports/constants';
import { IBusinessData } from 'domains/reports/types';
import { flow, get, set, merge } from 'lodash/fp';
import ActionType from 'store/actions/types';
import { Action } from 'types/action';
import { Modes } from 'types/query';

export const initialDomainsDateRanges = {
  [Modes.linear]: {
    max_date: new Date().toISOString().substring(0, 10),
    min_date: INITIAL_REPORTS_DATE,
  },
  [Modes.adverts]: {
    max_date: new Date().toISOString().substring(0, 10),
    min_date: INITIAL_REPORTS_DATE,
  },
  [Modes.customAdverts]: {
    max_date: new Date().toISOString().substring(0, 10),
    min_date: INITIAL_REPORTS_DATE,
  },
  [Modes.genericEvents]: {
    max_date: new Date().toISOString().substring(0, 10),
    min_date: INITIAL_REPORTS_DATE,
  },
  [Modes.placeIQ]: {
    max_date: new Date().toISOString().substring(0, 10),
    min_date: INITIAL_REPORTS_DATE,
  },
};

export const initialState = { domainsDateRanges: initialDomainsDateRanges };

export const setAvailableDimensions = set('availableDimensions');
export const setAvailableRulesDimensions = set('availableRulesDimensions');
export const setAvailableMetrics = set('availableMetrics');
export const setAvailableIntervals = set('availableIntervals');
export const setAvailableOrderFields = set('availableOrderFields');
export const setAvailableTargets = set('availableTargets');
export const setDomainsDateRanges = set('domainsDateRanges');

const reducer = (
  businessData: IBusinessData = initialState,
  action: Action<IBusinessData>,
): IBusinessData => {
  const { payload, type } = action;

  const rulesDimensions = get('rulesDimensions', payload);
  const dimensions = get('dimensions', payload);

  switch (type) {
    case ActionType.BUSINESS_DATA_SET_BUSINESS_DATA:
      return payload;
    case ActionType.BUSINESS_DATA_SET_AVAILABLE_METRICS:
      return setAvailableMetrics(payload, businessData);
    case ActionType.BUSINESS_DATA_SET_AVAILABLE_DIMENSIONS:
      return flow(
        setAvailableDimensions(dimensions),
        setAvailableRulesDimensions(rulesDimensions),
      )(businessData);
    case ActionType.BUSINESS_DATA_ADD_BUSINESS_DATA:
      return merge(businessData, payload);
    case ActionType.BUSINESS_DATA_RESET_AVAILABLE_DATA:
      return initialState;
    case ActionType.BUSINESS_DATA_SET_AVAILABLE_INTERVALS:
      return setAvailableIntervals(payload, businessData);
    case ActionType.BUSINESS_DATA_SET_AVAILABLE_ORDER_FIELDS:
      return setAvailableOrderFields(payload, businessData);
    case ActionType.BUSINESS_DATA_SET_AVAILABLE_TARGETS:
      return setAvailableTargets(payload, businessData);
    default:
      return businessData;
  }
};

export default reducer;
