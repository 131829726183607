import { DataLabelsFormatterCallbackFunction, Point } from 'highcharts';

interface IPointMarker {
  height: number;
  radius: number;
  width: number;
}
interface IPointExtended extends Point {
  marker: IPointMarker;
}

const bubbleLabelFormatter: DataLabelsFormatterCallbackFunction = function () {
  // avgCharWidth is based on LatoBold 12px font-face
  const avgCharWidth = 8;
  const name = this?.point?.name;
  const width = (this.point as IPointExtended)?.marker?.width ?? 0;
  const maxNameLength = width / avgCharWidth;

  if (name === 'Off Network') {
    return 'OFF';
  }
  if (name === 'On Network') {
    return 'ON';
  }

  if (name.length > maxNameLength) {
    return this?.point?.name?.slice(0, maxNameLength > 3 ? maxNameLength : 3);
  }
  return name;
};

export default bubbleLabelFormatter;
