import Button, { Kind as buttonKinds } from 'components/Button';
import Icon, {
  Color as iconColors,
  Size as iconSizes,
  Type as iconTypes,
} from 'components/Icon';
import { getClass, getId, getTestId } from 'helpers/components';
import { contains, get } from 'lodash/fp';
import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

export const inputComponentName = 'input';

export enum Type {
  text = 'text',
  search = 'search',
  password = 'password',
  checkbox = 'checkbox',
  select = 'select',
  number = 'number',
  typeahead = 'typeahead',
  multipleDropdownSelect = 'multipleDropdownSelect',
  children = 'children',
  dropdownSearch = 'dropdownSearch',
}

export enum States {
  error = 'error',
}

export type NewInputValue = string | boolean | number;

export interface IInputProps {
  confirmOnEnter?: boolean;
  type: Type;
  value?: string | number;
  checked?: boolean;
  testId?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  preventEnterKeyDown?: boolean;
  onEnterKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (newValue: NewInputValue) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  wrapperStyle?: CSSProperties;
  autocomplete?: boolean;
  valueIcon?: ReactNode;
  children?: ReactNode;
  loading?: boolean;
  truncateText?: boolean;
}

const allowedKeyOnNumberList = [
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'Backspace',
  'Tab',
];
const numRegExp = /^\d+$/;
const isNormalInteger = numRegExp.test.bind(numRegExp);

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (props, forwardedRef): ReactElement => {
    const {
      confirmOnEnter,
      type,
      name,
      placeholder,
      disabled,
      hasError,
      id,
      testId,
      value: defaultValue,
      checked,
      onChange,
      onBlur,
      onEnterKeyDown,
      onClick,
      preventEnterKeyDown = true,
      readonly,
      wrapperStyle,
      autocomplete = false,
      valueIcon,
      children,
      loading = false,
      truncateText = false,
    } = props;

    const [value, setValue] = useState(defaultValue ?? '');
    const [check, setCheck] = useState(!!checked);
    const [iconColor, setIconColor] = useState(iconColors.primary);

    React.useEffect(() => {
      setCheck(!!checked);
    }, [checked]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (type === Type.checkbox) {
        setCheck(!check);
        if (onChange) onChange(!check);
      } else {
        const newValue = event?.target?.value || '';
        setValue(newValue);
        if (onChange) onChange(newValue);
      }
    };

    const handleEnterKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>,
    ): void => {
      const eventKey = get('key', event);
      const isEnterKeyPress = eventKey === 'Enter';
      const inputShouldHandleOnEnterKeyPress =
        type !== Type.checkbox || confirmOnEnter;
      const inputTypeIsNumeric = type === Type.number;
      const eventKeyIsNumeric = isNormalInteger(eventKey);
      const isNaNAllowed = contains(eventKey, allowedKeyOnNumberList);

      if (preventEnterKeyDown && isEnterKeyPress) {
        event.preventDefault();
      }

      if (inputShouldHandleOnEnterKeyPress && isEnterKeyPress) {
        if (onEnterKeyDown) onEnterKeyDown(event);
      }

      if (
        inputTypeIsNumeric &&
        !eventKeyIsNumeric &&
        !isEnterKeyPress &&
        !isNaNAllowed
      ) {
        event.preventDefault();
      }
    };

    const handleFocus = (): void => {
      setIconColor(iconColors.primary);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      if (onBlur) {
        onBlur(event);
      }
      setIconColor(iconColors.primary);
    };

    const handleDeleteSearchValue = (): void => {
      setValue('');
      if (onChange) onChange('');
    };

    const isSelect = useCallback((): boolean => type === Type.select, [type]);

    const inputId = getId(inputComponentName, id);
    const inputTestId = getTestId(inputComponentName, testId);
    const inputWrapperClass = getClass(inputComponentName, {
      concat: ['wrapper'],
    });

    const inputClass = useMemo(
      () =>
        getClass(inputComponentName, {
          text: [type],
          boolean: [
            {
              state: hasError,
              class: States.error,
            },
            {
              state: isSelect(),
              class: 'select',
            },
            {
              state: truncateText,
              class: 'truncating',
            },
          ],
        }),
      [hasError, isSelect, type, truncateText],
    );

    const content =
      type === Type.children ? (
        <div data-testid={inputTestId} className={`${inputClass}`}>
          {children}
        </div>
      ) : (
        <>
          {valueIcon ?? []}
          <input
            ref={forwardedRef}
            max={Infinity}
            min={0}
            autoComplete={!autocomplete ? 'off' : 'on'}
            type={type}
            className={`${inputClass}`}
            data-testid={inputTestId}
            id={type === Type.checkbox ? id : inputId}
            data-lpignore={!autocomplete}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            checked={check}
            value={defaultValue === undefined ? value : defaultValue}
            readOnly={isSelect() || readonly}
            onChange={handleChange}
            onKeyDown={handleEnterKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={onClick}
          />
          {type === Type.search && (value === '' || !value) && !loading && (
            <Icon
              type={iconTypes.search}
              size={iconSizes.small}
              color={iconColor}
              testId={`${inputTestId}-${Type.search}`}
            />
          )}
          {type === Type.search && value && value !== '' && !loading && (
            <Button
              onClick={handleDeleteSearchValue}
              kind={buttonKinds.icon}
              iconType={iconTypes.close}
              iconSize={iconSizes.small}
              iconColor={iconColors.dark}
              testId={`${inputTestId}-delete`}
            />
          )}
          {type === Type.select && (
            <Icon
              type={iconTypes.carrot}
              color={iconColor}
              size={iconSizes.micro}
              testId={`${inputTestId}-${Type.select}`}
            />
          )}
        </>
      );

    return (
      <div className={inputWrapperClass} style={wrapperStyle}>
        {content}
      </div>
    );
  },
);

export default Input;
