import ChartOmissionAlert from 'components/ChartOmissionAlert';
import { getClass, getTestId } from 'helpers/components';
import Highcharts, { SeriesBarOptions, SeriesLineOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useChartReflow } from 'hooks/useChartReflow';
import React from 'react';
import { IMultiChartConfig, IMultiChartRefs } from 'types/MultiChart';
import { useMultiChartConfig } from './tools/useMultiChartConfig';

export const componentName = 'multichart';
const legendTopTestId = getTestId(componentName, 'legend-top');
const legendBottomTestId = getTestId(componentName, 'legend-bottom');
const chartTopTestId = getTestId(componentName, 'chart-top');
const chartBottomTestId = getTestId(componentName, 'chart-bottom');

interface IMultichart {
  chartConfig: IMultiChartConfig;
  legend?: {
    bottom?: React.ReactNode;
    top?: React.ReactNode;
  };
  testId?: string;
}

const multichartComponentName = 'multichart';
const multichartClass = getClass(multichartComponentName);

export const Multichart: React.FC<IMultichart> = ({
  chartConfig,
  legend,
  testId,
}) => {
  const [plotBoundaries, setPlotBoundaries] = React.useState({
    top: { top: 0, left: 0, width: 0, height: 0 },
    bottom: { top: 0, left: 0, width: 0, height: 0 },
  });

  const chartRefs = React.useRef<IMultiChartRefs>({
    bottom: undefined,
    top: undefined,
  });

  const { top: topOptions, bottom: bottomOptions } = useMultiChartConfig({
    chartConfig,
    chartRefs,
    setPlotBoundaries,
  });

  const { top: topLegend, bottom: bottomLegend } = legend ?? {};
  const multichartTestId = React.useMemo(
    () => getTestId(componentName, testId),
    [testId],
  );

  // NOTE (Jakub Mandra): separate callbacks to avoid chart 'render' calls
  // after state update (for top & bottom plotBoundaries)
  const addTopChartToRefs = React.useCallback(
    (chart: Highcharts.Chart): void => {
      chartRefs.current.top = chart;
    },
    [],
  );
  const addBottomChartToRefs = React.useCallback(
    (chart: Highcharts.Chart): void => {
      chartRefs.current.bottom = chart;
    },
    [],
  );

  const hasOmission = React.useMemo(() => {
    const doAllTopSeriesHaveEmptyData = topOptions.series?.every(
      (s) => !(s as SeriesBarOptions | SeriesLineOptions).data?.length,
    );

    const doAllBottomSeriesHaveEmptyData = bottomOptions.series?.every(
      (s) => !(s as SeriesBarOptions | SeriesLineOptions).data?.length,
    );

    return doAllTopSeriesHaveEmptyData && doAllBottomSeriesHaveEmptyData;
  }, [topOptions.series, bottomOptions.series]);

  // NOTE (Jakub Mandra): we use here 2 chart plot boundaries which should have same width,
  // top position is from top chart, height is a sum of top & bottom chart heights + margin between them,
  // additional pixels are used to cover the charts border
  const plotBoundary = {
    top: plotBoundaries.top.top - 2,
    left: plotBoundaries.top.left - 1,
    width: plotBoundaries.top.width + 2,
    height:
      4 +
        plotBoundaries.top.height +
        plotBoundaries.bottom.height +
        plotBoundaries.bottom.top +
        (bottomOptions?.chart?.margin as number[])?.[0] ??
      bottomOptions?.chart?.margin ??
      0 + (topOptions?.chart?.margin as number[])?.[2] ??
      topOptions?.chart?.margin ??
      0,
  };

  const containerRefTop = React.useRef<HTMLDivElement>(null);
  const containerRefBottom = React.useRef<HTMLDivElement>(null);

  useChartReflow(chartRefs.current.top, containerRefTop.current);
  useChartReflow(chartRefs.current.bottom, containerRefBottom.current);

  return (
    <div className={multichartClass} data-testid={multichartTestId}>
      {hasOmission && (
        <div className={`${multichartClass}__overlay`} style={plotBoundary}>
          <ChartOmissionAlert />
        </div>
      )}
      <div
        className={multichartClass}
        data-testid={chartTopTestId}
        ref={containerRefTop}
      >
        {topLegend && <div data-testid={legendTopTestId}>{topLegend}</div>}
        <HighchartsReact
          allowChartUpdate
          highcharts={Highcharts}
          options={topOptions}
          callback={addTopChartToRefs}
        />
      </div>
      <div
        className={multichartClass}
        data-testid={chartBottomTestId}
        ref={containerRefBottom}
      >
        {bottomLegend && (
          <div data-testid={legendBottomTestId}>{bottomLegend}</div>
        )}
        <HighchartsReact
          allowChartUpdate
          highcharts={Highcharts}
          options={bottomOptions}
          callback={addBottomChartToRefs}
        />
      </div>
    </div>
  );
};
