import { ChartDataSet } from 'domains/reports/types';
import { get, orderBy } from 'lodash';

export const sliceTargetByResultsCount = (
  chartDataSet: ChartDataSet[],
  count: number,
  sortedBy?: string,
): ChartDataSet[] => {
  if (!sortedBy) return chartDataSet.slice(0, count);
  const orderByFunc = (data: ChartDataSet): number => {
    const value = get(data, sortedBy);
    if (typeof value !== 'number') return Number.NEGATIVE_INFINITY;
    return value;
  };
  const sortedChartDataset = orderBy(chartDataSet, orderByFunc, ['desc']);
  return sortedChartDataset.slice(0, count);
};
