import SkeletonChartBar from 'components/Skeleton/ChartBar';
import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';

export const skeletonChartBarsComponentName = 'skeleton-chart-bars';

export enum Direction {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export enum Size {
  large = 'large',
  medium = 'medium',
  small = 'small',
  full = 'full',
}

export interface ISkeletonChartBarProps {
  size?: Size;
  direction?: Direction;
  testId?: string;
}

const SkeletonChartBars: FunctionComponent<ISkeletonChartBarProps> = (
  props,
): ReactElement => {
  const { size, direction, testId } = props;

  const skeletonChartBarsClass = getClass(skeletonChartBarsComponentName, {
    text: [size, direction],
  });
  const skeletonChartBarsTestId = getTestId(
    skeletonChartBarsComponentName,
    testId,
  );

  return (
    <div
      className={skeletonChartBarsClass}
      data-testid={skeletonChartBarsTestId}
    >
      <SkeletonChartBar length="30%" />
      <SkeletonChartBar length="50%" />
      <SkeletonChartBar length="90%" />
      <SkeletonChartBar length="40%" />
    </div>
  );
};

export default SkeletonChartBars;
