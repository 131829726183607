import { PanelLayout, Dashboard, IDashboardReport } from '../types';

export const replaceDashboardReport = (
  dashboardReport: IDashboardReport,
  dashboard: Dashboard,
): Dashboard => ({
  ...dashboard,
  reports: [
    ...dashboard.reports.filter(
      (item: IDashboardReport) => item.id !== dashboardReport.id,
    ),
    dashboardReport,
  ],
});

export const removeDashboardReport = (
  dashboardReport: IDashboardReport,
  dashboard: Dashboard,
): Dashboard => ({
  ...dashboard,
  reports: [
    ...dashboard.reports.filter(
      (item: IDashboardReport) => item.id !== dashboardReport.id,
    ),
  ],
});

export const updateDashboardReportLayout = (
  layouts: PanelLayout[],
  dashboard: Dashboard,
): Dashboard => ({
  ...dashboard,
  reports: dashboard.reports.map((report: IDashboardReport) => {
    const reportLayout = layouts.find((item) => item.i === report.id);
    return {
      ...report,
      row: reportLayout?.y ?? 0,
      col: reportLayout?.x ?? 0,
      sizeX: reportLayout?.w ?? 0,
      sizeY: reportLayout?.h ?? 0,
    };
  }),
});

export const getDashboardsByUserId = (
  dashboardsList: Dashboard[],
  userId: string,
): Dashboard | undefined =>
  dashboardsList.find((dashboard: Dashboard) => dashboard.userId === userId);
