import IReport from 'domains/reports/types';
import { trackFiltersChange } from 'helpers/mixpanel';
import ITextValue, { IOption } from 'types/textValue';
import { sectionName } from '..';

export enum ChartFilterActions {
  CHANGE_TARGET = 'CHANGE_TARGET',
  CHANGE_LEFT_AXIS = 'CHANGE_LEFT_AXIS',
  CHANGE_RIGHT_AXIS = 'CHANGE_RIGHT_AXIS',
  CHANGE_AGGREGATION = 'CHANGE_AGGREGATION',
}

export interface IChartFiltersState {
  selectedTargets: IOption[];
  leftYAxis: string | number;
  rightYAxis: string | number;
  aggregation: string | number;
  lastValidReport: IReport | null;
}

type ChartFilterAction =
  | {
      payload: ITextValue;
      type: Exclude<ChartFilterActions, ChartFilterActions.CHANGE_TARGET>;
    }
  | { payload: IOption[]; type: ChartFilterActions.CHANGE_TARGET };

export const chartFiltersReducer = (
  state: IChartFiltersState,
  action: ChartFilterAction,
): IChartFiltersState => {
  switch (action.type) {
    case ChartFilterActions.CHANGE_TARGET: {
      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: action.payload.map((target) => target.text),
      });

      return { ...state, selectedTargets: action.payload };
    }
    case ChartFilterActions.CHANGE_LEFT_AXIS: {
      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: action.payload.text,
      });
      const eqAdjustedValue =
        action.payload.text === 'Eq. Impressions'
          ? 'impressionsEQ'
          : action.payload.value;
      return { ...state, leftYAxis: eqAdjustedValue };
    }
    case ChartFilterActions.CHANGE_RIGHT_AXIS: {
      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: action.payload.text,
      });

      const eqAdjustedValue =
        action.payload.text === 'Eq. Impressions'
          ? 'impressionsEQ'
          : action.payload.value;
      return { ...state, rightYAxis: eqAdjustedValue };
    }
    case ChartFilterActions.CHANGE_AGGREGATION: {
      trackFiltersChange({
        lastValidReport: state.lastValidReport,
        actionType: action.type,
        sectionName,
        selection: action.payload.text,
      });

      return { ...state, aggregation: action.payload.value };
    }
    default: {
      return state;
    }
  }
};
