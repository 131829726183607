import { getClass, getTestId } from 'helpers/components';
import { get, noop } from 'lodash/fp';
import React, { ReactElement, useEffect, useState } from 'react';
import { Column, Row } from 'react-table';
import Icon, { Type as IconType } from '../Icon';
import useTimeout from './helpers/useTimeout';

export const tableRowComponentName = 'table-row';
const cellWithButtonComponentName = 'table-cell-with-button';
const DOM_KEY_REMOVABLE_ROW = 'removable';
const ACTION_KEY_ROW_REMOVING = 'removing';

export interface TableRowProps<T extends { [P in keyof T]: T[P] }> {
  row: Row<T>;
  rowIndex: number;
  pageIndex: number;
  TOTAL_PAGES_TO_SHOW: number;
  ROW_REMOVE_ACTION_DELAY: number;
  columns: Array<Column<T>>;
  role: string | undefined;

  canEditItem?: boolean;
  editItemSegment?: string;
  indexable?: boolean;
  tbodyTdStyle?: Record<string, unknown>;
  testId?: string;

  getCellClass: (columnIndex: number) => string;
  handleClickCell?: (itemId?: string) => void;
  onRowHover?: (row: Row<T> | null) => void;
  rowRemoveClickAction?: (row: Row<T> | null) => void;
}

const getRowOriginalId = get('row.original.id');

const TableRow = <T extends { [P in keyof T]: T[P] }>({
  row,
  rowIndex,
  pageIndex,
  TOTAL_PAGES_TO_SHOW,
  ROW_REMOVE_ACTION_DELAY,
  columns,

  canEditItem,
  editItemSegment,
  indexable,
  tbodyTdStyle,
  role,
  testId,

  getCellClass,
  handleClickCell,
  onRowHover,
  rowRemoveClickAction,
}: TableRowProps<T>): ReactElement => {
  const [currRow, setCurrRow] = useState(row);
  const [isRowRemoving, setIsRowRemoving] = useState(false);
  const hasRowRemoveAction = !!rowRemoveClickAction;

  useEffect(() => {
    if (!isRowRemoving) {
      setCurrRow(row);
    }
  }, [row, isRowRemoving]);

  const handleRowHover = (isHovered: boolean): void =>
    onRowHover?.(isHovered ? currRow : null);

  const handleRowRemoveBtnClick = (): void => {
    if (!hasRowRemoveAction) return;

    setIsRowRemoving(true);
  };

  const runDelayedRowRemoveAction = (): void => {
    // checked twice because ts complains during build
    if (!hasRowRemoveAction || !rowRemoveClickAction) return;

    rowRemoveClickAction(currRow);
    setIsRowRemoving(false);
  };

  useTimeout({
    callback: runDelayedRowRemoveAction,
    delay: isRowRemoving ? ROW_REMOVE_ACTION_DELAY : null,
  });

  const tableRowTestId = getTestId(tableRowComponentName, testId);

  const tableRowClass = getClass(tableRowComponentName, {
    boolean: [
      {
        state: hasRowRemoveAction,
        class: DOM_KEY_REMOVABLE_ROW,
        preventCollisions: true,
      },
      {
        state: isRowRemoving,
        class: ACTION_KEY_ROW_REMOVING,
        preventCollisions: true,
      },
    ],
  });

  const cellWithButtonClass = getClass(cellWithButtonComponentName);
  return (
    <tr
      role={role}
      data-testid={tableRowTestId}
      onMouseEnter={() => handleRowHover(true)}
      onMouseLeave={() => handleRowHover(false)}
      className={tableRowClass}
    >
      {currRow.cells.map((cell, cellIndex) => {
        const { key: cellKey, role: cellRole } = cell.getCellProps();
        const hasClick =
          editItemSegment && canEditItem && cell.column.id !== 'Actions';
        const isLastCell = cellIndex === currRow.cells.length - 1;

        if (indexable && cellIndex === 0) {
          return (
            <td key={cellKey}>
              <span>{rowIndex + 1 + pageIndex * TOTAL_PAGES_TO_SHOW}</span>
            </td>
          );
        }

        return (
          <td
            className={getCellClass(cellIndex)}
            key={cellKey}
            onClick={
              hasClick ? () => handleClickCell?.(getRowOriginalId(cell)) : noop
            }
            onKeyDown={noop}
            role={cellRole}
            style={{
              width: columns[cellIndex]?.width ?? 'auto',
              ...tbodyTdStyle,
            }}
          >
            {hasRowRemoveAction && isLastCell ? (
              <div className={cellWithButtonClass}>
                {cell.render('Cell')}
                <button
                  className={`${cellWithButtonClass}__button`}
                  onClick={handleRowRemoveBtnClick}
                  data-testid={`${tableRowTestId}-button`}
                  type="button"
                >
                  <Icon type={IconType.close2} />
                </button>
              </div>
            ) : (
              cell.render('Cell')
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default TableRow;
