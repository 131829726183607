import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { cloneDeep } from 'lodash';
import React, { FC, useMemo, useState, useEffect } from 'react';
import ITextValue from 'types/textValue';
import { Overlay } from './Overlay';
import { SortableItem } from './SortableItem';

interface SortableListProps {
  items: (string | ITextValue)[];
  minimumChipsSelected: number;
  testId?: string;
  disabled?: boolean;
  onChipClick: (index: number) => void;
  canReorder?: boolean;
  onSortEnd: (event: DragEndEvent) => void;
  onSortStart: (event: DragStartEvent) => void;
}

export const SortableList: FC<SortableListProps> = (props) => {
  const {
    items,
    minimumChipsSelected,
    onChipClick,
    canReorder,
    disabled,
    testId,
    onSortEnd,
    onSortStart,
  } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor),
  );

  const [widths, setWidths] = useState<{ [id: string]: number }>({});

  const itemIds: { id: UniqueIdentifier }[] = useMemo(
    () =>
      items.map((item) => ({
        id: typeof item === 'string' ? item : (item as ITextValue).text,
      })),
    [items],
  );
  const [tempOrder, setTempOrder] = useState<{ id: UniqueIdentifier }[]>([]);

  const handleOnSortStart = (event: DragStartEvent): void => {
    onSortStart(event);
  };
  const handleSortEnd = (event: DragEndEvent): void => {
    onSortEnd(event);
  };
  const handleDragOver = (event: DragOverEvent): void => {
    const { active, over } = event;
    const oldIndex = active.data.current?.sortable?.index;
    const newIndex = over?.data.current?.sortable?.index;
    const result = arrayMove(cloneDeep(itemIds), oldIndex, newIndex);
    setTempOrder(result);
  };

  const chipRemovable = (item: string | ITextValue): boolean => {
    const iTextValue = typeof item === 'object';
    if (iTextValue && item.fixed) {
      return false;
    }

    return items.length > minimumChipsSelected;
  };

  useEffect(() => {
    setTempOrder(itemIds);
  }, [itemIds]);
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleSortEnd}
      onDragStart={handleOnSortStart}
      onDragOver={handleDragOver}
    >
      <SortableContext
        items={itemIds}
        disabled={!canReorder}
        strategy={rectSortingStrategy}
      >
        {items.map((item, index) => (
          <SortableItem
            id={itemIds[index].id as string}
            key={itemIds[index].id as string}
            canRemove={chipRemovable(item)}
            testId={testId}
            canReorder={canReorder}
            chipDisabled={disabled}
            width={widths[tempOrder[index]?.id]}
            item={item}
            onWidthSet={(id, width) => {
              if (!widths?.[id]) {
                setWidths((prevState) => ({
                  ...prevState,
                  [id]: width,
                }));
              }
            }}
            onClick={() => {
              onChipClick(index);
            }}
          />
        ))}
        <Overlay
          chipDisabled={disabled ?? false}
          canRemove={items.length > minimumChipsSelected}
          widths={widths}
        />
      </SortableContext>
    </DndContext>
  );
};
