import { IFilters } from 'helpers/charts/chartWithTable/types';
import { ActionTypes } from '../reducer';

enum ActionToFilterKeyMap {
  CHANGE_TARGETS = 'targets',
  CHANGE_BREAKOUT = 'breakout',
  CHANGE_X_AXIS = 'xAxis',
  CHANGE_Y_AXIS = 'yAxis',
  CHANGE_BUBBLE_SIZE = 'bubbleSize',
}

enum ActionToFilterPrevKeyMap {
  CHANGE_TARGETS = 'targets',
  CHANGE_BREAKOUT = 'breakout',
  CHANGE_X_AXIS = 'prevXAxis',
  CHANGE_Y_AXIS = 'prevYAxis',
  CHANGE_BUBBLE_SIZE = 'prevBubbleSize',
}

type ActionToFiltersTypes =
  | ActionTypes.CHANGE_TARGETS
  | ActionTypes.CHANGE_BREAKOUT
  | ActionTypes.CHANGE_X_AXIS
  | ActionTypes.CHANGE_Y_AXIS
  | ActionTypes.CHANGE_BUBBLE_SIZE;

export const getFilterKey: (
  actionType: ActionToFiltersTypes,
) => keyof IFilters = (actionType) => ActionToFilterKeyMap[actionType];

export const getPrevFilterKey: (
  actionType: ActionToFiltersTypes,
) => keyof IFilters = (actionType) => ActionToFilterPrevKeyMap[actionType];
