import { Placement } from 'components/Popover';
import { Style, Tooltip } from 'components/Tooltip';
import React, { ReactElement, useCallback } from 'react';
import { ColumnInstance, HeaderGroup, TableHeaderProps } from 'react-table';
import { v4 as uuid } from 'uuid';

type ColumnInstanceExtended<T extends Record<string, unknown>> =
  ColumnInstance<T> & {
    tooltip?: string;
  };

export interface IHeaderColumn {
  colSpan: number;
  key?: string;
  role?: string;
  colspan?: number;
  label?: string;
}

type ThRowProps<T extends { [P in keyof T]: T[P] }> = {
  footer?: boolean;
  getCellAlignment: (columnIndex: number) => string;
  getCellTextOverflow?: () => string;
  header?: boolean;
  headerGroups: HeaderGroup<T>[];
  tableHeadClass: string;
  sort?: boolean;
  overrideHeaders?: IHeaderColumn[][];
};

const ThRow = <T extends { [P in keyof T]: T[P] }>({
  footer,
  getCellAlignment,
  getCellTextOverflow,
  header,
  headerGroups,
  tableHeadClass,
  overrideHeaders,
}: ThRowProps<T>): ReactElement => {
  const getThClass = useCallback(
    (columnIndex: number): string => {
      const cellAlignment = getCellAlignment(columnIndex);

      if (getCellTextOverflow) {
        const cellTextOverflow = getCellTextOverflow();
        return `${cellAlignment} ${cellTextOverflow}`;
      }

      return cellAlignment;
    },
    [getCellAlignment, getCellTextOverflow],
  );

  const content = headerGroups.map((headerGroup: HeaderGroup<T>) => {
    const { key, role } = headerGroup.getHeaderGroupProps();
    return (
      <tr key={key} role={role}>
        {headerGroup.headers.map(
          (column: ColumnInstanceExtended<T>, index: number) => {
            const headerProps = column.getHeaderProps(
              column.getSortByToggleProps(),
            ) as IHeaderColumn & TableHeaderProps;
            const headerPropsStyle = headerProps.style;
            const lastColumn = headerGroup.headers.length - 1 === index;

            const thContent = column.tooltip ? (
              <Tooltip
                placement={lastColumn ? Placement.auto : Placement.top}
                style={Style.secondary}
                content={column.tooltip}
              >
                {column.Header as string}
              </Tooltip>
            ) : (
              column.Header
            );

            const sortDirectionIndicator = column.isSortedDesc ? ' ↓' : ' ↑';

            return (
              <th
                {...headerProps}
                key={headerProps.key}
                colSpan={headerProps.colSpan}
                style={{
                  ...(headerPropsStyle ?? {}),
                  width: column.width,
                }}
                role={headerProps.role}
                className={getThClass(index)}
              >
                <>
                  {thContent}
                  {column.isSorted && (
                    <span className="sort-direction-indicator">
                      {sortDirectionIndicator}
                    </span>
                  )}
                </>
              </th>
            );
          },
        )}
      </tr>
    );
  });

  if (header) {
    return (
      <thead className={tableHeadClass}>
        {overrideHeaders
          ? overrideHeaders.map((currentHeader: IHeaderColumn[]) => (
              <tr key={uuid()}>
                {currentHeader.map((headerCell: IHeaderColumn) => (
                  <th
                    colSpan={headerCell.colspan}
                    className="wrap"
                    key={headerCell.key}
                  >
                    <span>{headerCell.label}</span>
                  </th>
                ))}
              </tr>
            ))
          : []}

        {content}
      </thead>
    );
  }

  if (footer) {
    return <tfoot className={tableHeadClass}>{content}</tfoot>;
  }

  return content[0];
};

export default ThRow;
