import IReport, {
  reportType,
  ReportType,
  RulesetConfig,
  EngineEvent,
  NotPersistedFacts,
} from 'domains/reports/types';
import { Engine, EngineResult } from 'json-rules-engine';
import { isEmpty, noop, get } from 'lodash/fp';
import { batch } from 'react-redux';
import { Dispatch } from 'redux';
import store from 'store';
import { getEvents } from 'store/selectors/events';
import attributionReportRules from './attributionReport.json';
import baseReportRules from './baseReport.json';
import targetRules from './target.json';

const getElseEvents = (
  results: EngineResult,
  reportRules: RulesetConfig,
): EngineEvent[] => {
  const elseEvents: EngineEvent[] = [];

  results.failureEvents.forEach((failureEvent) => {
    const failureEventType = failureEvent.type;
    if (failureEventType) {
      const foundElseType =
        reportRules[failureEventType as keyof typeof baseReportRules].event
          .elseType;
      if (foundElseType) {
        elseEvents.push({
          type: foundElseType,
        });
      }
    }
  });

  return elseEvents;
};

export const getRulesetActions = (type: ReportType): string[] => {
  let ruleConfigs: RulesetConfig = {};
  switch (type) {
    case reportType.report:
      ruleConfigs = { ...baseReportRules };
      break;
    case reportType.target:
      ruleConfigs = { ...baseReportRules, ...targetRules };
      break;
    case reportType.attributionReport: {
      ruleConfigs = { ...baseReportRules, ...attributionReportRules };
      break;
    }
    default:
      break;
  }
  const rules: string[] = [];

  Object.keys(ruleConfigs).forEach((ruleSet) => {
    rules.push(ruleSet);
    const elseRule = get('event.elseType', ruleConfigs[ruleSet]);
    if (elseRule) rules.push(elseRule);
  });
  return rules;
};

const getConditions = (
  notPersistedFacts: NotPersistedFacts,
  baseReport: IReport,
  savedReport: IReport,
): NotPersistedFacts => {
  const state = store.getState();
  const events = getEvents(state);
  return {
    ...(baseReport?.notPersistedProps ?? {}),
    ...notPersistedFacts,
    isBaseReportNew: !!baseReport.notPersistedProps?.isBaseReportNew,
    userCanAudit:
      !!baseReport?.notPersistedProps?.userCanAudit || !isEmpty(events),
    isBaseReportGranularityChanged:
      savedReport.query.level !== baseReport.query.level,
    isBaseReportNameChanged: savedReport.name !== baseReport.name,
    isBaseReportPropertyChanged: savedReport.isPublic !== baseReport.isPublic,
    isBaseReportSampleFactorChanged:
      savedReport.query.sample_factor !== baseReport.query.sample_factor,
    isBaseReportTimeFrameChanged:
      savedReport.query.time_frame !== baseReport.query.time_frame,
    isBaseReportTimeZoneChanged:
      savedReport.query.time_zone !== baseReport.query.time_zone,
    isBaseReportWeightChanged:
      savedReport.query.weight !== baseReport.query.weight,
    isBaseReportWithoutName: !baseReport.name,
    report: baseReport as IReport,
    savedReport: savedReport as IReport,
  };
};

export const runRuleset = async (
  baseReport?: IReport,
  savedReport?: IReport,
  notPersistedFacts?: NotPersistedFacts,
): Promise<EngineResult> => {
  if (!baseReport || !(baseReport as IReport)?.query) return new Promise(noop);
  if (!savedReport || !(baseReport as IReport)?.query) return new Promise(noop);

  const summarizedRules = {
    ...baseReportRules,
    ...(baseReport.type === reportType.target && { ...targetRules }),
    ...(baseReport.type === reportType.attributionReport && {
      ...attributionReportRules,
    }),
  };

  const engine = new Engine([], {
    allowUndefinedFacts: true,
  });

  engine.addOperator(
    'arrayLengthLessOrEqualThan',
    (factValue: number[] | string[], jsonValue: number) =>
      factValue?.length <= jsonValue,
  );

  Object.keys(summarizedRules).forEach((rule) => {
    // @ts-ignore
    engine.addRule(summarizedRules[rule]);
  });

  const conditions = getConditions(
    notPersistedFacts ?? {},
    baseReport,
    savedReport,
  );

  const facts = {
    ...notPersistedFacts,
    ...conditions,
  };

  const results = await engine.run(facts);
  const elseEvents = getElseEvents(results, summarizedRules);
  results.events.push(...elseEvents);
  return results;
};

export const processRulesetWithRedux = async (
  baseReport: IReport,
  dispatch: Dispatch,
): Promise<string[]> => {
  const result = await runRuleset(
    baseReport,
    baseReport,
    baseReport?.notPersistedProps,
  );
  const eventTypes = result.events.map((event) => event.type);
  const toggleActions = getRulesetActions(baseReport.type ?? reportType.report);
  batch(() => {
    eventTypes.forEach((event) => {
      if (toggleActions.includes(event)) dispatch({ type: event });
    });
  });

  return eventTypes;
};
