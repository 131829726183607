import Flex, { Horizontal, Vertical } from 'components/Flex';
import H3 from 'components/H3';
import Icon, { Type as IconType, Color as IconColor } from 'components/Icon';
import { getTestId, getClass } from 'helpers/components';
import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useState,
  useMemo,
  useEffect,
} from 'react';
import AnimateHeight from 'react-animate-height';

export const componentName = 'accordion';

export const DOM_KEY_ICON = 'icon';
export const DOM_KEY_HEADER = 'header';
export const DOM_KEY_TITLE = 'title';
export const DOM_KEY_BODY = 'body';
export const DOM_KEY_HEADER_TOOLBAR = 'header-toolbar';
export const DOM_KEY_FOOTER = 'footer';

export interface IAccordionProps {
  children: ReactNode;
  className?: string;
  footerLink?: ReactElement;
  headerToolbar?: ReactElement;
  onChange?: (state: boolean) => void;
  open?: boolean;
  testId?: string;
  title: string;
  onOpeningFinished?: () => void;
}

const Accordion: FunctionComponent<IAccordionProps> = (props): ReactElement => {
  const {
    children,
    className,
    footerLink,
    headerToolbar,
    open = false,
    testId,
    title,
    onChange,
    onOpeningFinished,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(open);

  useEffect(() => {
    document.dispatchEvent(new Event('click'));
    setIsOpen(open);
  }, [open, title]);

  const handleOnToggleExpand = (): void => {
    document.dispatchEvent(new Event('click'));
    if (onChange) {
      onChange(!isOpen);
    }

    setIsOpen(!isOpen);
  };

  const rootClass = useMemo(
    () => getClass(componentName, { add: [className ?? ''] }),
    [className],
  );
  const headerToolbarClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_HEADER_TOOLBAR] }),
    [],
  );
  const bodyClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_BODY] }),
    [],
  );
  const footerClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_FOOTER] }),
    [],
  );
  const titleClass = useMemo(
    () => getClass(componentName, { concat: [DOM_KEY_TITLE] }),
    [],
  );

  const headerClass = useMemo(
    () =>
      getClass(componentName, {
        concat: [DOM_KEY_HEADER],
        boolean: [
          {
            state: isOpen,
            class: 'open',
          },
        ],
      }),
    [isOpen],
  );

  const iconClass = useMemo(
    () =>
      getClass(componentName, {
        concat: [DOM_KEY_ICON],
        boolean: [
          {
            state: isOpen,
            class: 'open',
          },
        ],
      }),
    [isOpen],
  );

  const rootTestId = useMemo(() => getTestId(componentName, testId), [testId]);
  const headerTestId = useMemo(
    () => getTestId(componentName, DOM_KEY_HEADER),
    [],
  );
  const bodyTestId = useMemo(() => getTestId(componentName, DOM_KEY_BODY), []);
  const footerTestId = useMemo(
    () => getTestId(componentName, DOM_KEY_FOOTER),
    [],
  );

  return (
    <section className={rootClass} data-testid={rootTestId}>
      <header className={headerClass} data-testid={headerTestId}>
        <Flex
          horizontal={headerToolbar ? Horizontal.between : Horizontal.center}
          vertical={Vertical.between}
        >
          <Flex horizontal={Horizontal.left} vertical={Vertical.middle}>
            <div
              className={iconClass}
              onClick={handleOnToggleExpand}
              onKeyPress={(): void => {}}
            >
              <Icon type={IconType.carrot} color={IconColor.primary} />
            </div>
            <div
              className={titleClass}
              onClick={handleOnToggleExpand}
              onKeyPress={(): void => {}}
            >
              <H3>{title}</H3>
            </div>
          </Flex>
          {headerToolbar && (
            <div className={headerToolbarClass}>{headerToolbar}</div>
          )}
        </Flex>
      </header>
      <AnimateHeight
        duration={500}
        height={isOpen ? 'auto' : 0}
        onHeightAnimationEnd={onOpeningFinished}
      >
        <section className={bodyClass} data-testid={bodyTestId}>
          {children}
        </section>
        {footerLink && (
          <footer className={footerClass} data-testid={footerTestId}>
            {footerLink}
          </footer>
        )}
      </AnimateHeight>
    </section>
  );
};

export default Accordion;
