export const STATUS_CHECK_INTERVAL_SECONDS = 2;

/* Metric related */
export const defaultMetrics = ['REACH'];
export const defaultCharts = ['column'];

/* Result related */
export const optionAxisTypes = {
  datetime: 'datetime',
  category: 'category',
  linear: 'linear',
  logarithmic: 'logarithmic',
} as const;

export const stackings = {
  stacked: 'normal',
  percentageStacked: 'percent',
  unstacked: '',
} as const;

/* Date related */
export const CONTENT_OFFSET_DAYS = 3;
export const ADS_OFFSET_DAYS = 5;
export const CUSTOM_OFFSET_DAYS = 0;
export const GENERIC_OFFSET_DAYS = 0;
export const INITIAL_REPORTS_DATE = '2020-01-01';
export const GENPOP_TARGET_ID = 'genpop';
export const DEFAULT_UNIVERSE_NAME = 'All US TV Households';
export const CROSSWALKED_FORMAT = 'csv-target-crosswalked';

export const daysOfWeek = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
};
