import Loading from 'components/Loading';
import useSelectedClient from 'domains/clients/useSelectedClient';
import Dashboard from 'domains/dashboard/types';
import useFetch from 'hooks/useFetch';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Index } from 'routes';
import * as businessDataActions from 'store/actions/businessData';
import dashboardActions from 'store/actions/dashboard';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import DashboardDetailFeature from '../../detail.feature';

type Props = {
  setUserDashboard: (payload: Dashboard) => void;
  fetchTargets: (extId?: string | undefined) => void;
};

const DashboardDetailContainer: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const { setUserDashboard, fetchTargets } = props;
  const [dashboard, setDashboard] = useState<Dashboard>();
  const [loading, setLoading] = useState(false);
  const [dashboardFetched, setDashboardFetched] = useState(false);
  const { selectedClient } = useSelectedClient();
  const { doErrorToast } = useToast();
  const { doFetch } = useFetch<Dashboard>();
  const { id: dashboardId } = useParams<{ id: string }>();

  useEffect(() => {
    if (!dashboardFetched && !dashboard && !loading) {
      const dashboardUrl = dashboardId
        ? `/${Index.SEGMENT_DASHBOARDS}/${dashboardId}?clientExtID=${selectedClient?.extID}`
        : `/${Index.SEGMENT_DASHBOARDS}/?clientExtID=${selectedClient?.extID}`;

      setLoading(true);
      setDashboardFetched(true);
      fetchTargets();
      doFetch({
        endpoint: dashboardUrl,
        method: FetchMethod.GET,
        onSuccess: (res: Dashboard[]) => {
          if (!res?.length) {
            doErrorToast('Cannot get list of dashboards');
            return;
          }

          setLoading(false);
          setDashboard(res[0]);
          setUserDashboard(res[0]);
        },
        onError: (error: IError) => {
          setLoading(false);
          doErrorToast(error?.message ?? 'There has been an error.');
        },
      });
    }
  }, [
    doErrorToast,
    doFetch,
    loading,
    selectedClient.extID,
    dashboard,
    selectedClient,
    dashboardId,
    dashboardFetched,
    setUserDashboard,
    fetchTargets,
  ]);

  const resetDashboard = (): void => {
    setDashboardFetched(false);
    setDashboard(undefined);
  };

  let { id } = useParams<{ id: string }>();

  if (!id) {
    id = selectedClient?.defaultDash ?? id;
  }

  return (
    <>
      {dashboard ? (
        <DashboardDetailFeature
          dashboard={dashboard}
          loading={loading}
          resetDashboard={resetDashboard}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

const mapDispatchToProps = {
  setUserDashboard: dashboardActions.dashboardSetUserDashboard,
  fetchTargets: businessDataActions.fetchTargetsAction,
};

export default connect(
  null,
  mapDispatchToProps,
)(React.memo(DashboardDetailContainer));
