import Flex from 'components/Flex';
import H1 from 'components/H1';
import Tabs from 'components/Tabs';
import { useDatasets } from 'domains/datasets/hooks';
import { IFeatureProp } from 'features';
import { getClass } from 'helpers/components';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Actions, Index } from 'routes';
import DatasetList from './datasets/list.feature';
import QueriesList from './queries/list.feature';
import ToolsList from './tools/list.feature';
import UsersEditContainer from './users/components/UsersEditContainer';
import UsersListContainer from './users/components/UsersListContainer';
import ConfigOptionsEdit from './configOptions/edit.feature';
import ConfigOptionsListContainer from './configOptions/components/ConfigOptionsListContainer';
import UsersEditMany from './users/edit-many.feature';
import Uploads from './uploads';

export const adminComponentName = 'admin';

const LOCALE_ADMIN_TITLE = 'Admin';

const DOM_KEY_ADMIN_HEADER = 'header';
const DOM_KEY_ADMIN_PANEL_CONTENT = 'content';

const Admin: FunctionComponent<IFeatureProp> = ({ path }) => {
  const { datasets } = useDatasets();
  const { checkPermissions } = useUserPermissions();

  const adminClass = getClass(adminComponentName);
  const adminHeaderClass = getClass(adminComponentName, {
    concat: [DOM_KEY_ADMIN_HEADER],
  });
  const adminPanelContentClass = getClass(adminComponentName, {
    concat: [DOM_KEY_ADMIN_PANEL_CONTENT],
  });

  const userListPanel = {
    title: 'User List',
    path: 'users',
    id: 'users',
    panelContent: (
      <section className={adminPanelContentClass}>
        <Routes>
          <Route path={Index.SEGMENT_USERS} element={<UsersListContainer />} />
          {checkPermissions('users::view') && (
            <Route
              path={`${Index.SEGMENT_USERS}/${Actions.SEGMENT_CREATE}`}
              element={<UsersEditContainer />}
            />
          )}
          {checkPermissions('users::update') && (
            <Route
              path={`${Index.SEGMENT_USERS}/${Actions.SEGMENT_EDIT}/:id`}
              element={<UsersEditContainer />}
            />
          )}
          {checkPermissions('users::update') &&
            checkPermissions('users::create') &&
            checkPermissions('users::delete') && (
              <Route
                path={`${Index.SEGMENT_USERS}/${Actions.SEGMENT_EDIT_MANY}`}
                element={<UsersEditMany />}
              />
            )}
          <Route path="*" element={<Navigate to={Index.SEGMENT_USERS} />} />
        </Routes>
      </section>
    ),
  };

  const queryPanel = {
    title: 'Queries',
    id: 'queries',
    path: 'queries',
    panelContent: (
      <section className={adminPanelContentClass}>
        <Routes>
          <Route path={Index.SEGMENT_QUERIES} element={<QueriesList />} />
          <Route path="*" element={<Navigate to={Index.SEGMENT_QUERIES} />} />
        </Routes>
      </section>
    ),
  };

  const uploadsPanel = {
    title: 'Uploads',
    path: 'uploads',
    id: 'uploads',
    panelContent: (
      <section className={adminPanelContentClass}>
        <Routes>
          <Route
            path={Index.SEGMENT_UPLOADS}
            element={<DatasetList datasets={datasets} />}
          />

          {checkPermissions('datasets::create') && (
            <Route
              path={`${Index.SEGMENT_UPLOADS}/${Actions.SEGMENT_CREATE}/:type`}
              element={<Uploads />}
            />
          )}
          {checkPermissions('datasets::view') && (
            <Route
              path={`${Index.SEGMENT_UPLOADS}/${Actions.SEGMENT_EDIT}/:id`}
              element={<Uploads />}
            />
          )}
          <Route path="*" element={<Navigate to={Index.SEGMENT_UPLOADS} />} />
        </Routes>
      </section>
    ),
  };

  const toolsPanel = {
    title: 'Tools',
    path: 'tools',
    id: 'tools',
    panelContent: (
      <section className={adminPanelContentClass}>
        <Routes>
          <Route path={Index.SEGMENT_TOOLS} element={<ToolsList />} />
          <Route path="*" element={<Navigate to={Index.SEGMENT_TOOLS} />} />
        </Routes>
      </section>
    ),
  };

  const configOptionsPanel = {
    title: 'Config Options',
    path: 'config-options',
    id: 'config-options',
    panelContent: (
      <section className={adminPanelContentClass}>
        <Routes>
          <Route
            path={Index.SEGMENT_CONFIG_OPTIONS}
            element={<ConfigOptionsListContainer />}
          />
          <Route
            path={`${Index.SEGMENT_CONFIG_OPTIONS}/${Actions.SEGMENT_CREATE}`}
            element={<ConfigOptionsEdit />}
          />
          <Route
            path={`${Index.SEGMENT_CONFIG_OPTIONS}/${Actions.SEGMENT_EDIT}/:id`}
            element={<ConfigOptionsEdit />}
          />
          <Route
            path="*"
            element={<Navigate to={Index.SEGMENT_CONFIG_OPTIONS} />}
          />
        </Routes>
      </section>
    ),
  };

  const panels = [
    userListPanel,
    queryPanel,
    uploadsPanel,
    configOptionsPanel,
    toolsPanel,
  ].filter(
    (panel) =>
      (panel.path === 'users' && checkPermissions('users::view')) ||
      (panel.path === 'queries' && checkPermissions('queries::view')) ||
      (panel.path === 'uploads' && checkPermissions('datasets.list::view')) ||
      (panel.path === 'tools' && checkPermissions('admins_tools::view')) ||
      panel.path === 'config-options',
  );

  return (
    <div className={adminClass}>
      <header className={adminHeaderClass}>
        <Flex>
          <H1>{LOCALE_ADMIN_TITLE}</H1>
        </Flex>
      </header>
      <Tabs panels={panels} path={path} />
    </div>
  );
};

export default Admin;
