import useSelectedClient from 'domains/clients/useSelectedClient';
import IDashboard from 'domains/dashboard/types';
import useFetch from 'hooks/useFetch';
import useToast from 'hooks/useToast';
import React, { ReactElement, useEffect, useState } from 'react';
import { Index } from 'routes';
import IError from 'types/error';
import FetchMethod from 'types/fetchMethod';
import DashboardsListFeature from '../../list.feature';

interface IProps {
  testId?: string;
}

const LOCALE_DASBOARDS_LIST_TITLE = 'Dashboards';

const DashboardsListContainer = (props: IProps): ReactElement => {
  const [dashboards, setDashboards] = useState<IDashboard[]>();
  const [loading, setLoading] = useState(false);
  const {
    selectedClient: { extID },
  } = useSelectedClient();
  const { doErrorToast } = useToast();
  const { testId } = props;
  const { doFetch } = useFetch<IDashboard>();

  useEffect(() => {
    if (!dashboards && !loading) {
      setLoading(true);
      doFetch({
        endpoint: `/${Index.SEGMENT_DASHBOARDS}/list?clientExtID=${extID}`,
        method: FetchMethod.GET,
        onSuccess: (res: IDashboard[]) => {
          setLoading(false);
          setDashboards(res);
        },
        onError: (error: IError) => {
          setLoading(false);
          setDashboards([]);
          doErrorToast(error?.message ?? 'There has been an error.');
        },
      });
    }
  }, [doErrorToast, doFetch, loading, extID, dashboards]);

  const resetDashboards = (): void => setDashboards(undefined);

  return (
    <DashboardsListFeature
      title={LOCALE_DASBOARDS_LIST_TITLE}
      testId={testId}
      dashboards={dashboards ?? []}
      setDashboards={setDashboards}
      resetList={resetDashboards}
      loading={loading}
    />
  );
};

export default DashboardsListContainer;
