import { useEffect, useState } from 'react';

export const useDebounce = <T>(
  value: T | undefined,
  delay: number,
): T | undefined => {
  const [debouncedValue, setDebouncedValue] = useState<T>();
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay ?? 200);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
};
