import Icon, {
  Type as IconType,
  Color as IconColor,
  Size as IconSize,
} from 'components/Icon';
import { getClass } from 'helpers/components';
import React from 'react';

interface ISampleLegend {
  items: Array<{
    title: string;
    icon: IconType;
  }>;
  position?: {
    top?: number | string;
    right?: number | string;
    margin?: string;
  };
}

export const sampleLegendComponentName = 'sample-legend';
const sampleLegendClass = getClass(sampleLegendComponentName);
const sampleLegendItemClass = getClass(sampleLegendComponentName, {
  concat: ['item'],
});
const sampleLegendItemTitleClass = getClass(sampleLegendComponentName, {
  concat: ['item-title'],
});

export const SampleLegend: React.FC<ISampleLegend> = ({
  items,
  position = { right: 100, top: 25, margin: '0 0 0 0' },
}) => (
  <div className={sampleLegendClass} style={{ ...position }}>
    {items.map(({ title, icon }) => (
      <div
        className={sampleLegendItemClass}
        data-testid={sampleLegendItemClass}
        key={`${title}${icon}`}
      >
        <Icon type={icon} color={IconColor.dark} size={IconSize.small} />
        <span className={sampleLegendItemTitleClass}>{title}</span>
      </div>
    ))}
  </div>
);
