import Icon, { Color, Type as IconType } from 'components/Icon';
import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { showTabWarningSign } from '../adapters';
import { ICustomValuesTabProps } from '../types';
import { MAPPED_VALUE_PLACEHOLDER } from '../locale';

export const CustomValuesTab: FC<ICustomValuesTabProps> = ({
  errors,
  touched,
  index,
  customValue,
}) => (
  <div>
    {isEmpty(customValue.mapped_value)
      ? MAPPED_VALUE_PLACEHOLDER
      : customValue.mapped_value}{' '}
    {showTabWarningSign(errors, touched, index) && (
      <Icon type={IconType.warning} color={Color.red} />
    )}
  </div>
);
