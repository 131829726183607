import { getClass, getTestId } from 'helpers/components';
import React, { FunctionComponent, ReactNode } from 'react';
import ReactModal from 'react-modal';

export const modalComponentName = 'modal';

export enum Type {
  fullScreen = 'full-screen',
  dialog = 'dialog',
  auto = 'auto',
}

type Props = {
  children: ReactNode;
  customClass?: string;
  isOpen: boolean;
  onAfterOpen?: () => void;
  onCloseClick?: () => void;
  showSeparators?: boolean;
  testId?: string;
  type?: Type;
};

const customAutoStyles = {
  content: {
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Modal: FunctionComponent<Props> = (props: Props) => {
  const {
    children,
    customClass,
    isOpen,
    onAfterOpen,
    onCloseClick,
    showSeparators = true,
    testId,
    type = Type.fullScreen,
  } = props;

  const modalTestId = getTestId(modalComponentName, testId);
  const modalClasses = getClass(modalComponentName, {
    add: [type],
    boolean: [
      {
        state: !!customClass,
        class: customClass,
      },
    ],
  });
  const overlayClasses = getClass(modalComponentName, { concat: ['overlay'] });
  const containerClasses = getClass(`${modalComponentName}-container`, {
    boolean: [{ state: showSeparators, class: 'separators' }],
  });

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onCloseClick}
      closeTimeoutMS={200}
      className={modalClasses}
      style={type === Type.auto ? customAutoStyles : {}}
      overlayClassName={overlayClasses}
      role="dialog"
    >
      <div data-testid={modalTestId} className={containerClasses}>
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
