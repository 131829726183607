import ActionType from 'store/actions/types';
import { Action } from 'types/action';

export interface IAdminState {
  isLoadingUserReport: boolean;
}

export const initialState = {
  isLoadingUserReport: false,
};

const reducer = (
  state: IAdminState = initialState,
  action: Action<IAdminState>,
): IAdminState => {
  const { type } = action;

  switch (type) {
    case ActionType.ADMIN_USERS_REPORT_LOAD_FINISHED:
      return { ...state, isLoadingUserReport: false };
    case ActionType.ADMIN_USERS_REPORT_LOAD_STARTED:
      return { ...state, isLoadingUserReport: true };
    default:
      return state;
  }
};

export default reducer;
