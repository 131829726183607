type Configuration = {
  attributes: boolean;
  childList: boolean;
  subtree: boolean;
};

class ObserverNodeSize {
  private targetElement: Element | null;

  private config: Configuration;

  private observer: MutationObserver;

  height: string | undefined;

  width: string | undefined;

  constructor(targetElement: Element | null) {
    this.targetElement = targetElement;
    this.config = { attributes: true, childList: true, subtree: true };
    this.set(this.targetElement);
    this.observer = new MutationObserver(this.handlerObserve.bind(this));
  }

  private set(element: Element | Node | null): void {
    const elementFirstChild = element?.firstChild;
    const elementParent = elementFirstChild?.parentElement;

    this.height = elementParent?.style?.height;
    this.width = elementParent?.style?.width;
  }

  private handlerObserve(mutations: MutationRecord[]): void {
    mutations.forEach((mutation: MutationRecord) => {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'style'
      ) {
        this.set(mutation?.target);
      }
    });
  }

  connect(): void {
    if (this.targetElement)
      this.observer.observe(this.targetElement, this.config);
  }

  disconnect(): void {
    if (this.observer) this.observer.disconnect();
  }
}

export default ObserverNodeSize;
