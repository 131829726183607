import Flex from 'components/Flex';
import H1 from 'components/H1';
import useSelectedClient from 'domains/clients/useSelectedClient';
import DashboardForm from 'features/dashboards/components/Form';
import { getTestId, getClass } from 'helpers/components';
import useCurrentUser from 'hooks/useCurrentUser';
import useItemAdmin from 'hooks/useItemAdmin';
import useToast from 'hooks/useToast';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMeta } from 'types/apiReturn';
import IError from 'types/error';
import IDashboard from '../../domains/dashboard/types';

export const editDashboardComponentName = 'edit-dashboard';

interface Props {
  dashboard?: IDashboard;
  id?: string;
  loading?: boolean;
  setDashboard?: (dashboard?: IDashboard) => void;
  testId?: string;
  title?: string;
}

const Edit: FunctionComponent<Props> = (props: Props) => {
  const { id, loading = false, setDashboard, testId, title } = props;
  let { dashboard } = props;

  const navigate = useNavigate();
  const { doSuccessToast, doErrorToast } = useToast();
  const { userId } = useCurrentUser();
  const { selectedClient } = useSelectedClient();

  const dashboardFallback = {
    userId: userId ?? '',
    reports: [],
  };

  dashboard = dashboard ?? dashboardFallback;

  const {
    doUpdate,
    doCreate,
    loading: updateIsLoading,
  } = useItemAdmin<IDashboard>({
    endpoint: '/dashboards',
  });

  const goToDashboards = (): void => {
    navigate('/dashboards/list');
  };

  const handleSuccess = (
    dashboards: IDashboard[],
    meta?: IMeta<IDashboard>,
  ): void => {
    if (meta?.updated && setDashboard) {
      setDashboard(meta.updated);
    }

    navigate('/dashboards/list');
    doSuccessToast(id ? 'Dashboard updated' : 'Dashboard created');
  };

  const handleError = (err: IError): void => {
    doErrorToast(err?.message ?? 'There has been an error.');
  };

  const handleSubmit = (updatedDashboard: IDashboard): void => {
    if (id) {
      doUpdate({
        item: {
          id,
          ...updatedDashboard,
          client: selectedClient.id,
        },
        onSuccess: handleSuccess,
        onError: handleError,
      });
    } else {
      doCreate({
        item: {
          ...updatedDashboard,
          client: selectedClient.id,
        },
        onSuccess: handleSuccess,
        onError: handleError,
      });
    }
  };

  const editDashboardTestId = getTestId(editDashboardComponentName, testId);
  const editDashboardDefaultClass = getClass(editDashboardComponentName);

  return (
    <section
      className={editDashboardDefaultClass}
      data-testid={editDashboardTestId}
    >
      <header className={`${editDashboardDefaultClass}-header`}>
        <Flex>
          <H1>{title}</H1>
        </Flex>
      </header>
      <DashboardForm
        dashboard={dashboard}
        onSubmit={handleSubmit}
        onCancel={goToDashboards}
        loading={loading || updateIsLoading}
        testId={editDashboardTestId}
      />
    </section>
  );
};

export default Edit;
