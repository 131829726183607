import React, { Reducer } from 'react';

type HookReturn<T> = {
  openModal: (item?: T) => void;
  closeModal: () => void;
  isModalOpen: boolean;
  modalEditItem: T | undefined;
};

type State<T> = { isModalOpen: boolean; modalEditItem?: T };

type Action<T> =
  | { type: 'done editing' }
  | { type: 'toggle modal' }
  | { type: 'edit item'; payload?: T };

const initState = {
  isModalOpen: false,
};

const reducer = <T>(s: State<T>, a: Action<T>): State<T> => {
  switch (a.type) {
    case 'done editing':
      return { isModalOpen: false };
    case 'edit item':
      return { isModalOpen: true, modalEditItem: a.payload };
    default:
      return s;
  }
};

const useModal = <T extends {} = {}>(): HookReturn<T> => {
  const [state, dispatch] = React.useReducer(
    reducer as Reducer<State<T>, Action<T>>,
    initState as State<T>,
  );

  const openModal = (item?: T): void => {
    dispatch({ type: 'edit item', payload: item });
  };

  const closeModal = (): void => {
    dispatch({ type: 'done editing' });
  };

  const hookReturnData: HookReturn<T> = {
    openModal,
    closeModal,
    isModalOpen: state.isModalOpen,
    modalEditItem: state.modalEditItem,
  };
  return hookReturnData;
};

export default useModal;
