import sharedTooltipFormatter from 'helpers/charts/sharedTooltipFormatter';

export const commonMultichartConfig: Highcharts.Options = {
  tooltip: {
    shared: true,
    formatter: sharedTooltipFormatter,
    outside: false,
  },
  yAxis: {
    softMin: 0,
  },
  plotOptions: {
    column: {
      borderWidth: 1,
    },
    series: {
      marker: {
        enabled: false,
        symbol: 'circle',
      },
    },
  },
};
