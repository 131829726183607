const ROW_LIMIT_PLACEHOLDER = 'Show all';

export default {
  NO_CHART_DATA: 'Run a query to display chart data',
  TOO_MUCH_DATA: 'There is too much data to visualize in a chart.',
  ROW_LIMIT_LABEL: 'Limit',
  ROW_LIMIT_PLACEHOLDER,
  ROW_LIMIT_OPTIONS: [
    {
      text: ROW_LIMIT_PLACEHOLDER,
      value: 0,
    },
    {
      text: '10',
      value: 10,
    },
    {
      text: '20',
      value: 20,
    },
    {
      text: '50',
      value: 50,
    },
    {
      text: '100',
      value: 100,
    },
  ],
  DATA_READY_TO_EXPORT: 'Data is ready. Click on Export button.',
};
