import {
  IAttributionReport,
  ReportDatasetSelection,
  ReportDatasetsSelections,
} from 'domains/reports/types';
import {
  hasQueryAdsDatasets,
  hasQueryContentDatasets,
  hasQueryCustomAdsDatasets,
} from '../adapters/datasets';
import {
  checkPermissions,
  areAttributionQueryRulesValid,
} from '../adapters/general';

export const isAttributionReportValid = (
  report: IAttributionReport,
  datasetSelection: ReportDatasetSelection,
): boolean => {
  switch (datasetSelection) {
    case ReportDatasetsSelections.content:
      return (
        hasQueryContentDatasets(report.query.mode) &&
        areAttributionQueryRulesValid(report.query)
      );
    case ReportDatasetsSelections.ads:
      return (
        hasQueryAdsDatasets(report.query.mode) &&
        areAttributionQueryRulesValid(report.query)
      );
    case ReportDatasetsSelections.customAds:
      return (
        hasQueryCustomAdsDatasets(report.query.mode) &&
        areAttributionQueryRulesValid(report.query)
      );
    case ReportDatasetsSelections.genericEvents:
      return areAttributionQueryRulesValid(report.query);
    default:
      return false;
  }
};

export const getUserCanSeeLroiOptions = (): boolean =>
  checkPermissions('attribution_reports.linear_roi_super_user::view');
