import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Button';
import Flex from 'components/Flex';
import { Color as IconColor } from 'components/Icon';
import { getTestId, getId, getClass } from 'helpers/components';
import { trackReportInputChange } from 'helpers/mixpanel';
import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
  useMemo,
} from 'react';
import ITextValue from 'types/textValue';

export const segmentedControlComponentName = 'segmented-control';
export type ISize = 'small' | 'default';
export interface IProps {
  controlledSelected?: string | number;
  testId?: string;
  trackingId?: string;
  id?: string;
  upperCase?: boolean;
  options: ITextValue[];
  selected?: string | number;
  disabled?: boolean;
  onSelect?: (newSelected: string) => void;
  onSelectComplex?: (newSelected: ITextValue) => void;
  size?: ISize;
}

const SegmentedControl: FunctionComponent<IProps> = (props) => {
  const {
    controlledSelected,
    id,
    testId,
    trackingId,
    upperCase,
    options,
    onSelect = false,
    onSelectComplex = false,
    selected: defaultSelected,
    disabled = false,
    size = 'default',
  } = props;
  const [selected, setSelected] = useState(defaultSelected);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const segmentedControlClass = useMemo(
    () => getClass(segmentedControlComponentName),
    [],
  );
  const segmentedControlId = useMemo(
    () => getId(segmentedControlComponentName, id),
    [id],
  );
  const segmentedControlTestId = useMemo(
    () => getTestId(segmentedControlComponentName, testId),
    [testId],
  );

  const toUpperCase = (text: string): string => text.toUpperCase();

  const handleClick = (option: ITextValue): void => {
    const externalControl = onSelect && defaultSelected;

    if (trackingId) trackReportInputChange(trackingId, option.value);
    if (!externalControl) {
      setSelected(option.value);
    }

    if (onSelectComplex) onSelectComplex(option);
    if (onSelect) onSelect(option.value as string);
  };

  return (
    <div
      id={segmentedControlId}
      data-testid={segmentedControlTestId}
      className={`${segmentedControlClass}${
        size === 'default' ? '' : ` ${size}`
      }`}
    >
      <Flex>
        {options &&
          options.length > 0 &&
          options.map((option: ITextValue): ReactElement => {
            let className = '';

            if (controlledSelected && option.value === controlledSelected) {
              className = 'active';
            }

            if (!controlledSelected && selected && option.value === selected) {
              className = 'active';
            }

            if (!controlledSelected && selected && option.text === selected) {
              className = 'active';
            }

            return (
              <Button
                disabled={option.disabled ? option.disabled : disabled}
                key={`button-${option.value}${
                  option.text ? `-${option.text}` : ''
                }`}
                kind={[ButtonKind.outline, ButtonKind.icon]}
                testId={segmentedControlTestId}
                tooltip={option.tooltip}
                type={ButtonType.button}
                onClick={(): void => handleClick(option)}
                className={className}
                iconType={option.iconType}
                iconColor={IconColor.dark}
              >
                {upperCase ? toUpperCase(option.text) : option.text}
              </Button>
            );
          })}
      </Flex>
    </div>
  );
};

export default SegmentedControl;
