export const NOT_FOUND_CACHE_RESULT = 'not found';
export const LOCALE_DELETE_STOP_QUERY_HEADER =
  'Do you want to cancel the report?';
export const LOCALE_REPORT_UPDATED = 'Your report has been saved.';
export const LOCALE_REPORT_DOWNLOADED =
  'Downloaded report export successfully.';
export const LOCALE_REPORT_UPLOADED = 'This has been sent to your S3 bucket.';
export const LOCALE_DELETE_STOP_QUERY_TEXT = 'This cannot be undone.';
export const LOCALE_RUN_TARGET_QUERY_HAS_ERRORS_HEADER = 'Notification';
export const CONFIRM_QUERY_MESSAGES = {
  confirmLongQuery:
    'This report may produce a very large number of rows, and could take a long time to complete. Do you wish to continue?',
  noResults:
    'No results will be returned for the current report parameters. Please check dates and selections.',
};
export const LOCALE_NOT_ENOUGH_HH =
  'There were not enough households in your report to meet minimum reporting thresholds. Please adjust your criteria and run again.';
export const LOCALE_PARTIAL_DATA =
  'Some results did not meet minimum reporting thresholds and were omitted.';
export const LOCALE_NO_DATA_FOR_BREAKOUT =
  'There is no data available for this breakout.';
export const LOCALE_NO_DATA_RETURNED =
  'There is no result data for this report.';
export const LOCALE_NO_BREAKOUTS_WITH_POSITIVE_LIFT =
  'No breakouts had a lift greater than 0%, so there are no Top Performing Breakouts to report.';
export const LOCALE_CANCEL_REPORT_RUN = 'Cancel Report Run';
export const LOCALE_GO_BACK = 'Go Back';
export const LOCALE_EMPTY_SEGMENT_FILE =
  'Segment file is empty. Please check your configuration and re-run your advanced audience.';
export enum confirmDialogType {
  confirmLongQuery = 'confirmLongQuery',
  noResults = 'noResults',
  cancelReport = 'cancelReport',
  errorReport = 'errorReport',
}
