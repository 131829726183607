import { toTextValuePairs } from 'helpers/types';
import { useDispatch, useSelector } from 'react-redux';
import { setDashboards } from 'store/actions/domains';
import State from 'types/state';
import ITextValue from '../../types/textValue';
import { Dashboard } from './types';

export type HookReturn = {
  dashboards: Dashboard[];
  setDashboards: (dashboards: Dashboard[]) => void;
  dashboardOptions: ITextValue[];
};

const useDashboards = (): HookReturn => {
  const dashboards = useSelector(
    ({ domains: { dashboards: dashboardsState = [] } }: State) =>
      dashboardsState,
  );

  const dispatch = useDispatch();

  return {
    dashboards,
    setDashboards: (dashboardsToSet: Dashboard[]): void => {
      dispatch(setDashboards(dashboardsToSet));
    },
    dashboardOptions: toTextValuePairs(dashboards),
  };
};

export default useDashboards;
