import { IChartAndTableTransformerInputData } from 'components/ChartWithTable/transformer/types';
import { ResultBaseTotalDataTarget } from 'domains/reports/types';
import { sliceTargetByResultsCount } from './sliceTargetByResultsCount';
import { IFilters } from './types';
import { ChartType } from 'components/ChartWithTable';

export const getFilteredData = (
  filters: IFilters,
  currBreakoutData: ResultBaseTotalDataTarget,
): IChartAndTableTransformerInputData => {
  const filteredData = {
    target: sliceTargetByResultsCount(
      currBreakoutData.target ?? [],
      filters.resultsCount,
      filters.chartType === ChartType.BAR ? `${filters.yAxis}.VALUE` : '',
    ),
    genpop: sliceTargetByResultsCount(
      currBreakoutData.genpop ?? [],
      filters.resultsCount,
      filters.chartType === ChartType.BAR ? `${filters.yAxis}.VALUE` : '',
    ),
  };
  return filteredData;
};
