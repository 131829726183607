import { Placement, Sticker, Style, Type } from 'components/Sticker';
import React, { ReactNode } from 'react';

export { Style, Placement };

export const popoverComponentName = 'popover';

type PopoverProps = {
  content: string | ReactNode;
  children: string | ReactNode;
  style?: Style;
  placement?: Placement;
  showArrow?: boolean;
  customStickerClass?: string;
  customStickerWrapperClass?: string;
  stickToBottom?: boolean;
  customPortalContainer?: string | HTMLElement;
  matchTriggerWidth: boolean;
  controlled: boolean;
  visible?: boolean;
};

export const Popover: React.FunctionComponent<PopoverProps> = (
  props: PopoverProps,
) => {
  const {
    children,
    placement,
    content,
    style = Style.primary,
    showArrow,
    customStickerClass,
    customStickerWrapperClass,
    customPortalContainer,
    stickToBottom,
    matchTriggerWidth,
    controlled,
    visible,
  } = props;

  return (
    <Sticker
      style={style}
      type={Type.popover}
      content={content}
      placement={placement}
      showArrow={showArrow}
      controlled={controlled}
      visible={visible}
      customStickerClass={customStickerClass}
      customStickerWrapperClass={customStickerWrapperClass}
      stickToBottom={stickToBottom}
      customPortalContainer={customPortalContainer}
      matchTriggerWidth={matchTriggerWidth}
    >
      {children}
    </Sticker>
  );
};
