import { checkPermissionTree } from 'domains/permissions/helpers';
import { useCallback } from 'react';
import useCurrentUser from './useCurrentUser';

const useUserPermissions = (): {
  checkPermissions: (permission: string | string[]) => boolean;
} => {
  const { permissions = {} } = useCurrentUser();

  const checkPermissions = useCallback(
    (permission: string | string[]): boolean =>
      checkPermissionTree(permission, permissions),
    [permissions],
  );

  return { checkPermissions };
};

export default useUserPermissions;
