import { Placement, Style } from 'components/Popover';
import { Tooltip } from 'components/Tooltip';
import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';

export const labelComponentName = 'label';

export enum States {
  error = 'error',
  disabled = 'disabled',
}
export interface ILabelProps {
  text?: string;
  forId?: string;
  testId?: string;
  hasError?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const Label: FunctionComponent<ILabelProps> = (props): ReactElement => {
  const { forId, text, testId, hasError, disabled, tooltip } = props;

  const labelTestId = getTestId(labelComponentName, testId);
  const labelClass = getClass(labelComponentName, {
    boolean: [
      {
        state: hasError,
        class: States.error,
      },
      {
        state: disabled,
        class: States.disabled,
      },
    ],
  });

  return (
    <Tooltip
      style={Style.secondary}
      content={tooltip}
      placement={Placement.auto}
    >
      <label
        className={`${labelClass} d-flex`}
        data-testid={labelTestId}
        htmlFor={forId}
      >
        {text}
      </label>
    </Tooltip>
  );
};

export default Label;
