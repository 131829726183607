import Flex, { Horizontal } from 'components/Flex';
import Logo from 'components/Logo';
import TopBar from 'components/TopBar';
import { getTestId, getId, getClass } from 'helpers/components';
import React, { ReactElement } from 'react';
import { NavLink, Route, Routes as Switch } from 'react-router-dom';
import { Index as Routes } from 'routes';
import { IClient } from '../../domains/clients/types';

export const headerComponentName = 'header';

const navLinkClass = getClass(headerComponentName, { concat: ['nav-link'] });

export interface IHeaderProps {
  clients?: IClient[];
  id?: string;
  onSelectClient: (client: IClient) => void;
  selectedClient: IClient | undefined;
  testId?: string;
  userClients?: string[];
  userId?: string;
  userName?: string;
}

function ImpactLogo(): React.ReactElement {
  document.title = '605IMP4CT';

  return (
    <NavLink to={`/${Routes.SEGMENT_DASHBOARDS}`} className={navLinkClass}>
      <Logo height="33px" />
    </NavLink>
  );
}

function PlatformLogo(): React.ReactElement {
  document.title = '605PLATF0RM';

  return (
    <NavLink to={`/${Routes.SEGMENT_DASHBOARDS}`} className={navLinkClass}>
      <Logo height="33px" />
    </NavLink>
  );
}

const Header = (props: IHeaderProps): ReactElement => {
  const {
    id,
    testId,
    clients,
    onSelectClient,
    selectedClient,
    userClients,
    userId,
    userName,
  } = props;

  const headerClass = getClass(headerComponentName);
  const headerId = getId(headerComponentName, id);
  const headerTestId = getTestId(headerComponentName, testId);

  return (
    <div className={headerClass} data-testid={headerTestId} id={headerId}>
      <Flex horizontal={Horizontal.between}>
        <Switch>
          <Route
            path={`/${Routes.SEGMENT_ATTRIBUTION_REPORTS}/*`}
            element={<ImpactLogo />}
          />
          <Route path="*" element={<PlatformLogo />} />
        </Switch>
        <div style={{ width: '30%' }}>
          <TopBar
            clients={clients}
            onSelectClient={onSelectClient}
            selectedClient={selectedClient}
            userId={userId}
            userName={userName}
            userClients={userClients}
          />
        </div>
      </Flex>
    </div>
  );
};

export default Header;
