import Flex, { Horizontal } from 'components/Flex';
import { Size as SkeletonSizes } from 'components/Skeleton';
import SkeletonButton from 'components/Skeleton/Button';
import SkeletonInput from 'components/Skeleton/Input';
import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';
import { v4 as uuid } from 'uuid';

export const skeletonFormComponentName = 'skeleton-form';

type Buttons = {
  yes?: boolean;
};

type Actions = {
  buttons?: Buttons;
};

type Input = {
  type?: string;
  size?: SkeletonSizes;
};

type Fields = {
  inputs?: Input[];
};

export type Props = {
  title?: boolean;
  fields?: Fields;
  actions?: Actions;
  testId?: string;
};

const SkeletonForm: FunctionComponent<Props> = (props): ReactElement => {
  const { fields, actions, testId } = props;

  const skeletonFormClass = getClass(skeletonFormComponentName);
  const skeletonFormTestId = getTestId(skeletonFormComponentName, testId);

  return (
    <div className={skeletonFormClass} data-testid={skeletonFormTestId}>
      {fields?.inputs &&
        fields.inputs.map((input) => (
          <div key={uuid()}>
            <SkeletonInput size={input?.size} />
          </div>
        ))}
      <Flex horizontal={Horizontal.right}>
        {actions?.buttons && <SkeletonButton />}
      </Flex>
    </div>
  );
};

export default SkeletonForm;
