import { format, isValid, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { timezones } from 'domains/reports/types';
import { getTestId, getClass } from 'helpers/components';
import React, { ReactElement } from 'react';

export const componentName = 'date-time-list';

const LOCALE_DATE_TIME_FORMAT = 'MMM d, yyyy h:mm:ss a';

const timezone = timezones.americaNewYork;

interface IProps {
  dateTime?: string;
  testId?: string;
}

const DateTimeCell = (props: IProps): ReactElement => {
  const { dateTime, testId } = props;

  const dataTestId = getTestId(componentName, testId);
  const className = getClass(componentName);
  const parsedDateTime = parseISO(dateTime ?? '');
  return (
    <span data-testid={dataTestId} className={className}>
      {isValid(parsedDateTime) &&
        format(
          utcToZonedTime(parsedDateTime, timezone),
          LOCALE_DATE_TIME_FORMAT,
        )}
    </span>
  );
};

export default DateTimeCell;
