import { IDomainsDateRanges } from 'domains/reports/types';
import { get, isEmpty, isNil, map } from 'lodash';
import { flow, find, get as getFp } from 'lodash/fp';
import { RuleFilter } from 'types/filter';
import { isBefore, isAfter } from 'date-fns';
import { INITIAL_REPORTS_DATE } from 'domains/reports/constants';
import { getToday } from 'domains/reports/adapters/date';
import ITextValue from 'types/textValue';

export const verifyAndGetFilterRules = (
  existingFilters: RuleFilter[],
  index: number,
): Record<string, unknown>[] => {
  if (!existingFilters.length || existingFilters.length - 1 < 0) return [];

  const previousFilters = existingFilters.filter((f, i) => i < index);

  const currentFilter = existingFilters[index];

  const filterValues = previousFilters
    .filter((pf) => pf && pf !== null)
    .map((previousFilter) => {
      const { field, id, operator, value = [] } = previousFilter;
      if (!field) return {};
      return {
        field: field ?? currentFilter.field,
        id: id ?? currentFilter.id,
        type: 'SIMPLE',
        operator: operator ?? currentFilter.operator,
        value: map(
          value,
          (val: { text: string; value?: string }) => val.value ?? val,
        ),
      };
    });

  return filterValues.filter((fv) => fv);
};

export const getCompoundRuleMinDate = (
  dateRanges: IDomainsDateRanges,
  isPIQField: boolean,
): string => {
  if (!isPIQField) return INITIAL_REPORTS_DATE;
  return dateRanges.MODE_PLACEIQ.min_date;
};
export const getCompoundRuleMaxDate = (
  dateRanges: IDomainsDateRanges,
  isPIQField: boolean,
): string => {
  if (!isPIQField) return getToday();
  return dateRanges.MODE_PLACEIQ.max_date;
};

export const getCompoundRuleDate = (
  mainFilter: RuleFilter,
  dateRanges: IDomainsDateRanges,
  queryDate = '',
  datePath: 'start' | 'end',
  isPIQField: boolean,
): string => {
  const dateFilter = mainFilter?.children?.[0];
  const date = get(dateFilter, datePath, queryDate);
  if (!isPIQField) return date;
  const minPIQLimit = getCompoundRuleMinDate(dateRanges, true);
  const maxPIQLimit = getCompoundRuleMaxDate(dateRanges, true);
  if (isBefore(new Date(date), new Date(minPIQLimit))) return minPIQLimit;
  if (isAfter(new Date(date), new Date(maxPIQLimit))) return maxPIQLimit;
  return date;
};

export const handleMetricRetrive = (
  response: ITextValue[] | undefined,
  start: string | undefined,
  end: string | undefined,
  handleDateRangeChange: (startDate: string, endDate: string) => void,
  setMinDate: (minDateValue: string) => void,
  setMaxDate: (maxDateValue: string) => void,
): void => {
  if (isNil(response) || isEmpty(response)) return;
  const getMinValue = flow(find({ value: 'min' }), getFp('text'));
  const getMaxValue = flow(find({ value: 'max' }), getFp('text'));
  const minDateValue = getMinValue(response);
  const maxDateValue = getMaxValue(response);
  if (!(start && end)) {
    handleDateRangeChange(minDateValue, maxDateValue);
  }
  setMinDate(minDateValue);
  setMaxDate(maxDateValue);
};
