import Skeleton, {
  Size as SkeletonSizes,
  Direction as SkeletonDirections,
} from 'components/Skeleton';
import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';

export const skeletonInputComponentName = 'skeleton-input';

export type Props = {
  size?: SkeletonSizes;
  testId?: string;
};

const SkeletonInput: FunctionComponent<Props> = (props): ReactElement => {
  const { size = SkeletonSizes.full, testId } = props;

  const skeletonInputsClass = getClass(skeletonInputComponentName, {
    add: [size],
  });
  const skeletonInputTestId = getTestId(skeletonInputComponentName, testId);

  return (
    <div className={skeletonInputsClass} data-testid={skeletonInputTestId}>
      <Skeleton direction={SkeletonDirections.horizontal} size={size} />
    </div>
  );
};

export default SkeletonInput;
