import { getTestId, getClass } from 'helpers/components';
import React, { FunctionComponent, ReactElement } from 'react';
import Logo605 from '../../theme/605/images/logo.svg';
import Logo605Black from '../../theme/605/images/logo-black.svg';

export const logoComponentName = 'logo';

interface ILogoProps {
  logoPath?: string;
  width?: string;
  height?: string;
  testId?: string;
  isBlack?: boolean;
}

const Logo: FunctionComponent<ILogoProps> = (props): ReactElement => {
  const { logoPath, width, height, testId, isBlack } = props;

  const svgLogoPath = isBlack ? Logo605Black : Logo605;
  const logoClass = getClass(logoComponentName);
  const logoTestId = getTestId(logoComponentName, testId);

  return (
    <img
      className={logoClass}
      alt="logo"
      src={logoPath ?? svgLogoPath}
      data-testid={logoTestId}
      style={{
        width,
        height,
      }}
    />
  );
};

export default Logo;
