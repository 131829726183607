import { Axis, AxisTickPositionerCallbackFunction } from 'highcharts';
import { isNil } from 'lodash';
import { getTicIncrementValues } from './getTickIncrementValues';

export interface ICustomTickPositionerAxis extends Axis {
  dataMin: number;
  dataMax: number;
}

export const dumbbellTickPositioner: AxisTickPositionerCallbackFunction =
  function () {
    const { dataMin, dataMax } = this as ICustomTickPositionerAxis;
    const positions = [];

    if (!isNil(dataMax) && !isNil(dataMin)) {
      let min = dataMin > 100 ? 100 : dataMin;
      let max = dataMax < 100 ? 100 : dataMax;

      if (min === max) {
        min -= 2;
        max += 2;
      }

      let { tick, increment } = getTicIncrementValues({
        dataMax: max,
        dataMin: min,
      });

      for (tick; tick - increment <= max; tick += increment) {
        if (tick !== 100) positions.push(tick);
      }
    }

    return positions;
  };
