import ConfirmationDialog from 'components/ConfirmationDialog';
import Loading from 'components/Loading';
import Modal, { Type } from 'components/Modal';
import {
  removeDashboardReport,
  updateDashboardReportLayout,
} from 'domains/dashboard/helpers';
import { newDashboardReport } from 'domains/dashboard/initializers';
import {
  Dashboard,
  IDashboardReport,
  PanelLayout,
} from 'domains/dashboard/types';
import DashboardReportForm from 'features/dashboards/components/ReportForm';
import DashboardView from 'features/dashboards/components/View';
import { getTestId, getClass } from 'helpers/components';
import useItemAdmin from 'hooks/useItemAdmin';
import useModal from 'hooks/useModal';
import useToast from 'hooks/useToast';
import useUserPermissions from 'hooks/useUserPermissions';
import React, { useState, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dashboardActions from 'store/actions/dashboard';
import * as dashboardSelectors from 'store/selectors/dashboard';
import IError from 'types/error';
import State from 'types/state';
import ITextValue from 'types/textValue';

export const dashboardsDetailComponentName = 'dashboards-detail';

const LOCALE_CONFIRMATION_GO_TO_REPORT_TEXT =
  'Are you sure that you wish to navigate to the report page?. Changes made to the configure dashboard page will be lost.';

type OwnState = {
  reports: ITextValue[];
};

type Props = {
  dashboard: Dashboard;
  loading: boolean;
  resetDashboard: () => void;
  loadAllReports: () => void;
  dashboardReportFormSet: (payload: IDashboardReport) => void;
  dashboardReportFormReset: () => void;
};

interface OwnProps extends OwnState, Props {}

const DashboardDetail = (props: OwnProps): ReactElement => {
  const {
    dashboard,
    resetDashboard,
    reports,
    loading,
    loadAllReports,
    dashboardReportFormSet,
    dashboardReportFormReset,
  } = props;

  const [selectedReportId, setSelectedReportId] = useState('');

  const { checkPermissions } = useUserPermissions();
  const { doUpdate } = useItemAdmin<Dashboard>({ endpoint: '/dashboards' });
  const { doSuccessToast, doErrorToast } = useToast();
  const navigate = useNavigate();

  const { openModal, closeModal, isModalOpen, modalEditItem } =
    useModal<IDashboardReport>();

  const canAddReport = checkPermissions('dashboards.report::add');
  const canRemoveReport = checkPermissions('dashboards.report::remove');
  const canUpdateDashboard = checkPermissions('dashboards::update');
  const canRemoveDashboard = checkPermissions('dashboards::delete');
  const canUpdateReport = checkPermissions('dashboards.report::update');

  useEffect(() => {
    loadAllReports();
  }, [loadAllReports]);

  const handleSuccessAndSetDashboards = (message: string) => (): void => {
    resetDashboard();
    doSuccessToast(message);
  };

  const handleError = (err: IError): void => {
    doErrorToast(err?.message ?? 'There has been an error.');
  };

  const handleLayoutChange = (layout: PanelLayout[]): void => {
    doUpdate({
      item: updateDashboardReportLayout(layout, dashboard),
      onError: handleError,
    });
  };

  const handleUpdateReport = (): void => {
    resetDashboard();
    doSuccessToast('Dashboard updated');
    closeModal();
  };

  const handleRemoveReport = (dashboardReport: IDashboardReport): void => {
    doUpdate({
      item: removeDashboardReport(dashboardReport, dashboard),
      onSuccess: handleSuccessAndSetDashboards('Report removed'),
      onError: handleError,
    });
  };

  const handleCloseClick = (): void => {
    dashboardReportFormReset();
    closeModal();
  };

  const handleGoToReportClick = (reportId: string): void => {
    setSelectedReportId(reportId);
  };

  const onReportUpdate = (report: IDashboardReport): void => {
    dashboardReportFormSet(report);
    openModal();
  };

  const dashboardsDetailTestId = getTestId(dashboardsDetailComponentName);
  const dashboardsDetailClass = getClass(dashboardsDetailComponentName);

  return (
    <div className={dashboardsDetailClass} data-testid={dashboardsDetailTestId}>
      {selectedReportId && !loading ? (
        <ConfirmationDialog
          isOpen={!!selectedReportId}
          onCancel={(): void => {
            setSelectedReportId('');
          }}
          onConfirm={(): void => {
            navigate(`/reports/edit/${selectedReportId}`);
            setSelectedReportId('');
          }}
        >
          {LOCALE_CONFIRMATION_GO_TO_REPORT_TEXT}
        </ConfirmationDialog>
      ) : (
        <Modal
          isOpen={isModalOpen}
          showSeparators
          type={Type.auto}
          onCloseClick={handleCloseClick}
        >
          <DashboardReportForm
            reports={reports ?? []}
            dashboardReport={modalEditItem ?? newDashboardReport()}
            onAddReport={handleUpdateReport}
            onCancel={handleCloseClick}
            onGoToReportClick={handleGoToReportClick}
          />
        </Modal>
      )}
      {loading ? (
        <Loading />
      ) : (
        <DashboardView
          dashboard={dashboard}
          canAddReport={canAddReport}
          onReportAdd={openModal}
          canRemoveDashboard={canRemoveDashboard}
          canUpdateDashboard={canUpdateDashboard}
          canRemoveReport={canRemoveReport}
          onReportRemove={handleRemoveReport}
          canUpdateReport={canUpdateReport}
          onReportUpdate={onReportUpdate}
          onLayoutChange={handleLayoutChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: State): OwnState => ({
  reports: dashboardSelectors.getAllDashboardReportsOptions(state),
});

const mapDispatchToProps = {
  loadAllReports: dashboardActions.loadAllReports,
  dashboardReportFormSet: dashboardActions.dashboardReportFormSet,
  dashboardReportFormReset: dashboardActions.dashboardReportFormReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDetail);
