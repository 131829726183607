export const LOCALE_AD_DETAILS_LABEL = 'Ad Details';
export const LOCALE_DATASETS_LABEL = 'Datasets';
export const LOCALE_DATASETS_LABEL_TOOLTIP = 'TV viewership dataset.';

export const LOCALE_DATASETS_DATERANGE_LABEL = 'Date Range';
export const LOCALE_DATASETS_DATERANGE_LABEL_TOOPTIP =
  'Date range of the tune-in event.';

export const LOCALE_AD_DATE_RANGE_LABEL = 'Ad Flight Dates';
export const LOCALE_AD_DATE_RANGE_LABEL_TOOLTIP =
  'Dates that the campaign aired.';

export const LOCALE_VIEWERSHIP_DETAILS_LABEL = 'Viewership Details';
export const LOCALE_VIEWERSHIP_DETAILS_LABEL_TOOLPIT =
  'Date range of the tune-in event';

export const LOCALE_OUTCOME_LABEL = 'Outcome Details';
export const LOCALE_OUTCOME = 'Outcome';
export const LOCALE_OUTCOME_TOOLTIP = 'Desired result of the campaign.';

export const LOCALE_TARGET = 'Audience';
export const LOCALE_TARGET_TOOLTIP =
  'A group of households that represent a portion of All US TV Households based on viewership habits or demographic criteria.';
export const LOCALE_TARGET_SELECT_TARGET = 'Add Audience';

export const LOCALE_EQ_METRIC_LABEL = 'Equivalized';
export const LOCALE_EQ_METRIC_TOOLTIP =
  'Normalize ad spots, impressions and impression frequency to be equivalent to a 30s spots';

export const LOCALE_COMPARE_NETWORK_ON_VS_OFF_LABEL =
  'Compare ON Vs OFF Network';
export const LOCALE_COMPARE_NETWORK_ON_VS_OFF_LABEL_TOOLTIP =
  'Toggle to aggregate metrics for a family of networks against all other networks. Type in the network of interest.';

export const PerformanceMetricToolTips: Record<string, string> = {
  converters: 'The projected quantity of households that converted.',
  conversions:
    'Total count of visits, transactions, or events across all converted household',
  sales:
    'The projected quantity of total household sales and sales per transaction',
};
