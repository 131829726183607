import {
  PerformanceGroupKeys,
  PerformanceGroupNames,
} from 'domains/reports/types';
import { IOption } from 'types/textValue';
import { checkPermissions } from 'domains/reports/adapters/general';
import { compact, first } from 'lodash';
import { YAxisOptions, YAxisTitles } from '../campaign-timeseries';

export const getAxisInputOptions = (
  useEquivalizedMetrics: boolean,
  performanceMetricGroupArray: PerformanceGroupNames[],
): { leftYAxisInputOptions: IOption[]; rightYAxisInputOptions: IOption[] } => {
  const hasImpressionsPermission = checkPermissions(
    'attribution_reports.campaign.impressions::view',
  );
  const hasReachPermission = checkPermissions(
    'attribution_reports.campaign.reach::view',
  );
  const impressionText = useEquivalizedMetrics
    ? 'campaign.IMPRESSIONS_EQ'
    : 'campaign.IMPRESSIONS';

  const salesOptions: IOption[] = [
    {
      id: 4,
      color: '',
      text: YAxisTitles[YAxisOptions['total_sales.EXPOSED_SALES']],
      value: YAxisOptions['total_sales.EXPOSED_SALES'],
    },
    {
      id: 5,
      color: '',
      text: YAxisTitles[YAxisOptions['total_sales.ALL_EXPOSED_SALES']],
      value: YAxisOptions['total_sales.ALL_EXPOSED_SALES'],
    },
  ];

  const leftYAxisInputOptions: IOption[] = compact([
    hasReachPermission && {
      id: 0,
      color: '',
      text: YAxisTitles[YAxisOptions['campaign.REACH']],
      value: YAxisOptions['campaign.REACH'],
    },
    hasImpressionsPermission && {
      id: 1,
      color: '',
      text: YAxisTitles[YAxisOptions[impressionText]],
      value: YAxisOptions['campaign.IMPRESSIONS'],
    },
    {
      id: 2,
      color: '',
      text: YAxisTitles[YAxisOptions['conversions.EXPOSED_CONVERSIONS']],
      value: YAxisOptions['conversions.EXPOSED_CONVERSIONS'],
    },
    {
      id: 3,
      color: '',
      text: YAxisTitles[YAxisOptions['conversions.ALL_CONVERTED_HOUSEHOLDS']],
      value: YAxisOptions['conversions.ALL_CONVERTED_HOUSEHOLDS'],
    },
    ...(performanceMetricGroupArray.includes(PerformanceGroupKeys.total_sales)
      ? salesOptions
      : []),
  ]);
  return {
    leftYAxisInputOptions,
    rightYAxisInputOptions: [
      ...leftYAxisInputOptions,
      { id: 4, color: '', text: 'None', value: YAxisOptions.NONE },
    ],
  };
};

export const convertEqValue = (
  axis: YAxisOptions,
  useEQ: boolean,
): YAxisOptions => {
  if (axis === YAxisOptions['campaign.IMPRESSIONS_EQ'] && !useEQ) {
    return YAxisOptions['campaign.IMPRESSIONS'];
  }
  if (axis === YAxisOptions['campaign.IMPRESSIONS'] && useEQ) {
    return YAxisOptions['campaign.IMPRESSIONS_EQ'];
  }
  return axis;
};

export const getAxisLabel = (
  axis: string | number | undefined,
  useEquivalizedMetrics: boolean,
  side: 'left' | 'right',
): number | string => {
  const hasImpressionsPermission = checkPermissions(
    'attribution_reports.campaign.impressions::view',
  );
  const hasReachPermission = checkPermissions(
    'attribution_reports.campaign.reach::view',
  );
  const leftYDefault = first(
    compact([
      hasImpressionsPermission && YAxisOptions['campaign.IMPRESSIONS'],
      hasReachPermission && YAxisOptions['campaign.REACH'],
      YAxisOptions['conversions.EXPOSED_CONVERSIONS'],
    ]),
  );
  const rightYDefault = YAxisOptions['conversions.EXPOSED_CONVERSIONS'];
  const defaultLabel = (
    side === 'left' ? leftYDefault : rightYDefault
  ) as string;
  if (
    axis === YAxisOptions['campaign.IMPRESSIONS'] &&
    !hasImpressionsPermission
  ) {
    return defaultLabel;
  }

  if (axis === YAxisOptions['campaign.REACH'] && !hasReachPermission) {
    return defaultLabel;
  }

  if (axis === YAxisOptions['campaign.IMPRESSIONS'] && useEquivalizedMetrics) {
    return YAxisOptions['campaign.IMPRESSIONS_EQ'];
  }
  return axis ?? defaultLabel;
};
