import IReport from 'domains/reports/types';
import useReports from 'hooks/useReports';
import useToast from 'hooks/useToast';
import { isNull } from 'lodash/fp';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import * as rootActions from 'store/actions/root';
import * as rootSelectors from 'store/selectors/root';
import * as userSelectors from 'store/selectors/user';
import { Action } from 'types/action';
import IError from 'types/error';
import State from 'types/state';
import ReportsListFeature from '../../list.feature';

interface IProps {
  clientExtID: string;
  reportList: IReport[];
  setReportList: (arg0: IReport[] | null) => Action<IReport[] | null>;
  testId?: string;
}

const LOCALE_REPORT_LIST_REPORTS = 'Measurement Reports';

function ReportsListContainer(props: IProps): ReactElement {
  const { clientExtID, reportList, setReportList, testId } = props;
  const { doErrorToast } = useToast();
  const { fetchReports } = useReports();

  useEffect(() => {
    if (isNull(reportList)) {
      fetchReports({
        clientExtID,
        onSuccess: (res: IReport[]) => {
          setReportList(res);
        },
        onError: (error: IError) => {
          setReportList(null);
          doErrorToast(error?.message ?? 'There has been an error.');
        },
      });
    }
  }, [clientExtID, doErrorToast, fetchReports, reportList, setReportList]);

  return (
    <>
      <ReportsListFeature
        title={LOCALE_REPORT_LIST_REPORTS}
        testId={testId}
        loading={isNull(reportList)}
      />
    </>
  );
}

const mapStateToProps = (
  state: State,
): Pick<IProps, 'clientExtID' | 'reportList'> => ({
  clientExtID: userSelectors.getSelectedClientExtId(state),
  reportList: rootSelectors.getReportList(state),
});

const mapDispatchToProps = {
  setReportList: rootActions.setReportList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsListContainer);
