import Box, { Type as BoxType } from 'components/Box';
import Button, { Kind } from 'components/Button';
import Chart from 'components/Chart';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Flex, { Horizontal } from 'components/Flex';
import H2 from 'components/H2';
import Icon, {
  Type as IconType,
  Size as IconSize,
  Color as IconColor,
} from 'components/Icon';
import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import PopoverMenu from 'components/PopoverMenu';
import QueryTable from 'components/QueryTable';
import { Tooltip, Style } from 'components/Tooltip';
import {
  IDashboardReport,
  IDashboardReportData,
} from 'domains/dashboard/types';
import { Metric } from 'domains/metrics/types';
import {
  LOCALE_NOT_ENOUGH_HH,
  LOCALE_PARTIAL_DATA,
} from 'domains/reports/locales/general';
import IReport, {
  ChartTypesLocales,
  ChartTypes,
  DataRowGraph,
  ISGMConfig,
} from 'domains/reports/types';
import SingleStat from 'features/dashboards/components/SingleStat';
import { getTestId, getClass } from 'helpers/components';
import { getDateRangeString, listTargetsName } from 'helpers/reports';
import useCurrentUser from 'hooks/useCurrentUser';
import useFetch from 'hooks/useFetch';
import useModal from 'hooks/useModal';
import { isEmpty } from 'lodash/fp';
import get from 'lodash/get';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Index } from 'routes';
import dashboardActions from 'store/actions/dashboard';
import * as businessDataSelector from 'store/selectors/businessData';
import * as dashboardSelectors from 'store/selectors/dashboard';
import State from 'types/state';

export const panelComponentName = 'dashboard-panel';

const LOCALE_CONFIRMATION_MODAL_HEADER = 'Delete report from dashboard?';
const LOCALE_CONFIRMATION_CONFIRM_TEXT = 'Delete';
const HEADER_DOM_KEY = 'header';
const HEADER_TITLE_DOM_KEY = 'title';
const HEADER_DATE_RANGE_DOM_KEY = 'date-range';
const HEADER_END_DOM_KEY = 'header-end';
const HEADER_HOVER_DETAILS_DOM_KEY = 'hover-details';
const BODY_DOM_KEY = 'body';
const CORRECTION_PANEL_BODY_TABLE_HEIGHT_FACTOR = 80;
const CORRECTION_PANEL_BODY_CHART_HEIGHT_FACTOR = 110;
const TABLE_PAGINATION_PAGE_SIZE = 5;
const CONFIG_PANEL_DOM_KEY = 'config-panel';

const STATE_CLASS_IS_TABLET = 'is-tablet';
const STATE_CLASS_IS_SINGLE_STAT = 'is-single-stat';

interface IProps {
  dashboardReport: IDashboardReport;
  canUpdateReport?: boolean;
  canRemoveReport?: boolean;
  testId?: string;
  bodyHeight?: string;
  pageSize?: number;
  canUpdateDashboard?: boolean;
  onReportRemove?: (report: IDashboardReport) => void;
  onReportUpdate?: (report: IDashboardReport) => void;
  processDashboardReport: (id: string) => void;
  dashboardReportsData: IDashboardReportData[];
  availableTargets: IReport[];
}

const getSingleStatMetricSelectedValue = (
  reportExecutionResult: DataRowGraph[],
  singleStatSelectedMetric: Metric,
): string => {
  if (!reportExecutionResult?.length) {
    return '';
  }
  const firstRowReport = reportExecutionResult[0];

  if (!firstRowReport || !singleStatSelectedMetric) {
    return '';
  }

  const selectedMetricValue = firstRowReport[singleStatSelectedMetric];
  return get(selectedMetricValue, 'DISPLAY_VALUE', '') as string;
};

const DashboardPanel: FunctionComponent<IProps> = (props): ReactElement => {
  const {
    bodyHeight,
    dashboardReport,
    canUpdateReport,
    canRemoveReport,
    onReportUpdate,
    onReportRemove,
    pageSize,
    testId,
    canUpdateDashboard,
    processDashboardReport,
    dashboardReportsData,
    availableTargets,
  } = props;

  const {
    openModal: openRemoveReportConfirmation,
    closeModal,
    isModalOpen,
  } = useModal();

  const chartType = [dashboardReport.type];

  const [singleStatValue, setSingleStatValue] = useState<string>('');
  const { doFetch } = useFetch<IReport>();
  const { selectedClient } = useCurrentUser();

  const reportData: IDashboardReportData = React.useMemo(
    () =>
      (dashboardReportsData.find(
        (item: IDashboardReportData) => item.id === dashboardReport.reportId,
      ) ?? {}) as IDashboardReportData,
    [dashboardReport, dashboardReportsData],
  );

  const targetsListString: string = React.useMemo(
    () =>
      listTargetsName(
        reportData.data?.query?.targets ?? [],
        availableTargets ?? [],
      ),
    [availableTargets, reportData],
  );

  const dateRangeString: string = React.useMemo(
    () => getDateRangeString(reportData.data),
    [reportData],
  );

  const reportDetailsString =
    dateRangeString && targetsListString
      ? `${dateRangeString} ; ${targetsListString}`
      : '';

  const { isLoading, isRunning, hasError, hasMovingDates } = reportData;

  const reportExecutionResultData = React.useMemo(
    () => reportData?.execution?.data ?? [],
    [reportData],
  );
  const reportExecutionResultMetadata = React.useMemo(
    () => reportData?.execution?.metadata ?? [],
    [reportData],
  );
  const executedAt = reportData?.execution?.executed_at;
  const isTable = dashboardReport.type === ChartTypes.table;
  const isSingleStat =
    dashboardReport.type === ChartTypes.singleStat ||
    (reportExecutionResultData?.length === 1 && !isTable);
  const isChart = !isTable && !isSingleStat;
  const date = '';

  const selectedMetrics: Metric[] = React.useMemo(
    () => get(reportData, 'data.selectedMetrics') ?? [],
    [reportData],
  );

  useEffect(() => {
    if (reportData.data || isLoading || hasError) return;
    processDashboardReport(dashboardReport.reportId);
  }, [
    dashboardReport.reportId,
    doFetch,
    isLoading,
    reportData,
    hasError,
    selectedClient,
    processDashboardReport,
  ]);

  const getBodyHeight = (): string | undefined => {
    if (isSingleStat) {
      return undefined;
    }

    const bodyHeightNumberPart = bodyHeight?.split('px');
    let bodyHeightNumber;

    if (bodyHeightNumberPart?.[0]) {
      bodyHeightNumber = parseInt(bodyHeightNumberPart[0], 10);

      if (bodyHeightNumber && isTable) {
        return (bodyHeightNumber - CORRECTION_PANEL_BODY_TABLE_HEIGHT_FACTOR)
          .toString()
          .concat('px');
      }
      if (bodyHeightNumber && isChart) {
        return (bodyHeightNumber - CORRECTION_PANEL_BODY_CHART_HEIGHT_FACTOR)
          .toString()
          .concat('px');
      }
    }

    return undefined;
  };
  const reportMetrics = reportData?.data?.query?.select;

  useEffect(() => {
    const singleStatMetricSelectedValue = getSingleStatMetricSelectedValue(
      reportExecutionResultData,
      get(dashboardReport, 'selectVal', ''),
    );
    setSingleStatValue(singleStatMetricSelectedValue);
  }, [reportExecutionResultData, reportMetrics, dashboardReport]);

  const handleReportUpdate = (): void => {
    if (onReportUpdate) onReportUpdate(dashboardReport);
  };

  const handleReportRemove = (): void => {
    closeModal();
    if (onReportRemove) onReportRemove(dashboardReport);
  };

  const contextualMenuOptions = [
    {
      visible: canUpdateReport,
      key: 'add',
      option: (
        <Button kind={Kind.text} onClick={handleReportUpdate}>
          Settings
        </Button>
      ),
    },
    {
      visible: canRemoveReport,
      key: 'edit',
      option: (
        <Button kind={Kind.text} onClick={openRemoveReportConfirmation}>
          Remove report from dashboard
        </Button>
      ),
    },
  ];

  const panelTestId = useMemo(
    () => getTestId(panelComponentName, testId),
    [testId],
  );
  const panelClass = useMemo(() => getClass(panelComponentName), []);
  const panelHeaderClass = useMemo(
    () => getClass(panelComponentName, { concat: [HEADER_DOM_KEY] }),
    [],
  );
  const panelTitleClass = useMemo(
    () => getClass(panelComponentName, { concat: [HEADER_TITLE_DOM_KEY] }),
    [],
  );
  const panelDateRangeClass = useMemo(
    () => getClass(panelComponentName, { concat: [HEADER_DATE_RANGE_DOM_KEY] }),
    [],
  );
  const panelHeaderEndClass = useMemo(
    () => getClass(panelComponentName, { concat: [HEADER_END_DOM_KEY] }),
    [],
  );

  const panelBodyClass = useMemo(
    () =>
      getClass(panelComponentName, {
        concat: [BODY_DOM_KEY],
        boolean: [
          {
            state: isSingleStat,
            class: `${BODY_DOM_KEY}-${STATE_CLASS_IS_SINGLE_STAT}`,
            preventCollisions: true,
          },
          {
            state: isTable,
            class: `${BODY_DOM_KEY}-${STATE_CLASS_IS_TABLET}`,
            preventCollisions: true,
          },
        ],
      }),
    [isSingleStat, isTable],
  );

  const panelHoverDetailsClass = useMemo(
    () =>
      getClass(panelComponentName, {
        concat: [HEADER_HOVER_DETAILS_DOM_KEY],
      }),
    [],
  );

  const renderReportChart = (): ReactElement => {
    const SGMConfig = reportData.SGMConfig as ISGMConfig;

    if (isLoading || hasError) {
      return <></>;
    }

    if (isRunning && hasMovingDates) {
      const progress = reportData?.execution?.progress;
      return (
        <div className="execution-progress-container">
          <div className="label-container">
            <span>
              This report is being refreshed. The report will be shown once the
              results are available.
            </span>
          </div>
          <div className="progress-container">
            <Icon
              type={IconType.loading}
              size={IconSize.large}
              color={IconColor.primary}
            />
            <span>{`${progress ?? 0}%`}</span>
          </div>
        </div>
      );
    }

    return !isSingleStat &&
      !isTable &&
      selectedMetrics.length &&
      chartType.length &&
      SGMConfig ? (
      <Chart
        data={reportExecutionResultData}
        metadata={reportExecutionResultMetadata}
        SGMConfig={SGMConfig}
        height={getBodyHeight()}
        disableAnimationAfterFirstRender
        colors={dashboardReport.chart_colors}
      />
    ) : (
      <></>
    );
  };

  return (
    <div data-testid={panelTestId} className={panelClass}>
      <ConfirmationDialog
        isOpen={isModalOpen}
        header={LOCALE_CONFIRMATION_MODAL_HEADER}
        onCancel={closeModal}
        onConfirm={handleReportRemove}
        confirmButtonText={LOCALE_CONFIRMATION_CONFIRM_TEXT}
      >
        <p>This action cannot be undone.</p>
      </ConfirmationDialog>
      <Box type={BoxType.primary}>
        <header
          className={panelHeaderClass}
          data-testid={`${panelTestId}-${HEADER_DOM_KEY}`}
        >
          <Flex horizontal={Horizontal.between}>
            {dashboardReport.on_hover_details && (
              <div
                className={panelHoverDetailsClass}
                data-testid={`${panelTestId}-${HEADER_HOVER_DETAILS_DOM_KEY}`}
              >
                <Tooltip
                  style={Style.secondary}
                  content={dashboardReport.on_hover_details}
                >
                  <Icon type={IconType.helpCircle} />
                </Tooltip>
              </div>
            )}
            <div
              className={`${panelTitleClass} ${
                canUpdateDashboard ? 'drag-handle' : ''
              }`}
              data-testid={`${panelTestId}-${HEADER_TITLE_DOM_KEY}`}
            >
              <H2>
                {dashboardReport.title ??
                  ChartTypesLocales[dashboardReport.type]}
              </H2>
              {!isLoading && !isRunning && reportDetailsString && (
                <div className="report-details-subtitle">
                  {reportDetailsString}
                </div>
              )}
              {!isLoading && !isRunning && executedAt && (
                <div>
                  <span className="last-executed-subtitle">
                    {`Report was last executed on ${executedAt}`}
                  </span>
                </div>
              )}
            </div>
            <div className={panelHeaderEndClass}>
              <Flex horizontal={Horizontal.between}>
                <div className="report-details-icon-wrapper">
                  <Link
                    to={`/${Index.SEGMENT_REPORTS}/edit/${reportData.id}`}
                    target="_blank"
                  >
                    <Icon
                      type={IconType.reportDetails}
                      size={IconSize.medium}
                    />
                  </Link>
                </div>
                <div
                  className={panelDateRangeClass}
                  data-testid={`${panelTestId}-${HEADER_DATE_RANGE_DOM_KEY}`}
                >
                  {date}
                </div>
                {(canUpdateReport || canRemoveReport) && (
                  <PopoverMenu
                    options={contextualMenuOptions}
                    wrapperClassName={CONFIG_PANEL_DOM_KEY}
                  >
                    <Icon type={IconType.cog} size={IconSize.medium} />
                  </PopoverMenu>
                )}
              </Flex>
            </div>
          </Flex>
        </header>
        <section
          className={panelBodyClass}
          data-testid={`${panelTestId}-${BODY_DOM_KEY}`}
          style={{ height: getBodyHeight() }}
        >
          {isEmpty(reportExecutionResultData) &&
          !isLoading &&
          !isRunning &&
          ((reportData?.execution?.rows_dropped ?? 0) as number) > 0 ? (
            <div className="not-enough-hh">
              <InlineAlert
                message={LOCALE_NOT_ENOUGH_HH}
                mode={AlertTypes.warn}
                hideClose
              />
            </div>
          ) : (
            <>
              {((reportData?.execution?.rows_dropped ?? 0) as number) > 0 &&
                !isLoading &&
                !isRunning && (
                  <div className="partial-data">
                    <InlineAlert
                      message={LOCALE_PARTIAL_DATA}
                      mode={AlertTypes.notify}
                    />
                  </div>
                )}
              {isSingleStat && dashboardReport.selectVal ? (
                <SingleStat
                  key={dashboardReport.selectVal}
                  value={singleStatValue}
                  color={
                    dashboardReport?.chart_colors
                      ? dashboardReport?.chart_colors[0]
                      : dashboardReport.font_color
                  }
                />
              ) : (
                []
              )}
              {renderReportChart()}
              {isTable &&
              !isLoading &&
              !isRunning &&
              reportExecutionResultData &&
              reportExecutionResultMetadata ? (
                <QueryTable
                  data={reportExecutionResultData}
                  metadata={reportExecutionResultMetadata}
                  pageSize={pageSize ?? TABLE_PAGINATION_PAGE_SIZE}
                />
              ) : (
                []
              )}
            </>
          )}
        </section>
      </Box>
    </div>
  );
};

const mapStateToProps = (
  state: State,
): {
  dashboardReportsData: IDashboardReportData[];
  availableTargets: IReport[];
} => ({
  dashboardReportsData: dashboardSelectors.getDashboardReports(state),
  availableTargets: businessDataSelector.getAvailableTargets(state),
});

const mapDispatchToProps = {
  processDashboardReport: dashboardActions.processDashboardReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(DashboardPanel));
