import { DataRowGraph, IMetaData } from 'domains/reports/types';
import { uniqBy } from 'lodash';
import { find, get, times, size } from 'lodash/fp';
import {
  IColumn,
  Header,
  IUtilReturn,
} from '../../../../ReportResultsForm/utils';

type PivotConfig = { [key: string]: string[] };

type SizeOfParams = {
  inputData: DataRowGraph[];
  configParams: PivotConfig;
  dimension: string;
};

type PivotTableConfig = {
  ROWS: string[];
  COLS: string[];
  VALUES: string[];
};

type RawPivotTableResult = {
  data: DataMatrix;
  headers: IColumn[][];
};

type TableSpanConfig = {
  name?: string;
  count?: number;
};

export type TableRowConfig = {
  [key: string]: string;
};

type DataMatrix = string[][];

type PermutationValue = {
  permutation: string[];
  index: number;
};

type PermutationsMap = Map<string, PermutationValue>;

const joinPermutationKey = (p: string[]): string => p.join('');

const uniqArrays = (arr: string[][]): string[][] =>
  uniqBy(arr, (v) => joinPermutationKey(v));

const convertPermutationsArrayToMap = (
  permutations: string[][],
): PermutationsMap =>
  new Map(
    permutations.map((p, index) => [
      joinPermutationKey(p),
      { permutation: p, index },
    ]),
  );

const getPermutationsHashMap = ({
  inputData,
  configParams,
  dimension,
}: SizeOfParams): PermutationsMap => {
  const permutations: string[][] = [];

  for (const dataRecord of inputData) {
    const permutation = configParams[dimension].map((recordDimension: string) =>
      dataRecord[recordDimension].DISPLAY_VALUE.toString(),
    );

    if (dimension === 'COLS') {
      configParams.VALUES.forEach((val) => {
        permutations.push([...permutation, val]);
      });
    } else {
      permutations.push(permutation);
    }
  }

  const uniqPermutations = uniqArrays(permutations);

  const permutationsHashMap = convertPermutationsArrayToMap(uniqPermutations);

  return permutationsHashMap;
};

const getInitialRow = (numCols: number, initial: string): string[] =>
  times(() => initial, numCols);

const getInitialMatrix = (
  numRows: number,
  numCols: number,
  initial: string,
): string[][] => times(() => getInitialRow(numCols, initial), numRows);

const getDataToFindInMatrix = ({
  configParams,
  data,
  dimension,
  additionalValue,
}: {
  configParams: PivotConfig;
  data: DataRowGraph;
  dimension: string;
  additionalValue?: string;
}): string[] => {
  const dataToFind = configParams[dimension].map((element) =>
    data[element].DISPLAY_VALUE.toString(),
  );

  if (additionalValue) {
    dataToFind.push(additionalValue);
  }

  return dataToFind;
};

const createValuesMatrix = ({
  inputData,
  configParams,
  rowsPermutationsMap,
  colsPermutationsMap,
}: {
  inputData: DataRowGraph[];
  configParams: PivotTableConfig;
  rowsPermutationsMap: PermutationsMap;
  colsPermutationsMap: PermutationsMap;
}): DataMatrix => {
  const numRows = rowsPermutationsMap.size;
  const numCols = colsPermutationsMap.size;
  const valuesMatrix = getInitialMatrix(numRows, numCols, 'N/A');

  for (const data of inputData) {
    const rowData = getDataToFindInMatrix({
      configParams,
      data,
      dimension: 'ROWS',
    });

    const dataRowIndex = rowsPermutationsMap.get(
      joinPermutationKey(rowData),
    )?.index;

    if (dataRowIndex !== undefined) {
      configParams.VALUES.forEach((value: string) => {
        const colData = getDataToFindInMatrix({
          configParams,
          data,
          dimension: 'COLS',
          additionalValue: value,
        });

        const dataColIndex = colsPermutationsMap.get(
          joinPermutationKey(colData),
        )?.index;

        if (dataColIndex === undefined) return;

        valuesMatrix[dataRowIndex][dataColIndex] =
          data[value].DISPLAY_VALUE.toString();
      });
    }
  }

  return valuesMatrix;
};

const getData = ({
  rowsPermutationsMap,
  valuesMatrix,
}: {
  rowsPermutationsMap: PermutationsMap;
  valuesMatrix: DataMatrix;
}): string[][] => {
  const data: string[][] = [];

  rowsPermutationsMap.forEach(({ permutation, index }) => {
    const rowData = [...permutation];

    valuesMatrix[index].forEach((val: string) => {
      rowData.push(val);
    });

    data.push(rowData);
  });

  return data;
};

const getLabel = (label: string, metadata: IMetaData[]): string | undefined => {
  const metaElement = find(
    (element: IMetaData) => get('id', element) === label,
  )(metadata);

  return get('name', metaElement) ?? label;
};

const getTableHeadersData = ({
  configParams,
  colsPermutationsMap,
  metadata,
}: {
  configParams: PivotConfig;
  colsPermutationsMap: PermutationsMap;
  metadata: IMetaData[];
}): IColumn[][] => {
  const headers: IColumn[][] = [];

  configParams.COLS.forEach((headerCategory) => {
    const colsHeaderEntry: IColumn[] = [];
    const colspan = size(configParams.ROWS) + 1;

    colsHeaderEntry.push({
      colspan,
      label: getLabel(headerCategory, metadata),
    });

    const colIndex = configParams.COLS.indexOf(headerCategory);

    colsPermutationsMap.forEach(({ permutation }) => {
      const colEntry = {
        colspan: 0,
        label: getLabel(permutation[colIndex], metadata),
      };
      const isInHeaders = colsHeaderEntry.some(
        (entry) => entry.label === colEntry.label,
      );
      if (!isInHeaders) {
        colsHeaderEntry.push(colEntry);
      }
    });

    headers.push(colsHeaderEntry);
  });

  const headerEntry: IColumn[] = [];

  configParams.ROWS.forEach((headerRow) => {
    headerEntry.push({ colspan: 0, label: headerRow });
  });

  colsPermutationsMap.forEach(({ permutation }) => {
    const colEntry = { colspan: 0, label: permutation[permutation.length - 1] };
    headerEntry.push(colEntry);
  });

  headers.push(headerEntry);

  return headers;
};

const getHeaderSpansCountsFromConfig = (
  configParams: PivotConfig,
): TableSpanConfig[] => {
  const countsForSpans: TableSpanConfig[] = [];

  configParams.COLS.forEach((element) => {
    const countDict = {
      name: element,
      count: configParams.ROWS.length,
    };

    countsForSpans.push(countDict);
  });

  configParams.ROWS.forEach((element) => {
    const countDict = {
      name: element,
      count: 1,
    };

    countsForSpans.push(countDict);
  });

  return countsForSpans;
};

const incrementCounts = (
  countsForSpans: TableSpanConfig[],
  selection: string,
): TableSpanConfig[] =>
  countsForSpans.map((item) => {
    if (item?.name === selection && item?.count) {
      return {
        ...item,
        count: ++item.count,
      };
    }
    return item;
  });

// Counts the amount of each permutation in permutations list
const getHeaderSpansCounts = ({
  colsPermutationsMap,
  configParams,
}: {
  colsPermutationsMap: PermutationsMap;
  configParams: PivotConfig;
}): TableSpanConfig[] => {
  const counts = getHeaderSpansCountsFromConfig(configParams);

  colsPermutationsMap.forEach(({ permutation }) => {
    permutation.forEach((value) => {
      const countDict: TableSpanConfig = {};

      const isInCountsForSpans = counts.some((entry) => entry.name === value);

      if (isInCountsForSpans) {
        incrementCounts(counts, value);
      } else {
        countDict.name = value;
        countDict.count = 1;
      }

      counts.push(countDict);
    });
  });

  return counts;
};

const getHeaders = ({
  configParams,
  colsPermutationsMap,
  metadata,
}: {
  configParams: PivotConfig;
  colsPermutationsMap: PermutationsMap;
  metadata: IMetaData[];
}): IColumn[][] => {
  const headers = getTableHeadersData({
    configParams,
    colsPermutationsMap,
    metadata,
  });

  const countsForHeaderSpans = getHeaderSpansCounts({
    colsPermutationsMap,
    configParams,
  });

  headers.forEach((header) => {
    header.forEach((headerEntry) => {
      countsForHeaderSpans.forEach((span) => {
        if (headerEntry.label === span.name) {
          headerEntry.colspan = span.count;
        }
      });

      configParams.VALUES.forEach((value) => {
        if (headerEntry.label === value) {
          headerEntry.colspan = 1;
        }
      });
    });
  });

  return headers;
};

export const generateRawPivotTable = (
  inputData: DataRowGraph[],
  configParams: PivotTableConfig,
  metadata: IMetaData[],
): RawPivotTableResult => {
  const rowsPermutationsMap = getPermutationsHashMap({
    inputData,
    configParams,
    dimension: 'ROWS',
  });

  const colsPermutationsMap = getPermutationsHashMap({
    inputData,
    configParams,
    dimension: 'COLS',
  });

  const valuesMatrix = createValuesMatrix({
    inputData,
    configParams,
    rowsPermutationsMap,
    colsPermutationsMap,
  });

  const data = getData({
    rowsPermutationsMap,
    valuesMatrix,
  });

  const headers = getHeaders({
    colsPermutationsMap,
    configParams,
    metadata,
  });

  return {
    data,
    headers,
  };
};

export const generatePivotTable = (
  rawData: DataRowGraph[],
  config: PivotTableConfig,
  metadata: IMetaData[],
): IUtilReturn => {
  const rawTable = generateRawPivotTable(rawData, config, metadata);

  const { data, headers } = rawTable;

  const unlinkedHeaders = [...headers];

  const lastHeader = unlinkedHeaders.splice(-1, 1)[0];

  const newHeaders = lastHeader.map((column: IColumn, index: number) => ({
    Header: column.label ?? '',
    accessor: `${column.label ?? ''}|${index}`,
    ...(index === 0 && { sticky: 'left' }),
  }));

  const newDataMapped = data.map((row) =>
    newHeaders.reduce(
      (allHeaders: TableRowConfig, header: Header, index: number) => {
        if (header.accessor) {
          allHeaders[header.accessor] = row[index];
        }
        return allHeaders;
      },
      {} as TableRowConfig,
    ),
  );

  return {
    data: newDataMapped,
    headers: newHeaders,
    unlinkedHeaders,
  };
};
