import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import State from 'types/state';
import { IClient } from '../domains/clients/types';
import { AccumulatedEntries, getAccumulatedEntries } from './helpers';

interface IProps {
  clients: IClient[];
  indexedClients: AccumulatedEntries;
}

const useClients = (): IProps => {
  const clients = useSelector(
    ({ domains: { clients: clientsState = [] } }: State) => clientsState,
  );

  const indexedClients: AccumulatedEntries = useMemo(
    () => getAccumulatedEntries(clients),
    [clients],
  );
  return {
    clients,
    indexedClients,
  };
};

export default useClients;
