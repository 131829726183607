import Button, { Kind } from 'components/Button';
import IDimension from 'domains/dimensions/types';
import { getClass, getTestId } from 'helpers/components';
import { trackRuleAdded } from 'helpers/mixpanel';
import React, { FunctionComponent, ReactElement } from 'react';
import { newFilter, RuleFilter } from 'types/filter';
import RulesBuilderItem from './components/RulesBuilderItem';

export const componentName = 'rules-builder';

const LOCALE_ADD_RULE_LABEL = 'Add New Rule';

const componentClass = getClass(componentName);

export interface IProps {
  canAddRules?: boolean;
  canDeleteRules?: boolean;
  datasets?: string[];
  dimensions?: IDimension[];
  disabled?: boolean;
  filters: RuleFilter[];
  fixed?: boolean;
  maxRules?: number;
  addItem: (filter: RuleFilter) => void;
  replaceItem: (filter: RuleFilter) => void;
  deleteItem: (filter: RuleFilter) => void;
  placeholder?: string;
  ruleIdsToHide?: string[];
  section?: string;
  testId?: string;
  minDate?: string;
  maxDate?: string;
}

const RulesBuilder: FunctionComponent<IProps> = (
  props: IProps,
): ReactElement => {
  const {
    canAddRules = true,
    canDeleteRules = true,
    datasets,
    dimensions,
    disabled,
    filters,
    fixed = false,
    maxRules,
    ruleIdsToHide,
    placeholder = LOCALE_ADD_RULE_LABEL,
    testId,
    section,
    minDate,
    maxDate,
    addItem,
    replaceItem,
    deleteItem,
  } = props;

  /**
   * if maxRules property is set and number of filters equals or exceeds maxRules
   */
  const haveMaxRulesExceeded = maxRules && filters.length >= maxRules;
  const showAddRulesButton = canAddRules && !haveMaxRulesExceeded;
  const hasRules = filters?.length > 0;

  const componentTestId = getTestId(componentName, testId);
  const componentFooterClass = getClass(componentName, {
    concat: ['footer'],
    boolean: [
      {
        state: !hasRules,
        class: 'no-rules',
      },
    ],
  });

  return (
    <section data-testid={componentTestId} className={componentClass}>
      <div>
        {filters.map(
          (filter, i) =>
            !(
              filter.field &&
              ruleIdsToHide &&
              ruleIdsToHide.includes(filter.field)
            ) && (
              <RulesBuilderItem
                datasets={datasets}
                dimensions={dimensions}
                disabled={disabled}
                filter={filter}
                fixed={fixed}
                section={section}
                index={i}
                isDeletable={canDeleteRules}
                key={filter.id + i.toString()}
                onChange={replaceItem}
                onDelete={deleteItem}
                placeholder={placeholder}
                queryMinDate={minDate}
                queryMaxDate={maxDate}
              />
            ),
        )}
      </div>
      <footer className={componentFooterClass}>
        {showAddRulesButton && (
          <Button
            kind={Kind.linkPrimary}
            className="add-new-rule-button"
            onClick={() => {
              trackRuleAdded(filters);
              addItem(newFilter());
            }}
            disabled={disabled}
            testId="add-filter"
          >
            {LOCALE_ADD_RULE_LABEL}
          </Button>
        )}
      </footer>
    </section>
  );
};

export default RulesBuilder;
