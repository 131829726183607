import Accordion from 'components/Accordion';
import Table from 'components/Table';
import React, { FC } from 'react';
import { Column } from 'react-table';
import { IQueryProgressComponentProps } from './types';

const TABLE_COLUMNS: Column<{
  query: string;
  progress: number;
}>[] = [
  {
    Header: 'Task',
    accessor: 'query',
  },
  {
    Header: 'Progress',
    accessor: 'progress',
  },
];

export const QueryProgressComponent: FC<IQueryProgressComponentProps> = ({
  queryProgress,
}) => {
  const table = Object.entries(queryProgress).map(([query, progressValue]) => ({
    query,
    progress: progressValue.percent_progress,
  }));

  return (
    <section>
      <Accordion title="Query Progress">
        <Table columns={TABLE_COLUMNS} data={table} />
      </Accordion>
    </section>
  );
};
