import InlineAlert, { AlertTypes } from 'components/InlineAlert';
import Input, { Type } from 'components/Input';
import OptionsTree from 'components/OptionsTree';
import { Popover } from 'components/Popover';
import { Placement } from 'components/Sticker';
import { Style, Tooltip } from 'components/Tooltip';
import { getClass, getId, getTestId } from 'helpers/components';
import { trackReportInputChange } from 'helpers/mixpanel';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import noop from 'lodash/noop';
import React, {
  FocusEventHandler,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RuleFilter } from 'types/filter';
import ITextValue from 'types/textValue';
import { isArrayOfStrings } from '../../helpers/types';

export interface IDropdownProps {
  value?: string;
  selectedOptions?: string[] | ITextValue[];
  options?: string[] | ITextValue[];
  testId?: string;
  trackingId?: string;
  trackingRuleContext?: {
    filter: RuleFilter;
    index: number;
    parentRuleTracking?: RuleFilter;
  };
  cancelTracking?: boolean;
  id?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  fixed?: boolean;
  isButton?: boolean;
  hasError?: boolean;
  errorText?: string;
  onChange?: (newValue: string) => void;
  ignoreGrouping?: boolean;
  keepOpenOnItemSelect?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  stickToBottom?: boolean;
  description?: string;
  isReportInvalid?: boolean;
  portalContainerClass?: string;
  isAttributionReport?: boolean;
  modalDropdown?: boolean;
}

const featureComponentName = 'feature';
const DOM_KEY_FEATURE_RIGHTSIDE_CONTENT = 'right-side-content';
const featureRightSideContentClass = getClass(featureComponentName, {
  concat: [DOM_KEY_FEATURE_RIGHTSIDE_CONTENT],
});

export const dropdownComponentName = 'dropdown';

export enum States {
  error = 'error',
}

const getOption = (
  value: string | undefined,
  options: string[] | ITextValue[],
): string | undefined => {
  let found;
  if (isArrayOfStrings(options)) {
    found = (options as string[]).find((option: string) => option === value);
  } else {
    found = (options as ITextValue[]).find(
      (option: ITextValue) => option.value?.toString() === value,
    );
    return found?.text;
  }
  return found;
};

export const getValue = (
  value: string | undefined,
  options: string[] | ITextValue[],
): string => {
  if (isArrayOfStrings(options) && getOption(value, options)) {
    return value ?? '';
  }
  const foundOption = getOption(value, options);
  if (foundOption) {
    return foundOption;
  }
  return '';
};

const defaultWidth = '100%';

const getSelectedIcon = (
  options: ITextValue[],
  defaultValue: string,
): ReactNode =>
  (options as ITextValue[]).some((option: ITextValue) => option.icon)
    ? (options as ITextValue[]).find((x) => x.value === defaultValue)?.icon
    : [];

const getValueFromText = (text = '', options: ITextValue[]): string =>
  options.find((option) => option.text === text)?.value.toString() ?? '';

const Dropdown: FunctionComponent<IDropdownProps> = (props): ReactElement => {
  const {
    name,
    value: defaultValue,
    placeholder,
    disabled,
    fixed,
    isButton,
    options = [],
    id,
    testId,
    trackingId,
    trackingRuleContext,
    cancelTracking = false,
    hasError,
    errorText = '',
    onChange,
    onBlur,
    ignoreGrouping,
    keepOpenOnItemSelect = false,
    selectedOptions,
    stickToBottom,
    description,
    isReportInvalid,
    portalContainerClass,
    isAttributionReport,
    modalDropdown,
  } = props;

  const [value, setValue] = useState<string | null>(
    getValue(defaultValue, options),
  );
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setValue(getValue(defaultValue, options));
  }, [defaultValue, options]);

  const handleOnChange = (newValue: string): void => {
    if (isButton || keepOpenOnItemSelect) {
      if (onChange) onChange(newValue);
    } else {
      if (newValue) {
        const textValue = getValue(newValue, options);
        if (!cancelTracking && trackingId)
          trackReportInputChange(
            trackingId,
            newValue,
            textValue,
            'changed',
            trackingRuleContext,
          );
        setValue(textValue);
        if (onChange) onChange(newValue);
      } else {
        setValue(null);
        if (onChange) onChange('');
      }

      setOpened(false);
    }
  };

  const handleClick = useCallback((): false | void => {
    if (!disabled && !fixed) {
      setOpened((prev) => !prev);
    }
  }, [disabled, fixed]);

  useOnOutsideClick(
    [ref, optionsRef],
    useCallback(() => setOpened(false), []),
  );

  const filteredOptions = useCallback(
    (param: ITextValue[]): ITextValue[] =>
      isAttributionReport
        ? param.filter(
            (option) =>
              ![
                'NOT IN',
                'NOT HAVE',
                'NOT CONTAINS',
                'UNEXPOSED BETWEEN',
                'CONTAINS',
              ].includes(option.value as string),
          )
        : param,
    [isAttributionReport],
  );

  const dropdownId = getId(dropdownComponentName, id);
  const dropdownTestId = getTestId(dropdownComponentName, testId);
  const dropdownStickerClass = getClass(dropdownComponentName, {
    boolean: [
      {
        state: modalDropdown,
        class: 'modalDropdown',
      },
    ],
  });
  const dropdownClass = getClass(dropdownComponentName, {
    boolean: [
      {
        state: hasError,
        class: States.error,
      },
      {
        state: disabled,
        class: 'disabled',
      },
      {
        state: fixed,
        class: 'fixed',
      },
      {
        state: isButton,
        class: 'button',
      },
      {
        state: modalDropdown,
        class: 'modalDropdown',
      },
    ],
  });

  const inputType = fixed ? Type.text : Type.select;

  const OptionsTreeClass = getClass(dropdownComponentName, {
    text: ['tooltip-wrapper'],
  });

  const optionsStyle = {
    width: `${ref.current?.offsetWidth ?? defaultWidth}px`,
  };
  const valueIcon =
    defaultValue && getSelectedIcon(options as ITextValue[], defaultValue);

  return (
    <div
      id={dropdownId}
      data-testid={dropdownTestId}
      className={dropdownClass}
      ref={ref}
      onKeyDown={noop}
    >
      <Tooltip
        style={Style.secondary}
        content={description ?? ''}
        placement={Placement.top}
      >
        <Popover
          placement={Placement.bottomStart}
          matchTriggerWidth
          showArrow={false}
          customStickerClass={dropdownStickerClass}
          stickToBottom={stickToBottom}
          customPortalContainer={
            portalContainerClass
              ? `.${portalContainerClass}`
              : `.${featureRightSideContentClass}`
          }
          controlled
          visible={opened}
          content={
            !fixed && (
              <div
                className={OptionsTreeClass}
                style={optionsStyle}
                ref={optionsRef}
              >
                <OptionsTree
                  selectedOptions={selectedOptions}
                  value={getValueFromText(
                    value ?? '',
                    filteredOptions(options as ITextValue[]),
                  )}
                  ignoreGrouping={ignoreGrouping}
                  options={filteredOptions(options as ITextValue[])}
                  onClick={handleOnChange}
                />
              </div>
            )
          }
        >
          <Input
            disabled={disabled}
            readonly={fixed}
            name={name}
            onBlur={onBlur}
            placeholder={placeholder}
            testId={dropdownComponentName}
            type={inputType}
            value={value ?? ''}
            valueIcon={valueIcon}
            wrapperStyle={{
              width: defaultWidth,
            }}
            onClick={handleClick}
            hasError={!!errorText && isReportInvalid}
          />
        </Popover>
        {errorText && isReportInvalid && (
          <InlineAlert mode={AlertTypes.error} message={errorText} hideClose />
        )}
      </Tooltip>
    </div>
  );
};

export default Dropdown;
