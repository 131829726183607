import { ResultBaseSubStructure } from 'domains/reports/types';
import { get } from 'lodash';
import { TSegmentedData } from '..';

/**
 * Updates provided segmentedData with new point values for each segment
 *
 * @param segmentedData
 * Can be empty object or previous segmentedData - it will use that object as default value for reduce function
 * @param targetData
 * Target data {genpop} or {target} from breakout
 * @param color
 * String value for each point added to segment
 * @param demographic
 * DEMOGRAPHIC metric param which will function as new object key
 */

export const updateSegmentData = (
  segmentedData: TSegmentedData,
  demographicData: ResultBaseSubStructure[],
  color: string,
  name: string,
): TSegmentedData =>
  demographicData.reduce(
    (prevSet: TSegmentedData, currSet: ResultBaseSubStructure) => {
      const { DISPLAY_VALUE } = get(currSet, 'name', { DISPLAY_VALUE: '' });
      const { VALUE } = currSet?.campaign?.POPULATION_INDEX ?? {};
      const pcOfUniverse = currSet?.campaign?.PC_OF_UNIVERSE?.VALUE ?? 0;
      const reachValue = currSet?.campaign?.REACH?.VALUE ?? 0;

      const intValue = Math.round(VALUE as number);
      const intPcOfUniverse = Math.round((pcOfUniverse as number) * 100) / 100;
      const intReachValue = Math.round(reachValue as number);
      const extraDemographicData = {
        pcOfUniverse: intPcOfUniverse ?? 0,
        reach: intReachValue ?? 0,
      };
      return {
        ...prevSet,
        [DISPLAY_VALUE]: [
          ...(prevSet?.[DISPLAY_VALUE] ?? []),
          {
            value: intValue,
            color,
            name,
            ...(currSet?.campaign?.PC_OF_UNIVERSE ? extraDemographicData : {}),
          },
        ],
      };
    },
    segmentedData,
  );
