import Icon, {
  Color as IconColor,
  Size as IconSize,
  Type as IconType,
} from 'components/Icon';
import { Placement } from 'components/Popover';
import { Style, Tooltip } from 'components/Tooltip';
import { getClass, getTestId } from 'helpers/components';
import isArray from 'lodash/isArray';
import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';

export const buttonComponentName = 'button';

const Modifiers = {
  upperCase: 'uppercase',
} as const;

export type Modifier = (typeof Modifiers)[keyof typeof Modifiers];

export enum Kind {
  add = 'add',
  icon = 'icon',
  link = 'link',
  linkPrimary = 'link-primary',
  outline = 'outline',
  placeholder = 'placeholder',
  primary = 'primary',
  text = 'text',
  textPrimary = 'text-primary',
  toolbar = 'toolbar',
}

export enum Type {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

export enum Size {
  extraLarge = 'extra-large',
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export type Props = {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  iconColor?: IconColor;
  iconSize?: IconSize;
  iconType?: IconType;
  id?: string;
  kind?: Kind | Kind[];
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  size?: Size;
  testId?: string;
  tooltip?: string;
  tooltipShowArrow?: boolean;
  tooltipStyle?: Style;
  type?: Type;
  upperCase?: boolean;
};

const Button: FunctionComponent<Props> = (props): ReactElement => {
  const {
    children,
    className = '',
    disabled,
    iconColor,
    iconSize = IconSize.medium,
    iconType,
    id,
    kind,
    onClick,
    size,
    testId,
    tooltip,
    tooltipShowArrow = true,
    tooltipStyle = Style.secondary,
    type,
    upperCase = false,
  } = props;

  const buttonTestId = useMemo(
    () => getTestId(buttonComponentName, testId),
    [testId],
  );

  const kindIsAnArray = useMemo((): boolean => isArray(kind), [kind]);

  const buttonClass = useMemo(
    () =>
      getClass(buttonComponentName, {
        text: kindIsAnArray
          ? [size, iconType, ...(kind as string[])]
          : ([size, iconType, kind] as string[]),
        add: [className],
        boolean: [
          {
            state: upperCase,
            class: Modifiers.upperCase,
          },
        ],
      }),
    [className, iconType, kind, kindIsAnArray, size, upperCase],
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (type !== Type.submit) {
      event.preventDefault();
    }

    if (onClick) onClick(event);
  };

  return (
    <button
      className={buttonClass}
      data-testid={buttonTestId}
      disabled={disabled}
      onClick={handleClick}
      type={type}
      key={id}
    >
      <Tooltip
        content={tooltip}
        placement={Placement.top}
        style={tooltipStyle}
        showArrow={tooltipShowArrow}
      >
        {(kindIsAnArray
          ? (kind as Kind[]).includes(Kind.icon)
          : kind === Kind.icon) &&
          iconType && (
            <Icon type={iconType} color={iconColor} size={iconSize} />
          )}
        {children}
      </Tooltip>
    </button>
  );
};

export default Button;
