import { Type as IconType } from 'components/Icon';
import MultiCard, { IBaseCard } from 'components/MultiCard';
import { mainPerfectScrollbar } from 'features';
import { getTestId } from 'helpers/components';
import { trackAnchorClick } from 'helpers/mixpanel';
import useScroll from 'hooks/useScroll';
import React from 'react';
import { compact } from 'lodash';
import {
  getDeliveryAndOutcomesParams,
  numberFormatter,
} from 'helpers/visualization';
import { IExecutiveSummaryComponentProps } from './types';
import useUserPermissions from '../../../../../../hooks/useUserPermissions';

export const deliveryOutcomesComponentName = 'delievery-and-outcomes';
export const deliveryOutcomesTestId = getTestId(deliveryOutcomesComponentName);

export const DeliveryAndOutcomes: React.FC<IExecutiveSummaryComponentProps> = ({
  data: miscResult,
  selectedTarget,
  lastValidReport,
  conversionsMetricName,
  showLroiInVisualization,
  useEquivalizedMetrics,
}) => {
  const {
    SPOT_COUNT,
    AD_COST,
    REACH,
    REACH_PC,
    IMPRESSIONS,
    FREQUENCY,
    CONVERTED_REACH,
    INCREMENTAL_LIFT,
    DAILY_CONVERSIONS,
    CONVERSIONS_INCREMENTAL_LIFT,
    TOTAL_SALES,
    TOTAL_SALES_INCREMENTAL_LIFT,
    percentageOfConverters,
    percentageOfConversions,
    percentageOfTotalSales,
    adToConversationLatencyDays,
  } = getDeliveryAndOutcomesParams(miscResult, selectedTarget);
  const { goTo } = useScroll(mainPerfectScrollbar);
  const { checkPermissions } = useUserPermissions();

  const contentPrefix = useEquivalizedMetrics ? 'Eq. ' : '';

  const cards: IBaseCard[] = compact([
    {
      iconType: IconType.tv,
      title: numberFormatter(SPOT_COUNT?.VALUE),
      content: `${contentPrefix}Ad Spots`,
    },
    AD_COST?.VALUE && {
      iconType: IconType.money,
      title: numberFormatter(AD_COST?.VALUE, 'money'),
      content: 'Total Ad Cost',
    },
    checkPermissions('attribution_reports.campaign.reach::view') && {
      iconType: IconType.chartTypeLineBold,
      title: numberFormatter(REACH?.VALUE),
      subtitle: `${numberFormatter(REACH_PC?.VALUE, 'percent')} of Universe`,
      content: 'Reach',
    },
    checkPermissions('attribution_reports.campaign.impressions::view') && {
      iconType: IconType.eye,
      title: numberFormatter(IMPRESSIONS?.VALUE),
      content: `${contentPrefix}Impressions`,
    },
    {
      iconType: IconType.eye,
      title: numberFormatter(FREQUENCY?.VALUE),
      content: `${contentPrefix}Impression Frequency`,
    },
    {
      iconType: IconType.funnel2,
      title: numberFormatter(CONVERTED_REACH?.VALUE),
      subtitle: `${numberFormatter(INCREMENTAL_LIFT?.VALUE)} (${numberFormatter(
        percentageOfConverters,
        'percent',
      )}) are Incremental`,
      content: 'Converters',
      clickToScroll: () => {
        trackAnchorClick({
          lastValidReport,
          sectionName: 'Executive Summary',
          destination: '#CAMPAIGN_DELIVERY_AND_INSIGHTS',
        });

        goTo('#CAMPAIGN_DELIVERY_AND_INSIGHTS');
      },
    },
    DAILY_CONVERSIONS?.VALUE && {
      iconType: IconType.funnel,
      title: numberFormatter(DAILY_CONVERSIONS?.VALUE),
      subtitle: `${numberFormatter(
        CONVERSIONS_INCREMENTAL_LIFT?.VALUE,
      )} (${numberFormatter(
        percentageOfConversions,
        'percent',
      )}) are Incremental`,
      content: conversionsMetricName,
      clickToScroll: () => {
        trackAnchorClick({
          lastValidReport,
          sectionName: 'Executive Summary',
          destination: '#CAMPAIGN_DELIVERY_AND_INSIGHTS',
        });

        goTo('#CAMPAIGN_DELIVERY_AND_INSIGHTS');
      },
    },
    TOTAL_SALES?.VALUE && {
      iconType: IconType.money,
      title: numberFormatter(TOTAL_SALES?.VALUE, 'money'),
      subtitle: `${numberFormatter(
        TOTAL_SALES_INCREMENTAL_LIFT?.VALUE,
        'money',
      )} (${numberFormatter(
        percentageOfTotalSales,
        'percent',
      )}) are Incremental`,
      content: 'Total Sales',
      clickToScroll: () => {
        trackAnchorClick({
          lastValidReport,
          sectionName: 'Executive Summary',
          destination: '#CAMPAIGN_DELIVERY_AND_INSIGHTS',
        });

        goTo('#CAMPAIGN_DELIVERY_AND_INSIGHTS');
      },
    },
    showLroiInVisualization &&
      checkPermissions(
        'attribution_reports.ad_exposure_to_conversion_latency::view',
      ) && {
        iconType: IconType.calendar,
        title: adToConversationLatencyDays,
        content: 'Ad to Conversion Latency',
        clickToScroll: () => {
          trackAnchorClick({
            lastValidReport,
            sectionName: 'Executive Summary',
            destination: '#CAMPAIGN_EXPOSURE',
          });

          goTo('#CAMPAIGN_EXPOSURE');
        },
      },
  ]);

  return (
    <div style={{ marginTop: 16 }} data-testid={deliveryOutcomesTestId}>
      <MultiCard cards={cards} />
    </div>
  );
};
