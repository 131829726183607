import IReport from 'domains/reports/types';
import { connect } from 'react-redux';
import dashboardActions from 'store/actions/dashboard';
import featuresActions from 'store/actions/features';
import * as reportActions from 'store/actions/report';
import { handleUpdateName } from 'store/actions/report/handleUpdateName';
import * as reportResultActions from 'store/actions/reportResult';
import * as rootActions from 'store/actions/root';
import * as toolbarActions from 'store/actions/toolbar';
import * as eventsSelectors from 'store/selectors/events';
import * as messageListSelectors from 'store/selectors/messageList';
import * as reportSelectors from 'store/selectors/report';
import * as reportResultSelectors from 'store/selectors/reportResult';
import * as toolbarSelectors from 'store/selectors/toolbar';
import State from 'types/state';
import { EditFeature } from './EditFeature.component';
import { ConnectedActions, ConnectedState } from './types';

const mapStateToProps = (state: State): ConnectedState => ({
  attributionReportResult:
    reportResultSelectors.getAttributionReportResult(state),
  confirmationDialog: reportSelectors.getConfirmationDialog(state),
  events: eventsSelectors.getEvents(state),
  messageList: messageListSelectors.getMessageList(state),
  invalidTargetList: reportSelectors.getInvalidTargetList(state),
  isAccordionMethodologyOpen: reportSelectors.isAccordionMethodologyOpen(state),
  isAccordionQueryOpen: reportSelectors.isAccordionQueryOpen(state),
  isReportInvalid: reportSelectors.isReportInvalid(state),
  isAccordionResultsOpen: reportSelectors.isAccordionResultsOpen(state),
  isBaseReportNew: reportSelectors.getBaseReportNew(state),
  isReportNameDisabled: toolbarSelectors.getReportNameDisabled(state),
  modalDialog: reportSelectors.getModalDialog(state),
  report: reportSelectors.getReport(state),
  validTargetList: reportSelectors.getValidTargetList(state),
  errorMessage: reportResultSelectors.getErrorMessage(state),
  isBaseReportSaving: reportSelectors.isBaseReportSaving(state),
  isLoadingReadOnlyToggle: toolbarSelectors.getIsLoadingReadOnlyToggle(state),
  isDownloadingPresentation:
    reportSelectors.getIsDownloadingPresentation(state),
});

const mapDispatchToProps: ConnectedActions = {
  disableCancel: toolbarActions.disableCancel,
  disableSave: toolbarActions.disableSave,
  disableSaveAs: toolbarActions.disableSaveAs,
  enableCancel: toolbarActions.enableCancel,
  enableSave: toolbarActions.enableSave,
  enableSaveAs: toolbarActions.enableSaveAs,
  run: toolbarActions.run,
  cancel: toolbarActions.cancel,
  setProgress: toolbarActions.setProgress,
  setRefreshLaunched: toolbarActions.setRefreshLaunched,
  setRefreshNotLaunched: toolbarActions.setRefreshNotLaunched,
  setLastCachedDate: toolbarActions.setLastCachedDate,
  setType: toolbarActions.setType,
  setAccordionQueryOpen: reportActions.setAccordionQueryOpen,
  setAccordionQueryClosed: reportActions.setAccordionQueryClosed,
  processRules: reportActions.processRules,
  runFileUploadQuery: reportActions.runFileUploadQuery,
  runDownloadQuery: reportActions.runDownloadQuery,
  runQuery: reportActions.runQuery,
  showReportPayload: reportActions.showReportPayload,
  setAggregationMethod: reportActions.setAggregationMethod,
  setReportName: reportActions.setReportName,
  setReport: reportActions.setReport,
  setUserConfirmationAction: reportActions.setUserConfirmationAction,
  setAcceptDialogModal: reportActions.setAcceptDialogModal,
  handleUpdateName: handleUpdateName as unknown as (
    name: string,
  ) => Promise<IReport | undefined>,
  setAttributionReportList: rootActions.setAttributionReportList,
  resetAttributionReportResult:
    reportResultActions.resetAttributionReportResult,
  cancelExecutionJobAction: reportResultActions.cancelExecutionJobAction,
  handleSubmitAttributionForm: featuresActions.handleSubmitAttributionForm,
  handleRemoveMissingReportName: featuresActions.handleRemoveMissingReportName,
  onSaveEmptyNameReport: featuresActions.onSaveEmptyNameReport,
  setIsReportInvalid: reportActions.setIsReportInvalid,
  setErrorMessage: reportResultActions.setErrorMessage,
  resetDashboardReport: dashboardActions.dashboardReportReset,
  handleToggleReadOnly: reportActions.handleToggleReadOnly,
  handleDownloadPresentation: reportActions.handleDownloadPresentation,
  handleCloneReport: rootActions.handleCloneReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFeature);
