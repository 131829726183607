import { Auth0Context, Auth0Provider } from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';
import axios from 'axios';
import ProtectedRoute from 'components/ProtectedRoute';
import IConfig from 'domains/config/types';
import useConfig from 'domains/config/useConfig';
import useToast from 'hooks/useToast';
import { get, omit } from 'lodash/fp';
import mixpanel from 'mixpanel-browser';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Routes, useNavigate, Route } from 'react-router-dom';
import { Index as Segments } from 'routes';
import {
  GetTokenSilentlyOptions,
  GetTokenSilentlyVerboseResponse,
} from '@auth0/auth0-spa-js';
import Feature from './features';

const getAccessTokenSilently = (() => Promise.resolve('')) as unknown as {
  (
    options: GetTokenSilentlyOptions & { detailedResponse: true },
  ): Promise<GetTokenSilentlyVerboseResponse>;
  (options?: GetTokenSilentlyOptions): Promise<string>;
  (
    options: GetTokenSilentlyOptions,
  ): Promise<GetTokenSilentlyVerboseResponse | string>;
};

const MOCK_AUTH_PROVIDER = (
  <Auth0Context.Provider
    value={{
      isAuthenticated: true,
      isLoading: false,
      getAccessTokenSilently,
      getAccessTokenWithPopup: () => Promise.resolve(''),
      getIdTokenClaims: () => Promise.resolve({ __raw: '' }),
      loginWithPopup: () => Promise.resolve(),
      loginWithRedirect: () => Promise.resolve(),
      logout: async () => undefined,
      handleRedirectCallback: () => Promise.resolve({}),
    }}
  >
    <Routes>
      <Route path="*" element={<Feature />} />
    </Routes>
  </Auth0Context.Provider>
);

const Auth: FunctionComponent = () => {
  const { config, setConfig } = useConfig();
  const [loading, setLoading] = useState<boolean>();
  const { doErrorToast } = useToast();
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState | undefined): void => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  useEffect(() => {
    if (config.loaded || loading) return;
    setLoading(true);
    axios
      .get(
        `${window.location.protocol}//${window.location.hostname}:${
          process.env.REACT_APP_API_PORT ?? 443
        }/${Segments.SEGMENT_BASE_API}/${Segments.SEGMENT_UI_CONFIG}`,
      )
      .then((response) => {
        const mixpanelToken = get('data.mixpanelToken', response) || 'NO-TOKEN';
        const responseData = omit('mixpanelToken', response.data) as IConfig;
        setConfig(responseData);
        // Initialize mixpanel
        mixpanel.init(mixpanelToken);
      })
      .catch((error) => {
        doErrorToast(error?.message || 'There has been an error.');
      });
  }, [config.loaded, doErrorToast, loading, setConfig]);

  if (!config.loaded) return null;

  return process.env.REACT_APP_CYPRESS ? (
    MOCK_AUTH_PROVIDER
  ) : (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: config.audience,
        scope: 'read:current_user update:current_user_metadata',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Routes>
        <Route element={<ProtectedRoute component={Feature} />} path="*" />
      </Routes>
    </Auth0Provider>
  );
};

export default Auth;
